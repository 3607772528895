import React from "react";
import { MdVerifiedUser } from "react-icons/md";
import { SiHiveBlockchain } from "react-icons/si";
import { IoMdAnalytics } from "react-icons/io";
import { GiArtificialIntelligence } from "react-icons/gi";
import { useSelector } from "react-redux";
import { BiSolidMessageDetail } from "react-icons/bi";
import resumeParser from "../Images/icon.jpeg";

const PremiumServicesOptions = ({ setSwitchTab }) => {
  // store
  const selectedLanguage = useSelector((state) => state.language);
  const content = useSelector((state) => state.content);
  return (
    <div>
      {/* premium services */}
      <div
        className="mt-2 rounded-top "
        style={{
          backgroundColor:
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "SecBarBgColor"
              ) || {}
            ).mvalue || "#577126",
          color:
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "SecBarFontColor"
              ) || {}
            ).mvalue || "#F7FFDD",
          height: "",
        }}
      >
        <div className="text h6 text-center   " style={{ padding: "8px 0" }}>
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "PremiumServices"
            ) || {}
          ).mvalue || "nf PremiumServices"}
        </div>
      </div>
      <table className="table table-sm table-hover ">
        <tbody>
          <tr className=" " onClick={() => setSwitchTab("premium")}>
            <td className="">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ResumeParsing"
                ) || {}
              ).mvalue || "nf ResumeParsing"}
            </td>
            <td className="d-flex justify-content-center">
              <img
                src={resumeParser}
                alt=""
                style={{ width: "16px", cursor: "pointer" }}
                className="bg-img"
              />
            </td>
          </tr>

          <tr onClick={() => setSwitchTab("premium")}>
            <td>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "BlockChain"
                ) || {}
              ).mvalue || "nf BlockChain"}
            </td>
            <td className="d-flex justify-content-center">
              <SiHiveBlockchain />
            </td>
          </tr>
          <tr onClick={() => setSwitchTab("premium")}>
            <td>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ThirdPartySupport"
                ) || {}
              ).mvalue || "nf ThirdPartySupport "}
            </td>

            <td className="d-flex justify-content-center">
              <MdVerifiedUser />
            </td>
          </tr>
          <tr onClick={() => setSwitchTab("customAnalytics")}>
            <td>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "CustomAnalytics"
                ) || {}
              ).mvalue || "nf CustomAnalytics"}
            </td>
            <td className="d-flex justify-content-center">
              <IoMdAnalytics />
            </td>
          </tr>
          <tr onClick={() => setSwitchTab("premium")}>
            <td>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ConversationalAI"
                ) || {}
              ).mvalue || "nf ConversationalAI"}
            </td>
            <td className="d-flex justify-content-center">
              <GiArtificialIntelligence />
            </td>
          </tr>
          <tr onClick={() => setSwitchTab("premium")}>
            <td>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "MessageEngine"
                ) || {}
              ).mvalue || "nf MessageEngine"}
            </td>
            <td className="d-flex justify-content-center">
              <BiSolidMessageDetail />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PremiumServicesOptions;
