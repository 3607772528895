import React from 'react'

const TableLoaders = ({ Rows, Cols, btnCols }) => {
    let r = Rows? Rows : 2
    let c = Cols? Cols : 2
    let bc = btnCols ? btnCols : 3;
    return (
        <React.Fragment>
            {[...Array(r)].map((e, i) => <tr className="" >

                {[...Array(c)].map((e, i) =>
                    <td class="placeholder-glow">
                        <span class="placeholder  w-100"></span>
                    </td>
                )}
                <td class="placeholder-glow ">
                    {[...Array(bc)].map((e,i)=>
                    <span class="placeholder  w-25 mx-1"></span>
                    
                   )}
                </td>

            </tr>
            )}


        </React.Fragment>
    )
}

export default TableLoaders