import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { DayDifferenceToDynamicView } from './../../SkillOwner/HelperFunction/DayDifferenceToDynamicView';
import { calculateDaysDifference } from './../../SkillOwner/HelperFunction/CalculateDaysDifference ';
import { formatTimestampToDate } from './../../SkillOwner/HelperFunction/FormatTimestampToDate';
import ValidationDataComponentSeeker from '../ValidationDataComponentSeeker';

const SeekerSkillAppliedDetail = ({ skillName, userDetails }) => {
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    const [appliedData, setAppliedData] = useState([]);
    const regionalData = useSelector(state => state.regionalData);

    useEffect(() => {
        if (userDetails.skillapp && userDetails.skillapp !== "null" && userDetails.skillapp !== "undefined") {
            const temp = userDetails.skillapp.filter((skill) => skill.title.toLowerCase() === skillName.toLowerCase())
            setAppliedData(temp);
        }
    }, [SkillBasedResult])
    useEffect(() => {
        console.log("appDatadetail ", appliedData);
    }, [appliedData])
    return (
        <div className="table-responsive mt-2 ms-4 me-5" >
            {appliedData?.length > 0 ? <><div className='d-none d-lg-block'>
                <table className='table table-sm  table-borderless   my-2   table-fixed     '>
                    <tr className='   '>
                        <th scope="col" className='bg-body- ' style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectTitle') || {}).mvalue || "nf ProjectTitle"}</th>
                        <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}</th>
                        <th scope="col" className='bg-body- ' style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf ProjectType"}</th>
                        <th scope="col" className='bg-body- ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                        <th scope="col" className='' style={{ width: "10%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>

                    </tr>

                    <div className='mt-1 ' ></div>

                    {/* horizontal line */}
                    <tr style={{ borderColor: "gray", borderWidth: "1px" }} />

                </table>
            </div>


                {appliedData.map((skill, index) => (
                    skill.recordHide === 'Yes' ?
                        <></>
                        :
                        <>
                            <div className='   ' key={index}>

                                <div className=' '>
                                    {/* for mobile screens */}
                                    <div className='d-lg-none '>
                                        <table className='table table-borderless m-0  ' >
                                            <tr className=' ' >
                                                <td><b>Project/Activity</b> </td>
                                                <td>:{skill.projectActivity}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Duration</b> </td>
                                                <td>:{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Type</b> </td>
                                                <td>:{skill.mtype}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Location</b> </td>
                                                <td>:{skill.location ? skill.location?.length> 17 ? skill.location.substring(0, 17) + "..." : skill.location : ""} </td>
                                            </tr>
                                            <tr>
                                                <td><b>Validation</b> </td>
                                                <td>:{skill.validation} </td>
                                            </tr>


                                        </table>

                                    </div>
                                    {/*for lap   */}
                                    <div className='d-lg-flex d-none   '>
                                        <div className='   ' style={{ width: "34%" }}>{skill.projectActivity ? (skill.projectActivity?.length> 17 ? skill.projectActivity.substring(0, 17) + "..." : skill.projectActivity) : (skill.organization && skill.organization?.length> 17 ? skill.organization.substring(0, 17) + "..." : skill.organization)}</div>
                                        <div style={{ width: "13%" }}>{DayDifferenceToDynamicView(skill.duration)}</div>
                                        <div className='' style={{ width: "17%" }}>{skill.mtype === "Own" ?
                                            (content[selectedLanguage].find(item => item.elementLabel === 'ProjectOwn') || {}).mvalue || "nf Project(O)" :
                                            (skill.mtype === "Employment" && skill.projectActivity ?
                                                (content[selectedLanguage].find(item => item.elementLabel === 'ProjectEmployment') || {}).mvalue || "nf Project(E)" :
                                                (content[selectedLanguage].find(item => item.elementLabel === 'ProfessionalExperience') || {}).mvalue || "nf Professional Experience"
                                            )
                                        }</div>
                                        <div className='    ' style={{ width: "26%" }} data-tooltip-id="my-tooltip" data-tooltip-content={skill.location ? skill.location : ""}>{skill.location ? (skill.location?.length> 25 ? skill.location.substring(0, 25) + "..." : skill.location) : ""}</div>

                                        <div className='  ' style={{ width: "10%" }}>{skill.validation} </div>

                                    </div>
                                </div>



                                <div className='mb-3 mt-2  ms-4 '>
                                    <div className='d-lg-flex align-items-center  '>
                                        {skill?.mtype === "Employment" && (
                                            <div className='d-flex  ' style={{ width: "64%" }} > <b>{(content[selectedLanguage].find(item => item.elementLabel === 'Organization') || {}).mvalue || "nf Organization"}:</b>
                                                <div>{skill.recordAnonymous === 'Yes' ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : skill.organization}</div>
                                            </div>
                                        )}
                                        <div className='d-flex  ps-lg-0  mt-1 mt-lg-0 me-3 ' style={{ width: "17%" }}>
                                            <b>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"}&nbsp;:&nbsp;</b>
                                            <div>{formatTimestampToDate((skill.fromDate), regionalData.selectedCountry.dateFormat)}</div>
                                        </div>
                                        <div className='d-flex  ps-lg-0    ' style={{ width: "22%" }}>
                                            <b>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"}&nbsp;:&nbsp;</b>
                                            <div>{skill.toDate ? formatTimestampToDate((skill.toDate), regionalData.selectedCountry.dateFormat) : "On-going"}</div>
                                        </div>
                                    </div>

                                    <div className='pb-1 pt-2   '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"}:</b> </div>
                                    <div className='ms-4    '> {skill.briefDescriptions}</div>


                                    {skill.validation === "Yes" &&
                                        <ValidationDataComponentSeeker data={skill?.ValidationData} itemId={skill?.id} /> 
                                    }

                                </div>
                            </div>
                            <hr className='border-black px-0 py-0  ' style={{ borderColor: "black", borderWidth: "2px" }} />

                        </>
                ))
                }</>
                : <div className='d-flex justify-content-center mt-2 mb-3 mx-1' >
                    {(content[selectedLanguage].find(item => item.elementLabel === 'NoSkillAppliedDataSeeker') || {}).mvalue || "nf No Skills Applied Expirience "}
                </div>}
        </div>
    )
}

export default SeekerSkillAppliedDetail