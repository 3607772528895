import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import  { useEffect, useState } from "react";
import "./supportService.css";
import LocationSuggestionApi from '../../api/locationApi/LocationSuggestionApi';
import CreatableSelect from "react-select/creatable";

export default function ServiceDashboardLayout() {
    
        const [selectedLocations, setSelectedLocations] = useState([]);
        const [locationInput, setLocationInput] = useState("");
        const [inputValue,setInputValue]=useState("")
        
      
        const [filterLocation, setFilterLocation] = useState([]);
        const components = {
          DropdownIndicator: null,
        };
      
        useEffect(() => {
          LocationSuggestionApi(
            locationInput,
            "city",
            localStorage.getItem("countryCode")
          )
            .then((res) => {
              const data = res.data;
      
              //eslint-disable-next-line
              setFilterLocation(
                data.map((item) => ({
                  value: item.city,
                  label: item.city,
                  latitude: item.cityLatitude,
                  longitude: item.cityLongitude,
                }))
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }, [localStorage.getItem("countryCode"), locationInput]);
  return (
    <>
    <div class="container-fluid ">
        <br />
        <br />
       <div class="row justify-content-center ">
        <div class="col-10 ">
          <form class="card card-sm">
            <div class="card-body row no-gutters align-items-center">
              <div class="col-auto">
                <i class="fas fa-search h4 text-body"></i>
              </div>

              <div class="col">
                <input
                  class="form-control form-control-lg form-control-borderless"
                  type="text"
                  placeholder="Search Services or Companies"
                />
              </div>
              <div className="col-1">
                <div class="vr vr-blurry" style={{ height: "1.7rem" }}></div>
              </div>

              <div className="col-3">
                <CreatableSelect
                    
                  placeholder={"Add Location"}
                  options={filterLocation}
                  components={components}
                  inputValue={locationInput}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "10rem",
                      border: "none",
                      fontSize: "1.2rem",
                      outline: "none",
                    }),
                  }}
                  onInputChange={(newValue) => setLocationInput(newValue)}
                  isClearable
                  menuPlacement="auto"
                  menuPosition="fixed"
                  isValidNewOption={() => false}
                  onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedLocations(newValue);
                  }}
                  value={selectedLocations}
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-lg btn-primary"
                  type="submit"
                  style={{
                    backgroundColor: "#577126",
                    border: "none",
                    outline: "none",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    <Outlet>

    </Outlet>
    </div>
    </>
  )
}
