import React, { useEffect, useState } from "react";
import CreateSelect from "../SelectComponent/CreateSelect";
import SecondaryBtn from "../../Buttons/SecondaryBtn";
import { useSelector } from "react-redux";
import PostApi from "../../../api/PostData/PostApi";
import { addNewSkillApplied } from "../../../reducer/skillProfile/SkillsAppliedSlice";
import { useDispatch } from "react-redux";
import { showSuccessToast } from "../../ToastNotification/showSuccessToast";
import { showErrorToast } from "../../ToastNotification/showErrorToast";
import { addNewProject } from "../../../reducer/detailedProfile/projectSlice";
import { addNewEmployment } from "../../../reducer/detailedProfile/employmentSlice";

import { convertDateToMilliseconds } from "../HelperFunction/convertDateToMilliseconds";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import EditApi from "../../../api/editData/EditApi";
import { replaceTopSkillById } from "../../../reducer/mySkills/TopSkillSlice";
import {
  editAppliedExp,
  editExp,
  editYoe,
} from "../../../reducer/mySkills/SkillSelectedSlice";
import MultiSelect from "../SelectComponent/MultiSelect";
import { RxCross2 } from "react-icons/rx";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import { timestampToYYYYMMDD } from "../HelperFunction/timestampToYYYYMMDD";
import SmallLoader from "../../SkillAvailer/SmallLoader";
import {
  FormatDateIntoPost,
  FormatPostIntoDate,
} from "../HelperFunction/FormatDateIntoPost";
import SecondaryBtnLoader from "../../Buttons/SecondaryBtnLoader";
import { FetchProjectHistory } from "../../../api/fetchAllData/FetchProjectHistory";
import { BiSolidInfoCircle } from "react-icons/bi";
import DatePicker from "react-datepicker";
import { formatDateInputType } from "../HelperFunction/FormatDateInputType";

const ProjectForm = ({
  formValues,
  setFormValues,
  setValidation,
  setFileupload,
  handleModalClose,
  setSelectedValue,
  skillsApplied,
}) => {
  const selectedLanguage = useSelector((state) => state.language);
  const SkillSelected = useSelector((state) => state.SkillSelected);

  /* STORE IMPORTS */
  const {
    regionalData,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const initialProjectState = {
    projectActivity: "",
    fromDate: "",
    toDate: "",
    briefDescriptions: "",
    mtype: "Own",
    mlanguage: selectedLanguage,
    userId: localStorage.getItem("userId"),
    organization: "",
    showHide: "Yes",
    validation: "No",
    duration: "",
    remark: "",
    location: "",
    orgIsnew: false,
  };
  const initialEmployeeState = {
    organization: "",
    fromDate: "",
    toDate: "",
    location: "",
    briefDescriptions: "",
    mlanguage: selectedLanguage,
    mtype: "Employment",
    userId: localStorage.getItem("userId"),
    showHide: "Yes",
    validation: "No",
    duration: "",
    remark: "",
  };

  const [newProject, setnewProject] = useState(initialProjectState);
  const [newEmployment, setNewEmployment] = useState(initialEmployeeState);
  const [onGoing, setOnGoing] = useState(false); //project ongoing
  const [skillAccOngoing, setSkillAccOngoing] = useState(false);
  const [empOngoing, setEmpOngoing] = useState(false);
  const [newEmpOngoing, setNewEmpOngoing] = useState(false);

  // to pass react select and control over clear values
  const [projectSelectValue, setProjectSelectValue] = useState(null);
  const [companySelectValue, setCompanySelectValue] = useState(null);
  const [isAddingproject, setIsAddingProject] = useState(false);
  const [isAddingNewProject, setIsAddingNewProject] = useState(false);
  const [isAddingNewEmployment, setIsAddingNewEmployment] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [workDesc, setWorkDesc] = useState("");

  //locations for exsiting project
  const [locationForProject, setLocationForProject] = useState([]);
  useEffect(() => {
    let locations = projectSelectValue?.value?.location?.split(",");
    setLocationForProject(locations);
  }, [projectSelectValue]);
  //locations for new project
  const [locationForNewProject, setLocationForNewProject] = useState("");
  const [newProjectOnline, setNewProjectOnline] = useState(false);

  //locations for exsiting employment
  const [locationForEmployment, setLocationForEmployment] = useState("");

  useEffect(() => {
    let locations = companySelectValue?.value?.location?.split(",");
    setLocationForEmployment(locations);
    dispatch(FetchProjectHistory())
      .then((data) => console.log(data?.payload[0]?.id, "bbb"))
      .catch((err) => console.error("Error while getting project data: ", err));
  }, [companySelectValue?.value?.location, dispatch]);
  //locations for new employment
  const [locationForNewEmployment, setLocationForNewEmployment] = useState("");
  const [newEmploymentOnline, setNewEmploymentOnline] = useState(false);

  console.log("skill Selected", SkillSelected);

  const handleValidateProject = () => {
    setValidation(true);
  };

  const checkDuplicate = (form) => {
    var duplicate = false;

    skillsApplied?.data?.map((skill) => {
      console.log(SkillSelected, skill.title);
      console.log(skill.source, form.organization);

      if (
        SkillSelected.skillOccupation === skill.title &&
        skill.projectActivity === form.projectActivity &&
        skill.mtype === formValues.mtype
      ) {
        let fromDate = convertDateToMilliseconds(form.fromDate);
        let toDate = form.toDate
          ? convertDateToMilliseconds(FormatDateIntoPost(form.toDate))
          : Date.now();

        console.log(skill);

        console.log(fromDate, skill.fromDate);
        console.log(toDate, skill.toDate ? skill.toDate : Date.now());

        if (
          fromDate === skill.fromDate ||
          toDate === (skill.toDate ? skill.toDate : Date.now())
        ) {
          duplicate = true; // Overlap detected
        }
        // Check for overlap
        else if (
          (fromDate >= skill.fromDate &&
            fromDate <= (skill.toDate ? skill.toDate : Date.now())) || // User from date falls within existing date range
          (toDate >= skill.fromDate &&
            toDate <= (skill.toDate ? skill.toDate : Date.now())) || // User to date falls within existing date range
          (fromDate <= skill.fromDate &&
            toDate >= (skill.toDate ? skill.toDate : Date.now())) || // User date range completely overlaps existing date range
          (fromDate <= skill.fromDate &&
            toDate >= skill.fromDate &&
            toDate <= (skill.toDate ? skill.toDate : Date.now())) || // Right-side overlap
          (toDate >= (skill.toDate ? skill.toDate : Date.now()) &&
            fromDate >= skill.fromDate &&
            fromDate <= (skill.toDate ? skill.toDate : Date.now())) // Left-side overlap
        ) {
          console.log("inside");
          duplicate = true; // Overlap detected
        }
      }
    });

    return duplicate;
  };

  const handleSubmitDate = (close) => {
    if (isAddingproject) {
      return;
    }

    let duplicate = checkDuplicate(formValues);
    console.log(duplicate);
    if (duplicate) {
      showErrorToast(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "SkillAppliedDateRange"
          ) || {}
        ).mvalue || "nf SkillApplied already exist within the date range"
      );
      return;
    }

    setIsAddingProject(true);

    formValues.title = SkillSelected.skillOccupation;
    formValues.userSkill = SkillSelected?.skillOccupation;
    formValues.userSkillsId = SkillSelected?.id;
    // formValues.location = newProject.mtype === "Own" ? locationForNewProject : locationForEmployment.join();
    formValues.location = locationForProject
      ? locationForProject?.join()
      : locationForEmployment?.join();
    formValues.mtype = projectSelectValue?.value?.mtype
      ? projectSelectValue?.value?.mtype
      : companySelectValue?.value?.mtype;
    formValues.organization = projectSelectValue?.value?.organization
      ? projectSelectValue?.value?.organization
      : companySelectValue?.value?.organization;
    formValues.duration = calculateDaysDifference(
      convertDateToMilliseconds(formValues.fromDate),
      formValues.toDate
        ? convertDateToMilliseconds(formValues.toDate)
        : Date.now()
    );
    formValues.fromDate = FormatDateIntoPost(formValues.fromDate);
    formValues.toDate = formValues.toDate
      ? FormatDateIntoPost(formValues.toDate)
      : "";
    /* TICKETS ID BASED ON THE TICKETS AVAILABLITY */

    const ticketIdsArray = [];

    if (projectSelectValue?.value?.id) {
      ticketIdsArray.push(projectSelectValue.value.id);
    }
    if (companySelectValue?.value?.id) {
      ticketIdsArray.push(companySelectValue.value.id);
    }

    formValues.ticketids = ticketIdsArray;
    /* TO MAKE ARRAY COMMA SEPERATED VALUES */
    const ticketIdsString = ticketIdsArray.join(",");

    if (valueYesOrNo === "No") {
      formValues.projectActivity = workDesc;

      try {
        const res = PostApi("Project  History", formValues);
        const data = res?.data;
        data.fromDate = convertDateToMilliseconds(data.fromDate);
        data.toDate = data.toDate
          ? convertDateToMilliseconds(res.data.toDate)
          : "";
        dispatch(addNewProject(data));
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "AddedNewProject"
            ) || {}
          ).mvalue || "nf Added new Project"
        );
        setnewProject(initialProjectState);
        setCompanySelectValue(null);
        setOnGoing(false);
      } catch (error) {
        if (error instanceof TypeError) {
          console.error("Type error occured: ", error.message);
        } else if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error.message);
        } else {
          console.error(
            "Error Occurs during project history: ",
            error?.response
          );
        }
      }
    }

    PostApi("Skills Applied", formValues)
      .then((res) => {
        const data = res.data;
        console.log(res.data);
        const days = calculateDaysDifference(
          data.fromDate,
          data.toDate ? data.toDate : Date.now()
        );
        console.log("days areeeee", days);
        const totalDays = days + Number(SkillSelected.yoe);
        const totalExp =
          (SkillSelected.skillAppliedExp
            ? Number(SkillSelected.skillAppliedExp)
            : 0) + days;
        console.log("totalll expp", totalExp);
        dispatch(
          addNewSkillApplied({
            ...data,
            fromDate: convertDateToMilliseconds(data.fromDate),
            toDate: data.toDate ? convertDateToMilliseconds(data.toDate) : "",
          })
        );
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "ProjectAddedSuccessful"
            ) || {}
          ).mvalue || "nf Project Added Successful"
        );

        if (valueYesOrNo === "No") dispatch(FetchProjectHistory());

        EditApi("User Skills", SkillSelected.id, {
          yoe: `${totalDays}`,
          skillAppliedExp: `${totalExp}`,
        })
          .then((res) => {
            console.log("edited data", res.data);
            dispatch(
              replaceTopSkillById({
                id: SkillSelected.id,
                updatedData: res.data,
              })
            );
            dispatch(editYoe(totalDays));
            dispatch(editAppliedExp(totalExp));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        showErrorToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SomethingWentWrong"
            ) || {}
          ).mvalue || "nf Something went wrong"
        );
      })
      .finally(() => {
        setIsAddingProject(false);
        // if press save and close => close is TRUE => close the modal
        if (close) {
          handleModalClose();
        } else {
          setProjectSelectValue(null);
          formValues.projectActivity = "";
          formValues.briefDescriptions = "";
        }
      });
  };

  const content = useSelector((state) => state.content);
  const projectHistory = useSelector((state) => state.projectHistory.data);
  const employmentHistory = useSelector(
    (state) => state.employmentHistory.data
  );

  console.log(projectHistory);
  const filterLang = projectHistory
    .filter((project) => project.mlanguage === selectedLanguage)
    .map((project) => ({
      value: project,
      label: project.projectActivity,
    }));

  const filterEmployment = employmentHistory
    .filter((employment) => employment.mlanguage === selectedLanguage)
    .map((employment) => ({
      value: employment,
      label: employment.organization,
    }));

  const handleAddNewProject = () => {
    if (isAddingNewProject) {
      return;
    }

    const fromDate = formatDate(newProject.fromDate);
    const toDate = newProject.toDate ? formatDate(newProject.toDate) : "";

    console.log(newProject);
    const formatdata = {
      ...newProject,
      projectActivity: projectSelectValue.label,
      organization: companySelectValue?.label,
      fromDate: fromDate,
      toDate: toDate,
      mtype: formValues?.mtype === "Own" ? "Own" : "Employment",
      duration: calculateDaysDifference(
        convertDateToMilliseconds(newProject.fromDate),
        newProject.toDate
          ? convertDateToMilliseconds(newProject.toDate)
          : Date.now()
      ),
      location:
        newProject.mtype === "Own"
          ? locationForNewProject
          : locationForEmployment?.join(),
      ticketids: companySelectValue?.value?.id
        ? [companySelectValue?.value?.id]
        : [],
    };

    console.log(formatdata);

    setIsAddingNewProject(true);

    PostApi("Project  History", formatdata)
      .then((res) => {
        const data = res.data;
        console.log("resdata is ", data);
        data.fromDate = convertDateToMilliseconds(data.fromDate);
        data.toDate = data.toDate ? convertDateToMilliseconds(data.toDate) : "";
        dispatch(addNewProject(data));
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "AddedNewProject"
            ) || {}
          ).mvalue || "nf Added new Project"
        );
        document.getElementById("closeBtn").click();
        setnewProject(initialProjectState);
        // setFormValues({...formValues,fromDate:data.fromDate,toDate:data.toDate})
        setProjectSelectValue({ label: data.projectActivity, value: data });

        setFormValues({
          ...formValues,
          projIsnew: false,
          projectActivity: data.projectActivity,
          fromDate: timestampToYYYYMMDD(Number(data.fromDate)),
          toDate: data.toDate ? timestampToYYYYMMDD(Number(data.toDate)) : "",
          organization: data.organization,
          mtype: data.mtype,
        });

        setIsAddingNewProject(false);
      })
      .catch((err) => {
        console.log(err);
        showErrorToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SomethingWentWrong"
            ) || {}
          ).mvalue || "nf Something went wrong"
        );
      })
      .finally(() => {
        setIsAddingNewProject(false);
      });
  };

  //  useEffect(()=>{
  //  setFormValues({...formValues,
  //   fromDate:FormatDateIntoPost((timestampToYYYYMMDD(Number(projectSelectValue?.value?.fromDate)))) ,
  //   toDate: FormatDateIntoPost((timestampToYYYYMMDD(Number(projectSelectValue?.value?.toDate))))})
  //   },[projectSelectValue?.label])

  const formatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${month}/${day}/${year}`;
  };

  const handleProjectClose = () => {
    setFormValues({ ...formValues, projIsnew: false, projectTitle: "" });
    setProjectSelectValue(null);
  };

  const handleEmploymentClose = () => {
    setnewProject({ ...newProject, orgIsnew: false, organization: "" });
    setCompanySelectValue(null);
  };

  const handleAddNewEmployment = () => {
    if (isAddingNewEmployment) {
      return;
    }
    setIsAddingNewEmployment(true);

    const fromDate = formatDate(newEmployment.fromDate);
    const toDate = newEmployment.toDate ? formatDate(newEmployment.toDate) : "";
    const formatdata = {
      ...newEmployment,
      fromDate: fromDate,
      toDate: toDate,
      duration: calculateDaysDifference(
        convertDateToMilliseconds(newEmployment.fromDate),
        newEmployment.toDate
          ? convertDateToMilliseconds(newEmployment.toDate)
          : Date.now()
      ),
      location: locationForNewEmployment,
    };

    PostApi("Employment History", formatdata)
      .then((res) => {
        const data = res.data;
        data.fromDate = convertDateToMilliseconds(data.fromDate);
        data.toDate = data.toDate ? convertDateToMilliseconds(data.toDate) : "";
        dispatch(addNewEmployment(data));
        // setnewProject({...newProject,fromDate:data.fromDate,toDate: data.toDate? data.toDate : ""})
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "AddedNewEmployment"
            ) || {}
          ).mvalue || "nf Added new Employment"
        );
        document.getElementById("closeBtnEmployee").click();
        setNewEmployment(initialEmployeeState);
        // setnewProject({...newProject,organization:data.organization})
        setCompanySelectValue({ label: data.organization, value: data });

        setFormValues({
          ...formValues,
          fromDate: timestampToYYYYMMDD(Number(data.fromDate)),
          toDate: data.toDate ? timestampToYYYYMMDD(Number(data.toDate)) : "",
          organization: data.organization,
          mtype: data.mtype,
        });
      })
      .catch((err) => {
        console.log(err);
        showErrorToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SomethingWentWrong"
            ) || {}
          ).mvalue || "nf Something went wrong"
        );
      })
      .finally(() => {
        setIsAddingNewEmployment(false);

        console.log(formValues);
      });
  };

  console.log("form values", formValues);
  console.log("projectSelectValue", projectSelectValue);
  console.log("company select value", companySelectValue);

  console.log("newProject", newProject);

  // useEffect(() => {
  //   if (!projectSelectValue) {
  //     setSkillAccOngoing(false);
  //     return;
  //   }
  //   if (!companySelectValue) {
  //     setSkillAccOngoing(false);
  //     return;
  //   }
  //   if (projectSelectValue?.__isNew__) {
  //     setSkillAccOngoing(false);
  //     return;
  //   }
  //   if (companySelectValue?.__isNew__) {
  //     setSkillAccOngoing(false);
  //     return;
  //   }

  //   if (!projectSelectValue?.value?.toDate) {
  //     setSkillAccOngoing(true);
  //     setFormValues({ ...formValues, toDate: "" });
  //   } else if (!companySelectValue?.value?.toDate) {
  //     setSkillAccOngoing(true);
  //     setFormValues({ ...formValues, toDate: "" });
  //   } else {
  //     setSkillAccOngoing(false);
  //   }
  // }, [projectSelectValue, companySelectValue, formValues, setFormValues]);

  useEffect(() => {
    if (projectSelectValue && companySelectValue && !projectSelectValue.__isNew__ && !companySelectValue.__isNew__) {
      if (!projectSelectValue.value.toDate || !companySelectValue.value.toDate) {
        setSkillAccOngoing(true);
        setFormValues(prev => ({ ...prev, toDate: "" }));
      } else {
        setSkillAccOngoing(false);
      }
    } else {
      setSkillAccOngoing(false);
    }
  }, [projectSelectValue, companySelectValue, setFormValues]);


  const [valueYesOrNo, setValueYesOrNo] = useState("0");
  /* HANDLE SELECT APPLIED */
  const handleSelectYesOrNo = (e) => {
    const optionYesOrNo = e.target.value;
    setValueYesOrNo(optionYesOrNo);
  };

  useEffect(() => {
    if (!companySelectValue) {
      setEmpOngoing(false);
      return;
    } else {
      //  setnewProject({...newProject,fromDate: timestampToYYYYMMDD(Number(companySelectValue?.value?.fromDate)) })
    }
    if (companySelectValue?.__isNew__) {
      setEmpOngoing(false);
      return;
    }

    if (!companySelectValue?.value?.toDate) {
      setEmpOngoing(true);

      setnewProject({
        ...newProject,
        fromDate: timestampToYYYYMMDD(
          Number(companySelectValue?.value?.fromDate)
        ),
        toDate: "",
      });
    } else {
      setnewProject({
        ...newProject,
        fromDate: timestampToYYYYMMDD(
          Number(companySelectValue?.value?.fromDate)
        ),
        toDate: timestampToYYYYMMDD(Number(companySelectValue?.value?.toDate)),
      });
      setEmpOngoing(false);
    }
  }, [companySelectValue]);
  // useEffect(() => {
  //   if (companySelectValue?.__isNew__) {
  //     setEmpOngoing(false);
  //   } else if (companySelectValue?.value?.toDate) {
  //     setnewProject(prev => ({
  //       ...prev,
  //       fromDate: timestampToYYYYMMDD(Number(companySelectValue.value.fromDate)),
  //       toDate: timestampToYYYYMMDD(Number(companySelectValue.value.toDate))
  //     }));
  //     setEmpOngoing(false);
  //   } else {
  //     setEmpOngoing(true);
  //     setnewProject(prev => ({
  //       ...prev,
  //       fromDate: timestampToYYYYMMDD(Number(companySelectValue.value.fromDate)),
  //       toDate: ""
  //     }));
  //   }
  // }, [companySelectValue]);


  useEffect(() => {
    return () => setValueYesOrNo("0");
  }, [formValues?.mtype]);

  /* FILTER ONLY OWN MTYPE FOR OWN */
  const fileterSuggestionsForOwn = filterLang
    .filter((option) => option.value.mtype === "Own")
    .map((option) => ({
      value: option.value,
      label: option.value.projectActivity,
    }));
  /* FILTER ONLY EMPLOYMENT MTYPE FOR EMPLOYMENT */
  let fileterSuggestionsForEmployment = filterLang
    .filter(
      (option) =>
        option.value.mtype === "Employment" &&
        option.value.organization === companySelectValue?.label
    )
    .map((option) => ({
      value: option.value,
      label: option.value.projectActivity,
    }));

  useEffect(() => { }, [companySelectValue]);

  return (
    <div className="ms-3 " style={{ minHeight: "15rem" }}>
      {formValues?.mtype !== "Employment" && (
        <div className="my-2 ">
          <div class="my-2 ">
            <label for="exampleFormControlInput1" class="form-label">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectTitle"
                ) || {}
              ).mvalue || "nf Project Title"}
              <span className="text-danger"> *</span>
              <span
                className="ms-1 mb-1"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "ProjectDescription"
                    ) || {}
                  ).mvalue || "nf ProjectDescription"
                }
              >
                <BiSolidInfoCircle
                  className="text-muted"
                  style={{ fontSize: "20px" }}
                />
              </span>
            </label>

            <CreateSelect
              newField="projIsnew"
              placeholder={
                (
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "EnterYourProjectName"
                  ) || {}
                ).mvalue || "nf Enter your project title"
              }
              setFormValues={setFormValues}
              formValues={formValues}
              setNewForm={setnewProject}
              NewForm={newProject}
              setNewField="projectActivity"
              options={fileterSuggestionsForOwn}
              value={projectSelectValue}
              setValue={setProjectSelectValue}
              applyEmpFilter={false}
            />
          </div>
        </div>
      )}

      {/*  new form for adding new project   */}
      {(formValues.projIsnew || formValues?.mtype === "Employment") && (
        <>
          <div
            className={`ms-2  me-2 ${formValues?.mtype === "Own" && "px-1 py-1"
              }`}
          >
            <div className="d-flex justify-content-between align-items-center px-2">
              <h3
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ display: formValues.mtype === "Own" ? "" : "none" }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "AddingNewProject"
                  ) || {}
                ).mvalue || "nf Adding new Project"}
              </h3>
              <button
                type="button"
                className={`btn-close ${formValues?.mtype === "Employment" && "d-none"
                  }`}
                onClick={handleProjectClose}
                aria-label="Close"
                id="closeBtn"
              ></button>
            </div>

            <div className="px-2" style={{ marginLeft: "-1rem" }}>
              <div class="form-check form-check-inline my-1 d-none">
                <input
                  class="  "
                  type="radio"
                  name="mtype"
                  id="inlineRadio1"
                  value="Own"
                  // checked={newProject.mtype === "Own"}
                  checked={formValues.mtype === "Own"}
                  onChange={(e) =>
                    setnewProject({
                      ...newProject,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <label class="form-check-label" for="inlineRadio1">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Own"
                    ) || {}
                  ).mvalue || "nf Own"}
                </label>
              </div>
              <div class="form-check form-check-inline d-none">
                <input
                  class="x"
                  type="radio"
                  name="mtype"
                  id="inlineRadio2"
                  value="Employment"
                  // checked={newProject.mtype === "Employment"}
                  checked={formValues.mtype === "Employment"}
                  onChange={(e) =>
                    setnewProject({
                      ...newProject,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <label class="form-check-label" for="inlineRadio2">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Employment"
                    ) || {}
                  ).mvalue || "nf Employment"}
                </label>
              </div>

              {/* ADDING EMPLOYMENT */}
              {(formValues.mtype === "Employment" ||
                newProject?.mtype === "Employment") && (
                  <div>
                    <div class="my-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "NameOfCompany"
                          ) || {}
                        ).mvalue || "nf Name of Company"}
                        <span className="text-danger"> *</span>
                      </label>
                      <CreateSelect
                        newField="orgIsnew"
                        placeholder={
                          (
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "EnterYourCompanyName"
                            ) || {}
                          ).mvalue || "nf Enter your company name"
                        }
                        setFormValues={setFormValues}
                        formValues={formValues}
                        setNewForm={setNewEmployment}
                        NewForm={newEmployment}
                        setNewField="organization"
                        options={filterEmployment}
                        value={companySelectValue}
                        setValue={setCompanySelectValue}
                        applyEmpFilterComp={true}
                        applyEmpFilter={true}
                        setValueYesOrNo={setValueYesOrNo}
                      />
                    </div>

                    {newEmployment.orgIsnew && (
                      <div>
                        {/* new form for adding new Employment   */}
                        <div className="ms-4 me-2  border  px-1 py-1 mb-2   ">
                          <div className="d-flex justify-content-between align-items-center px-2 ">
                            <h3
                              className="modal-title fs-5"
                              id="exampleModalLabel"
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "AddingNewEmployment"
                                ) || {}
                              ).mvalue || "nf Adding new Employment"}
                            </h3>
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={handleEmploymentClose}
                              id="closeBtnEmployee"
                            ></button>
                          </div>

                          <div className="px-2 ">
                            <div class="form-check form-check-inline my-1 d-none">
                              <input
                                class="  "
                                type="radio"
                                name="mtype2"
                                id="emp1"
                                value="Own"
                                checked={newEmployment.mtype === "Own"}
                                onChange={(e) =>
                                  setNewEmployment({
                                    ...newEmployment,
                                    // [e.target.name]: e.target.value,
                                    mtype: e.target.value,
                                  })
                                }
                              />
                              <label class="form-check-label" for="emp1">
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Own"
                                  ) || {}
                                ).mvalue || "nf Own"}
                              </label>
                            </div>
                            <div class="form-check form-check-inline d-none">
                              <input
                                class="  "
                                type="radio"
                                name="mtype2"
                                id="emp2"
                                value="Employment"
                                checked={newEmployment.mtype === "Employment"}
                                onChange={(e) =>
                                  setNewEmployment({
                                    ...newEmployment,
                                    // [e.target.name]: e.target.value,
                                    mtype: e.target.value,
                                  })
                                }
                              />
                              <label class="form-check-label" for="emp2">
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Employment"
                                  ) || {}
                                ).mvalue || "nf Employment"}
                              </label>
                            </div>

                            <div className="d-lg-flex my-2  w-100   ">
                              <div className=" h-75 w-100  ">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label "
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel ===
                                        "EmploymentStartDate"
                                    ) || {}
                                  ).mvalue || "nf Employment Start Date"}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <input
                                type="date"
                                // min={newEmployment.fromDate}
                                max={timestampToYYYYMMDD(Date.now())}
                                style={{ height: "32px" }}
                                className="form-control bg-body-tertiary h-75 "
                                id="exampleFormControlInput1"
                                name="fromDate"
                                onChange={(e) =>
                                  setNewEmployment({
                                    ...newEmployment,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={newEmployment.fromDate}
                              /> */}
                                <DatePicker
                                  style={{ height: "32px" }}
                                  maxDate={timestampToYYYYMMDD(Date.now())}
                                  className={`form-control h-75 buttom-line-input px-0 w-100`}
                                  id="exampleFormControlInput1"
                                  onChange={(e) => setNewEmployment({ ...newEmployment, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                  toggleCalendarOnIconClick
                                  selected={newEmployment?.fromDate ? newEmployment?.fromDate : null}
                                  dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  placeholderText={regionalData?.selectedCountry?.dateFormat}
                                  onBlur={() => { }}
                                />
                              </div>
                              <div className=" ms-lg-2 h-75 w-100  ">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className={
                                    newEmpOngoing
                                      ? "form-label bg-body-tertiary text-secondary  "
                                      : "form-label bg-body-tertiary"
                                  }
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel === "EmploymentEndDate"
                                    ) || {}
                                  ).mvalue || "nf Employment End Date"}{" "}
                                </label>
                                {/* <input
                                  type="date"
                                  min={newEmployment.fromDate}
                                  max={timestampToYYYYMMDD(Date.now())}
                                  style={{ height: "32px" }}
                                  className={
                                    newEmpOngoing
                                      ? "form-control bg-body-tertiary h-75 text-secondary  "
                                      : "form-control bg-body-tertiary h-75 "
                                  }
                                  id="exampleFormControlInput1"
                                  name="toDate"
                                  onChange={(e) =>
                                    setNewEmployment({
                                      ...newEmployment,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  value={newEmployment.toDate}
                                  {...(newEmpOngoing && { disabled: true })}
                                /> */}
                                <DatePicker
                                  id="exampleFormControlInput1"
                                  style={{ height: "32px" }}
                                  className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                  minDate={newEmployment?.fromDate}
                                  maxDate={timestampToYYYYMMDD(Date.now())}
                                  onChange={(e) => setNewEmployment({ ...newEmployment, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                  toggleCalendarOnIconClick
                                  selected={newEmployment?.toDate ? newEmployment?.toDate : null}
                                  dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  placeholderText={regionalData?.selectedCountry?.dateFormat}
                                  disabled={newEmpOngoing}
                                  onBlur={() => { }}
                                />

                                <div
                                  className={
                                    newEmpOngoing
                                      ? "d-flex ms-1 align-items-center font-6 text-secondary   "
                                      : "d-flex ms-1 align-items-center font-6 text-secondary   "
                                  }
                                >
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className=""
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Ongoing"
                                      ) || {}
                                    ).mvalue || "nf Ongoing"}
                                  </label>
                                  <input
                                    className="ms-2 "
                                    type="checkbox"
                                    name="projectEndDate"
                                    checked={newEmpOngoing}
                                    onChange={(e) => {
                                      setNewEmpOngoing(e.target.checked);
                                      setNewEmployment({
                                        ...newEmployment,
                                        toDate: "",
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="my-2 d-flex justify-content-between">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Locations"
                                  ) || {}
                                ).mvalue || "nf Locations"}
                                <span className="text-danger"> *</span>
                              </label>

                              <div className="d-flex align-items-center justify-align-content">
                                <input
                                  id="onlineCheckbox"
                                  className="ms-2"
                                  type="checkbox"
                                  name="online"
                                  checked={newEmploymentOnline}
                                  value={newEmploymentOnline}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setNewEmploymentOnline(isChecked);
                                  }}
                                />
                                <label htmlFor="onlineCheckbox" className="ms-1">
                                  {(
                                    content[selectedLanguage].find(
                                      (item) => item.elementLabel === "Online"
                                    ) || {}
                                  ).mvalue || " nf Online"}
                                </label>
                              </div>
                            </div>
                            <div>
                              <MultiSelect
                                viewLocation={locationForNewEmployment}
                                setLocationData={setLocationForNewEmployment}
                                onlineStatus={newEmploymentOnline}
                              />
                            </div>

                            <div className="my-2 ">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                className="form-label"
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel ===
                                      "BriefDescriptionAboutOrganization"
                                  ) || {}
                                ).mvalue ||
                                  "nf Brief Description about Organization"}
                              </label>
                              <textarea
                                className="form-control bg-body-tertiary"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                onChange={(e) =>
                                  setNewEmployment({
                                    ...newEmployment,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={newEmployment.briefDescriptions}
                                name="briefDescriptions"
                              ></textarea>
                            </div>

                            <div className="d-flex justify-content-end align-items-center mb-1  ">
                              {/* <button
                              type="button"
                              className={`text-white border-0 px-2 py-1 rounded   pill-bg-color  font-5 ${isAddingNewEmployment ? 'disabled' : ''}`}
                              onClick={handleAddNewEmployment}
                              disabled={isAddingNewEmployment}
                            >
                              Add Employment
                            </button> */}
                              <SecondaryBtnLoader
                                label={
                                  (
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel === "AddEmployment"
                                    ) || {}
                                  ).mvalue || "nf Add Employment"
                                }
                                onClick={() => handleAddNewEmployment()}
                                backgroundColor="#815F0B"
                                color="#F8F8E9"
                                loading={isAddingNewEmployment}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {/* IF IT IS EMPLOYMENT AND THE EMPLOYMENT IS NOT THE NEW ONE */}
              {formValues?.mtype === "Employment" &&
                !newProject.orgIsnew &&
                companySelectValue &&
                !companySelectValue.__isNew__ && (
                  <React.Fragment>
                    <select
                      className="form-select mb-3 bg-body-tertiary font-5 "
                      aria-label="Default select example"
                      name="type"
                      defaultValue="0"
                      onChange={handleSelectYesOrNo}
                      style={{ height: "32px" }}
                    >
                      <option
                        className="bg-body-tertiary"
                        value="0"
                        disabled
                        selected
                        hidden
                      >
                        {(
                          content[selectedLanguage].find(
                            (item) =>
                              item.elementLabel ===
                              "DidApplySkillParticularProject"
                          ) || {}
                        ).mvalue ||
                          "Did you apply this skill in a particular Project / Functional area within this employment"}
                        ?
                      </option>
                      <option value="Yes">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Yes"
                          ) || {}
                        ).mvalue || "Yes"}
                      </option>
                      <option value="No">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "No"
                          ) || {}
                        ).mvalue || "No"}
                      </option>
                    </select>

                    {valueYesOrNo === "Yes" && valueYesOrNo !== "0" && (
                      <React.Fragment>
                        <CreateSelect
                          newField="projIsnew"
                          placeholder={
                            (
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "EnterYourProjectName"
                              ) || {}
                            ).mvalue || "nf Enter your project title"
                          }
                          setFormValues={setFormValues}
                          formValues={formValues}
                          setNewForm={setnewProject}
                          NewForm={newProject}
                          setNewField="projectActivity"
                          options={fileterSuggestionsForEmployment}
                          value={projectSelectValue}
                          setValue={setProjectSelectValue}
                          applyEmpFilter={true}
                        />

                        {formValues.projIsnew &&
                          formValues?.mtype === "Employment" && (
                            <React.Fragment>
                              <div class="my-2 ">
                                {/* if project is own then enter locations and date range is wide */}
                                {newProject.mtype === "Own" && (
                                  <>
                                    <div class="my-2 d-flex justify-content-between">
                                      <label
                                        for="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel === "Locations"
                                          ) || {}
                                        ).mvalue || "nf Locations"}
                                        <span className="text-danger"> *</span>
                                      </label>

                                      <div className="d-flex align-items-center justify-align-content">
                                        <input
                                          id="onlineCheckbox"
                                          className="ms-2"
                                          type="checkbox"
                                          name="online"
                                          value={newProjectOnline}
                                          checked={newProjectOnline}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setNewProjectOnline(isChecked);
                                          }}
                                        />
                                        <label
                                          htmlFor="onlineCheckbox"
                                          className="ms-1"
                                        >
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Online"
                                            ) || {}
                                          ).mvalue || " nf Online"}
                                        </label>
                                      </div>
                                    </div>
                                    <MultiSelect
                                      viewLocation={locationForNewProject}
                                      setLocationData={setLocationForNewProject}
                                      onlineStatus={newProjectOnline}
                                    />

                                    <div className="d-lg-flex my-2  w-100   ">
                                      <div className=" h-75 w-100  ">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label "
                                        >
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "ProjectStartDate"
                                            ) || {}
                                          ).mvalue || "nf Project Start Date"}
                                          <span className="text-danger">*</span>
                                        </label>

                                        {/* <input
                                          type="date"
                                          max={timestampToYYYYMMDD(Date.now())}
                                          style={{ height: "32px" }}
                                          className="form-control bg-body-tertiary h-75 "
                                          id="exampleFormControlInput1"
                                          name="fromDate"
                                          onChange={(e) =>
                                            setnewProject({
                                              ...newProject,
                                              [e.target.name]: e.target.value,
                                            })
                                          }
                                          value={newProject.fromDate}
                                        /> */}
                                        <DatePicker
                                          style={{ height: "32px" }}
                                          maxDate={timestampToYYYYMMDD(Date.now())}
                                          className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                          id="exampleFormControlInput1"
                                          onChange={(e) => setnewProject({ ...newProject, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                          toggleCalendarOnIconClick
                                          selected={newProject?.fromDate ? newProject?.fromDate : null}
                                          dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100}
                                          placeholderText={regionalData?.selectedCountry?.dateFormat}
                                          onBlur={() => { }}
                                        />
                                      </div>
                                      <div className=" ms-lg-2 h-75 w-100  ">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className={
                                            onGoing
                                              ? "form-label bg-body-tertiary text-secondary  "
                                              : "form-label bg-body-tertiary"
                                          }
                                        >
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "ProjectEndDate"
                                            ) || {}
                                          ).mvalue ||
                                            "nf Project End Date"}{" "}
                                        </label>

                                        {/* <input
                                          type="date"
                                          min={newProject.fromDate}
                                          max={timestampToYYYYMMDD(Date.now())}
                                          style={{ height: "32px" }}
                                          className={
                                            onGoing
                                              ? "form-control bg-body-tertiary h-75 text-secondary  "
                                              : "form-control bg-body-tertiary h-75 "
                                          }
                                          id="exampleFormControlInput1"
                                          name="toDate"
                                          onChange={(e) =>
                                            setnewProject({
                                              ...newProject,
                                              [e.target.name]: e.target.value,
                                            })
                                          }
                                          value={newProject.toDate}
                                          {...(onGoing && { disabled: true })}
                                        /> */}
                                        <DatePicker
                                          id="exampleFormControlInput1"
                                          style={{ height: "32px" }}
                                          className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                          minDate={newProject?.fromDate}
                                          maxDate={timestampToYYYYMMDD(Date.now())}
                                          onChange={(e) => setnewProject({ ...newProject, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                          toggleCalendarOnIconClick
                                          selected={newProject?.toDate ? newProject?.toDate : null}
                                          dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100}
                                          placeholderText={regionalData?.selectedCountry?.dateFormat}
                                          disabled={onGoing}
                                          onBlur={() => { }}
                                        />
                                        <div
                                          className={
                                            onGoing
                                              ? "d-flex ms-1 align-items-center font-6 text-secondary   "
                                              : "d-flex ms-1 align-items-center font-6 text-secondary   "
                                          }
                                        >
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className=""
                                          >
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "CurrentProject"
                                              ) || {}
                                            ).mvalue ||
                                              "nf Current Project"}{" "}
                                          </label>
                                          <input
                                            className="ms-2 "
                                            type="checkbox"
                                            name="projectEndDate"
                                            checked={onGoing}
                                            onChange={(e) => {
                                              setOnGoing(e.target.checked);
                                              if (e.target.checked) {
                                                setnewProject({
                                                  ...newProject,
                                                  toDate: "",
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {/* if project is Emplpyment then show selected company location  and 
                      date range should be between company date range */}
                                {newProject.mtype === "Employment" && (
                                  <>
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Locations"
                                        ) || {}
                                      ).mvalue || "nf Locations"}
                                      <span className="text-danger me-2">
                                        {" "}
                                        *
                                      </span>
                                      <SecondaryBtn
                                        label="reset"
                                        onClick={() => {
                                          setLocationForEmployment(
                                            companySelectValue?.value?.location.split(
                                              ","
                                            )
                                          );
                                        }}
                                        backgroundColor="#F8F8E9"
                                        color="#815F0B"
                                      />
                                    </label>

                                    <div className="d-flex gap-2">
                                      {locationForEmployment?.map((loc) => {
                                        return (
                                          <div className="p-1  rounded bg-light border">
                                            {loc}
                                            <span
                                              className="text-muted px-1"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                let newLocations =
                                                  locationForEmployment.filter(
                                                    (location) => {
                                                      return location !== loc;
                                                    }
                                                  );
                                                setLocationForEmployment(
                                                  newLocations
                                                );
                                              }}
                                            >
                                              <RxCross2 />
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div className="d-lg-flex my-2  w-100   ">
                                      <div className=" h-75 w-100  ">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label "
                                        >
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "ProjectStartDate"
                                            ) || {}
                                          ).mvalue || "nf Project Start Date"}
                                          <span className="text-danger">*</span>
                                        </label>

                                        {/* <input
                                          type="date"
                                          min={timestampToYYYYMMDD(
                                            Number(
                                              companySelectValue?.value
                                                ?.fromDate
                                            )
                                          )}
                                          max={
                                            companySelectValue?.value?.toDate
                                              ? timestampToYYYYMMDD(
                                                Number(
                                                  companySelectValue?.value
                                                    ?.toDate
                                                )
                                              )
                                              : ""
                                          }
                                          // defaultValue={timestampToYYYYMMDD(Number(companySelectValue?.value?.fromDate))}

                                          style={{ height: "32px" }}
                                          className="form-control bg-body-tertiary h-75 "
                                          id="exampleFormControlInput1"
                                          name="fromDate"
                                          onChange={(e) =>
                                            setnewProject({
                                              ...newProject,
                                              [e.target.name]: e.target.value,
                                            })
                                          }
                                          value={newProject.fromDate}
                                        /> */}
                                        <DatePicker
                                          id="exampleFormControlInput1"
                                          style={{ height: "32px" }}
                                          className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                          minDate={timestampToYYYYMMDD(
                                            Number(
                                              companySelectValue?.value
                                                ?.fromDate
                                            )
                                          )}
                                          maxDate={companySelectValue?.value?.toDate
                                            ? timestampToYYYYMMDD(
                                              Number(
                                                companySelectValue?.value
                                                  ?.toDate
                                              )
                                            )
                                            : ""}
                                          onChange={(e) => setnewProject({ ...newProject, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                          toggleCalendarOnIconClick
                                          selected={newProject?.fromDate ? newProject?.fromDate : null}
                                          dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100}
                                          placeholderText={regionalData?.selectedCountry?.dateFormat}
                                          onBlur={() => { }}
                                        />
                                      </div>
                                      <div className=" ms-lg-2 h-75 w-100  ">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className={
                                            onGoing
                                              ? "form-label bg-body-tertiary text-secondary  "
                                              : "form-label bg-body-tertiary"
                                          }
                                        >
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "ProjectEndDate"
                                            ) || {}
                                          ).mvalue ||
                                            "nf Project End Date"}{" "}
                                        </label>

                                        {/* <input
                                          type="date"
                                          min={newProject.fromDate}
                                          max={
                                            companySelectValue?.value?.toDate
                                              ? timestampToYYYYMMDD(
                                                Number(
                                                  companySelectValue?.value
                                                    ?.toDate
                                                )
                                              )
                                              : timestampToYYYYMMDD(Date.now())
                                          }
                                          // max={
                                          //   newProject.toDate
                                          //     ? newProject.toDate
                                          //     : new Date().getDate()
                                          // }
                                          style={{ height: "32px" }}
                                          className={
                                            empOngoing
                                              ? "form-control bg-body-tertiary h-75 text-secondary  "
                                              : "form-control bg-body-tertiary h-75 "
                                          }
                                          id="exampleFormControlInput1"
                                          name="toDate"
                                          onChange={(e) =>
                                            setnewProject({
                                              ...newProject,
                                              [e.target.name]: e.target.value,
                                            })
                                          }
                                          value={newProject.toDate}
                                          {...(empOngoing && {
                                            disabled: true,
                                          })}
                                        /> */}
                                        <DatePicker
                                          id="exampleFormControlInput1"
                                          style={{ height: "32px" }}
                                          className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                          minDate={newProject?.fromDate}
                                          maxDate={companySelectValue?.value?.toDate
                                            ? timestampToYYYYMMDD(
                                              Number(
                                                companySelectValue?.value
                                                  ?.toDate
                                              )
                                            )
                                            : timestampToYYYYMMDD(Date.now())}
                                          onChange={(e) => setnewProject({ ...newProject, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                          toggleCalendarOnIconClick
                                          selected={newProject?.toDate ? newProject?.toDate : null}
                                          dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100}
                                          placeholderText={regionalData?.selectedCountry?.dateFormat}
                                          disabled={empOngoing}
                                          onBlur={() => { }}
                                        />

                                        {!companySelectValue?.value?.toDate && (
                                          <div
                                            className={
                                              empOngoing
                                                ? "d-flex ms-1 align-items-center font-6 text-secondary   "
                                                : "d-flex ms-1 align-items-center font-6 text-secondary   "
                                            }
                                          >
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className=""
                                            >
                                              {(
                                                content[selectedLanguage].find(
                                                  (item) =>
                                                    item.elementLabel ===
                                                    "CurrentProject"
                                                ) || {}
                                              ).mvalue ||
                                                "nf Current Project"}{" "}
                                            </label>
                                            <input
                                              className="ms-2 "
                                              type="checkbox"
                                              name="projectEndDate"
                                              checked={empOngoing}
                                              onChange={(e) => {
                                                setEmpOngoing(e.target.checked);
                                                if (e.target.checked) {
                                                  setnewProject({
                                                    ...newProject,
                                                    toDate: "",
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="my-2 ">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel ===
                                        "BriefDescriptionAboutProject"
                                    ) || {}
                                  ).mvalue ||
                                    "nf Brief Description about Project"}
                                </label>
                                <textarea
                                  className="form-control bg-body-tertiary"
                                  id="exampleFormControlTextarea1"
                                  rows="2"
                                  onChange={(e) =>
                                    setnewProject({
                                      ...newProject,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  value={newProject.briefDescriptions}
                                  name="briefDescriptions"
                                ></textarea>
                              </div>

                              <div className="d-flex justify-content-end align-items-center mb-1">
                                <SecondaryBtnLoader
                                  label={
                                    (
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "AddProject"
                                      ) || {}
                                    ).mvalue || "nf Add Project"
                                  }
                                  onClick={() => handleAddNewProject()}
                                  backgroundColor="#815F0B"
                                  color="#F8F8E9"
                                  loading={isAddingNewProject}
                                />
                              </div>
                            </React.Fragment>
                          )}
                      </React.Fragment>
                    )}
                    {valueYesOrNo === "No" && (
                      <React.Fragment>
                        <div class="my-2 ">
                          {/* if project is Emplpyment then show selected company location  and 
                      date range should be between company date range */}
                          {formValues?.mtype === "Employment" && (
                            <>
                              <div>
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ProjectName"
                                  ) || {}
                                ).mvalue || "nf ProjectName"}
                                <input
                                  type="text"
                                  name="projectActivity"
                                  className="form-control text-dark mt-2"
                                  style={{ borderColor: "#ccc" }}
                                  placeholder={
                                    (
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel ===
                                          "EnterYourProjectName"
                                      ) || {}
                                    ).mvalue || "nf Enter your work description"
                                  }
                                  value={workDesc}
                                  onChange={(e) => setWorkDesc(e.target.value)}
                                />
                              </div>
                              <label
                                for="exampleFormControlInput1"
                                class="form-label mt-1"
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Locations"
                                  ) || {}
                                ).mvalue || "nf Locations"}
                                <span className="text-danger me-2"> *</span>
                                <SecondaryBtn
                                  label="reset"
                                  onClick={() => {
                                    setLocationForEmployment(
                                      companySelectValue?.value?.location.split(
                                        ","
                                      )
                                    );
                                  }}
                                  backgroundColor="#F8F8E9"
                                  color="#815F0B"
                                />
                              </label>

                              <div className="d-flex gap-2">
                                {locationForEmployment?.map((loc) => {
                                  return (
                                    <div className="p-1  rounded bg-light border">
                                      {loc}
                                      <span
                                        className="text-muted px-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          let newLocations =
                                            locationForEmployment.filter(
                                              (location) => {
                                                return location !== loc;
                                              }
                                            );
                                          setLocationForEmployment(
                                            newLocations
                                          );
                                        }}
                                      >
                                        <RxCross2 />
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="d-lg-flex my-2  w-100   ">
                                <div className=" h-75 w-100  ">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label "
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel ===
                                          "SkillAppliedStart"
                                      ) || {}
                                    ).mvalue || "nf Skill Start Date"}
                                    <span className="text-danger">*</span>
                                  </label>

                                  {/* <input
                                    type="date"
                                    min={timestampToYYYYMMDD(
                                      Number(
                                        companySelectValue?.value?.fromDate
                                      )
                                    )}
                                    max={
                                      companySelectValue?.value?.toDate
                                        ? timestampToYYYYMMDD(
                                          Number(
                                            companySelectValue?.value?.toDate
                                          )
                                        )
                                        : ""
                                    }
                                    style={{ height: "32px" }}
                                    className="form-control bg-body-tertiary h-75 "
                                    id="exampleFormControlInput1"
                                    name="fromDate"
                                    onChange={(e) =>
                                      setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    value={formValues.fromDate}
                                  /> */}
                                  <DatePicker
                                    id="exampleFormControlInput1"
                                    style={{ height: "32px" }}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    minDate={timestampToYYYYMMDD(
                                      Number(
                                        companySelectValue?.value?.fromDate
                                      )
                                    )}
                                    maxDate={companySelectValue?.value?.toDate
                                      ? timestampToYYYYMMDD(
                                        Number(
                                          companySelectValue?.value?.toDate
                                        )
                                      )
                                      : ""}
                                    onChange={(e) => setFormValues({ ...formValues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formValues?.fromDate ? formValues?.fromDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    onBlur={() => { }}
                                  />
                                </div>
                                <div className=" ms-lg-2 h-75 w-100  ">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className={
                                      onGoing
                                        ? "form-label bg-body-tertiary text-secondary  "
                                        : "form-label bg-body-tertiary"
                                    }
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel ===
                                          "SkillAppliedEnd"
                                      ) || {}
                                    ).mvalue || "nf Skill End Date"}{" "}
                                  </label>
                                  {/* <input
                                    type="date"
                                    min={
                                      formValues?.fromDate
                                        ? formValues?.fromDate
                                        : ""
                                    }
                                    max={
                                      companySelectValue?.value?.toDate
                                        ? timestampToYYYYMMDD(
                                          Number(
                                            companySelectValue?.value?.toDate
                                          )
                                        )
                                        : timestampToYYYYMMDD(Date.now())
                                    }
                                    style={{
                                      height: "32px",
                                      opacity: skillAccOngoing ? ".5" : "1",
                                    }}
                                    className={
                                      skillAccOngoing
                                        ? "form-control bg-body-tertiary h-75 text-secondary  "
                                        : "form-control bg-body-tertiary h-75 "
                                    }
                                    id="exampleFormControlInput1"
                                    name="toDate"
                                    onChange={(e) =>
                                      setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    value={formValues.toDate}
                                    {...(skillAccOngoing && { disabled: true })}
                                  /> */}
                                  <DatePicker
                                    id="exampleFormControlInput1"
                                    style={{ height: "32px" }}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    minDate={formValues?.fromDate
                                      ? formValues?.fromDate
                                      : ""}
                                    maxDate={companySelectValue?.value?.toDate
                                      ? timestampToYYYYMMDD(
                                        Number(
                                          companySelectValue?.value?.toDate
                                        )
                                      )
                                      : timestampToYYYYMMDD(Date.now())}
                                    onChange={(e) => setFormValues({ ...formValues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formValues?.toDate ? formValues?.toDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    disabled={skillAccOngoing}
                                    onBlur={() => { }}
                                  />
                                  {!companySelectValue?.value?.toDate && (
                                    <div
                                      className={
                                        skillAccOngoing
                                          ? "d-flex ms-1 align-items-center font-6 text-secondary"
                                          : "d-flex ms-1 align-items-center font-6 text-secondary"
                                      }
                                    >
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className=""
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "CurrentProject"
                                          ) || {}
                                        ).mvalue || "nf Current Project"}{" "}
                                      </label>
                                      <input
                                        className="ms-2"
                                        type="checkbox"
                                        name="projectEndDate"
                                        checked={skillAccOngoing}
                                        onChange={(e) => {
                                          setSkillAccOngoing(!skillAccOngoing);
                                          setFormValues({
                                            ...formValues,
                                            toDate: "",
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="my-2 ">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "BriefDescriptionAboutProject"
                              ) || {}
                            ).mvalue || "nf Brief Description about Project"}
                          </label>
                          <textarea
                            className="form-control bg-body-tertiary"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={formValues.briefDescriptions}
                            name="briefDescriptions"
                          ></textarea>
                        </div>

                        <div className="d-flex justify-content-end align-items-baseline">
                          <div className="d-flex gap-2">
                            <SecondaryBtnLoader
                              label={
                                (
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Save"
                                  ) || {}
                                ).mvalue || "Save"
                              }
                              onClick={() => handleSubmitDate(false)}
                              backgroundColor="#F8F8E9"
                              color="#815F0B"
                              loading={isAddingproject}
                            />
                            <SecondaryBtnLoader
                              label={
                                (
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Save&Close"
                                  ) || {}
                                ).mvalue || "Save & Close"
                              }
                              onClick={() => handleSubmitDate(true)}
                              backgroundColor="#815F0B"
                              color="#F8F8E9"
                              loading={isAddingproject}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

              {/* remaining things for new project  */}
              {newProject.mtype === "Own" && formValues?.mtype === "Own" && (
                <div>
                  <div class="my-2 ">
                    {/* if project is own then enter locations and date range is wide */}
                    {newProject.mtype === "Own" && (
                      <>
                        <div class="my-2 d-flex justify-content-between">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "nf Locations"}
                            <span className="text-danger"> *</span>
                          </label>

                          <div className="d-flex align-items-center justify-align-content">
                            <input
                              id="onlineCheckbox"
                              className="ms-2"
                              type="checkbox"
                              name="online"
                              value={newProjectOnline}
                              checked={newProjectOnline}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setNewProjectOnline(isChecked);
                              }}
                            />
                            <label htmlFor="onlineCheckbox" className="ms-1">
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "Online"
                                ) || {}
                              ).mvalue || " nf Online"}
                            </label>
                          </div>
                        </div>
                        <MultiSelect
                          viewLocation={locationForNewProject}
                          setLocationData={setLocationForNewProject}
                          onlineStatus={newProjectOnline}
                        />

                        <div className="d-lg-flex my-2  w-100   ">
                          <div className=" h-75 w-100  ">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label "
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectStartDate"
                                ) || {}
                              ).mvalue || "nf Project Start Date"}
                              <span className="text-danger">*</span>
                            </label>

                            {/* <input
                              type="date"
                              max={timestampToYYYYMMDD(Date.now())}
                              style={{ height: "32px" }}
                              className="form-control bg-body-tertiary h-75 "
                              id="exampleFormControlInput1"
                              name="fromDate"
                              onChange={(e) =>
                                setnewProject({
                                  ...newProject,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={newProject.fromDate}
                            /> */}
                            <DatePicker
                              style={{ height: "32px" }}
                              maxDate={timestampToYYYYMMDD(Date.now())}
                              className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                              id="exampleFormControlInput1"
                              onChange={(e) => setnewProject({ ...newProject, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                              toggleCalendarOnIconClick
                              selected={newProject?.fromDate ? newProject?.fromDate : null}
                              dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText={regionalData?.selectedCountry?.dateFormat}
                              onBlur={() => { }}
                            />
                          </div>
                          <div className=" ms-lg-2 h-75 w-100  ">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className={
                                onGoing
                                  ? "form-label bg-body-tertiary text-secondary  "
                                  : "form-label bg-body-tertiary"
                              }
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectEndDate"
                                ) || {}
                              ).mvalue || "nf Project End Date"}{" "}
                            </label>

                            {/* <input
                              type="date"
                              min={newProject.fromDate}
                              // max={
                              //   newProject.toDate
                              //     ? newProject.toDate
                              //     : new Date().getDate()
                              // }
                              max={timestampToYYYYMMDD(Date.now())}
                              style={{ height: "32px" }}
                              className={
                                onGoing
                                  ? "form-control bg-body-tertiary h-75 text-secondary  "
                                  : "form-control bg-body-tertiary h-75 "
                              }
                              id="exampleFormControlInput1"
                              name="toDate"
                              onChange={(e) =>
                                setnewProject({
                                  ...newProject,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={newProject.toDate}
                              {...(onGoing && { disabled: true })}
                            /> */}
                            <DatePicker
                              id="exampleFormControlInput1"
                              style={{ height: "32px" }}
                              className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                              minDate={newProject?.fromDate}
                              maxDate={timestampToYYYYMMDD(Date.now())}
                              onChange={(e) => setnewProject({ ...newProject, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                              toggleCalendarOnIconClick
                              selected={newProject?.toDate ? newProject?.toDate : null}
                              dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText={regionalData?.selectedCountry?.dateFormat}
                              disabled={onGoing}
                              onBlur={() => { }}
                            />
                            <div
                              className={
                                onGoing
                                  ? "d-flex ms-1 align-items-center font-6 text-secondary   "
                                  : "d-flex ms-1 align-items-center font-6 text-secondary   "
                              }
                            >
                              <label
                                htmlFor="exampleFormControlInput1"
                                className=""
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "CurrentProject"
                                  ) || {}
                                ).mvalue || "nf Current Project"}{" "}
                              </label>
                              <input
                                className="ms-2 "
                                type="checkbox"
                                name="projectEndDate"
                                checked={onGoing}
                                onChange={(e) => {
                                  setOnGoing(e.target.checked);
                                  if (e.target.checked) {
                                    setnewProject({
                                      ...newProject,
                                      toDate: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* if project is Emplpyment then show selected company location  and 
                      date range should be between company date range */}
                    {newProject.mtype === "Employment" && (
                      <>
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Locations"
                            ) || {}
                          ).mvalue || "nf Locations"}
                          <span className="text-danger me-2"> *</span>
                          <SecondaryBtn
                            label="reset"
                            onClick={() => {
                              setLocationForEmployment(
                                companySelectValue?.value?.location.split(",")
                              );
                            }}
                            backgroundColor="#F8F8E9"
                            color="#815F0B"
                          />
                        </label>

                        <div className="d-flex gap-2">
                          {locationForEmployment?.map((loc) => {
                            return (
                              <div className="p-1  rounded bg-light border">
                                {loc}
                                <span
                                  className="text-muted px-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let newLocations =
                                      locationForEmployment.filter(
                                        (location) => {
                                          return location !== loc;
                                        }
                                      );
                                    setLocationForEmployment(newLocations);
                                  }}
                                >
                                  <RxCross2 />
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        <div className="d-lg-flex my-2  w-100   ">
                          <div className=" h-75 w-100  ">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label "
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectStartDate"
                                ) || {}
                              ).mvalue || "nf Project Start Date"}
                              <span className="text-danger">*</span>
                            </label>

                            {/* <input
                              type="date"
                              min={timestampToYYYYMMDD(
                                Number(companySelectValue?.value?.fromDate)
                              )}
                              max={
                                companySelectValue?.value?.toDate
                                  ? timestampToYYYYMMDD(
                                    Number(companySelectValue?.value?.toDate)
                                  )
                                  : ""
                              }
                              // defaultValue={timestampToYYYYMMDD(Number(companySelectValue?.value?.fromDate))}

                              style={{ height: "32px" }}
                              className="form-control bg-body-tertiary h-75 "
                              id="exampleFormControlInput1"
                              name="fromDate"
                              onChange={(e) =>
                                setnewProject({
                                  ...newProject,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={newProject.fromDate}
                            /> */}
                            <DatePicker
                              id="exampleFormControlInput1"
                              style={{ height: "32px" }}
                              className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                              minDate={timestampToYYYYMMDD(
                                Number(companySelectValue?.value?.fromDate)
                              )}
                              maxDate={companySelectValue?.value?.toDate
                                ? timestampToYYYYMMDD(
                                  Number(companySelectValue?.value?.toDate)
                                )
                                : ""}
                              onChange={(e) => setnewProject({ ...newProject, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                              toggleCalendarOnIconClick
                              selected={newProject?.fromDate ? newProject?.fromDate : null}
                              dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText={regionalData?.selectedCountry?.dateFormat}
                              onBlur={() => { }}
                            />
                          </div>
                          <div className=" ms-lg-2 h-75 w-100  ">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className={
                                onGoing
                                  ? "form-label bg-body-tertiary text-secondary  "
                                  : "form-label bg-body-tertiary"
                              }
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectEndDate"
                                ) || {}
                              ).mvalue || "nf Project End Date"}{" "}
                            </label>

                            {/* <input
                              type="date"
                              min={newProject.fromDate}
                              max={
                                companySelectValue?.value?.toDate
                                  ? timestampToYYYYMMDD(
                                    Number(companySelectValue?.value?.toDate)
                                  )
                                  : timestampToYYYYMMDD(Date.now())
                              }
                              // max={
                              //   newProject.toDate
                              //     ? newProject.toDate
                              //     : new Date().getDate()
                              // }
                              style={{ height: "32px" }}
                              className={
                                empOngoing
                                  ? "form-control bg-body-tertiary h-75 text-secondary  "
                                  : "form-control bg-body-tertiary h-75 "
                              }
                              id="exampleFormControlInput1"
                              name="toDate"
                              onChange={(e) =>
                                setnewProject({
                                  ...newProject,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={newProject.toDate}
                              {...(empOngoing && { disabled: true })}
                            /> */}
                            <DatePicker
                              id="exampleFormControlInput1"
                              style={{ height: "32px" }}
                              className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                              minDate={newProject?.fromDate}
                              maxDate={companySelectValue?.value?.toDate
                                ? timestampToYYYYMMDD(
                                  Number(companySelectValue?.value?.toDate)
                                )
                                : timestampToYYYYMMDD(Date.now())}
                              onChange={(e) => setnewProject({ ...newProject, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                              toggleCalendarOnIconClick
                              selected={newProject?.toDate ? newProject?.toDate : null}
                              dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText={regionalData?.selectedCountry?.dateFormat}
                              disabled={empOngoing}
                              onBlur={() => { }}
                            />

                            {!companySelectValue?.value?.toDate && (
                              <div
                                className={
                                  empOngoing
                                    ? "d-flex ms-1 align-items-center font-6 text-secondary   "
                                    : "d-flex ms-1 align-items-center font-6 text-secondary   "
                                }
                              >
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className=""
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel === "CurrentProject"
                                    ) || {}
                                  ).mvalue || "nf Current Project"}{" "}
                                </label>
                                <input
                                  className="ms-2 "
                                  type="checkbox"
                                  name="projectEndDate"
                                  checked={empOngoing}
                                  onChange={(e) => {
                                    setEmpOngoing(e.target.checked);
                                    if (e.target.checked) {
                                      setnewProject({
                                        ...newProject,
                                        toDate: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="my-2 ">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "BriefDescriptionAboutProject"
                        ) || {}
                      ).mvalue || "nf Brief Description about Project"}
                    </label>
                    <textarea
                      className="form-control bg-body-tertiary"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      onChange={(e) =>
                        setnewProject({
                          ...newProject,
                          [e.target.name]: e.target.value,
                        })
                      }
                      value={newProject.briefDescriptions}
                      name="briefDescriptions"
                    ></textarea>
                  </div>

                  <div className="d-flex justify-content-end align-items-center mb-1  ">
                    {/* <button
                        type="button"
                        className={`text-white d-flex gap-1 justify-content-center align-items-center border-0 px-2 py-1 rounded   pill-bg-color  font-5  ${isAddingNewProject ? 'disabled' : ''}`}
                        onClick={handleAddNewProject}
                        disabled={isAddingNewProject}
                      >
                        {
                          isAddingNewProject && <SmallLoader height={'15px'} width={'15px'} bg={'white'} />
                        }
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "AddProject"
                          ) || {}
                        ).mvalue || "not found"}
                      </button> */}

                    <SecondaryBtnLoader
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "AddProject"
                          ) || {}
                        ).mvalue || "nf Add Project"
                      }
                      onClick={() => handleAddNewProject()}
                      backgroundColor="#815F0B"
                      color="#F8F8E9"
                      loading={isAddingNewProject}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* remaining things for adding existing project */}
      {(companySelectValue !== null &&
        formValues?.mtype === "Employment" &&
        valueYesOrNo !== "0" &&
        valueYesOrNo !== "No" &&
        projectSelectValue !== null &&
        !formValues.projIsnew) ||
        (!formValues.projIsnew &&
          formValues.projectActivity &&
          projectSelectValue !== null &&
          (formValues?.mtype === "Own" || formValues === "0")) ? (
        <>
          <div className="d-lg-flex my-2  w-100   ">
            <div className=" h-75 w-100  ">
              <label htmlFor="exampleFormControlInput1" className="form-label ">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "SkillAppliedStart"
                  ) || {}
                ).mvalue || "nf Skill Start Date"}
                <span className="text-danger">*</span>
              </label>

              {/* <input
                type="date"
                min={timestampToYYYYMMDD(
                  Number(projectSelectValue?.value?.fromDate)
                )}
                max={timestampToYYYYMMDD(
                  Number(projectSelectValue?.value?.toDate)
                )}
                value={formValues.fromDate}
                style={{ height: "32px" }}
                className="form-control bg-body-tertiary h-75 "
                id="exampleFormControlInput1"
                name="fromDate"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              /> */}
              <DatePicker
                style={{ height: "32px" }}
                minDate={timestampToYYYYMMDD(
                  Number(projectSelectValue?.value?.fromDate)
                )}
                maxDate={timestampToYYYYMMDD(
                  Number(projectSelectValue?.value?.toDate)
                )}
                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                id="exampleFormControlInput1"
                onChange={(e) => setFormValues({ ...formValues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                toggleCalendarOnIconClick
                selected={formValues?.fromDate ? formValues?.fromDate : null}
                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText={regionalData?.selectedCountry?.dateFormat}
                onBlur={() => { }}
              />
            </div>
            <div className=" ms-lg-2 h-75 w-100  ">
              <label
                htmlFor="exampleFormControlInput1"
                className={"form-label bg-body-tertiary  "}
                style={{
                  opacity:
                    !projectSelectValue?.value?.toDate && skillAccOngoing
                      ? ".5"
                      : "1",
                }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "SkillAppliedEnd"
                  ) || {}
                ).mvalue || "nf Skill End Date"}{" "}
              </label>

              {/* <input
                type="date"
                value={formValues.toDate}
                min={formValues.fromDate ? formValues.fromDate : ""}
                max={
                  projectSelectValue?.value?.toDate
                    ? timestampToYYYYMMDD(
                      Number(projectSelectValue?.value?.toDate)
                    )
                    : timestampToYYYYMMDD(Date.now())
                }
                style={{
                  height: "32px",
                  opacity:
                    !projectSelectValue?.value?.toDate && skillAccOngoing
                      ? ".5"
                      : "1",
                }}
                className={"form-control bg-body-tertiary h-75   "}
                id="exampleFormControlInput1"
                name="toDate"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
                {...(!projectSelectValue?.value?.toDate &&
                  skillAccOngoing && { disabled: true })}
              /> */}
              <DatePicker
                id="exampleFormControlInput1"
                style={{ height: "32px" }}
                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                minDate={formValues.fromDate ? formValues.fromDate : ""}
                maxDate={projectSelectValue?.value?.toDate
                  ? timestampToYYYYMMDD(
                    Number(projectSelectValue?.value?.toDate)
                  )
                  : timestampToYYYYMMDD(Date.now())}
                onChange={(e) => setnewProject({ ...formValues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                toggleCalendarOnIconClick
                selected={formValues?.toDate ? formValues?.toDate : null}
                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText={regionalData?.selectedCountry?.dateFormat}
                disabled={skillAccOngoing}
                onBlur={() => { }}
              />

              {!projectSelectValue?.value?.toDate && (
                <div
                  className={
                    "d-flex ms-1 align-items-center font-6 text-secondary   "
                  }
                >
                  <label htmlFor="exampleFormControlInput1" className="">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "CurrentProject"
                      ) || {}
                    ).mvalue || "nf Current Project"}{" "}
                  </label>
                  <input
                    className="ms-2 "
                    type="checkbox"
                    name="skillAccProject"
                    checked={skillAccOngoing}
                    onChange={(e) => {
                      setSkillAccOngoing(!skillAccOngoing);
                      setFormValues({ ...formValues, toDate: "" });
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div class="my-2 ">
            <label for="exampleFormControlInput1" class="form-label">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Locations"
                ) || {}
              ).mvalue || "nf Locations"}
              <span className="text-danger me-2"> *</span>

              <SecondaryBtn
                label="reset"
                onClick={() => {
                  setLocationForProject(
                    projectSelectValue?.value?.location.split(",")
                  );
                }}
                backgroundColor="#F8F8E9"
                color="#815F0B"
              />
            </label>

            {/* <LocationSelect /> */}
            {/* <MultiSelect setLocationData={setLocationData} /> */}

            <div className="d-flex gap-2">
              {locationForProject?.map((loc) => {
                return (
                  <div className="p-1  rounded bg-light border">
                    {loc}
                    <span
                      className="text-muted px-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newLocations = locationForProject.filter(
                          (location) => {
                            return location !== loc;
                          }
                        );
                        setLocationForProject(newLocations);
                      }}
                    >
                      <RxCross2 />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="my-2 ">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              {(
                content[selectedLanguage].find(
                  (item) =>
                    item.elementLabel ===
                    "BriefDescriptionAboutSkillAppliedInProject"
                ) || {}
              ).mvalue || "nf Brief Description about skill applied in project"}
            </label>
            <textarea
              className="form-control bg-body-tertiary"
              id="exampleFormControlTextarea1"
              rows="2"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                })
              }
              value={formValues.briefDescriptions}
              name="briefDescriptions"
            ></textarea>
          </div>
        </>
      ) : null}

      {/* buttons to add project attach and validation */}
      {(companySelectValue !== null &&
        formValues?.mtype === "Employment" &&
        valueYesOrNo !== "No" &&
        valueYesOrNo !== "0" &&
        projectSelectValue !== null &&
        !formValues.projIsnew) ||
        (!formValues.projIsnew &&
          !newProject.orgIsnew &&
          formValues.projectActivity &&
          projectSelectValue !== null &&
          (formValues?.mtype === "Own" || formValues === "0")) ? (
        <div>
          <div className="d-flex justify-content-between align-items-baseline">
            {/* <div>
              <SecondaryBtn
                label="Validate this project"
                onClick={handleValidateProject}
                backgroundColor="#F8F8E9"
                color="#815F0B"
              />
              <div id="emailHelp" class="form-text">
                <a href="/">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequireValidationSupport"
                    ) || {}
                  ).mvalue || "not found"}
                </a>
              </div>
            </div> */}
            {/* <div>
              <div>
                <SecondaryBtn
                  label="Attach related documents"
                  onClick={() => setFileupload(true)}
                  backgroundColor="#F8F8E9"
                  color="#815F0B"
                />
              </div>
            </div> */}
          </div>

          <div className="modal-footer d-flex justify-content-end py-0 my-0">
            {/* <div className="d-flex gap-2  ">

              <button className="d-flex btn py-1 px-2 gap-1" onClick={() => handleSubmitDate(false)} style={{ backgroundColor: "#F8F8E9", color: "#815F0B", border: '1px solid #815F0B', fontSize: '12px' }} disabled={isAddingproject}>
                {
                  isAddingproject && <SmallLoader height={'15px'} width={'15px'} bg={'white'} />
                }
                Save</button>

              <button className="d-flex btn py-1 px-2 gap-1" onClick={() => handleSubmitDate(true)} style={{ backgroundColor: "#815F0B", color: "#fff", fontSize: '12px' }} disabled={isAddingproject}>
                {
                  isAddingproject && <SmallLoader height={'15px'} width={'15px'} bg={'white'} />
                }
                Save & Close</button>
            </div> */}

            <div className="d-flex gap-2">
              <SecondaryBtnLoader
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Save"
                    ) || {}
                  ).mvalue || "Save"
                }
                onClick={() => handleSubmitDate(false)}
                backgroundColor="#F8F8E9"
                color="#815F0B"
                loading={isAddingproject}
              />
              <SecondaryBtnLoader
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Save&Close"
                    ) || {}
                  ).mvalue || "Save & Close"
                }
                onClick={() => handleSubmitDate(true)}
                backgroundColor="#815F0B"
                color="#F8F8E9"
                loading={isAddingproject}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProjectForm;
