import React, { useEffect, useState } from 'react'
import SecondaryBtn from '../../Buttons/SecondaryBtn'
import MultiSelect from '../SelectComponent/MultiSelect';
import { timestampToYYYYMMDD } from '../HelperFunction/timestampToYYYYMMDD';
import PostApi from '../../../api/PostData/PostApi';
import { addNewCertification } from '../../../reducer/detailedProfile/certificationSlice';
import { useDispatch } from 'react-redux';
import { convertDateToMilliseconds } from '../HelperFunction/convertDateToMilliseconds';
import { showSuccessToast } from '../../ToastNotification/showSuccessToast';
import { showErrorToast } from '../../ToastNotification/showErrorToast';
import CreateSelect from '../SelectComponent/CreateSelect';
import { useSelector } from 'react-redux';
import { RxCross2 } from 'react-icons/rx';
import SmallLoader from '../../SkillAvailer/SmallLoader';

import CreatableSelect from 'react-select/creatable';
import { fetchSkillingHistory } from '../../../api/fetchAllData/fetchSkillingHistory';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import educationInstitutionApi from '../../../api/searchSuggestionAPIs/educationInstitutionApi';
import CreateSelectInstitution from '../SelectComponent/CreateSelectInstitution';
import { ThreeDots } from 'react-loader-spinner';
import SecondaryBtnLoader from '../../Buttons/SecondaryBtnLoader';
import { FormatDateIntoPost } from '../HelperFunction/FormatDateIntoPost';
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference ';
import DatePicker from 'react-datepicker';
import { formatDateInputType } from '../HelperFunction/FormatDateInputType';

const SkillingForm = ({ formvalues6, setFormValues6, setValidation, setFileupload, handleSubmit, isAcquiredEdit, handleModalClose, isLoading, sendSkillingDataToParent }) => {
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    //redux
    const skillingsHistory = useSelector(state => state.skillingsHistory);
    const regionalData = useSelector(state => state.regionalData);
    console.log(skillingsHistory);
    const [skillingOptions, setSkillingOptions] = useState([])

    useEffect(() => {
        const filterSkilling = skillingsHistory?.data
            .map((employment) => ({
                value: employment,
                label: employment.title,
            }));

        console.log(filterSkilling);

        setSkillingOptions(filterSkilling)

    }, [skillingsHistory])

    const [selectedSkilling, setSelectedSkilling] = useState(null)




    const [onGoing, setOnGoing] = useState(false);
    const [locationForSkilling, setLocationForSkilling] = useState([])
    const [onlineLocation, setOnlineLocation] = useState(false);
    // const [isAddingNewCert, setIsAddingSkilling] = useState(false);

    const [location, setLocation] = useState([]);
    const [online, setOnline] = useState(false);

    const [isAdddingSkilling, setIsAddingSkilling] = useState(false)
    const [isAdddingCert, setIsAddingCert] = useState(false)


    const [resetLocation, setResetLocation] = useState(false);
    const [companySelectValue, setCompanySelectValue] = useState(null);

    //locations for certificate from selected company 
    useEffect(() => {
        let locations = selectedSkilling?.value?.location?.split(",")
        setLocationForSkilling(locations)
    }, [selectedSkilling])


    const dispatch = useDispatch()

    const handleValidateProject = () => {
        setValidation(true);
    };

    useEffect(() => {
        if (locationForSkilling?.length > 0) {
            setFormValues6({ ...formvalues6, location: locationForSkilling.join(',') })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationForSkilling])

    useEffect(() => {
        if (location?.length > 0) {
            setFormValues6({ ...formvalues6, location: location })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    // useEffect(() => {
    //     dispatch(fetchSkillingHistory());
    // }, [])


    useEffect(() => {

        console.log("isAcquiredEdit : ", isAcquiredEdit);
        if (isAcquiredEdit && formvalues6.location.length > 0 && locationForSkilling.length === 0) {
            setLocationForSkilling(formvalues6.location)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAcquiredEdit])

    console.log(selectedSkilling);
    console.log(formvalues6);



    const employmentHistory = useSelector((state) => state.employmentHistory.data);

    console.log(employmentHistory);

    const filterEmployment = employmentHistory
        .filter((employment) => employment.mlanguage === selectedLanguage)
        .map((employment) => ({
            value: employment,
            label: employment.organization,
        }));


    const handleCertChange = (selectedOption) => {
        console.log(selectedOption);

        if (selectedOption) {
            setSelectedSkilling(selectedOption);
            setFormValues6({
                ...formvalues6,
                organization: selectedOption?.value?.organization,
                source: selectedOption?.value?.organization,
                fromDate: timestampToYYYYMMDD(Number(selectedOption?.value?.startDate)),
                toDate: (selectedOption?.value?.endDate) ? timestampToYYYYMMDD(Number(selectedOption?.value?.endDate)) : "",
                title: selectedOption?.label,
                insIsnew: selectedOption?.__isNew__,
                ticketids: selectedOption?.value?.id
            });
        } else {
            setSelectedSkilling(null);
            setFormValues6({
                ...formvalues6,
                organization: '',
                certificationName: '',
                fromDate: '',
                toDate: ''
            });
        }

    }


    console.log("form value", formvalues6);
    console.log("selected cert", selectedSkilling);



    const formatDate = (inputDate) => {
        const [year, month, day] = inputDate.split("-");
        return `${month}/${day}/${year}`;
    };

    const handleSubmitDate = (close) => {
        handleSubmit(close)
    }

    //Api institution data
    const [institutionApiData, setInstitutionApiData] = useState([]);
    const [insConvertedToSelect, setInsConvertedToSelect] = useState([]);
    const [insSearch, setInsSearch] = useState("");
    const [eduApiLoader, setEduApiLoader] = useState(false);

    useEffect(() => {
        if (insSearch.length > 0 && insSearch !== " ") {
            setEduApiLoader(true);
            debouncedApiRequest(educationInstitutionApi, insSearch, selectedLanguage, setInstitutionApiData, setEduApiLoader);
        }

    }, [insSearch])

    useEffect(() => {
        if (institutionApiData.length > 0) {
            const data = institutionApiData.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.institutionName, // Add new field 1 with a default value
                    label: item.institutionName, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setInsConvertedToSelect(data);
        }

    }, [institutionApiData])

    useEffect(() => {
        if (!selectedSkilling) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }
        if (selectedSkilling?.__isNew__) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }


        if (!selectedSkilling?.value?.endDate) {
            setOnGoing({ ...onGoing, certificationEndDate: true })
        } else {
            setOnGoing({ ...onGoing, certificationEndDate: false })
        }

        sendSkillingDataToParent(selectedSkilling);
    }, [selectedSkilling?.label, selectedSkilling])


    const handleAddSkillingHistory = () => {


        // PostApi("Training",
        //     {
        //         ...formvalues, organization: formvalues.organization, source: formvalues.organization,
        //         startDate: FormatDateIntoPost(formvalues.fromDate), endDate: formvalues.toDate ? FormatDateIntoPost(formvalues.toDate) : "",
        //         duration: calculateDaysDifference(convertDateToMilliseconds(formvalues.fromDate), formvalues.toDate ? convertDateToMilliseconds(formvalues.toDate) : Date.now()),
        //         briefDescription:formvalues.briefDescriptions

        //     }).then((res) => {
        //         // update redux store
        //         dispatch(fetchTrainingHistory());
        //         setSelectedTraining({...selectedTraining,value:res.data, __isNew__ : false})
        //         setIsAddingCert(false)
        //         showSuccessToast('training Added')
        //         setFormValues({...formvalues,briefDescriptions:''})

        //     }).catch((err) => {
        //         console.log(err);
        //         showErrorToast('something went wrong')
        //         setIsAddingCert(false)

        //     })

        setIsAddingCert(true)


        PostApi('Skilling',
            {
                ...formvalues6, source: formvalues6.organization,
                startDate: FormatDateIntoPost(formvalues6.fromDate), endDate: formvalues6.toDate ? FormatDateIntoPost(formvalues6.toDate) : "",
                duration: calculateDaysDifference(convertDateToMilliseconds(formvalues6.fromDate), formvalues6.toDate ? convertDateToMilliseconds(formvalues6.toDate) : Date.now()),
                briefDescription: formvalues6.briefDescriptions, ticketids: formvalues6?.id ? [formvalues6?.id] : []
            }).then((res) => {
                // update redux store
                dispatch(fetchSkillingHistory());
                setSelectedSkilling({ ...selectedSkilling, value: { ...res.data, startDate: convertDateToMilliseconds(res.data.startDate), endDate: (res?.data?.endDate) ? convertDateToMilliseconds(res.data.endDate) : "" }, __isNew__: false })
                setIsAddingCert(false)
                showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'SkillingAdded') || {}).mvalue || "nf Skilling Added")
                setFormValues6({ ...formvalues6, briefDescriptions: '', ticketids: res?.data?.id })


            }).catch((err) => {
                console.log(err);
                showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong")
                setIsAddingCert(false)

            })




    }


    return (
        <div className='ms-3  '>

            {/* {!isAcquiredEdit && <div class="my-2 ">
                <label for="exampleFormControlInput1" class="form-label">Certification name <span className='text-danger' >*</span></label>
                <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary" id="" placeholder="" value={formvalues6.certificationName} name='certificationName' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} />
            </div>} */}

            {/* <div className="mb-2 ">
                <label htmlFor="exampleFormControlInput1" className="form-label">Organization Name<span className='text-danger' >*</span></label>
                <input type="text" style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" value={formvalues6.organization} name='organization' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} />
            </div> */}
            {/* <div class="my-2 ">
                <label for="exampleFormControlInput1" class="form-label">
                    {(
                        content[selectedLanguage].find(
                            (item) => item.elementLabel === "OrganizationName"
                        ) || {}
                    ).mvalue || "not found"}
                    <span className="text-danger"> *</span>
                </label>
                <CreateSelect
                    newField="orgIsnew"
                    placeholder="Enter your company name"
                    setFormValues={setFormValues6}
                    formValues={formvalues6}
                    setNewForm={setFormValues6}
                    NewForm={formvalues6}
                    setNewField="organization"
                    options={filterEmployment}
                    value={companySelectValue}
                    setValue={setCompanySelectValue}
                />
            </div> */}


            {/* <div class="my-2 ">
                <div className='d-flex '>
                    <label for="exampleFormControlInput1" class="form-label">Location</label>
                    <div className='align-content-center ' style={{ marginLeft: 'auto' }}><input type='checkbox' className='me-2' onClick={(e) => setOnlineLocation(e.target.checked)} />Online</div>
                </div>

                <MultiSelect setLocationData={setLocationForSkilling} viewLocation={locationForSkilling} onlineStatus={onlineLocation} isEdit={isAcquiredEdit} />
            </div> */}
            <div className='mb-2'>


                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'SkillingName') || {}).mvalue || "nf Skilling Name"}<span className='text-danger' >*</span></label>
                <CreatableSelect
                    options={skillingOptions}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#f5f5f5',
                            primary: '#815F0B',
                            primary50: '#f5f5f5',
                            neutral0: "#f8f9fA"
                        },
                    })}
                    closeMenuOnSelect={true}
                    openMenuOnClick={false}
                    components={{ DropdownIndicator: () => null }}
                    // value={selectedSkilling}
                    onChange={handleCertChange}
                    isClearable={true}
                    placeholder="Enter your skilling name"
                />

            </div>


            {

                selectedSkilling?.__isNew__ &&

                <div className='' style={{ border: "solid 1px #D3D3D3", padding: "5px" }}>
                    <h5>{"Add Sklling"}</h5>
                    <div class="mb-2 ">
                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf InstitutionName"}<span className='text-danger' > *</span></label>
                        {/* <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.organization} /> */}
                        <CreateSelectInstitution
                            setInsSearch={setInsSearch}
                            insSearch={insSearch}
                            insConvertedToSelect={insConvertedToSelect}
                            formvalues={formvalues6}
                            setFormValues={setFormValues6}
                            showDropdown={false}

                        />
                        {eduApiLoader && <div style={{ transform: "translate(365px,-24px)", width: "50px" }}>
                            <  ThreeDots width={"30"} height={"10"} />
                        </div>}
                    </div>

                    <div className='d-lg-flex my-2 '>
                        <div className=" w-100  ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"}<span className='text-danger' >*</span></label>
                            {/* <input type="date"

                                max={timestampToYYYYMMDD(Date.now())}
                                style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.fromDate} /> */}
                            <DatePicker
                                style={{ height: "32px" }}
                                maxDate={timestampToYYYYMMDD(Date.now())}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                id="exampleFormControlInput1"
                                onChange={(e) => setFormValues6({ ...formvalues6, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues6?.fromDate ? formvalues6?.fromDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                onBlur={() => { }}
                            />
                        </div>
                        <div className=" ms-lg-2 w-100 ">
                            <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End date"} </label>
                            {/* <input type="date"
                                min={formvalues6.fromDate}
                                max={timestampToYYYYMMDD(Date.now())}
                                style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.toDate}
                                {...onGoing.certificationEndDate && { disabled: true }} /> */}
                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={formvalues6?.fromDate}
                                maxDate={timestampToYYYYMMDD(Date.now())}
                                onChange={(e) => setFormValues6({ ...formvalues6, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues6?.toDate ? formvalues6?.toDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                disabled={onGoing?.certificationEndDate}
                                onBlur={() => { }}
                            />
                            <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                <label htmlFor="exampleFormControlInput1" className="">{(content[selectedLanguage].find(item => item.elementLabel === 'OnGoing') || {}).mvalue || "nf OnGoing"} </label>
                                <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                    setFormValues6({ ...formvalues6, toDate: "" })
                                    setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="my-2">
                        <div className='d-flex justify-content-between'>
                            <label htmlFor="locationInput" className="form-label">
                                {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}<span className='text-danger'> *</span>
                            </label>
                            <div className='d-flex align-items-center justify-align-content'>
                                <input
                                    id="onlineCheckbox"
                                    className='ms-2'
                                    type="checkbox"
                                    name="online"
                                    checked={online}
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        setOnline(isChecked);
                                    }}
                                />
                                <label htmlFor="onlineCheckbox" className="ms-1"> {(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</label>
                            </div>
                        </div>
                        {/* <CreateSelectForLocation locationData={location}
                                                setLocation={setLocation}
                                                onlineStatus={toggleOnline} /> */}
                        <MultiSelect setLocationData={setLocation} viewLocation={location} onlineStatus={online} />
                    </div>

                    <div className="my-2 ">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                        <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.briefDescriptions} >  </textarea>
                    </div>

                    <div className='d-flex gap-2 justify-content-end'>

                        <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'AddSkilling') || {}).mvalue || "nf Add Skilling"} onClick={() => handleAddSkillingHistory()} backgroundColor="#815F0B" color="#F8F8E9" loading={isAdddingCert} />
                    </div>
                </div>
            }
            {

                !selectedSkilling?.__isNew__ &&

                <>
                    <div class="mb-2 ">
                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf Institution Name"}<span className='text-danger' > *</span></label>
                        <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.organization} disabled />
                    </div>

                    <div className='d-lg-flex my-2 '>
                        <div className=" w-100  ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"}  <span className='text-danger' >*</span></label>
                            {/* <input type="date"

                                min={timestampToYYYYMMDD(Number(selectedSkilling?.value?.startDate))}
                                max={timestampToYYYYMMDD(Number((selectedSkilling?.value?.endDate) ? selectedSkilling?.value?.endDate : Date.now()))}
                                style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.fromDate} /> */}
                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={timestampToYYYYMMDD(Number(selectedSkilling?.value?.startDate))}
                                maxDate={timestampToYYYYMMDD(Number((selectedSkilling?.value?.endDate) ? selectedSkilling?.value?.endDate : Date.now()))}
                                onChange={(e) => setFormValues6({ ...formvalues6, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues6?.fromDate ? formvalues6?.fromDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                onBlur={() => { }}
                            />
                        </div>
                        <div className=" ms-lg-2 w-100 ">
                            <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End Date"}  </label>
                            {/* <input type="date"

                                min={formvalues6.fromDate}
                                max={timestampToYYYYMMDD(Number((selectedSkilling?.value?.endDate) ? selectedSkilling?.value?.endDate : Date.now()))}
                                style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.toDate}
                                {...onGoing.certificationEndDate && { disabled: true }} /> */}

                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={formvalues6?.fromDate}
                                maxDate={timestampToYYYYMMDD(Date.now())}
                                onChange={(e) => setFormValues6({ ...formvalues6, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues6?.toDate ? formvalues6?.toDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                disabled={onGoing?.certificationEndDate}
                                onBlur={() => { }}
                            />


                            {!selectedSkilling?.value?.endDate &&
                                <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                    <label htmlFor="exampleFormControlInput1" className="">{(content[selectedLanguage].find(item => item.elementLabel === 'OnGoing') || {}).mvalue || "nf OnGoing"} </label>
                                    <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                        setFormValues6({ ...formvalues6, toDate: "" })
                                        setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                    }} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="my-2">

                        <div>
                            <label for="exampleFormControlInput1" class="form-label">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Locations"
                                    ) || {}
                                ).mvalue || "not found"}
                                <span className="text-danger me-2">*</span>

                                <SecondaryBtn
                                    label={(content[selectedLanguage].find(item => item.elementLabel === 'Reset') || {}).mvalue || "nf reset"}
                                    onClick={() => {

                                        setLocationForSkilling(selectedSkilling?.value?.location.split(","))
                                    }}
                                    backgroundColor="#F8F8E9"
                                    color="#815F0B"

                                />
                            </label>
                        </div>

                        <div className="d-flex gap-2">

                            {locationForSkilling?.map((loc) => {
                                return (
                                    <div className="p-1  rounded bg-light border" >
                                        {loc}
                                        <span className="text-muted px-1" style={{ cursor: 'pointer' }} onClick={() => {
                                            let newLocations = locationForSkilling?.filter((location) => {
                                                return location !== loc
                                            })
                                            setLocationForSkilling(newLocations)
                                        }}><RxCross2 /></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="my-2 ">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"}  <span className='text-danger' >*</span></label>
                        <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setFormValues6({ ...formvalues6, [e.target.name]: e.target.value })} value={formvalues6.briefDescriptions} >  </textarea>
                    </div>

                    <div className="modal-footer d-flex justify-content-end  ">



                        <div className='d-flex gap-2'>
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "nf Save"} onClick={() => handleSubmit(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isLoading} />
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "nf Save & Close"} onClick={() => handleSubmit(true)} Active={true} backgroundColor="#815F0B" color="#F8F8E9" loading={isLoading} />
                        </div>
                    </div>
                </>
            }






        </div>
    )
}

export default SkillingForm
