import React, { useEffect, useState } from "react";
import ShowHideIcon from "../../ShowHideIcon";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { timestampToYYYYMMDD } from "../HelperFunction/timestampToYYYYMMDD";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import { ImAttachment, ImCheckmark } from "react-icons/im";
import Validation from "../../ValidationForm";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import TableLoaders from "../../CustomLoader/TableLoaders";
import { useDispatch } from "react-redux";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import {
  LicensesDelete,
  licenseUpdate,
} from "../../../reducer/detailedProfile/licensesSlice";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import AttachmentForm from "../../AttachmentForm";
import { showErrorToast } from "../../ToastNotification/showErrorToast";
import DeleteApi from "../../../api/DeleteData/DeleteApi";
import { showSuccessToast } from "../../ToastNotification/showSuccessToast";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import Anonimization from "../../Anonimization";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import EditDetailedProfileLicesnseAndAwards from "../Forms/Edit Forms/EditDetailedProfileLicesnseAndAwards";
const LicenseSummary = ({ data, yoeCalc, editEnable }) => {
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);

  const dispatch = useDispatch();

  /* HANDLE EDIT FOR LICENSE HISTORY */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };

  const handleValidate = (validationData) => {
    dispatch(setValidationData(validationData));
  };
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  /* CHANGE VALIDATION RESULTS */
  const {
    getUserValidation: { userValidationData },
  } = useSelector((state) => state);

  useEffect(() => {
    console.log(data, "ddddd");
    /* CHECK FOR MATCHING ID AND CAPTURE DATA */
    data?.data?.forEach((dataItem) => {
      userValidationData?.forEach((validationItem) => {
        if (
          dataItem.id === validationItem.requestorItemId &&
          dataItem.validation === "No" &&
          validationItem.isValidated === "Yes"
        ) {
          dispatch(licenseUpdate({ id: dataItem.id, validation: "Yes" }));
        }
      });
    });
  }, [data, userValidationData, dispatch]);

  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      <AttachmentForm />
      <DeleteFormDetailedProfile />
      {/* LICENSE DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfileLicesnseAndAwards />}
      {/* table start */}

      <div className="table-responsive ">
        <table className="table table-sm   table-fixed table-hover    ">
          <thead>
            <tr className="border-dark-subtle">
              <th scope="col" className="bg-body- " style={{ width: "25%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "License"
                  ) || {}
                ).mvalue || "nf License"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "19%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectOrganization"
                  ) || {}
                ).mvalue || "nf Organization"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Date"
                  ) || {}
                ).mvalue || "nf Date"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "18%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectLocation"
                  ) || {}
                ).mvalue || "nf Location"}{" "}
              </th>
              <th scope="col" style={{ width: "12%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectValidation"
                  ) || {}
                ).mvalue || "nf Validation"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "10%" }}>
                {" "}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.status === "loading" ? (
              <TableLoaders Rows={2} Cols={5} btnCols={2} />
            ) : (
              data.status === "success" &&
              data.data.length > 0 &&
              data.data.map((certs, index) => (
                <tr className="">
                  <td>
                    {certs.licenseName
                      ? certs.licenseName.length > 17
                        ? certs.licenseName.substring(0, 17) + "..."
                        : certs.licenseName
                      : ""}
                  </td>
                  <td>
                    {certs.organization
                      ? certs.organization.length > 17
                        ? certs.organization.substring(0, 17) + "..."
                        : certs.organization
                      : ""}
                  </td>
                  <td>{DayDifferenceToDynamicView(certs.duration)}</td>
                  <td>
                    {certs.location
                      ? certs.location.length > 12
                        ? certs.location.substring(0, 12) + "..."
                        : certs.location
                      : ""}
                  </td>
                  <td className=" ">
                    {certs.validation === "Yes" ? " Yes" : " No"}
                  </td>
                  <td
                    className="d-flex justify-content-between"
                    style={{ minHeight: "26px" }}
                  >
                    <>
                      <button
                        className="border-0 bg-white"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Validate"
                        data-bs-toggle="modal"
                        data-bs-target="#validationForm"
                        onClick={() => handleValidate(certs)}
                      >
                        <img
                          src={validationIcon}
                          alt=""
                          style={{ width: "1rem", height: "1rem" }}
                        />
                      </button>
                      <button
                        className="border-0 bg-white"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Attachment" 
                        onClick={() => handleAttachment(certs)}
                        data-bs-toggle="modal"
                        data-bs-target="#attachmentList"
                      >
                        <ImAttachment />
                      </button>
                    </>
                    {editEnable && (
                      <>
                        <div className="">
                          <button
                            className="border-0 bg-white"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Edit"
                            data-bs-toggle="modal"
                            data-bs-target="#EditDetailedProfileLicesnseModal" 
                            onClick={() => handleEdit(certs)}
                          >
                            <MdEdit />
                          </button>
                        </div>
                        <ShowHideIcon
                          value={certs.recordHide === "Yes" ? true : false}
                          toggleable={true}
                          form={certs}
                          fieldName={"recordHide"}
                          api={"Skills Applied"}
                          id={certs.id}
                        />
                        <Anonimization
                          value={certs.recordAnonymous === "Yes" ? true : false}
                          toggleable={true}
                          form={certs}
                          fieldName={"recordAnonymous"}
                          api={"Skills Applied"}
                          id={certs.id}
                        />
                        <button
                          className="border-0 bg-transparent"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Delete"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteformdetailedprofile"
                          onClick={() => {
                            dispatch(setDeleteDetailedProfileData(certs));
                          }}
                        >
                          {" "}
                          <MdDelete />{" "}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* table end */}
    </>
  );
};

export default LicenseSummary;
