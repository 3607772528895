import React from 'react'
import LeftPanelSeeker from './LeftPanelSeeker'
import ResultMIddlePanel from './ResultMIddlePanel'

const SkillSeekerSearch = ({ contentHeight, SkillBasedResult }) => {
    return (
        <div className="  d-flex container-fluid  " style={{ height: contentHeight, margin: 0, padding: 0, backgroundColor: "#E7E7E7" }} >


            <div className="  row container-fluid " style={{ margin: 0, padding: 0, backgroundColor: "#E7E7E7"  }}   >


                {/* LEft bar */}
                <div className=" col  font-5 overflow-y-auto   " style={{ height: contentHeight, margin: 0, padding: 0, backgroundColor: "#E7E7E7" }} >
                    <LeftPanelSeeker contentHeight={contentHeight} />
                </div>



                {/* Middle Sections */}
                <div className=" col-lg-9   font-5 overflow-y-auto mt-2  " style={{ height: contentHeight,backgroundColor:"#E7E7E7" , margin: 0, paddingX: '1rem',paddingY:"0.5rem" }} >
                    <ResultMIddlePanel Result={SkillBasedResult} />
                </div>



            </div>




        </div>
    )
}

export default SkillSeekerSearch