import React from "react";
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";

const ResumeControls = ({
  handleColorChange,
  handleFontColor,
  handleFontSizeInc,
  handleFontSizeDec,
  handleAlignmentChange,
  handleHeaderColAlign,
  handleBackgroundImageChange,
  isLoading,
  resumeBGImages,
}) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mt-2 no-print">
        {["#FFFFFF", "red", "green", "blue", "gold", "orange", "black"].map(
          (color) => (
            <div
              key={color}
              onClick={() => handleColorChange(color)}
              style={{
                width: "2rem",
                height: "2rem",
                backgroundColor: color,
                margin: "0 5px",
                cursor: "pointer",
                border: "1px solid #577126",
                borderRadius: "50%",
              }}
            ></div>
          )
        )}
        <div style={{ position: "relative", marginLeft: "10px" }}>
          <input
            type="color"
            onChange={(e) => handleColorChange(e.target.value)}
            style={{
              width: "2rem",
              height: "2rem",
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            className="rounded"
          />
          <div
            style={{
              width: "2rem",
              height: "2rem",
              background:
                "conic-gradient(red, orange, yellow, #577126, blue, indigo, violet, red)",
              border: "1px solid #577126",
              borderRadius: "50%",
              pointerEvents: "none",
            }}
          ></div>
        </div>
      </div>
      <div className="mt-2 no-print">
        <div className="d-flex justify-content-center fw-bold fs-6 text-success no-print">
          Select a font color
        </div>
        <div className="d-flex justify-content-center mt-2 no-print">
          {["#FFFFFF", "red", "green", "blue", "gold", "orange", "black"].map(
            (color) => (
              <div
                key={color}
                onClick={() => handleFontColor(color)}
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: color,
                  margin: "0 5px",
                  cursor: "pointer",
                  border: "1px solid #577126",
                  borderRadius: "50%",
                }}
              ></div>
            )
          )}
          <div style={{ position: "relative", marginLeft: "10px" }}>
            <input
              type="color"
              onChange={(e) => handleFontColor(e.target.value)}
              style={{
                width: "2rem",
                height: "2rem",
                opacity: 0,
                position: "absolute",
                cursor: "pointer",
                borderRadius: "50%",
              }}
              className="rounded"
            />
            <div
              style={{
                width: "2rem",
                height: "2rem",
                background:
                  "conic-gradient(red, orange, yellow, #577126, blue, indigo, violet, red)",
                border: "1px solid #577126",
                borderRadius: "50%",
                pointerEvents: "none",
              }}
            ></div>
          </div>
        </div>
      </div>

      {/* SWITCH COLUMNS */}
      <div className="mt-2 no-print">
        <div className="d-flex justify-content-center fw-bold fs-6 text-success no-print">
          Switch columns
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() => handleHeaderColAlign()}
          >
            <BiTransfer />
          </div>
        </div>
      </div>

      {/* FONT SIZES */}
      <div className="mt-2 no-print">
        <div className="d-flex justify-content-center fw-bold fs-6 text-success no-print">
          Font Size
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={handleFontSizeDec}
          >
            <FaMinus />
          </div>
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={handleFontSizeInc}
          >
            <FaPlus />
          </div>
        </div>
      </div>

      {/* ALIGNMENT CONTROLS */}
      <div className="mt-2 no-print">
        <div className="d-flex justify-content-center fw-bold fs-6 text-success no-print">
          Alignment
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() => handleAlignmentChange("left")}
          >
            <FaAlignLeft />
          </div>
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() => handleAlignmentChange("center")}
          >
            <FaAlignCenter />
          </div>
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() => handleAlignmentChange("right")}
          >
            <FaAlignRight />
          </div>
        </div>
      </div>
      {/* BACKGROUND IMAGE */}
      <div className="mt-2 no-print">
        <div className="d-flex justify-content-center fw-bold fs-6 text-success no-print">
          Select a background image
        </div>
        <div
          className="d-flex justify-content-center mt-2 no-print"
          style={{ flexFlow: "wrap" }}
        >
          {isLoading ? (
            <React.Fragment>Loading...</React.Fragment>
          ) : (
            <React.Fragment>
              {resumeBGImages.map((image, index) => (
                <div
                  key={index}
                  onClick={() => handleBackgroundImageChange(image?.name)}
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundImage: `url(${image?.name})`,
                    backgroundSize: "cover",
                    margin: "0 5px",
                    cursor: "pointer",
                    border: "1px solid #577126",
                  }}
                  className="rounded mt-1"
                ></div>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeControls;
