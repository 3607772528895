import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import anonymousLogo from '../../src/Images/anonymousLogo.png'
import EditApi from '../api/editData/EditApi';
import { showSuccessToast } from './ToastNotification/showSuccessToast';
import { showErrorToast } from './ToastNotification/showErrorToast';
import { editSkillApplied } from '../reducer/skillProfile/SkillsAppliedSlice';
import { editSkillAcquired } from '../reducer/skillProfile/SkillsAcquiredSlice';
import { useDispatch } from 'react-redux';
import { updateEmployment } from '../reducer/detailedProfile/employmentSlice';
import { projectUpdateRecord } from '../reducer/detailedProfile/projectSlice';
import { educationUpdateRecord } from '../reducer/detailedProfile/educationSlice';
import { certificationUpdateRecord } from '../reducer/detailedProfile/certificationSlice';
import { trainingUpdateRecord } from '../reducer/detailedProfile/trainingSlice';
import { conferenceUpdateRecord } from '../reducer/detailedProfile/conferenceSlice';
import { skillingUpdateRecord } from '../reducer/detailedProfile/skillingsSlice';
import { licenseUpdateRecord } from '../reducer/detailedProfile/licensesSlice';
import { achievementUpdateRecord } from '../reducer/detailedProfile/achievementSlice';
import { membershipUpdateRecord } from '../reducer/detailedProfile/membershipSlice';
import { othersUpdateRecord } from '../reducer/detailedProfile/otherSlice';
import noAnon from '../Images/No anon .jpg'
import { BASE_URL } from '../config/Properties';
const Anonimization = ({ fieldName = "def", form, setForm = null, value, toggleable = false, api = "false", id = '0' }) => {
  const [isVisible, setIsVisible] = useState();

  const [Loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsVisible(value);


  }, [value])



  const toggleVisibility = () => {
    setLoader(true);

    setIsVisible(prevIsVisible => !prevIsVisible); // Use state updater function
    const newValue = !isVisible ? "Yes" : "No";
    if (toggleable && api !== "false") {
      try {
        // Use the updated state value
        EditApi(form?.applicationName, form?.id, { recordAnonymous: newValue }

        ).then((res) => {
          setLoader(false);
          showSuccessToast("Status Changed");

          switch (res.data.applicationName) {
            case "Skills Applied":
              dispatch(editSkillApplied(res.data));
              console.log("skil Applied edit");
              break;
            case "Skills Acquired":
              dispatch(editSkillAcquired(res.data));
              console.log("skil Acquired edit");
              break;

            case "Employment History":
              dispatch(updateEmployment(res.data));
              console.log("updateEmployment edit");
              break;

            case "Project  History":
              dispatch(projectUpdateRecord(res.data));
              console.log("projectUpdateRecord edit");
              break;

            case "Education History":
              dispatch(educationUpdateRecord(res.data));
              console.log("educationUpdateRecord edit");
              break;

            case "Certification History":
              dispatch(certificationUpdateRecord(res.data));
              console.log("certificationUpdateRecord edit");
              break;
            case "Training":
              dispatch(trainingUpdateRecord(res.data));
              console.log("trainingUpdateRecord edit");
              break;

            case "Conferences":
              dispatch(conferenceUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Skilling":
              dispatch(skillingUpdateRecord(res.data));
              console.log("skillingUpdateRecord edit");
              break;
            case "Licenses":
              dispatch(licenseUpdateRecord(res.data));
              console.log("licenseUpdateRecord edit");
              break;
            case "Awards":
              dispatch(achievementUpdateRecord(res.data));
              console.log("achievementUpdateRecord edit");
              break;
            case "Memberships":
              dispatch(membershipUpdateRecord(res.data));
              console.log("membershipUpdateRecord edit");
              break;
            case "Others":
              dispatch(othersUpdateRecord(res.data));
              console.log("othersUpdateRecord edit");
              break;
            default:
              break;
          }
          console.log(res.data);
        })
      } catch (error) {
        showErrorToast("status not changed");
        console.log(error);
      }

    } else if (toggleable && form && setForm && api === "false") {

      if (fieldName === "fn" || fieldName === "mlnShowHide") {

        setForm({ ...form, "fn": newValue,"mlnShowHide":newValue });
      }else {

        setForm({ ...form, [fieldName]: newValue });
      }
    }

  };



  return (
    <React.Fragment>

      {
        api === "false" ?

          toggleable ?
            <div variant="outline-primary" onClick={toggleVisibility}>
              {!isVisible ? <img src={noAnon} width={16} height={16} /> : <img src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"} width={16} height={16} />}
            </div> :
            <div variant="outline-primary" >
              {!isVisible ? <img src={noAnon} width={16} height={16} /> : <img src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"} width={16} height={16} />}
            </div>
          :
          toggleable && Loader === false ?
            <div variant="outline-primary" onClick={toggleVisibility}>
              {!isVisible ? <img src={noAnon} width={16} height={16} /> : <img src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"} width={16} height={16} />}
            </div> :
            <div variant="outline-primary" class="opacity-25 " >
              {!isVisible ? <img src={noAnon} width={16} height={16} /> : <img src={BASE_URL + "/skill/api/v1/getImage/anonymousLogo.png"} class="opacity-75  " width={16} height={16} />}
            </div>

      }

    </React.Fragment >
  );
};

export default Anonimization;
