import React, { useCallback, useEffect, useState } from "react";
import SecondaryBtn from "./Buttons/SecondaryBtn";
import { useSelector } from "react-redux";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdOpenInNew } from "react-icons/md";
import EditApi from "../api/editData/EditApi";
import { FaRegTrashCan } from "react-icons/fa6";
import { deleteTheState } from "../reducer/attachments/skillOwnerAttachments";
import { useDispatch } from "react-redux";
import { fetchCertificationHistory } from "../api/fetchAllData/fetchCertificationHistory";
import { FetchProjectHistory } from "../api/fetchAllData/FetchProjectHistory";
import { fetchTrainingHistory } from "../api/fetchAllData/fetchTrainingHistory";
import { FetchOrganizationHistory } from "../api/fetchAllData/fetchOrganization";
import { fetchSkillingHistory } from "../api/fetchAllData/fetchSkillingHistory";
import { fetchDataSkillsApplied } from "../api/fetchAllData/fetchDataSkillsApplied";
import { fetchDataSkillsAcquired } from "../api/fetchAllData/fetchDataSkillsAcquired";
import SecondaryBtnLoader from "./Buttons/SecondaryBtnLoader";
import { fetchEducationHistory } from "../api/fetchAllData/fetchEducationHistory";
import { fetchConferencesHistory } from "../api/fetchAllData/fetchConferenceHistory";
import { fetchOthersHistory } from "../api/fetchAllData/fetchOtherHistory";
import { fetchLanguages } from "../reducer/localization/listOfLangSlice";
import { fetchMembershipHistory } from "../api/fetchAllData/fetchMembershipHistory";
import { fetchAwardsHistory } from "../api/fetchAllData/fetchAwardsHistory";
import { fetchLicensesHistory } from "../api/fetchAllData/fetchLicensesHistory";
import {
  DownloadAttachment,
  GetAttachment,
} from "../api/Attachment  API/DownloadAttachmentApi";
import { GetUserForSelectedLanguage } from "./SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import { MakeAttachmentRelationPostApi } from "../api/Attachment  API/Attachment Console/MakeRelationApi";
import { fetchUserAttachment } from "../reducer/attachments/getUserAttachmentSlice";
import DeleteApi from "../api/DeleteData/DeleteApi";

const AttachmentForm = () => {
  /* DISPATCH */
  const dispatch = useDispatch();
  /* USER ID */
  const userId = localStorage.getItem("userId");
  /* USER ROLE */
  const role_ = localStorage.getItem("USER_ROLE");

  //attachmentPDF
  const [courseMediaFile, setCourseMediaFile] = useState(null);
  const [data, setData] = useState();

  const datas = useSelector((state) => state.skillOwnerAttachments.data);

  const [fileObj, setFileObj] = useState([]);
  const [loading, setLoading] = useState(false);

  /* SELECTED ATTACHMENTS */
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const {
    language: selectedLanguage,
    getUserAttachment: { userAttachmentData },
    content,
  } = useSelector((state) => state);
  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  useEffect(() => {}, [datas, selectedLanguage, content]);

  useEffect(() => {}, [userAttachmentData]);

  useEffect(() => {}, [fileObj]);

  useEffect(() => {}, [courseMediaFile]);

  useEffect(() => {
    setData(datas);
  }, [datas]);

  useEffect(() => {}, [datas]);

  /* HANDLE DATA UPDATE */
  const handleDataUpdate = useCallback(
    (applicationName) => {
      switch (applicationName) {
        case "Certification History":
          dispatch(fetchCertificationHistory());
          break;
        case "Project  History":
          dispatch(FetchProjectHistory());
          break;
        case "Training":
          dispatch(fetchTrainingHistory());
          break;
        case "Employment History":
          dispatch(FetchOrganizationHistory());
          break;
        case "Skilling":
          dispatch(fetchSkillingHistory());
          break;
        case "Skills Applied":
          dispatch(fetchDataSkillsApplied());
          break;
        case "Skills Acquired":
          dispatch(fetchDataSkillsAcquired());
          break;
        case "Education History":
          dispatch(fetchEducationHistory());
          break;
        case "Conferences":
          dispatch(fetchConferencesHistory());
          break;
        case "Others":
          dispatch(fetchOthersHistory());
          break;
        case "Languages":
          dispatch(fetchLanguages());
          break;
        case "Memberships":
          dispatch(fetchMembershipHistory());
          break;
        case "Awards":
          dispatch(fetchAwardsHistory());
          break;
        case "Licenses":
          dispatch(fetchLicensesHistory());
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  /* HANDLE ADDING ATTACHMENT */
  const handleAttachmentAdd = useCallback(async () => {
    setLoading(true);

    /* PARSE EXISING FILES */
    let existingAttachments = [];
    if (data?.attachmentFileNames) {
      try {
        existingAttachments = JSON.parse(data?.attachmentFileNames);
      } catch (error) {
        console.error("Error parsing attachmentFileNames: ", error);
      }
    }

    const newUploadedFiles = selectedAttachments.map((file) => ({
      filename: file.fileName,
      fileId: file.fileId,
      fileTitle: file.fileTitle || "",
    }));

    /* ATTACHMENT RELATION PROMISE */
    const attachmentRelationPromises = newUploadedFiles.map((file) => {
      const body = {
        userId: userId,
        fileId: file?.fileId,
        fileName: file?.filename,
        fileTitle: file?.fileTitle,
        linkedId: data?.id,
        linkedApplicationName: data?.applicationName,
        roleName: role_,
      };
      return MakeAttachmentRelationPostApi(body);
    });

    try {
      await Promise.all(attachmentRelationPromises);
      /* DISPATCH DATA */
      dispatch(fetchUserAttachment());
    } catch (error) {
      console.error("Error POSTing attachment relation in child: ", error);
    }

    /* COMBINE EXISING WITH THE NEW ONE */
    const updatedAttachments = [...existingAttachments, ...selectedAttachments];
    /* PAYLOAD */
    const payload = {
      attachmentFileNames: JSON.stringify(updatedAttachments),
    };

    try {
      await EditApi(data?.applicationName, data?.id, payload);
      handleDataUpdate(data?.applicationName);
      setLoading(false);
      setSelectedAttachments([]);
      /* SET DATA */
      setData((prevData) => ({
        ...prevData,
        attachmentFileNames: JSON.stringify(updatedAttachments),
      }));
    } catch (error) {
      setLoading(false);
      console.error("Error adding attachment: ", error);
    }
  }, [
    data?.applicationName,
    data?.attachmentFileNames,
    data?.id,
    dispatch,
    handleDataUpdate,
    selectedAttachments,
    userId,
    role_
  ]);

  /* HANDLE REMOVE ATTACHMENT BY INDEX */
  const handleRemoveAttachment = useCallback(
    async (indexToRemove, id) => {
      try {
        try {
          await DeleteApi("Attachment Map", id);
          /* DISPATCH ADVANCE DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error("Error deleting attachment detail: ", error);
        }
        /* PARSE THE ATTACHMENT FILE NAMES */
        let attachments = JSON.parse(data?.attachmentFileNames);
        attachments.splice(indexToRemove, 1);
        /* PAYLOAD */
        const resultedAttachments = {
          attachmentFileNames: JSON.stringify(attachments),
        };
        await EditApi(data?.applicationName, data?.id, resultedAttachments);
        handleDataUpdate(data.applicationName);
        /* SET DATA */
        setData((prevData) => ({
          ...prevData,
          attachmentFileNames: JSON.stringify(attachments),
        }));
      } catch (error) {
        console.error("Error deleting attachment: ", error);
      }
    },
    [
      data?.attachmentFileNames,
      data?.applicationName,
      data?.id,
      handleDataUpdate,
      dispatch,
    ]
  );

  const clearFormData = () => {
    setCourseMediaFile(null);
    setFileObj([]);
    setData([]);
    dispatch(deleteTheState());
  };

  /* HANDLE CHECKBOX CHANGE */
  const handleAttachmentsCheckboxChange = (attachment) => {
    setSelectedAttachments((prevSelected) =>
      prevSelected.some((item) => item.fileName === attachment.fileName)
        ? prevSelected.filter((item) => item.fileName !== attachment.fileName)
        : [...prevSelected, attachment]
    );
  };

  useEffect(() => {}, [data]);

  /* REMOVE ATTACHMENTS NOT PRESENT IN USER DETAILS */
  const removeInvalidAttachments = useCallback(async () => {
    try {
      const userDetailsAttachments = JSON.parse(
        userDetails?.attachmentFileNames
      );
      const userDetailsFileIds = userDetailsAttachments.map(
        (att) => att.fileId
      );

      let attachments = JSON.parse(data?.attachmentFileNames);
      const validAttachments = attachments.filter((att) =>
        userDetailsFileIds.includes(att?.fileId)
      );

      if (validAttachments.length !== attachments.length) {
        const resultedAttachments = {
          attachmentFileNames: JSON.stringify(validAttachments),
        };
        await EditApi(data?.applicationName, data?.id, resultedAttachments);
        handleDataUpdate(data?.applicationName);
      }
    } catch (error) {
      console.error("Error removing invalid attachments: ", error);
    }
  }, [data, userDetails, handleDataUpdate]);

  useEffect(() => {
    removeInvalidAttachments();
  }, [data, userDetails, removeInvalidAttachments]);

  return (
    <div>
      <div
        class="modal fade"
        id="attachmentList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ width: "100%", maxWidth: "600px", minWidth: "70vw" }}
        >
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="attachmentList">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Attachments"
                  ) || {}
                ).mvalue || "nf Attachments "}
              </h1>
              <button
                type="button"
                class="btn-close"
                onClick={clearFormData}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-1rem", padding: "0px" }}
              >
                <span className="">
                  {[
                    "Employment History",
                    "Project  History",
                    "Education History",
                    "Certification History",
                    "Training",
                    "Conferences",
                    "Skilling",
                    "Awards",
                    "Memberships",
                  ].includes(data?.applicationName) ? (
                    <span>
                      <b>{`${data?.applicationName}`}: </b>
                    </span>
                  ) : (
                    <div className="" style={{ width: "75px" }}>
                      <span>
                        <b>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Skill"
                            ) || {}
                          ).mvalue || "nf Skill"}{" "}
                        </b>
                      </span>
                    </div>
                  )}
                </span>

                <div
                  className=" p-2 px-2  m-3 fw-bolder "
                  style={{ color: "#577126" }}
                >
                  {["Skills Applied", "Skills Acquired"].includes(
                    data?.applicationName
                  ) ? (
                    <div className="d-flex justify-content-between ">
                      <div className="me-1">{": "}</div>
                      <div>{data?.title}</div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between ">
                      <div className="me-1">{": "}</div>
                      <div>
                        {" "}
                        {data?.certificationName ||
                          data?.organization ||
                          data?.projectActivity ||
                          data?.applicationName}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {data?.applicationName === "Skills Applied" && (
                <div
                  className="d-flex align-items-center "
                  style={{ marginTop: "-1rem", padding: "0px" }}
                >
                  <div className="">
                    <div style={{ width: "75px" }}>
                      <b>
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "AppliedAt"
                          ) || {}
                        ).mvalue || "nf AppliedAt"}
                      </b>
                    </div>
                  </div>
                  <div
                    className=" p-2 px-2  m-3 fw-bolder "
                    style={{ color: "#577126" }}
                  >
                    <div className="d-flex justify-content-between ">
                      <div className="me-1">{": "}</div>
                      <div>
                        {data.projectActivity
                          ? data.projectActivity
                          : data.organization}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {data?.applicationName === "Skills Acquired" && (
                <div
                  className="d-flex align-items-center  "
                  style={{ marginTop: "-1rem" }}
                >
                  <div className="">
                    <div style={{ width: "75px" }}>
                      <b>
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "AcquiredAt"
                          ) || {}
                        ).mvalue || "nf Acquired At"}
                      </b>
                    </div>
                  </div>
                  <div
                    className=" p-2 px-2  m-3 fw-bolder "
                    style={{ color: "#577126" }}
                  >
                    <div className="d-flex justify-content-between ">
                      <div className="me-1">{": "}</div>
                      <div>{data?.source}</div>
                    </div>
                  </div>
                </div>
              )}

              {/* ATTACHMENTS TABLE */}
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  style={{ fontSize: "0.9rem" }}
                >
                  <thead>
                    <tr>
                      <th className="fw-bold w-25">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Attachments"
                          ) || {}
                        ).mvalue || "nf Attachments"}
                      </th>
                      <th className="fw-bold">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "DescriptionLabel"
                          ) || {}
                        ).mvalue || "nf DescriptionLabel"}
                      </th>
                      <th className="fw-bold" style={{ width: "15%" }}>
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Actions"
                          ) || {}
                        ).mvalue || "nf Actions"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userAttachmentData &&
                    userAttachmentData?.filter(
                      (att) => att?.linkedId === data?.id
                    )?.length > 0 ? (
                      userAttachmentData
                        ?.filter((att) => att?.linkedId === data?.id)
                        .map((attachment, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{attachment.fileName}</td>
                              <td>{attachment.fileTitle}</td>
                              <td>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <a
                                      rel="noreferrer"
                                      href={GetAttachment(
                                        userDetails?.id,
                                        attachment?.fileName,
                                        attachment?.fileId
                                      )}
                                      target="_blank"
                                    >
                                      <MdOpenInNew
                                        className=""
                                        style={{
                                          color: "#577126",
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      rel="noreferrer"
                                      href={DownloadAttachment(
                                        userDetails?.id,
                                        attachment?.fileName,
                                        attachment?.fileId
                                      )}
                                      target="_blank"
                                    >
                                      <IoCloudDownloadOutline
                                        className="mx-2"
                                        style={{
                                          color: "#577126",
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <div style={{ cursor: "pointer" }}>
                                    <FaRegTrashCan
                                      onClick={() =>
                                        handleRemoveAttachment(
                                          index,
                                          attachment?.id
                                        )
                                      }
                                      Tooltip="dsdasd"
                                      className=""
                                      style={{
                                        color: "#577126",
                                        height: "16px",
                                        width: "16px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                    ) : (
                      <tr>
                        <td
                          className="fw-bold text-center text-success"
                          colSpan="4"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "NoAttachmentsFound"
                            ) || {}
                          ).mvalue || "nf No Attachments Found"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button
                style={{ border: "0px" }}
                data-bs-target="#attachmentForm"
                data-bs-toggle="modal"
              >
                <SecondaryBtn
                  label={
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "LinkAttachment"
                      ) || {}
                    ).mvalue || "nf Link Attachment"
                  }
                  backgroundColor="#F8F8E9"
                  color="#815F0B"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ADD ATTACHMENT MODAL */}
      <div
        class="modal fade"
        id="attachmentForm"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ width: "100%", maxWidth: "600px", minWidth: "70vw" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h3 className="modal-title fs-5" id="attachmentForm">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Attachments"
                    ) || {}
                  ).mvalue || "nf Attachments"}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className="modal-body pb-0 mb-0  ">
              <div
                className="d-flex align-items-center "
                style={{ marginTop: "-1rem", padding: "0px" }}
              >
                <span className="">
                  {[
                    "Employment History",
                    "Project  History",
                    "Education History",
                    "Certification History",
                    "Training",
                    "Conferences",
                    "Skilling",
                    "Awards",
                    "Memberships",
                  ].includes(data?.applicationName) ? (
                    <span>
                      <b>{`${data?.applicationName}`}: </b>
                    </span>
                  ) : (
                    <div className="" style={{ width: "75px" }}>
                      <span>
                        <b>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Skill"
                            ) || {}
                          ).mvalue || "nf Skill"}{" "}
                        </b>
                      </span>
                    </div>
                  )}
                </span>

                <div
                  className=" p-2 px-2  m-3 fw-bolder "
                  style={{ color: "#577126" }}
                >
                  {["Skills Applied", "Skills Acquired"].includes(
                    data?.applicationName
                  ) ? (
                    <React.Fragment>
                      {": "}
                      {data?.title}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {": "}{" "}
                      {data?.certificationName ||
                        data?.organization ||
                        data?.projectActivity ||
                        data?.applicationName}
                    </React.Fragment>
                  )}
                </div>
              </div>

              {data?.applicationName === "Skills Applied" && (
                <div
                  className="d-flex align-items-center "
                  style={{ marginTop: "-1rem" }}
                >
                  <span>
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "AppliedAt"
                        ) || {}
                      ).mvalue || "nf Applied At"}
                    </b>
                  </span>
                  <div
                    className=" p-2 px-2  m-3 fw-bolder "
                    style={{ color: "#577126" }}
                  >
                    {": "}
                    {data?.projectActivity}
                  </div>
                </div>
              )}

              {data?.applicationName === "Skills Acquired" && (
                <div
                  className="d-flex align-items-center "
                  style={{ marginTop: "-1rem" }}
                >
                  <span>
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "AcquiredAt"
                        ) || {}
                      ).mvalue || "nf Acquired At"}
                    </b>
                  </span>
                  <div
                    className=" p-2 px-2  m-3 fw-bolder "
                    style={{ color: "#577126" }}
                  >
                    {": "}
                    {data?.source}
                  </div>
                </div>
              )}

              {/* DISPLAY UPLOADED FILE BY USER ID */}
              <React.Fragment>
                {userAttachmentData &&
                userAttachmentData?.filter(
                  (att) =>
                    att?.linkedApplicationName === "User Details" &&
                    att?.roleName === role_
                )?.length > 0 ? (
                  userAttachmentData
                    ?.filter(
                      (att) =>
                        att?.linkedApplicationName === "User Details" &&
                        att?.roleName === role_
                    )
                    .map((attachment, index) => (
                      <React.Fragment key={index}>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-evenly align-items-center gap-2 order-1">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAttachmentsCheckboxChange(attachment)
                              }
                            >
                              {attachment?.fileName}
                            </div>
                            <div>
                              <a
                                rel="noreferrer"
                                href={GetAttachment(
                                  userDetails?.id,
                                  attachment?.fileName,
                                  attachment?.fileId
                                )}
                                target="_blank"
                              >
                                <MdOpenInNew
                                  className=""
                                  style={{
                                    color: "#577126",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div class="form-check form-check-inline order-0">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="attachments"
                              id={`attachment${index}`}
                              checked={
                                selectedAttachments.some(
                                  (item) =>
                                    item.fileName === attachment.fileName
                                ) ||
                                userAttachmentData.some(
                                  (item) =>
                                    item.fileName === attachment.fileName &&
                                    item.linkedApplicationName ===
                                      data?.applicationName &&
                                    item.linkedId === data?.id
                                )
                              }
                              onChange={() =>
                                handleAttachmentsCheckboxChange(attachment)
                              }
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                ) : (
                  <div className="text-center text-success mb-2">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "NoAttachmentsFound"
                      ) || {}
                    ).mvalue || "nf No Attachments Found"}
                  </div>
                )}
              </React.Fragment>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="">
                <button
                  style={{ border: "0px" }}
                  data-bs-target="#attachmentList"
                  data-bs-toggle="modal"
                >
                  <SecondaryBtn
                    label={
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ViewAttachments"
                        ) || {}
                      ).mvalue || "nf View Attachments"
                    }
                    backgroundColor="#F8F8E9"
                    color="#815F0B"
                  />
                </button>
              </div>

              <div>
                <div className="d-flex flex-grow-1 gap-2">
                  <SecondaryBtnLoader
                    label={
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Link"
                        ) || {}
                      ).mvalue || "nf Link"
                    }
                    onClick={() => {
                      setLoading(true);
                      handleAttachmentAdd(true);
                    }}
                    backgroundColor="#815F0B"
                    color="#F8F8E9"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentForm;
