import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTopSkill } from "../../api/fetchAllData/fetchTopSkill";
import SecondaryBtnLoader from "../Buttons/SecondaryBtnLoader";
import SecondaryBtn from "../Buttons/SecondaryBtn";
import {
  removeResumeSkills,
  setResumeSkills,
} from "../../reducer/resume/resumeSkillSlice";

const PrintModal = ({ handlePrint }) => {
  const {
    content,
    TopSkill,
    language: selectedLanguage,
    resumeSkills,
  } = useSelector((state) => state);
  const buttonRef = useRef();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {}, [resumeSkills]);

  useEffect(() => {
    if (TopSkill.status === "idle") {
      dispatch(fetchTopSkill());
    }

    if (TopSkill.status === "success") {
      setItems(
        TopSkill.data.filter((item) => item.mlanguage === selectedLanguage)
      );
    }
  }, [TopSkill, selectedLanguage, dispatch]);

  const handleCheckboxChangeForSkill = (e) => {
    const { value, checked } = e.target;
    const skill = JSON.parse(value);

    setSelectedSkills((prev) => {
      if (checked) {
        return [...prev, skill];
      } else {
        return prev.filter((item) => item.id !== skill.id);
      }
    });
  };

  const handleSelectAllSkill = () => {
    setSelectedSkills(items);
  };

 
  

  useEffect(() => {
    dispatch(setResumeSkills(selectedSkills));
    console.log(selectedSkills);
  }, [dispatch, selectedSkills]);

  /* HANDLE PRINT */
  const handlePrintRes = useCallback(() => {
    buttonRef?.current?.click();
    setTimeout(() => {
      handlePrint();
    }, 2000);
  }, [handlePrint]);

  /* HANDLE SUBMIT AND PRINT */
  const handleSetSkillAndPrint = useCallback(() => {
    handlePrintRes();
    setTimeout(() => {
      dispatch(removeResumeSkills());
      setSelectedSkills([]);
    }, 2000);
  }, [handlePrintRes, dispatch]);

  return (
    <div
      class="modal fade modal-xl mt-5"
      id="printmodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "SelectSkillsForPrint"
                ) || {}
              ).mvalue || "nf Select Skills For Print"}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={buttonRef}
            ></button>
          </div>
          <div class="modal-body">
            <div className="mb-2">
              <SecondaryBtn
                onClick={handleSelectAllSkill}
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "SelectAll"
                    ) || {}
                  ).mvalue || "nf SelectAll"
                }
              />
            </div>
            {items.map(
              (item, index) =>
                item.skillOccupation && (
                  <div key={item.id} className="d-flex gap-1">
                    <input
                      type="checkbox"
                      id={item.id}
                      onChange={handleCheckboxChangeForSkill}
                      value={JSON.stringify(item)}
                      checked={selectedSkills.some(
                        (skill) => skill.id === item.id
                      )}
                    />
                    <label htmlFor={item.id}> {item.skillOccupation}</label>
                  </div>
                )
            )}
          </div>
          <div className="d-flex justify-content-end py-0 m-3  ">
            <div className="d-flex gap-2">
              <SecondaryBtnLoader
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Cancel"
                    ) || {}
                  ).mvalue || "nf Cancel"
                }
                backgroundColor="#815F0B"
                color="#F8F8E9"
                onClick={() => {
                  buttonRef?.current?.click();
                  setSelectedSkills([]);
                }}
              />
              <SecondaryBtnLoader
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Print"
                    ) || {}
                  ).mvalue || "nf Print"
                }
                Active={true}
                backgroundColor="#F8F8E9"
                color="#815F0B"
                onClick={handleSetSkillAndPrint}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintModal;
