import React from "react";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import Anonimization from "../../Anonimization";
import AttachmentForm from "../../AttachmentForm";
import TableLoaders from "../../CustomLoader/TableLoaders";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import ShowHideIcon from "../../ShowHideIcon";
import Validation from "../../ValidationForm";
import EditDetailedProfileConfandSkilling from "../Forms/Edit Forms/EditDetailedProfileConfandSkilling";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";
const ConferenceDetail = ({ data, editEnable }) => {
  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const regionalData = useSelector((state) => state.regionalData);

  /* HANDLE VAIDATION */
  const handleValidate = (validationData) => {
    dispatch(setValidationData(validationData));
  };

  /* HANDLE EDIT FOR CONF */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };
  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      {<AttachmentForm />}
      {/* DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfileConfandSkilling />}
      <DeleteFormDetailedProfile />
      {/* table start */}
      <div className="table-responsive ">
        <table className="table table-sm     table-fixed     ">
          <tr className="border-dark-subtle border-bottom  ">
            <th scope="col" className="bg-body- " style={{ width: "34%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ConferenceName"
                ) || {}
              ).mvalue || "nf Conference Name"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "17%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectOrganization"
                ) || {}
              ).mvalue || "nf Organization"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "13%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectDuration"
                ) || {}
              ).mvalue || "nf Duration"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "14%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectLocation"
                ) || {}
              ).mvalue || "nf Location"}{" "}
            </th>
            <th scope="col" className="" style={{ width: "12%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectValidation"
                ) || {}
              ).mvalue || "nf Validation"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "10%" }}></th>
          </tr>
        </table>
      </div>

      {data.status === "loading" ? (
        <TableLoaders Rows={2} Cols={5} btnCols={2} />
      ) : (
        data.status === "success" &&
        data.data.length > 0 &&
        data.data.map((confs, index) => (
          <div className="border-bottom  pt-2">
            <div className="d-flex   ">
              <div className=""></div>
              <div className="  " style={{ width: "34%" }}>
                {confs.conferencesName || confs?.title}
              </div>
              <div className="    " style={{ width: "17%" }}>
                {confs.organization}
              </div>
              <div style={{ width: "13%" }}>
                {DayDifferenceToDynamicView(confs.duration)}
              </div>
              <div className="    " style={{ width: "14%" }}>
                {confs.location}
              </div>
              <div className="" style={{ width: "12%" }}>
                {confs.validation ? "Yes" : "No"}
              </div>
              <div
                className="d-flex justify-content-between align-items-baseline"
                style={{ width: "10%" }}
              >
                {editEnable ? (
                  <>
                    <div
                      id="addModalBtn"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#detailedProfileEditConfandSkillingModal"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(confs)}
                    >
                      <MdEdit />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteformdetailedprofile"
                      onClick={() => {
                        dispatch(setDeleteDetailedProfileData(confs));
                      }}
                    >
                      <MdDelete />
                    </div>
                    <div style={{ cursor: "pointer" }}>
                      <ShowHideIcon
                        value={confs.recordHide === "Yes" ? true : false}
                        toggleable={true}
                        form={confs}
                        fieldName={"recordHide"}
                        api={"Skills Applied"}
                        id={confs.id}
                      />
                    </div>
                    <div style={{ cursor: "pointer" }}>
                      <Anonimization
                        value={confs.recordAnonymous === "Yes" ? true : false}
                        toggleable={true}
                        form={confs}
                        fieldName={"recordAnonymous"}
                        api={"Skills Applied"}
                        id={confs.id}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Validate"
                      data-bs-toggle="modal"
                      data-bs-target="#validationForm"
                      onClick={() => handleValidate(confs)}
                    >
                      <img
                        src={validationIcon}
                        alt=""
                        style={{ width: "1rem", height: "1rem" }}
                      />
                    </button>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Attachment"
                      onClick={() => handleAttachment(confs)}
                      data-bs-toggle="modal"
                      data-bs-target="#attachmentList"
                    >
                      <ImAttachment />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="ms-5  my-3  ">
              <table className="d-flex gap-4  ">
                <tr>
                  <td>
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ProjecStart"
                        ) || {}
                      ).mvalue || "nf From"}{" "}
                      &nbsp;
                    </b>{" "}
                  </td>
                  <td>
                    :{" "}
                    {formatTimestampToDate(
                      Number(confs.startDate),
                      regionalData.selectedCountry.dateFormat
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ProjectEnd"
                        ) || {}
                      ).mvalue || "nf To"}{" "}
                      &nbsp;
                    </b>{" "}
                  </td>
                  <td>
                    :{" "}
                    {confs.endDate
                      ? formatTimestampToDate(
                          Number(confs.endDate),
                          regionalData.selectedCountry.dateFormat
                        )
                      : "On-going"}
                  </td>
                </tr>
              </table>
              <div className="pb-1 pt-2  ">
                <b>
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "ProjectBriefDescription"
                    ) || {}
                  ).mvalue || "nf Brief Description"}{" "}
                  :
                </b>{" "}
              </div>
              <div className="ms-4  ">{confs.briefDescription}</div>

              {confs.validation === "Yes" && (
                <ValidationDataComponent itemId={confs.id} />
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ConferenceDetail;