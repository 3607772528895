import React from "react";
import { FormatDateTimeStampMonthAndDate } from "../SkillOwner/HelperFunction/FormatDateTimeStampMonthAndDate";

const ResumeBodySkillDetail = ({
  selectedLanguage,
  content,
  fontSize,
  userDetails,
  top5Skills,
  remainingSkills,
  skillAppliedCount,
  skillAcquiredCount,
  SkillsAcquired,
  SkillsApplied,
  regionalData,
  userValidationData,
  resumeSkills,
}) => {
  const sortedArray = [...resumeSkills].sort((a, b) => {
    return a.userRank - b.userRank;
  });
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center align-items-center resume-skill-detail mt-2 resume-sub-headings"
        style={{
          fontSize: `${fontSize + 4}px`,
          color: "#808080",
          fontWeight: "bold",
        }}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MySkillsDetails"
          ) || {}
        ).mvalue || "nf MySkills Details"}
      </div>
      <div>
        {/* SKILL APPLYING */}
        {skillAppliedCount > 0 &&
          sortedArray.map((skill, index) => (
            <React.Fragment>
              <div
                className="d-flex justify-content-center mt-2 skill-heading resume-sub-headings"
                style={{
                  fontSize: `${fontSize + 4}px`,
                  color: "#808080",
                  fontWeight: "bold",
                  pageBreakBefore: index !== 0 && "always",
                }}
              >
                <React.Fragment>
                  {skill?.userRank <= 5
                    ? `Skill Rank #${skill?.userRank}`
                    : "Skill"}
                  : <span>{skill?.skill}</span>
                </React.Fragment>
              </div>
              <div
                className="d-flex justify-content-start mt-2 skill-heading resume-sub-headings"
                style={{
                  fontSize: `${fontSize + 4}px`,
                  color: "#808080",
                  fontWeight: "bold",
                }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "HowDidIApplied?"
                  ) || {}
                ).mvalue || "nf How did I applied this skill?"}
              </div>
              <div
                className="d-flex justify-content-center align-items-center mt-1 resume-sub-headings"
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "darkgreen",
                }}
              ></div>
              {/* THIS CODE IS TO AVOID DUPLICATION OF HEADINGS */}
              {SkillsApplied?.data &&
                (() => {
                  let employmentHeadingDisplayed = false;
                  let ownHeadingDisplayed = false;

                  /* SORT FOR OWN TO COME FIRST IN ARRAY */
                  const sortedData = [...SkillsApplied.data].sort((a, b) => {
                    if (a.mtype === "Own" && b.mtype !== "Own") return -1;
                    if (a.mtype !== "Own" && b.mtype === "Own") return 1;
                    return 0;
                  });

                  return sortedData.map((appliedSkill) => (
                    <React.Fragment key={appliedSkill.id}>
                      {appliedSkill.mlanguage === selectedLanguage &&
                        skill.skillOccupation === appliedSkill.title && (
                          <div className="mt-3 resume-sub-headings">
                            {appliedSkill?.mtype === "Employment" &&
                              appliedSkill?.recordHide !== "Yes" && (
                                <div className="no-page-break">
                                  {!employmentHeadingDisplayed && (
                                    <>
                                      <div
                                        style={{
                                          color: "#808080",
                                          fontStyle: "italic",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel === "PWPOE"
                                          ) || {}
                                        ).mvalue ||
                                          "nf Projects / Work as Part of Employment"}
                                      </div>
                                      {(employmentHeadingDisplayed = true)}
                                    </>
                                  )}
                                  <div className="table-responsive mt-2">
                                    <table className="table table-bordered w-100 resume-skill-table">
                                      <tbody>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectLabel"
                                              ) || {}
                                            ).mvalue || "nf Project"}
                                          </th>
                                          <td
                                            style={{
                                              color: "#808080",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {appliedSkill?.projectActivity}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Duration"
                                              ) || {}
                                            ).mvalue || "nf Duration"}
                                          </th>
                                          <td>
                                            {appliedSkill?.fromDate &&
                                            appliedSkill?.toDate
                                              ? `${FormatDateTimeStampMonthAndDate(
                                                  appliedSkill?.fromDate
                                                )} -
                                  ${FormatDateTimeStampMonthAndDate(
                                    appliedSkill?.toDate
                                  )}`
                                              : FormatDateTimeStampMonthAndDate(
                                                  appliedSkill?.fromDate
                                                )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectOrganization"
                                              ) || {}
                                            ).mvalue || "nf Organization"}{" "}
                                            &{" "}
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Location"
                                              ) || {}
                                            ).mvalue || "nf Location"}
                                          </th>
                                          <td>
                                            <div>
                                              <div>
                                                {appliedSkill?.recordAnonymous ===
                                                "Yes"
                                                  ? (
                                                      content[
                                                        selectedLanguage
                                                      ].find(
                                                        (item) =>
                                                          item.elementLabel ===
                                                          "Confidential"
                                                      ) || {}
                                                    ).mvalue ||
                                                    "nf CONFIDENTIAL"
                                                  : appliedSkill?.organization}
                                              </div>
                                              <div>
                                                {appliedSkill?.location}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "DescriptionLabel"
                                              ) || {}
                                            ).mvalue || "nf Description"}
                                          </th>
                                          <td>
                                            {appliedSkill?.briefDescriptions}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectValidation"
                                              ) || {}
                                            ).mvalue || "nf Validation"}
                                          </th>
                                          <td>
                                            {userValidationData
                                              ?.filter(
                                                (val) =>
                                                  val?.requestorItemId ===
                                                    appliedSkill?.id &&
                                                  val?.skillOccupation ===
                                                    appliedSkill?.title &&
                                                  val?.validatorResponse ===
                                                    "Accept"
                                              )
                                              .map((val, index) => (
                                                <div key={index}>
                                                  <React.Fragment>
                                                    <div>
                                                      {val?.validatorComments
                                                        ? val?.validatorComments
                                                        : (
                                                            content[
                                                              selectedLanguage
                                                            ].find(
                                                              (item) =>
                                                                item.elementLabel ===
                                                                "NoRemarksFromValidator"
                                                            ) || {}
                                                          ).mvalue ||
                                                          "nf No remarks from validator"}
                                                    </div>
                                                    <div
                                                      style={{
                                                        textIndent: "2px",
                                                      }}
                                                    >
                                                      -
                                                      <b>
                                                        {val.validatorName},{" "}
                                                        {val?.relationship}
                                                      </b>
                                                    </div>
                                                  </React.Fragment>
                                                </div>
                                              ))}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            {appliedSkill?.mtype === "Own" &&
                              appliedSkill?.recordHide !== "Yes" && (
                                <div className="no-page-break">
                                  {!ownHeadingDisplayed && (
                                    <>
                                      <div
                                        style={{
                                          color: "#808080",
                                          fontStyle: "italic",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel === "PWPOI"
                                          ) || {}
                                        ).mvalue ||
                                          "nf Projects / Work as Own Initiatives"}
                                      </div>
                                      {(ownHeadingDisplayed = true)}
                                    </>
                                  )}
                                  <div className="table-responsive mt-2">
                                    <table className="table table-bordered w-100 resume-skill-table">
                                      <tbody>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectLabel"
                                              ) || {}
                                            ).mvalue || "nf Project"}
                                          </th>
                                          <td
                                            style={{
                                              color: "#808080",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {appliedSkill?.projectActivity}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Duration"
                                              ) || {}
                                            ).mvalue || "nf Duration"}
                                          </th>
                                          <td>
                                            {appliedSkill?.fromDate &&
                                            appliedSkill?.toDate
                                              ? `${FormatDateTimeStampMonthAndDate(
                                                  appliedSkill?.fromDate
                                                )} -
                                  ${FormatDateTimeStampMonthAndDate(
                                    appliedSkill?.toDate
                                  )}`
                                              : FormatDateTimeStampMonthAndDate(
                                                  appliedSkill?.fromDate
                                                )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectOrganization"
                                              ) || {}
                                            ).mvalue || "nf Organization"}{" "}
                                            &{" "}
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Location"
                                              ) || {}
                                            ).mvalue || "nf Location"}
                                          </th>
                                          <td>
                                            <div>
                                              <div>
                                                {appliedSkill?.recordAnonymous ===
                                                "Yes"
                                                  ? (
                                                      content[
                                                        selectedLanguage
                                                      ].find(
                                                        (item) =>
                                                          item.elementLabel ===
                                                          "Confidential"
                                                      ) || {}
                                                    ).mvalue ||
                                                    "nf CONFIDENTIAL"
                                                  : appliedSkill?.organization}
                                              </div>
                                              <div>
                                                {appliedSkill?.location}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "DescriptionLabel"
                                              ) || {}
                                            ).mvalue || "nf Description"}
                                          </th>
                                          <td>
                                            {appliedSkill?.briefDescriptions}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ width: "20%" }}>
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "ProjectValidation"
                                              ) || {}
                                            ).mvalue || "nf Validation"}
                                          </th>
                                          <td>
                                            {userValidationData
                                              ?.filter(
                                                (val) =>
                                                  val?.requestorItemId ===
                                                    appliedSkill?.id &&
                                                  val?.skillOccupation ===
                                                    appliedSkill?.title &&
                                                  val?.validatorResponse ===
                                                    "Accept"
                                              )
                                              .map((val, index) => (
                                                <div key={index}>
                                                  <React.Fragment>
                                                    <div>
                                                      {val?.validatorComments
                                                        ? val?.validatorComments
                                                        : (
                                                            content[
                                                              selectedLanguage
                                                            ].find(
                                                              (item) =>
                                                                item.elementLabel ===
                                                                "NoRemarksFromValidator"
                                                            ) || {}
                                                          ).mvalue ||
                                                          "nf No remarks from validator"}
                                                    </div>
                                                    <div
                                                      style={{
                                                        textIndent: "2px",
                                                      }}
                                                    >
                                                      -
                                                      <b>
                                                        {val.validatorName},{" "}
                                                        {val?.relationship}
                                                      </b>
                                                    </div>
                                                  </React.Fragment>
                                                </div>
                                              ))}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                    </React.Fragment>
                  ));
                })()}
            </React.Fragment>
          ))}

        {/* SKILL ACQUIRING */}
        <div
          className="d-flex justify-content-center align-items-center resume-skill-detail mt-2 page-break-before resume-sub-headings"
          style={{
            fontSize: `${fontSize + 4}px`,
            color: "#808080",
            fontWeight: "bold",
          }}
        >
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "MySkillsDetails"
            ) || {}
          ).mvalue || "nf MySkills Details"}
        </div>
        {skillAcquiredCount > 0 &&
          sortedArray.map((skill, index) => (
            <React.Fragment>
              <div
                className="d-flex justify-content-center mt-2 skill-heading resume-sub-headings"
                style={{
                  fontSize: `${fontSize + 4}px`,
                  color: "#808080",
                  fontWeight: "bold",
                  pageBreakBefore: index !== 0 && "always",
                }}
              >
                <React.Fragment>
                  {skill?.userRank <= 5
                    ? `Skill Rank #${skill?.userRank}`
                    : "Skill"}
                  : <span>{skill?.skill}</span>
                </React.Fragment>
              </div>
              <div
                className="d-flex justify-content-start mt-2 skill-heading resume-sub-headings"
                style={{
                  fontSize: `${fontSize + 4}px`,
                  color: "#808080",
                  fontWeight: "bold",
                }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "HowDidIAcquire?"
                  ) || {}
                ).mvalue || "nf How did I acquire this skill?"}
              </div>
              <div
                className="d-flex justify-content-center align-items-center mt-1 resume-sub-headings"
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "darkgreen",
                }}
              ></div>
              {SkillsAcquired &&
                [...SkillsAcquired]
                  .sort((a, b) => {
                    const order = [
                      "Certification",
                      "Training",
                      "Education",
                      "Conferences",
                      "Skilling",
                    ];
                    return order.indexOf(a.mtype) - order.indexOf(b.mtype);
                  })
                  .map((acqSkill) => (
                    <React.Fragment key={acqSkill?.id}>
                      {acqSkill.mlanguage === selectedLanguage &&
                        skill.skillOccupation === acqSkill.title &&
                        acqSkill?.recordHide !== "Yes" && (
                          <div
                            key={acqSkill?.id}
                            className="resume-sub-headings mt-3 no-page-break"
                          >
                            <div
                              style={{
                                color: "#808080",
                                fontStyle: "italic",
                                fontWeight: "bold",
                              }}
                            >
                              {acqSkill?.mtype}
                            </div>
                            {/* TABLE */}
                            <div className="table-responsive mt-2">
                              <table className="table table-bordered w-100 resume-skill-table">
                                <tbody>
                                  <tr>
                                    <th style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Course"
                                        ) || {}
                                      ).mvalue || "nf Course"}
                                    </th>
                                    <td
                                      style={{
                                        color: "#808080",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {acqSkill?.source}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Duration"
                                        ) || {}
                                      ).mvalue || "nf Duration"}
                                    </th>
                                    <td>
                                      {acqSkill?.fromDate && acqSkill?.toDate
                                        ? `${FormatDateTimeStampMonthAndDate(
                                            acqSkill?.fromDate
                                          )} -
                                      ${FormatDateTimeStampMonthAndDate(
                                        acqSkill?.toDate
                                      )}`
                                        : FormatDateTimeStampMonthAndDate(
                                            acqSkill?.fromDate
                                          )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Institution"
                                        ) || {}
                                      ).mvalue || "nf Institution"}{" "}
                                      &{" "}
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Location"
                                        ) || {}
                                      ).mvalue || "nf Location"}
                                    </th>
                                    <td>
                                      {
                                        <div>
                                          <div>
                                            {acqSkill?.recordAnonymous === "Yes"
                                              ? (
                                                  content[
                                                    selectedLanguage
                                                  ].find(
                                                    (item) =>
                                                      item.elementLabel ===
                                                      "Confidential"
                                                  ) || {}
                                                ).mvalue || "nf CONFIDENTIAL"
                                              : acqSkill?.organization}
                                          </div>
                                          <div>{acqSkill?.location}</div>
                                        </div>
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "DescriptionLabel"
                                        ) || {}
                                      ).mvalue || "nf Description"}
                                    </th>
                                    <td>{acqSkill?.briefDescriptions}</td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20%" }}>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectValidation"
                                        ) || {}
                                      ).mvalue || "nf Validation"}
                                    </th>
                                    {/* <td>{acqSkill?.validation}</td> */}
                                    <td>
                                      {userValidationData
                                        ?.filter(
                                          (val) =>
                                            val?.requestorItemId ===
                                              acqSkill?.id &&
                                            val?.skillOccupation ===
                                              acqSkill?.title &&
                                            val?.validatorResponse === "Accept"
                                        )
                                        .map((val, index) => (
                                          <div key={index}>
                                            {
                                              <React.Fragment>
                                                <div>
                                                  {val?.validatorComments
                                                    ? val?.validatorComments
                                                    : (
                                                        content[
                                                          selectedLanguage
                                                        ].find(
                                                          (item) =>
                                                            item.elementLabel ===
                                                            "NoRemarksFromValidator"
                                                        ) || {}
                                                      ).mvalue ||
                                                      "nf No remarks from validator"}
                                                </div>
                                                <div
                                                  style={{ textIndent: "2px" }}
                                                >
                                                  -
                                                  <b>
                                                    {val.validatorName},{" "}
                                                    {val?.relationship}
                                                  </b>
                                                </div>
                                              </React.Fragment>
                                            }
                                          </div>
                                        ))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))}
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

export default ResumeBodySkillDetail;
