import React from "react";
import ResumeHeader from "./ResumeHeader";
import ResumeBody from "./ResumeBody";
import ResumeFooter from "./ResumeFooter";
import ResumeFootnote from "./ResumeFootnote";

const ResumeContent = ({
  logo,
  content,
  selectedLanguage,
  timestamp,
  userDetails,
  selectedColor,
  fontColor,
  fontSize,
  skillAppliedCount,
  skillAcquiredCount,
  SkillsApplied,
  SkillSelected,
  SkillsAcquired,
  regionalData,
  alignment,
  isProfileImgLeft,
  handleFontColor,
  handleProfileNameColor,
  profileNameColor,
  handleProfileOtherColor,
  profieOtherDetailColor,
  handleAlignmentChange,
  handleProfileNameAlignment,
  handleOtherDetailAligment,
  profileNameAlignment,
  profileDetailAlignment,
  handleStylingOptions,
  isShowStylingOptions,
  setIsShowStylingOptions,
  handleFontPNSizeInc,
  handleFontPNSizeDec,
  profileNameSize,
  profielDetailSize,
  handleFontOtherDSizeInc,
  handleFontOtherDSizeDec,
  backgroundImage,
  top5Skills,
  remainingSkills,
  userValidationData,
  resumeSkills
}) => {
  const style = {
    "--font-size-screen": `${fontSize - 4}px`,
    "--font-size-print": `${fontSize}px`,
  };
  return (
    <React.Fragment>
      <div
        style={{
          height: "42vw",
          border: "1px solid #577126",
          padding: "2px",
          margin: "20px auto",
          backgroundColor: "#FFFFFF",
          fontSize: fontSize,
          overflowY: "auto",
          ...style,
        }}
        className="w-50 rounded-1 resume-page"
        id="resume-section"
      >
        <ResumeFootnote content={content} selectedLanguage={selectedLanguage} />
        <ResumeHeader
          logo={logo}
          content={content}
          selectedLanguage={selectedLanguage}
          fontColor={fontColor}
          profileNameColor={profileNameColor}
          profieOtherDetailColor={profieOtherDetailColor}
          timestamp={timestamp}
          userDetails={userDetails}
          headerColor={selectedColor}
          alignment={alignment}
          isProfileImgLeft={isProfileImgLeft}
          selectedColor={selectedColor}
          fontSize={fontSize}
          handleFontColor={handleFontColor}
          handleProfileNameColor={handleProfileNameColor}
          handleProfileOtherColor={handleProfileOtherColor}
          handleAlignmentChange={handleAlignmentChange}
          handleProfileNameAlignment={handleProfileNameAlignment}
          handleOtherDetailAligment={handleOtherDetailAligment}
          profileNameAlignment={profileNameAlignment}
          profileDetailAlignment={profileDetailAlignment}
          handleStylingOptions={handleStylingOptions}
          isShowStylingOptions={isShowStylingOptions}
          setIsShowStylingOptions={setIsShowStylingOptions}
          handleFontPNSizeDec={handleFontPNSizeDec}
          handleFontPNSizeInc={handleFontPNSizeInc}
          profileNameSize={profileNameSize}
          profielDetailSize={profielDetailSize}
          handleFontOtherDSizeDec={handleFontOtherDSizeDec}
          handleFontOtherDSizeInc={handleFontOtherDSizeInc}
          backgroundImage={backgroundImage}
        />
        <div>
          <ResumeBody
            logo={logo}
            content={content}
            selectedLanguage={selectedLanguage}
            timestamp={timestamp}
            userDetails={userDetails}
            skillAppliedCount={skillAppliedCount}
            skillAcquiredCount={skillAcquiredCount}
            SkillsApplied={SkillsApplied}
            SkillSelected={SkillSelected}
            SkillsAcquired={SkillsAcquired}
            regionalData={regionalData}
            fontColor={fontColor}
            fontSize={fontSize}
            handleStylingOptions={handleStylingOptions}
            isShowStylingOptions={isShowStylingOptions}
            setIsShowStylingOptions={setIsShowStylingOptions}
            top5Skills={top5Skills}
            remainingSkills={remainingSkills}
            userValidationData={userValidationData}
            resumeSkills={resumeSkills}
          />
        </div>
        <div>
          <ResumeFooter
            footerColor={selectedColor}
            fontColor={fontColor}
            content={content}
            selectedLanguage={selectedLanguage}
            backgroundImage={backgroundImage}
            handleStylingOptions={handleStylingOptions}
            isShowStylingOptions={isShowStylingOptions}
            setIsShowStylingOptions={setIsShowStylingOptions}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeContent;
