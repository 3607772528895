import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavbarComponent from "../../../../components/navbar/NavbarComponent";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SignUpForm from "./SignUpForm";
import { signupApiSimple } from "../../../../api/auth/signupApiSimple";
import { encryptData } from "../../../../config/encrypt/encryptData";
import axios from "axios";
import { BASE_URL } from "../../../../config/Properties";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../../../components/ToastNotification/showErrorToast";
import "../LoginPage.css";
import logo from "../../../../Images/logo.png";

const SignUpTemplate = () => {
  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    roles,
    regionalData: { selectedCountry },
  } = useSelector((state) => state);

  /* NAVIGATE INIT */
  const navigate = useNavigate();

  /* STATES INIT */
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("R1");
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState("");
  const [validated, setValidated] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
  const [ageConfirmationCheckBox, setAgeConfirmationCheckBox] = useState();
  const [tosCheckbox, setTosCheckBox] = useState();
  const [timeZone, setTimeZone] = useState();
  const [Country, setCountry] = useState(selectedCountry);
  const email = localStorage.getItem("auth_key");

  useEffect(() => {
    if(!email) {
      navigate("/user-auth")
    }
  }, [email, navigate]);

  /* DOCUMENT TITLE INIT */
  useEffect(() => {
    document.title = `${
      (
        content[selectedLanguage].find(
          (item) => item.elementLabel === "Title"
        ) || {}
      ).mvalue || "MySkillsTree"
    } ${
      (
        content[selectedLanguage].find(
          (item) => item.elementLabel === "SignUp"
        ) || {}
      ).mvalue || "SignUp"
    }`;
  }, [content, selectedLanguage]);

  useEffect(() => {}, [roles]);

  useEffect(() => {
    async function fetchTimeZone() {
      try {
        const check = Country?.countryCode;

        const res = await axios.get(
          `${BASE_URL}/skill/api/v1/skills/RegionalData/Time Zone Data?&searchFieldName=countryCode&searchValue=${check}%25`
        );
        setTimeZone(res?.data[0]?.abbreviation);

        const mappedArray = res?.data.map((obj) => ({
          ...obj,
          value: obj.abbreviation,
          label: obj.countryCode + " " + obj.abbreviation + " " + obj.gmtOffset,
        }));

        setTimeZone(res?.data?.[0]?.abbreviation);
      } catch (err) {
        console.error("Cant fetch TimezoneS ", err);
        showErrorToast("Cant fetch TimezoneS");
      }
    }
    fetchTimeZone();
  }, [Country]);

  /* HANDLE ROLE CHANGE */
  const handleRoleChange = useCallback((e) => {
    setSelectedRole(e.target.value);
  }, []);

  /* HANDLE SIGNUP */
  const handleSignUp = useCallback(
    async (e) => {
      setIsLoading(true);
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }

      setValidation("was-validated");
      setValidated(true);

      if (selectedRole === "" || password === "" || confirmPassword === "") {
        setIsLoading(false);
        return;
      }

      /* RETURN IF NEW PASSWORD AND CONFIRM PASSWORD IS MATCHED */
      if (password !== confirmPassword) {
        setIsLoading(false);
        return;
      }

      /* PAYLOAD */
      const payload = {
        accountId: encryptData(email),
        email: email,
        password: encryptData(password),
        confirmPassword: encryptData(password),
        role: selectedRole,
        isEncrypted: true,
        homeLanguage: selectedLanguage,
        mlanguage: selectedLanguage,
        homeCountry: Country.countryCode,
        homeTimeZone: timeZone !== undefined && timeZone,
        hideProfile: "No",
        hlShowHide: "No",
        pcShowHide: "No",
        cityShowHide: "No",
        stateShowHide: "No",
        countryShowHide: "No",
        address3ShowHide: "No",
        address1ShowHide: "No",
        address2ShowHide: "No",
        dobShowHide: "No",
        genderShowHide: "No",
        mnShowHide: "No",
        reShowHide: "No",
        memailShowHide: "No",
        mlnShowHide: "No",
        fn: "No",
      };

      try {
        const res = await signupApiSimple(payload);
        const data = res?.data;

        if (res?.status === 200 && data?.status === "success") {
          try {
            await axios.post(
              `${BASE_URL}/skill/api/v1/skills/create/User Details`,
              {
                ...payload,
                UserID: data?.userid,
                accountId: email,
              },
              {
                auth: {
                  username: email,
                  password: password,
                },
              }
            );
            setIsLoading(false);
            toast.success(data?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            navigate("/login-page");
          } catch (error) {
            console.error("ERROR OCCURED: ", error);
          }
        } else {
          setIsLoading(false);
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        setIsLoading(false);
        if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error?.message);
        } else if (error instanceof TypeError) {
          console.error("Type error occured: ", error?.message);
        } else {
          console.error("Error occured during signup: ", error);
        }
      }
    },
    [
      selectedRole,
      password,
      confirmPassword,
      email,
      navigate,
      selectedLanguage,
      timeZone,
      Country,
    ]
  );

  /* HANDLE PASSWORD SHOW */
  const handlePasswordShow = useCallback(() => {
    setIsPasswordShow((prev) => !prev);
  }, []);

  /* HANDLE CONFIRM PASSWORD SHOW */
  const handleConfirmPasswordShow = useCallback(() => {
    setIsConfirmPasswordShow((prev) => !prev);
  }, []);

  useEffect(() => {
  }, [Country]);

  return (
    <React.Fragment>
      {/* NAVBAR */}
      <NavbarComponent
        isNewLoginPage={true}
        isDisable={true}
        Country={Country}
        setCountry={setCountry}
      />

      {/* CONTENT */}
      <div className="p-5" style={{ height: "calc(-60px + 96vh)" }}>
        <div className="container-fluid" style={{ height: "100%" }}>
          <div className="row no-gutter" style={{ height: "100%" }}>
            {/* LEFT CONTENT {IMAGE} */}
            <div className="col-md-6 d-none  d-lg-flex  justify-content-center align-items-center h-100 ">
              <img
                src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1706759529/55806084555_zu7shg.png"
                alt="login-avatar"
                style={{ width: "40vw" }}
              />
            </div>

            {/* RIGHT CONTENT */}
            <SignUpForm
              content={content}
              selectedLanguage={selectedLanguage}
              validation={validation}
              validated={validated}
              isPasswordShow={isPasswordShow}
              isConfirmPasswordShow={isConfirmPasswordShow}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              handlePasswordShow={handlePasswordShow}
              handleConfirmPasswordShow={handleConfirmPasswordShow}
              handleRoleChange={handleRoleChange}
              selectedRole={selectedRole}
              handleSignUp={handleSignUp}
              isLoading={isLoading}
              roles={roles}
              FaEye={FaEye}
              FaEyeSlash={FaEyeSlash}
              ageConfirmationCheckBox={ageConfirmationCheckBox}
              setAgeConfirmationCheckBox={setAgeConfirmationCheckBox}
              tosCheckbox={tosCheckbox}
              setTosCheckBox={setTosCheckBox}
              logo={logo       }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpTemplate;
