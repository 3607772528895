import React, { useEffect, useState } from 'react'
import ShowHideIcon from '../../ShowHideIcon'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DayDifferenceToDynamicView } from '../HelperFunction/DayDifferenceToDynamicView'
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference '
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import Validation from '../../ValidationForm';
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import TableLoaders from '../../CustomLoader/TableLoaders'
import { useDispatch } from 'react-redux'
import { setValidationData } from '../../../reducer/validation/validationSlice'
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments'
import AttachmentForm from '../../AttachmentForm';
import { educationUpdate } from '../../../reducer/detailedProfile/educationSlice'
import { setDetailedProfileEditData } from '../../../reducer/edit/detailedProfileEditSlice'
import EditDetailedProfileEducation from '../Forms/Edit Forms/EditDetailedProfileEducation'
import { setDeleteDetailedProfileData } from '../../../reducer/delete/deleteDetailedProfileSlice'
import DeleteFormDetailedProfile from '../../DeleteFormDetailedProfile'
import Anonimization from '../../Anonimization'

const EducationSummary = ({ data, editEnable }) => {
    const dispatch = useDispatch();
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    const handleEdit = (data) => {
        dispatch(setDetailedProfileEditData(data));
    };

    const handleValidate = (validationData) => {
        dispatch(setValidationData(validationData));
    };

    const handleAttachment = (selectedData) => {
        dispatch(setSkillOwnerAttachment(selectedData));
    };

    /* CHANGE VALIDATION RESULTS */
    const {
        getUserValidation: { userValidationData },
    } = useSelector((state) => state);

    const [isValidationResult, setIsValidationResult] = useState(false);

    useEffect(() => {
        /* CHECK FOR MATCHING ID AND CAPTURE DATA */
        data?.data?.forEach(dataItem => {
            userValidationData?.forEach(validationItem => {
                if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
                    dispatch(educationUpdate({ id: dataItem.id, validation: "Yes" }));
                } else if (dataItem.id === validationItem.requestorItemId && validationItem.isValidated === "Yes") {
                    setIsValidationResult(true)
                } else {
                    setIsValidationResult(false)
                }
            });
        });
    }, [data, userValidationData, dispatch]);

    return (

        <>
            {/* VALIDATION MODAL */}
            <Validation />
            {<AttachmentForm />}
            {/* DETAILED PROFILE EDIT MODAL */}
            {<EditDetailedProfileEducation />}
            <DeleteFormDetailedProfile />
            {/* table start */}
            <div className="table-responsive ">
                <table className='table table-sm   table-fixed table-hover    '>
                    <thead >
                        <tr className='border-dark-subtle border-bottom '>
                            <th scope="col" className='bg-body- ' style={{ width: "32%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'InstituteCollegeUniversity') || {}).mvalue || "nf Institute/College/University"}  </th>
                            <th scope="col" className='bg-body- ' style={{ width: "19%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'CourseDegree') || {}).mvalue || "nf Course/Degree"}  </th>
                            <th scope="col" className='bg-body- ' style={{ width: "13%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "nf Duration"}     </th>
                            <th scope="col" className='bg-body- ' style={{ width: "14%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf Location"}     </th>
                            <th scope="col" style={{ width: "12%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf Validation"}   </th>
                            <th scope="col" className='bg-body- ' style={{ width: "10%" }}>              </th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            data.status === "loading" ?


                                <TableLoaders Rows={2} Cols={5} btnCols={2} />

                                : data.status === "success" && data.data.length > 0
                                && data.data.map((education, index) =>
                                (
                                    < tr className='' >
                                        <td >{education.institute ? (education.institute.length > 17 ? education.institute.substring(0, 17) + "..." : education.institute) : ''}</td>
                                        <td >{education.course ? (education.course.length > 17 ? education.course.substring(0, 17) + "..." : education.course) : ''}</td>
                                        <td>{DayDifferenceToDynamicView(
                                            education.duration
                                        )}</td>
                                        <td>{education.location ? (education.location.length > 12 ? education.location.substring(0, 12) + "..." : education.location) : ''}</td>
                                        <td className=' '>{education.validation || (isValidationResult ? "Yes" : "No")}</td>
                                        <td className='d-flex justify-content-between   ' style={{ minHeight: "26px" }}>
                                            <>
                                                <button
                                                    className="border-0 bg-white"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Validate"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#validationForm"
                                                    onClick={() => handleValidate(education)}
                                                >
                                                    <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                                                </button>
                                                <button className='border-0 bg-white'
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Attachment"
                                                    onClick={() => handleAttachment(education)}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#attachmentList">
                                                    <ImAttachment />
                                                </button>
                                            </>
                                            {editEnable && (
                                                <>

                                                    <div data-bs-toggle="modal" data-bs-target="#detailedProfileEditEducationModal" id="addModalBtn"><button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                                        onClick={() => handleEdit(education)}
                                                    ><MdEdit /></button></div>

                                                    <ShowHideIcon value={education.recordHide === 'Yes' ? true : false} toggleable={true}
                                                        form={education} fieldName={"recordHide"} api={"Skills Applied"} id={education.id} />
                                                    <Anonimization value={education.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                                                        form={education} fieldName={"recordAnonymous"} api={"Skills Applied"} id={education.id} />


                                                    <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                                        dispatch(setDeleteDetailedProfileData(education))
                                                    }}>  <MdDelete /> </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )
                                )


                        }
                    </tbody>




                </table >
            </div >
            {/* table end */}


        </>


    )
}

export default EducationSummary