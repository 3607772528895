import React from 'react'
import SeekerSkillAppliedDetail from './SkillApplied/SeekerSkillAppliedDetail'
import SmallLoader from './SmallLoader'
import SeekerSkillAcquiredDetail from './SkillAcquired/SeekerSkillAcquiredDetail'
import { useSelector } from 'react-redux'
import { DayDifferenceToDynamicView } from '../SkillOwner/HelperFunction/DayDifferenceToDynamicView'

const SkillProfileView = ({ skillProfileView, userDetail, skillPofileLoader, appliedData, acquiredData, listView = false }) => {
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);
    const RefMyRequirements = useSelector(state => state.RefMyRequirements);
    return (

        skillProfileView &&

        <div className=" font-5  border mb-2" style={{

            borderRadius: "20px",
            borderColor: "#815F0B",
            borderStyle: "solid",
            backgroundColor: listView ? "#E5E5E5" : "#ffff",

        }}
        >
            {
                userDetail?.otherSkills && userDetail?.otherSkills?.length > 0 &&
                <React.Fragment>
                    <div className='UserCardBg'>
                        <div
                            className="d-flex bg-body- justify-content-center align-items-center font-5   mb-2"
                            style={{

                                color: "#212529",
                                backgroundColor: listView ? "#E5E5E5" : "#F8F9FA",
                                fontWeight: "bolder",
                            }}
                        >
                            {(
                                content[selectedLanguage].find(
                                    (item) => item.elementLabel === "OtherSkills"
                                ) || {}
                            ).mvalue || "nf Other Skills"}
                        </div>
                        <div className="d-flex mt-1 UserCardBg" style={{ flexWrap: "wrap" }}>
                            {userDetail?.otherSkills?.map((skills) => (
                                <div
                                    key={skills?.id}
                                    className="m-2 p-2 rounded-1 print-skill"
                                    style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.125)",
                                        color: "#181818",
                                    }}
                                >
                                    {skills?.skill
                                        ? skills.skill
                                        : skills?.skillOccupation &&
                                        skills?.skillOccupation.split("| |")[0].trim()}
                                </div>
                            ))}


                        </div>
                    </div>
                </React.Fragment>
            }



            <div
                className="d-flex bg-body- justify-content-center align-items-center font-4  mb-2"
                style={{

                    color: "#212529",
                    backgroundColor: listView ? "#E5E5E5" : "#ffff",
                    fontWeight: "bolder",
                }}
            >
                {(
                    content[selectedLanguage].find(
                        (item) => item.elementLabel === "SkillProfile"
                    ) || {}
                ).mvalue || "nf SkillProfile"}
            </div>


            <div style={{ maxHeight: "400px", minHeight: "150px", overflowY: "scroll", backgroundColor: listView ? "#E5E5E5" : "#ffff", }}>
                {listView === false ?
                    userDetail?.availableSkills && userDetail?.availableSkills.map((skills) =>

                        RefMyRequirements?.skillsInRefined.some((refinedSkill) => refinedSkill.label === skills.skillOccupation) &&
                        <div className="ms-3  " style={{}} key={skills?.skillOccupation}>

                            <div className="d-flex flex-column font-5 font-weight-2 mb-2  align-items-baseline  " >
                                <div className="font-4 font-weight-2 mb-2 d-flex align-items-baseline  " >

                                    <div> {(content[selectedLanguage].find(item => item.elementLabel === 'Skill') || {}).mvalue || "nf Skill"} : {skills?.skillOccupation}</div>
                                    {skills.Rank <= 5 && <div className="mx-2 " >#{skills.Rank}</div>}
                                </div>
                                <div className='d-flex gap-4'>
                                    <p> {(content[selectedLanguage].find(item => item.elementLabel === 'AppliedExperience') || {}).mvalue || "nf AppliedExperience"} : {DayDifferenceToDynamicView(skills.AppliedExp)}</p>
                                    <p> {(content[selectedLanguage].find(item => item.elementLabel === 'AcquiredExperience') || {}).mvalue || "nf AcquiredExperience"} : {DayDifferenceToDynamicView(skills.AcquiredExp)}</p>
                                </div>
                            </div>
                            <div className="my-2  font-weight-2 font-5 ms-3">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsApplied"
                                    ) || {}
                                ).mvalue || "nf SkillsApplied"}
                            </div>
                            {skillPofileLoader ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} /> :
                                <>
                                    <SeekerSkillAppliedDetail skillName={skills?.skillOccupation} userDetails={userDetail} />
                                    {/* Skill learnt from */}
                                </>
                            }

                            <div className="my-2 mt-3 ms-3  font-weight-2 font-5">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsAcquired"
                                    ) || {}
                                ).mvalue || "nf SkillsAcquired"}
                            </div>


                            {/* table start */}
                            {skillPofileLoader ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} color={"black"} /> :
                                <>
                                    <SeekerSkillAcquiredDetail skillName={skills?.skillOccupation} userDetails={userDetail} />
                                    {/* Skill learnt from */}
                                </>
                            }
                            {/* table end */}
                        </div>
                    ) :
                    userDetail?.availableSkills && userDetail?.availableSkills.map((skills) =>


                        <div className="ms-3  " style={{}} key={skills?.skillOccupation}>

                            <div className="d-flex flex-column font-5 font-weight-2 mb-2  align-items-baseline  " >
                                <div className="font-4 font-weight-2 mb-2 d-flex align-items-baseline  " >

                                    <div> {(content[selectedLanguage].find(item => item.elementLabel === 'Skill') || {}).mvalue || "nf Skill"} : {skills?.skillOccupation}</div>
                                    {skills.Rank <= 10 && <div className="mx-2 " >#{skills.Rank}</div>}
                                </div>
                                <div className='d-flex gap-4'>
                                    <p> {(content[selectedLanguage].find(item => item.elementLabel === 'AppliedExperience') || {}).mvalue || "nf AppliedExperience"} : {DayDifferenceToDynamicView(skills.AppliedExp)}</p>
                                    <p> {(content[selectedLanguage].find(item => item.elementLabel === 'AcquiredExperience') || {}).mvalue || "nf AcquiredExperience"} : {DayDifferenceToDynamicView(skills.AcquiredExp)}</p>
                                </div>
                            </div>
                            <div className="my-2  font-weight-2 font-5 ms-3">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsApplied"
                                    ) || {}
                                ).mvalue || "nf SkillsApplied"}
                            </div>
                            {skillPofileLoader ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} /> :
                                <>
                                    <SeekerSkillAppliedDetail skillName={skills?.skillOccupation} userDetails={userDetail} />
                                    {/* Skill learnt from */}
                                </>
                            }

                            <div className="my-2 mt-3 ms-3  font-weight-2 font-5">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsAcquired"
                                    ) || {}
                                ).mvalue || "nf SkillsAcquired"}
                            </div>


                            {/* table start */}
                            {skillPofileLoader ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} color={"black"} /> :
                                <>
                                    <SeekerSkillAcquiredDetail skillName={skills?.skillOccupation} userDetails={userDetail} />
                                    {/* Skill learnt from */}
                                </>
                            }
                            {/* table end */}
                        </div>
                    )}
            </div>
        </div>

    )
}

export default SkillProfileView