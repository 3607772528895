import React, { useEffect, useRef, useState } from "react";
import "./SkillAvailerMenu.css";
import { useNavigate } from "react-router-dom";
import Validation from "../../img/validation 1.svg";
import Profile from "../../img/profile-circle-svgrepo-com (5).svg";
import skills from "../../img/skills.svg";
import Awards from "../../img/awards 1.svg";
import membership from "../../img/membership.svg";
import Languagess from "../../img/languages.svg";
import Others from "../../img/other.svg";
import { useSelector } from "react-redux";
import ValidationModal from "../Modals/ValidationModal";
import UserProfile from "../SkillingAgency/UserComponents/UserProfile";
import AttachmentIcon from "../../img/attachment.png";
import AttachmentsModal from "../Modals/AttachmentsModal";

const SkillAvailerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguage = useSelector((state) => state.language);
  const content = useSelector((state) => state.content);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="menu-menu-container" ref={menuRef}>
      <ValidationModal />
      <UserProfile />
      {/* ATTACHMENT MODAL */}
      <AttachmentsModal />

      <button
        className={`menu-button ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <svg
          fill="#F7FFDD"
          height={"1.4em"}
          width={"1.4em"}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M0 0h4v4H0V0zm0 6h4v4H0V6zm0 6h4v4H0v-4zM6 0h4v4H6V0zm0 6h4v4H6V6zm0 6h4v4H6v-4zm6-12h4v4h-4V0zm0 6h4v4h-4V6zm0 6h4v4h-4v-4z"
              fill-rule="evenodd"
            ></path>{" "}
          </g>
        </svg>
      </button>
      {isOpen && (
        <div
          className=" card menu p-3 rounded border border-5  rounded-5 shadow "
          style={{ }}
        >
          <ul className="card-body">
            <li>
              {/* <h6 class="card-title  fs-2">Skill Profile Managment 
</h6> */}
              {/* <hr class="w-100  mb-2 mb-xl-9 border-dark-subtle" /> */}

              <div className="d-flex mt-2">
                {/* PROFILE */}
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#setting"
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Profile}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image logo-img "
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyProfile"
                        ) || {}
                      ).mvalue || "nf My Profile"}
                    </h6>
                  </div>
                </div>

                {/* ATTACHMENTS */}
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#attachmentModal"
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1 me-3">
                      <img
                        src={AttachmentIcon}
                        width="350"
                        alt="Attachment icon"
                        className="profile-image logo-img"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Attachments"
                        ) || {}
                      ).mvalue || "nf Attachments"}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul className="card-body mt-2">
            <li>
              <hr class="w-100  mb-3 mb-xl-9 border-dark-subtle" />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SkillAvailerMenu;
