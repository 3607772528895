import React, { useEffect, useState } from 'react'
import ShowHideIcon from '../../ShowHideIcon'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DayDifferenceToDynamicView } from '../HelperFunction/DayDifferenceToDynamicView'
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference '
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import Validation from '../../ValidationForm';
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import TableLoaders from '../../CustomLoader/TableLoaders'
import { useDispatch } from 'react-redux'
import { setValidationData } from '../../../reducer/validation/validationSlice'
import { trainingUpdate } from '../../../reducer/detailedProfile/trainingSlice'
import AttachmentForm from '../../AttachmentForm'
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments'
import { setDetailedProfileEditData } from '../../../reducer/edit/detailedProfileEditSlice'
import EditDetailedProfileCertificationandTraining from '../Forms/Edit Forms/EditDetailedProfileCertificationandTraining'
import DeleteFormDetailedProfile from '../../DeleteFormDetailedProfile'
import { setDeleteDetailedProfileData } from '../../../reducer/delete/deleteDetailedProfileSlice'
import Anonimization from '../../Anonimization'
const TrainingSummary = ({ data, editEnable }) => {

    const dispatch = useDispatch();
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    const handleEdit = (data) => {
        dispatch(setDetailedProfileEditData(data));
    };

    const handleValidate = (validationData) => {
        dispatch(setValidationData(validationData));
    };
    const handleAttachment = (selectedData) => {
        dispatch(setSkillOwnerAttachment(selectedData));
    };

    /* CHANGE VALIDATION RESULTS */
    const {
        getUserValidation: { userValidationData },
    } = useSelector((state) => state);

    useEffect(() => {
        /* CHECK FOR MATCHING ID AND CAPTURE DATA */
        data?.data.forEach(dataItem => {
            userValidationData.forEach(validationItem => {
                if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
                    dispatch(trainingUpdate({ id: dataItem.id, validation: "Yes" }));
                }
            });
        });
    }, [data, userValidationData, dispatch]);

    return (
        <>
            {/* VALIDATION POPUP */}
            <Validation />
            {<AttachmentForm />}
            {/* DETAILED PROFILE EDIT MODAL */}
            {<EditDetailedProfileCertificationandTraining />}
            <DeleteFormDetailedProfile />
            {/* table start */}
            <div className="table-responsive ">
                <table className='table table-sm   table-fixed table-hover    '>
                    <thead >
                        <tr className='border-dark-subtle border-bottom '>
                            <th scope="col" className='bg-body- ' style={{ width: "32%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Training') || {}).mvalue || "nf Training"}  </th>
                            <th scope="col" className='bg-body- ' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "nf Organization"}  </th>
                            <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "nf Duration"}      </th>
                            <th scope="col" className='bg-body- ' style={{ width: "14%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf Location"}      </th>
                            <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf Valiation"}    </th>
                            <th scope="col" className='bg-body- ' style={{ width: "10%" }}>              </th>
                        </tr>
                    </thead>
                    <tbody className=''>

                        {data.status === "success" ?

                            data.status === "success" && data.data.length > 0 && data.data.map((trainings, index) => (

                                <tr className='' id={index}>
                                    <td >{trainings.title ? (trainings.title.length > 17 ? trainings.title.substring(0, 17) + "..." : trainings.title) : ''}</td>
                                    <td >{trainings.organization ? (trainings.organization.length > 17 ? trainings.organization.substring(0, 17) + "..." : trainings.organization) : ''}</td>
                                    <td >{DayDifferenceToDynamicView(
                                        trainings.duration
                                    )}</td>
                                    <td>{trainings.location ? (trainings.location.length > 12 ? trainings.location.substring(0, 12) + "..." : trainings.location) : ''}</td>
                                    <td className=' '>{trainings.validation}</td>
                                    <td className='d-flex justify-content-between   ' style={{ minHeight: "26px" }}>
                                        <>
                                            <button
                                                className="border-0 bg-white"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Validate"
                                                data-bs-toggle="modal"
                                                data-bs-target="#validationForm"
                                                onClick={() => handleValidate(trainings)}
                                            >
                                                <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                                            </button>
                                            <button className='border-0 bg-white'
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Attachment"
                                                onClick={() => handleAttachment(trainings)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#attachmentList">
                                                <ImAttachment />
                                            </button>
                                        </>
                                        {editEnable &&
                                            (
                                                <>

                                                    <div data-bs-toggle="modal" data-bs-target="#detailedProfileEditCertandTrainingModal" id="addModalBtn"><button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                                        onClick={() => handleEdit(trainings)}
                                                    ><MdEdit /></button></div>

                                                    <ShowHideIcon value={trainings.recordHide === 'Yes' ? true : false} toggleable={true}
                                                        form={trainings} fieldName={"recordHide"} api={"Skills Applied"} id={trainings.id} />
                                                    <Anonimization value={trainings.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                                                        form={trainings} fieldName={"recordAnonymous"} api={"Skills Applied"} id={trainings.id} />

                                                    <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                                        dispatch(setDeleteDetailedProfileData(trainings))
                                                    }}>  <MdDelete /> </button>
                                                </>
                                            )}
                                    </td>
                                </tr>)
                            )

                            :
                            <TableLoaders Rows={2} Cols={5} btnCols={2} />





                        }
                    </tbody>




                </table>
            </div>
            {/* table end */}


        </>
    )
}

export default TrainingSummary