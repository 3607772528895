import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import SecondaryBtn from "../Buttons/SecondaryBtn";
import { FaFileDownload, FaMoneyBillAlt } from "react-icons/fa";
import { IoLanguage, IoLocation } from "react-icons/io5";
import { RiContractUpDownLine } from "react-icons/ri";
import { SlArrowUp } from "react-icons/sl";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { premiumServicePrices } from "../../pages/SkillingAgency/SkillingAgencyConstants";
import { useState } from "react";
import { BASE_URL } from "../../config/Properties";
import { FaFileShield } from "react-icons/fa6";
import { useApi } from "../../context/useApi";




export default function ServiceDetails() {
  const navigate = useNavigate();
  const [showAllDetails, setShowAllDetails] = useState(false);
  const currency = useSelector((state) => state.currency.value);
  const { id } = useParams();
  const queryClient = useQueryClient()
  const {data,isLoading,isError,isFetching,error}=useApi()

  const deleteServiceData = async () => {
    const  userid = id
    const response = await axios.delete(`${BASE_URL}/skill/api/v1/skills/remove/User Services/${userid}?authToken=${localStorage.getItem("token")}` )
  
    return response.data;
  };
  const mutation = useMutation({
    
    mutationFn: deleteServiceData,
    onSuccess: () => {
      queryClient.invalidateQueries("myservices");
     navigate(-1)
    },
  });
 

  
  const filteredData = data?.data.filter((service) => service.id.includes(id)); 


  function handleClick (){
    mutation.mutate()
  }
  if (isLoading || isFetching) {
    return <Loader />;
  }
  if(isError){
    return <h1>Something went wrong{error.message}</h1>
  }
  
  else{
  return (
    <div>
      {filteredData.map((service) => {
        return (
          <>
            <div className=" row  d-flex align-items-center mb-1 mt-2">
              <div className=" col-10 d-flex gap-3">
                <div className="  ml-2" style={{ fontWeight: "bold" }}>
                  <span
                    className="mx-2"
                    style={{ color: "#577126", fontWeight: "bold" }}
                  >
                    Name :
                  </span>
                  {service.serviceName}{" "}
                </div>
                {/* <div className=' ml-2' style={{ fontWeight: 'bold' }}><span className='mx-2' style={{ color: '#577126', fontWeight: 'bold' }} >Release Status :</span>Not Released </div> */}
              </div>
              <div className="col d-flex justify-content-end">
                <SecondaryBtn label={"Delete"} backgroundColor={"#F7FFDD"} color="#815F0B" onClick={handleClick}/>
              </div>
              <div className="col d-flex justify-content-start">
                <SecondaryBtn
                  label={"Edit"}
                  backgroundColor="#F7FFDD"
                  color="#815F0B"
                  onClick={()=>navigate(`/supportservices/editservices/${service.id}`)}
                />
              </div>
              
            </div>

            <div className="d-flex gap-2 mb-2 justify-content-center align-items-center mx-2">
              <i className=" m-1">
                There are{" "}
                <i
                  className="mx-2 pill-bg-color text-white rounded-pill px-2 py-1 font-5   border-0  "
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  2 potential candidates{" "}
                </i>{" "}
                for this service
              </i>
              <div>
                <SecondaryBtn
                  label={"show"}
                  backgroundColor="#F7FFDD"
                  color="#815F0B"
                />
              </div>
            </div>

            <section class="light mt-2">
              <div class="container">
                <div className="d-flex justify-content-between  mb-4"></div>

                <article class="postcard light blue ">
                  <a class="postcard__img_link" href="#">
                    <img
                      class="postcard__img"
                      src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1704366309/Cartoon_tiny_young_programmers_and_coders_working_with_computers_xr7gjf.jpg"
                      alt="Image Title"
                    />
                  </a>

                  <div class="postcard__text p-4 t-dark">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h1 class="postcard__title blue mb-0">
                          <a href="#">{service.serviceName}</a>
                        </h1>
                        <div class="postcard__subtitle small">
                          <time
                            datetime="2020-05-25 12:00:00"
                            class="mr-2"
                          ></time>
                        </div>
                      </div>

                      <div class="d-flex justify-content-center">
                        <div class="content text-center">
                          <div class="ratings">
                            <span class="product-rating p-0">4.6</span>
                            <span>/5</span>
                            <div class="stars">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                            <div class="rating-text">
                              <span>46 ratings & 15 reviews</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="postcard__bar"></div> */}
                    <div class="postcard__preview-txt mt-2 mb-3">
                      {service.serviceDescription},
                    </div>
                    <div class="accordion-body mt-3">
                      <table class="">
                        <tbody className=" ">
                          <tr className="">
                            <th className="p-1" scope="col">
                              <span
                                className="mr-2"
                                style={{ color: "#577126", fontWeight: "bold" }}
                              >
                                Locations :
                              </span>
                            </th>
                            <td className="p-1" scope="col">
                              {" "}
                              <div
                                class="tag__item d-flex justify-content-start align-items-center px-2 py-0 rounded"
                                style={{
                                  backgroundColor: "#577126",
                                  color: "white",
                                  width: "fit-content",
                                }}
                              >
                                <IoLocation
                                  style={{
                                    fontSize: "17px",
                                    marginRight: "10px",
                                  }}
                                />
                                <span>{service.serviceLocation}</span>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <th className="p-1" scope="col">
                              {" "}
                              <span
                                className="mr-2"
                                style={{ color: "#577126", fontWeight: "bold" }}
                              >
                                Languages :
                              </span>
                            </th>
                            <td className="p-1" scope="col">
                              <div
                                class="tag__item d-flex justify-content-start align-items-center px-2 py-0 rounded"
                                style={{
                                  backgroundColor: "#577126",
                                  color: "white",
                                }}
                              >
                                <IoLanguage
                                  style={{
                                    fontSize: "17px",
                                    marginRight: "10px",
                                  }}
                                />
                                <span>{service.serviceLanguage}</span>
                              </div>
                            </td>
                          </tr>

                          
                          <tr className="">
                            <th className="p-1" scope="col">
                              {" "}
                              <span
                                className="mr-2"
                                style={{ color: "#577126", fontWeight: "bold" }}
                              >
                                Service Cost :
                              </span>
                            </th>
                            <td className="p-1" scope="col">
                              <div
                                class="tag__item d-flex justify-content-start align-items-center px-2 py-0 rounded"
                                style={{
                                  backgroundColor: "#577126",
                                  color: "white",
                                }}
                              >
                                <FaMoneyBillAlt
                                  style={{
                                    fontSize: "17px",
                                    marginRight: "10px",
                                  }}
                                />
                                {service.serviceCost} ,
                                <span>
                                  {service.serviceCostCurrency}
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr className="">
                            <th className="p-1" scope="col">
                              {" "}
                              <span
                                className="mr-2"
                                style={{ color: "#577126", fontWeight: "bold" }}
                              >
                                Service brochure :
                              </span>
                            </th>
                            <td className="p-1" scope="col">
                              <div
                                class="tag__item d-flex justify-content-start align-items-center px-2 py-0 rounded"
                                style={{
                                  backgroundColor: "#577126",
                                  color: "white",
                                }}
                              >
                                <FaFileDownload
                                  style={{
                                    fontSize: "17px",
                                    marginRight: "10px",
                                  }}
                                />
                                Download
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

       
                  </div>
                </article>
              </div>
            </section>

            {/* service details */}
          </>
        );
      })}
    </div>
  );
    }
}
