import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import { RiDragMoveFill } from 'react-icons/ri';

const ShortableItem = (props) => {
    const navigate = useNavigate()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    const handleClick = () => {
        navigate(`coursepreview/${props.course.id}`)      
      };
    return (
        <tr ref={setNodeRef} style={style} {...attributes} {...listeners}  onClick={handleClick}>
            {/* <th className='p-1' scope="row">{props.i < 5 ? props.i + 1 : ''}</th> */}
            <th className='p-1' scope="row" style={props.i < 5 ? {color:'black'} : {color:'gray'}}> {props.i + 1 }</th>
            <td className='p-1' >{props.course.courseName}</td>
            <td className='p-1' >{props.course.status}</td>
            <td className='p-1' ><RiDragMoveFill/></td>
        </tr>
    )
}

export default ShortableItem
