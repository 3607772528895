import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const UserAccountDetailsApi = async (body) => {
  const token = localStorage.getItem("token");
  const userName = localStorage.getItem("userName");

  return axios
    .get(`${BASE_URL}/skill/api/v1/skills/get-user-details/${userName}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      }).then((response) => {
        return response;
      }
    )
     
     
};

export default UserAccountDetailsApi;
