import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiSolidMessageDetail } from "react-icons/bi";
import { CiCirclePlus } from "react-icons/ci";
import { GiArtificialIntelligence } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { MdVerifiedUser } from "react-icons/md";
import { SiHiveBlockchain } from "react-icons/si";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config/Properties";
import Top5Services from "./Top5Services";
import { useApi } from "../../context/useApi";

export default function SupportServicesLeftSideBar() {
  const navigate = useNavigate();
  const [toolt, setToolt] = useState(false);
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);

  const { data, isLoading, isError, isFetching, error } = useApi();

  const setSwitchTab = () => {};
  

  return (
    <>
      <div
        class="container-fluid h-100 px-1"
        style={{ height: "calc(-60px + 96vh)" }}
      >
        <div class="row h-50 " style={{ maxHeight: "20rem" }}>
          {/* **** */}
          {/* myservices */}
          <div class="col-sm-12 ">
            <div
              className="mt-2 d-flex justify-content-center px-2  align-items-center rounded-top    "
              style={{ backgroundColor: "#577126", position: "relative" }}
            >
              <div
                className="text py-2 text-center   "
                style={{ padding: "8px 0 ", color: "#F7FFDD" }}
              >
                My Services{" "}
              </div>

              <Link
                to="/supportservices/createservice"
                style={{ position: "absolute", right: "5px" }}
              >
                <CiCirclePlus
                  style={{
                    color: "#F7FFDD",
                    fontSize: "25px",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
            {isLoading && (
              <div
                class="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <div
                  class="spinner-border"
                  style={{ width: "2rem", height: "2rem" }}
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {isError && <h1>{error.message} </h1>}
            {data ? (
              <>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className="p-1" scope="col">
                        #
                      </th>
                      <th className="p-1" scope="col">
                        Service Name
                      </th>
                      <th className="p-1" scope="col">
                        Status
                      </th>

                      <th className="p-1" scope="col">
                        {" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <Top5Services userService={data?.data} />
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div
                  className=" table-responsive font-5 "
                  style={{ height: "250px" }}
                >
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <img
                      src="https://i.postimg.cc/q7H8FSQZ/Artboard-1.png"
                      alt=""
                      className="h-100 w-100"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  :
                </div>
              </>
            )}

            <div className=" font-6  d-flex justify-content-center px-2   align-items-center ">
              <i>drag and drop your Services</i>
            </div>
          </div>

          <div class="col-sm-12 ">
            <div
              className=" rounded-top "
              style={{
                backgroundColor:
                  (
                    content[selectedLanguage]?.find(
                      (item) => item.elementLabel === "secBarBgColor"
                    ) || {}
                  ).mvalue || "#577126",
                color:
                  (
                    content[selectedLanguage]?.find(
                      (item) => item.elementLabel === "secBarFontColor"
                    ) || {}
                  ).mvalue || "#F7FFDD",
                height: "",
              }}
            >
              <div
                className="text h6 text-center   "
                style={{ padding: "8px 0" }}
              >
                {(
                  content[selectedLanguage]?.find(
                    (item) => item.elementLabel === "PremiumServices"
                  ) || {}
                ).mvalue || "not found"}
              </div>
            </div>
            <table className="table table-sm table-hover ">
              <tbody>
                <tr className=" " onClick={() => setSwitchTab("premium")}>
                  {/* <td className='' >{(content[selectedLanguage]?.find(item => item.elementLabel === 'ResumeParsing') || {}).mvalue || "not found"}</td> */}
                  <td>Course Parser</td>
                  <td>
                    <img
                      src={"/logo.jpeg"}
                      alt=""
                      style={{ width: "16px", cursor: "pointer" }}
                      className="bg-img"
                    />
                  </td>
                </tr>

                <tr onClick={() => setSwitchTab("premium")}>
                  <td>
                    {(
                      content[selectedLanguage]?.find(
                        (item) => item.elementLabel === "BlockChain"
                      ) || {}
                    ).mvalue || "not found"}
                  </td>
                  <td>
                    <SiHiveBlockchain />
                  </td>
                </tr>
                <tr onClick={() => setSwitchTab("premium")}>
                  <td>
                    {(
                      content[selectedLanguage]?.find(
                        (item) => item.elementLabel === "ThirdPartySupport"
                      ) || {}
                    ).mvalue || "not found"}
                  </td>

                  <td>
                    <MdVerifiedUser />
                  </td>
                </tr>
                <tr onClick={() => setSwitchTab("customAnalytics")}>
                  <td>
                    {(
                      content[selectedLanguage]?.find(
                        (item) => item.elementLabel === "CustomAnalytics"
                      ) || {}
                    ).mvalue || "not found"}
                  </td>
                  <td>
                    <IoMdAnalytics />
                  </td>
                </tr>
                <tr onClick={() => setSwitchTab("premium")}>
                  <td>
                    {(
                      content[selectedLanguage]?.find(
                        (item) => item.elementLabel === "ConversationalAI"
                      ) || {}
                    ).mvalue || "not found"}
                  </td>
                  <td>
                    <GiArtificialIntelligence />
                  </td>
                </tr>
                <tr onClick={() => setSwitchTab("premium")}>
                  <td>
                    {(
                      content[selectedLanguage]?.find(
                        (item) => item.elementLabel === "MessageEngine"
                      ) || {}
                    ).mvalue || "not found"}
                  </td>
                  <td>
                    <BiSolidMessageDetail />
                  </td>
                </tr>
                <tr
                  onClick={() =>
                    navigate(
                      "/skillingagency/coursesearch/premimum/translationservice"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <td>Translation Service</td>
                  <td>
                    <IoLanguage />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* {premimumservices end} */}
        </div>
      </div>
    </>
  );
}
