import React, { useState, useEffect } from "react";
import SecondaryBtn from '../Buttons/SecondaryBtn'
import SeekerSkillAppliedSummary from './SkillApplied/SeekerSkillAppliedSummary'
import { useSelector } from "react-redux";
import AcquiredSkillsUsingNameAndSkillAPI from "../../api/SkillSeeker/AcquiredSkillsUsingNameAndSkillAPI";
import SeekerSkillAcquiredDetail from "./SkillAcquired/SeekerSkillAcquiredDetail";
import SeekerSkillAcquiredSummary from "./SkillAcquired/SeekerSkillAcquiredSummary";
import SeekerSkillAppliedDetail from "./SkillApplied/SeekerSkillAppliedDetail";
import { setAcquiredSkills, setAppliedSkills, setDetailedProfileData } from "../../reducer/SkillSeeker/SkillBasedSearch/SkillBasedResultSlice";
import { useDispatch } from 'react-redux';
import AppliedSkillsUsingNameAndSkillAPI from "../../api/SkillSeeker/AppliedSkillsUsingNameAndSkillAPI";
import SmallLoader from "./SmallLoader";
import ValidationByItemIdAPI from "../../api/SkillSeeker/ValidationByItemIdAPI";
import DetailedProfileView from "./DetailedProfileView";
import { FetchDetailedDataByUsedId } from "../../api/fetchAllData/FetchDetailedDataByUsedId";
import { TablePagination } from "@mui/material";
const ListView = ({ userDetail }) => {

    // summary and details
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    const dispatch = useDispatch();
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);

    const [appliedSkillData, setAppliedSkillData] = useState([]);
    const [acquiredSkillData, setAcquiredSkillData] = useState([]);

    //const [userDetails, setUserDetails] = useState(userDetail);
    let rankOn = false;

    const [AcqAppDataLoaded, setAcqAppDataLoaded] = useState(false);
    const [detailBtnHighLight, setDetailBtnHighLight] = useState(false);
    const [skillBtnHighLight, setSkillBtnHighLight] = useState(false);
    // Create a deep copy of userDetails and sort the availalbeSkills array

    // Update the state with the sorted userDetails

    //let top5 = userDetails?.availableSkills.filter((data)=>data.Rank <=5);
    userDetail?.availableSkills.map((data) => {
        if (data.Rank <= 5) {
            rankOn = true;
            return 0;
        }
        return 1;
    });

    // Make sure to adjust the dependencies based on your component's needs
    // summary and details
    const [summary, setSummary] = useState(false);
    const [details, setDetails] = useState(false);
    const [loaderOn, setLoaderOn] = useState(true);

    //console.log("usercard ", userDetail);
    //api call for Acquired skilll

    const validationApiCaller = async (skillID) => {
        console.log("sACqval ", skillID);
        try {
            const skillValidation = await ValidationByItemIdAPI(skillID);

            console.log("sACqval ", skillValidation);
            return skillValidation;
        }
        catch (error) {
            console.log("error in validation api sACqval ", error);

        }


    }
    const AcquiredApiCaller = async (skill) => {
        try {
            const data = await AcquiredSkillsUsingNameAndSkillAPI(userDetail.AccountID, skill);
            if (data?.length > 0) {
                // Use Promise.all to wait for all validations to complete
                await Promise.all(
                    data.map(async (item) => {
                        if (item?.id) {
                            console.log("sACqval  Acquired spinning acq ");
                            const validationData = await validationApiCaller(item?.id);
                            console.log("individual validationData ", validationData);
                            item.ValidationData = validationData ? validationData : [];
                        }
                        console.log("individual Item ", item);
                    })
                );
            }
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        }
    };
    const AppliedapiCaller = async (skill) => {
        try {
            const data = await AppliedSkillsUsingNameAndSkillAPI(userDetail.AccountID, skill);
            console.log("api ", data);
            if (data?.length > 0) {
                // Use Promise.all to wait for all validations to complete
                await Promise.all(
                    data.map(async (item) => {
                        if (item?.id) {
                            console.log("sACqval Applied spinning acq ");
                            const validationData = await validationApiCaller(item?.id);
                            console.log("individual validationData ", validationData);
                            item.ValidationData = validationData ? validationData : [];
                        }
                        console.log("individual Item ", item);
                    })
                );
            }

            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        }
    };

    const apilooper = async () => {
        const promises = userDetail?.availableSkills.map(async (skills) => {
            const apidataAcquired = await AcquiredApiCaller(skills.availableSkills);
            const apidataApplied = await AppliedapiCaller(skills.availableSkills);
            if (apidataAcquired && apidataAcquired.length > 0) {
                console.log("logging in acq....");
                setAcquiredSkillData((prev) => [...prev, ...apidataAcquired]);
            }
            if (apidataApplied && apidataApplied.length > 0) {
                console.log("logging in app....");

                setAppliedSkillData((prev) => [...prev, ...apidataApplied]);
            }
        });

        // Wait for all promises to resolve
        await Promise.all(promises);
    };

    const [skillProfileView, setSkillProfileView] = useState(false);
    const handleSummaryClick1 = async () => {
        setDetails(false);
        setSkillProfileView(!skillProfileView);
        setSkillBtnHighLight(false);
        setDetailBtnHighLight(true);

        if (!AcqAppDataLoaded) {
            try {
                setAcqAppDataLoaded(true);
                // Reset acquired and applied skill data arrays
                setAcquiredSkillData([]);
                setAppliedSkillData([]);

                // Wait for all asynchronous API calls to finish
                await apilooper();
                console.log("looper ", acquiredSkillData);


            } catch (error) {
                // Handle errors appropriately
                console.error("Error fetching skills:", error);
            }
        }
        setLoaderOn(false);
    };

    useEffect(() => {
        dispatch(setAcquiredSkills({ userId: userDetail.userId, acquiredSkillData: acquiredSkillData }));
        dispatch(setAppliedSkills({ userId: userDetail.userId, appliedSkillData: appliedSkillData }));
    }, [acquiredSkillData, appliedSkillData])

    const handleDetailsSummary1 = async () => {
        setSkillProfileView(false);
        setDetails(!details);
        if (!userDetail.detailedProfileData) {
            setLoaderOn(true)
            try {
                const res = await FetchDetailedDataByUsedId(userDetail?.userId);
                dispatch(setDetailedProfileData(res));
                console.log("all details of detailed Profile ", userDetail?.detailedProfileData);


            } catch (error) {
                // Handle errors appropriately
                console.error("Error fetching skills:", error);

            }

        }

        setLoaderOn(false);
    };




    return (
        <div>
            

            <div className='d-lg-flex  font-5 py-1   '>
                
                <div className=' p-0 m-0   border-black  bg-danger ' style={{ borderWidth: "1px" }} />

                <div className='  ' style={{ width: "20%" }}>{userDetail?.LNShowHide === "Yes" || userDetail?.FNShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.FirstName + " " + userDetail.LastName}</div>
                <div style={{ width: "20%" }}>{userDetail.PrefferedLocation}</div>
                {/* <div className='    ' data-tooltip-id='my-tooltip-children-multiline' data-tooltip-place='bottom' style={{ width: "10%" }}>{userDetail?.About ? (userDetail?.About?.length > 20 ? userDetail?.About?.substring(0, 20) + "..." : userDetail?.About) : ''}</div> */}

                <div className=' ' style={{ width: "26%" }}>{userDetail?.EmailShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.Email}</div>
                <div className=' ' style={{ width: "15%" }}>{userDetail?.PhNoShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.PhoneNumber}</div>
                <div className='d-flex font-6 gap-1   ' style={{ width: "21%" }}>

                    <SecondaryBtn label="Skill Profile" onClick={handleSummaryClick1} />
                    <SecondaryBtn label="Detailed Profile" onClick={handleDetailsSummary1} />

                </div>

            </div>

            <div className='border-bottom   ' ></div>



            {skillProfileView &&
                <div className=' bg-body-tertiary'>
                    <div
                        className="d-flex bg-body- justify-content-center align-items-center  mb-2"
                        style={{
                            fontSize: `22px`,
                            color: "#212529",
                            backgroundColor: "#ffff",
                            fontWeight: "",
                        }}
                    >
                        {(
                            content[selectedLanguage].find(
                                (item) => item.elementLabel === "SkillProfile"
                            ) || {}
                        ).mvalue || "nf SkillProfile"}
                    </div>

                    {userDetail?.availableSkills && userDetail?.availableSkills.map((skills) =>


                        <div className="ms-3 " key={skills.availableSkills}>

                            <div className="font-2 font-weight-2 mb-2 d-flex align-items-baseline ">
                                <div>{skills.availableSkills}</div>
                                {skills.Rank <= 10 && <div className="mx-2 " >#{skills.Rank}</div>}
                                <div className="font-4 font-weight-1 mb-2 mx-1 d-flex align-items-center " >


                                </div>

                            </div>
                            <div className="my-2  font-weight-2 font-15px ">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsApplied"
                                    ) || {}
                                ).mvalue || "nf SkillsApplied"}
                            </div>
                            {loaderOn ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} /> :
                                <>
                                    <SeekerSkillAppliedDetail skillName={skills.availableSkills} userDetails={userDetail} />
                                    {/* Skill learnt from */}
                                </>
                            }

                            <div className="my-2 mt-3  font-weight-2 font-15px">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "SkillsAcquired"
                                    ) || {}
                                ).mvalue || "nf SkillsAcquired"}
                            </div>

                            {/* table start */}
                            {loaderOn ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} /> :
                                <>
                                    <SeekerSkillAcquiredDetail skillName={skills.availableSkills} userDetails={userDetail} />
                                    <hr />
                                    {/* Skill learnt from */}
                                </>
                            }
                            {/* table end */}

                        </div>
                    )}
                </div>
            }
            {
                details &&

                <div className="   border mb-3" style={{
                    borderTop: "none",
                    borderRadius: "20px",
                    borderColor: "#815F0B",
                    borderStyle: "solid",
                    backgroundColor: "#F8F9FA",

                }}>
                    <div
                        className="d-flex bg-body- justify-content-center align-items-center  mb-2"
                        style={{
                            fontSize: `22px`,
                            color: "#212529",
                            backgroundColor: "#F8F9FA",
                            fontWeight: "",
                        }}
                    >
                        {(
                            content[selectedLanguage].find(
                                (item) => item.elementLabel === "DetailedProfile"
                            ) || {}
                        ).mvalue || "nf DetailedProfile"}
                    </div>
                    <div style={{ maxHeight: "400px", minHeight: "150px", overflowY: "scroll" }}>
                        {loaderOn ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} /> :
                            <div className="ms-5">
                                <DetailedProfileView userDetails={userDetail} />
                                {/* Skill learnt from */}
                            </div>
                        }

                    </div>
                </div>

            }
        </div>
    )
}

export default ListView