import { combineReducers } from "redux";
import languageReducer from "./localization/languageSlice";
import contentReducer from "./localization/contentSlice";
import userReducer from "./userDetails/UserProfileSlice";
import TopSkillReducer from "./mySkills/TopSkillSlice";
import SkillsApplied from "./skillProfile/SkillsAppliedSlice";
import SkillsAcquired from "./skillProfile/SkillsAcquiredSlice";
import CertificationHistory from "../reducer/detailedProfile/certificationSlice";
import TrainingHistory from "../reducer/detailedProfile/trainingSlice";
import ConferenceHistory from "../reducer/detailedProfile/conferenceSlice";
import ProjectHistory from "../reducer/detailedProfile/projectSlice";
import EmploymentHistory from "../reducer/detailedProfile/employmentSlice";
import EducationHistory from "../reducer/detailedProfile/educationSlice";
import MembershipHistory from "../reducer/detailedProfile/membershipSlice";
import SkillingsHistory from "../reducer/detailedProfile/skillingsSlice";
import LicensesHistory from "../reducer/detailedProfile/licensesSlice";
import OtherHistory from "../reducer/detailedProfile/otherSlice";
import SkillSelectedSlice from "./mySkills/SkillSelectedSlice";
import langListReducer from "./localization/listOfLangSlice";
import RoleMappingSlice from "./roles/RoleMappingSlice";

import skillOwnerAttachmentSlice from "./attachments/skillOwnerAttachments";

import myCourseReducer from '../reducer/SkillingAgency/features/course/mycourse/myCourseSlice'
import userCoursesReducer from './SkillingAgency/CourseSearch/userCoursesSlice'
import myCurrencyReducer from '../reducer/SkillingAgency/features/course/currency/myCurrencySlice'
import SkillBasedResult from "./SkillSeeker/SkillBasedSearch/SkillBasedResultSlice";
import MyRequirement from "./SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice";
import RefMyRequirements from "./SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice";
import regionalData from "./localization/CountryRegionalSlice";
import categorySlice from "./nf specific/categorySlice";
import languageKnownSlice from "./detailedProfile/languageKnownSlice";
import achievementHistory from "./detailedProfile/achievementSlice"

/* SKILL VALIDATION */
import validationSlice from "./validation/validationSlice";
import getUserValidationSlice from "./validation/getUserValidationSlice";
import deleteDetailedProfileSlice from "./delete/deleteDetailedProfileSlice";

/* SKILLS APPLIED EDIT DATA */
import skillsAppliedEditSlice from "./edit/skillsAppliedEditSlice";
/* SKILLS ACQUIRED EDIT DATA */
import skillAcquiredEditSlice from "./edit/skillAcquiredEditSlice";
/* DETAILED PROFILE EDIT DATA */
import detailedProfileEditSlice from "./edit/detailedProfileEditSlice";
import UserAccountDetailsSlice from "./userDetails/UserAccountDetailsSlice";

/* SET SKILLS FOR RESUME SKILL PROFILE */
import resumeSkillSlice from "./resume/resumeSkillSlice";

/* SCREEN NAMEeeeee */
import screenNameSlice from "./screen/screenNameSlice";
/* COLLECTIVE ATTACHMENTS [ATTACHMENT CONOLE] */
import getUserAttachmentSlice from "./attachments/getUserAttachmentSlice";

const rootReducer = combineReducers({
  achievementHistory:achievementHistory,
  languageKnown:languageKnownSlice,
  regionalData:regionalData,
  RefMyRequirements: RefMyRequirements,
  MyRequirement: MyRequirement,
  SkillBasedResult: SkillBasedResult,
  langList: langListReducer,
  language: languageReducer,
  content: contentReducer,
  SkillSelected: SkillSelectedSlice,
  userProfile: userReducer,
  TopSkill: TopSkillReducer,
  SkillsApplied: SkillsApplied,
  SkillsAcquired: SkillsAcquired,
  certificationHistory: CertificationHistory,
  trainingHistory: TrainingHistory,
  licensesHistory:LicensesHistory,
  conferenceHistory: ConferenceHistory,
  projectHistory: ProjectHistory,
  employmentHistory: EmploymentHistory,
  educationHistory: EducationHistory,
  membershipHistory: MembershipHistory,
  skillingsHistory: SkillingsHistory,
  otherHistory: OtherHistory,
  roles: RoleMappingSlice,
  skillOwnerAttachments: skillOwnerAttachmentSlice,
  UserAccountDetails: UserAccountDetailsSlice,
  /* SPECIFIC CATEGORY */
  category: categorySlice,

  //user courses dummy data
  userCourses : userCoursesReducer,
  myCourse: myCourseReducer,
  currency: myCurrencyReducer,

  /* VALIDATION */
  skillValidation: validationSlice,
  getUserValidation: getUserValidationSlice,

  deleteDetailedProfile : deleteDetailedProfileSlice,

  /* SKILLS APPLIED EDIT */
  skillsAppliedEdit: skillsAppliedEditSlice,
  /* SKILLS ACQUIRED EDIT */
  skillsAcquiredEdit: skillAcquiredEditSlice,
  /* DETAILED PROFILE EDIT */
  detailedProfileEdit: detailedProfileEditSlice,

  /* RESUME SKILLS */
  resumeSkills: resumeSkillSlice,

  /* SCREEN NAME */
  screenName: screenNameSlice,

  /* ATTACHMENT CONSOLE */
  getUserAttachment: getUserAttachmentSlice
});

export default rootReducer;