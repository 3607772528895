import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";
import { BASE_URL } from "../config/Properties";
import axios from "axios";

export const ApiContext = createContext()

const fetchMyServices = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    let searchUrl = BASE_URL + `/skill/api/v1/skills/get-all-user-data-by-userId/User Services/${userId}?authToken=${token}`
    console.log("Url" + searchUrl);
    return axios.get(searchUrl);
  };


export function ApiProvider ({children}){

    const { data, isLoading, isError, isFetching, error } = useQuery({
        queryKey: ["myservices"],
        queryFn: fetchMyServices,
    
      });

 return(
    <ApiContext.Provider value={{data,isLoading,isError,isFetching,error}}>
        {children}
    </ApiContext.Provider>
 )
}