export const OnlyNumbersAndSpecialCharacters = (e) => {
  if (e.type === "input") {
    const inputValue = e.target.value;
    if (!/^[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(inputValue)) {
      e.target.value = "";
    }
  } else if (e.type === "keydown") {
    if (
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key) &&
      !/^[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(e.key)
    ) {
      e.preventDefault();
    }
  }
};
