import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../reducer/localization/languageSlice';

const LanguageComponent = () => {
    const selectedLanguage = useSelector(state => state.language);
    const langList = useSelector(state => state.langList);
    const content = useSelector(state => state.content);
    const userDetails = useSelector(state => state.userProfile.data);

    const dispatch = useDispatch();

    const handleLanguageChange = (event) => {
        localStorage.setItem('prevLang', selectedLanguage );
        dispatch(setLanguage(event.target.value));
        // while switch language we need to update the userid in localstorage

        const userDetailsArray = Object.values(userDetails);

        const userForSelectedLanguage = userDetailsArray.find(userObject =>
            userObject && userObject.mlanguage === selectedLanguage
        );

        if (userForSelectedLanguage) {
            localStorage.setItem('userId', userForSelectedLanguage.id);
        } else {
            console.log("No language found for the selected language.");
        }


    }



    return (
        <div className='d-none d-lg-block  '>
            <select class="   font-dd " style={{
                border: "solid 1px  ", padding: "1px", paddingLeft: "4px",
                backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryInactiveButtonBgColor') || {}).mvalue || "#F7FFDD",
                color: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryInactiveFontColor') || {}).mvalue || "#815F0B",
                borderStyle: "solid",
                fontWeight: "500", fontSize: "8.5px", borderRadius: '3px'

            }} aria-label="Default select example  "
                onChange={handleLanguageChange} value={selectedLanguage} >
                <React.Fragment className='profile'>
                    {langList.AllLanguage.length > 0 && langList.AllLanguage.map((item) =>
                        <option value={item.code} key={item.code} className='bg-body-tertiary font-dd    text-black  ' >
                            {selectedLanguage === item.code ? item.code : ` ${item.mlanguage}`}
                        </option>
                    )}
                </React.Fragment>
            </select>
        </div >
    )
}

export default LanguageComponent