import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { useDispatch } from "react-redux";
import CreateSelect from "../../SelectComponent/CreateSelect";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { fetchEducationHistory } from "../../../../api/fetchAllData/fetchEducationHistory";
import { components } from "react-select";
import degreeSearchSuggestions from "../../../../api/searchSuggestionAPIs/degreeSearchSuggestions";
import { debouncedApiRequest } from "../../../DebounceHelperFunction/debouncedApiRequest";
import { ThreeDots } from "react-loader-spinner";
import CreatableSelect from "react-select/creatable";
import { fetchDataSkillsAcquired } from "../../../../api/fetchAllData/fetchDataSkillsAcquired";
import { toTitleCase } from "../../HelperFunction/toTitleCase";
import { exceptionPOSTapi } from "../../../../api/PostData/exceptionsPOSTapi";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = (innerProps) => {
  return <></>;
};

const EditDetailedProfileEducation = () => {
  /* DISPATCH */
  const dispatch = useDispatch();

  /* STORE IMPORTS */
  const {
    detailedProfileEdit: detailedProfileEducationEditData,
    language: selectedLanguage,
    content,
    regionalData
  } = useSelector((state) => state);

  /* FORMAT THE DATES */
  const formattedStartDate =
    detailedProfileEducationEditData?.startDate &&
      detailedProfileEducationEditData?.startDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileEducationEditData?.startDate)
      : formatTimestampToDate(
        Number(detailedProfileEducationEditData?.startDate),
        regionalData?.selectedCountry?.dateFormat
      );
  const formattedEndDate =
    detailedProfileEducationEditData?.endDate &&
      detailedProfileEducationEditData?.endDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileEducationEditData?.endDate)
      : formatTimestampToDate(
        Number(detailedProfileEducationEditData?.endDate),
        regionalData?.selectedCountry?.dateFormat
      );

  /* STATE INIT */
  const [desc, setDesc] = useState(
    detailedProfileEducationEditData?.briefDescriptions || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);
  const [degData, setDegData] = useState([]);
  const [degreeSuggestionsData, setDegreeSuggestionsData] = useState([]);
  const [degSuggestionLoader, setDegSuggestionLoader] = useState(false);
  const [degSearchValue, setDegSearchValue] = useState("");
  const [newEducation, setNewEducation] = useState(
    detailedProfileEducationEditData?.course
  );
  const [isCustomDegree, setIsCustomDegree] = useState(false);

  const degSearch = (e) => {
    if (e.length > 0) {
      setDegData([]);
      setDegSuggestionLoader(true);
      debouncedApiRequest(
        degreeSearchSuggestions,
        e,
        selectedLanguage,
        setDegreeSuggestionsData,
        setDegSuggestionLoader
      );
    } else {
      setDegData([]);
    }
  };

  useEffect(() => {
    if (
      degreeSuggestionsData.length > 0 &&
      degreeSuggestionsData[0].skillOccupation !== "No suggestions found"
    ) {
      const data = degreeSuggestionsData?.map((item) => {
        const newItem = {
          ...item,
          value: item.combined,
          label: item.combined,
        };
        return newItem;
      });
      setDegData(data);
    }
  }, [degreeSuggestionsData]);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* HANDLE CREATING NEW DEGREE IN MASTER */
  const handleInsertNewDegree = useCallback(
    async (newCourseName, lang, moduleName, contentName, itemId) => {
      const payload = {
        combined: newCourseName,
        mlanguage: lang,
        educationStatus: "W",
      };
      try {
        const res = await exceptionPOSTapi("Educational Degrees", payload);
        const data = res?.data;

        handleDegreeExceptions(
          data?.applicationName,
          data?.id,
          moduleName,
          contentName,
          itemId
        );
      } catch (error) {
        console.error("Error inserting new degree name: ", error);
      }
    },
    []
  );

  /* HANDLE DEGREE EXCEPTION */
  const handleDegreeExceptions = async (
    applicationName,
    recordId,
    moduleName,
    contentName,
    itemId
  ) => {
    const body = {
      masterTable: applicationName,
      masterTableRecordID: recordId,
      module: moduleName,
      userId: localStorage.getItem("userId"),
      content: contentName,
      itemId: itemId,
      status: "New",
    };
    try {
      await exceptionPOSTapi("Exceptions", body);
    } catch (error) {
      console.error("Error while handling exceptions: ", error);
    }
  };

  /* HANDLE EDIT SUBMIT */
  const handleEditProjectDetail = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);

      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescriptions: desc,
        },
        formNames: [],
      };

      if (
        detailedProfileEducationEditData?.applicationName ===
        "Education History"
      ) {
        payload.formNames.push("Skills Acquired");
        payload.formNames.push("Education History");
        payload.editData.source = newEducation;
        payload.editData.course = newEducation;
        payload.skillsAcquired = {
          source: newEducation,
        };
        payload.educationHistory = {
          source: newEducation,
        };
      }
      try {
        const res = await EditSkillsApi(
          `${detailedProfileEducationEditData?.applicationName}`,
          detailedProfileEducationEditData?.id,
          payload
        );
        const data = res?.data;
        if (isCustomDegree) {
          handleInsertNewDegree(
            data?.editData?.course,
            data?.editData?.mlanguage,
            data?.applicationName,
            data?.editData?.course,
            data?.id
          );
        }
        setNewEducation(newEducation);
        setIsSendingData(false);
        dispatch(fetchEducationHistory());
        dispatch(fetchDataSkillsAcquired());
        setIsCustomDegree(false);
        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      dispatch,
      detailedProfileEducationEditData,
      handleModalClose,
      newEducation,
      isCustomDegree,
      handleInsertNewDegree,
    ]
  );

  useEffect(() => {
    setDesc(detailedProfileEducationEditData?.briefDescriptions);
    setNewEducation(detailedProfileEducationEditData?.course);
  }, [detailedProfileEducationEditData]);

  /* HANDLE DEGREE NAME CHANGE */
  const handleDegreeChange = (selectedOption) => {
    if (selectedOption) {
      /* CHECKING FOR THE ENTERED DEGREE VALUE PRESENT IN DB */
      const isExistingDegree = degData.some(
        (degree) => degree.label === selectedOption.label
      );

      if (!isExistingDegree) {
        /* FOR USER ENTERED VALUE */
        setNewEducation(toTitleCase(selectedOption.label));
        setIsCustomDegree(true);
      } else {
        /* FOR THE DEGREE VALUES IN DB */
        setNewEducation(selectedOption.label);
      }
    } else {
      setNewEducation("");
      setDegData([]);
    }
  };

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="detailedProfileEditEducationModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "EducationHistory"
                  ) || {}
                ).mvalue || "nf EducationHistory"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span> {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                {/* DETAILED PROFILE EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-1">
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Course"
                            ) || {}
                          ).mvalue || "nf Course"}
                        </label>
                        <CreatableSelect
                          components={{ DropdownIndicator, IndicatorSeparator }}
                          placeholder={newEducation}
                          onInputChange={(e) => {
                            setDegSearchValue(e);
                            degSearch(e);
                          }}
                          // onChange={(selectedOption) => {
                          //   if (selectedOption) {
                          //     setNewEducation(selectedOption.label);
                          //   } else {
                          //     setDegData([]);
                          //   }
                          // }}
                          onChange={handleDegreeChange}
                          // value={newEducation}
                          value={
                            newEducation && newEducation.course
                              ? {
                                label: newEducation.course,
                                value: newEducation.course,
                              }
                              : null
                          }
                          isClearable
                          options={degData}
                          isValidNewOption={() => degSearchValue && true}
                        />

                        {degSuggestionLoader && (
                          <div
                            style={{
                              transform: "translate(365px,-24px)",
                              width: "50px",
                            }}
                          >
                            <ThreeDots width={"30"} height={"10"} />
                          </div>
                        )}
                      </div>

                      {/* INSTITUTION */}
                      {detailedProfileEducationEditData?.institute && (
                        <div className="mt-2" style={{ pointerEvents: "none" }}>
                          {/* Institution: */}
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "InstituteCollegeUniversity"
                            ) || {}
                          ).mvalue || "nf Institute/College/University"}
                          <div className="mt-2">
                            <CreateSelect
                              newField="orgIsnew"
                              placeholder={
                                detailedProfileEducationEditData?.institute
                              }
                              setNewField="organization"
                              value={
                                detailedProfileEducationEditData?.institute
                              }
                            />
                          </div>
                        </div>
                      )}

                      {/* ORGANIZATION */}
                      {detailedProfileEducationEditData?.organization && (
                        <div className="mt-2" style={{ pointerEvents: "none" }}>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "OrganizationName"
                            ) || {}
                          ).mvalue || "not found"}
                          <div className="mt-2">
                            <CreateSelect
                              newField="orgIsnew"
                              placeholder={
                                detailedProfileEducationEditData?.organization
                              }
                              setNewField="organization"
                              value={
                                detailedProfileEducationEditData?.organization
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className="d-lg-flex my-2 w-100">
                        <div className=" h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "InstituteStartDate"
                              ) || {}
                            ).mvalue || "nf InstituteStartDate"}
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={ timestampToYYYYMMDD(Number(detailedProfileEducationEditData?.startDate))}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "InstituteEndDate"
                              ) || {}
                            ).mvalue || "nf InstituteEndDate"}
                          </label>
                          <input
                            type="date"
                            min={detailedProfileEducationEditData?.endDate}
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={detailedProfileEducationEditData?.endDate ? timestampToYYYYMMDD(Number(detailedProfileEducationEditData?.endDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}
                      {detailedProfileEducationEditData?.location && (
                        <div className="my-2" style={{ pointerEvents: "none" }}>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "InstituteLocation"
                              ) || {}
                            ).mvalue || "nf Institute Location"}
                          </label>

                          <div className="d-flex gap-2">
                            <div className="p-1 text-secondary rounded bg-light border">
                              {detailedProfileEducationEditData?.location}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "BriefDescriptionAboutOrganization"
                            ) || {}
                          ).mvalue || "nf Brief Description about Organization"}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescriptions"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditProjectDetail("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditProjectDetail("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditDetailedProfileEducation;
