import React, { useEffect, useState } from 'react'
import SecondaryBtn from '../../Buttons/SecondaryBtn'
import MultiSelect from '../SelectComponent/MultiSelect';
import { timestampToYYYYMMDD } from '../HelperFunction/timestampToYYYYMMDD';
import { RxCross2 } from 'react-icons/rx';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchTrainingHistory } from '../../../api/fetchAllData/fetchTrainingHistory';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import educationInstitutionApi from '../../../api/searchSuggestionAPIs/educationInstitutionApi';
import CreateSelectInstitution from '../SelectComponent/CreateSelectInstitution';
import { ThreeDots } from 'react-loader-spinner';
import SecondaryBtnLoader from '../../Buttons/SecondaryBtnLoader';
import { showErrorToast } from '../../ToastNotification/showErrorToast';
import { showSuccessToast } from '../../ToastNotification/showSuccessToast';
import { convertDateToMilliseconds } from '../HelperFunction/convertDateToMilliseconds';
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference ';
import { FormatDateIntoPost } from '../HelperFunction/FormatDateIntoPost';
import PostApi from '../../../api/PostData/PostApi';
import DatePicker from 'react-datepicker';
import { formatDateInputType } from '../HelperFunction/FormatDateInputType';
const TrainSkillConfForm = ({ formvalues, setFormValues, setValidation, setFileupload, handleSubmit, disabled, isTransSkillCertEdit, isLoading, sendTrainingToParent }) => {
    const dispatch = useDispatch();

    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const regionalData = useSelector(state => state.regionalData);

    const [onGoing, setOnGoing] = useState(false);
    const [locationForTSC, setLocationForTSC] = useState("");

    const [isAdddingCert, setIsAddingCert] = useState(false)

    const [location, setLocation] = useState("");




    const [onlineLocation, setOnlineLocation] = useState(false);

    const [online, setOnline] = useState(false);


    const [selectedTraining, setSelectedTraining] = useState(null);

    const TrainingHistory = useSelector(state => state.trainingHistory);

    useEffect(() => {
        let locations = selectedTraining?.value?.location?.split(",")
        setLocationForSkilling(locations)
    }, [selectedTraining])

    useEffect(() => {
        if (location?.length > 0) {
            setFormValues({ ...formvalues, location: location })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    const [locationForSkilling, setLocationForSkilling] = useState([])
    const [trainingOptions, setTrainingOptions] = useState([])

    useEffect(() => {
        const filterTraining = TrainingHistory?.data
            .map((employment) => ({
                value: employment,
                label: employment.title,
            }));

        setTrainingOptions(filterTraining)

    }, [])

    const handleValidateProject = () => {
        setValidation(true);
    };

    useEffect(() => {
        if (locationForSkilling?.length > 0) {
            setFormValues({ ...formvalues, location: locationForSkilling.join(',') })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationForSkilling])

    useEffect(() => {
        if (locationForTSC.length > 0) {
            setFormValues({ ...formvalues, location: locationForTSC })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationForTSC])

    // for edit mode usestate data to set location
    useEffect(() => {

        if (isTransSkillCertEdit && formvalues.location.length > 0 && locationForTSC.length === 0) {
            setLocationForTSC(formvalues.location)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTransSkillCertEdit])

    // useEffect(() => {
    //     dispatch(fetchTrainingHistory());
    // }, [])

    useEffect(() => {
    }, [formvalues])


    const handleSubmitDate = (close) => {
        handleSubmit(close)
    }

    const handleCertChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedTraining(selectedOption);
            setFormValues({
                ...formvalues,
                organization: selectedOption?.value?.organization,
                source: selectedOption?.value?.organization,
                fromDate: timestampToYYYYMMDD(Number(selectedOption?.value?.startDate)),
                toDate: (selectedOption?.value?.endDate) ? timestampToYYYYMMDD(Number(selectedOption?.value?.endDate)) : '',
                title: selectedOption?.label,
                insIsnew: selectedOption?.__isNew__,
                ticketids: selectedOption?.value?.id
            });
        } else {
            setSelectedTraining(null);
            setFormValues({
                ...formvalues,
                organization: '',
                certificationName: '',
                fromDate: '',
                toDate: ''
            });
        }

    }

    //Api institution data
    const [institutionApiData, setInstitutionApiData] = useState([]);
    const [insConvertedToSelect, setInsConvertedToSelect] = useState([]);
    const [insSearch, setInsSearch] = useState("");
    const [eduApiLoader, setEduApiLoader] = useState(false);

    useEffect(() => {
        if (insSearch.length > 0 && insSearch !== " ") {
            setEduApiLoader(true);
            debouncedApiRequest(educationInstitutionApi, insSearch, selectedLanguage, setInstitutionApiData, setEduApiLoader);
        }

    }, [insSearch])

    useEffect(() => {
        if (institutionApiData.length > 0) {
            const data = institutionApiData.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.institutionName, // Add new field 1 with a default value
                    label: item.institutionName, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setInsConvertedToSelect(data);
        }

    }, [institutionApiData])


    useEffect(() => {
        if (!selectedTraining) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }
        if (selectedTraining?.__isNew__) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }


        if (!selectedTraining?.value?.endDate) {
            setOnGoing({ ...onGoing, certificationEndDate: true })
        } else {
            setOnGoing({ ...onGoing, certificationEndDate: false })
        }

        sendTrainingToParent(selectedTraining)
    }, [selectedTraining?.label, selectedTraining])




    const handleAddTrainingHistory = () => {

        setIsAddingCert(true)
        PostApi("Training",
            {
                ...formvalues, organization: formvalues.organization, source: formvalues.organization,
                startDate: FormatDateIntoPost(formvalues.fromDate), endDate: formvalues.toDate ? FormatDateIntoPost(formvalues.toDate) : "",
                duration: calculateDaysDifference(convertDateToMilliseconds(formvalues.fromDate), formvalues.toDate ? convertDateToMilliseconds(formvalues.toDate) : Date.now()),
                briefDescription: formvalues.briefDescriptions, ticketids: formvalues?.id ? [formvalues?.id] : []

            }).then((res) => {
                // update redux store
                dispatch(fetchTrainingHistory());
                setSelectedTraining({ ...selectedTraining, value: { ...res.data, startDate: convertDateToMilliseconds(res.data.startDate), endDate: (res?.data?.endDate) ? convertDateToMilliseconds(res.data.endDate) : "" }, __isNew__: false })
                setIsAddingCert(false)
                showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'TrainingAdded') || {}).mvalue || "nf Training Added")
                setFormValues({ ...formvalues, briefDescriptions: '', ticketids: res?.data?.id })

            }).catch((err) => {
                console.error(err);
                showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong")
                setIsAddingCert(false)

            })



    }

    return (
        <>
            <div className='mb-2 ms-3'>


                <label for="exampleFormControlInput1" class="form-label ">{(content[selectedLanguage].find(item => item.elementLabel === 'TrainingName') || {}).mvalue || "nf Training Name"}  <span className='text-danger' >*</span></label>
                <CreatableSelect
                    options={trainingOptions}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#f5f5f5',
                            primary: '#815F0B',
                            primary50: '#f5f5f5',
                            neutral0: "#f8f9fA"
                        },
                    })}
                    closeMenuOnSelect={true}
                    openMenuOnClick={false}
                    components={{ DropdownIndicator: () => null }}
                    // value={selectedTraining}
                    onChange={handleCertChange}
                    isClearable={true}
                    placeholder="Enter your training name"
                />

            </div>
            <div className='ms-3 '>


                {

                    selectedTraining?.__isNew__ &&


                    <div className='' style={{ border: "solid 1px #D3D3D3", padding: "5px" }}>
                        <h5>{"Add Training"}</h5>
                        <div class="mb-2 ">
                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf InstitutionName"}<span className='text-danger' > *</span></label>
                            {/* <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues({ ...formvalues,organization: e.target.value })} value={formvalues.organization} /> */}
                            <CreateSelectInstitution
                                setInsSearch={setInsSearch}
                                insSearch={insSearch}
                                insConvertedToSelect={insConvertedToSelect}
                                formvalues={formvalues}
                                setFormValues={setFormValues}
                                showDropdown={false}

                            />
                            {eduApiLoader && <div style={{ transform: "translate(365px,-24px)", width: "50px" }}>
                                <  ThreeDots width={"30"} height={"10"} />
                            </div>}
                        </div>

                        <div className='d-lg-flex my-2 '>
                            <div className=" w-100  ">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"} <span className='text-danger' >*</span></label>
                                {/* <input type="date"

                                    max={timestampToYYYYMMDD(Number(Date.now()))}
                                    style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.fromDate} /> */}
                                <DatePicker
                                    style={{ height: "32px" }}
                                    maxDate={timestampToYYYYMMDD(Date.now())}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    id="exampleFormControlInput1"
                                    onChange={(e) => setFormValues({ ...formvalues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formvalues?.fromDate ? formvalues?.fromDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    onBlur={() => { }}
                                />
                            </div>
                            <div className=" ms-lg-2 w-100 ">
                                <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End Date"} </label>
                                {/* <input type="date"
                                    min={formvalues.fromDate}
                                    max={timestampToYYYYMMDD(Number(Date.now()))}
                                    style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.toDate}
                                    {...onGoing.certificationEndDate && { disabled: true }} /> */}
                                <DatePicker
                                    id="exampleFormControlInput1"
                                    style={{ height: "32px" }}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    minDate={formvalues?.fromDate}
                                    maxDate={timestampToYYYYMMDD(Date.now())}
                                    onChange={(e) => setFormValues({ ...formvalues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formvalues?.toDate ? formvalues?.toDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    disabled={onGoing?.certificationEndDate}
                                    onBlur={() => { }}
                                />
                                <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                    <label htmlFor="exampleFormControlInput1" className="">{(content[selectedLanguage].find(item => item.elementLabel === 'OnGoing') || {}).mvalue || "nf OnGoing"} </label>
                                    <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                        setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                        setFormValues({ ...formvalues, toDate: "" })
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="my-2">
                            <div className='d-flex justify-content-between'>
                                <label htmlFor="locationInput" className="form-label">
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}<span className='text-danger'> *</span>
                                </label>
                                <div className='d-flex align-items-center justify-align-content'>
                                    <input
                                        id="onlineCheckbox"
                                        className='ms-2'
                                        type="checkbox"
                                        name="online"
                                        checked={online}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setOnline(isChecked);
                                        }}
                                    />
                                    <label htmlFor="onlineCheckbox" className="ms-1"> {(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</label>
                                </div>
                            </div>
                            {/* <CreateSelectForLocation locationData={location}
                                setLocation={setLocation}
                                onlineStatus={toggleOnline} /> */}
                            <MultiSelect setLocationData={setLocation} viewLocation={location} onlineStatus={online} />


                            <div className="my-2 ">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                                <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" value={formvalues.briefDescriptions} name='briefDescriptions' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} >
                                </textarea>
                            </div>
                            <div className="modal-footer d-flex justify-content-end  ">

                                <div className='d-flex gap-2'>

                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'AddTraining') || {}).mvalue || "nf Add Training"} onClick={() => handleAddTrainingHistory()} backgroundColor="#815F0B" color="#F8F8E9" loading={isAdddingCert} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* new location */}
                {

                    !selectedTraining?.__isNew__ &&

                    <>
                        <div class="mb-2 ">
                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf Institution Name"}<span className='text-danger' > *</span></label>
                            <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.organization} disabled />
                        </div>

                        <div className='d-lg-flex my-2 '>
                            <div className=" w-100  ">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"} <span className='text-danger' >*</span></label>
                                {/* <input type="date"

                                    min={timestampToYYYYMMDD(Number(selectedTraining?.value?.startDate))}
                                    max={(selectedTraining?.value?.endDate) ? timestampToYYYYMMDD(Number((selectedTraining?.value?.endDate))) : Date.now()}
                                    style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.fromDate} /> */}
                                <DatePicker
                                    style={{ height: "32px" }}
                                    minDate={timestampToYYYYMMDD(Number(selectedTraining?.value?.startDate))}
                                    maxDate={(selectedTraining?.value?.endDate) ? timestampToYYYYMMDD(Number((selectedTraining?.value?.endDate))) : Date.now()}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    id="exampleFormControlInput1"
                                    onChange={(e) => setFormValues({ ...formvalues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formvalues?.fromDate ? formvalues?.fromDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    onBlur={() => { }}
                                />
                            </div>
                            <div className=" ms-lg-2 w-100 ">
                                <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End Date"}</label>
                                {/* <input type="date"

                                    min={formvalues.fromDate}
                                    max={timestampToYYYYMMDD(Number((selectedTraining?.value?.endDate) ? selectedTraining?.value?.endDate : Date.now()))}
                                    style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.toDate}
                                    {...onGoing.certificationEndDate && { disabled: true }} /> */}
                                <DatePicker
                                    id="exampleFormControlInput1"
                                    style={{ height: "32px" }}
                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                    minDate={formvalues?.fromDate}
                                    maxDate={timestampToYYYYMMDD(Number((selectedTraining?.value?.endDate) ? selectedTraining?.value?.endDate : Date.now()))}
                                    onChange={(e) => setFormValues({ ...formvalues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                    toggleCalendarOnIconClick
                                    selected={formvalues?.toDate ? formvalues?.toDate : null}
                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                    disabled={onGoing?.certificationEndDate}
                                    onBlur={() => { }}
                                />

                                {!selectedTraining?.value?.endDate &&
                                    <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                        <label htmlFor="exampleFormControlInput1" className="">{(content[selectedLanguage].find(item => item.elementLabel === 'OnGoing') || {}).mvalue || "nf OnGoing"}</label>
                                        <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                            setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                            setFormValues({ ...formvalues, toDate: "" })
                                        }} />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="my-2">

                            <div>
                                <label for="exampleFormControlInput1" class="form-label">
                                    {(
                                        content[selectedLanguage].find(
                                            (item) => item.elementLabel === "Locations"
                                        ) || {}
                                    ).mvalue || "nf locations"}
                                    <span className="text-danger me-2">*</span>

                                    <SecondaryBtn
                                        label={(content[selectedLanguage].find(item => item.elementLabel === 'Reset') || {}).mvalue || "nf reset"}
                                        onClick={() => {

                                            setLocationForSkilling(selectedTraining?.value?.location.split(","))
                                        }}
                                        backgroundColor="#F8F8E9"
                                        color="#815F0B"

                                    />
                                </label>
                            </div>

                            <div className="d-flex gap-2">

                                {locationForSkilling?.map((loc) => {
                                    return (
                                        <div className="p-1  rounded bg-light border" >
                                            {loc}
                                            <span className="text-muted px-1" style={{ cursor: 'pointer' }} onClick={() => {
                                                let newLocations = locationForSkilling?.filter((location) => {
                                                    return location !== loc
                                                })
                                                setLocationForSkilling(newLocations)
                                            }}><RxCross2 /></span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="my-2 ">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                            <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" value={formvalues.briefDescriptions} name='briefDescriptions' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} >
                            </textarea>
                        </div>
                        <div className="modal-footer d-flex justify-content-end  ">

                            <div className='d-flex gap-2'>
                                <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "nf Save"} onClick={() => handleSubmit(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isLoading} />
                                <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "nf Save & Close"} Active={true} onClick={() => handleSubmit(true)} backgroundColor="#815F0B" color="#F8F8E9" loading={isLoading} />
                            </div>
                        </div>
                    </>
                }


            </div>
        </>
    )
}

export default TrainSkillConfForm