import React, { useEffect, useRef, useState } from 'react'
import NavbarA from '../../components/SkillAvailer/NavbarA'
import RightSideBar from '../../components/RightSideBar'
import skillsData from "../../skills.json"
import UserCardA from '../../components/SkillAvailer/UserCardA'
import { LiaFileExportSolid } from "react-icons/lia"
import user from "../../Images/user.jpeg"
import placeholderImage from "../../Images/avatar-placeholder.webp"
import Footer from '../../components/Footer'
import { MdDelete, MdEdit, MdVerifiedUser } from 'react-icons/md'
import { IoMdAnalytics, IoMdArrowDropdown } from 'react-icons/io'
import PremiumService from '../../components/SkillOwner/PremiumServices/PremiumService'
import { pdfjs } from 'react-pdf';
import extractTextFromPDF from "../../components/SkillAvailer/helperFunction/extractTextFromPDF"
import { useSelector } from 'react-redux'
import FileuploadJd from '../../components/SkillAvailer/FileUploadJd'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import PremiumServicesOptionsAvailor from '../../components/PremiumServicesOptionsAvailor'
import { showWarningToast } from '../../components/ToastNotification/showWarningToast'
import { useDispatch } from 'react-redux'
import { fetchUsersBasedOnSkillSearch } from '../../api/SkillSeeker/fetchUsersBasedOnSkillSearch'
import ResultMIddlePanel from './ResultMIddlePanel'
import SidebarSeeker from './SidebarSeeker'
import SkillSeekerMenuItems from './SkillSeekerMenuItems'
import SkillingAgencyNavbar from '../../components/SkillingAgency/DashboardComponents/SkillingAgencyNavbar'
import { useLocation } from 'react-router-dom'


// to read pdf content using react-pdf for parse JD
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const JDVIewDashboard = () => {



    //stores

    const { pathname } = useLocation();
    //search results
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);


    const navbarRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('100vh');

    useEffect(() => {
        if (navbarRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            setContentHeight(`calc(98vh - ${navbarHeight}px)`);
        }
    }, []);



    // store imports
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);


    const [sidebarHeight, setSidebarHeight] = useState('100vh');

    useEffect(() => {

        if (navbarRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            setContentHeight(`calc(98vh - ${navbarHeight}px)`);
            setSidebarHeight(`calc(100vh - ${navbarHeight}px)`);
        }
    }, []);





    return (
        <div className='d-print-none'>
            <div ref={navbarRef} id="yourNavbarId" >
                {
                    pathname.includes('skillingagency') ? <SkillingAgencyNavbar /> : <NavbarA />
                }


                <hr className='p-0 m-0 ' />

                {/* secondary nav bar lap view */}

            </div>
            <div className='d-flex '>


                <SidebarSeeker sidebarHeight={sidebarHeight} menuItems={SkillSeekerMenuItems} />
                




                <Footer />
            </div>
        </div>
    )
}

export default JDVIewDashboard