import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { formatTimestampToDate } from '../HelperFunction/FormatTimestampToDate';
import { timestampToYYYYMMDD } from '../HelperFunction/timestampToYYYYMMDD';
import organizationSearchSuggestions from '../../../api/searchSuggestionAPIs/organizationSearchSuggestions';
import { useSelector } from 'react-redux';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import { ThreeDots } from 'react-loader-spinner';

const CreateSelect = ({ newField, placeholder, options, formValues, setFormValues, errors={}, setErrors=()=>{},
    setNewForm, NewForm, setNewField, value, setValue, hideCreate = false, usedIn = 'skillProfile', applyEmpFilter = false, applyEmpFilterComp = false, setValueYesOrNo }) => {



    const selectedLanguage = useSelector(state => state.language);
    const employmentHistory = useSelector(state => state.employmentHistory);


    //Api institution data
    const [orgApiData, setOrgApiData] = useState([]);
    const [insConvertedToSelect, setInsConvertedToSelect] = useState([{ value: null, label: 'Type more than 2 letters for suggestions...', isdisabled: true }]);
    const [insSearch, setInsSearch] = useState("");
    const [eduApiLoader, setEduApiLoader] = useState(false);


    const [groupedOptions, setGroupOptions] = useState(null)


    const customStyles = {

        option: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? 'gray' : 'black', // Change color for disabled options
        }),
        dropdownIndicator: () => ({ display: 'none' }),
    };

    const customStylesForNewUserForm = {
        control: (provided, state) => ({
            ...provided,
            width:'18rem',
            border: 'none',
            borderBottom: errors?.organization ? '2px solid #d9534f' : '1px solid #ced4da' , // Customize bottom border style
            borderRadius: '0',
            boxShadow: state.isFocused ? 'none' : 'none', // Remove the default focus box-shadow
            '&:hover': {
                borderBottom: errors?.organization ? '2px solid #d9534f' : '2px solid #ced4da' // Adjust hover state if needed
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: () => ({ display: 'inline' }),
        // You can add more custom styles as needed
    };



    useEffect(() => {
        if (insSearch.length > 2 && insSearch !== " ") {
            setEduApiLoader(true);
            debouncedApiRequest(organizationSearchSuggestions, insSearch, selectedLanguage, setOrgApiData, setEduApiLoader);
        } else {
            setEduApiLoader(false);
            setOrgApiData([])
            setInsConvertedToSelect([{ value: null, label: 'Type more than 2 letters for suggestions...', isdisabled: true }])

        }

    }, [insSearch])

    useEffect(() => {
        if (orgApiData.length > 0) {
            const data = orgApiData.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.organization, // Add new field 1 with a default value
                    label: item.organization, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setInsConvertedToSelect(data);
        }

    }, [orgApiData])










    const handleChange = (selectedOption) => {




        if (selectedOption) {
            if (!selectedOption?.__isNew__ && !(setNewField === 'projectActivity')) {

                setErrors({...errors,organization:false, fromDate:false , toDate:false})

                const isAlreadyPresent = employmentHistory?.data?.find((data) => {
                    return data.organization === selectedOption?.label
                })


                if (isAlreadyPresent) {
                    selectedOption = {
                        label: isAlreadyPresent.organization,
                        value: isAlreadyPresent,
                        __isNew__: false
                    }
                } else {
                    if(selectedOption?.value === 'NA'){
                        selectedOption = {...selectedOption, __isNew__: false}
                    }else{

                        selectedOption = {
                            label: selectedOption.organization,
                            value: selectedOption.organization,
                            __isNew__: true
                        }
                    }

                }

                console.log("new", selectedOption);


            }
        }


        setValue(selectedOption);
        if (selectedOption) {
            if (!selectedOption.__isNew__) {
                // if it project select else it is company select
                if (setNewField === 'projectActivity' && selectedOption?.value?.projectActivity) {
                    setFormValues({
                        ...formValues,
                        projectActivity: selectedOption?.value.projectActivity,
                        // company: selectedOption?.value,
                        fromDate: timestampToYYYYMMDD(Number(selectedOption?.value.fromDate)),
                        toDate: selectedOption?.value.toDate ? timestampToYYYYMMDD(Number(selectedOption?.value.toDate)) : '',
                        organization: selectedOption.value.organization,
                        mtype: selectedOption?.value.mtype,
                    });
                } else {
                    setFormValues({
                        ...formValues, ticketids: [selectedOption.value.id],
                        organization: selectedOption.value.organization,
                        fromDate: selectedOption?.value?.fromDate ? timestampToYYYYMMDD(Number(selectedOption?.value?.fromDate)) : "",
                        toDate: selectedOption?.value?.toDate ? timestampToYYYYMMDD(Number(selectedOption?.value?.toDate)) : "",
                    });
                }
            }
            if (selectedOption.__isNew__) {
                setFormValues({
                    ...formValues,
                    [setNewField]: selectedOption?.label,
                    [newField]: true,
                })
                setNewForm({
                    ...NewForm,
                    [setNewField]: selectedOption?.label,
                    [newField]: true,
                });
            }
        }
        // if click clear btn
        else {
            if (applyEmpFilter && applyEmpFilterComp) {
                setValueYesOrNo("0");
            }
            setFormValues({
                ...formValues,
                projectActivity: '',
                fromDate: '',
                toDate: '',
                briefDescriptions: '',
                organization: '',
                mtype: applyEmpFilter ? "Employment" : 'Own',
                [setNewField]: '',
                [newField]: false,
                location: '',
            })
            setNewForm({
                ...NewForm,
                projectActivity: '',
                fromDate: '',
                toDate: '',
                briefDescriptions: '',
                organization: '',
                mtype: applyEmpFilterComp ? "Employment" : 'Own',
                [setNewField]: '',
                [newField]: false,
                location: '',
            });
        }


    };


    // react select clear btn
    const ClearIndicator = (props) => {
        const {
            children = <div><CustomClearText /></div>,
            getStyles,
            innerProps: { ref, ...restInnerProps },

        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >
                {children}
            </div>
        );
    }
    // react select clear btn text
    const CustomClearText = () => {
        return (
            <div style={{ color: '#815F0B' }}  >Clear</div>
        );
    }

    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        if(usedIn === 'newUserForm'){
            return false
        }
        return inputValue.trim() !== '' && !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase());

    };

    const handleInputChange = (value) => {
        setInsSearch(value)
        console.log(options);
        console.log(value);
    }



    useEffect(() => {

        if (insConvertedToSelect?.length > 0) {

            if (insConvertedToSelect?.length === 1) {
                setGroupOptions([
                    {
                        label: "Suggesions",
                        options: insConvertedToSelect
                    },
                    {
                        label: "Your Employments",
                        options: options
                    },
                ])
            } else {

                setGroupOptions([
                    {
                        label: "Your Employments",
                        options: options
                    },
                    {
                        label: "Suggesions",
                        options: insConvertedToSelect
                    },
                ])

            }
        } else {

            setGroupOptions([
                {
                    label: "Suggesions",
                    options: [{ value: null, label: 'none', isdisabled: true }]
                },
                {
                    label: "Your Employments",
                    options: options
                },

            ])

        }

        console.log(insConvertedToSelect);
    }, [eduApiLoader, insConvertedToSelect])

    console.log(insConvertedToSelect);
    console.log(errors);


    const formatGroupLabel = (groupedOptions) => (
        <div style={{}}>
            <span className='fw-bold'>{groupedOptions.label}</span>

        </div>
    );

    return (
        <>
            <CreatableSelect
                // isValidNewOption={() => !hideCreate}
                isOptionDisabled={(option) => option.isdisabled}
                formatGroupLabel={formatGroupLabel}
                isValidNewOption={isValidNewOption}
                options={setNewField === 'projectActivity' || usedIn === 'detailedProfile' || usedIn === 'newUserForm' ? options : groupedOptions}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#f5f5f5',
                        primary: '#815F0B',
                        primary50: '#f5f5f5',
                        // neutral0: "#f8f9fA"
                    },
                })}
                onInputChange={setNewField === 'projectActivity' || usedIn === 'newUserForm' ? null : handleInputChange}
                closeMenuOnSelect={true}
                // openMenuOnClick={false}
                value={value}
                onChange={handleChange}
                isClearable={true}
                placeholder={placeholder}
                // components={{ ClearIndicator }}
                // components={ { DropdownIndicator: () => null }}
                createOptionPosition="first"

                styles={usedIn === 'newUserForm' ? customStylesForNewUserForm : customStyles}
            />
            {eduApiLoader && <div style={{ transform: "translate(335px,-24px)", width: "50px" }}>
                <ThreeDots width={"30"} height={"10"} />
            </div>}
        </>

    );
};

export default CreateSelect;
