import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { DayDifferenceToDynamicView } from './../../SkillOwner/HelperFunction/DayDifferenceToDynamicView';
import { calculateDaysDifference } from './../../SkillOwner/HelperFunction/CalculateDaysDifference ';
const SeekerSkillAppliedSummary = ({ skillName, userDetails }) => {
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    const [appliedData, setAppliedData] = useState([]);
    useEffect(() => {
        if (userDetails.skillapp && userDetails.skillapp !== "null" && userDetails.skillapp !== "undefined" && userDetails.skillapp.length > 0) {
            const temp = userDetails.skillapp.filter((skill) => skill.title.toLowerCase() === skillName.toLowerCase())
            setAppliedData(temp);
        }
        console.log("abc ", SkillBasedResult.data);
    }, [SkillBasedResult.data])
    useEffect(() => {

        console.log("appDataSummary ", appliedData);
    }, [appliedData])

    return (
        <>
            <div>

                <div className="mx-5 mt-2">
                    {/* table start */}

                    {appliedData.length > 0
                        ? <div className="table-responsive ">

                            <table className="table table-sm   table-fixed     ">

                                <thead>
                                    <tr className="">
                                        <th scope="col" style={{ width: "35%", }} >
                                            {(content[selectedLanguage].find((item) => item.elementLabel === "ProjectTitle") || {}
                                            ).mvalue || "nf ProjectTitle"}
                                        </th>
                                        <th scope="col" style={{ width: "13%", }} >
                                            {(content[selectedLanguage].find((item) => item.elementLabel === "Duration") || {}
                                            ).mvalue || "nf Duration"}
                                        </th>
                                        <th scope="col" className="bg-body- " style={{ width: "17%" }} >
                                            {" "}{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectType") || {}
                                            ).mvalue || "nf ProjectType"}
                                        </th>
                                        <th
                                            scope="col"
                                            className="bg-body- "
                                            style={{ width: "15%" }}
                                        >
                                            {(
                                                content[selectedLanguage].find(
                                                    (item) =>
                                                        item.elementLabel === "ProjectLocation"
                                                ) || {}
                                            ).mvalue || "nf ProjectLocation"}
                                        </th>
                                        <th scope="col" style={{ width: "12%" }}>
                                            {(
                                                content[selectedLanguage].find(
                                                    (item) =>
                                                        item.elementLabel === "Validation"
                                                ) || {}
                                            ).mvalue || "nf Validation"}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="">
                                    {appliedData.map((skill, index) =>
                                        skill.recordHide === 'Yes' ?
                                            <></>
                                            :
                                            <tr className="" key={index}>
                                                <td>{skill.projectActivity ? (skill.projectActivity.length > 22 ? skill.projectActivity.substring(0, 17) + "..." : skill.projectActivity) : (skill.organization && skill.organization.length > 17 ? skill.organization.substring(0, 17) + "..." : skill.organization)}</td>
                                                <td>{DayDifferenceToDynamicView(skill.duration)}</td>

                                                <td>
                                                    {skill.mtype === "Own" ?
                                                        (content[selectedLanguage].find(item => item.elementLabel === 'ProjectOwn') || {}).mvalue || "nf Project(O)" :
                                                        (skill.mtype === "Employment" && skill.projectActivity ?
                                                            (content[selectedLanguage].find(item => item.elementLabel === 'ProjectEmployment') || {}).mvalue || "nf Project(E)" :
                                                            (content[selectedLanguage].find(item => item.elementLabel === 'ProfessionalExperience') || {}).mvalue || "nf Professional Experience"
                                                        )
                                                    }
                                                </td>
                                                <td style={{ width: "15%" }} className='' data-tooltip-id="my-tooltip" data-tooltip-content={skill.location}>{skill.location ? skill.location.length > 12 ? skill.location.substring(0, 12) + "..." : skill.location : ""}</td>
                                                <td className='text-center '>{skill.validation}</td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        : <div className='d-flex justify-content-center mt-2 mb-3'>
                            {(content[selectedLanguage].find(item => item.elementLabel === 'NoSkillAppliedDataSeeker') || {}).mvalue || "nf No Skills Applied Expirience "}
                        </div>}

                </div>


                {/* table end */}
            </div></>
    )
}

export default SeekerSkillAppliedSummary