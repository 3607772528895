import React from 'react'
import { useSelector } from 'react-redux';
const PrimaryBtn = ({ label, onClick, style, statusTab, font = "550" }) => {

    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);

    const buttonStyle = {
        backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonOffBgColor') || {}).mvalue || "#000",
        color: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonOffFontColor') || {}).mvalue || "#FFEA00",
        borderStyle: "solid 4px",
        borderColor: "#815F0B",
        height: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonHeight') || {}).mvalue || "25px",
        fontWeight: font,
        ...style, // overriding styles from props if present
    };
    const buttonStyleA = {
        backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonOnBgColor') || {}).mvalue || "#FFEA00",
        color: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonOnFontColor') || {}).mvalue || "#000",
        borderStyle: "solid",
        borderColor: "#815F0B",
        height: (content[selectedLanguage].find(item => item.elementLabel === 'PrimaryButtonHeight') || {}).mvalue || "25px",
        fontWeight: font,
        ...style, // overriding styles from props if present
    };

    return (
        <button className='btn border px-2  py-0 ' onClick={onClick} style={statusTab ? buttonStyleA : buttonStyle}>
            {label}
        </button>
    )
}

export default PrimaryBtn