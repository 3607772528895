import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const signupApiSimple = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/skill/api/v1/skills/simple/signup`,
      payload
    );
    return res;
  } catch (error) {
    throw error;
  }
};
