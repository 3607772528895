import React from 'react';

import CreatableSelect from 'react-select/creatable';
import { timestampToYYYYMMDD } from './SkillOwner/HelperFunction/timestampToYYYYMMDD';
import { ThreeDots } from 'react-loader-spinner';


const CreatableSelectForDeg = ({degSuggestionLoader, data, setDegSearchValue, degSearch, setSelectedDeg, setFormValues, formValues, newEducation, setNewEducation, options, setCourseIsNew, setExtractedLocation }) => {


  const handleIpChange = (op) => {
    setDegSearchValue(op);
    degSearch(op);
    console.log("XXX1", degSearch);
  }




  const handleChange = (selectedOption) => {
    setSelectedDeg(selectedOption);
    console.log("selcted", selectedOption);
    if (selectedOption) {

      setFormValues({ ...formValues, course: selectedOption.label });
      setNewEducation({ ...newEducation, course: selectedOption.label });

      if (selectedOption?.value?.institute) {
        setCourseIsNew(false)
        setFormValues({ ...formValues,ticketids: selectedOption?.value?.id,course: selectedOption?.label, source: selectedOption?.value?.institute, institute: selectedOption?.value?.institute, fromDate: timestampToYYYYMMDD(Number(selectedOption?.value?.startDate)), toDate:(selectedOption?.value?.endDate) ? timestampToYYYYMMDD(Number(selectedOption?.value?.endDate)) : "" });
        setExtractedLocation(selectedOption?.value?.location.split(','))
      }else{
        setCourseIsNew(true)
        setFormValues({ ...formValues, source: '', institute: '', fromDate: '', toDate: '' , course: selectedOption?.label });
        setExtractedLocation([])
      }

    }else{
      setFormValues({ ...formValues, source: '', institute: '', fromDate: '', toDate: '' , course: '' });
      setCourseIsNew(false)
      setExtractedLocation([])
    }




  }

  return (
    <>

      <CreatableSelect
        onInputChange={(e) => handleIpChange(e)}
        onChange={handleChange}
        components={ { DropdownIndicator: () => null }}
        // isClearable={data.some((v) => !v.isFixed)}
        isClearable
        options={data.length > 0 ? data : options} />

      {degSuggestionLoader && <div style={{ transform: "translate(365px,-24px)", width: "50px" }}>
        <ThreeDots width={"30"} height={"10"} />
      </div>}
    </>


  )
}

export default CreatableSelectForDeg