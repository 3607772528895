import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import ShowHideIcon from "../../ShowHideIcon";
import { SiHiveBlockchain } from "react-icons/si";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DeleteApi from "../../../api/DeleteData/DeleteApi";
import { showErrorToast } from "../../ToastNotification/showErrorToast";
import { showSuccessToast } from "../../ToastNotification/showSuccessToast";
import { skillAcquiredDelete, skillAcquiredUpdate } from "../../../reducer/skillProfile/SkillsAcquiredSlice";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import Validation from "../../ValidationForm";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import AttachmentForm from "../../AttachmentForm";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import Anonimization from "../../Anonimization";
import DeleteForm from "../../DeleteForm";
import SkillAcquiredEdit from "../Forms/Edit Forms/SkillAcquiredEdit";
import { setSkillsAcquiredEditData } from "../../../reducer/edit/skillAcquiredEditSlice";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";

const SkillAcquiredSummary = ({ data, editEnable, edit }) => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const SkillSelected = useSelector(
    (state) => state.SkillSelected.skillOccupation
  );
  const [projectSelected, setProjectSelected] = useState(null)

  const handleDelete = (id) => {
    DeleteApi("Skills Acquired", id)
      .then((res) => {
        if (res.data.deletedStatus === "true") {
          dispatch(skillAcquiredDelete(id));
          showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'RecordDeletedSuccessfully') || {}).mvalue || "nf Record Deleted Successfully");
        } else {
          showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'YouAlreadyDeletedData') || {}).mvalue || "nf You already deleted this data");
        }
      })
      .catch((err) => {
        console.error(err);
        showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'ErrorDeletingData') || {}).mvalue || "nf Error in deleting data");
      });
  };

  const handleEdit = (data) => {
    dispatch(setSkillsAcquiredEditData(data));
  };

  /* VALIDATION HANDLING */
  const handleValidation = (data) => {
    dispatch(setValidationData(data))
  };

  /* CHANGE VALIDATION RESULTS */
  const {
    getUserValidation: { userValidationData },
  } = useSelector((state) => state);

  useEffect(() => {
    /* CHECK FOR MATCHING ID AND CAPTURE DATA */
    if (data && userValidationData) {
      data.forEach(dataItem => {
        userValidationData.forEach(validationItem => {
          if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
            dispatch(skillAcquiredUpdate({ id: dataItem.id, validation: "Yes" }));
          }
        });
      });
    }
  }, [data, userValidationData, dispatch]);

  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  return (
    <>
      {/* VALIDATION MODAL */}
      {<Validation />}
      {<AttachmentForm />}
      {<DeleteFormDetailedProfile />}
      {<SkillAcquiredEdit />}

      <div className="table-responsive ">
        <table className="table table-sm   table-fixed table-hover    ">
          <thead>
            <tr className="">
              <th scope="col" style={{ width: "26%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Name"
                  ) || {}
                ).mvalue || "nf Name"}
              </th>
              <th scope="col" style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Duration"
                  ) || {}
                ).mvalue || "nf Duration"}
              </th>
              <th scope="col" style={{ width: "17%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Type"
                  ) || {}
                ).mvalue || "nf Type "}
              </th>
              <th scope="col" style={{ width: "14%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Location"
                  ) || {}
                ).mvalue || "nf Location"}
              </th>
              <th scope="col" style={{ width: "8%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Validation"
                  ) || {}
                ).mvalue || "nf Validation"}
              </th>
              <th scope="col" style={{ width: "13%" }}></th>
            </tr>
            {/* horizontal line */}
            <tr style={{ borderColor: "gray", borderWidth: "1px" }} />
          </thead>

          <tbody className="">
            {data.map((skill, index) => (
              <>

                {skill.mlanguage === selectedLanguage &&
                  SkillSelected === skill.title && (
                    <tr className="" key={index}>
                      <td data-tooltip-id="my-tooltip" data-tooltip-content={skill.source}>{skill.source ? (skill.source.length > 25 ? skill.source.substring(0, 25) + "..." : skill.source) : ''}</td>
                      <td>
                        {DayDifferenceToDynamicView(
                          skill.duration
                        )}
                      </td>
                      <td>{skill.mtype}</td>
                      <td
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={skill.location}
                      >
                        {skill.location
                          ? skill.location.length > 12
                            ? skill.location.substring(0, 12) + "..."
                            : skill.location
                          : ""}
                      </td>
                      <td className=" ">{skill.validation}</td>
                      <td
                        className={`d-flex ${editEnable
                          ? "justify-content-between"
                          : "justify-content-evenly"
                          }`}
                        style={{ minHeight: "26px" }}
                      >
                        {editEnable ? (
                          <>
                            {/* <button
                              className="border-0 bg-white"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Validate"
                              data-bs-toggle="modal"
                              data-bs-target="#validationForm"
                              onClick={() => handleValidation(skill)}
                            >
                              <img
                                src={validationIcon}
                                alt=""
                                style={{ width: "1rem", height: "1rem" }}
                              />
                            </button> */}
                            {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Attachment" onClick={() => handleAttachment(skill)} data-bs-toggle="modal" data-bs-target="#attachmentList">
                              <ImAttachment />
                            </button> */}
                            <div data-bs-toggle="modal" data-bs-target="#skillsAcquiredEditModal" id="addModalBtn">
                              <button
                                className="border-0 bg-white"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Edit"
                                onClick={() => handleEdit(skill)}
                              >
                                <MdEdit />
                              </button>
                            </div>

                            <ShowHideIcon value={skill.recordHide === 'Yes' ? true : false} toggleable={true}
                              form={skill} fieldName={"recordHide"} api={skill.applicationName} id={skill.id} />
                            <Anonimization value={skill.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                              form={skill} fieldName={"recordAnonymous"} api={skill.applicationName} id={skill.id} />
                            {/* <button
                              className="border-0 bg-white"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Add on Blockchain"
                              style={{
                                visibility:
                                  skill.validation === "Yes"
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              <SiHiveBlockchain />
                            </button> */}
                            {/* <button
                              className="border-0 bg-white"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Trash"
                              onClick={() => handleDelete(skill.id)}
                            >
                              <MdDelete />
                            </button> */}
                            <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                              setProjectSelected(skill)
                              handleAttachment(skill)
                              handleValidation(skill)
                              dispatch(setDeleteDetailedProfileData(skill))
                            }}>  <MdDelete /> </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="border-0 bg-white"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Validate"
                              data-bs-toggle="modal"
                              data-bs-target="#validationForm"
                              onClick={() => handleValidation(skill)}
                            >
                              <img
                                src={validationIcon}
                                alt=""
                                style={{ width: "1rem", height: "1rem" }}
                              />
                            </button>
                            <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Attachment" onClick={() => handleAttachment(skill)} data-bs-toggle="modal" data-bs-target="#attachmentList">
                              <ImAttachment />
                            </button>

                          </>
                        )}
                      </td>
                    </tr>
                  )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SkillAcquiredSummary;
