import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const getHeaders = () => {
  const token = localStorage.getItem("token");
  return {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  };
};

export const EditSkillsApi = async (form, skillId, body) => {
  try {
    const res = await axios.put(
      `${BASE_URL}/skill/api/v1/skills/edit/relation/${form}/${skillId}`,
      body,
      getHeaders()
    );
    return res;
  } catch (error) {
    throw error;
  }
};
