import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const organizationSearchSuggestions = async (value, selectedLanguage) => {

  selectedLanguage  = selectedLanguage.substring(0, 2);
  let SEARCH_URI =0;
  console.log("selectedLanguage" , selectedLanguage);
  SEARCH_URI = BASE_URL +
  //http://localhost:8080/skill/api/v1/skills/searchByName/Organizations?authToken=e57411e3be76d5cd875e0cf7cee87b65&searchFieldName=mlanguage&searchString=EN&name=organization&nameSearch=tata
  `/skill/api/v1/skills/searchByName/Organizations?authToken=${localStorage.getItem("token")}&searchFieldName=mlanguage&searchString=${selectedLanguage}&name=organization&nameSearch=${value}`;
  return axios
    .get(`${SEARCH_URI}`)
    .then((response) => {
      //console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default organizationSearchSuggestions;
