import React, { useEffect, useState } from "react";
import { FaUserCircle, FaUserCheck } from "react-icons/fa";
import UserDetailsApi from "../../api/auth/UserDetailsApi";
import { useSelector } from "react-redux";
import { GetUserForSelectedLanguage } from "../SkillOwner/HelperFunction/GetUserForSelectedLanguage";

const AccountLanDetails = ({ content, selectedLanguage }) => {
  /* STATES */
  const [userDetailsArr, setUserDetailsArr] = useState([]);

  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await UserDetailsApi();
        setUserDetailsArr(res?.data);
      } catch (error) {
        console.error("Error getting user details :", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [userDetails]);
  return (
    <div className="container rounded mt-4">
      <div className="row justify-content-center mb-2 pb-2 rounded">
        <div className="col-12 bg-light pt-4 pb-4 rounded shadow-sm">
          <div className="mb-4 text-center">
            <p className="fw-bold fs-6">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProfileDetails"
                ) || {}
              ).mvalue || "nf Profile Details"}
            </p>
          </div>
          <div
            className="d-flex flex-wrap gap-4"
            style={{ fontSize: "0.9rem" }}
          >
            {userDetailsArr.map((data) => (
              <div
                key={data?.id}
                className="mb-3 d-flex align-items-center p-2 rounded"
                style={{ border: "1px solid rgb(87, 113, 38)", gap: "1rem" }}
              >
                <div>
                  <div className="mr-3">
                    {userDetails?.id === data?.id ? (
                      <FaUserCheck size={24} color="rgb(87, 113, 38)" /> 
                    ) : (
                      <FaUserCircle size={24} color="gray" /> 
                    )}
                  </div>
                </div>
                <div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Name"
                        ) || {}
                      ).mvalue || "nf Name"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{`${data?.firstName} ${data?.lastName}`}</span>
                  </div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "PhoneNumber"
                        ) || {}
                      ).mvalue || "nf Phone Number"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{data?.mobileNumber}</span>
                  </div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "EmailAddress"
                        ) || {}
                      ).mvalue || "nf Email Address"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{data?.email}</span>
                  </div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ProfileLanguage"
                        ) || {}
                      ).mvalue || "nf Profile Language"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{data?.mlanguage}</span>
                  </div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Country"
                        ) || {}
                      ).mvalue || "nf Country"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{data?.userCountry}</span>
                  </div>
                  <div className="mb-2">
                    <strong>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "State"
                        ) || {}
                      ).mvalue || "nf State"}
                      :{" "}
                    </strong>
                    <span className="ml-2">{data?.userState}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLanDetails;
