import React from 'react'
import { BsFillBriefcaseFill } from 'react-icons/bs'
import { FaSearch } from 'react-icons/fa'
const SkillSeekerMenuItems = [
  {
    id: 1,
    path: "jdsearch",
    icon: <BsFillBriefcaseFill />,
    name: "My JD'S"

  },
  {
    id: 2,
    path: "conventionalsearch",
    icon: <FaSearch />,
    name: "Skill Search"

  },
]

export default SkillSeekerMenuItems;
