import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const AcquiredSkillsUsingNameAndSkillAPI = async (userId, skill) => {

  const SEARCH_URI = BASE_URL + `/skill/api/v1/skills/get/Skills_Acquired/${userId}/${skill}?&authToken=${localStorage.getItem("token")}`;
  return axios
  .get(SEARCH_URI)
    .then((response) => {
      console.log("response skill Acquireds", response.data);
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default AcquiredSkillsUsingNameAndSkillAPI;
