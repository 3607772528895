import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  editValidationReq,
  postValidation,
} from "../api/validations/validationApi";
import { useSelector } from "react-redux";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { MdEdit } from "react-icons/md";
import ShowHideIcon from "../components/ShowHideIcon";
import SmallLoader from "./SkillAvailer/SmallLoader";
import { useDispatch } from "react-redux";
import { fetchValidation } from "../reducer/validation/getUserValidationSlice";
import EditApi from "../api/editData/EditApi";
import { formatTimestampToDate } from "./SkillOwner/HelperFunction/FormatTimestampToDate";
import { ImAttachment } from "react-icons/im";
import { showSuccessToast } from "./ToastNotification/showSuccessToast";
import { showErrorToast } from "./ToastNotification/showErrorToast";
import refreshIcon from "../Images/refresh-page-option.png";
import { BASE_URL } from "../config/Properties";
import { OnlyNumbersAndSpecialCharacters } from "./SkillOwner/HelperFunction/OnlyNumbersAndSpecialCharacters";
import { GetUserForSelectedLanguage } from "./SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import AttachmentSelectCard from "./Attachment/AttachmentSelectCard";
import { MakeAttachmentRelationPostApi } from "../api/Attachment  API/Attachment Console/MakeRelationApi";
import { fetchUserAttachment } from "../reducer/attachments/getUserAttachmentSlice";
import { GetAttachment } from "../api/Attachment  API/DownloadAttachmentApi";
import DeleteApi from "../api/DeleteData/DeleteApi";

const Validation = () => {
  /* STATES */
  const [whoValidates, setWhoValidates] = useState("");
  const [relationship, setRelationship] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isValidationReqOpen, setIsValidationReqOpen] = useState(true);
  const [isRaiseValidationReqOpen, setIsRaiseValidationReqOpen] =
    useState(false);
  const [issendingReq, setIsSendingReq] = useState(false);
  const [isSendingEditReq, setIisSendingEditReq] = useState(false);
  const [validationReqData, setValidationReqData] = useState([]);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [validationEditData, setValidationEditData] = useState({});
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [attachmentFileNames, setAttachmentFileNames] = useState([]);
  const [showAttachmentError, setShowAttachmentError] = useState(false);
  const [resendButtonDisabled, setResendButtonDisabled] = useState({});
  const [showAttachmentCard, setShowAttachmentCard] = useState(false);
  /* SELECTED ATTACHMENTS */
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  /* STATE FOR VALIDATOR RESPONSE */
  const [valResponse] = useState("Request Sent");

  /* DISPATCH THE LATEST ADDITION IN STATE */
  const dispatch = useDispatch();

  /* USER ID */
  const userId = localStorage.getItem("userId");
  /* USER ROLE */
  const role_ = localStorage.getItem("USER_ROLE");

  /* STORE IMPORTS */
  const {
    skillValidation: validationData,
    getUserValidation: { userValidationData },
    getUserAttachment: { userAttachmentData },
    language: selectedLanguage,
    SkillsApplied,
    SkillsAcquired,
    SkillSelected,
    content,
    regionalData,
  } = useSelector((state) => state);

  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  useEffect(() => {
    /* STORE THE FILTERED VALUE FOR VALIDATION REQUEST TABLE */
    const filterValidationReqData = userValidationData?.filter(
      (val) => val?.requestorItemId === validationData?.id
    );
    setValidationReqData(filterValidationReqData);
  }, [validationData, selectedLanguage, content, userValidationData]);

  const [selectedId, setSelectedId] = useState(null);

  const showMoreDetails = useCallback(
    (valId) => {
      setSelectedId(valId === selectedId ? null : valId);
    },
    [selectedId]
  );

  // useEffect(() => {
  //   dispatch(fetchValidation());
  // }, [SkillSelected, SkillsAcquired, SkillsApplied, dispatch]);

  /* RESET FORM */
  const resetReqForm = () => {
    setWhoValidates("");
    setRelationship("");
    setEmail("");
    setMobile("");
    setRemarks("");
  };

  const handleFileSelection = (event) => {
    const selectedFiles = event.target.files;
    const totalFiles = selectedFiles.length + attachmentFiles.length;

    if (totalFiles > 4) {
      setShowAttachmentError(true);
      setTimeout(() => {
        setShowAttachmentError(false);
      }, 5000);
      return;
    }

    const fileArray = Array.from(selectedFiles);

    /* LIMIT 4 */
    const filesToUpload = fileArray.slice(0, 4 - attachmentFiles.length);

    /* CONCAT NEW FILE WITH THE EXISTING ONE */
    setAttachmentFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
  };

  /* HANDLE FILE REMOVAL */
  const handleRemoveAttachment = (indexToRemove) => {
    setAttachmentFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  /* HANDLE FILE REMOVE FOR EDIT */
  const handleRemoveAttachmentForEdit = async (indexToRemove, id) => {
    try {
      const {
        applicationName,
        createdTime,
        id,
        expired,
        ...previousValidationData
      } = validationEditData;

      /* FORMAT THE DATE */
      previousValidationData.dateSent = formatTimestampToDate(
        Number(validationEditData?.dateSent),
        regionalData?.selectedCountry?.dateFormat
      );
      /* PARSE THE ATTACHMENT FILE NAMES */
      let attachments = JSON.parse(
        previousValidationData?.requestorAttachmentFileNames
      );
      attachments.splice(indexToRemove, 1);
      /* PAYLOAD */
      previousValidationData.requestorAttachmentFileNames =
        JSON.stringify(attachments);

      await EditApi(
        "validation requests",
        validationEditData?.id,
        previousValidationData
      );
      /* DISPATCH DATA */
      dispatch(fetchValidation());
    } catch (error) {
      console.error("Error remving attachment from the validation: ", error);
    }
    try {
      await DeleteApi("Attachment Map", id);
      /* DISPATCH ADVANCE DATA */
      dispatch(fetchUserAttachment());
    } catch (error) {
      console.error("Error deleting attachment detail: ", error);
    }
    setAttachmentFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, index) => index !== indexToRemove);
      return newFiles
        .slice(0, indexToRemove)
        .concat(newFiles.slice(indexToRemove + 2));
    });
  };

  const containsFileName = (obj) => {
    return obj.name.includes('"filename"');
  };

  const doesNotContainKeys = (obj) => {
    return (
      !obj.name.includes('"filename"') &&
      !obj.name.includes('"fileId"') &&
      !obj.name.includes('"fileTitle"')
    );
  };

  /* HANDLE FILE SELECTION FOR EDITING */
  const handleEditFileSelection = (event) => {
    const elementsWithFileName = attachmentFiles.filter(containsFileName);
    const elementsWithoutKeys = attachmentFiles.filter(doesNotContainKeys);

    const selectedFiles = event.target.files;
    const totalFiles =
      selectedFiles.length + elementsWithFileName.length + elementsWithoutKeys;

    if (totalFiles > 4) {
      setShowAttachmentError(true);
      setTimeout(() => {
        setShowAttachmentError(false);
      }, 5000);
      return;
    }

    const fileArray = Array.from(selectedFiles);

    const filesToUpload = fileArray.slice(0, 4 - attachmentFiles.length);

    setAttachmentFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
  };

  /* HANDLING THE RESEND ACTIVATION */
  /* THIS IS FOR DISABLING */
  const disableResendButton = (validationRequestId) => {
    setResendButtonDisabled((prevState) => ({
      ...prevState,
      [validationRequestId]: true,
    }));
  };

  /* THIS IS FOR ENABLING AFTER 24HOURS */
  const enableResendButton = (validationRequestId) => {
    setTimeout(() => {
      setResendButtonDisabled((prevState) => ({
        ...prevState,
        [validationRequestId]: false,
      }));
    }, 24 * 60 * 60 * 1000);
  };

  /* HANDLE VALIDATION */
  const handleValidation = useCallback(async () => {
    /* SET LOADER */
    setIsSendingReq(true);

    /* GET CURRENT DATE */
    const currentDate = new Date();

    const formattedDate = `${
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }/${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }/${currentDate.getFullYear()}`;

    /* PAYLOAD */
    const data = {
      dateSent: formattedDate,
      validatorName: whoValidates,
      relationship: relationship,
      validatorEmail: email,
      validatorPhone: mobile,
      isValidated: "No",
      description: remarks,
      skillOccupation: SkillSelected?.skillOccupation,
      userId: localStorage.getItem("userId"),
      requestorId: localStorage.getItem("userId"),
      requestorItemId: validationData?.id,
      validatorResponse: valResponse,
      validatorComments: "",
      remarks: remarks,
      mlanguage: localStorage.getItem("HLang"),
      invitationLink: "null",
      requestorAttachmentUrls: "",
      requestorAttachmentFileNames:
        selectedAttachments?.length && JSON.stringify(selectedAttachments),
      validatorAttachmentUrls: "",
      validatorAttachmentFileNames: "",
      ticketids: [validationData?.id],
      keyTable: validationData?.applicationName,
      keyName:
        validationData?.certificationName ||
        validationData?.organization ||
        validationData?.projectActivity ||
        validationData?.institute ||
        validationData?.applicationName,
    };

    /* FORM DATA INIT */
    const formData = new FormData();
    /* MAKE IT BLOB */
    formData.append(
      "json",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );

    for (const key of Object.keys(attachmentFiles)) {
      formData.append("file", attachmentFiles[key]);
    }

    try {
      const res = await postValidation(formData);
      setIsSendingReq(false);
      resetReqForm();
      showSuccessToast("Request Sent Successfully");
      /* DATA VAR */
      const data = res?.data;

      /* CREATE RECORD IN ATTACHMENT MAP */
      const newUploadedFiles = selectedAttachments.map((file) => ({
        filename: file.fileName,
        fileId: file.fileId,
        fileTitle: file.fileTitle || "",
      }));

      /* ATTACHMENT RELATION PROMISE */
      const attachmentRelationPromises = newUploadedFiles.map((file) => {
        const body = {
          userId: userId,
          fileId: file?.fileId,
          fileName: file?.filename,
          fileTitle: file?.fileTitle,
          linkedId: data?.id,
          linkedApplicationName: data?.applicationName,
          roleName: role_
        };
        return MakeAttachmentRelationPostApi(body);
      });

      try {
        await Promise.all(attachmentRelationPromises);
        /* DISPATCH DATA */
        dispatch(fetchUserAttachment());
      } catch (error) {
        console.error(
          "Error POSTing attachment relation in validation: ",
          error
        );
      }

      setSelectedAttachments([]);
      /* DISPATCH DATA */
      dispatch(fetchValidation());

      /* OPEN VALIDATION CONSOLE */
      setIsValidationReqOpen((prev) => !prev);
      setIsRaiseValidationReqOpen(false);

      /* RESETTING THE ATTACHMENT */
      setAttachmentFiles([]);
      setAttachmentFileNames([]);
      setLoading(false);
      setShowAttachmentCard(false);

      /* MAKING RESEND BUTTON DISABLE */
      disableResendButton(res?.data?.id);
      /* MAKING THE RESEND BUTTON ENABLE */
      enableResendButton(res?.data?.id);
    } catch (error) {
      console.error("Error posting for validation: ", error);
      setIsSendingReq(false);
      showErrorToast(`${error?.message}`);
    }
  }, [
    email,
    mobile,
    relationship,
    whoValidates,
    SkillSelected,
    remarks,
    validationData,
    valResponse,
    attachmentFiles,
    selectedAttachments,
    userId,
    dispatch,
    role_
  ]);

  /* HANDLE VALIDATIO REQ EDIT */
  const sendEditData = (data) => {
    setValidationEditData(data);

    if (data.requestorAttachmentFileNames) {
      const fileNamesArray = data.requestorAttachmentFileNames.split(",");

      const files = fileNamesArray.map((fileName) => ({
        name: fileName,
      }));

      setAttachmentFiles(files);
      setAttachmentFileNames(fileNamesArray);
    } else {
      setAttachmentFiles([]);
      setAttachmentFileNames([]);
    }
    setIsEditEnabled(true);
  };

  const editFun = useCallback(() => {
    if (isEditEnabled) {
      setWhoValidates(validationEditData?.validatorName);
      setRelationship(validationEditData?.relationship);
      setEmail(validationEditData.validatorEmail);
      setMobile(validationEditData?.validatorPhone);
      setRemarks(validationEditData?.remarks);
      setAttachmentFileNames(validationEditData?.requestorAttachmentFileNames);
    }
  }, [
    isEditEnabled,
    validationEditData?.relationship,
    validationEditData?.remarks,
    validationEditData?.requestorAttachmentFileNames,
    validationEditData?.validatorName,
    validationEditData.validatorEmail,
    validationEditData?.validatorPhone,
  ]);

  useEffect(() => {
    editFun();
  }, [validationEditData, editFun]);

  const handleValidationEdit = useCallback(async () => {
    setIisSendingEditReq(true);

    const data = {
      dateSent: formatTimestampToDate(
        Number(validationEditData?.dateSent),
        regionalData.selectedCountry.dateFormat
      ),
      validatorName: whoValidates,
      relationship: relationship,
      validatorEmail: email,
      validatorPhone: mobile,
      isValidated: "No",
      description: remarks,
      skillOccupation: SkillSelected?.skillOccupation,
      userId: localStorage.getItem("userId"),
      requestorId: localStorage.getItem("userId"),
      requestorItemId: validationData?.id,
      validatorResponse: valResponse,
      validatorComments: "",
      remarks: remarks,
      mlanguage: localStorage.getItem("HLang"),
      invitationLink: validationEditData?.invitationLink,
      requestorAttachmentUrls: "",
      requestorAttachmentFileNames:
        validationEditData?.requestorAttachmentFileNames,
      validatorAttachmentUrls: "",
      validatorAttachmentFileNames: "",
      keyTable: validationData?.applicationName,
      keyName:
        validationData?.certificationName ||
        validationData?.organization ||
        validationData?.projectActivity ||
        validationData?.institute ||
        validationData?.applicationName,
    };

    try {
      if (validationEditData?.validatorEmail !== email) {
        const {
          applicationName,
          createdTime,
          id,
          expired,
          ...previousValidationData
        } = validationEditData;

        /* FORMAT THE DATE */
        previousValidationData.dateSent = formatTimestampToDate(
          Number(validationEditData?.dateSent),
          regionalData?.selectedCountry?.dateFormat
        );
        /* CHANGE VALDATOR RESPONSE TO RESENT */
        previousValidationData.validatorResponse = "Request Expired";

        /* MAKE THE VALIDATION RESPONSE TO REQUEST EXPIRED */
        await EditApi(
          "validation requests",
          validationEditData?.id,
          previousValidationData
        );

        /* INCASE CHANGE IN EMAIL ID */
        data.invitationLink = "null";
        /* IF USER ADD NEW ATTACHMENTS */
        if (selectedAttachments?.length) {
          const arr_ = JSON.parse(data?.requestorAttachmentFileNames);
          const _val = arr_.filter(
            (att) => att?.linkedId === validationEditData?.id
          );
          const finalRes = [..._val, ...selectedAttachments];
          data.requestorAttachmentFileNames = JSON.stringify(finalRes);
        } else {
          const arr_ = JSON.parse(data?.requestorAttachmentFileNames);
          const _val = arr_.filter(
            (att) => att?.linkedId === validationEditData?.id
          );
          const finalRes = [..._val];
          data.requestorAttachmentFileNames = JSON.stringify(finalRes);
        }

        /* FORM DATA INIT */
        const formData = new FormData();
        /* MAKE IT BLOB */
        formData.append(
          "json",
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          })
        );

        for (const key of Object.keys(attachmentFiles)) {
          formData.append("file", attachmentFiles[key]);
        }

        /* CREATE NEW VALIDATION */
        const _res = await postValidation(formData);
        const _newUpdateData = _res?.data;

        /* CREATE RECORD IN ATTACHMENT MAP */
        const newUploadedFiles = selectedAttachments.map((file) => ({
          filename: file.fileName,
          fileId: file.fileId,
          fileTitle: file.fileTitle || "",
        }));

        /* ATTACHMENT RELATION PROMISE */
        const attachmentRelationPromises = newUploadedFiles.map((file) => {
          const body = {
            userId: userId,
            fileId: file?.fileId,
            fileName: file?.filename,
            fileTitle: file?.fileTitle,
            linkedId: _newUpdateData?.id,
            linkedApplicationName: _newUpdateData?.applicationName,
            roleName: role_
          };
          return MakeAttachmentRelationPostApi(body);
        });

        try {
          await Promise.all(attachmentRelationPromises);
          /* DISPATCH DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error(
            "Error POSTing attachment relation in validation: ",
            error
          );
        }

        dispatch(fetchValidation());
        setIsEditEnabled(false);
        setIisSendingEditReq(false);
        showSuccessToast("New validation request sent successfully");
        setIsSendingReq(false);
        resetReqForm();
        /* OPEN VALIDATION CONSOLE */
        setIsValidationReqOpen((prev) => !prev);
        setIsRaiseValidationReqOpen(false);
        setSelectedAttachments([]);
        /* MAKING RESEND BUTTON DISABLE */
        disableResendButton(_newUpdateData?.id);
        /* MAKING THE RESEND BUTTON ENABLE */
        enableResendButton(_newUpdateData?.id);
      } else {
        /* IF NO CHANGE IN EMAIL ID */
        if (selectedAttachments?.length) {
          const arr_ = JSON.parse(data?.requestorAttachmentFileNames);
          const _val = arr_.filter(
            (att) => att?.linkedId === validationEditData?.id
          );
          const finalRes = [..._val, ...selectedAttachments];
          data.requestorAttachmentFileNames = JSON.stringify(finalRes);
        } else {
          const arr_ = JSON.parse(data?.requestorAttachmentFileNames);
          const _val = arr_.filter(
            (att) => att?.linkedId === validationEditData?.id
          );
          const finalRes = [..._val];
          data.requestorAttachmentFileNames = JSON.stringify(finalRes);
        }

        /* FORM DATA INIT */
        const formData = new FormData();
        /* MAKE IT BLOB */
        formData.append(
          "json",
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          })
        );

        for (const key of Object.keys(attachmentFiles)) {
          formData.append("file", attachmentFiles[key]);
        }

        const res = await editValidationReq(validationEditData?.id, formData);
        const responseData = res?.data;

        /* CREATE RECORD IN ATTACHMENT MAP */
        const newUploadedFiles = selectedAttachments.map((file) => ({
          filename: file.fileName,
          fileId: file.fileId,
          fileTitle: file.fileTitle || "",
        }));

        /* ATTACHMENT RELATION PROMISE */
        const attachmentRelationPromises = newUploadedFiles.map((file) => {
          const body = {
            userId: userId,
            fileId: file?.fileId,
            fileName: file?.filename,
            fileTitle: file?.fileTitle,
            linkedId: responseData?.id,
            linkedApplicationName: responseData?.applicationName,
            roleName: role_
          };
          return MakeAttachmentRelationPostApi(body);
        });

        try {
          await Promise.all(attachmentRelationPromises);
          /* DISPATCH DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error(
            "Error POSTing attachment relation in validation: ",
            error
          );
        }
        /* INCASE OF NEW FILE ADDITION */
        // if (selectedAttachments) {
        //   if (responseData.requestorAttachmentFileNames.includes("[")) {
        //     const arrayEndIndex =
        //       responseData.requestorAttachmentFileNames.lastIndexOf("]");
        //     const arrayString =
        //       responseData.requestorAttachmentFileNames.substring(
        //         0,
        //         arrayEndIndex + 1
        //       );

        //     /* PARSE THE ARRAY */
        //     const arr = JSON.parse(arrayString);
        //     const newFile = selectedAttachments.map((file) => ({
        //       filename: file.fileName,
        //       fileId: file.fileId,
        //       fileTitle: file.fileTitle || file.fileName,
        //     }));

        //     arr.push(newFile);

        //     const updatedArrString = JSON.stringify(arr);
        //     responseData.requestorAttachmentFileNames =
        //       updatedArrString +
        //       responseData.requestorAttachmentFileNames.substring(
        //         arrayEndIndex + 1
        //       );

        //     /* REMOVE THE FILE NAMES WITHOUT THE FILEID */
        //     const _formatVal =
        //       responseData.requestorAttachmentFileNames.lastIndexOf("]");
        //     const _finalval =
        //       responseData.requestorAttachmentFileNames.substring(
        //         0,
        //         _formatVal + 1
        //       );
        //     responseData.requestorAttachmentFileNames =
        //       removeInnerBrackets(_finalval);
        //   } else {
        //     const newFile = selectedAttachments.map((file) => ({
        //       filename: file.fileName,
        //       fileId: file.fileId,
        //       fileTitle: file.fileTitle || file.fileName,
        //     }));

        //     responseData.requestorAttachmentFileNames = JSON.stringify(newFile);

        //     /* REMOVE THE FILE NAMES WITHOUT THE FILEID */
        //     const _formatVal =
        //       responseData.requestorAttachmentFileNames.lastIndexOf("]");
        //     const _finalval =
        //       responseData.requestorAttachmentFileNames.substring(
        //         0,
        //         _formatVal + 1
        //       );
        //     responseData.requestorAttachmentFileNames = _finalval;
        //   }

        //   /* FORM DATA INIT */
        //   const formData_ = new FormData();
        //   /* MAKE IT BLOB */
        //   formData_.append(
        //     "json",
        //     new Blob([JSON.stringify(responseData)], {
        //       type: "application/json",
        //     })
        //   );

        //   for (const key of Object.keys(attachmentFiles)) {
        //     formData.append("file", attachmentFiles[key]);
        //   }

        //   await editValidationReq(validationEditData?.id, formData_);
        // }
        dispatch(fetchValidation());
        setIsEditEnabled(false);
        setIisSendingEditReq(false);
        showSuccessToast("Request Edited Successfuly");
        setIsSendingReq(false);
        resetReqForm();
        /* OPEN VALIDATION CONSOLE */
        setIsValidationReqOpen((prev) => !prev);
        setIsRaiseValidationReqOpen(false);
        /* RESETTING THE ATTACHMENT */
        setSelectedAttachments([]);
        setAttachmentFiles([]);
        setAttachmentFileNames([]);
      }
    } catch (error) {
      console.error("Error editing validation request", error);
      setIisSendingEditReq(false);
    }
  }, [
    validationEditData,
    email,
    mobile,
    relationship,
    whoValidates,
    SkillSelected,
    remarks,
    validationData,
    valResponse,
    attachmentFiles,
    dispatch,
    regionalData?.selectedCountry?.dateFormat,
    selectedAttachments,
    userId,
    role_
  ]);

  /* RESEND REQUEST */
  const resendRequest = async (data) => {
    /* GET CURRENT DATE */
    const currentDate = new Date();

    const formattedDate = `${
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }/${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }/${currentDate.getFullYear()}`;

    try {
      const { applicationName, createdTime, expired, id, ...requestData } =
        data;

      /* FORMAT THE DATE */
      requestData.dateSent = formattedDate;
      /* CHANGE VALDATOR RESPONSE TO RESENT */
      requestData.validatorResponse = "Resend";

      /* FORM DATA INIT */
      const _formData = new FormData();
      /* MAKE IT BLOB */
      _formData.append(
        "json",
        new Blob([JSON.stringify(requestData)], {
          type: "application/json",
        })
      );

      for (const key of Object.keys(attachmentFiles)) {
        _formData.append("file", attachmentFiles[key]);
      }

      await editValidationReq(data?.id, _formData);
      showSuccessToast("Request Resent");
      dispatch(fetchValidation());
      /* MAKING RESEND BUTTON DISABLE */
      disableResendButton(data.id);
      /* ENABLE RESEND BUTTON */
      enableResendButton(data.id);
    } catch (error) {
      console.error("Error while Resending requst: ", error);
    }
  };

  /* CANCEL THE VALIDATION */
  const cancelValidation = async (cancelData) => {
    /* GET CURRENT DATE */
    const currentDate = new Date();

    const formattedDate = `${
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }/${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }/${currentDate.getFullYear()}`;

    try {
      /* SET DATA TO CANCEL */
      const { applicationName, createdTime, id, expired, ...cancelledData } =
        cancelData;

      /* CHANGE EXPIRE TO TRUE */
      cancelledData.validatorResponse = "Request Expired";
      /* FORMAT THE DATE */
      cancelledData.dateSent = formattedDate;

      await EditApi("validation requests", cancelData?.id, cancelledData);
      showSuccessToast("Request Cancelled Successfully");
      dispatch(fetchValidation());
    } catch (error) {
      console.error("Error cancelling request: ", error);
    }
  };

  /* REFRESH MODAL */
  /* ROTATION OF REFRESH ICON */
  const [rotateRefreshIcon, setRotateRefreshIcon] = useState(false);
  const handleRefresh = () => {
    setRotateRefreshIcon(true);
    dispatch(fetchValidation());
    setTimeout(() => {
      setRotateRefreshIcon(false);
    }, 2000);
  };

  useEffect(() => {}, [attachmentFiles]);

  /* INIT TOKEN */
  const accessToken = localStorage.getItem("token");

  useEffect(() => {}, [selectedAttachments]);

  return (
    <div
      className="modal modal-xl"
      id="validationForm"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content" style={{ marginTop: "5rem" }}>
          <div className="ms-2 me-2 border px-1 py-1">
            <div className="d-flex justify-content-between align-items-center ">
              <h3 className="modal-title fs-5" id="exampleModalLabel">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "MyValidations"
                  ) || {}
                ).mvalue || "nf My Validations"}
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetReqForm}
              ></button>
            </div>
            <div className="d-flex justify-content-end align-items-center w-100">
              <i className="me-2">
                {" "}
                <span className="text-danger ">*</span>{" "}
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "RequiredFields"
                  ) || {}
                ).mvalue || "nf Required Fields"}
              </i>
            </div>
            <div className="d-flex justify-content-end align-items-center w-100 text-success mt-2">
              <div className="d-flex align-items-cente me-2">
                <i
                  style={{ fontWeight: "600", cursor: "pointer" }}
                  onClick={handleRefresh}
                >
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RefreshBtn"
                    ) || {}
                  ).mvalue || "nf Refresh"}
                </i>{" "}
                &nbsp;
                <span
                  style={{
                    cursor: "pointer",
                    transition: "transform 1s ease",
                    transform: rotateRefreshIcon
                      ? "rotate(360deg)"
                      : "rotate(0deg)",
                  }}
                  onClick={handleRefresh}
                >
                  <img
                    src={refreshIcon}
                    alt=""
                    style={{
                      width: "1rem",
                      height: "1rem",
                      filter:
                        "invert(20%) sepia(100%) saturate(500%) hue-rotate(100deg)",
                    }}
                  />
                </span>
              </div>
            </div>
            <div
              className="d-flex col-12 align-items-center"
              style={{ marginTop: "-1rem" }}
            >
              <span className="col-2 fs-6">
                {[
                  "Employment History",
                  "Project  History",
                  "Education History",
                  "Certification History",
                  "Training",
                  "Conferences",
                  "Skilling",
                  "Awards",
                  "Memberships",
                ].includes(validationData?.applicationName) ? (
                  <span>
                    <b>{`${validationData?.applicationName}`} </b>
                  </span>
                ) : (
                  <span>
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "SkillLabel"
                        ) || {}
                      ).mvalue || "nf Skill"}{" "}
                    </b>
                  </span>
                )}
              </span>
              <span>
                <b className="col-1">∶</b>
              </span>
              <div
                className="col-9 p-2 px-2 m-3"
                style={{
                  color:
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "SecBarBgColor"
                      ) || {}
                    ).mvalue || "#577126",
                }}
              >
                {["Skills Applied", "Skills Acquired"].includes(
                  validationData?.applicationName
                ) ? (
                  <React.Fragment key={validationData.id}>
                    <b>{validationData?.title}</b>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>
                      {validationData?.skillingName ||
                        validationData?.awardName ||
                        validationData?.licenseName ||
                        validationData?.trainingName ||
                        validationData?.conferencesName ||
                        validationData?.certificationName ||
                        validationData?.organization ||
                        validationData?.projectActivity ||
                        validationData?.institute ||
                        validationData?.applicationName}
                    </b>
                  </React.Fragment>
                )}
              </div>
            </div>
            {validationData?.applicationName === "Skills Applied" && (
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-1rem", fontSize: "0.9rem" }}
              >
                <span className="col-2">
                  <b>
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "AppliedAt"
                      ) || {}
                    ).mvalue || "nf Applied At"}{" "}
                  </b>
                </span>
                <span>
                  <b className="col-1">∶</b>
                </span>
                <div
                  className="col-9 p-2 px-2 m-3"
                  style={{
                    color:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "SecBarBgColor"
                        ) || {}
                      ).mvalue || "#577126",
                  }}
                >
                  <b>{validationData?.projectActivity}</b>
                </div>
              </div>
            )}
            {validationData?.applicationName === "Skills Acquired" && (
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-1rem", fontSize: "0.9rem" }}
              >
                <span className="col-2">
                  <b>
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "AcquiredAt"
                      ) || {}
                    ).mvalue || "nf Acquired At"}{" "}
                  </b>
                </span>
                <span>
                  <b className="col-1">∶</b>
                </span>
                <div
                  className="col-9 p-2 px-2 m-3"
                  style={{
                    color:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "SecBarBgColor"
                        ) || {}
                      ).mvalue || "#577126",
                  }}
                >
                  <b>{validationData?.organization || validationData?.institute}</b>
                </div>
              </div>
            )}

            <hr />

            <div
              className="text-white p-2 px-2 rounded d-flex justify-content-between m-3 mt-4 fs-6"
              style={{
                cursor: "pointer",
                backgroundColor:
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "SecBarBgColor"
                    ) || {}
                  ).mvalue || "#577126",
              }}
              onClick={() => {
                setIsValidationReqOpen((prev) => !prev);
                setIsRaiseValidationReqOpen(false);
                setIsEditEnabled(false);
                resetReqForm();
              }}
            >
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ViewValidationRequests"
                ) || {}
              ).mvalue || "nf View Validation Requests"}
              <FaChevronDown
                style={{
                  transform: `${isValidationReqOpen ? "rotate(180deg)" : ""}`,
                }}
              />
            </div>

            {/* VALIDATION REQUESTS TABLE */}
            {isValidationReqOpen && (
              <div
                className="card m-3"
                style={{ maxHeight: "34vh", overflowY: "auto" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="card-body">
                      <table className="table table-hover">
                        <thead
                          style={{ position: "sticky", top: "0", zIndex: "2" }}
                        >
                          <tr>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "DateSent"
                                ) || {}
                              ).mvalue || "nf Date Sent"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectValidator"
                                ) || {}
                              ).mvalue || "nf Validator Name"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel ===
                                    "ProjectValidatorRelationship"
                                ) || {}
                              ).mvalue || "nf Relationship"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel ===
                                    "ProjectValidatorEmail"
                                ) || {}
                              ).mvalue || "nf Email Id"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel ===
                                    "ProjectValidatorPhone"
                                ) || {}
                              ).mvalue || "nf Mobile Number"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ValidationStatus"
                                ) || {}
                              ).mvalue || "nf Validation Status"}
                            </th>
                            <th className="fw-bold">
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "Actions"
                                ) || {}
                              ).mvalue || "nf Actions"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {validationReqData?.length ? (
                            validationReqData.map((validatior, index) => (
                              <React.Fragment key={validatior.id}>
                                <tr
                                  style={{
                                    cursor: "pointer",
                                    pointerEvents: `${
                                      validatior?.validatorResponse ===
                                      "Request Expired"
                                        ? "none"
                                        : ""
                                    }`,
                                    opacity: `${
                                      validatior?.validatorResponse ===
                                      "Request Expired"
                                        ? "0.5"
                                        : ""
                                    }`,
                                  }}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-place="top"
                                  data-tooltip-content={`Click to view validation details`}
                                >
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {formatTimestampToDate(
                                      Number(validatior?.dateSent),
                                      regionalData.selectedCountry.dateFormat
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {validatior.validatorName}
                                  </td>
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {validatior.relationship}
                                  </td>
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {validatior.validatorEmail}
                                  </td>
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {validatior.validatorPhone
                                      ? validatior.validatorPhone
                                      : "N/A"}
                                  </td>
                                  <td
                                    onClick={() =>
                                      showMoreDetails(validatior.id)
                                    }
                                  >
                                    {validatior?.validatorResponse ===
                                      "Request Sent" ||
                                    validatior?.validatorResponse ===
                                      "Request Expired"
                                      ? validatior?.validatorResponse
                                      : validatior?.validatorResponse ===
                                        "Resend"
                                      ? "Resent"
                                      : `${validatior?.validatorResponse}ed`}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-around align-items-center">
                                      <button
                                        className="d-flex justify-content-around align-items-center"
                                        style={{
                                          fontSize: "0.9rem",
                                          border: "none",
                                        }}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Edit"
                                        disabled={
                                          validatior?.validatorResponse !==
                                            "Request Sent" &&
                                          validatior?.validatorResponse !==
                                            "Resend"
                                        }
                                        onClick={() => {
                                          sendEditData(validatior);
                                          setIsValidationReqOpen(
                                            (prev) => !prev
                                          );
                                          setIsRaiseValidationReqOpen(
                                            (prev) => !prev
                                          );
                                        }}
                                      >
                                        <MdEdit />
                                      </button>
                                      <button
                                        className="d-flex justify-content-around align-items-center"
                                        style={{
                                          fontSize: "0.9rem",
                                          border: "none",
                                        }}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Resend"
                                        disabled={
                                          resendButtonDisabled[
                                            validatior?.id
                                          ] ||
                                          [
                                            "Accept",
                                            "Reject",
                                            "Abstain",
                                          ].includes(
                                            validatior?.validatorResponse
                                          )
                                        }
                                        onClick={() =>
                                          resendRequest(validatior)
                                        }
                                      >
                                        <IoIosSend />
                                      </button>
                                      <button
                                        className="d-flex justify-content-around align-items-center"
                                        style={{
                                          fontSize: "0.9rem",
                                          border: "none",
                                        }}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Hide"
                                        disabled={
                                          validatior?.validatorResponse ===
                                            "Request Sent" ||
                                          validatior?.validatorResponse ===
                                            "Resend"
                                        }
                                      >
                                        <ShowHideIcon
                                          value={
                                            validatior.recordHide === "Yes"
                                              ? true
                                              : false
                                          }
                                          toggleable={true}
                                          form={validatior}
                                          fieldName={"recordHide"}
                                          api={"validation requests"}
                                          id={validatior.id}
                                        />
                                      </button>
                                      <button
                                        className="d-flex justify-content-around align-items-center"
                                        style={{
                                          fontSize: "0.9rem",
                                          border: "none",
                                        }}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Camcel"
                                        disabled={
                                          validatior?.validatorResponse !==
                                            "Request Sent" &&
                                          validatior?.validatorResponse !==
                                            "Resend"
                                        }
                                        onClick={() =>
                                          cancelValidation(validatior)
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                {selectedId === validatior.id && (
                                  <tr>
                                    <td colSpan="7">
                                      <div className="card">
                                        <div
                                          className="card-body d-flex"
                                          style={{ textIndent: "3rem" }}
                                        >
                                          <div
                                            className="col-6"
                                            style={{
                                              borderRight: "1px solid #577126",
                                            }}
                                          >
                                            <p>
                                              {(
                                                content[selectedLanguage].find(
                                                  (item) =>
                                                    item.elementLabel ===
                                                    "ProjectValidatorRemark"
                                                ) || {}
                                              ).mvalue || "nf Remarks"}
                                              : {validatior.remarks}
                                            </p>
                                            <p>
                                              {(
                                                content[selectedLanguage].find(
                                                  (item) =>
                                                    item.elementLabel ===
                                                    "Attachments"
                                                ) || {}
                                              ).mvalue || "nf Attachments"}
                                              :{" "}
                                              {validatior?.requestorAttachmentFileNames &&
                                              validatior?.requestorAttachmentFileNames !==
                                                "[]" &&
                                              JSON.parse(
                                                validatior?.requestorAttachmentFileNames
                                              ) ? (
                                                JSON.parse(
                                                  validatior?.requestorAttachmentFileNames
                                                ).map((attachment, index) => (
                                                  <React.Fragment>
                                                    {index > 0 && (
                                                      <span>, </span>
                                                    )}
                                                    <a
                                                      key={index}
                                                      rel="noreferrer"
                                                      // href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${
                                                      //   validatior?.id
                                                      // }/${
                                                      //   attachment.filename
                                                      // }/${
                                                      //   attachment.fileId
                                                      // }?Authorization=${localStorage.getItem(
                                                      //   "token"
                                                      // )}`}
                                                      href={GetAttachment(
                                                        userDetails?.id,
                                                        attachment?.fileName,
                                                        attachment?.fileId
                                                      )}
                                                      target="_blank"
                                                    >
                                                      {attachment.fileName}
                                                    </a>
                                                  </React.Fragment>
                                                ))
                                              ) : (
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "NoAttachmentsSent"
                                                    ) || {}
                                                  ).mvalue ||
                                                    "nf No Attachments Sent"}
                                                </p>
                                              )}
                                            </p>
                                          </div>
                                          <div className="col-6">
                                            <p>
                                              {(
                                                content[selectedLanguage].find(
                                                  (item) =>
                                                    item.elementLabel ===
                                                    "ValidatorsRemark"
                                                ) || {}
                                              ).mvalue ||
                                                "nf Validator's Remark"}
                                              :{" "}
                                              {validatior?.validatorComments
                                                ? validatior?.validatorComments
                                                : (
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "NoRemarksFromValidator"
                                                    ) || {}
                                                  ).mvalue ||
                                                  "nf No remarks from validator"}
                                            </p>
                                            <p>
                                              {(
                                                content[selectedLanguage].find(
                                                  (item) =>
                                                    item.elementLabel ===
                                                    "ValidatorsAttachments"
                                                ) || {}
                                              ).mvalue ||
                                                "nf Validator's Attachments"}
                                              :{" "}
                                              {validatior?.validatorAttachmentFileNames &&
                                              JSON.parse(
                                                validatior?.validatorAttachmentFileNames
                                              ) ? (
                                                JSON.parse(
                                                  validatior?.validatorAttachmentFileNames
                                                ).map((attachment, index) => (
                                                  <React.Fragment>
                                                    {index > 0 && (
                                                      <span>, </span>
                                                    )}
                                                    <a
                                                      key={index}
                                                      rel="noreferrer"
                                                      href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment.filename}/${attachment.fileId}?Authorization=${accessToken}`}
                                                      target="_blank"
                                                    >
                                                      {attachment.fileTitle}
                                                    </a>
                                                  </React.Fragment>
                                                ))
                                              ) : (
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "NoAttachmentsMsg"
                                                    ) || {}
                                                  ).mvalue ||
                                                    "nf No Attachments Received Yet"}
                                                </p>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td
                                className="fw-bold text-center text-success"
                                colSpan="7"
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "NoResults"
                                  ) || {}
                                ).mvalue || "nf No results"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Tooltip id="my-tooltip" style={{ zIndex: 100 }} />
              </div>
            )}

            <div
              className="pill-bg-color text-white p-2 px-2 rounded d-flex justify-content-between m-3 fs-6"
              style={{
                cursor: "pointer",
                backgroundColor:
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "SecBarBgColor"
                    ) || {}
                  ).mvalue || "#577126",
              }}
              onClick={() => {
                setIsRaiseValidationReqOpen((prev) => !prev);
                setIsValidationReqOpen(false);
              }}
            >
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "RaiseValidationRequests"
                ) || {}
              ).mvalue || "nf Raise Validation Requests"}
              <FaChevronDown
                style={{
                  transform: `${
                    isRaiseValidationReqOpen ? "rotate(180deg)" : ""
                  }`,
                }}
              />
            </div>

            {/* VALIDATION RAISE FORM */}
            {isRaiseValidationReqOpen && (
              <div
                className="card p-4 m-3"
                style={{ maxHeight: "40vh", overflowY: "auto" }}
              >
                <div className="my-2 card-body p-0">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label "
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "WhoValidates"
                      ) || {}
                    ).mvalue || "nf Who Validates"}{" "}
                    ? <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    style={{ height: "32px" }}
                    className="form-control bg-body-tertiary h-75 "
                    id="exampleFormControlInput1"
                    value={whoValidates}
                    onChange={(e) => setWhoValidates(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="" className="form-label mt-2  ">
                    {(
                      content[selectedLanguage].find(
                        (item) =>
                          item.elementLabel === "ProjectValidatorRelationship"
                      ) || {}
                    ).mvalue || "nf Relationship"}{" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                {["Skills Acquired", "Training", "Education History"].includes(
                  validationData?.applicationName
                ) ? (
                  <table>
                    <tr>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship1"
                            value="Co-participants"
                            checked={relationship === "Co-participants"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship1">
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "CoParticipants"
                              ) || {}
                            ).mvalue || "nf Co-participants"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship2"
                            value="Teacher"
                            checked={relationship === "Teacher"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship2">
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Teacher"
                              ) || {}
                            ).mvalue || "nf Teacher"}
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship3"
                            value="Head of the Department"
                            checked={relationship === "Head of the Department"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship3">
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "HeadOfTheDepartment"
                              ) || {}
                            ).mvalue || "nf Head of the Department"}{" "}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship5"
                            value="Institution"
                            checked={relationship === "Institution"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship5">
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Institution"
                              ) || {}
                            ).mvalue || "nf Institution"}{" "}
                          </label>
                        </div>
                      </td>
                    </tr>
                  </table>
                ) : (
                  <table>
                    <tr>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship1"
                            value="Client Team Leader"
                            checked={relationship === "Client Team Leader"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship1">
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "ClientTeamLeader"
                              ) || {}
                            ).mvalue || "nf Client Team Leader"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship2"
                            value="Client Team Member"
                            checked={relationship === "Client Team Member"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship2">
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "ClientTeamMember"
                              ) || {}
                            ).mvalue || "nf Client Team Member"}
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship3"
                            value="Own Team Leader"
                            checked={relationship === "Own Team Leader"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship3">
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "OwnTeamLeader"
                              ) || {}
                            ).mvalue || "nf Own Team Leader"}{" "}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="relationship"
                            id="relationship5"
                            value="Own Team Member"
                            checked={relationship === "Own Team Member"}
                            onChange={(e) => setRelationship(e.target.value)}
                          />
                          <label class="form-check-label" for="relationship5">
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "OwnTeamMember"
                              ) || {}
                            ).mvalue || "nf Own Team Member"}{" "}
                          </label>
                        </div>
                      </td>
                    </tr>
                  </table>
                )}

                <div class="form-check form-check-inline mb-2">
                  <input
                    class="form-check-input bg-body-primary"
                    type="radio"
                    name="relationship"
                    id="relationship4"
                    value="Other Person"
                    checked={relationship === "Other Person"}
                    onChange={(e) => setRelationship(e.target.value)}
                  />
                  <label class="form-check-label" for="relationship4">
                    {[
                      "Skills Acquired",
                      "Training",
                      "Education History",
                    ].includes(validationData?.applicationName) ? (
                      <p>
                        {(
                          content[selectedLanguage].find(
                            (item) =>
                              item.elementLabel ===
                              "OtherPersonWhoIsAwareOfOrganization"
                          ) || {}
                        ).mvalue ||
                          "nf Other Person who is aware of organization"}{" "}
                      </p>
                    ) : (
                      <p>
                        {(
                          content[selectedLanguage].find(
                            (item) =>
                              item.elementLabel ===
                              "OtherPersonWhoIsAwareOfProject"
                          ) || {}
                        ).mvalue ||
                          "nf Other Person who is aware of project"}{" "}
                      </p>
                    )}
                  </label>
                </div>

                <div class="mb-2  ">
                  <label for="exampleFormControlInput1" class="form-label">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "ProjectValidatorEmail"
                      ) || {}
                    ).mvalue || "nf Email Id"}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    style={{ height: "32px" }}
                    class="form-control bg-body-tertiary h-75 "
                    id="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="my-2 ">
                  <label for="exampleFormControlInput1" class="form-label">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "ProjectValidatorPhone"
                      ) || {}
                    ).mvalue || "nf Mobile Number"}
                  </label>
                  <input
                    type="text"
                    style={{ height: "32px" }}
                    class="form-control bg-body-tertiary h-75 "
                    id="mobile"
                    placeholder=""
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    onKeyDown={OnlyNumbersAndSpecialCharacters}
                    onPaste={OnlyNumbersAndSpecialCharacters}
                  />
                </div>
                <div className="my-2 ">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "ProjectValidatorRemark"
                      ) || {}
                    ).mvalue || "nf Remarks"}{" "}
                  </label>
                  <textarea
                    className="form-control bg-body-tertiary"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  ></textarea>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="position-relative">
                    {!isEditEnabled ? (
                      <React.Fragment>
                        {/* <input
                          type="file"
                          className="position-absolute opacity-0 w-100 h-100"
                          onChange={handleFileSelection}
                          accept=".pdf,.jpeg,.jpg,.png,.xlsx,.docx"
                          multiple
                          max="4"
                        /> */}

                        <button
                          type="button"
                          className="text-white border-0 px-2 py-2 rounded pill-bg-color font-5"
                          style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   fileInputRef.current && fileInputRef.current.click()
                          // }
                          onClick={() => setShowAttachmentCard((prev) => !prev)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <ImAttachment />
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Attachment"
                              ) || {}
                            ).mvalue || "nf Attachment"}
                          </div>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {/* <input
                          type="file"
                          className="position-absolute opacity-0 w-100 h-100"
                          onChange={handleEditFileSelection}
                          accept=".pdf,.jpeg,.jpg,.png,.xlsx,.docx"
                          multiple
                          max="4"
                        /> */}

                        <button
                          type="button"
                          className="text-white border-0 px-2 py-2 rounded pill-bg-color font-5"
                          style={{ cursor: "pointer" }}
                          // onClick={() => fileInputRef.current.click()}
                          onClick={() => setShowAttachmentCard(true)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <ImAttachment />
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "AddNewAttachment"
                              ) || {}
                            ).mvalue || "nf Add New Attachment"}
                          </div>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                  {!isEditEnabled ? (
                    <button
                      type="button"
                      onClick={handleValidation}
                      disabled={
                        !whoValidates || !email || !relationship || issendingReq
                      }
                      className="text-white border-0 px-2 py-2 rounded pill-bg-color font-5"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {issendingReq && !isEditEnabled ? (
                        <div className="d-flex justify-content-between align-items-center">
                          <SmallLoader
                            height={"14px"}
                            width={"14px"}
                            color={"#fff"}
                          />{" "}
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SendRequest"
                            ) || {}
                          ).mvalue || "nf Send Request"}
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          <IoIosSend className="fs-5" />{" "}
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SendRequest"
                            ) || {}
                          ).mvalue || "nf Send Request"}
                        </div>
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleValidationEdit}
                      disabled={
                        !whoValidates ||
                        !email ||
                        !relationship ||
                        isSendingEditReq
                      }
                      className="text-white border-0 px-2 py-2 rounded pill-bg-color font-5"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {isSendingEditReq && isEditEnabled ? (
                        <div className="d-flex justify-content-between align-items-center">
                          <SmallLoader
                            height={"14px"}
                            width={"14px"}
                            color={"#fff"}
                          />{" "}
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Update"
                            ) || {}
                          ).mvalue || "nf Update"}
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Update"
                            ) || {}
                          ).mvalue || "nf Update"}
                        </div>
                      )}
                    </button>
                  )}
                </div>
                {attachmentFiles.length > 0 && !isEditEnabled ? (
                  <div className="d-flex align-items-center">
                    {attachmentFiles.map(
                      (file, index) =>
                        file.name &&
                        !file.name.includes('{"filename":"') &&
                        !file.name.includes('"fileId":') &&
                        !file.name.includes('"fileTitle":') && (
                          <React.Fragment key={index}>
                            <p
                              className="bg-success text-white p-2 rounded me-1"
                              style={{ fontSize: "0.9em" }}
                            >
                              <FaTimes
                                onClick={() => handleRemoveAttachment(index)}
                              />
                              {file?.name}
                            </p>
                          </React.Fragment>
                        )
                    )}
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    {/* {attachmentFiles.map((file, index) => {
                      if (file.name && file.name.includes('{"filename":"')) {
                        const match = file.name.match(/{"filename":"([^"]+)"/);
                        if (match) {
                          const fileName = match[1];
                          return (
                            <React.Fragment key={index}>
                              <p
                                className="bg-success text-white p-2 rounded me-1"
                                style={{ fontSize: "0.9em" }}
                              >
                                <FaTimes
                                  onClick={() =>
                                    handleRemoveAttachmentForEdit(index, file?.id)
                                  }
                                />
                                {fileName}
                              </p>
                            </React.Fragment>
                          );
                        }
                      } else if (
                        file.name &&
                        !file.name.includes('{"filename":') &&
                        !file.name.includes('"fileId":') &&
                        !file.name.includes('"fileTitle":')
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <p
                              className="bg-success text-white p-2 rounded me-1"
                              style={{ fontSize: "0.9em" }}
                            >
                              <FaTimes
                                onClick={() => handleRemoveAttachment(index)}
                              />
                              {file.name}
                            </p>
                          </React.Fragment>
                        );
                      }
                      return null;
                    })} */}
                    {userAttachmentData &&
                    userAttachmentData?.filter(
                      (att) => att?.linkedId === validationEditData?.id
                    )?.length > 0
                      ? userAttachmentData
                          ?.filter(
                            (att) => att?.linkedId === validationEditData?.id
                          )
                          .map((attachment, index) => (
                            <React.Fragment key={index}>
                              <p
                                className="bg-success text-white p-2 rounded me-1"
                                style={{ fontSize: "0.9em" }}
                              >
                                <FaTimes
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveAttachmentForEdit(
                                      index,
                                      attachment?.id
                                    )
                                  }
                                />
                                {attachment?.fileName}
                              </p>
                            </React.Fragment>
                          ))
                      : null}
                  </div>
                )}

                {/* INCASE OF ERROR IN FILE NUMBERS */}
                {showAttachmentError && (
                  <b className="text-danger">{"Upto 4 files allowed!"}</b>
                )}

                {/* ATTACHMENT UPLOAD CARD */}
                {showAttachmentCard && (
                  <AttachmentSelectCard
                    userAttachmentData={userAttachmentData}
                    userDetails={userDetails}
                    content={content}
                    selectedLanguage={selectedLanguage}
                    selectedAttachments={selectedAttachments}
                    setSelectedAttachments={setSelectedAttachments}
                    loading={loading}
                    setLoading={setLoading}
                    FaTimes={FaTimes}
                    setShowAttachmentCard={setShowAttachmentCard}
                    role_={role_}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validation;
