export const GetUserForSelectedLanguage = (userDetails, selectedLanguage) => {

    console.log("userDetails log ", userDetails);
    // Convert object to an array
    const userDetailsArray = Object.values(userDetails);

    // Check if userDetailsArray is not empty
    if (Array.isArray(userDetailsArray) && userDetailsArray.length > 0) {
        // Find the user object for the selected language
        const userForSelectedLanguage = userDetailsArray.find(userObject => 
            userObject && userObject.mlanguage === selectedLanguage
        );

        console.log("userForSelectedLanguage log ", userForSelectedLanguage);

        // Return the user object if found
        if (userForSelectedLanguage) {
            return userForSelectedLanguage;
        } else {
            console.log("No language found for the selected language.");
            return {}; // or handle default value if needed
        }
    } else {
        console.log("userDetails is not a non-empty array.");
        return {}; // or handle default value if needed
    }
};
