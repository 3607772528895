import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AiFillPrinter, AiOutlineClose } from 'react-icons/ai';
import { FcMenu } from 'react-icons/fc';
import Playground from './FlagSelection';
import user from "../Images/avatar-placeholder.webp"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../reducer/localization/languageSlice';
import { logout, setUserInfo, setUserProfile } from '../reducer/userDetails/UserProfileSlice';
import logo from "../Images/logo.png";
import GetAllLangApi from '../api/content/GetAllLangApi';
import SettingsModal from './Modals/SettingsModal';
import PasswordModal from './Modals/PasswordModal';
import SwitchRoleModal from './Modals/SwitchRoleModal';
import PrivacyModal from './Modals/PrivacyModal';
import LanguageComponent from './LanguageComponent';
import { logoutUser } from '../Store';
import UserProfile from './SkillingAgency/UserComponents/UserProfile';
import { GetUserForSelectedLanguage } from './SkillOwner/HelperFunction/GetUserForSelectedLanguage';
import ValidationModal from './Modals/ValidationModal';
import { BASE_URL } from '../config/Properties';
import axios from 'axios';
import { showErrorToast } from './ToastNotification/showErrorToast';
import AccountSettings from './Modals/AccountSettings';

import Menu from './Supportservices/Menu';
import { fetchValidation } from '../reducer/validation/getUserValidationSlice';
import { GetAttachment } from '../api/Attachment  API/DownloadAttachmentApi';
import PrintModal from './Modals/PrintModal';
import UserDetailsApi from '../api/auth/UserDetailsApi';
import { fetchAwardsHistory } from '../api/fetchAllData/fetchAwardsHistory';
import { FetchOrganizationHistory } from '../api/fetchAllData/fetchOrganization';
import { FetchProjectHistory } from '../api/fetchAllData/FetchProjectHistory';
import { fetchEducationHistory } from '../api/fetchAllData/fetchEducationHistory';
import { fetchCertificationHistory } from '../api/fetchAllData/fetchCertificationHistory';
import { fetchTrainingHistory } from '../api/fetchAllData/fetchTrainingHistory';
import { fetchSkillingHistory } from '../api/fetchAllData/fetchSkillingHistory';
import { fetchLicensesHistory } from '../api/fetchAllData/fetchLicensesHistory';
import { fetchMembershipHistory } from '../api/fetchAllData/fetchMembershipHistory';
import { fetchUserLanguages } from '../api/fetchAllData/fetchUserLanguages';
import { fetchOthersHistory } from '../api/fetchAllData/fetchOtherHistory';
import { fetchConferencesHistory } from '../api/fetchAllData/fetchConferenceHistory';
import { getAccountDetails } from '../api/auth/getAccountDetails';


const Navbar = ({ handlePdf }) => {
  const location = useLocation().pathname;
  // check local storage if present put in state or else default values
  const userAccountDetails = useSelector((state) => state.UserAccountDetails);

  const userInfo = useSelector(state => state.userProfile.accountDetails.userInfo);

  console.log('detailsare', userAccountDetails);

  const [Country, setCountry] = useState({ countryCode: localStorage.getItem('countryCode') });


  // useEffect(() => { 

  //   setCountry({ countryCode: localStorage.getItem('countryCode') , countryName: ""})

  // }, [userAccountDetails])

  // const [Country, setCountry] = useState({ countryCode: localStorage.getItem("countryCode") || "US", countryName: localStorage.getItem("countryName") || "United States" });
  const [ShowDropDown, setShowDropDown] = useState(false)
  const [menu, setMenu] = useState(false)
  const [profileOptionShow, setProfileOptionShow] = useState(false)

  //used to detect popup hides when clicked outside of it 
  const profileRef = useRef(null);
  const profileImgRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current && !profileRef.current.contains(event.target) &&
        profileImgRef.current && !profileImgRef.current.contains(event.target)
      ) {
        setProfileOptionShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef, profileImgRef]);

  const [lang, setLang] = useState([])

  const [timeZone, setTimeZone] = useState([{ "code": "en-US", "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" },
  { "code": "en-US", "country": "US", "abbr": "PST", "TZ": "America/Los_Angeles" },
  { "code": "en-US", "country": "US", "abbr": "EST", "TZ": "America/New_York" },
  { "code": "en-IN", "country": "India", "abbr": "IST", "TZ": "Asia/Kolkata" }]);


  const [selectedTimeZone, setSelectedTimeZone] = useState({});
  const [selectedObject, setSelectedObject] = useState({ "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" });
  const [flagSearch, setFlagSearch] = useState("");
  // const [isDialogScreen, setIsDialogScreen] = useState(false);
  const [isDialogScreen, setIsDialogScreen] = useState(false);
  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("userName");


  const handleRendering = () => {

  }

  useEffect(() => {
    const fetchScreenDetail = async () => {
      try {
        const res = await getAccountDetails(accountId);
        if (res?.data?.account?.defaultView === "DIALOG") {
          setIsDialogScreen(true);
        } else if (
          res?.data?.account?.defaultView === "MAIN" ||
          res?.data?.account?.defaultView !== "DIALOG"
        ) {
          setIsDialogScreen(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchScreenDetail();
  }, [accountId]);

  // useEffect(() => {

  //   GetAllLangApi().then((res) => {
  //     setLang(res.data)
  //   })

  //   // storing country code in local storage
  //   localStorage.setItem("countryCode", Country.countryCode)
  //   localStorage.setItem("countryName", Country.countryName)
  // }, [Country])


  // useEffect(() => {

  //   async function fetchTimeZone() {
  //     try {
  //       const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/RegionalData/Time Zone Data?&searchFieldName=countryCode&searchValue=${Country.countryCode}%25`)
  //       setTimeZone(res?.data)
  //       setSelectedTimeZone(res?.data?.[0].abbreviation);

  //       setSelectedObject(res?.data?.[0]);

  //       console.log("change value is aa ", res?.data?.[0].abbreviation);
  //       console.log("change value is bb", res?.data?.[0]);
  //       console.log("nav bar res is", res);
  //     } catch (err) {
  //       console.log(err);
  //       showErrorToast('Cant fetch Timezones')
  //     }

  //   }

  //   // if(Country.countryCode !== localStorage.getItem('countryCode')){

  //   fetchTimeZone()
  //   // }


  // }, [localStorage.getItem('countryCode')])






  const handleDropDown = ({ handlePdf }) => {
    setShowDropDown(!ShowDropDown)
  }

  const pathIncludesDetailedProfileString = () => {

    console.log("location.includes('detailedProfile') ", location.includes('detailedProfile'));

    return location.includes('detailedProfile');
  };
  const handlePrint = async () => {
    console.log("pppp ", location.includes("detailedprofile"));
    if (location.includes("detailedprofile")) {
      const dispatchPromises = [
        dispatch(fetchAwardsHistory()),
        dispatch(FetchOrganizationHistory()),
        dispatch(FetchProjectHistory()),
        dispatch(fetchEducationHistory()),
        dispatch(fetchCertificationHistory()),
        dispatch(fetchTrainingHistory()),
        dispatch(fetchSkillingHistory()),
        dispatch(fetchLicensesHistory()),
        dispatch(fetchMembershipHistory()),
        dispatch(fetchUserLanguages()),
        dispatch(fetchOthersHistory()),
        dispatch(fetchConferencesHistory()),
      ];

      try {
        // Wait for all the dispatch promises to resolve
        await Promise.all(dispatchPromises);
        // Call handlePdf() after all the API calls are completed
        handlePdf();
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    }
    else {
      handlePdf();
    }
  }

  // store imports
  const selectedLanguage = useSelector(state => state.language);
  const content = useSelector(state => state.content);
  const userDetailsAll = useSelector(state => state.userProfile.data);
  const { screenName } = useSelector((state) => state); 
  const [userDetails, setuserDetails] = useState({})
   const [profilePicObj, setProfilePicObj] = useState({});

  useEffect(() => {

    setuserDetails(GetUserForSelectedLanguage(userDetailsAll, selectedLanguage));

  }, [selectedLanguage, userDetailsAll])

  useEffect(() => {

    dispatch(fetchValidation());


  }, []);

  function splitStringToObject(str) {
    try {
      const parts = str.split('||').map(part => part.trim());
      const obj = {};
      parts?.forEach(part => {
        const [key, value] = part.split('=').map(item => item.trim());
        obj[key] = value;
      });
      return obj;
    } catch (error) {
      console.error("Error occurred while parsing the string:", error.message);
      return {}; //Return an empty object in case of failure
    }
  }

  useEffect(() => {

    setProfilePicObj(splitStringToObject(userDetails?.profilePictureFileName));
  }, [userDetails])





  const dispatch = useDispatch();

  const handleLanguageChange = (event) => {
    dispatch(setLanguage(event.target.value));
  }
  const handleTimeZoneChange = (e) => {
    const selectedValue = e.target.value;
    const selectedObjects = timeZone.find(item => item.abbreviation === selectedValue);
    setSelectedTimeZone(selectedValue);
    setSelectedObject(selectedObjects);
    console.log("change value is ", e.target.value);
    console.log("change value is ", selectedObjects);
  }

  const handleLogout = () => {
    console.log("logout fn1 ");
    logoutUser();
    dispatch(logout());
    localStorage.clear();

  }
  const date = new Date();

  console.log(
    date.toLocaleString(selectedObject.code, {
      timeZone: selectedObject.TZ,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      // timeZoneName: 'short',
    }),
  ); // 👉️ "07/24/2023, 04:55:00 AM PDT"

  //specify the date string and the format it's initially in
  // var mydate = moment('15/11/2000', 'DD/MM/YYYY');

  // //format that date into a different format
  // moment(mydate).format("MM/DD/YYYY");


  function convertUTCtoLocalDateTime(utcOffset) {
    // Get the current time in UTC
    const currentUTC = new Date();

    // Parse the UTC offset string
    const sign = utcOffset.startsWith('UTC -') ? -1 : 1;
    const offsetParts = utcOffset.split(' ');
    const offsetHours = parseInt(offsetParts[1].split(':')[0]) * sign;
    const offsetMinutes = parseInt(offsetParts[1].split(':')[1]) * sign;

    // Apply the offset to get the local time
    const localTime = new Date(currentUTC.getTime() + (offsetHours * 60 + offsetMinutes) * 60000);

    // Extract date components
    const month = (localTime.getMonth() + 1).toString().padStart(2, '0');
    const day = localTime.getDate().toString().padStart(2, '0');
    const year = localTime.getFullYear();

    // Extract time components
    const hours = localTime.getHours().toString().padStart(2, '0');
    const minutes = localTime.getMinutes().toString().padStart(2, '0');

    // Format the date and time string
    const formattedDateTime = `${month}/${day}/${year}, ${hours}:${minutes}`;

    return formattedDateTime;
  }

  const handleFlagFilter = (e) => {
    setFlagSearch(e.target.value);
  }




  return (
    <>
      {content.status === "succeeded" && <>

        {/* modal tabs */}
        <UserProfile />
        {/* <PasswordModal /> */}
        {/* <SwitchRoleModal /> */}
        <AccountSettings />
        <PrivacyModal />
        <PrintModal handlePrint={handlePdf} />



        <nav style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className="navbar navbar-expand-lg d-print-none p-0 m-0   "  >
          <div className="container-fluid  ">
            <a className="navbar-brand d-flex align-items-center   " href="/#/dashboard/skillprofile">

              <div className='d-flex  flex-column align-items-center justify-align-content-around'>
                <img src={logo} alt="Logo" width="35" height="35" className="d-inline-block " />
                <div className='h6 m-0  ' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", fontSize: "12px", fontWeight: "700" }} >{(content[selectedLanguage].find(item => item.elementLabel === 'Title') || {}).mvalue || "nf MySkillsTree"}</div>
              </div>
              <div className='d-flex flex-column  ms-2'>
                <div className='px-1 font-weight-1 font-1 mb-0 ' style={{ height: "28px", color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ApplicationName') || {}).mvalue || "nf Skill dashboard"}</div>
                <div className='d-flex px-1 pt-0  font-weight-0  font-6 fst-italic   ' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillOwner') || {}).mvalue || "nf Skill Owner"}</div>
              </div>
            </a>

            <div className='d-flex align-items-center   ' >
              <div className='mx-0 ' >
                <ul className="nav   d-flex align-items-baseline d-none d-lg-flex      ">
                {screenName !== "DIALOG" && (
                  <>
                  <li className="nav-item  font-weight-bold  pt-lg-4 ">
                    <Link style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className={((location === "/newuser")) ? "  fw-bold px-2   " : "nav-link  text-gray py-0 my-0 px-2   "} aria-current="page" to="/newuser/basicinfo">{(content[selectedLanguage].find(item => item.elementLabel === 'Dialogue') || {}).mvalue || "nf Dialogue"}</Link>
                  </li>
                  <li className="nav-item  font-weight-bold  pt-lg-4 ">
                    <Link style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className={((location === "/dashboard/skillprofile") || (location.startsWith("/premium-services"))) ? "  fw-bold px-2   " : "nav-link  text-gray py-0 my-0 px-2   "} aria-current="page" to="/dashboard/skillprofile">{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsProfile') || {}).mvalue || "nf Skills Profile Management"}</Link>
                  </li>
                  <li className="nav-item font-weight-bold pt-lg-4  ">
                    <Link style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className={location.startsWith("/dashboard/detailedprofile") ? "  fw-bold  px-2   " : "nav-link  text-gray py-0 my-0 px-2   "} to="/dashboard/detailedprofile">{(content[selectedLanguage].find(item => item.elementLabel === 'SkillDetails') || {}).mvalue || "nf Detailed Profile Management "}</Link>
                  </li>
                  <li>
                    <div className=' d-flex flex-column-reverse ' style={{ width: "20px", marginRight: "22px", marginLeft: "10px" }}>
                      {
                        (location === "/dashboard/skillprofile") ?
                          <>
                            <button className='btn m-0 p-0' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#577126" }} data-bs-toggle="modal" data-bs-target="#printmodal" >
                            {/* <button className='btn m-0 p-0' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#577126" }} onClick={handlePrint} > */}

                                  <AiFillPrinter className='  ' />
                                </button>
                              </>
                              :

                          <button onClick={handlePrint} style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#577126" }} className=' border-0   me-5   px-0   '>
                            <AiFillPrinter />
                          </button>
                      }
                    </div>
                  </li>
                  </>
                )}


                </ul>
              </div>
              <div className='d-flex justify-content-around  '>



                {/* Language and TImeZOne selection and time/timezone/date view*/}
                <div className=' d-flex flex-column justify-content-around  ' style={{ width: "100px", marginRight: "-10px", marginLeft: "20px" }}>

                  {/* <div className='d-flex justify-content-between  ' style={{ width: "100%" }} >

                    <LanguageComponent />

                    <div className='d-none d-lg-block  '>
                      <select class="  border-0 font-dd  " style={{
                        paddingLeft: "4px",
                        color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#577126",
                        width: "70px", height: "10px", fontWeight: "500", marginBottom: "15px", fontSize: "10px"
                      }} aria-label="Default select example  "
                        onChange={handleTimeZoneChange} value={selectedTimeZone} >
                        {timeZone.map((item) =>
                          <option value={item.abbreviation}
                            key={item.abbreviation} className='bg-body-tertiary font-dd    text-black  ' >
                            {selectedTimeZone === item.abbreviation ? selectedObject.countryCode + " " + selectedObject.abbreviation : item.countryCode + " " + item.abbreviation}
                          </option>
                        )}
                      </select>
                    </div>
                  </div> */}

                  {/* <div className='d-flex   ' style={{ fontSize: "10px", alignContent: "center", fontWeight: "500" }}>
                   
                    {selectedObject &&
                      <>


                        {date.toLocaleString(selectedObject.code, {
                          timeZone: selectedObject.TZ,
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          timeZoneName: 'short',
                        })}
                      </>
                    }
                  </div> */}

                </div>

                {profileOptionShow &&
                  <div ref={profileRef} className='z-3  border px-2 py-1  mt-1 bg-body-tertiary  border-5 profile ' >
                    <table className=' table-sm   font-6 ' >
                      {/* <tr className=' ' >
                        <td>
                          <button id='userProfileshow' className='btn btn-light  p-0  m-0 ' data-bs-toggle="modal" data-bs-target="#setting" onClick={() => setProfileOptionShow(false)}  >{(content[selectedLanguage].find(item => item.elementLabel === 'MyProfile') || {}).mvalue || "nf My Profile"}</button>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td>
                          <button className='btn btn-light p-0 m-0' data-bs-toggle="modal" data-bs-target="#password" onClick={() => setProfileOptionShow(false)} >
                            Change Password</button>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td>
                          <button className='btn btn-light p-0 m-0' data-bs-toggle="modal" data-bs-target="#role" onClick={() => setProfileOptionShow(false)} >Change Role</button>
                        </td>
                      </tr> */}

                      {/* <tr>
                        <td>
                          <button className='btn btn-light p-0 m-0' data-bs-toggle="modal" data-bs-target="#validation" onClick={() => setProfileOptionShow(false)} >{(content[selectedLanguage].find(item => item.elementLabel === 'MyValidations') || {}).mvalue || "nf My Validations"}</button>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <button className='btn btn-light p-0 m-0' data-bs-toggle="modal" data-bs-target="#accountsettings" onClick={() => setProfileOptionShow(false)} >{(content[selectedLanguage].find(item => item.elementLabel === 'AccountSettings') || {}).mvalue || "nf Account Settings"}</button>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <button className='btn btn-light p-0 m-0' data-bs-toggle="modal" data-bs-target="#privacy" onClick={() => setProfileOptionShow(false)} >{(content[selectedLanguage].find(item => item.elementLabel === 'PrivacySettings') || {}).mvalue || "nf Privacy Settings"}</button>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <Link to="/">
                            <button className='btn btn-light p-0 m-0'
                              onClick={handleLogout}  >
                              {(content[selectedLanguage].find(item => item.elementLabel === 'Logout') || {}).mvalue || "nf Logout"}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    </table>
                  </div>
                }


                {/* hamburger menu mobile */}
                {screenName !== "DIALOG" && (
                  <>
                <div className='d-flex d-lg-none ps-1 mb-2 pb-1  '  >
                  {!menu && <button className=' border-0 navigation-bg-color  ' onClick={() => setMenu(true)} style={{ fontSize: "26px" }} >
                    <FcMenu />
                  </button>}
                  {menu && <button className=' border-0 navigation-bg-color  ' onClick={() => setMenu(false)} style={{ fontSize: "26px" }} >
                    <AiOutlineClose />
                  </button>}
                </div>
                <div className='ms-3 my-2' onClick={handleRendering}  >
                  {/* <GiHamburgerMenu onClick={toggleMenu} style={{ height: "35px", width: "35px" }} /> */}
                  <Menu ></Menu>
                </div>
                </>
                )}
                {/* flag and language */}
                <div class="   px-0 d-none d-lg-block mx-3   ">

                  {/* <div type="button" onClick={handleDropDown} class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside"> */}
                  <div class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside">
                    {/* <div class=" border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside"> */}
                    <div>

                      <div className='font-10px p-0 m-0 d-flex flex-column justify-content-center  ' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000" }}>
                        {/* {Country.countryName} want to show 10letters if more than that print ... */}
                        {/* {Country.countryName.length > 10 ? Country.countryName.slice(0, 7) + "..." : Country.countryName} */}
                        {/* {Country.countryCode} */}
                        <img className='m-0 p-0 align-self-center me-1' style={{ width: "35px", height: "37px", borderRadius: "100%" }} src={`https://flagsapi.com/${Country.countryCode}/flat/32.png`} alt="" />
                        <LanguageComponent />
                      </div>
                    </div>
                  </div>
                  {/* {ShowDropDown &&
                    <div class={ShowDropDown ? "dropdown-menu show" : "dropdown-menu "} style={{ minWidth: "10px", marginRight: "100px", marginLeft: "-30px" }} >

                      <div className='d-flex justify-content-center '>
                        <input placeholder='Search Country...' value={flagSearch} onChange={handleFlagFilter} style={{ width: "130px", height: "20px", fontSize: "10px", padding: "2px" }}>

                        </input>
                      </div>
                      <div className=' table-responsive d-flex  font-5 ' style={{ height: "130px" }}>

                        <table className='table table-sm d-flex table-hover  '>

                          <tbody className='font-5' style={{ width: "5%" }} >
                            <Playground setCountry={setCountry} setShowDropDown={setShowDropDown} flagSearch={flagSearch} setFlagSearch={setFlagSearch} />
                          </tbody>

                        </table>
                      </div>
                    </div>} */}
                </div>


                <div ref={profileImgRef} className='d-flex  flex-column align-items-center  ' onClick={() => setProfileOptionShow(!profileOptionShow)} style={{ cursor: "pointer" }} >
                  <div id='profileOptionShow'>
                    <img
                      // src={userDetails.attachmentFileNames && userDetails.attachmentFileNames.length > 2 ? `${BASE_URL}/skill/api/v1/skills/imgHandle/filedownload/${userDetails.id}/${userDetails.attachmentFileNames}?authToken=${token}` :
                      //   user}
                      // src={user}
                      src={userDetails?.profilePictureFileName && userDetails?.profilePictureFileName?.length > 1 ? GetAttachment(userDetails?.id, profilePicObj?.fileName, profilePicObj?.fileId) : user}
                      alt={(content[selectedLanguage].find(item => item.elementLabel === 'Profile Picture') || {}).mvalue || "NF"}
                      width="32" height="32"
                      className="d-inline-block rounded-circle ms-1 mt-1   " />
                  </div>
                  <div style={{ fontSize: "12px", fontWeight: "550", alignSelf: "center" }} className='  p-0 m-0 text-center align-self-between '>{userDetails.firstName}</div>
                </div>



              </div>

            </div>

            {menu && <div class="offcanvas-body mt-1 d-lg-none d-block   ">
              <ul class="   gap-2 d-flex flex-column justify-content-center align-content-center mx-auto my-0    list-unstyled   " style={{}} >
                <li className="nav-item  font-weight-bold  pt-lg-4 ">
                  <Link className={location === "/dashboard/skillprofile" ? " text-brown fw-bold  " : "nav-link  text-gray py-0 my-0  "} aria-current="page" to="/dashboard/skillprofile">{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsProfile') || {}).mvalue || "nf Skill Profile Management"}</Link>
                </li>
                <li className="nav-item font-weight-bold pt-lg-4  ">
                  <Link className={location.startsWith("/dashboard/detailedprofile") ? " text-brown fw-bold  me-3  " : "nav-link  text-gray py-0 my-0  "} to="/dashboard/detailedprofile">{(content[selectedLanguage].find(item => item.elementLabel === 'SkillDetails') || {}).mvalue || "nf Detailed Profile Management"}</Link>
                </li>

                <li>

                </li>

              </ul>

              <div className=' d-flex align-items-center  mb-1    ' >
                <button onClick={handlePrint} className=' text-white font-weight-bold pt-lg-4 px-2 py-1 rounded    border-0 navigation-bg-color ' style={{ backgroundColor: "#815F0B" }} >
                  Print Resume <AiFillPrinter className='  ' />
                </button>

                {/* flag */}
                <div className="   px-0  ps-2  ">
                  <button type="button" onClick={handleDropDown} class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside">
                    <img className='m-0 p-0 ' style={{ width: "30px" }} src={`https://flagsapi.com/${Country.countryCode}/flat/32.png`} alt="" />
                    <div className='font-10px p-0 m-0 ' >
                      {/* {Country.countryName} want to show 10letters if more than that print ... */}
                      {Country.countryName.length > 10 ? Country.countryName.slice(0, 10) + "..." : Country.countryName}
                    </div>
                  </button>
                  {/* {ShowDropDown &&
                    <div class={ShowDropDown ? "dropdown-menu show" : "dropdown-menu "} style={{ Width: "33px", }} >
                      <div className=' table-responsive d-flex  font-5 ' style={{ height: "130px" }}>

                        <table className='table table-sm d-flex table-hover  '>

                          <tbody className='font-5' style={{ width: "5%", }} >
                            <Playground setCountry={setCountry} setShowDropDown={setShowDropDown} />
                          </tbody>

                        </table>
                      </div>
                    </div>} */}
                </div>
              </div>

            </div>}
          </div>

        </nav>
      </>}
    </>
  )
}

export default Navbar;
