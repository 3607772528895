import React from 'react'
import { DayDifferenceToDynamicView } from '../../HelperFunction/DayDifferenceToDynamicView';
import { useSelector } from 'react-redux';
const TrainingSummaryResView = ({ data }) => {
    const selectedLanguage = useSelector(state => state.language);

    const content = useSelector(state => state.content);
    return (
        <React.Fragment>
            {
                data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                <React.Fragment>
                    <div className='font-3 font-weight-2'>{(content[selectedLanguage].find(item => item.elementLabel === 'TrainingHistory') || {}).mvalue || "nf TrainingHistory"}</div>
                    <div className="table-responsive ms-3 mt-2">
                        <table className='table table-sm   table-fixed table-hover    '>
                            <thead >
                                <tr className='border-dark-subtle border-bottom '>
                                    <th scope="col" className='bg-body- ' style={{ width: "21%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Training') || {}).mvalue || "nf Training"}  </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "25%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "nf ProjectOrganization"}  </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "nf ProjectDuration"}      </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf ProjectLocation"}      </th>
                                    <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf ProjectValidation"}    </th>

                                </tr>
                            </thead>
                            <tbody className=''>

                                {data?.map((trainings, index) => (
                                    trainings.recordHide === 'Yes' ?
                                        <></>
                                        :
                                        <tr className='' id={index}>
                                            <td >{trainings.title}</td>
                                            <td >{trainings.recordAnonymous === 'Yes' ? (
                                                content[selectedLanguage].find(
                                                    (item) =>
                                                        item.elementLabel ===
                                                        "Confidential"
                                                ) || {}
                                            ).mvalue || "nf CONFIDENTIAL" : trainings.organization}</td>
                                            <td >{DayDifferenceToDynamicView(
                                                trainings.duration
                                            )}</td>
                                            <td>{trainings.location}</td>
                                            <td className=' '>{trainings.validation}</td>

                                        </tr>)
                                )

                                }
                            </tbody>




                        </table>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default TrainingSummaryResView