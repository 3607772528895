import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { MdVerifiedUser } from 'react-icons/md';
import { SiHiveBlockchain } from 'react-icons/si';
import { IoMdAnalytics } from 'react-icons/io';
import { GiArtificialIntelligence } from 'react-icons/gi';
import resumeParser from "../../../Images/icon.jpeg";
import { BiSolidMessageDetail } from 'react-icons/bi';
import { CiCirclePlus } from "react-icons/ci";
import { IoCheckmarkDoneCircleSharp, IoLanguage, IoSearch } from "react-icons/io5";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { IoIosList } from "react-icons/io";
import { BsListColumnsReverse } from "react-icons/bs";
import SortableList from '../../SkillOwner/SkillsDragDrop/SortableList';
import Top5Courses from '../CourseSearchComponents/Top5Courses';
import RefinePrerequsiteSkills from '../CourseSearchComponents/RefinePrerequsiteSkills';
import { useGetMyCoursesQuery } from '../../../reducer/SkillingAgency/services/myCourses';
import Loader from '../../Loader';
import { useDispatch } from 'react-redux';
import { fetchUserCourses } from '../../../api/SkillingAgency/fetchUserCourses';
import { RiDragMoveFill } from 'react-icons/ri';



const SkillingAgencyLeftSidebar = () => {


    const dispatch = useDispatch()
    const { data, status, error } = useSelector((state) => state.userCourses)

    useEffect(() => {
        dispatch(fetchUserCourses())
    }, [])


    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const location = useLocation()
    const navigate = useNavigate()
    const [toolt, setToolt] = useState(false)


    const setSwitchTab = () => {

    }

    const handleAddSkill = () => {

    }



    if (data) {
        console.log('course data', data);
    }


    return (

        <div class="container-fluid h-100 px-1" style={{ height: 'calc(-60px + 96vh)' }} >
            <div class="row h-50 " style={{ maxHeight: "20rem" }}>

                <div class="col-sm-12 ">
                    <div className="mt-2 d-flex justify-content-center px-2  align-items-center rounded-top    " style={{ backgroundColor: "#577126", position: 'relative' }}>
                        <div className='text py-2 text-center   ' style={{ padding: "8px 0 ", color: "#F7FFDD" }} >My Courses </div>


                        <Link to='/skillingagency/coursesearch/createcourse/new' style={{ position: 'absolute', right: '5px' }} >
                            <CiCirclePlus style={{ color: "#F7FFDD", fontSize: '25px', fontWeight: 'bolder', cursor: 'pointer' }} />
                        </Link>

                    </div>


                    {
                        status === 'loading' &&
                        <div class="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                            <div class="spinner-border" style={{ width: "2rem", height: "2rem" }} role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }

                    {status === 'success' &&
                        <div className=' table-responsive font-5 ' style={{ height: "250px" }}>
                            {
                                !data.length ?
                                <div className='d-flex justify-content-center align-items-center h-100'>
                                {/* <h4>Create Your First Course</h4> */}
                                    <img src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1707738221/61339543_rwy5at.png" alt="" className='h-100 w-100' style={{  objectFit: 'contain' }} />
                                </div>
                                :
                                <>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th className='p-1' scope="col">#</th>
                                        <th className='p-1' scope="col">Course Name</th>
                                        <th className='p-1' scope="col">Status</th>
                                        {/* <th className='p-1' scope="col">Release</th> */}
                                        <th className='p-1' scope="col"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Top5Courses userCourses={data} status={status} />

                                    {/* {
                                        data?.map((course, i) => {
                                            return (

                                                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`coursepreview/${course.id}`)}>
                                                    <th className='p-1' scope="row">{i + 1}</th>
                                                    <td className='p-1' >{course.courseName}</td>
                                                  
                                                    <td className='p-1' >{course.status}</td>
                                                  
                                                    <td className='p-1' ><RiDragMoveFill /></td>
                                                </tr>
                                            )
                                        })

                                    } */}
                                </tbody>
                            </table>

                                </>
                            }
                        </div>
                    }

                    {
                        status === 'error' &&
                        <div class="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                            <h1>error</h1>
                        </div>
                    }

                    <div className=' font-6  d-flex justify-content-center px-2   align-items-center '>
                        <i>drag and drop your courses</i>
                    </div>
                </div>

            </div>






            <div class="row h-50 " >





                {

                    (location.pathname.includes('coursecandidate')) &&


                    <div class="col-sm-12 ">
                        <div class="accordion" id="accordionPanelsStayOpenExample">


                            <div class="accordion-item " >
                                <h2 class="accordion-header " >
                                    <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseLeft" aria-expanded="true" aria-controls="panelsStayOpen-collapseLeft">
                                        Refine My Requirements
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseLeft" class="accordion-collapse collapse show">
                                    <div class="accordion-body">


                                        {/* <RefinePrerequsiteSkills /> */}



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>












                }




















                <div class="col-sm-12 ">
                    <div className=" rounded-top " style={{
                        backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126",
                        color: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD", height: ""
                    }}>
                        <div className='text h6 text-center   ' style={{ padding: "8px 0" }} >{(content[selectedLanguage]?.find(item => item.elementLabel === 'PremiumServices') || {}).mvalue || "not found"}</div>
                    </div>
                    <table className='table table-sm table-hover '>
                        <tbody>
                            <tr className=' ' onClick={() => setSwitchTab('premium')}>
                                {/* <td className='' >{(content[selectedLanguage]?.find(item => item.elementLabel === 'ResumeParsing') || {}).mvalue || "not found"}</td> */}
                                <td>Course Parser</td>
                                <td>
                                    <img src={resumeParser} alt="" style={{ width: "16px", cursor: "pointer" }} className='bg-img' />
                                </td>
                            </tr>

                            <tr onClick={() => setSwitchTab('premium')}>
                                <td>{(content[selectedLanguage]?.find(item => item.elementLabel === 'BlockChain') || {}).mvalue || "not found"}</td>
                                <td>
                                    <SiHiveBlockchain />

                                </td>
                            </tr>
                            <tr onClick={() => setSwitchTab('premium')}>
                                <td>{(content[selectedLanguage]?.find(item => item.elementLabel === 'ThirdPartySupport') || {}).mvalue || "not found"}</td>

                                <td><MdVerifiedUser /></td>
                            </tr>
                            <tr onClick={() => setSwitchTab('customAnalytics')}>
                                <td>{(content[selectedLanguage]?.find(item => item.elementLabel === 'CustomAnalytics') || {}).mvalue || "not found"}</td>
                                <td><IoMdAnalytics /></td>
                            </tr>
                            <tr onClick={() => setSwitchTab('premium')}>
                                <td>{(content[selectedLanguage]?.find(item => item.elementLabel === 'ConversationalAI') || {}).mvalue || "not found"}</td>
                                <td><GiArtificialIntelligence /></td>
                            </tr>
                            <tr onClick={() => setSwitchTab('premium')}>
                                <td>{(content[selectedLanguage]?.find(item => item.elementLabel === 'MessageEngine') || {}).mvalue || "not found"}</td>
                                <td><BiSolidMessageDetail /></td>
                            </tr>
                            <tr onClick={() => navigate('/skillingagency/coursesearch/premimum/translationservice')} style={{ cursor: 'pointer' }}>
                                <td>Translation Service</td>
                                <td><IoLanguage /></td>
                            </tr>



                        </tbody>
                    </table>
                </div>
            </div>
        </div>







    )
}

export default SkillingAgencyLeftSidebar
