import autumnFallImage from "../img/autumn-fall-bg-img.jpg";
import forestTerrainImage from "../img/forest-terrain-bg-img.jpg";
import skyImage from "../img/sky-resume-bg-img.jpg";
import pinkWood from "../img/pink-woods-resume-bg-img.jpg";
import stars from "../img/stars-resume-bg-img.jpg";
import stones from "../img/stones-resume-bg-img.jpg";
import leaves from "../img/leaves-resume-bg-img.jpg";
import floralImg from "../img/floral-resume-bg-img.jpg";

export const resumeBGImages = [
  {
    id: 1,
    name: autumnFallImage,
  },
  {
    id: 2,
    name: forestTerrainImage,
  },
  {
    id: 3,
    name: skyImage,
  },
  {
    id: 4,
    name: pinkWood,
  },
  {
    id: 5,
    name: stars,
  },
  {
    id: 6,
    name: stones,
  },
  {
    id: 7,
    name: leaves,
  },
  {
    id: 8,
    name: floralImg,
  },
];
