export function convertDateToMilliseconds(dateString) {
    // Parse the date string into a Date object
    var date = new Date(dateString);

    // Get the time in milliseconds
    var milliseconds = date.getTime();

    // Return the result as a string with quotes
    return milliseconds.toString();
}
