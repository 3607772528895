import React from 'react'

const SwitchRoleModal = () => {
    return (
        <div class="modal fade modal" id="role" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Switch Role</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div className=''>
                            <div>
                                <div class="container">
                                    <div class="row justify-content-center  mb-2 pb-2  ">
                                        <div class="col-12    bg-body-secondary  pt-2  ">

                                            <select class="form-select font-5 mb-2 " aria-label="Default select example" style={{ height: "32px" }}>
                                                {/* <option className='bg-body-tertiary' value="0" disabled selected hidden>Role</option> */}
                                                <option value="owner" selected >Skill Owner</option>
                                                <option value="availer">Skill seeker</option>
                                                <option value="validator">Skill Validator</option>
                                                <option value="agency">Skilling Agency</option>
                                                <option value="government">Government Agency</option>
                                                <option value="support">Support Agency</option>
                                            </select>
                                            <button class="btn text-white mb-3 mt-2  " style={{ backgroundColor: "#815F0B" }}>Submit</button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SwitchRoleModal