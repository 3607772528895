import React, { useCallback } from "react";
import {
  FaBold,
  FaItalic,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaPlus,
  FaMinus,
} from "react-icons/fa";

const TextStylingComponent = ({
  handleProfileNameColor,
  handleProfileOtherColor,
  handleProfileNameAlignment,
  handleOtherDetailAligment,
  onBoldChange,
  onItalicChange,
  handleFontPNSizeInc,
  handleFontPNSizeDec,
  handleFontOtherDSizeDec,
  handleFontOtherDSizeInc,
  selectedVal,
}) => {
  /* HANDLE BOLD FUNC */
  const handleBoldFont = useCallback(() => {
    onBoldChange((prev) => !prev);
  }, [onBoldChange]);

  /* HANDLE ITALIC */
  const handleItalicFont = useCallback(() => {
    onItalicChange((prev) => !prev);
  }, [onItalicChange]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div
          className="p-1 rounded fs-5"
          style={{ cursor: "pointer" }}
          onClick={handleBoldFont}
        >
          <FaBold />
        </div>
        <div
          className="p-1 rounded fs-5"
          style={{ cursor: "pointer" }}
          onClick={handleItalicFont}
        >
          <FaItalic />
        </div>
        <div
          className="p-1 rounded fs-5"
          style={{ cursor: "pointer" }}
          onClick={() =>
            (selectedVal === "profileName" &&
              handleProfileNameAlignment("left")) ||
            (selectedVal === "OtherDetails" &&
              handleOtherDetailAligment("left"))
          }
        >
          <FaAlignLeft />
        </div>
        <div
          className="p-1 rounded fs-5"
          style={{ cursor: "pointer" }}
          onClick={() =>
            (selectedVal === "profileName" &&
              handleProfileNameAlignment("center")) ||
            (selectedVal === "OtherDetails" &&
              handleOtherDetailAligment("center"))
          }
        >
          <FaAlignCenter />
        </div>
        <div
          className="p-1 rounded fs-5"
          style={{ cursor: "pointer" }}
          onClick={() =>
            (selectedVal === "profileName" &&
              handleProfileNameAlignment("right")) ||
            (selectedVal === "OtherDetails" &&
              handleOtherDetailAligment("right"))
          }
        >
          <FaAlignRight />
        </div>
        {/* FONT SIZE */}
        <div className="d-flex justify-content-around align-items-center">
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() =>
              (selectedVal === "profileName" && handleFontPNSizeDec()) ||
              (selectedVal === "OtherDetails" && handleFontOtherDSizeDec())
            }
          >
            <FaMinus />
          </div>
          <div
            className="border p-1 rounded fs-5"
            style={{ cursor: "pointer" }}
            onClick={() =>
              (selectedVal === "profileName" && handleFontPNSizeInc()) ||
              (selectedVal === "OtherDetails" && handleFontOtherDSizeInc())
            }
          >
            <FaPlus />
          </div>
        </div>
        {/* COLORS */}
        <div style={{ position: "relative", marginLeft: "10px" }}>
          <input
            type="color"
            onChange={(e) =>
              (selectedVal === "profileName" &&
                handleProfileNameColor(e.target.value)) ||
              (selectedVal === "OtherDetails" &&
                handleProfileOtherColor(e.target.value))
            }
            style={{
              width: "2rem",
              height: "2rem",
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            className="rounded"
          />
          <div
            style={{
              width: "2rem",
              height: "2rem",
              background:
                "conic-gradient(red, orange, yellow, #577126, blue, indigo, violet, red)",
              border: "1px solid #577126",
              borderRadius: "50%",
              pointerEvents: "none",
            }}
          ></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextStylingComponent;
