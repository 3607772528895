import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./Store";
import { Provider } from "react-redux";
import UserDetailsApi from "./api/auth/UserDetailsApi";
import { setUserProfile } from "./reducer/userDetails/UserProfileSlice";


import { Tooltip } from "react-tooltip";

import axios from "axios";
import { BASE_URL } from "./config/Properties";

import { setUserRoles } from "./reducer/roles/RoleMappingSlice";

const root = ReactDOM.createRoot(document.getElementById("root"));


if (localStorage.getItem("token")) {
 
  // allow user and go to dashboard page

  if (localStorage.getItem("userId") && localStorage.getItem("userName")) {
    //http://localhost:8080/skill/api/v1/skills/AcccountDetails/PRIYAJANSIUSER
    try {
      axios.get(`${BASE_URL}/skill/api/v1/skills/AcccountDetails/${localStorage.getItem("userName")}`).then((res) => {
        console.log("initail account det ", res);

        store.dispatch(setUserRoles(res?.data?.account?.roles));
      })
    } catch (error) {
      console.log("initial errp r", error);
    }
    UserDetailsApi().then((res) => {
      if (res.status === 200) {
        // set state of user details
        // const dispatch = useDispatch();
        // dispatch(setUserProfile(res.data[0].allValues));
        const userDetails = {
          ...res.data,
          token: localStorage.getItem("token"),
        };
        store.dispatch(setUserProfile(userDetails));
      } else {
        // something went wrong redirect to login page
        // localStorage.clear();
      }
    });


  }
}

root.render(
  <Provider store={store}>

    <App />

    <ToastContainer />
    <Tooltip id="my-tooltip" />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
