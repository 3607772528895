import { useContext } from "react";
import { ApiContext } from "./ApiProvider";


export const useApi = ()=>{
const context  =useContext(ApiContext)

if(context === undefined){
    throw new Error("UseApi must be used inside apiprovider")
}
return context 
}