export function FormatDateIntoPost(dateString) {
  if (!dateString) return "";
  const [year, month, day] = dateString?.split("-");
  return `${month}/${day}/${year}`;
}
export function FormatPostIntoDate(dateString) {
  if (!dateString) return "";
  const [day, month, year] = dateString?.split("/");
  return `${year}-${month}-${day}`;
}
