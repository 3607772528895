import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import star_1 from "../../Images/star 1.png";
import AcquiredSkillsUsingNameAndSkillAPI from "../../api/SkillSeeker/AcquiredSkillsUsingNameAndSkillAPI";
import user from "../../Images/avatar-placeholder.webp";

import { setAcquiredSkills, setAppliedSkills, setDetailedProfileData } from "../../reducer/SkillSeeker/SkillBasedSearch/SkillBasedResultSlice";
import { useDispatch } from 'react-redux';

import ValidationByItemIdAPI from "../../api/SkillSeeker/ValidationByItemIdAPI";
import { GetAttachment, splitStringToObject } from "../../api/Attachment  API/DownloadAttachmentApi";
import { FetchDetailedDataByUsedId } from "../../api/fetchAllData/FetchDetailedDataByUsedId";

import PrimaryBtn from "../Buttons/PrimaryBtn";
import Loader from './../Loader';
import SkillPRofileOfUserAndUserSkill from "../../api/SkillSeeker/SkillPRofileOfUserAndUserSkill";
import SkillProfileView from "./SkillProfileView";
import DetailedProfileViewSeeker from "./DetailedProfileViewSeeker";
const UserCardA = ({ userDetail, image, SelectedRefSkillFilter }) => {
    const active = {
        backgroundColor: "#EFF5DC",
        borderStyle: "solid",
        borderColor: "#815F0B",
        color: "#815F0B",
    };
    const dispatch = useDispatch();
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);
    //store applied and acquired skills data
    const [appliedSkillData, setAppliedSkillData] = useState([]);
    const [acquiredSkillData, setAcquiredSkillData] = useState([]);
    const [profilePictureObj, setProfilePictureObj] = useState([]);
    //refine my req
    const RefMyRequirements = useSelector(state => state.RefMyRequirements);
    //const [userDetails, setUserDetails] = useState(userDetail);
    let rankOn = false;

    useEffect(() => {
        if (userDetail?.ProfilePictureFileName && userDetail?.ProfilePictureFileName?.length > 1) {
            setProfilePictureObj(splitStringToObject(userDetail?.ProfilePictureFileName));
        }
        console.log("all details of detailed Profile ", userDetail);
    }, [userDetail])


    const [AcqAppDataLoaded, setAcqAppDataLoaded] = useState(false);
    // Create a deep copy of userDetails and sort the availableSkills array

    // Update the state with the sorted userDetails

    //let top5 = userDetails?.availableSkills.filter((data)=>data.Rank <=5);
    userDetail?.availableSkills?.map((data) => {
        if (data.Rank <= 5) {
            rankOn = true;
            return 0;
        }
        return 1;
    });

    // Make sure to adjust the dependencies based on your component's needs
    // summary and details
    const [skillProfileView, setSkillProfileView] = useState(false);
    const [detailProfileView, setDetailProfileView] = useState(false);
    const [skillPofileLoader, setSkillPofileLoader] = useState(false);
    const [detailProfileLoader, setDetailProfileLoader] = useState(false);
    const [detailBtnHighLight, setDetailBtnHighLight] = useState(false);
    const [skillBtnHighLight, setSkillBtnHighLight] = useState(false);
    //console.log("usercard ", userDetail);
    //api call for Acquired skilll
    const validationApiCaller = async (skillID) => {
        console.log("sACqval ", skillID);
        try {
            const skillValidation = await ValidationByItemIdAPI(skillID);

            console.log("sACqval ", skillValidation);
            return skillValidation;
        }
        catch (error) {
            console.log("error in validation api sACqval ", error);

        }


    }

    const SkillProfileAPICaller = async (skill) => {
        try {
            const data = await SkillPRofileOfUserAndUserSkill(userDetail.AccountID, skill);
            console.log("sACqval  SkillProfileAPICaller", skill, " ", data);
            if (data?.SkillsAcquired?.length > 0) {
                // Use Promise.all to wait for all validations to complete
                await Promise.all(
                    data?.map(async (item) => {
                        if (item?.id) {
                            console.log("sACqval  Acquired spinning acq ");
                            const validationData = await validationApiCaller(item?.id);
                            console.log("individual validationData ", validationData);
                            item.ValidationData = validationData ? validationData : [];
                        }
                        console.log("individual Item ", item);
                    })
                );
            }
            if (data?.skillsApplied?.length > 0) {
                // Use Promise.all to wait for all validations to complete

                await Promise.all(
                    data.skillsApplied?.map(async (item) => {
                        if (item?.id) {
                            console.log("sACqval Applied spinning acq ");
                            const validationData = await validationApiCaller(item?.id);
                            console.log("individual validationData ", validationData);
                            item.ValidationData = validationData ? validationData : [];
                        }
                        console.log("individual Item ", item);
                    })
                );
            }

            return data;
        } catch (error) {
            console.error("Error fetching Applied or val data:", error);
            return null;
        }
    };

    const apilooper = async () => {

        const promises = userDetail?.availableSkills?.map(async (skills) => {
            // const apidataAcquired = await AcquiredApiCaller(skills?.availableSkills);
            // const apidataApplied = await AppliedapiCaller(skills?.availableSkills);

            const skillProfileData = await SkillProfileAPICaller(skills?.skillOccupation);
            const apidataAcquired = skillProfileData?.skillsAcquired;
            const apidataApplied = skillProfileData?.skillsApplied;
            if (apidataAcquired && apidataAcquired.length > 0) {
                console.log("logging in acq....", apidataAcquired);

                setAcquiredSkillData((prev) => [...prev, ...apidataAcquired]);
            }
            if (apidataApplied && apidataApplied.length > 0) {
                console.log("logging in app....", apidataApplied);


                setAppliedSkillData((prev) => [...prev, ...apidataApplied]);
            }

        });



        // Wait for all promises to resolve
        await Promise.all(promises);
    };



    const handleSkillProfileClick1 = async () => {
        if (skillProfileView === false) {
            setSkillProfileView(true);

            setSkillBtnHighLight(true);
            setDetailBtnHighLight(false);
            setDetailProfileView(false);
        } else {
            setSkillProfileView(false);
            setSkillBtnHighLight(false);
            setDetailBtnHighLight(false);
            setDetailProfileView(false);
        }

        if (!AcqAppDataLoaded) {
            setSkillPofileLoader(true);
            try {
                setAcqAppDataLoaded(true);
                // Reset acquired and applied skill data arrays
                setAcquiredSkillData([]);
                setAppliedSkillData([]);

                // Wait for all asynchronous API calls to finish
                await apilooper();

                console.log("looper ", acquiredSkillData);


            } catch (error) {
                // Handle errors appropriately
                console.error("Error fetching skills:", error);
            } finally {
                setSkillPofileLoader(false);
            }
        }

    };

    useEffect(() => {
        dispatch(setAcquiredSkills({ userId: userDetail.userId, acquiredSkillData: acquiredSkillData }));
        dispatch(setAppliedSkills({ userId: userDetail.userId, appliedSkillData: appliedSkillData }));
    }, [acquiredSkillData, appliedSkillData])

    const handleDetailedProfileClick = async () => {

        if (detailProfileView === false) {
            setSkillProfileView(false);

            setSkillBtnHighLight(false);
            setDetailBtnHighLight(true);
            setDetailProfileView(true);
        } else {
            setSkillProfileView(false);
            setSkillBtnHighLight(false);
            setDetailBtnHighLight(false);
            setDetailProfileView(false);
        }



        if (!userDetail.detailedProfileData) {

            try {
                console.log("Setting loader to true");
                setDetailProfileLoader(true);
                const res = await FetchDetailedDataByUsedId(userDetail?.userId);

                dispatch(setDetailedProfileData(res));
                console.log("all details of detailed Profile ", userDetail?.detailedProfileData);


            } catch (error) {
                // Handle errors appropriately
                console.error("Error fetching skills:", error);

            } finally {
                setDetailProfileLoader(false);
            }
        }



    };

    useEffect(() => {
        console.log("detailProfileLoader ", detailProfileLoader);



    }, [detailProfileLoader])

    return (
        <div>
            <div
                className="mx-1 my-1  bg-body-tertiary row border mb-3  "
                style={{
                    borderRadius: "10px",
                    borderColor: "#815F0B",
                    borderStyle: "solid",
                    marginBottom: "50px"
                }}
            >
                <div className="col-3 text-center mt-3  ">
                    <div class="avatar ">
                        {userDetail?.ProfilePictureFileName
                            && userDetail?.ProfilePictureFileName.length > 2 && userDetail?.PPShowHide !== 'Yes' ?
                            <img style={{ objectFit: "cover" }}
                                //`${BASE_URL}/skill/api/v1/skills/imgHandle/filedownload/${userInfo?.id}/${userInfo?.attachmentFileNames}?authToken=${token}`
                                src={GetAttachment(userDetail?.userId, profilePictureObj?.fileName, profilePictureObj?.fileId)}
                                alt="Admin" class="rounded-circle" width="100" height="100" />


                            :
                            <img src={user} alt="Admin" class="rounded-circle" width="100" />}
                    </div>
                </div>
                {/* USer CARD ONLY */}
                <div className="col ">
                    <div className="row ">
                        <div className="col-7 ">
                            <div>
                                <h6 class="mb-1 mt-2 font-5" key={userDetail.AccountID}>
                                    {userDetail?.LNShowHide === "Yes" || userDetail?.FNShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.FirstName + " " + userDetail.LastName}
                                </h6>
                                {/* skill badge under name  */}
                                <div className="d-flex  ">
                                    {/* <span class="badge badge-dark bg-black  ">{userDetails.location}</span> */}
                                    {userDetail?.availableSkills?.map((topSkill) =>
                                        (!topSkill.Rank || topSkill.Rank > 5)

                                        &&
                                        <div data-tooltip-id="my-tooltip" data-tooltip-content={topSkill?.Skill ? topSkill?.Skill : topSkill?.skillOccupation}
                                            className=" fw-bold me-2 d-flex flex-row font-6  mx-1 overflowDots" onHover
                                            style={{
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: "#815F0B",
                                                backgroundColor: "#F7FFDD",
                                                color: "#815F0B",
                                                padding: "1%",
                                                borderRadius: "6px",
                                                alignContent: "center",
                                                alignItems: "center",
                                               
                                                height: "25px", 


                                            }}
                                        >
                                            {topSkill?.Skill ?  topSkill?.Skill  : topSkill?.skillOccupation?.split('||')[0] }
                                        </div>)}
                                </div>
                            </div>
                        </div>
                        {/* Users TOP 5 SKILL box */}
                        <div className="col-5 mt-2 font-5">
                            {rankOn && <div className="font-5"> {(content[selectedLanguage].find(item => item.elementLabel === 'UsersTopSkill') || {}).mvalue || "nf User's Top Skill"}</div>}
                            <div class="mt-2 d-flex " style={{ maxHeight: "70px", minHeight: "70px", overflow: "auto" }}>
                                {userDetail?.availableSkills?.map((topSkill) =>
                                    topSkill.Rank <= 5 &&
                                    <div className="d-flex flex-row  font-5" style={{ display: "flex", alignItems: "baseline" }}>
                                        <div
                                            className="fw-bold me-2 d-flex flex-row   "
                                            style={{
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: "#815F0B",
                                                backgroundColor: "#F7FFDD",
                                                color: "#815F0B",
                                                padding: "1%",
                                                borderRadius: "7px",
                                                alignContent: "center",
                                                alignItems: "center",
                                                width: "100px",
                                                maxWidth: "100px",

                                                textAlign: "center",

                                            }} onHover >

                                            <img src={star_1} alt={topSkill.Rank} style={{ height: "15px", left: "0px" }} ></img>

                                            <div style={{ width: "100%", maxWidth: "100%" }} className="d-flex justify-content-center overflowDots text-center  font-5" data-tooltip-id="my-tooltip" data-tooltip-content={topSkill?.Skill ? topSkill?.Skill : topSkill?.skillOccupation} >
                                                {topSkill?.Skill ? topSkill?.Skill?.length < 10 ? topSkill?.Skill : topSkill?.Skill?.slice(0, 9) + "..." : topSkill?.skillOccupation?.split('||')[0].length < 10 ? topSkill?.skillOccupation?.split('||')[0] : topSkill?.skillOccupation?.split('||')[0].slice(0, 9) + "..."}
                                            </div>
                                        </div>

                                    </div>

                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-7 ">
                            <p class="text-muted">
                                {userDetail.About}
                            </p>
                        </div>

                        <div class="col ">

                            <p class="small mb-0 text-muted">{userDetail.PrefferedLocation} </p>
                            <p class="small mb-0 text-muted mt-1">{userDetail?.EmailShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.Email}</p>
                            <p class="small mb-0 text-muted mt-1">{userDetail?.PhNoShowHide === "Yes" ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : userDetail.PhoneNumber}</p>

                            <div className="d-flex gap-2 my-2 ">
                                <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'SkillProfile') || {}).mvalue || "nf SkillProfile"} onClick={handleSkillProfileClick1} backgroundColor="#F7FFDD" color="#815F0B" statusTab={skillBtnHighLight} font={"400"} />
                                <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'DetailedProfile') || {}).mvalue || "nf DetailedProfile"} onClick={handleDetailedProfileClick} backgroundColor="#F7FFDD" color="#815F0B" statusTab={detailBtnHighLight} font={"400"} />

                            </div>
                        </div>
                    </div>
                </div>

                <div>


                    {/* Type 2 summary  card*/}

                    {/* detils */}
                    <SkillProfileView skillProfileView={skillProfileView} userDetail={userDetail} skillPofileLoader={skillPofileLoader} />
                    <DetailedProfileViewSeeker detailProfileView={detailProfileView} userDetail={userDetail} detailProfileLoader={detailProfileLoader} />

                </div>
            </div>
        </div >
    );
};
export default UserCardA;