export function timestampToYYYYMMDD(timestamp) {
  if (!timestamp || isNaN(timestamp)) {
    return null;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}