import { debounce } from "lodash";


const sendRequest = (api,value, selectedLanguage, setSkillSuggestions,setSuggestionLoader) => {

  // send value to the backend
  api(value, selectedLanguage).then((res) => {
    console.log(res.data);
    setSuggestionLoader(false); 
    // check res data length if empty pass No suggestions found
    if (res.data.length === 0) {
      setSkillSuggestions([
        { skillOccupation: "No suggestions found", id: 1, occupation: selectedLanguage },
      ]);
    } else {
      setSkillSuggestions(res.data);
    }
  });
    
};


// debounce(callback, wait in ms)
export const debouncedApiRequest = debounce(sendRequest, 500);
