import React from 'react'
import FeaturedServiceProvider from '../../components/Supportservices/FeaturedServiceProvider'



export default function CompanyList() {
  return (
    <>
      <div class="mt-4 mb-5 text-center">
        <h4>Search Results For "service providers"</h4></div>
        
        <div className="row">
          <div className="col-12">
          <FeaturedServiceProvider name={"ABC Service Company"}/>
          </div>
        </div>
       
        
      
        
   
    </>
  )
}
