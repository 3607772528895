import React, { useEffect, useState } from 'react'
import { showErrorToast } from '../../ToastNotification/showErrorToast'
import { BASE_URL } from '../../../config/Properties'
import Loader from '../../Loader'
import { FiMinus } from 'react-icons/fi'

const SkillsAttainable = ({ courseId }) => {
    const [skillsAttainable, setSkillsAttainable] = useState([])
    const [skillsAttainableLoading, setSkillsAttainableLoading] = useState(false)

    const fetchUserCourseSkillsAttainable = async () => {
        setSkillsAttainableLoading(true)
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/courses/UserCourse%20SkillsAttainable?authToken=${token}&searchFieldName=userCourseId&searchValue=${courseId}`);
            const data = await response.json();
            setSkillsAttainable(data)
            console.log(data);
            setSkillsAttainableLoading(false)
        } catch (error) {
            showErrorToast('something went wrong')
            setSkillsAttainableLoading(false)
            throw error;
        }
    }
    useEffect(() => {
        fetchUserCourseSkillsAttainable()
    }, [courseId])


    if (skillsAttainableLoading) {
        return (
            <div class="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <div class="spinner-border" style={{ width: "2rem", height: "2rem" }} role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
  return (
    <>
                <table class="table table-hover">
                <thead>
                    <tr>
                        <th className='p-1' scope="col">#</th>
                        <th className='p-1' scope="col">Skills/Topic Name</th>
                        <th className='p-1' scope="col">Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {
                     skillsAttainable?.map((topic, i) => {
                        if(topic.skillId){
                        return (
                          <tr>
                            <th className='p-1' scope="row">{i + 1}</th>
                            <td className='p-1'>{topic.skillId}</td>
                            <td className='p-1'>{topic.duration} - NA</td>
                            {/* <td className='p-1'>{topic.duration}{topic.phase}</td> */}

                          </tr>
                        )

                        }
                      })
                    }
                </tbody>
            </table>
      
    </>
  )
}

export default SkillsAttainable
