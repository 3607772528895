import React, { useCallback, useEffect, useState } from "react";
import TextStylingComponent from "./TextStylingComponent";

const PersonalInfo = ({
  content,
  selectedLanguage,
  userDetails,
  alignment,
  fontColor,
  fontSize,
  profileNameColor,
  profieOtherDetailColor,
  isShowStylingOptions,
  handleStylingOptions,
  handleAlignmentChange,
  handleFontColor,
  handleProfileNameColor,
  handleProfileOtherColor,
  profileNameAlignment,
  profileDetailAlignment,
  handleProfileNameAlignment,
  handleOtherDetailAligment,
  setIsShowStylingOptions,
  handleFontPNSizeInc,
  handleFontPNSizeDec,
  profileNameSize,
  profielDetailSize,
  handleFontOtherDSizeInc,
  handleFontOtherDSizeDec,
}) => {
  /* STATES */
  const [isNameBold, setIsNameBold] = useState(false);
  const [isNameItalic, setIsNameItalic] = useState(false);
  const [isOtherBold, setIsOtherBold] = useState(false);
  const [isOtherItalic, setIsOtherItalic] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");

  /* HANDLE BOLD FONT */
  const handleFontBold = useCallback(() => {
    if (selectedVal === "profileName") {
      setIsNameBold((prev) => !prev);
    } else if (selectedVal === "OtherDetails") {
      setIsOtherBold((prev) => !prev);
    }
  }, [selectedVal]);

  /* HANDLE FONT ITALICS */
  const handleFontItalics = useCallback(() => {
    if (selectedVal === "profileName") {
      setIsNameItalic((prev) => !prev);
    } else if (selectedVal === "OtherDetails") {
      setIsOtherItalic((prev) => !prev);
    }
  }, [selectedVal]);

  /* HANDLE CLOSE STYLING OPTIONS */
  const handleCloseStylingOptions = useCallback(() => {
    setIsShowStylingOptions(false);
  }, [setIsShowStylingOptions]);

  /* CSS VARIABLE FOR DYNAMIC STYLING */
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--selected-color-header-name",
      profileNameColor
    );
    document.documentElement.style.setProperty(
      "--selected-color-header-other",
      profieOtherDetailColor
    );
  }, [profileNameColor, profieOtherDetailColor]);
  return (
    <div className="position-relative">
      <React.Fragment>
        <div
          className="resume-profile-name"
          style={{
            color: profileNameColor && fontColor ? profileNameColor : fontColor,
            textAlign:
              profileNameAlignment && alignment
                ? profileNameAlignment
                : alignment,
            fontWeight: isNameBold ? "bolder" : "normal",
            fontStyle: isNameItalic ? "italic" : "inherit",
            fontSize: profileNameSize && fontSize ? profileNameSize : fontSize,
          }}
          onDoubleClick={() => {
            handleStylingOptions("profileName");
            setSelectedVal("profileName");
          }}
          onClick={handleCloseStylingOptions}
        >
          {userDetails?.fn !== "Yes" && userDetails?.mlnShowHide !== "Yes"
            ? userDetails?.firstName + " " + userDetails?.lastName
            : userDetails?.fn !== "Yes" && userDetails?.mlnShowHide === "No"
              ? userDetails?.lastName
              : userDetails?.fn === "No" && userDetails?.mlnShowHide !== "Yes"
                ? userDetails?.firstName
                : (
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Confidential"
                  ) || {}
                ).mvalue || "nf CONFIDENTIAL"}
        </div>
        {isShowStylingOptions && (
          <div
            className="card position-absolute no-print"
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
          >
            <TextStylingComponent
              handleProfileNameColor={handleProfileNameColor}
              handleProfileOtherColor={handleProfileOtherColor}
              handleAlignmentChange={handleAlignmentChange}
              handleProfileNameAlignment={handleProfileNameAlignment}
              handleOtherDetailAligment={handleOtherDetailAligment}
              onBoldChange={handleFontBold}
              onItalicChange={handleFontItalics}
              handleFontPNSizeInc={handleFontPNSizeInc}
              handleFontPNSizeDec={handleFontPNSizeDec}
              handleFontOtherDSizeInc={handleFontOtherDSizeInc}
              handleFontOtherDSizeDec={handleFontOtherDSizeDec}
              selectedVal={selectedVal}
            />
          </div>
        )}
        <div
          className="profile-other-details mt-3"
          style={{
            color:
              profieOtherDetailColor && fontColor
                ? profieOtherDetailColor
                : fontColor,
            textAlign:
              profileDetailAlignment && alignment
                ? profileDetailAlignment
                : alignment,
            fontWeight: isOtherBold ? "bold" : "normal",
            fontStyle: isOtherItalic ? "italic" : "",
            fontSize:
              profielDetailSize && fontSize ? profielDetailSize : fontSize,
          }}
          onDoubleClick={() => {
            handleStylingOptions("OtherDetails");
            setSelectedVal("OtherDetails");
          }}
          onClick={handleCloseStylingOptions}
        >
          {
            userDetails?.memailShowHide !== "Yes" && userDetails?.email &&
            <div>
              <a
                className="text-decoration-underline mail_resume_link"
                href={`mailto:${userDetails?.memailShowHide !== "Yes"
                  ? userDetails?.email
                  : "mail-confidential"
                  }`}
              >
                {userDetails?.memailShowHide !== "Yes"
                  ? userDetails?.email
                  : (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Confidential"
                    ) || {}
                  ).mvalue || "nf CONFIDENTIAL"}{" "}
              </a>
            </div>
          }
          {
            userDetails?.mnShowHide !== "Yes"
            && userDetails.mobileNumber &&
            <div>
              {userDetails?.mnShowHide !== "Yes"
                ? userDetails.mobileNumber
                : (
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Confidential"
                  ) || {}
                ).mvalue || "nf CONFIDENTIAL"}{" "}
            </div>
          }
          <div>{userDetails?.about}</div>
          {/* <div>
            {userDetails?.address1ShowHide !== "Yes"
              ? userDetails?.
              : (
                 content[selectedLanguage].find(
                    (item) =>
                        item.elementLabel ===
                        "Confidential"
                    ) || {}
                ).mvalue || "nf CONFIDENTIAL"}
          </div> */}
        </div>
      </React.Fragment>
    </div>
  );
};

export default PersonalInfo;
