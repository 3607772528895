export function formatDateInputType(inputFormat) {
    // Capitalize the month part if the month index is before the day index
    let dateFormat = localStorage.getItem("dateFormat")
    console.log(dateFormat);
    if (dateFormat === 'dd/mm/yyyy') {
        return 'dd/MM/yyyy' ;
    }
    if (dateFormat === 'mm/dd/yyyy') { // Capitalize the day part otherwise
        return 'MM/dd/yyyy';
    }
   
}