import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showWarningToast = (message, options) => {
  toast.warning(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    ...options,
  });
};
