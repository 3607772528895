import { createSlice } from "@reduxjs/toolkit";
import { fetchEducationHistory } from "../../api/fetchAllData/fetchEducationHistory";
const initialState = {
  data: [{
    course: "B.E",
    startDate: "1706745600000",
    endDate: "1708992000000",
    duration: "12",
    briefDescription: "Salesforce offers certifications related to its Sales Cloud platform, which is widely used in sales development and customer relationship management (CRM).",
    institute: "Iit Bombay",
    location: "Online",
    type: "BACHELOR'S DEGREE",
    showHide: true,
    validation: true,
    blockChain: true,
    dateSent: "01/06/2021",
    validatorName: "James",
    relationship: "Own Team Member",
    description: "David Marcos's performance on the lead generation campaign has been exceptional. His dedication and hard work significantly contributed to our ability to identify and qualify leads effectively.    ",
    validatorEmail: "james@gmail.com",
    validatorPhone: "9710838457",
    isValidated: true

  },
  {
    course: "B.TECH",
    startDate: "12/01/2012",
    endDate: "12/04/2016",
    duration: "6",
    briefDescription: "Certification can be valuable for understanding website analytics, tracking user behavior, and optimizing online marketing campaigns, all of which are important in sales development",
    institute: "Anna University",
    location: "CHENNAI",
    type: "BACHELOR'S DEGREE",
    showHide: false,
    validation: true,
    blockChain: false,
    dateSent: "03/01/2022",
    validatorName: "Michael Johnson",
    relationship: "External Consultant",
    description:
      "Michael Johnson's expertise in marketing analytics was instrumental in the successful development of the marketing analytics dashboard. He provided valuable insights and recommendations throughout the project.",
    validatorEmail: "michael@example.com",
    validatorPhone: "555-123-4567",
    isValidated: true

  }],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const educationSlice = createSlice({
  name: "educationHistory",
  initialState,
  reducers: {
    setEducation: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyEducation: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewEducation: (state, action) => {
      state.data = [action.payload, ...state.data];
      state.status = "success";
      state.error = null;
    },
    EducationDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutEducation: () => initialState,
    educationUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    educationUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducationHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchEducationHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchEducationHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },

});

export const {educationUpdateRecord, seteducation, emptyeducation, educationUpdate, EducationDelete } = educationSlice.actions;
export default educationSlice.reducer;
