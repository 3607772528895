import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux';
import { BASE_URL } from '../../config/Properties';



const ValidationDataComponentSeeker = ({ itemId, data }) => {



    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    const [validationReqData, setValidationReqData] = useState([]);
    const [count, setCount] = useState(0);
    //const regionalData = useSelector(state => state.regionalData);


    useEffect(() => {
        console.log("ValidationReqData checker ", data);
        /* STORE THE FILTERED VALUE FOR VALIDATION REQUEST TABLE */
        ///const flattenData = data.flatMap(val => val.validator || []);

        const filterValidationReqData = data?.filter(

            (val) => val?.requestorItemId === itemId
        );
        const filterHiddenValidation = filterValidationReqData?.filter(

            (val) => val?.recordHide !== "Yes"
        );
        console.log("checker all ", data);
        console.log("checker foll ", filterValidationReqData);
        setValidationReqData(filterHiddenValidation);
        let c = 0;
        // validationReqData.map((validatior) => (
        //     validatior.recordHide !== 'Yes'));


    }, [data, itemId]);


    useEffect(() => {
        console.log("ValidationReqData ", validationReqData);



    }, [validationReqData]);










    const token = localStorage.getItem("token");




    return (

        <>

            {validationReqData && validationReqData?.length > 0 &&

                <React.Fragment>


                    <div className="ms-0" style={{ backgroundColor: "#E5E5E5", overflowX: "hidden" }}>
                        <p className='mt-1  ' style={{ marginBottom: "-1px" }} ><b>{(
                            content[selectedLanguage].find(
                                (item) => item.elementLabel === "MyValidations"
                            ) || {}
                        ).mvalue || "nf MyValidation"}{" :"}</b></p>
                        <table className=" ms-1 mt-0" style={{ backgroundColor: "#E5E5E5", overflowX: "hidden" }}  >
                            <thead
                                style={{ position: "sticky", top: "0", zIndex: "2" }}
                            >
                                <tr>

                                    <th className="fw-bold" width="20%" >{(content[selectedLanguage].find((item) => item.elementLabel === "ProjeValidator") ||
                                        {}).mvalue || "nf ProjeValidator"}</th>
                                    <th className="fw-bold" width="14%">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectValidatorRelationship") ||
                                        {}).mvalue || "nf Relationship"}</th>
                                    <th className="fw-bold" width="40%">{(
                                        content[selectedLanguage].find(
                                            (item) => item.elementLabel === "ValidatorsRemark"
                                        ) || {}
                                    ).mvalue || "nf Validators Remark"}</th>
                                    <th className="fw-bold" width="25%">{(content[selectedLanguage].find((item) => item.elementLabel === "ValidationStatus") ||
                                        {}).mvalue || "nf ValidationStatus"}</th>

                                </tr>
                            </thead>
                            <tbody style={{ borderBottomStyle: "hidden" }} >
                                {
                                    validationReqData.map((validatior, index) => (
                                        validatior.recordHide !== 'Yes' && validatior?.validatorResponse === "Accept" &&
                                        <React.Fragment key={validatior.id}>

                                            <tr style={{ borderBottomStyle: "hidden" }}>

                                                <td width="16%">
                                                    {validatior.validatorName}
                                                </td>
                                                <td width="14%">
                                                    {validatior.relationship}
                                                </td>

                                                <td width="40%">

                                                    {validatior?.validatorComments
                                                        ? validatior?.validatorComments
                                                        : "No Remarks from validator"}



                                                </td>


                                                <td width="25%">
                                                    {validatior?.validatorResponse ===
                                                        "Request Sent" ||
                                                        validatior?.validatorResponse ===
                                                        "Request Expired"
                                                        ? validatior?.validatorResponse
                                                        : validatior?.validatorResponse ===
                                                            "Resend"
                                                            ? "Resent"
                                                            : `${validatior?.validatorResponse}ed`}
                                                </td>
                                            </tr>
                                            <tr>

                                                <td width="20%">

                                                </td>
                                                <td width="25%">

                                                </td>

                                                {/* <div width="40%" style={{ borderTopStyle: "hidden" }} >

                                                    {validatior?.validatorAttachmentFileNames &&

                                                        validatior?.validatorAttachmentFileNames.length > 1 ?

                                                        <div >
                                                            <p>{(content[selectedLanguage].find((item) => item.elementLabel === "ValidatorsAttachments") ||
                                                                {}).mvalue || "nf Validators Attachments"}:</p>
                                                            <div className='d-flex flex-column ms-2 '>
                                                                
                                                                <p>
                                                                    <span>
                                                                        {validatior?.validatorAttachmentFileNames &&
                                                                            JSON.parse(
                                                                                validatior?.validatorAttachmentFileNames
                                                                            ) ? (
                                                                            JSON.parse(
                                                                                validatior?.validatorAttachmentFileNames
                                                                            ).map((attachment, index) => (
                                                                                <div>
                                                                                    <a
                                                                                        key={index}
                                                                                        rel="noreferrer"
                                                                                        href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment?.filename}s/${attachment?.fileId}`}
                                                                                        target="_blank"
                                                                                    >

                                                                                        {`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment?.filename}/${attachment?.fileId}`}
                                                                                    </a>
                                                                                </div >
                                                                            ))
                                                                        ) : (
                                                                            <p className="text-primary"></p>
                                                                        )}
                                                                    </span>
                                                                </p>


                                                            </div>
                                                        </div>

                                                        :
                                                        <>
                                                        </>

                                                    }



                                                </div> */}
                                                <td >

                                                </td>




                                            </tr>
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </React.Fragment >

            }
        </>
    )

}

export default ValidationDataComponentSeeker;