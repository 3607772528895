import React from "react";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import Anonimization from "../../Anonimization";
import AttachmentForm from "../../AttachmentForm";
import TableLoaders from "../../CustomLoader/TableLoaders";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import ShowHideIcon from "../../ShowHideIcon";
import Validation from "../../ValidationForm";
import EditDetailedProfileLicesnseAndAwards from "../Forms/Edit Forms/EditDetailedProfileLicesnseAndAwards";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
const AchievementDetail = ({ data, yoeCalc, editEnable }) => {
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };
  const regionalData = useSelector((state) => state.regionalData);

  /* HANDLE VALIDATE */
  const handleValidate = (validationData) => {
    dispatch(setValidationData(validationData));
  };

  /* HANDLE EDIT FOR AWARDS HISTORY */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };
  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      <AttachmentForm />
      <DeleteFormDetailedProfile />
      {/* AWARDS DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfileLicesnseAndAwards />}
      {/* table start */}
      <div className="table-responsive ">
        <table className="table table-sm  table-fixed     ">
          <tr className="border-dark-subtle border-bottom ">
            <th scope="col" className="bg-body- " style={{ width: "34%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Award/Achievement"
                ) || {}
              ).mvalue || "nf Award/Achievement"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "17%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectOrganization"
                ) || {}
              ).mvalue || "nf Organization"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "13%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Date"
                ) || {}
              ).mvalue || "nf Date"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "14%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectLocation"
                ) || {}
              ).mvalue || "nf Location"}{" "}
            </th>
            <th scope="col" className="         " style={{ width: "12%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectValidation"
                ) || {}
              ).mvalue || "nf Validation"}{" "}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "10%" }}></th>
          </tr>
        </table>
      </div>

      {data.status === "loading" ? (
        <TableLoaders Rows={2} Cols={5} btnCols={2} />
      ) : (
        data.status === "success" &&
        data.data.length > 0 &&
        data.data.map((certs, index) => (
          <div className="border-bottom  pt-2">
            <div className="d-flex   ">
              <div className=""></div>
              <div className="  " style={{ width: "34%" }}>
                {certs.title}
              </div>
              <div className="    " style={{ width: "17%" }}>
                {certs.organization}
              </div>
              <div style={{ width: "13%" }}>
                {formatTimestampToDate(
                  Number(certs.date),
                  regionalData.selectedCountry.dateFormat
                )}
              </div>
              <div className="    " style={{ width: "14%" }}>
                {certs.location}
              </div>
              <div className="" style={{ width: "12%" }}>
                {certs.validation === "Yes" ? "Yes" : "No"}
              </div>
              <div
                className="d-flex justify-content-between align-items-baseline"
                style={{ width: "10%" }}
              >
                {editEnable ? (
                  <>
                    <div
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Edit"
                      data-bs-toggle="modal"
                      data-bs-target="#EditDetailedProfileLicesnseModal"
                      onClick={() => handleEdit(certs)}
                    >
                      <MdEdit />
                    </div>
                    <div
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteformdetailedprofile"
                      onClick={() => {
                        dispatch(setDeleteDetailedProfileData(certs));
                      }}
                    >
                      <MdDelete />
                    </div>

                    <div style={{ cursor: "pointer" }}>
                      <ShowHideIcon
                        value={certs.recordHide === "Yes" ? true : false}
                        toggleable={true}
                        form={certs}
                        fieldName={"recordHide"}
                        api={"Skills Applied"}
                        id={certs.id}
                      />
                    </div>
                    <div style={{ cursor: "pointer" }}>
                      <Anonimization
                        value={certs.recordAnonymous === "Yes" ? true : false}
                        toggleable={true}
                        form={certs}
                        fieldName={"recordAnonymous"}
                        api={"Skills Applied"}
                        id={certs.id}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Validate"
                      data-bs-toggle="modal"
                      data-bs-target="#validationForm"
                      onClick={() => handleValidate(certs)}
                    >
                      <img
                        src={validationIcon}
                        alt=""
                        style={{ width: "1rem", height: "1rem" }}
                      />
                    </button>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Attachment"
                      onClick={() => handleAttachment(certs)}
                      data-bs-toggle="modal"
                      data-bs-target="#attachmentList"
                    >
                      <ImAttachment />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="ms-5  my-3  ">
              <div className="pb-1 pt-2  ">
                <b>
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "ProjectBriefDescription"
                    ) || {}
                  ).mvalue || "nf Brief Description"}{" "}
                  :
                </b>{" "}
              </div>
              <div className="ms-4  ">{certs.briefDescription}</div>

              {certs.validation === "Yes" && (
                <ValidationDataComponent itemId={certs.id} res={true} />
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default AchievementDetail;
