import React from 'react'

const PasswordModal = () => {
    return (
        <div class="modal fade modal-xl" id="password" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div className=''>
                            <div>
                                <div class="container">
                                    <div class="row justify-content-center  mb-2 pb-2  ">
                                        <div class="col-12    bg-body-secondary  pt-2  ">
                                            <div class="">

                                                <form>
                                                    <div class="row mb-4">
                                                        <div class="col-md-6">
                                                            <div class="form-group my-1 ">
                                                                <label for="inputPassword4">Old Password</label>
                                                                <input type="password" style={{ height: "32px" }} class="form-control" id="inputPassword5" />
                                                            </div>
                                                            <div class="form-group my-1 ">
                                                                <label for="inputPassword5">New Password</label>
                                                                <input type="password" style={{ height: "32px" }} class="form-control" id="inputPassword5" />
                                                            </div>
                                                            <div class="form-group my-1 ">
                                                                <label for="inputPassword6">Confirm Password</label>
                                                                <input type="password" style={{ height: "32px" }} class="form-control" id="inputPassword6" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="mb-2">Password requirements</p>
                                                            <p class="small text-muted mb-2">To create a new password, you have to meet all of the following requirements:</p>
                                                            <ul class="small text-muted pl-4 mb-0">
                                                                <li>Minimum 8 character</li>
                                                                <li>At least one special character</li>
                                                                <li>At least one number</li>
                                                                <li>Can’t be the same as a previous password</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <button class="btn text-white mb-3 " style={{ backgroundColor: "#815F0B" }}>Reset Password</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default PasswordModal