import React, { useEffect, useState } from 'react'
import SecondaryBtn from '../../Buttons/SecondaryBtn'
import MultiSelect from '../SelectComponent/MultiSelect';
import { timestampToYYYYMMDD } from '../HelperFunction/timestampToYYYYMMDD';
import PostApi from '../../../api/PostData/PostApi';
import { addNewCertification } from '../../../reducer/detailedProfile/certificationSlice';
import { useDispatch } from 'react-redux';
import { convertDateToMilliseconds } from '../HelperFunction/convertDateToMilliseconds';
import { showSuccessToast } from '../../ToastNotification/showSuccessToast';
import { showErrorToast } from '../../ToastNotification/showErrorToast';
import CreateSelect from '../SelectComponent/CreateSelect';
import { useSelector } from 'react-redux';
import { RxCross2 } from 'react-icons/rx';
import SmallLoader from '../../SkillAvailer/SmallLoader';
import CreatableSelect from 'react-select/creatable';
import { fetchConferencesHistory } from '../../../api/fetchAllData/fetchConferenceHistory';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import educationInstitutionApi from '../../../api/searchSuggestionAPIs/educationInstitutionApi';
import CreateSelectInstitution from '../SelectComponent/CreateSelectInstitution';
import { ThreeDots } from 'react-loader-spinner';
import SecondaryBtnLoader from '../../Buttons/SecondaryBtnLoader';
import { FormatDateIntoPost } from '../HelperFunction/FormatDateIntoPost';
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference ';
import DatePicker from 'react-datepicker';
import { formatDateInputType } from '../HelperFunction/FormatDateInputType';

const ConferencesForm = ({ formvalues, setFormValues, setValidation, setFileupload, handleSubmit, isAcquiredEdit, handleModalClose, isLoading, sendconfToParent }) => {
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    /* STORE IMPORTS */
    const {
        regionalData,
    } = useSelector((state) => state);

    //redux
    const ConferenceHistory = useSelector(state => state.conferenceHistory);
    const [conferenceOptions, setConferenceOptions] = useState([])

    useEffect(() => {
        const filterConference = ConferenceHistory?.data
            .map((employment) => ({
                value: employment,
                label: employment.title,
            }));

        setConferenceOptions(filterConference)

    }, [])

    const [selectedConference, setSelectedConference] = useState(null)




    const [onGoing, setOnGoing] = useState(false);
    const [locationForSkilling, setLocationForSkilling] = useState([])
    const [onlineLocation, setOnlineLocation] = useState(false);
    // const [isAddingNewCert, setIsAddingSkilling] = useState(false);

    const [location, setLocation] = useState([]);
    const [online, setOnline] = useState(false);


    const [isAdddingCert, setIsAddingCert] = useState(false)

    const [isAdddingSkilling, setIsAddingSkilling] = useState(false)


    const [resetLocation, setResetLocation] = useState(false);
    const [companySelectValue, setCompanySelectValue] = useState(null);

    //locations for certificate from selected company 
    useEffect(() => {
        let locations = selectedConference?.value?.location?.split(",")
        setLocationForSkilling(locations)
    }, [selectedConference])


    const dispatch = useDispatch()

    const handleValidateProject = () => {
        setValidation(true);
    };

    // useEffect(() => {
    //     dispatch(fetchConferencesHistory());
    // }, [])

    useEffect(() => {
        if (locationForSkilling?.length > 0) {
            setFormValues({ ...formvalues, location: locationForSkilling.join(',') })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationForSkilling])

    useEffect(() => {
        if (location?.length > 0) {
            setFormValues({ ...formvalues, location: location })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



    useEffect(() => {
        if (isAcquiredEdit && formvalues.location.length > 0 && locationForSkilling.length === 0) {
            setLocationForSkilling(formvalues.location)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAcquiredEdit])

    const employmentHistory = useSelector((state) => state.employmentHistory.data);

    const filterEmployment = employmentHistory
        .filter((employment) => employment.mlanguage === selectedLanguage)
        .map((employment) => ({
            value: employment,
            label: employment.organization,
        }));




    const handleCertChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedConference(selectedOption);
            setFormValues({
                ...formvalues,
                organization: selectedOption?.value?.organization,
                source: selectedOption?.value?.organization,
                fromDate: timestampToYYYYMMDD(Number(selectedOption?.value?.startDate)),
                toDate: (selectedOption?.value?.endDate) ? timestampToYYYYMMDD(Number(selectedOption?.value?.endDate)) : "",
                title: selectedOption?.label,
                insIsnew: selectedOption?.__isNew__,
                ticketids: selectedOption?.value?.id
            });
        } else {
            setSelectedConference(null);
            setFormValues({
                ...formvalues,
                organization: '',
                certificationName: '',
            });
        }

    }

    const formatDate = (inputDate) => {
        const [year, month, day] = inputDate.split("-");
        return `${month}/${day}/${year}`;
    };

    const handleSubmitDate = (close) => {

        handleSubmit(close)

    }

    //Api institution data
    const [institutionApiData, setInstitutionApiData] = useState([]);
    const [insConvertedToSelect, setInsConvertedToSelect] = useState([]);
    const [insSearch, setInsSearch] = useState("");
    const [eduApiLoader, setEduApiLoader] = useState(false);

    useEffect(() => {
        if (insSearch.length > 0 && insSearch !== " ") {
            setEduApiLoader(true);
            debouncedApiRequest(educationInstitutionApi, insSearch, selectedLanguage, setInstitutionApiData, setEduApiLoader);
        }

    }, [insSearch])

    useEffect(() => {
        if (institutionApiData.length > 0) {
            const data = institutionApiData.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.institutionName, // Add new field 1 with a default value
                    label: item.institutionName, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setInsConvertedToSelect(data);
        }

    }, [institutionApiData])


    useEffect(() => {
        if (!selectedConference) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }
        if (selectedConference?.__isNew__) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }


        if (!selectedConference?.value?.endDate) {
            setOnGoing({ ...onGoing, certificationEndDate: true })
        } else {
            setOnGoing({ ...onGoing, certificationEndDate: false })
        }

        sendconfToParent(selectedConference);
    }, [selectedConference?.label, selectedConference])

    const handleAddConfHistory = () => {

        setIsAddingCert(true)
        PostApi('Conferences',
            {
                ...formvalues, organization: formvalues.organization, source: formvalues.organization,
                startDate: FormatDateIntoPost(formvalues.fromDate), endDate: formvalues.toDate ? FormatDateIntoPost(formvalues.toDate) : "",
                duration: calculateDaysDifference(convertDateToMilliseconds(formvalues.fromDate), formvalues.toDate ? convertDateToMilliseconds(formvalues.toDate) : Date.now()),
                briefDescription: formvalues.briefDescriptions, ticketids: formvalues?.id ? [formvalues?.id] : []

            }).then((res) => {
                // update redux store
                dispatch(fetchConferencesHistory());
                setSelectedConference({ ...selectedConference, value: { ...res.data, startDate: convertDateToMilliseconds(res.data.startDate), endDate: (res?.data?.endDate) ? convertDateToMilliseconds(res.data.endDate) : "" }, __isNew__: false })
                setIsAddingCert(false)
                showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'ConferencesAdded') || {}).mvalue || "nf Conferences Added")
                setFormValues({ ...formvalues, briefDescriptions: '', ticketids: res?.data?.id })

            }).catch((err) => {
                console.error(err);
                showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong")
                setIsAddingCert(false)
            })
    }

    return (
        <div className='ms-3  '>

            {/* {!isAcquiredEdit && <div class="my-2 ">
                <label for="exampleFormControlInput1" class="form-label">Certification name <span className='text-danger' >*</span></label>
                <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary" id="" placeholder="" value={formvalues.certificationName} name='certificationName' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} />
            </div>} */}

            {/* <div className="mb-2 ">
                <label htmlFor="exampleFormControlInput1" className="form-label">Organization Name<span className='text-danger' >*</span></label>
                <input type="text" style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" value={formvalues.organization} name='organization' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} />
            </div> */}
            {/* <div class="my-2 ">
                <label for="exampleFormControlInput1" class="form-label">
                    {(
                        content[selectedLanguage].find(
                            (item) => item.elementLabel === "OrganizationName"
                        ) || {}
                    ).mvalue || "not found"}
                    <span className="text-danger"> *</span>
                </label>
                <CreateSelect
                    newField="orgIsnew"
                    placeholder="Enter your company name"
                    setFormValues={setFormValues}
                    formValues={formvalues}
                    setNewForm={setFormValues}
                    NewForm={formvalues}
                    setNewField="organization"
                    options={filterEmployment}
                    value={companySelectValue}
                    setValue={setCompanySelectValue}
                />
            </div> */}


            {/* <div class="my-2 ">
                <div className='d-flex '>
                    <label for="exampleFormControlInput1" class="form-label">Location</label>
                    <div className='align-content-center ' style={{ marginLeft: 'auto' }}><input type='checkbox' className='me-2' onClick={(e) => setOnlineLocation(e.target.checked)} />Online</div>
                </div>

                <MultiSelect setLocationData={setLocationForSkilling} viewLocation={locationForSkilling} onlineStatus={onlineLocation} isEdit={isAcquiredEdit} />
            </div> */}
            <div className='mb-2'>


                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'ConferenceName') || {}).mvalue || "nf Conference Name "} <span className='text-danger' >*</span></label>
                <CreatableSelect
                    options={conferenceOptions}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#f5f5f5',
                            primary: '#815F0B',
                            primary50: '#f5f5f5',
                            neutral0: "#f8f9fA"
                        },
                    })}
                    closeMenuOnSelect={true}
                    openMenuOnClick={false}
                    // value={selectedConference}
                    onChange={handleCertChange}
                    isClearable={true}
                    placeholder="Enter your Conference name"
                    components={{ DropdownIndicator: () => null }}
                />

            </div>


            {

                selectedConference?.__isNew__ &&

                <div className='' style={{ border: "solid 1px #D3D3D3", padding: "5px" }}>
                    <h5>{"Add Conferences"}</h5>
                    <div class="mb-2 ">
                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf InstitutionName"}<span className='text-danger' > *</span></label>
                        {/* <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.organization} /> */}
                        <CreateSelectInstitution
                            setInsSearch={setInsSearch}
                            insSearch={insSearch}
                            insConvertedToSelect={insConvertedToSelect}
                            formvalues={formvalues}
                            setFormValues={setFormValues}
                            showDropdown={false}

                        />
                        {eduApiLoader && <div style={{ transform: "translate(365px,-24px)", width: "50px" }}>
                            <  ThreeDots width={"30"} height={"10"} />
                        </div>}
                    </div>

                    <div className='d-lg-flex my-2 '>
                        <div className=" w-100  ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"}<span className='text-danger' >*</span></label>
                            {/* <input type="date"

                                max={timestampToYYYYMMDD(Date.now())}
                                style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.fromDate} /> */}
                            <DatePicker
                                style={{ height: "32px" }}
                                maxDate={timestampToYYYYMMDD(Date.now())}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                id="exampleFormControlInput1"
                                onChange={(e) => setFormValues({ ...formvalues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues?.fromDate ? formvalues?.fromDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                onBlur={() => { }}
                            />
                        </div>
                        <div className=" ms-lg-2 w-100 ">
                            <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End date"} </label>
                            {/* <input type="date"
                                min={formvalues.fromDate}
                                max={timestampToYYYYMMDD(Date.now())}
                                style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.toDate}
                                {...onGoing.certificationEndDate && { disabled: true }} /> */}
                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={formvalues?.fromDate}
                                maxDate={timestampToYYYYMMDD(Date.now())}
                                onChange={(e) => setFormValues({ ...formvalues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues?.toDate ? formvalues?.toDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                disabled={onGoing?.certificationEndDate}
                                onBlur={() => { }}
                            />
                            <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                <label htmlFor="exampleFormControlInput1" className="">Ongoing </label>
                                <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                    setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                    setFormValues({ ...formvalues, toDate: "" })
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="my-2">
                        <div className='d-flex justify-content-between'>
                            <label htmlFor="locationInput" className="form-label">
                                {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}<span className='text-danger'> *</span>
                            </label>
                            <div className='d-flex align-items-center justify-align-content'>
                                <input
                                    id="onlineCheckbox"
                                    className='ms-2'
                                    type="checkbox"
                                    name="online"
                                    checked={online}
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        setOnline(isChecked);
                                    }}
                                />
                                <label htmlFor="onlineCheckbox" className="ms-1"> {(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</label>
                            </div>
                        </div>
                        {/* <CreateSelectForLocation locationData={location}
                                                setLocation={setLocation}
                                                onlineStatus={toggleOnline} /> */}
                        <MultiSelect setLocationData={setLocation} viewLocation={location} onlineStatus={online} />
                    </div>




                    <div className="my-2 ">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                        <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.briefDescriptions} >  </textarea>
                    </div>

                    <div className="modal-footer d-flex justify-content-end  ">



                        <div className='d-flex gap-2'>

                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'AddConference') || {}).mvalue || " nf Add Conference"} onClick={() => handleAddConfHistory()} backgroundColor="#815F0B" color="#F8F8E9" loading={isAdddingCert} />
                        </div>
                    </div>
                </div>
            }
            {

                !selectedConference?.__isNew__ &&

                <>
                    <div class="mb-2 ">
                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf Institution Name"}<span className='text-danger' > *</span></label>
                        <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.organization} disabled />
                    </div>

                    <div className='d-lg-flex my-2 '>
                        <div className=" w-100  ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"} <span className='text-danger' >*</span></label>
                            {/* <input type="date"

                                min={timestampToYYYYMMDD(Number(selectedConference?.value?.startDate))}
                                max={timestampToYYYYMMDD(Number((selectedConference?.value?.endDate) ? selectedConference?.value?.endDate : Date.now()))}
                                style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.fromDate} /> */}
                            <DatePicker
                                style={{ height: "32px" }}
                                minDate={timestampToYYYYMMDD(Number(selectedConference?.value?.startDate))}
                                maxDate={timestampToYYYYMMDD(Number((selectedConference?.value?.endDate) ? selectedConference?.value?.endDate : Date.now()))}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                id="exampleFormControlInput1"
                                onChange={(e) => setFormValues({ ...formvalues, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues?.fromDate ? formvalues?.fromDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                onBlur={() => { }}
                            />
                        </div>
                        <div className=" ms-lg-2 w-100 ">
                            <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End date"} </label>
                            {/* <input type="date"

                                min={formvalues.fromDate}
                                max={timestampToYYYYMMDD(Number((selectedConference?.value?.endDate) ? selectedConference?.value?.endDate : Date.now()))}
                                style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.toDate}
                                {...onGoing.certificationEndDate && { disabled: true }} /> */}
                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={formvalues?.fromDate}
                                maxDate={timestampToYYYYMMDD(Number((selectedConference?.value?.endDate) ? selectedConference?.value?.endDate : Date.now()))}
                                onChange={(e) => setFormValues({ ...formvalues, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues?.toDate ? formvalues?.toDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                disabled={onGoing?.certificationEndDate}
                                onBlur={() => { }}
                            />
                            {!selectedConference?.value?.endDate &&
                                <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                    <label htmlFor="exampleFormControlInput1" className="">Ongoing </label>
                                    <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                        setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                        setFormValues({ ...formvalues, toDate: "" })
                                    }} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="my-2">

                        <div>
                            <label for="exampleFormControlInput1" class="form-label">
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Locations"
                                    ) || {}
                                ).mvalue || "not found"}
                                <span className="text-danger me-2">*</span>

                                <SecondaryBtn
                                    label={(content[selectedLanguage].find(item => item.elementLabel === 'Reset') || {}).mvalue || "nf Reset"}
                                    onClick={() => {

                                        setLocationForSkilling(selectedConference?.value?.location.split(","))
                                    }}
                                    backgroundColor="#F8F8E9"
                                    color="#815F0B"

                                />
                            </label>
                        </div>

                        <div className="d-flex gap-2">

                            {locationForSkilling?.map((loc) => {
                                return (
                                    <div className="p-1  rounded bg-light border" >
                                        {loc}
                                        <span className="text-muted px-1" style={{ cursor: 'pointer' }} onClick={() => {
                                            let newLocations = locationForSkilling?.filter((location) => {
                                                return location !== loc
                                            })
                                            setLocationForSkilling(newLocations)
                                        }}><RxCross2 /></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>



                    <div className="my-2 ">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"}<span className='text-danger' >*</span></label>
                        <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setFormValues({ ...formvalues, [e.target.name]: e.target.value })} value={formvalues.briefDescriptions} >  </textarea>
                    </div>

                    <div className="modal-footer d-flex justify-content-end  ">



                        <div className='d-flex gap-2'>
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "nf Save"} onClick={() => handleSubmit(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isLoading} />
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "nf Save & Close"} Active={true} onClick={() => handleSubmit(true)} backgroundColor="#815F0B" color="#F8F8E9" loading={isLoading} />
                        </div>
                    </div>
                </>
            }





        </div>
    )
}

export default ConferencesForm
