import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import EditAccountApi from '../../api/Accounts/EditAccountApi';
import { useDispatch } from 'react-redux';
import { showSuccessToast } from '../ToastNotification/showSuccessToast';
import { addNewRole, setUserRoles } from '../../reducer/roles/RoleMappingSlice';
import { logout } from '../../reducer/userDetails/UserProfileSlice';
import { logoutUser } from '../../Store';
import { BASE_URL } from '../../config/Properties';
import SecondaryBtnLoader from '../Buttons/SecondaryBtnLoader';

const ChangeRole = () => {

  const dispatch = useDispatch();
  // store imports

  const selectedLanguage = useSelector(state => state.language);
  const content = useSelector(state => state.content);
  const roles = useSelector(state => state.roles);

  const [rolesList, setRolesList] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");


  useEffect(() => {
    setRolesList(roles.userRoles);
    setRolesOptions(roles?.data?.filter(objB => !roles?.userRoles?.some(objA =>
      objA.roleName === objB.roleName)).filter(role =>
        role.active === "Yes" && role.mlanguage === selectedLanguage));
    console.log("user roles ef ");

  }, [useSelector(state => state.roles.userRoles)])

  useEffect(() => {
    setRolesList(roles.userRoles);
    setRolesOptions(roles?.data?.filter(objB => !roles?.userRoles?.some(objA =>
      objA.roleName === objB.roleName)).filter(role =>
        role.active === "Yes" && role.mlanguage === selectedLanguage));

    console.log("initial role ");

  }, [])


  useEffect(() => {

    console.log("roles options ", rolesOptions);
    setSelectedRole(rolesOptions[0]?.label);

  }, [rolesOptions])



  useEffect(() => {
    console.log("roles list ini ", rolesList);


  }, [rolesList])

  useEffect(() => {
    console.log("role selected ", selectedRole);


  }, [selectedRole])

  const handleChange = (e) => {

    setSelectedRole(e.target.value);
  }

  const roleFix = () => {


    try {
      let rolesArr = rolesList?.map(role => { return role.roleName && role.roleName });
      rolesArr.push("ROLE_USER", "ROLE_DEFAULT", "ROLE_MYST");
      const role = rolesOptions?.find(item => item?.label === selectedRole);
     
      rolesArr = rolesArr.filter(roles => roles !== null && roles.length > 0);
      console.log("role array ", roles);
      console.log("api role obj ", rolesArr);
      EditAccountApi({
        "accountId": userId,
        "roles": rolesArr
      })
        .then((res) => {
          // setIsLoading(false);
          // dispatch(setUserRoles(res.data.account.roles));
          showSuccessToast("Roles fixed");
          console.log(res.data);
          //handleLogout();
          window.location.reload();
        });
    } catch (error) {
      setIsLoading(false);
      console.log("roles error ", error);
    }

  }

  const handleSumbit = () => {
    setIsLoading(true);
    if (selectedRole !== null && selectedRole !== undefined) {
      try {
        let rolesArr = rolesList?.map(role => { return role.roleName && role.roleName });
        rolesArr.push("ROLE_USER", "ROLE_DEFAULT", "ROLE_MYST");
        const role = rolesOptions?.find(item => item?.label === selectedRole);
        if (role.roleName !== null) {
          rolesArr.push(role.roleName);
        }
        rolesArr = rolesArr.filter(roles => roles !== null && roles.length > 0);
        console.log("role array ", roles);
        console.log("api role obj ", rolesArr);
        EditAccountApi({
          "accountId": userId,
          "roles": rolesArr
        })
          .then((res) => {
            setIsLoading(false);
            dispatch(setUserRoles(res.data.account.roles));
            showSuccessToast("Role added");
            console.log(res.data);
            //handleLogout();
            window.location.reload();
          });
      } catch (error) {
        setIsLoading(false);
        console.log("roles error ", error);
      }
    }
    setIsLoading(false);
  }

  const handleLogout = () => {
    logoutUser();
    dispatch(logout());
    localStorage.clear();
    // window.location.href = BASE_URL;

  }




  return (
    <div class="container rounded ">
      <div class="row justify-content-center  mb-2 pb-2 rounded  ">
        <div class="col-12    bg-light  pt-2  rounded ">
          <div>
            <h6>{"Current Role(S) :"}</h6>
            <div className='ms-4 mt-2 mb-4 d-flex align-self-center border-2 '>
              {/* style={{backgroundColor: "white", border: "solid #DEE2E6", borderRadius: "5px", height: "30px" }}  */}
              {
                rolesList?.map((roles) =>

                  <div className='d-flex me-4'>
                    <input
                      class="form-check-input"
                      type="radio"
                      checked
                      readonly

                    />
                    <div className='ms-1 mb-2'>
                      {roles.roleId}
                    </div>
                  </div>)
              }


            </div>
          </div>

          <div>{(content[selectedLanguage].find(item => item.elementLabel === 'AddRole') || {}).mvalue || "nf Add Role"}</div>
          <div className='d-flex '>
            <select class="form-select font-5 mb-2 me-2" onChange={handleChange} value={selectedRole} aria-label="Default select example" style={{ height: "32px", width: '200px' }}>
              {/* <option className='bg-body-tertiary' value="0" disabled selected hidden>Role</option> */}

              {
                rolesOptions?.map((roles, index) =>
                  <option key={roles?.roleName} value={roles.label}  > {roles.label}</option>
                )
              }
            </select>
            <div style={{ height: "13px" }}>
              <SecondaryBtnLoader loading={isLoading} onClick={handleSumbit} label={(content[selectedLanguage].find(item => item.elementLabel === 'Add') || {}).mvalue || "nf Add"} backgroundColor="#F8F8E9"
                color="#815F0B" />
            </div>
            <div style={{ height: "13px" }}>
              <SecondaryBtnLoader loading={isLoading} onClick={roleFix} label={"role Fix"} backgroundColor="#F8F8E9"
                color="#815F0B" />
            </div>
          </div>
          {/* <button class="btn text-white mb-3 mt-2  " onClick={handleSumbit} style={{ backgroundColor: "#815F0B" }}></button> */}

        </div>
      </div>

    </div >
  )
}

export default ChangeRole