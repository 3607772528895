export const toTitleCase = (value) => {
  /* SPLIT THE EACH VALUE BY SPACE FOR PROCESSING */
  return value.split(' ').map(word => {
    /* CHECK FOR UPPERCASE */
    if (word === word.toUpperCase()) {
      /* IF YES KEEP AS IT IS */
      return word;
    } else {
      /* IF NOT TURN THEM INTO TITLECASE */
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    }
    /* JOIN THE WORDS AGAIN */
  }).join(' '); 
};
