import React from "react";

const CustomAnalyticsPS = () => {
  return (
    <>
      <div className="container text-center mt-5 secondary-brown-text">
        <h1 className="mb-2 ">Premium Services Coming Soon</h1>
        <p>We're working on something awesome! Stay tuned.</p>

        <h4 className="mt-4  mb-2 ">
          Custom Analytics wlll answer questions like...
        </h4>

        <div className="my-2 ">
          <div className="border p-3 mb-3 text-start  ">
            <p className="font-weight-bold">
              What happens if my validated skill sets are uploaded into MyST –
              how will I know who is viewing my profile or if I have been
              considered in some short-list?
            </p>
            <p className="font-weight-bold">
              Would I come to know what is trending in skill sets?
            </p>
            <p className="font-weight-bold">
              Do I get paid for identifying the ‘right’ candidate/s?
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomAnalyticsPS;
