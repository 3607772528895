import React from 'react'
import { useSelector } from 'react-redux';
import { DayDifferenceToDynamicView } from '../../HelperFunction/DayDifferenceToDynamicView';

const EducationSummaryResView = ({ data }) => {
    const selectedLanguage = useSelector(state => state.language);

    const content = useSelector(state => state.content);
    return (
        <React.Fragment>
            {data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                <React.Fragment>
                    <div className='font-3 font-weight-2'>{(content[selectedLanguage].find(item => item.elementLabel === 'EducationHistory') || {}).mvalue || "nf EducationHistory"}</div>
                    <div className="table-responsive ms-3 mt-2">
                        <table className='table table-sm   table-fixed table-hover    '>
                            <thead >
                                <tr className='border-dark-subtle border-bottom '>
                                    <th scope="col" className='bg-body- ' style={{ width: "21%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'CourseDegree') || {}).mvalue || "nf CourseDegree"}  </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "25%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'InstituteCollegeUniversity') || {}).mvalue || "nf InstituteCollegeUniversity"}  </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "13%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}     </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "19%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}     </th>
                                    <th scope="col" style={{ width: "12%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}   </th>

                                </tr>
                            </thead>

                            <tbody className=''>
                                {data?.map((education, index) =>
                                    education.recordHide === 'Yes' ?
                                        <></>
                                        :
                                        < tr className='' >
                                            <td >{education.course}</td>
                                            <td >{education.recordAnonymous === 'Yes' ? (
                                                content[selectedLanguage].find(
                                                    (item) =>
                                                        item.elementLabel ===
                                                        "Confidential"
                                                ) || {}
                                            ).mvalue || "nf CONFIDENTIAL" : education.institute}</td>

                                            <td>{DayDifferenceToDynamicView(
                                                education.duration
                                            )}</td>
                                            <td>{education.location}</td>
                                            <td className=' '>{education.validation === "Yes" ? "Yes" : "No"}</td>

                                        </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )

}



export default EducationSummaryResView