import React, { useCallback, useEffect, useRef, useState } from "react";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import { useSelector, useDispatch } from "react-redux";
import CreateSelect from "../../SelectComponent/CreateSelect";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { fetchDataSkillsAcquired } from "../../../../api/fetchAllData/fetchDataSkillsAcquired";
import { showSuccessToast } from "../../../ToastNotification/showSuccessToast";

const SkillAcquiredEdit = () => {
  /* DISPATCH */
  const dispatch = useDispatch();

  /* STORE IMPORTS */
  const {
    skillsAcquiredEdit: skillsAcquiredEditData,
    language: selectedLanguage,
    content,
    regionalData,
  } = useSelector((state) => state);

  useEffect(() => {}, [skillsAcquiredEditData]);

  /* FORMAT THE DATES */
  const formattedStartDate =
    skillsAcquiredEditData?.fromDate &&
    skillsAcquiredEditData?.fromDate.includes("/")
      ? timestampToYYYYMMDD(skillsAcquiredEditData?.fromDate)
      : formatTimestampToDate(
          Number(skillsAcquiredEditData?.fromDate),
          regionalData?.selectedCountry?.dateFormat
        );
  const formattedEndDate =
    skillsAcquiredEditData?.toDate &&
    skillsAcquiredEditData?.toDate.includes("/")
      ? timestampToYYYYMMDD(skillsAcquiredEditData?.toDate)
      : formatTimestampToDate(
          Number(skillsAcquiredEditData?.toDate),
          regionalData?.selectedCountry?.dateFormat
        );

  /* STATE INIT */
  const [desc, setDesc] = useState(
    skillsAcquiredEditData?.briefDescriptions || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  useEffect(() => {
    setDesc(skillsAcquiredEditData?.briefDescriptions);
  }, [skillsAcquiredEditData?.briefDescriptions]);

  /* HANDLE EDIT SUBMIT */
  const handleEditSkillsAcquired = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);

      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescriptions: desc,
        },
        formNames: [],
      };

      /* CHECK FOR EMPLOYMENT AND PROJECT HISTORY */
      if (skillsAcquiredEditData?.mtype === "Education") {
        payload.formNames.push("Education History");
      } else if (skillsAcquiredEditData?.mtype === "Certification") {
        payload.formNames.push("Certification History");
      } else if (skillsAcquiredEditData?.mtype === "Conferences") {
        payload.formNames.push("Conferences");
      } else if (skillsAcquiredEditData?.mtype === "Skilling") {
        payload.formNames.push("Skilling");
      } else if (skillsAcquiredEditData?.mtype === "Training") {
        payload.formNames.push("Training");
      }

      try {
        await EditSkillsApi(
          "Skills Acquired",
          skillsAcquiredEditData?.id,
          payload
        );
        setIsSendingData(false);
        dispatch(fetchDataSkillsAcquired());
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SkillsAcquiredUpdatedSuccessful"
            ) || {}
          ).mvalue || "nf Skills acquired edited successfully"
        );
        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      dispatch,
      skillsAcquiredEditData,
      handleModalClose,
      content,
      selectedLanguage,
    ]
  );
  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0"
        style={{ margin: "0" }}
        id="skillsAcquiredEditModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex justify-content-between align-items-center">
              <i className="me-2 mt-1">
                {" "}
                <span className="text-danger">*</span> Required Fields
              </i>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeBtnRef}
              ></button>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                <div className="pill-bg-color text-white p-1 px-2 rounded-pill">
                  {skillsAcquiredEditData?.title}
                </div>

                {/* SKILLS APPLIED EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-3">
                      <div>
                        <h6>Edit Acquiring Skills</h6>
                      </div>
                      <div style={{ pointerEvents: "none" }}>
                        <CreateSelect
                          newField="orgIsnew"
                          placeholder={skillsAcquiredEditData?.mtype}
                          setNewField="organization"
                          value={skillsAcquiredEditData?.mtype}
                        />
                      </div>
                      {/* ORGANIZATION */}
                      <div className="mt-2" style={{ pointerEvents: "none" }}>
                        Institution/Organizer::
                        <div className="mt-2">
                          <CreateSelect
                            newField="orgIsnew"
                            placeholder={skillsAcquiredEditData?.organization}
                            setNewField="organization"
                            value={skillsAcquiredEditData?.organization}
                          />
                        </div>
                      </div>
                      {/* DATES */}
                      <div className="d-lg-flex my-2 w-100">
                        <div className="h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={ timestampToYYYYMMDD(Number(skillsAcquiredEditData?.fromDate))}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            min={skillsAcquiredEditData?.toDate}
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={skillsAcquiredEditData?.toDate ? timestampToYYYYMMDD( Number(skillsAcquiredEditData?.toDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}
                      <div className="my-2" style={{ pointerEvents: "none" }}>
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Locations"
                            ) || {}
                          ).mvalue || "not found"}
                        </label>

                        <div>
                          {!skillsAcquiredEditData?.location && (
                                <p className="mb-0 text-muted">
                                  {" "}
                                  No Locations{" "}
                                </p>
                              )}
                          </div>

                        <div className="d-flex gap-2">
                        {
                        skillsAcquiredEditData?.location &&
                          <div className="p-1 text-secondary rounded bg-light border">
                            {skillsAcquiredEditData?.location}
                          </div>

                        }
                        </div>
                      </div>

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Brief Description about Organization
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescriptions"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditSkillsAcquired("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditSkillsAcquired("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkillAcquiredEdit;
