import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RiDragMoveFill } from 'react-icons/ri';
import { useSelector } from "react-redux";

export function SortableItem(props) {
    const SkillSelected = useSelector(state => state.SkillSelected)

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        minWidth: "100%",
      
    }


    return (
        <tr className={SkillSelected.id === props.id.id ? 'table-active' : '' }  ref={setNodeRef} style={style} {...attributes} {...listeners} key={props.id.skill} >
        
            { (Number(props?.i) < 5) ?
            
            <td className="pe-0 ps-3 " style={{width:'5px'}}>{props?.i+1}</td>

            :
            <td></td>
            
            }
        
            <td data-tooltip-id="my-tooltip" data-tooltip-content={props.id.skillOccupation} className="ps-0 " style={{overflow: 'hidden',textOverflow: "ellipsis", whiteSpace: "nowrap",maxWidth: "50px"}}>
                {props.id.skillOccupation}</td>
            {/* <td className="font-6" style={{ width: "35%" }}>
                {DayDifferenceToDynamicView(props.id.yoe)}
            </td> */}
            <td style={{ width: "1%" }}><RiDragMoveFill /></td>
        </tr>

    )
}