import { createSlice } from "@reduxjs/toolkit";
import { fetchLicensesHistory } from "../../api/fetchAllData/fetchLicensesHistory";

const initialState = {
  data: [
    {
      mtype: "Certification",
      fromDate: "",
      toDate: "",
      duration: "",
      briefDescriptions: "",
      location: "",
      showHide: "",
      validation: "",
      blockChain: "",
      mlanguage: "",
      certificationName: "",
      organization: "",
      userId: "",
      title: ""
    }
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const licensesSlice = createSlice({
  name: "licensesHistory",
  initialState,
  reducers: {
    setLicenses: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyLicenses: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewLicenses: (state, action) => {
      state.data = [action.payload, ...state.data];
      state.status = "success";
      state.error = null;
    },
    LicensesDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutLicenses: () => initialState,
    licenseUpdate: (state, action) => {
      const { id, validation } = action.payload;
      const skillToUpdate = state.data.find((skill) => skill.id === id);
      if (skillToUpdate) {
        skillToUpdate.validation = validation;
      }
    },
    licenseUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicensesHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLicensesHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchLicensesHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {licenseUpdateRecord, setLicenses, emptyLicenses,addNewLicenses,LicensesDelete,logoutLicenses, licenseUpdate } = licensesSlice.actions;
export default licensesSlice.reducer;
