import React, { useEffect, useState } from 'react'
import user from "../Images/avatar-placeholder.webp"
import logo from "../Images/logo.png"
import { useSelector } from 'react-redux';
import { GetUserForSelectedLanguage } from './SkillOwner/HelperFunction/GetUserForSelectedLanguage';
import { BASE_URL } from '../config/Properties';
import { DayDifferenceToDynamicView } from './SkillOwner/HelperFunction/DayDifferenceToDynamicView';
import { formatTimestampToDate } from './SkillOwner/HelperFunction/FormatTimestampToDate';
import { calculateDaysDifference } from './SkillOwner/HelperFunction/CalculateDaysDifference ';
import ValidationDataComponent from './SkillOwner/SelectComponent/ValidationDataComponent';
import { GetAttachment, splitStringToObject } from '../api/Attachment  API/DownloadAttachmentApi';
import { setemployment } from '../reducer/detailedProfile/employmentSlice';
import EmploymentSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/EmploymentSummaryResView';
import ProjectSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/ProjectSummaryResView';
import EducationSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/EducationSummaryResView';
import CertificationSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/CertificationSummaryResView';
import SkillingSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/SkillingSummaryResView';
import TrainingSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/TrainingSummaryResView';
import AwardSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/AwardSummaryResView';
import ConferenceSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/ConferenceSummaryResView';
import LicensesSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/LicensesSummaryResView';
import MembershipSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/MembershipSummaryResView';
import LanguageSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/LanguageSummaryResView';
import OthersSummaryResView from './SkillOwner/DetailedProfile/For Resume and Seeker/OthersSummaryResView';



const DetailedResume = () => {

    // set date alone
    const [timestamp, setTimestamp] = useState(new Date().toLocaleDateString());
    const [profilePicObj, setProfilePicObj] = useState({});
    const token = localStorage.getItem("token");
    // const [timestamp, setTimestamp] = useState(new Date().toLocaleDateString(`${localStorage.getItem("countryCode")}`));
    const selectedLanguage = useSelector(state => state.language);
    const userDetails = useSelector(state => GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage));
    const regionalData = useSelector(state => state.regionalData);
    const content = useSelector(state => state.content);

    const employmentHistory = useSelector(state => state.employmentHistory);

    const projectHistory = useSelector(state => state.projectHistory);

    const educationHistory = useSelector(state => state.educationHistory);

    const certificationHistory = useSelector(state => state.certificationHistory);

    const trainingHistory = useSelector(state => state.trainingHistory);

    const conferenceHistory = useSelector(state => state.conferenceHistory);

    const skillingsHistory = useSelector(state => state.skillingsHistory);

    const licensesHistory = useSelector(state => state.licensesHistory);

    const membershipHistory = useSelector(state => state.membershipHistory);

    const languageKnown = useSelector(state => state.languageKnown);

    const achievementHistory = useSelector(state => state.achievementHistory);

    const otherHistory = useSelector(state => state.otherHistory);

    useEffect(() => {

        if (userDetails?.profilePictureFileName?.length > 1) {
            setProfilePicObj(splitStringToObject(userDetails?.profilePictureFileName));
        }


    }, [userDetails]);


    return (
        <div className=" mx-auto  rounded bg-white   font-5  "  >


            <React.Fragment>
                <headers
                    className="d-flex justify-content-between align-items-center resume-header_footer"
                    id="resume_header"
                    style={{
                        flex: "3",


                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        padding: "1rem",
                    }}
                >
                    {
                        userDetails?.ppShowHide !== "Yes" && userDetails?.profilePictureFileName &&
                        userDetails?.profilePictureFileName?.length > 1 &&
                        <div
                            className="d-flex"
                            style={{ flex: "1", order: "2" }}
                        >
                            <div style={{ margin: "1vw" }}>

                                <img class="rounded-circle"
                                    src={
                                        GetAttachment(
                                            userDetails?.id,
                                            profilePicObj?.fileName,
                                            profilePicObj?.fileId
                                        )

                                    }
                                    onError={(e) => (e.target.src = user)}

                                    width="108"
                                    height="108"
                                    className=" "
                                />
                            </div>
                        </div>
                    }
                    <div className='' style={{ flex: "3", order: "2", display: "flex", alignItems: "center" }}>

                        <div class="">

                            <div class="mb-1 mt-2 font-3 font-weight-2">
                                {(userDetails.fn === 'Yes'
                                    ||
                                    userDetails.mlnShowHide === 'Yes') ?
                                    (
                                        content[selectedLanguage].find(
                                            (item) =>
                                                item.elementLabel ===
                                                "Confidential"
                                        ) || {}
                                    ).mvalue || "nf CONFIDENTIAL"


                                    : userDetails.firstName + " " + userDetails.lastName}
                            </div>

                            <p class=" mb-2">{userDetails.memailShowHide !== 'Yes' && userDetails.email != undefined && userDetails.email + ", "}
                                {userDetails.mnShowHide !== 'Yes' && userDetails.mobileNumber != undefined && userDetails.mobileNumber}
                            </p>
                            <div class="">
                                <p class=" ">
                                    {userDetails.about}
                                </p>
                            </div>

                        </div>


                    </div>
                    <div className="logo-right-watermark d-flex justify-content-end" style={{ flex: "1", order: "3" }}>
                        <img
                            src={logo}
                            alt={
                                (
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "MySkillsTreeProfile"
                                    ) || {}
                                ).mvalue || "nf MySkillsTreeProfile"
                            }
                            width="100"
                            height="100"
                            style={{ pointerEvents: "none" }}
                        />
                    </div>
                </headers>

            </React.Fragment>
            <div
                className="d-flex justify-content-center align-items-end  "
                style={{
                    fontSize: `22px`,
                    color: "#000",
                    fontWeight: "bold",
                }}
            >
                {(
                    content[selectedLanguage].find(
                        (item) => item.elementLabel === "MySkillsTreeProfile"
                    ) || {}
                ).mvalue || "nf MySkillsTreeProfile"}
            </div>

            <table className='paging ' style={{ width: "100%" }} >
                <tbody>
                    <tr>
                        <td>



                            <div className='border-bottom border-2 border-dark mt-1  '></div>

                            <div
                                className="d-flex justify-content-center align-items-center mt-3"
                                style={{
                                    fontSize: `22px`,

                                    fontWeight: "bold",
                                }}
                            >
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "MySkillsSummary"
                                    ) || {}
                                ).mvalue || "nf MySkills Summary"}
                            </div>
                            <div className='mx-5 '>

                                {/* Employment history */}
                                <EmploymentSummaryResView data={employmentHistory?.data} />

                                {/* project summary */}
                                <ProjectSummaryResView data={projectHistory?.data} />
                                {/* Education history */}

                                <EducationSummaryResView data={educationHistory?.data} />

                                {/* certificaiton summary */}
                                <CertificationSummaryResView data={certificationHistory?.data} />

                                {/* Skillings */}
                                <SkillingSummaryResView data={skillingsHistory?.data} />

                                {/* Training */}
                                <TrainingSummaryResView data={trainingHistory?.data} />

                                {/* Award/achievement */}
                                <AwardSummaryResView data={achievementHistory?.data} />
                                {/* Conference */}

                                <ConferenceSummaryResView data={conferenceHistory?.data} />

                                {/* licenses history */}
                                <LicensesSummaryResView data={licensesHistory?.data} />
                                {/*Membership  */}
                                <MembershipSummaryResView data={membershipHistory?.data} />

                                {/* languages */}
                                <LanguageSummaryResView data={languageKnown?.data} />


                                {/* Others */}
                                <OthersSummaryResView data={otherHistory?.data} />

                            </div>
                            {/* DETAILED PROFILE */}
                            <div className='page-break-before '>


                                <div
                                    className="d-flex justify-content-center align-items-center  mb-4"
                                    style={{
                                        fontSize: `22px`,
                                        color: "#000",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {(
                                        content[selectedLanguage].find(
                                            (item) => item.elementLabel === "MySkillsDetails"
                                        ) || {}
                                    ).mvalue || "nf MySkills Details"}
                                </div>
                                <div className="">

                                    {/* Employment detailed */}
                                    {
                                        employmentHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center align-items-center mb-3"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'EmploymentHistory') || {}).mvalue || "nf EmploymentHistory"}
                                            </div>

                                            <div className="table-responsive mx-3 mt-2" >
                                                <table className='table table-sm  table-borderless     table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className="bg-body- " style={{ width: "34%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Organization"
                                                                ) || {}
                                                            ).mvalue || "nf Organization"}
                                                        </th>
                                                        <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Duration"
                                                                ) || {}
                                                            ).mvalue || "nf Duration"}{" "}
                                                        </th>
                                                        <th scope="col" className="bg-body- " style={{ width: "17%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Type"
                                                                ) || {}
                                                            ).mvalue || "nf Type"}{" "}
                                                        </th>
                                                        <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Location"
                                                                ) || {}
                                                            ).mvalue || "nf Location"}{" "}
                                                        </th>
                                                        <th scope="col" style={{ width: "12%" }}>
                                                            {(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}
                                                        </th>


                                                    </tr>


                                                </table>
                                                {/* row 1 */}
                                                {employmentHistory?.data?.map((skill, index) => (
                                                    skill.recordHide === 'Yes' ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                <div className='border-bottom    ' key={index}>
                                                                    <div className='d-flex   '>
                                                                        <div className='  ' style={{ width: "38%" }}>{skill.recordAnonymous === 'Yes' ? (
                                                                            content[selectedLanguage].find(
                                                                                (item) =>
                                                                                    item.elementLabel ===
                                                                                    "Confidential"
                                                                            ) || {}
                                                                        ).mvalue || "nf CONFIDENTIAL" : skill.organization}</div>
                                                                        <div style={{ width: "13%" }}>{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</div>
                                                                        <div className='    ' style={{ width: "20%" }}>{skill.mtype}</div>
                                                                        <div className='    ' style={{ width: "17%" }}>{skill.location}</div>
                                                                        <div className='ps-2 ' style={{ width: "12%" }}>{skill.validation ? "Yes" : "No"}</div>

                                                                    </div>
                                                                    <div className='my-2 ms-3  '>
                                                                        <div className='d-flex align-items-center flex-row   '>

                                                                            <div className='d-flex  ps-lg-0  mt-1 mt-lg-0  ' style={{ width: "20%" }}>
                                                                                <b>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"}&nbsp;:&nbsp;</b>
                                                                                <div>{formatTimestampToDate((skill.fromDate), regionalData.selectedCountry.dateFormat)}</div>
                                                                            </div>
                                                                            <div className='d-flex  ps-lg-0    ' style={{ width: "22%" }}>
                                                                                <b>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"}&nbsp;:&nbsp;</b>
                                                                                <div>{skill.toDate ? formatTimestampToDate((skill.toDate), regionalData.selectedCountry.dateFormat) : "On-going"}</div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='pb-1 pt-2   '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "nf BriefDescription"}:</b> </div>
                                                                        <div className='mx-3 w-90  '> {skill.briefDescriptions}</div>

                                                                        {
                                                                            skill.validation === "Yes" &&
                                                                            <ValidationDataComponent itemId={skill.id} res={true} />
                                                                        }



                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                ))}



                                            </div>
                                        </React.Fragment>
                                    }
                                    {/* project detailed */}
                                    {
                                        projectHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className={`${employmentHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 && 'page-break-before'}  `} >
                                            <div className="d-flex justify-content-center align-items-center mb-4 mt-3"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectHistory') || {}).mvalue || "nf ProjectHistory"}
                                            </div>

                                            <div className="table-responsive mx-3  mt-2" >
                                                <table className='table table-sm      table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "22%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf ProjectName"}
                                                        </th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Organization') || {}).mvalue || "nf Organization"}</th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}</th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                                        <th scope="col" className='         ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>

                                                    </tr>


                                                </table>
                                            </div>


                                            {projectHistory?.data.map((proj, index) => (

                                                <>
                                                    {proj.mlanguage === selectedLanguage &&
                                                        proj.recordHide === 'Yes' ?
                                                        <></>
                                                        :
                                                        <>
                                                            <div className='border-bottom pt-2 '>

                                                                <div className='d-flex  mx-3  ' key={index}>

                                                                    <div className='  ' style={{ width: "25%" }}>{proj.projectActivity}</div>
                                                                    <div className='  text-center  ' style={{ width: "23%" }}>{proj.recordAnonymous === 'Yes' ? (
                                                                        content[selectedLanguage].find(
                                                                            (item) =>
                                                                                item.elementLabel ===
                                                                                "Confidential"
                                                                        ) || {}
                                                                    ).mvalue || "nf CONFIDENTIAL" : proj.organization}</div>
                                                                    <div className='  text-center ' style={{ width: "16%" }}> {DayDifferenceToDynamicView(
                                                                        proj.duration
                                                                    )}</div>
                                                                    <div className='  text-center ' data-tooltip-id="my-tooltip" data-tooltip-content={proj.location}
                                                                        style={{ width: "22%" }}>{proj.location}</div>
                                                                    <div className='' style={{ width: "12%" }}>{proj.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                                </div>

                                                                <div className='my-2 ms-4'>
                                                                    <table className='d-flex gap-4  '>
                                                                        <tr>
                                                                            <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"} &nbsp;</b> </td>
                                                                            <td>: {formatTimestampToDate(Number(proj.fromDate), regionalData.selectedCountry.dateFormat)}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"} &nbsp;</b> </td>
                                                                            <td>:  {proj.toDate ? formatTimestampToDate(Number(proj.toDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                        </tr>
                                                                    </table>
                                                                    <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf BriefDescription"}:</b> </div>
                                                                    <div className='mx-3 w-90    '>
                                                                        {proj.briefDescriptions}
                                                                    </div>
                                                                    {
                                                                        proj.validation === "Yes" &&
                                                                        <ValidationDataComponent itemId={proj.id} res={true} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>}
                                                </>

                                            ))}

                                        </div>
                                    }
                                    {/* education detailed */}

                                    {
                                        educationHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center mb-4"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'Education') || {}).mvalue || "nf Education"}
                                            </div>

                                            <>

                                                {/* table start */}
                                                <div className="table-responsive ms-3 " >
                                                    <table className='table table-sm      table-fixed     '>
                                                        <tr className='border-dark-subtle border-bottom  '>
                                                            <th scope="col" className='bg-body- ' style={{ width: "22%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'CourseDegree') || {}).mvalue || "nf CourseDegree"}</th>
                                                            <th scope="col" className='bg-body- text-center' style={{ width: "20%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'InstituteCollegeUniversity') || {}).mvalue || "nf InstituteCollegeUniversity"}</th>
                                                            <th scope="col" className='bg-body- text-center' style={{ width: "13%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"} </th>
                                                            <th scope="col" className='bg-body- text-center' style={{ width: "19%" }}> {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}  </th>
                                                            <th scope="col" className='         ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"} </th>

                                                        </tr>


                                                    </table>
                                                </div>

                                                {educationHistory.data.map((education, index) => (
                                                    <>
                                                        {
                                                            education.mlanguage === selectedLanguage &&
                                                                education.recordHide === 'Yes' ?
                                                                <></>
                                                                :

                                                                <div className='border-bottom ms-4 pt-2'>

                                                                    <div className='d-flex   '>
                                                                        <div className=' ' style={{ width: "25%" }}>{education.course}</div>
                                                                        <div style={{ width: "23%" }}>{education.recordAnonymous === 'Yes' ? (
                                                                            content[selectedLanguage].find(
                                                                                (item) =>
                                                                                    item.elementLabel ===
                                                                                    "Confidential"
                                                                            ) || {}
                                                                        ).mvalue || "nf CONFIDENTIAL" : education.institute}</div>

                                                                        <div className='text-center  ' style={{ width: "16%" }}> {DayDifferenceToDynamicView(
                                                                            education.duration
                                                                        )}</div>
                                                                        {/* <div style={{ width: "13%" }}>2 wks</div> */}
                                                                        <div className='text-center ' style={{ width: "24%" }}>{education.location}</div>
                                                                        <div className='' style={{ width: "16%" }}>{education.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                                    </div>

                                                                    <div className='ms-4  my-3  '>
                                                                        <table className='d-flex gap-4  '>
                                                                            <tr>
                                                                                <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                                <td>: {formatTimestampToDate(Number(education.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                                {/* <td>: {"03/01/2024"}</td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                                <td>: {education.endDate ? formatTimestampToDate(Number(education.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                                {/* <td>: {"03/22/2024"}</td> */}
                                                                            </tr>
                                                                        </table>
                                                                        <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                                        <div className='ms-4  '>
                                                                            {education.briefDescriptions}
                                                                        </div>

                                                                        {
                                                                            education.validation === "Yes" &&
                                                                            <ValidationDataComponent itemId={education.id} />
                                                                        }
                                                                    </div>
                                                                </div>}
                                                    </>

                                                )
                                                )

                                                }
                                            </>
                                        </div>
                                    }
                                    {/* certifications */}
                                    {
                                        certificationHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center mb-4 "
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >{(content[selectedLanguage].find(item => item.elementLabel === 'Certifications') || {}).mvalue || "nf Certifications"}
                                            </div>
                                            <div className="table-responsive ms-3" >
                                                <table className='table table-sm      table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "22%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Certificates') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Organization') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='         ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "not found"}  </th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {certificationHistory.data.map((certs, index) => (
                                                certs.mlanguage === selectedLanguage &&
                                                    certs.recordHide === 'Yes' ?
                                                    <></>
                                                    :
                                                    <div className='border-bottom ms-3 pt-2'>

                                                        <div className='d-flex   '>
                                                            <div className=''></div>
                                                            <div style={{ width: "25%" }}>{certs.certificationName}</div>
                                                            <div className='text-center' style={{ width: "23%" }}>{certs.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : certs.organization}</div>
                                                            <div className='text-center' style={{ width: "16%" }}>{DayDifferenceToDynamicView(
                                                                certs.duration
                                                            )}</div>
                                                            <div className='text-center' style={{ width: "22%" }}>{certs.location}</div>
                                                            <div className='' style={{ width: "16%" }}>{certs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-4  my-3  '>
                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(certs.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {certs.endDate ? formatTimestampToDate(Number(certs.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                </tr>
                                                            </table>
                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {certs.briefDescription}
                                                            </div>

                                                            {
                                                                certs.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={certs.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>
                                            ))}

                                        </div>
                                    }
                                    {/* trainings */}
                                    {
                                        trainingHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center mb-4 "
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'TrainingHistory') || {}).mvalue || "not found"}
                                            </div>


                                            <div className="table-responsive ms-3 " >
                                                <table className='table table-sm    table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "32%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Training') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- ' style={{ width: "16%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- ' style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- ' style={{ width: "14%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"}  </th>
                                                        <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"}</th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {trainingHistory.data.map((trainings, index) => (
                                                trainings.mlanguage === selectedLanguage &&
                                                    trainings.recordHide === 'Yes' ?
                                                    <></>
                                                    :
                                                    <div className='border-bottom  pt-2'>

                                                        <div className='d-flex   ms-3  '>

                                                            <div className='  ' style={{ width: "31%" }}>{trainings.title}</div>
                                                            <div className=' text-center' style={{ width: "18%" }}>{trainings.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : trainings.organization}</div>
                                                            <div className=' text-center  ' style={{ width: "15%" }}>{DayDifferenceToDynamicView(
                                                                trainings.duration
                                                            )}</div>
                                                            <div className=' text-center  ' style={{ width: "19%" }}>{trainings.location}</div>
                                                            <div className=' text-center  ' style={{ width: "12%" }}>{trainings.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-5  my-3  '>
                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(trainings.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {trainings.endDate ? formatTimestampToDate(Number(trainings.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                </tr>
                                                            </table>
                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {trainings.briefDescription}
                                                            </div>

                                                            {
                                                                trainings.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={trainings.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>))}


                                        </div>
                                    }
                                    {/* Conference */}
                                    {
                                        conferenceHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center mb-4 "
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'ConferenceHistory') || {}).mvalue || "nf ConferenceHistory"}
                                            </div>

                                            {/* table start */}
                                            <div className="table-responsive ms-3" >
                                                <table className='table table-sm     table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom  '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ConferenceName') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center  ' style={{ width: "11%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"} </th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {conferenceHistory.data.map((confs, index) => (
                                                confs.mlanguage === selectedLanguage &&
                                                    confs.recordHide === 'Yes' ?
                                                    <></>
                                                    :

                                                    <div className='border-bottom ms-3 pt-2'>

                                                        <div className='d-flex   '>
                                                            <div className=''></div>
                                                            <div className='  ' style={{ width: "26%" }}>{confs.title}</div>
                                                            <div className='  text-center   ' style={{ width: "29%" }}>{confs.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : confs.organization}</div>
                                                            <div className='  text-center   ' style={{ width: "13%" }}>{DayDifferenceToDynamicView(
                                                                confs.duration
                                                            )}</div>
                                                            <div className='  text-center   ' style={{ width: "20%" }}>{confs.location}</div>
                                                            <div className='text-center' style={{ width: "12%" }}>{confs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-4  my-3  '>
                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(confs.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {confs.endDate ? formatTimestampToDate(Number(confs.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                </tr>
                                                            </table>
                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {confs.briefDescription}
                                                            </div>

                                                            {
                                                                confs.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={confs.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>))}



                                        </div>
                                    }
                                    {/* Skillings detail */}
                                    {
                                        skillingsHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center  mb-4"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'SkillingHistory') || {}).mvalue || "nf SkillingHistory"}
                                            </div>

                                            {/* table start */}



                                            {/* table start */}
                                            <div className="table-responsive ms-3" >
                                                <table className='table table-sm      table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom  '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Skilling') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "11%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "not found"}    </th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"}  </th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {skillingsHistory?.data?.map((certs, index) => (
                                                certs.mlanguage === selectedLanguage &&
                                                    certs.recordHide === 'Yes' ?
                                                    <></>
                                                    :

                                                    <div className='border-bottom mx-3 pt-2'>

                                                        <div className='d-flex   '>
                                                            <div className=''></div>
                                                            <div className='  ' style={{ width: "26%" }}>{certs.title}</div>
                                                            <div className=' text-center   ' style={{ width: "29%" }}>{certs.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : certs.organization}</div>
                                                            <div className=' text-center   ' style={{ width: "11%" }}>{DayDifferenceToDynamicView(
                                                                certs.duration
                                                            )}</div>
                                                            <div className=' text-center   ' style={{ width: "21%" }}>{certs.location}</div>
                                                            <div className=' text-center ' style={{ width: "12%" }}>{certs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-4  my-3  '>
                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(certs.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {certs.endDate ? formatTimestampToDate(Number(certs.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                </tr>
                                                            </table>
                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {certs.briefDescription}
                                                            </div>

                                                            {
                                                                certs.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={certs.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>))}





                                        </div>
                                    }
                                    {/* License detail */}
                                    {
                                        licensesHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center  mb-4"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'Licenses') || {}).mvalue || "nf Licenses"}
                                            </div>

                                            {/* table start */}
                                            <div className="table-responsive mx-3" >
                                                <table className='table table-sm  table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Award/Achievement') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center ' style={{ width: "11%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Date') || {}).mvalue || "not found"}   </th>
                                                        <th scope="col" className='bg-body-  text-center' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"}    </th>
                                                        <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"}  </th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {licensesHistory?.data?.map((certs, index) => (
                                                certs.mlanguage === selectedLanguage &&
                                                    certs.recordHide === 'Yes' ?
                                                    <></>
                                                    :

                                                    <div className='border-bottom  mx-3 pt-2'>

                                                        <div className='d-flex   '>
                                                            <div className=''></div>
                                                            <div className='  ' style={{ width: "27%" }}>{certs.licenseName}</div>
                                                            <div className=' text-center ' style={{ width: "29%" }}>{certs.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : certs.organization}</div>
                                                            <div className=' text-center ' style={{ width: "11%" }}>{DayDifferenceToDynamicView(calculateDaysDifference(certs.validFrom, certs.validTill))}</div>
                                                            <div className=' text-center ' style={{ width: "22%" }}>{certs.location}</div>
                                                            <div className='  ' style={{ width: "12%" }}>{certs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-4  my-3  '>

                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(certs.validFrom), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(certs.validTill), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                            </table>

                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {certs.briefDescription}
                                                            </div>


                                                            {
                                                                certs.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={certs.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>))}

                                        </div>
                                    }
                                    {/* membership detail */}
                                    {
                                        membershipHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center mb-4"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'Memberships') || {}).mvalue || "nf MemberShip"}
                                            </div>



                                            {/* table start */}

                                            <div className="table-responsive ms-3  " >
                                                <table className='table table-sm      table-fixed     '>
                                                    <tr className='border-dark-subtle border-bottom '>
                                                        <th scope="col" className='bg-body- ' style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Memberships') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"}</th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "11%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='bg-body- text-center' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"} </th>
                                                        <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"} </th>

                                                    </tr>


                                                </table>
                                            </div>

                                            {membershipHistory?.data?.map((certs, index) => (
                                                certs.mlanguage === selectedLanguage &&
                                                    certs.recordHide === 'Yes' ?
                                                    <></>
                                                    :

                                                    <div className='border-bottom  pt-2 ms-3 '>

                                                        <div className='d-flex   '>
                                                            <div className=''></div>
                                                            <div className='  ' style={{ width: "26%" }}>{certs.title}</div>
                                                            <div className=' text-center ' style={{ width: "29%" }}>{certs.recordAnonymous === 'Yes' ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel ===
                                                                        "Confidential"
                                                                ) || {}
                                                            ).mvalue || "nf CONFIDENTIAL" : certs.organization}</div>
                                                            <div className=' text-center ' style={{ width: "11%" }}>{DayDifferenceToDynamicView(
                                                                certs.duration
                                                            )}</div>
                                                            {/* <div style={{ width: "13%" }}>-- --</div> */}
                                                            <div className=' text-center ' style={{ width: "22%" }}>{certs.location}</div>
                                                            <div className='  ' style={{ width: "12%" }}>{certs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                        </div>

                                                        <div className='ms-4  my-3  '>
                                                            <table className='d-flex gap-4  '>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>: {formatTimestampToDate(Number(certs.startDate), regionalData.selectedCountry.dateFormat)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"} &nbsp;</b> </td>
                                                                    <td>:{certs.endDate ? formatTimestampToDate(Number(certs.endDate), regionalData.selectedCountry.dateFormat) : 'On-going'}</td>
                                                                </tr>
                                                            </table>
                                                            <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                            <div className='ms-4  '>
                                                                {certs.briefDescriptions}
                                                            </div>

                                                            {
                                                                certs.validation === "Yes" &&
                                                                <ValidationDataComponent itemId={certs.id} res={true} />
                                                            }
                                                        </div>
                                                    </div>))}


                                        </div>
                                    }
                                    {/* Awards achievements detailed */}
                                    {
                                        achievementHistory?.data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                                        <div className='page-break-before' >
                                            <div className="d-flex justify-content-center align-items-center  mb-4"
                                                style={{ fontSize: `20px`, color: "#000", fontWeight: "bold" }}
                                            >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'AwardsAccoladesAchievements') || {}).mvalue || "nf AwardsAccoladesAchivements"}
                                            </div>
                                            <>


                                                {/* table start */}
                                                <div className="table-responsive  ms-3 " >
                                                    <table className='table table-sm  table-fixed     '>
                                                        <tr className='border-dark-subtle border-bottom '>
                                                            <th scope="col" className='bg-body-  ' style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Award/Achievement') || {}).mvalue || "not found"} </th>
                                                            <th scope="col" className='bg-body- text-center ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found"} </th>
                                                            <th scope="col" className='bg-body- text-center ' style={{ width: "11%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Date') || {}).mvalue || "not found"}   </th>
                                                            <th scope="col" className='bg-body- text-center ' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "not found"}    </th>
                                                            <th scope="col" className='         ' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "not found"}  </th>

                                                        </tr>


                                                    </table>
                                                </div>

                                                {achievementHistory?.data?.map((certs, index) => (
                                                    certs.mlanguage === selectedLanguage &&
                                                        certs.recordHide === 'Yes' ?
                                                        <></>
                                                        :

                                                        <div className='border-bottom  ms-3  pt-2'>

                                                            <div className='d-flex   '>
                                                                <div className=''></div>
                                                                <div className=' ' style={{ width: "26%" }}>{certs.title}</div>
                                                                <div className=' text-center ' style={{ width: "29%" }}>{certs.recordAnonymous === 'Yes' ? (
                                                                    content[selectedLanguage].find(
                                                                        (item) =>
                                                                            item.elementLabel ===
                                                                            "Confidential"
                                                                    ) || {}
                                                                ).mvalue || "nf CONFIDENTIAL" : certs.organization}</div>
                                                                <div className=' text-center ' style={{ width: "11%" }}>{formatTimestampToDate(Number(certs.date), regionalData.selectedCountry.dateFormat)}</div>
                                                                <div className=' text-center ' style={{ width: "22%" }}>{certs.location}</div>
                                                                <div style={{ width: "12%" }}>{certs.validation === 'Yes' ? 'Yes' : 'No'}</div>

                                                            </div>

                                                            <div className='ms-4  my-3  '>

                                                                <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"} :</b> </div>
                                                                <div className='ms-4  '>
                                                                    {certs.briefDescription}
                                                                </div>

                                                                {
                                                                    certs.validation === "Yes" &&
                                                                    <ValidationDataComponent itemId={certs.id} res={true} />
                                                                }
                                                            </div>
                                                        </div>))}
                                            </>
                                        </div>
                                    }




                                </div>

                            </div>

                        </td></tr></tbody><tfoot><tr><td>&nbsp;</td></tr></tfoot></table>





        </div>
    )
}

export default DetailedResume