import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const getAccountDetails = async (accountId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/skill/api/v1/skills/AcccountDetails/${accountId}`
    );
    return res;
  } catch (error) {
    console.error("ERROR GETTING ACCOUNT DETAILS: ", error);
  }
};
