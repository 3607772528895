import React from 'react'
import { useSelector } from 'react-redux';
import { DayDifferenceToDynamicView } from '../../HelperFunction/DayDifferenceToDynamicView';

const OthersSummaryResView = ({ data }) => {
    const selectedLanguage = useSelector(state => state.language);

    const content = useSelector(state => state.content);
    return (
        <React.Fragment>
            {
                data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                <React.Fragment>
                    <div className='font-3 font-weight-2'> {(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || "nf Others"}</div>


                    <div className="table-responsive ms-3 mt-2">
                        <table className="table table-sm   table-fixed table-hover    ">
                            <thead>
                                <tr className="border-dark-subtle border-bottom ">
                                    <th scope="col" className="bg-body- " style={{ width: "22%" }}>
                                        {(
                                            content[selectedLanguage].find(
                                                (item) => item.elementLabel === "Others"
                                            ) || {}
                                        ).mvalue || "nf Others"}
                                    </th>
                                    <th scope="col" className="bg-body- " style={{ width: "78%" }}>
                                        {(
                                            content[selectedLanguage].find(
                                                (item) => item.elementLabel === "BriefDescription"
                                            ) || {}
                                        ).mvalue || "nf BriefDescription"}
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="">
                                {data?.map((others, index) => (
                                    others.recordHide === 'Yes' ?
                                        <></>
                                        :
                                        <tr className="">
                                            <td
                                                style={{

                                                }}
                                            >
                                                {others.title}
                                            </td>
                                            <td>{others?.mothers}</td>



                                        </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default OthersSummaryResView