import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CreatableSelect from "react-select/creatable";
import { fetchSkillsData } from "../../../api/searchSuggestionAPIs/fetchSkillsData";
import { ThreeDots } from "react-loader-spinner";
import { toTitleCase } from "../HelperFunction/toTitleCase";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const CreateSelectOccupation = ({ occupationData, setIsNewOccupation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isApiLoading, setIsApiLoading] = useState(false);

  /* STORE */
  const { language: selectedLanguage } = useSelector((state) => state);

  useEffect(() => {
    const fetchData = async () => {
      searchTerm ? setIsApiLoading(true) : setIsApiLoading(false);
      try {
        const res = await fetchSkillsData(searchTerm, selectedLanguage);
        // const occupation = res?.data.map((val) => ({
        //   value: val?.occupation,
        //   label: val?.occupation,
        // }));
        // setOptions(occupation);
        /* THIS CODE IS TO AVOID DUPLICATE OCCUPATION */
        const uniqueOccupations = new Set();
        const occupation = res?.data.reduce((acc, val) => {
          if (!uniqueOccupations.has(val?.occupation)) {
            uniqueOccupations.add(val?.occupation);
            acc.push({
              value: val?.occupation,
              label: val?.occupation,
            });
          }
          return acc;
        }, []);
        setOptions(occupation);
      } catch (error) {
        console.error("Error fetching skills data:", error);
        setIsApiLoading(false);
      } finally {
        setIsLoading(false);
        setIsApiLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [selectedLanguage, searchTerm]);

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOptionLabel = toTitleCase(inputValue);
      const newOption = createOption(newOptionLabel);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      occupationData(newOption);
      /* CHANGE STATE FOR NEW OCCUPATION */
      setIsNewOccupation(true);
    }, 1000);
  };

  /* HANDLE DATA CHANGE */
  const handleChange = (value) => {
    setValue(value);
    occupationData(value);
    /* SET NEW OCCUPATION STATE TO FALSE IF THE DATA SELECTION FROM DB */
    setIsNewOccupation(false);
  };

  return (
    <React.Fragment>
      <CreatableSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        onInputChange={(inputVal) => setSearchTerm(inputVal)}
      />
      {(isLoading || isApiLoading) && (
        <div
          style={{
            transform: "translate(365px,-24px)",
            width: "50px",
          }}
        >
          <ThreeDots width={"30"} height={"10"} />
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateSelectOccupation;
