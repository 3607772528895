import React, { useCallback, useEffect, useRef, useState } from "react";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import CreateSelect from "../../SelectComponent/CreateSelect";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { useSelector } from "react-redux";
import EditApi from "../../../../api/editData/EditApi";
import { useDispatch } from "react-redux";
import { fetchAwardsHistory } from "../../../../api/fetchAllData/fetchAwardsHistory";
import { fetchLicensesHistory } from "../../../../api/fetchAllData/fetchLicensesHistory";
import { fetchMembershipHistory } from "../../../../api/fetchAllData/fetchMembershipHistory";
import { FormatDateIntoPost } from "../../HelperFunction/FormatDateIntoPost";

const EditDetailedProfileLicesnseAndAwards = () => {
  /* STORE IMPORTS */
  const {
    detailedProfileEdit: DetailedProfileLicesnseAndAwardsEditData,
    language: selectedLanguage,
    content,
    regionalData,
  } = useSelector((state) => state);

  /* DISPATCH INIT */
  const dispatch = useDispatch();

  /* STATE INIT */
  const [desc, setDesc] = useState(
    DetailedProfileLicesnseAndAwardsEditData?.briefDescriptions || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* FORMAT THE DATES */
  const formattedStartDate =
    DetailedProfileLicesnseAndAwardsEditData?.validFrom &&
    DetailedProfileLicesnseAndAwardsEditData?.validFrom.includes("/")
      ? timestampToYYYYMMDD(DetailedProfileLicesnseAndAwardsEditData?.validFrom)
      : formatTimestampToDate(
          Number(DetailedProfileLicesnseAndAwardsEditData?.validFrom),
          regionalData?.selectedCountry?.dateFormat
        );
  const formattedEndDate =
    DetailedProfileLicesnseAndAwardsEditData?.validTill &&
    DetailedProfileLicesnseAndAwardsEditData?.validTill.includes("/")
      ? timestampToYYYYMMDD(DetailedProfileLicesnseAndAwardsEditData?.validTill)
      : formatTimestampToDate(
          Number(DetailedProfileLicesnseAndAwardsEditData?.validTill),
          regionalData?.selectedCountry?.dateFormat
        );

  /* FORMAT DATE FOR AWARDS */
  const formattedDate =
    DetailedProfileLicesnseAndAwardsEditData?.date &&
    DetailedProfileLicesnseAndAwardsEditData?.date.includes("/")
      ? timestampToYYYYMMDD(DetailedProfileLicesnseAndAwardsEditData?.date)
      : formatTimestampToDate(
          Number(DetailedProfileLicesnseAndAwardsEditData?.date),
          regionalData?.selectedCountry?.dateFormat
        );

  /* FORMAT THE DATES */
  const formattedStartDateForMEm =
    DetailedProfileLicesnseAndAwardsEditData?.startDate &&
    DetailedProfileLicesnseAndAwardsEditData?.startDate.includes("/")
      ? timestampToYYYYMMDD(DetailedProfileLicesnseAndAwardsEditData?.startDate)
      : formatTimestampToDate(
          Number(DetailedProfileLicesnseAndAwardsEditData?.startDate),
          regionalData?.selectedCountry?.dateFormat
        );
  const formattedEndDateForMEm =
    DetailedProfileLicesnseAndAwardsEditData?.endDate &&
    DetailedProfileLicesnseAndAwardsEditData?.endDate.includes("/")
      ? timestampToYYYYMMDD(DetailedProfileLicesnseAndAwardsEditData?.endDate)
      : formatTimestampToDate(
          Number(DetailedProfileLicesnseAndAwardsEditData?.endDate),
          regionalData?.selectedCountry?.dateFormat
        );

  /* HANDLE EDIT LICENSE DETAILS */
  const handleEditLicenseDetail = useCallback(
    async (closeR) => {
      /* SET SENDING PAYLOAD STATE TO TRUE */
      setIsSendingData(true);

      /* CREATE A DEEP COPY OF THE OBJECT */
      const payload = JSON.parse(
        JSON.stringify(DetailedProfileLicesnseAndAwardsEditData)
      );

      /* PAYLOAD */
      const { id, createdTime, briefDescriptions, expired, ...RPayload } =
        payload;
      if (RPayload?.applicationName === "Memberships") {
        if (RPayload?.startDate && RPayload?.endDate) {
          RPayload.startDate = FormatDateIntoPost(
            timestampToYYYYMMDD(Number(RPayload.startDate))
          );
          RPayload.endDate = FormatDateIntoPost(
            timestampToYYYYMMDD(Number(RPayload.endDate))
          );
        } else {
          if (RPayload?.endDate === "") delete RPayload?.endDate;
          RPayload.startDate = FormatDateIntoPost(
            timestampToYYYYMMDD(Number(RPayload.startDate))
          );
        }
      } else if (RPayload?.applicationName === "Awards") {
        delete RPayload?.status;
        RPayload.date = FormatDateIntoPost(
          timestampToYYYYMMDD(Number(RPayload.date))
        );
      } else if (RPayload?.applicationName === "Licenses") {
        delete RPayload?.applicationName;
        RPayload.validFrom = FormatDateIntoPost(
          timestampToYYYYMMDD(Number(RPayload.validFrom))
        );
        RPayload.validTill = FormatDateIntoPost(
          timestampToYYYYMMDD(Number(RPayload.validTill))
        );
      }
      RPayload.briefDescriptions = desc;
      try {
        await EditApi(
          DetailedProfileLicesnseAndAwardsEditData?.applicationName,
          DetailedProfileLicesnseAndAwardsEditData?.id,
          RPayload
        );
        if (closeR === "Yes") handleModalClose();
        /* FETCH DATA */
        if (
          DetailedProfileLicesnseAndAwardsEditData?.applicationName === "Awards"
        ) {
          dispatch(fetchAwardsHistory());
        } else if (
          DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
          "Licenses"
        ) {
          dispatch(fetchLicensesHistory());
        } else if (
          DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
          "Memberships"
        ) {
          dispatch(fetchMembershipHistory());
        }
        /* SET LOADING STATE TO FALSE */
        setIsSendingData(false);
      } catch (error) {
        setIsSendingData(false);
        if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error?.message);
        } else if (error instanceof TypeError) {
          console.error("TypeError Occured: ", error?.message);
        } else {
          console.error("Error occured while editing details: ", error);
        }
      }
    },
    [
      DetailedProfileLicesnseAndAwardsEditData,
      handleModalClose,
      desc,
      dispatch,
    ]
  );

  useEffect(() => {
    setDesc(
      (prev) =>
        DetailedProfileLicesnseAndAwardsEditData?.briefDescriptions || ""
    );
  }, [
    DetailedProfileLicesnseAndAwardsEditData,
    DetailedProfileLicesnseAndAwardsEditData?.briefDescriptions,
  ]);

  /* NOTE: THIS EDIT POP UP USED FOR EDITING LICENSE, AWARDS & MEMBERSHIPS */
  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="EditDetailedProfileLicesnseModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                "Licenses"
                  ? (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "License"
                      ) || {}
                    ).mvalue || "nf Licenses"
                  : DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                    "Awards"
                  ? (
                      content[selectedLanguage].find(
                        (item) =>
                          item.elementLabel === "AwardsAccoladesAchievements"
                      ) || {}
                    ).mvalue || "nf Awards/Accolades/Achievements"
                  : (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Memberships"
                      ) || {}
                    ).mvalue || "nf MemberShips"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span>{" "}
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                {/* DETAILED PROFILE EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-1">
                      {/* NAME */}
                      <div class="mb-2" style={{ pointerEvents: "none" }}>
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          {DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                          "Licenses"
                            ? (
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "License"
                                ) || {}
                              ).mvalue || "nf Licenses"
                            : DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                              "Awards"
                            ? (
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel ===
                                    "AwardsAccoladesAchievements"
                                ) || {}
                              ).mvalue || "nf Awards/Accolades/Achievements"
                            : (
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "NameOfTheMembership"
                                ) || {}
                              ).mvalue || "nf Name Of Membership"}
                        </label>
                        <CreateSelect
                          placeholder={
                            DetailedProfileLicesnseAndAwardsEditData?.licenseName
                              ? DetailedProfileLicesnseAndAwardsEditData.licenseName
                              : DetailedProfileLicesnseAndAwardsEditData?.title
                          }
                          value={
                            DetailedProfileLicesnseAndAwardsEditData?.licenseName
                              ? DetailedProfileLicesnseAndAwardsEditData.licenseName
                              : DetailedProfileLicesnseAndAwardsEditData?.title
                          }
                        />
                      </div>

                      {/* ORGANIZATION */}
                      {DetailedProfileLicesnseAndAwardsEditData?.organization && (
                        <div className="mt-2">
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "OrganizationName"
                            ) || {}
                          ).mvalue || "nf Organization Name"}
                          <div
                            className="mt-2"
                            style={{ pointerEvents: "none" }}
                          >
                            <CreateSelect
                              type="text"
                              name="projectActivity"
                              className="form-control text-dark"
                              style={{ borderColor: "#ccc" }}
                              placeholder={
                                DetailedProfileLicesnseAndAwardsEditData?.organization
                              }
                              value={
                                DetailedProfileLicesnseAndAwardsEditData?.organization
                              }
                            />
                          </div>
                        </div>
                      )}

                      {/* DATES */}
                      {DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                      "Licenses" ? (
                        <div className="d-lg-flex my-2 w-100">
                          <div className=" h-75 w-100">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "ValidFrom"
                                ) || {}
                              ).mvalue || "nf Valid From"}
                            </label>
                            <input
                              type="date"
                              max={timestampToYYYYMMDD(Date.now())}
                              style={{ height: "32px" }}
                              className="form-control bg-body-tertiary text-secondary h-75"
                              id="exampleFormControlInput1"
                              name="fromDate"
                              value={ timestampToYYYYMMDD(Number(DetailedProfileLicesnseAndAwardsEditData?.validFrom))}
                              disabled={true}
                              readOnly
                            />
                          </div>
                          <div className="ms-lg-2 h-75 w-100">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className={"form-label bg-body-tertiary"}
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "ValidTill"
                                ) || {}
                              ).mvalue || "nf Valid Till"}
                            </label>
                            <input
                              type="date"
                              min={
                                DetailedProfileLicesnseAndAwardsEditData?.endDate
                              }
                              max={timestampToYYYYMMDD(Date.now())}
                              style={{ height: "32px" }}
                              className={
                                "form-control bg-body-tertiary text-secondary h-75"
                              }
                              id="exampleFormControlInput1"
                              name="toDate"
                              value={DetailedProfileLicesnseAndAwardsEditData?.validTill ? timestampToYYYYMMDD(Number(DetailedProfileLicesnseAndAwardsEditData?.validTill)) : ''}
                              disabled={true}
                              readOnly
                            />
                          </div>
                        </div>
                      ) : DetailedProfileLicesnseAndAwardsEditData?.applicationName ===
                        "Awards" ? (
                        <div className="d-lg-flex my-2 w-100">
                          <div className=" h-75 w-100  ">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label "
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "Date"
                                ) || {}
                              ).mvalue || "nf Date"}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              style={{ height: "32px" }}
                              value={ timestampToYYYYMMDD(Number(DetailedProfileLicesnseAndAwardsEditData?.date))}
                              max={timestampToYYYYMMDD(Date.now())}
                              name="date"
                              className="form-control text-secondary bg-body-tertiary h-75"
                              id="exampleFormControlInput1"
                              disabled={true}
                              readOnly
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex my-2 w-100"
                          style={{ pointerEvents: "none" }}
                        >
                          <div className=" h-75 w-100">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label "
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "ProjecStart"
                                ) || {}
                              ).mvalue || "nf ProjectStartDate"}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              style={{ height: "32px" }}
                              className="form-control text-secondary bg-body-tertiary h-75 "
                              id="exampleFormControlInput1"
                              name="fromDate"
                              max={timestampToYYYYMMDD(Date.now())}
                              value={ timestampToYYYYMMDD(Number(DetailedProfileLicesnseAndAwardsEditData?.startDate))}
                              readOnly
                            />
                          </div>
                          <div className=" ms-2 h-75 w-100  ">
                            <div>
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label bg-body-tertiary"
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "ProjectEnd"
                                  ) || {}
                                ).mvalue || "nf ProjectEndDate"}
                              </label>
                              <input
                                type="date"
                                style={{
                                  height: "32px",
                                }}
                                className="form-control text-secondary bg-body-tertiary h-75 "
                                id="exampleFormControlInput1"
                                max={timestampToYYYYMMDD(Date.now())}
                                name="toDate"
                                value={DetailedProfileLicesnseAndAwardsEditData?.endDate ? timestampToYYYYMMDD(Number(DetailedProfileLicesnseAndAwardsEditData?.endDate)) : ''}
                                readOnly
                              />
                            </div>
                            {!DetailedProfileLicesnseAndAwardsEditData?.endDate ||
                            !formattedEndDateForMEm.length ? (
                              <React.Fragment>
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="text-secondary"
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel ===
                                        "CurrentMembership"
                                    ) || {}
                                  ).mvalue || "nf Current Membership"}{" "}
                                </label>
                                <input
                                  id="flexCheckCheckedDisabled"
                                  className="ms-2 text-secondary"
                                  type="checkbox"
                                  name="instituteEndDate"
                                  checked={
                                    !DetailedProfileLicesnseAndAwardsEditData?.endDate ||
                                    !formattedEndDateForMEm.length
                                  }
                                  readOnly
                                />
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      )}

                      {/* LOCATION */}
                      {DetailedProfileLicesnseAndAwardsEditData?.location && (
                        <div className="my-2">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "nf Locations"}
                          </label>

                          <div className="d-flex gap-2">
                            <div className="p-1 text-secondary rounded bg-light border">
                              {
                                DetailedProfileLicesnseAndAwardsEditData?.location
                              }
                            </div>
                          </div>
                        </div>
                      )}

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "ProjectBriefDescription"
                            ) || {}
                          ).mvalue || "nf Brief Description"}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescriptions"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditLicenseDetail("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditLicenseDetail("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditDetailedProfileLicesnseAndAwards;
