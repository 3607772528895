import React, { useCallback, useEffect, useRef, useState } from "react";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import CreateSelect from "../../SelectComponent/CreateSelect";
import { fetchConferencesHistory } from "../../../../api/fetchAllData/fetchConferenceHistory";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { useSelector, useDispatch } from "react-redux";
import { fetchSkillingHistory } from "../../../../api/fetchAllData/fetchSkillingHistory";
import { fetchDataSkillsAcquired } from "../../../../api/fetchAllData/fetchDataSkillsAcquired";

const EditDetailedProfileConfandSkilling = () => {
  /* DISPATCH */
  const dispatch = useDispatch();

  /* STORE IMPORTS */
  const {
    detailedProfileEdit: detailedProfileConfandSkillingEditData,
    language: selectedLanguage,
    content,
    regionalData
  } = useSelector((state) => state);

  /* STATE INIT */
  const [desc, setDesc] = useState(
    detailedProfileConfandSkillingEditData?.briefDescription || ""
  );
  const [titleName, setTitleName] = useState(
    detailedProfileConfandSkillingEditData?.title || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* FORMAT THE DATES */
  const formattedStartDate =
    detailedProfileConfandSkillingEditData?.startDate &&
      detailedProfileConfandSkillingEditData?.startDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileConfandSkillingEditData?.startDate)
      : formatTimestampToDate(
        Number(detailedProfileConfandSkillingEditData?.startDate),
        regionalData?.selectedCountry?.dateFormat
      );
  const formattedEndDate =
    detailedProfileConfandSkillingEditData?.endDate &&
      detailedProfileConfandSkillingEditData?.endDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileConfandSkillingEditData?.endDate)
      : formatTimestampToDate(
        Number(detailedProfileConfandSkillingEditData?.endDate),
        regionalData?.selectedCountry?.dateFormat
      );

  /* HANDLE EDIT SUBMIT */
  const handleEditProjectDetail = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);

      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescription: desc,
        },
        formNames: [],
      };

      if (
        detailedProfileConfandSkillingEditData?.applicationName ===
        "Conferences"
      ) {
        payload.formNames.push("Skills Acquired");
        payload.formNames.push("Conferences");
        payload.skillsAcquired = {
          source: titleName,
          briefDescription: desc,
        };
        payload.editData.title = titleName;
        payload.editData.source = titleName;
        payload.conferences = {
          source: titleName,
        };
      } else if (
        detailedProfileConfandSkillingEditData?.applicationName === "Skilling"
      ) {
        payload.formNames.push("Skills Acquired");
        payload.formNames.push("Skilling");
        payload.skillsAcquired = {
          source: titleName,
          briefDescription: desc,
        };
        payload.editData.title = titleName;
        payload.editData.source = titleName;
        payload.skilling = {
          source: titleName,
        };
      }

      try {
        await EditSkillsApi(
          `${detailedProfileConfandSkillingEditData?.applicationName}`,
          detailedProfileConfandSkillingEditData?.id,
          payload
        );
        setIsSendingData(false);
        /* DISPATCH */
        if (
          detailedProfileConfandSkillingEditData?.applicationName ===
          "Conferences"
        ) {
          dispatch(fetchConferencesHistory());
          dispatch(fetchDataSkillsAcquired());
        } else if (
          detailedProfileConfandSkillingEditData?.applicationName === "Skilling"
        ) {
          dispatch(fetchSkillingHistory());
          dispatch(fetchDataSkillsAcquired());
        }

        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      dispatch,
      detailedProfileConfandSkillingEditData,
      handleModalClose,
      titleName,
    ]
  );

  useEffect(() => {
    if (detailedProfileConfandSkillingEditData) {
      setDesc(detailedProfileConfandSkillingEditData?.briefDescription);

      if (
        detailedProfileConfandSkillingEditData?.organization &&
        detailedProfileConfandSkillingEditData?.title
      ) {
        setTitleName(detailedProfileConfandSkillingEditData?.title);
      }
    }
  }, [detailedProfileConfandSkillingEditData]);

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="detailedProfileEditConfandSkillingModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {detailedProfileConfandSkillingEditData?.applicationName ===
                  "Skilling"
                  ? (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Skilling"
                    ) || {}
                  ).mvalue || "nf Skilling"
                  : (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Conferences"
                    ) || {}
                  ).mvalue || "nf Conference"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span> {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                {/* DETAILED PROFILE EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-1">
                      {/* ORGANIZATION */}
                      {detailedProfileConfandSkillingEditData?.title && (
                        <div className="mt-2">
                          {detailedProfileConfandSkillingEditData?.applicationName ===
                            "Conferences"
                            ? (
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "conferenceName"
                              ) || {}
                            ).mvalue || "Name Of Conference"
                            : (
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "NameOfTheSkill"
                              ) || {}
                            ).mvalue || "nf Name Of Skill"}
                          <div className="mt-2">
                            <input
                              type="text"
                              name="projectActivity"
                              className="form-control text-dark"
                              style={{ borderColor: "#ccc" }}
                              placeholder={titleName}
                              value={titleName}
                              onChange={(e) => setTitleName(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {/* ORGANIZATION */}
                      {detailedProfileConfandSkillingEditData?.organization && (
                        <div className="mt-2" style={{ pointerEvents: "none" }}>
                          {detailedProfileConfandSkillingEditData?.applicationName ===
                            "Conferences"
                            ? (
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "InstituteCollegeUniversity"
                              ) || {}
                            ).mvalue || "nf Institute/College/University"
                            : (
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "InstituteCollegeUniversity"
                              ) || {}
                            ).mvalue || "nf Institute/College/University"}
                          <div className="mt-2">
                            <CreateSelect
                              type="text"
                              name="projectActivity"
                              className="form-control text-dark"
                              style={{ borderColor: "#ccc" }}
                              placeholder={
                                detailedProfileConfandSkillingEditData?.organization
                              }
                              value={
                                detailedProfileConfandSkillingEditData?.organization
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className="d-lg-flex my-2 w-100">
                        <div className=" h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjecStart"
                              ) || {}
                            ).mvalue || "nf ProjectStart"}
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={ timestampToYYYYMMDD(Number(detailedProfileConfandSkillingEditData?.startDate))}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjectEnd"
                              ) || {}
                            ).mvalue || "nf ProjectEnd"}
                          </label>
                          <input
                            type="date"
                            min={
                              detailedProfileConfandSkillingEditData?.endDate
                            }
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={detailedProfileConfandSkillingEditData?.endDate ? timestampToYYYYMMDD(Number(detailedProfileConfandSkillingEditData?.endDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}
                      {detailedProfileConfandSkillingEditData?.location && (
                        <div className="my-2" style={{ pointerEvents: "none" }}>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "not found"}
                          </label>

                          <div className="d-flex gap-2">
                            <div className="p-1 text-secondary rounded bg-light border">
                              {detailedProfileConfandSkillingEditData?.location}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "BriefDescriptionAboutOrganization"
                            ) || {}
                          ).mvalue || "nf Brief Description about Organization"}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescription"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditProjectDetail("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditProjectDetail("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditDetailedProfileConfandSkilling;
