import React, { useCallback, useEffect, useState } from "react";
import NavbarComponent from "../../../components/navbar/NavbarComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserExistenceDetail } from "../../../api/auth/getUserExistenceDetail";
import logo from "../../../Images/logo.png";

const UserVerificationEmail = () => {
  /* STORE IMPORTS */
  const { language: selectedLanguage, content } = useSelector((state) => state);

  /* NAVIGATE INIT */
  const navigate = useNavigate();

  /* STATES INIT */
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [Country, setCountry] = useState({
    countryCode: "US",
    countryName: "United States",
  });

  /* DOCUMENT TITLE INIT */
  useEffect(() => {
    document.title =
      (
        content[selectedLanguage].find(
          (item) => item.elementLabel === "WelcomeToMyST"
        ) || {}
      ).mvalue || "Welcome To MyST";
  }, [content, selectedLanguage]);

  /* HANDLE EMAIL SUBMIT */
  const handleEmailSubmit = useCallback(
    async (e) => {
      /* PREVENT BROWSER DEFAULT BEHAVIOR NOTE: ADDED THIS TO AVOID PAGE UNNECESSARY RELOAD */
      e.preventDefault();
      /* INIT LOADING */
      setIsLoading(true);

      if (!email.length) {
        setIsLoading(false);
        setIsEmail(false);
        return;
      }

      try {
        const res = await getUserExistenceDetail(email);
        const data = res?.data;

        if (data?.message === "Account Not Exist") {
          navigate("/signup-page");
        } else if (data?.message === "Account Exist") {
          navigate("/login-page");
        }
        /* SET EMAIL IN LOCAL STORAGE */
        localStorage.setItem("auth_key", email);
        setIsEmail(true);
        /* SET LOADING STATE FALSE */
        setIsLoading(false);
      } catch (error) {
        /* RESET THE LOADING STATE */
        setIsLoading(false);
        if (error instanceof TypeError) {
          console.error("Type error occured: ", error.message);
        } else if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error.message);
        } else {
          console.error(
            "Error occured while checking email for Forgot password: ",
            error.message
          );
        }
      }
    },
    [email, navigate]
  );

  return (
    <React.Fragment>
      {/* NAVBAR */}
      <NavbarComponent
        isNewLoginPage={true}
        Country={Country}
        setCountry={setCountry}
      />

      {/* PAGE CONTENT */}
      <div className="p-5" style={{ height: "calc(-60px + 96vh)" }}>
        <div class="container-fluid" style={{ height: "100%" }}>
          <div class="row no-gutter" style={{ height: "100%" }}>
            {/* LEFT CONTENT {IMAGE} */}
            <div class="col-md-6 d-none  d-lg-flex  justify-content-center align-items-center h-100 ">
              <img
                src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1706759529/6300958_kouoz3555_ntgoex.png"
                alt="login-avatar"
                style={{ width: "40vw" }}
              />
            </div>

            {/* RIGHT CONTENT */}
            <div class="col-md-6 m-auto">
              <div class="login d-flex align-items-center py-5">
                <div class="container">
                  <div className="d-flex justify-content-center align-content-center mb-2">
                    <img src={logo} height={"50px"} alt="logo"></img>
                  </div>
                  <div class="row">
                    <div class="col-lg-12  mx-auto ">
                      <h6
                        class="text-muted mb-4 text-center"
                        style={{ letterSpacing: ".1rem" }}
                      >
                        {" "}
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "EnterEmail"
                          ) || {}
                        ).mvalue || "nf Please Enter Email Address"}
                      </h6>
                      {/* FORM */}
                      <form
                        className={
                          validated
                            ? "needs-validation was-validated"
                            : "needs-validation"
                        }
                        noValidate
                      >
                        <div className="col-lg-8 mx-auto">
                          <div
                            class="form-group mb-3"
                            style={{ position: "relative" }}
                          >
                            <div
                              class=""
                              style={{
                                position: "absolute",
                                top: "2px",
                                left: "2px",
                              }}
                            >
                              <span
                                class="input-group-text bg-white pl-2 border-0 h-100"
                                style={{ borderRadius: 0 }}
                              >
                                <i class="fa fa-envelope text-muted"></i>
                              </span>
                            </div>
                            <input
                              id="email"
                              style={{ height: "32px", paddingLeft: "43px" }}
                              name="email"
                              type="text"
                              placeholder={`${
                                (
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "EmailAddress"
                                  ) || {}
                                ).mvalue || "nf EmailAddress"
                              } / ${
                                (
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "UserName"
                                  ) || {}
                                ).mvalue || "nf Username"
                              }`}
                              required
                              autofocus
                              class={
                                "form-control font-5" +
                                (email.length === 0 && !isEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              vlaue={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div class="invalid-feedback">
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "EnterEmail"
                                ) || {}
                              ).mvalue || "nf EnterAnEmail"}
                            </div>

                            <button
                              type="button"
                              id="signInBtn"
                              class="btn mt-2 text-white btn-block mb-2 float-end rounded-pill shadow-sm"
                              style={{ backgroundColor: "#815F0B" }}
                              onClick={handleEmailSubmit}
                            >
                              {isLoading && (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "SignIn"
                                ) || {}
                              ).mvalue || "nf SignIn"}
                              {" / "}
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "SignUp"
                                ) || {}
                              ).mvalue || "nf SignUp"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserVerificationEmail;
