import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DeleteAttachment,
  DownloadAttachment,
  GetAttachment,
} from "../../api/Attachment  API/DownloadAttachmentApi";
import { MdOpenInNew } from "react-icons/md";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import SmallLoader from "../SkillAvailer/SmallLoader";
import { MdEdit } from "react-icons/md";
import EditApi from "../../api/editData/EditApi";
import { useDispatch } from "react-redux";
import { editExistingUserProfile } from "../../reducer/userDetails/UserProfileSlice";
import Pagination from "../Pagination/Pagination";
import { fetchUserAttachment } from "../../reducer/attachments/getUserAttachmentSlice";
import {
  MakeAttachmentRelationPostApi,
  getLinkedAttachmentDetail,
} from "../../api/Attachment  API/Attachment Console/MakeRelationApi";
import AttachmentDeleteCard from "../Attachment/AttachmentDeleteCard";
import { useSelector } from "react-redux";
import { GetUserForSelectedLanguage } from "../SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import AttachmentPost from "../../api/Attachment  API/AttachmentPost";
import DeleteApi from "../../api/DeleteData/DeleteApi";
import { FaTimes, FaSearch } from "react-icons/fa";
import { IoCheckmarkSharp } from "react-icons/io5";

const AttachmentsModal = () => {
  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    getUserAttachment: { userAttachmentData },
  } = useSelector((state) => state);
  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  /* USER ID */
  const userId = localStorage.getItem("userId");
  /* USER ROLE */
  const role_ = localStorage.getItem("USER_ROLE");
  /* DISPATCH INIT */
  const dispatch = useDispatch();

  /* USE REFS */
  const fileInputRef = useRef(null);

  /* STATES INIT */
  const [editingAttachmentId, setEditingAttachmentId] = useState(null);
  const [newDescription, setNewDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [selectedId, setSelectedId] = useState(null);
  const [additionalData, setAdditionalData] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isDeleteOn, setisDeleteOn] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [FileId, setFileId] = useState("");
  const [Id, setId] = useState("");
  const [verifyData, setVerifyData] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAttachmentError, setShowAttachmentError] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [areFilesSet, setAreFilesSet] = useState(false);
  const [storagePercentage, setStoragePercentage] = useState(0);
  const [isStorageFull, setIsStorageFull] = useState(false);
  const [isNoSpaceError, setIsNoSpaceErro] = useState(false);
  const [isFileRemoving, setIsFileRemoving] = useState(false);

  const showMoreDetails = useCallback(
    (id) => {
      setSelectedId(id === selectedId ? null : id);
    },
    [selectedId]
  );

  useEffect(() => {}, [userDetails, userAttachmentData]);

  /* CALCULATE PERCENTAGE FOR STORAGE */
  useEffect(() => {
    const absoluteData = userAttachmentData?.filter(
      (att) =>
        att?.linkedApplicationName === userDetails?.applicationName &&
        att?.roleName === role_
    );

    if (absoluteData?.length) {
      let totalFileSize = 0;
      absoluteData.forEach((data) => {
        totalFileSize += Number(data?.fileSize || 0);
      });

      /* MAXIMUM SIZE OF FILE */
      const maxFileSize = Number(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MaxStorageCapacity"
          ) || {}
        ).mvalue || "nf Maximum storage capacity"
      );
      let percentage = (totalFileSize / maxFileSize) * 100;

      /* RESTICT % NOT TO EXCEED 100 */
      percentage = Math.min(percentage, 100);

      setStoragePercentage(Math.round(percentage));
    }else {
      setStoragePercentage(0);
    }
  }, [
    role_,
    userAttachmentData,
    userDetails?.applicationName,
    content,
    selectedLanguage,
  ]);

  const handleFileSelect = useCallback((e) => {
    setSelectedFiles(Array.from(e.target.files));
  }, []);

  /* HANDLE ATTACHMENTS */
  const handleAttachmentUpload = useCallback(
    async (files) => {
      if (files.length === 0) {
        setShowAttachmentError(false);
        return;
      }

      /* CHECK FILE SIZE */
      const largeFiles = files.some(
        (file) =>
          file?.size >
          Number(
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "MaxFileSize"
              ) || {}
            ).mvalue || "nf Maximum File Size"
          )
      );
      if (largeFiles) {
        setShowAttachmentError(true);
        setTimeout(() => {
          setShowAttachmentError(false);
        }, 5000);
        return;
      } else {
        setShowAttachmentError(false);
      }

      /* CALC TOTAL FILE SIZE */
      const absoluteData = userAttachmentData?.filter(
        (att) =>
          att?.linkedApplicationName === userDetails?.applicationName &&
          att?.roleName === role_
      );

      let totalFileSize = 0;
      absoluteData.forEach((data) => {
        totalFileSize += Number(data?.fileSize || 0);
      });

      const maxFileSize = Number(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MaxStorageCapacity"
          ) || {}
        ).mvalue || "nf Maximum storage capacity"
      );
      const remainingStorageCapacity = maxFileSize - totalFileSize;

      /* CHECKING TOTAL SIZE OF THE FILES TO BE UPLOAD */
      const totalSelectedFileSize = files.reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSelectedFileSize > remainingStorageCapacity) {
        setIsNoSpaceErro(true);
        setTimeout(() => {
          setIsNoSpaceErro(false);
        }, 5000);
        return;
      } else {
        setIsNoSpaceErro(false);
      }

      if (storagePercentage >= 100) {
        setIsStorageFull(true);
        setTimeout(() => {
          setIsStorageFull(false);
        }, 5000);
        return;
      } else {
        setIsStorageFull(false);
      }

      setAttachmentFiles((prev) => [...prev, ...files]);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      try {
        setIsAttachmentLoading(true);
        const res = await AttachmentPost("handleMultiFile", userId, formData);
        const data = res?.data;

        const newUploadedFiles = data.map((file, index) => ({
          filename: file.fileName,
          fileId: file.fileId,
          fileTitle: "",
          fileSize: files[index]?.size,
        }));

        /* ATTACHMENT RELATION PROMISE */
        const attachmentRelationPromises = newUploadedFiles.map((file) => {
          const body = {
            userId: userId,
            fileId: file.fileId,
            fileName: file.filename,
            fileTitle: "",
            fileSize: file?.fileSize,
            linkedId: userId,
            linkedApplicationName: userDetails?.applicationName,
            roleName: role_,
          };
          return MakeAttachmentRelationPostApi(body);
        });

        try {
          await Promise.all(attachmentRelationPromises);
          /* DISPATCH DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error("Error POSTing attachment map: ", error);
        }

        setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
        setAreFilesSet(true);
        setIsAttachmentLoading(false);
        setAttachmentFiles([]);
      } catch (error) {
        setIsAttachmentLoading(false);
        console.error("Error uploading files: ", error);
      }
    },
    [
      userId,
      dispatch,
      userDetails?.applicationName,
      role_,
      storagePercentage,
      userAttachmentData,
      content,
      selectedLanguage,
    ]
  );

  const handleAddFiles = useCallback(() => {
    if (selectedFiles.length > 0) {
      handleAttachmentUpload(selectedFiles);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [handleAttachmentUpload, selectedFiles]);

  /* HANDLE REMOVE ATTACHMENT */
  const handleRemoveAttachment = useCallback(
    async (fileId, id) => {
      setIsFileRemoving(true);
      try {
        const res = await DeleteAttachment(userId, fileId);
        if (res?.status === 200) {
          const result = JSON.parse(userDetails?.attachmentFileNames)?.filter(
            (attachment) => attachment?.fileId !== fileId
          );

          const updatedUserDetails = {
            // ...userDetails,
            attachmentFileNames: JSON.stringify(result),
          };

          try {
            await DeleteApi("Attachment Map", id);
            const remvoveLinkedAttachment = userAttachmentData
              ?.filter((att) => att?.fileId === fileId)
              .map((val) => {
                return DeleteApi("Attachment Map", val?.id);
              });
            try {
              await Promise.all(remvoveLinkedAttachment);
            } catch (error) {
              console.error("Error removing linked attachment map: ", error);
            }
            /* DISPATCH ADVANCE DATA */
            dispatch(fetchUserAttachment());
          } catch (error) {
            console.error("Error deleting attachment detail: ", error);
          }

          EditApi("User Details", userDetails.id, updatedUserDetails);

          dispatch(
            editExistingUserProfile({
              id: userDetails?.id,
              updatedData: updatedUserDetails,
            })
          );
          setIsFileRemoving(false);
        }
      } catch (error) {
        setIsFileRemoving(false);
        console.error("Error deleting attachment: ", error);
      }
    },
    [userId, dispatch, userDetails, userAttachmentData]
  );

  /* UPDATE USER DETAILS */
  useEffect(() => {
    if (areFilesSet && uploadedFiles.length > 0) {
      const updateUserDetails = async () => {
        try {
          /* PARSE EXISING FILES */
          let existingAttachments = [];
          if (userDetails.attachmentFileNames) {
            try {
              existingAttachments = JSON.parse(userDetails.attachmentFileNames);
            } catch (error) {
              console.error("Error parsing attachmentFileNames: ", error);
            }
          }

          /* COMBINE EXISING WITH THE NEW ONE */
          const updatedAttachments = [...existingAttachments, ...uploadedFiles];

          /* PAYLOAD */
          const payload = {
            attachmentFileNames: JSON.stringify(updatedAttachments),
          };

          /* EDIT THE USER DETAILS */
          await EditApi("User Details", userDetails.id, payload);

          /* UPDATE REDUX */
          dispatch(
            editExistingUserProfile({
              id: userDetails?.id,
              updatedData: payload,
            })
          );
          setUploadedFiles([]);
        } catch (error) {
          console.error("Error updating user details: ", error);
        }
      };

      /* FUNC CALL */
      updateUserDetails();
      /* RESET EDIT STATE */
      setAreFilesSet(false);
    }
  }, [areFilesSet, uploadedFiles, userDetails, dispatch]);

  /* FETCH ADDITIONAL DATA USING FILE ID */
  const fetchAdditonalData = useCallback(
    async (fileId) => {
      setIsFetchLoading(true);
      setVerifyData(true);
      try {
        const res = await getLinkedAttachmentDetail(userId, fileId);
        setAdditionalData(res?.data);
        setIsFetchLoading(false);
      } catch (error) {
        setIsFetchLoading(false);
        setisDeleteOn(false);
        setVerifyData(false);
        console.error(error);
      }
    },
    [userId]
  );

  const indexOfLastItem = useMemo(
    () => currentPage * itemsPerPage,
    [currentPage, itemsPerPage]
  );
  const indexOfFirstItem = useMemo(
    () => indexOfLastItem - itemsPerPage,
    [indexOfLastItem, itemsPerPage]
  );

  /* HANDLE EDIT CLICK */
  const handleEditClick = (fileId, currentDescription) => {
    setEditingAttachmentId(fileId);
    setNewDescription(currentDescription);
  };

  /* HANDLE SAVE DESCIPTION */
  const handleSaveDescription = useCallback(
    async (fileId, id) => {
      setVerifyData(true);
      const updatedAttachments = JSON.parse(
        userDetails?.attachmentFileNames
      ).map((attachment) => {
        if (attachment?.fileId === fileId) {
          return {
            ...attachment,
            fileTitle: newDescription,
          };
        }
        return attachment;
      });
      const payload = {
        attachmentFileNames: JSON.stringify(updatedAttachments),
      };
      try {
        const body = {
          fileTitle: newDescription,
        };
        await EditApi("Attachment Map", id, body);
        /* DISPATCH */
        dispatch(fetchUserAttachment());
      } catch (error) {
        setVerifyData(false);
        console.error("Error editing desc: ", error);
      }
      try {
        await EditApi("User Details", userDetails.id, payload);
        setEditingAttachmentId(null);
        dispatch(
          editExistingUserProfile({
            id: userDetails?.id,
            updatedData: payload,
          })
        );
        setVerifyData(false);
      } catch (error) {
        setVerifyData(false);
        console.error("Error saving description: ", error);
      }
    },
    [
      newDescription,
      userDetails?.attachmentFileNames,
      userDetails?.id,
      dispatch,
    ]
  );

  /* HANDLE FILTER DATA */
  const filteredData = useMemo(() => {
    if (!searchTerm)
      return userAttachmentData?.filter(
        (att) =>
          att?.linkedApplicationName === userDetails?.applicationName &&
          att?.roleName === role_
      );
    return userAttachmentData
      ?.filter(
        (att) =>
          att?.linkedApplicationName === userDetails?.applicationName &&
          att?.roleName === role_
      )
      ?.filter(
        (attachment) =>
          attachment?.fileName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          attachment?.fileTitle
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
  }, [searchTerm, userAttachmentData, userDetails?.applicationName, role_]);

  /* HANDLE SEARCH INPUT CHANGE */
  const handleSearchInputChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    /* DEFAULT SEARCH PAGE IS 1 */
    setCurrentPage(1);
  }, []);

  /* CALCULATE THE TOTAL PAGES BASED ON DATA LENGTH AND ITEMS PER PAGE */
  const totalPage = useMemo(
    () =>
      userAttachmentData ? Math.ceil(filteredData?.length / itemsPerPage) : 0,
    [userAttachmentData, itemsPerPage, filteredData?.length]
  );

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  /* SHOW NO. OF CONTENT ACC TO FILTERED DATA */
  useEffect(() => {
    if (filteredData?.length < 50) {
      setItemsPerPage(4);
    } else {
      setItemsPerPage(5);
    }
  }, [filteredData?.length]);

  useEffect(() => {
    if (filteredData?.length > 0 && indexOfFirstItem >= filteredData.length) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [filteredData, indexOfFirstItem]);

  /* HANDLE DELETE FILE */
  const handleDeleteFile = useCallback(
    async (fileId, id) => {
      setVerifyData(true);
      try {
        await fetchAdditonalData(fileId);
        const res = await getLinkedAttachmentDetail(userId, fileId);
        const data = res?.data;
        const filteredData = data?.filter(
          (att) => att?.applicationName !== userDetails?.applicationName
        );
        if (filteredData?.length) {
          setisDeleteOn(true);
          setFileId(fileId);
          setId(id);
        } else {
          setisDeleteOn(false);
          setFileId("");
          setId("");
          await handleRemoveAttachment(fileId, id);
        }
        setVerifyData(false);
      } catch (error) {
        setIsFetchLoading(false);
        setVerifyData(false);
        setisDeleteOn(false);
        console.error(error);
      }
    },
    [
      userId,
      fetchAdditonalData,
      userDetails?.applicationName,
      handleRemoveAttachment,
    ]
  );

  /* SELECTED FILE NAMES */
  const FILE_NAMES = selectedFiles.map((file) => file?.name).join(", ");

  return (
    <div
      className="modal fade modal-xl"
      id="attachmentModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div
          className="modal-content"
          style={{
            boxShadow: "0 5px 15px rgba(87, 113, 38, 0.5)",
            marginTop: "5rem",
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title text-dark">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Attachments"
                ) || {}
              ).mvalue || "nf Attachments"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {/* SEARCH */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div
              className="input-group w-50"
              style={{ paddingLeft: "1.75rem" }}
            >
              <span className="input-group-text text-success">
                <FaSearch />
              </span>
              <input
                type="text"
                className="form-control rounded border-success"
                placeholder="Search..."
                spellCheck="true"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </div>

            {/* PROGRESS BAR */}
            <div
              className="w-25 d-flex align-items-center flex-column"
              style={{ paddingRight: "1.8rem" }}
            >
              <div className="progress">
                <div
                  className={`progress-bar ${
                    storagePercentage < 90
                      ? "bg-success"
                      : storagePercentage >= 90 && storagePercentage < 100
                      ? "bg-warning"
                      : "bg-danger"
                  }`}
                  role="progressbar"
                  style={{ width: `${storagePercentage}%` }}
                  aria-valuenow={storagePercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div
                className="text-center text-success"
                style={{ fontSize: "0.75rem" }}
              >
                <strong>{storagePercentage}%</strong> of <strong>100%</strong>{" "}
                used
              </div>
            </div>
          </div>

          {/* TABLE CONTENT */}
          <div className="modal-body">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <thead>
                            <tr>
                              <th className="fw-bold w-25">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "Attachments"
                                  ) || {}
                                ).mvalue || "nf Attachments"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "DescriptionLabel"
                                  ) || {}
                                ).mvalue || "nf DescriptionLabel"}
                              </th>
                              <th className="fw-bold" style={{ width: "15%" }}>
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Actions"
                                  ) || {}
                                ).mvalue || "nf Actions"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData && filteredData.length > 0 ? (
                              filteredData
                                .slice(indexOfFirstItem, indexOfLastItem)
                                .map((attachment, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td
                                        className="text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          showMoreDetails(attachment?.id);
                                          fetchAdditonalData(
                                            attachment?.fileId
                                          );
                                        }}
                                      >
                                        {attachment?.fileName}
                                      </td>
                                      <td
                                      // onClick={() => {
                                      //   editingAttachmentId !==
                                      //     attachment?.fileId &&
                                      //     showMoreDetails(attachment?.id);
                                      //   fetchAdditonalData(
                                      //     attachment?.fileId
                                      //   );
                                      // }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          {editingAttachmentId ===
                                          attachment?.fileId ? (
                                            <>
                                              <input
                                                type="text"
                                                id="newDescInput"
                                                autoFocus
                                                style={{
                                                  width: "90%",
                                                  height: "100%",
                                                  border: "none",
                                                  padding: "0.5rem",
                                                  boxSizing: "border-box",
                                                }}
                                                value={newDescription}
                                                onChange={(e) =>
                                                  setNewDescription(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              <div className="d-flex justify-content-between align-items-center">
                                                <FaTimes
                                                  style={{
                                                    color: "#577126",
                                                    height: "16px",
                                                    width: "16px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setEditingAttachmentId(
                                                      null
                                                    );
                                                  }}
                                                />
                                                <IoCheckmarkSharp
                                                  style={{
                                                    color: "#577126",
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    handleSaveDescription(
                                                      attachment?.fileId,
                                                      attachment?.id
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  opacity:
                                                    !attachment?.fileTitle &&
                                                    "0.6",
                                                }}
                                              >
                                                {attachment?.fileTitle
                                                  ? attachment?.fileTitle
                                                  : (
                                                      content[
                                                        selectedLanguage
                                                      ].find(
                                                        (item) =>
                                                          item.elementLabel ===
                                                          "EnterYourDescription"
                                                      ) || {}
                                                    ).mvalue ||
                                                    "nf Enter your description..."}
                                              </div>
                                              <div>
                                                <MdEdit
                                                  style={{
                                                    color: "#577126",
                                                    height: "16px",
                                                    width: "16px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleEditClick(
                                                      attachment?.fileId,
                                                      attachment?.fileTitle
                                                    )
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content-around align-items-center">
                                          <div>
                                            <a
                                              rel="noreferrer"
                                              href={GetAttachment(
                                                userId,
                                                attachment?.fileName,
                                                attachment?.fileId
                                              )}
                                              target="_blank"
                                            >
                                              <MdOpenInNew
                                                className=""
                                                style={{
                                                  color: "#577126",
                                                  height: "16px",
                                                  width: "16px",
                                                }}
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              rel="noreferrer"
                                              href={DownloadAttachment(
                                                userId,
                                                attachment?.fileName,
                                                attachment?.fileId
                                              )}
                                              target="_blank"
                                            >
                                              <IoCloudDownloadOutline
                                                className="mx-2"
                                                style={{
                                                  color: "#577126",
                                                  height: "16px",
                                                  width: "16px",
                                                }}
                                              />
                                            </a>
                                          </div>
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isFileRemoving
                                                ? "none"
                                                : "",
                                              opacity: isFileRemoving
                                                ? "0.5"
                                                : "1",
                                            }}
                                          >
                                            <FaRegTrashCan
                                              // onClick={() => {
                                              //   handleRemoveAttachment(
                                              //     attachment?.fileId,
                                              //     attachment?.id
                                              //   );
                                              //   fetchAdditonalData(
                                              //     attachment?.fileId
                                              //   );
                                              // }}
                                              onClick={() =>
                                                handleDeleteFile(
                                                  attachment?.fileId,
                                                  attachment?.id
                                                )
                                              }
                                              Tooltip="delete"
                                              className=""
                                              style={{
                                                color: "#577126",
                                                height: "16px",
                                                width: "16px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {selectedId === attachment?.id && (
                                      <tr>
                                        <td colSpan="3">
                                          <div className="d-flex gap-2 p-2">
                                            {additionalData?.filter(
                                              (att) =>
                                                att?.applicationName !==
                                                userDetails?.applicationName
                                            ).length ? (
                                              additionalData
                                                ?.filter(
                                                  (att) =>
                                                    att?.applicationName !==
                                                    userDetails?.applicationName
                                                )
                                                .map((data) => (
                                                  <React.Fragment
                                                    key={data?.id}
                                                  >
                                                    <div
                                                      className={`d-flex flex-wrap bg-body-tertiary shadow p-2 rounded me-1 ${
                                                        isFetchLoading &&
                                                        "btn-loading"
                                                      }`}
                                                      style={{
                                                        direction:
                                                          (
                                                            content[
                                                              selectedLanguage
                                                            ].find(
                                                              (item) =>
                                                                item.elementLabel ===
                                                                "Direction"
                                                            ) || {}
                                                          ).mvalue || "ltr",
                                                        fontSize: "0.9em",
                                                        opacity: isFetchLoading
                                                          ? "0.5"
                                                          : "",
                                                      }}
                                                    >
                                                      <div>
                                                        <strong>
                                                          {(
                                                            content[
                                                              selectedLanguage
                                                            ].find(
                                                              (item) =>
                                                                item.elementLabel ===
                                                                "ItemType"
                                                            ) || {}
                                                          ).mvalue ||
                                                            "nf Item Type"}
                                                        </strong>
                                                        <strong>:</strong>
                                                        &nbsp;
                                                        <span>
                                                          {
                                                            data?.applicationName
                                                          }
                                                        </span>
                                                        {(data?.title ||
                                                          data?.skillOccupation) && (
                                                          <div>
                                                            <strong>
                                                              {(
                                                                content[
                                                                  selectedLanguage
                                                                ].find(
                                                                  (item) =>
                                                                    item.elementLabel ===
                                                                    "SkillLabel"
                                                                ) || {}
                                                              ).mvalue ||
                                                                "nf Skill"}
                                                            </strong>
                                                            <strong>:</strong>
                                                            &nbsp;
                                                            <span>
                                                              {data?.title ||
                                                                data?.skillOccupation}
                                                            </span>
                                                          </div>
                                                        )}
                                                        {data?.projectActivity && (
                                                          <div>
                                                            <strong>
                                                              {(
                                                                content[
                                                                  selectedLanguage
                                                                ].find(
                                                                  (item) =>
                                                                    item.elementLabel ===
                                                                    "ProjectName"
                                                                ) || {}
                                                              ).mvalue ||
                                                                "nf Project Name"}
                                                            </strong>
                                                            <strong>:</strong>
                                                            &nbsp;
                                                            <span>
                                                              {
                                                                data?.projectActivity
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                        {data?.organization && (
                                                          <div>
                                                            <strong>
                                                              {(
                                                                content[
                                                                  selectedLanguage
                                                                ].find(
                                                                  (item) =>
                                                                    item.elementLabel ===
                                                                    "OrganizationName"
                                                                ) || {}
                                                              ).mvalue ||
                                                                "nf Organization Name"}
                                                            </strong>
                                                            <strong>:</strong>
                                                            &nbsp;
                                                            <span>
                                                              {
                                                                data?.organization
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                        {/* KEYNAME */}
                                                        {data?.keyName && (
                                                          <div>
                                                            <strong>
                                                              {(
                                                                content[
                                                                  selectedLanguage
                                                                ].find(
                                                                  (item) =>
                                                                    item.elementLabel ===
                                                                    "ItemName"
                                                                ) || {}
                                                              ).mvalue ||
                                                                "nf Item Name"}
                                                            </strong>
                                                            <strong>:</strong>
                                                            &nbsp;
                                                            <span>
                                                              {data?.keyName}
                                                            </span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                ))
                                            ) : (
                                              <div
                                                className={`text-center text-success ${isFetchLoading} &&
                                                          "btn-loading"`}
                                                style={{ margin: "0 auto" }}
                                              >
                                                {(
                                                  content[
                                                    selectedLanguage
                                                  ].find(
                                                    (item) =>
                                                      item.elementLabel ===
                                                      "NoFieldAttached"
                                                  ) || {}
                                                ).mvalue ||
                                                  "nf No fields related with this attachment"}
                                                !
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))
                            ) : (
                              <tr>
                                <td
                                  className="fw-bold text-center text-success"
                                  colSpan="4"
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel ===
                                        "NoAttachmentsFound"
                                    ) || {}
                                  ).mvalue || "nf No Attachments Found"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* PAGINATION */}
                        {totalPage > 1 && filteredData?.length ? (
                          <React.Fragment>
                            <Pagination
                              currentPage={currentPage}
                              totalPage={totalPage}
                              onPageChange={handlePageChange}
                            />
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3" style={{ padding: "0.75rem" }}>
              <div className="fw-bold text-dark">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "AttachAFile"
                  ) || {}
                ).mvalue || "nf Attach a File"}{" "}
                <b>:</b>
              </div>
              <div className="input-group mt-2 w-50">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  onChange={handleFileSelect}
                  multiple
                  accept=".pdf,.jpeg,.jpg,.png,.xlsx,.docx"
                  ref={fileInputRef}
                />
                <button
                  id="inputGroupFileAddon04"
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    color:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NavBarFontColor"
                        ) || {}
                      ).mvalue || "#000",
                    backgroundColor:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NavBarBgColor"
                        ) || {}
                      ).mvalue || "#000",
                  }}
                  disabled={isAttachmentLoading}
                  onClick={handleAddFiles}
                >
                  {!isAttachmentLoading ? (
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "AddSkillButton"
                      ) || {}
                    ).mvalue || "nf Add"
                  ) : (
                    <SmallLoader
                      height={"10px"}
                      width={"10px"}
                      color={"#fff"}
                    />
                  )}
                </button>
              </div>
            </div>
            {selectedFiles?.length > 0 && (
              <div className="text-dark">{FILE_NAMES}</div>
            )}
            {showAttachmentError && (
              <b className="text-danger">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "FileTooLarge"
                  ) || {}
                ).mvalue || "nf File size too large"}
                !
              </b>
            )}
            {isStorageFull && (
              <b className="text-danger">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "StorageFull"
                  ) || {}
                ).mvalue || "nf Storage full"}
                !
              </b>
            )}
            {isNoSpaceError && (
              <b className="text-danger">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "NoSpace"
                  ) || {}
                ).mvalue || "nf No Space"}
                !
              </b>
            )}

            {/* DELETE MODAL */}
            {isDeleteOn && (
              <AttachmentDeleteCard
                content={content}
                selectedLanguage={selectedLanguage}
                setisDeleteOn={setisDeleteOn}
                FaTimes={FaTimes}
                additionalData={additionalData}
                handleRemoveAttachment={handleRemoveAttachment}
                setIsDeleting={setIsDeleting}
                userDetails={userDetails}
                Id={Id}
                FileId={FileId}
                setId={setId}
                setFileId={setFileId}
                isDeleting={isDeleting}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentsModal;
