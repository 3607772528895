import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import EditApi from "../../../../api/editData/EditApi";
import { useDispatch } from "react-redux";
import { fetchOthersHistory } from "../../../../api/fetchAllData/fetchOtherHistory";
import { fetchUserLanguages } from "../../../../api/fetchAllData/fetchUserLanguages";

const EditLanguageAndOthers = () => {
  /* STORE IMPORTS */
  const {
    detailedProfileEdit: DetailedProfileLanguageAndOthersEditData,
    language: selectedLanguage,
    content,
  } = useSelector((state) => state);

  /* DISPATCH INIT */
  const dispatch = useDispatch();

  /* STATE INIT */
  const [descOthers, setDescOthers] = useState(
    DetailedProfileLanguageAndOthersEditData?.mothers || ""
  );
  const [mLang, setMLang] = useState(
    DetailedProfileLanguageAndOthersEditData?.userLanguage || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* HANDLE EDIT LICENSE DETAILS */
  const handleEditOthersDetail = useCallback(
    async (closeR) => {
      /* SET SENDING PAYLOAD STATE TO TRUE */
      setIsSendingData(true);

      /* CREATE A DEEP COPY OF THE OBJECT */
      const payload = JSON.parse(
        JSON.stringify(DetailedProfileLanguageAndOthersEditData)
      );

      /* PAYLOAD */
      const {
        id,
        createdTime,
        briefDescriptions,
        expired,
        status,
        ...RPayload
      } = payload;
      if (
        DetailedProfileLanguageAndOthersEditData.applicationName === "Others"
      ) {
        RPayload.mothers = descOthers;
      } else {
        RPayload.userLanguage = mLang;
        delete RPayload?.tenantId;
        delete RPayload?.applicationName;
      }
      try {
        await EditApi(
          DetailedProfileLanguageAndOthersEditData?.applicationName,
          DetailedProfileLanguageAndOthersEditData?.id,
          RPayload
        );
        if (closeR === "Yes") handleModalClose();
        /* DISPATCH DATA */
        if (
          DetailedProfileLanguageAndOthersEditData?.applicationName === "Others"
        ) {
          dispatch(fetchOthersHistory());
        } else if (
          DetailedProfileLanguageAndOthersEditData?.applicationName ===
          "User Languages"
        ) {
          dispatch(fetchUserLanguages());
        }

        /* SET LOADING STATE TO FALSE */
        setIsSendingData(false);
      } catch (error) {
        setIsSendingData(false);
        if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error?.message);
        } else if (error instanceof TypeError) {
          console.error("TypeError Occured: ", error?.message);
        } else {
          console.error("Error occured while editing details: ", error);
        }
      }
    },
    [
      DetailedProfileLanguageAndOthersEditData,
      handleModalClose,
      descOthers,
      mLang,
      dispatch,
    ]
  );

  useEffect(() => {
    if (
      DetailedProfileLanguageAndOthersEditData &&
      DetailedProfileLanguageAndOthersEditData.applicationName === "Others"
    ) {
      setDescOthers(
        (prev) => DetailedProfileLanguageAndOthersEditData?.mothers || ""
      );
    } else if (
      DetailedProfileLanguageAndOthersEditData &&
      DetailedProfileLanguageAndOthersEditData.applicationName ===
        "User Languages"
    ) {
      setMLang(
        (prev) => DetailedProfileLanguageAndOthersEditData?.userLanguage || ""
      );
    }
  }, [DetailedProfileLanguageAndOthersEditData]);

  useEffect(() => {}, [DetailedProfileLanguageAndOthersEditData]);

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="EditDetailedProfileLanguageAndOthers"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {DetailedProfileLanguageAndOthersEditData &&
                DetailedProfileLanguageAndOthersEditData?.applicationName ===
                  "Others"
                  ? (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Others"
                      ) || {}
                    ).mvalue || "nf Others"
                  : (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Languages"
                      ) || {}
                    ).mvalue || "nf Languages"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span>{" "}
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              {DetailedProfileLanguageAndOthersEditData &&
              DetailedProfileLanguageAndOthersEditData?.applicationName ===
                "Others" ? (
                <React.Fragment>
                  <div class="mb-2">
                    <label for="exampleFormControlInput1" class="form-label">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Name"
                        ) || {}
                      ).mvalue || "nf Name"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      style={{ height: "32px" }}
                      name="title"
                      class="form-control text-secondary bg-body-tertiary h-75"
                      id=""
                      placeholder={
                        DetailedProfileLanguageAndOthersEditData?.title
                      }
                      value={DetailedProfileLanguageAndOthersEditData?.title}
                      disabled
                    />
                  </div>

                  <div className="my-2">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      {" "}
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "ProjectBriefDescription"
                        ) || {}
                      ).mvalue || "nf ProjectBriefDescription"}
                    </label>
                    <textarea
                      className="form-control bg-body-tertiary"
                      name="mothers"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      value={descOthers}
                      onChange={(e) => setDescOthers(e.target.value)}
                    ></textarea>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div class="mb-2">
                    <label for="exampleFormControlInput1" class="form-label">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Languages"
                        ) || {}
                      ).mvalue || "nf Languages"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      style={{ height: "32px" }}
                      class="form-control bg-body-tertiary h-75"
                      id=""
                      placeholder=""
                      name="userLanguage"
                      value={mLang}
                      onChange={(e) => setMLang(e.target.value)}
                    />
                  </div>

                  <div className="d-flex gap-4 my-3  ">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        name="mystRead"
                        checked={
                          DetailedProfileLanguageAndOthersEditData?.mystRead &&
                          DetailedProfileLanguageAndOthersEditData?.mystRead ===
                            "Yes"
                            ? true
                            : false
                        }
                        disabled
                        readOnly
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Read"
                          ) || {}
                        ).mvalue || "nf Read"}
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked={
                          DetailedProfileLanguageAndOthersEditData?.mystWrite &&
                          DetailedProfileLanguageAndOthersEditData?.mystWrite ===
                            "Yes"
                            ? true
                            : false
                        }
                        name="mystWrite"
                        disabled
                        readOnly
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Write"
                          ) || {}
                        ).mvalue || "nf Write"}
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked={
                          DetailedProfileLanguageAndOthersEditData?.mystSpeak &&
                          DetailedProfileLanguageAndOthersEditData?.mystSpeak ===
                            "Yes"
                            ? true
                            : false
                        }
                        name="mystSpeak"
                        disabled
                        readOnly
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Speak"
                          ) || {}
                        ).mvalue || "nf Speak"}
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/* SUBMIT BUTTONS */}
              <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                <SecondaryBtnLoader
                  label={
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Save"
                      ) || {}
                    ).mvalue || "Save"
                  }
                  backgroundColor="#F8F8E9"
                  color="#815F0B"
                  onClick={() => handleEditOthersDetail("No")}
                  loading={isSendingData}
                />
                <SecondaryBtnLoader
                  label={
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Save&Close"
                      ) || {}
                    ).mvalue || "Save & Close"
                  }
                  backgroundColor="#815F0B"
                  color="#F8F8E9"
                  onClick={() => handleEditOthersDetail("Yes")}
                  loading={isSendingData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditLanguageAndOthers;
