import React from 'react';
import { useSelector } from 'react-redux';

const SecondaryBtn = ({ label, onClick, backgroundColor, color, disabled = false }) => {
  const selectedLanguage = useSelector(state => state.language);
  const content = useSelector(state => state.content);
  const buttonStyle = {
    //backgroundColor: backgroundColor || "#815F0B",
    cursor: disabled ? "not-allowed" : "pointer",
    //color: color || "#fff",

    borderColor: disabled ? "#ccc" : "#815F0B",
    opacity: disabled ? 0.5 : 1,
    backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'ThirdButtonBgColor') || {}).mvalue || "#FFEA00",
    color: (content[selectedLanguage].find(item => item.elementLabel === 'ThirdButtonFontColor') || {}).mvalue || "#000",
    padding:"3px",
    fontSize: (content[selectedLanguage].find(item => item.elementLabel === 'ThirdButtonFontSize') || {}).mvalue || "10px",
    fontWeight: "600",
    borderRadius: "5px",border:"solid",
    borderColor:(content[selectedLanguage].find(item => item.elementLabel === 'ThirdButtonFontColor') || {}).mvalue || "#000",
    minWidth:"2rem",
  };

  return (
    <button className={`align-self-center  border-1 ${disabled ? 'disabled' : ''}`} style={buttonStyle} onClick={!disabled ? onClick : undefined}>
      {label}
    </button>
  );
};

export default SecondaryBtn;
