import React from "react";
import { BiSolidInfoCircle } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import Anonimization from "../../Anonimization";
import AttachmentForm from "../../AttachmentForm";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import ShowHideIcon from "../../ShowHideIcon";
import Validation from "../../ValidationForm";
import EditDetailedProfile from "../Forms/Edit Forms/EditDetailedProfile";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";

import { setValidationData } from "../../../reducer/validation/validationSlice";
const ProjectDetail = ({ data, yoeCalc, editEnable }) => {
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };
  const regionalData = useSelector((state) => state.regionalData);

  /* DATA SENDING FOR VALIDATION */
  const handleValidate = (data) => {
    dispatch(setValidationData(data));
  };

  /* HANDLE PROJECT EDIT */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };

  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      {<AttachmentForm />}
      {/* DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfile />}
      {/* DELETE MODAL */}
      {<DeleteFormDetailedProfile />}
      <div className="table-responsive ">
        <table className="table table-sm      table-fixed     ">
          <tr className="border-dark-subtle border-bottom ">
            <th scope="col" className="bg-body- " style={{ width: "34%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectName"
                ) || {}
              ).mvalue || "nf Project Name"}
              <span
                className="ms-1 mb-1"
                style={{ backgroundColor: "transparent" }}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "ProjectDescription"
                    ) || {}
                  ).mvalue || "nf ProjectDescription"
                }
              >
                <BiSolidInfoCircle
                  className="text-muted"
                  style={{ fontSize: "20px" }}
                />
              </span>
            </th>
            <th scope="col" className="bg-body- " style={{ width: "17%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectOrganization"
                ) || {}
              ).mvalue || "nf Organization"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "13%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectDuration"
                ) || {}
              ).mvalue || "nf Duration"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "14%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectLocation"
                ) || {}
              ).mvalue || "nf Location"}
            </th>
            <th scope="col" className="         " style={{ width: "12%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectValidation"
                ) || {}
              ).mvalue || "nf Validation"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "10%" }}></th>
          </tr>
        </table>
      </div>

      {data.data.map((proj, index) => (
        <>
          {proj.mlanguage === selectedLanguage && (
            <>
              <div className="border-bottom  ">
                <div className="d-flex   " key={index}>
                  <div className=""></div>
                  <div className="  " style={{ width: "34%" }}>
                    {proj.projectActivity}
                  </div>
                  <div className="    " style={{ width: "17%" }}>
                    {proj.organization}
                  </div>
                  <div style={{ width: "13%" }}>
                    {" "}
                    {DayDifferenceToDynamicView(proj.duration)}
                  </div>
                  <div
                    className="    "
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={proj.location}
                    style={{ width: "14%" }}
                  >
                    {proj.location
                      ? proj.location.length > 20
                        ? proj.location.substring(0, 20) + "..."
                        : proj.location
                      : ""}
                  </div>
                  <div className="" style={{ width: "12%" }}>
                    {proj?.validation === "Yes" ? "Yes" : "No"}
                  </div>
                  <div
                    className="   d-flex justify-content-between   "
                    style={{ width: "10%" }}
                  >
                    {editEnable ? (
                      <>
                        <div
                          id="addModalBtn"
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="modal"
                          data-bs-target="#detailedProfileEditModal"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Edit"
                          onClick={() => handleEdit(proj)}
                        >
                          <MdEdit />
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          className="border-0 bg-transparent"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Delete"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteformdetailedprofile"
                          onClick={() => {
                            dispatch(setDeleteDetailedProfileData(proj));
                          }}
                        >
                          <MdDelete />
                        </div>

                        <div style={{ cursor: "pointer" }}>
                          <ShowHideIcon
                            value={proj.recordHide === "Yes" ? true : false}
                            toggleable={true}
                            form={proj}
                            fieldName={"recordHide"}
                            api={"Skills Applied"}
                            id={proj.id}
                          />
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          <Anonimization
                            value={
                              proj.recordAnonymous === "Yes" ? true : false
                            }
                            toggleable={true}
                            form={proj}
                            fieldName={"recordAnonymous"}
                            api={"Skills Applied"}
                            id={proj.id}
                          />
                        </div>
                      </>
                    ) : (
                      <React.Fragment>
                        <button
                          className="border-0 bg-white"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Validate"
                          data-bs-toggle="modal"
                          data-bs-target="#validationForm"
                          onClick={() => handleValidate(proj)}
                        >
                          <img
                            src={validationIcon}
                            alt=""
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </button>
                        <button
                          className="border-0 bg-white"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Attachment"
                          onClick={() => handleAttachment(proj)}
                          data-bs-toggle="modal"
                          data-bs-target="#attachmentList"
                        >
                          <ImAttachment />
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className="ms-5  my-3  ">
                  <table className="d-flex gap-4  ">
                    <tr>
                      <td>
                        <b>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "ProjecStart"
                            ) || {}
                          ).mvalue || "nf From"}{" "}
                          &nbsp;
                        </b>{" "}
                      </td>
                      <td>
                        :{" "}
                        {formatTimestampToDate(
                          Number(proj.fromDate),
                          regionalData.selectedCountry.dateFormat
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "ProjectEnd"
                            ) || {}
                          ).mvalue || "nf To"}{" "}
                          &nbsp;
                        </b>{" "}
                      </td>
                      <td>
                        :{" "}
                        {proj.toDate
                          ? formatTimestampToDate(
                              Number(proj.toDate),
                              regionalData.selectedCountry.dateFormat
                            )
                          : "On-going"}
                      </td>
                    </tr>
                  </table>
                  <div className="pb-1 pt-2 ps-5  ">
                    <b>
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "ProjectBriefDescription"
                        ) || {}
                      ).mvalue || "nf Brief Description"}
                      :
                    </b>{" "}
                  </div>
                  <div className="ms-4 ps-5   ">{proj.briefDescriptions}</div>
                  {proj.validation === "Yes" && (
                    <ValidationDataComponent itemId={proj.id} />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ))}
    </>
  );
};

export default ProjectDetail;
