import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Menu.css";
import { useNavigate } from "react-router-dom";

import Validation from "../../img/validation 1.svg";
import Profile from "../../img/profile-circle-svgrepo-com (5).svg";
import AttachmentIcon from "../../img/attachment.png";
import Education from "../../img/2429310.svg";
import Certification from "../../img/certification-file-svgrepo-com.svg";
import Training from "../../img/training.svg";
import Conference from "../../img/conferences.svg";
import Skilling from "../../img/skilling 1.svg";
import skills from "../../img/skills.svg";
import Licenses from "../../img/licenses.svg";
import Awards from "../../img/awards 1.svg";
import membership from "../../img/membership.svg";
import Languagess from "../../img/languages.svg";
import employment from "../../img/employment.svg";
import Projects from "../../img/projects (2).svg";
import Others from "../../img/other.svg";
import { useSelector } from "react-redux";
import ValidationModal from "../Modals/ValidationModal";
import UserProfile from "../SkillingAgency/UserComponents/UserProfile";
import UserDetailsApi from "../../api/auth/UserDetailsApi";
import {
  editExistingUserProfile,
  setUserProfile,
} from "../../reducer/userDetails/UserProfileSlice";
import { useDispatch } from "react-redux";
import { MdOutlineViewTimeline } from "react-icons/md";
import AttachmentsModal from "../Modals/AttachmentsModal";
import AttachmentPost from "../../api/Attachment  API/AttachmentPost";
import { FaTimes, FaSearch } from "react-icons/fa";
import { DeleteAttachment } from "../../api/Attachment  API/DownloadAttachmentApi";
import { GetUserForSelectedLanguage } from "../SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import EditApi from "../../api/editData/EditApi";
import { IoCheckmarkSharp } from "react-icons/io5";
import {
  MakeAttachmentRelationPostApi,
  getAttachmentsRelationData,
} from "../../api/Attachment  API/Attachment Console/MakeRelationApi";
import DeleteApi from "../../api/DeleteData/DeleteApi";
import { fetchUserAttachment } from "../../reducer/attachments/getUserAttachmentSlice";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    getUserAttachment: { userAttachmentData },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  /* USER ID */
  const userId = localStorage.getItem("userId");
  /* USER ROLE */
  const role_ = localStorage.getItem("USER_ROLE");

  const toggleMenu = () => {
    handleRendering();
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const fileInputRef = useRef(null);
  const [areFilesSet, setAreFilesSet] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);

  /* GET ATTACHMENT DATA */
  useEffect(() => {
    const getAttachmentData = async () => {
      try {
        const res = await getAttachmentsRelationData();
        setAttachmentData(res?.data);
      } catch (error) {
        console.error("Error getting attachment relation details: ", error);
      }
    };
    getAttachmentData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleRendering = () => {
    console.log("Rendered");
    UserDetailsApi().then((res) => {
      if (res.status === 200) {
        // set state of user details
        // const dispatch = useDispatch();
        // dispatch(setUserProfile(res.data[0].allValues));
        const userDetails = {
          ...res.data,
          token: localStorage.getItem("token"),
        };
        dispatch(setUserProfile(userDetails));
      } else {
        // something went wrong redirect to login page
        // localStorage.clear();
      }
    });
  };

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [showAttachmentError, setShowAttachmentError] = useState(false);

  const handleClose = () => setShowAttachmentModal(false);
  const handleShow = () => setShowAttachmentModal(true);

  useEffect(() => {}, [userDetails, uploadedFiles]);

  useEffect(() => {
    dispatch(fetchUserAttachment());
  }, [dispatch]);

  useEffect(() => {}, [userAttachmentData]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileSelect = useCallback((e) => {
    setSelectedFiles(Array.from(e.target.files));
  }, []);

  /* HANDLE ATTACHMENTS */
  const handleAttachmentUpload = useCallback(
    async (files) => {
      console.log("FILES LOG CFOR CHECK PURPOSE", files)
      if (files.length === 0) {
        setShowAttachmentError(false);
        return;
      }

      /* CHECK FILE SIZE */
      const largeFiles = files.some((file) => file?.size > 500000);
      if (largeFiles) {
        setShowAttachmentError(true);
        setTimeout(() => {
          setShowAttachmentError(false);
        }, 5000);
        return;
      } else {
        setShowAttachmentError(false);
      }

      setAttachmentFiles((prev) => [...prev, ...files]);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      try {
        setIsAttachmentLoading(true);
        const res = await AttachmentPost("handleMultiFile", userId, formData);
        const data = res?.data;

        const newUploadedFiles = data.map((file, index) => ({
          filename: file.fileName,
          fileId: file.fileId,
          fileTitle: "",
          fileSize: files[index]?.size
        }));

        /* ATTACHMENT RELATION PROMISE */
        const attachmentRelationPromises = newUploadedFiles.map((file) => {
          const body = {
            userId: userId,
            fileId: file.fileId,
            fileName: file.filename,
            fileTitle: "",
            fileSize: file?.fileSize,
            linkedId: userId,
            linkedApplicationName: userDetails?.applicationName,
            role: role_
          };
          return MakeAttachmentRelationPostApi(body);
        });

        try {
          await Promise.all(attachmentRelationPromises);
          /* DISPATCH DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error("Error POSTing attachment map: ", error);
        }

        setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
        setAreFilesSet(true);
        setIsAttachmentLoading(false);
        setAttachmentFiles([]);
      } catch (error) {
        setIsAttachmentLoading(false);
        console.error("Error uploading files: ", error);
      }
    },
    [userId, dispatch, userDetails?.applicationName, role_]
  );

  const handleAddFiles = useCallback(() => {
    if (selectedFiles.length > 0) {
      handleAttachmentUpload(selectedFiles);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [handleAttachmentUpload, selectedFiles]);

  /* HANDLE REMOVE ATTACHMENT */
  const handleRemoveAttachment = useCallback(
    async (fileId, id) => {
      try {
        const res = await DeleteAttachment(userId, fileId);
        if (res?.status === 200) {
          const result = JSON.parse(userDetails?.attachmentFileNames).filter(
            (attachment) => attachment?.fileId !== fileId
          );

          const updatedUserDetails = {
            // ...userDetails,
            attachmentFileNames: JSON.stringify(result),
          };

          try {
            await DeleteApi("Attachment Map", id);
            const remvoveLinkedAttachment = userAttachmentData
              ?.filter((att) => att?.fileId === fileId)
              .map((val) => {
                return DeleteApi("Attachment Map", val?.id);
              });
            try {
              await Promise.all(remvoveLinkedAttachment);
            } catch (error) {
              console.error("Error removing linked attachment map: ", error);
            }
            /* DISPATCH ADVANCE DATA */
            dispatch(fetchUserAttachment());
          } catch (error) {
            console.error("Error deleting attachment detail: ", error);
          }

          EditApi("User Details", userDetails.id, updatedUserDetails);

          dispatch(
            editExistingUserProfile({
              id: userDetails?.id,
              updatedData: updatedUserDetails,
            })
          );
        }
      } catch (error) {
        console.error("Error deleting attachment: ", error);
      }
    },
    [userId, dispatch, userDetails, userAttachmentData]
  );

  /* UPDATE USER DETAILS */
  useEffect(() => {
    if (areFilesSet && uploadedFiles.length > 0) {
      const updateUserDetails = async () => {
        try {
          /* PARSE EXISING FILES */
          let existingAttachments = [];
          if (userDetails.attachmentFileNames) {
            try {
              existingAttachments = JSON.parse(userDetails.attachmentFileNames);
            } catch (error) {
              console.error("Error parsing attachmentFileNames: ", error);
            }
          }

          /* COMBINE EXISING WITH THE NEW ONE */
          const updatedAttachments = [...existingAttachments, ...uploadedFiles];

          /* PAYLOAD */
          const payload = {
            attachmentFileNames: JSON.stringify(updatedAttachments),
          };

          /* EDIT THE USER DETAILS */
          await EditApi("User Details", userDetails.id, payload);

          /* UPDATE REDUX */
          dispatch(
            editExistingUserProfile({
              id: userDetails?.id,
              updatedData: payload,
            })
          );
          setUploadedFiles([]);
        } catch (error) {
          console.error("Error updating user details: ", error);
        }
      };

      /* FUNC CALL */
      updateUserDetails();
      /* RESET EDIT STATE */
      setAreFilesSet(false);
    }
  }, [areFilesSet, uploadedFiles, userDetails, dispatch]);

  return (
    <div className="menu-menu-container" ref={menuRef}>
      <ValidationModal />
      <UserProfile />
      {/* ATTACHMENT MODAL */}
      <AttachmentsModal />
      
      <button
        className={`menu-button ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <svg
          fill="#F7FFDD"
          height={"1.4em"}
          width={"1.4em"}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M0 0h4v4H0V0zm0 6h4v4H0V6zm0 6h4v4H0v-4zM6 0h4v4H6V0zm0 6h4v4H6V6zm0 6h4v4H6v-4zm6-12h4v4h-4V0zm0 6h4v4h-4V6zm0 6h4v4h-4v-4z"
              fill-rule="evenodd"
            ></path>{" "}
          </g>
        </svg>
      </button>
      {isOpen && (
        <div
          className=" card menu p-3 rounded border border-5  rounded-5 shadow "
          style={{ right: "-16rem" }}
        >
          <ul className="card-body">
            <li>
              {/* <h6 class="card-title  fs-2">Skill Profile Managment 
</h6> */}
              {/* <hr class="w-100  mb-2 mb-xl-9 border-dark-subtle" /> */}

              <div className="d-flex mt-2">
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/dashboard/skillprofile");
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={skills}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image logo-img"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MySkills"
                        ) || {}
                      ).mvalue || "nf MySkills"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="d-flex justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#validation"
                  >
                    <div class="profile-container1  me-3">
                      <img
                        src={Validation}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#validation"
                    style={{ height: "25px", width: "100px" }}
                    className="text-center "
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyValidations"
                        ) || {}
                      ).mvalue || "nf My Validations"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#setting"
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Profile}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image logo-img "
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyProfile"
                        ) || {}
                      ).mvalue || "nf My Profile"}
                    </h6>
                  </div>
                </div>

                {/* ATTACHMENTS */}
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#attachmentModal"
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1 me-3">
                      <img
                        src={AttachmentIcon}
                        width="350"
                        alt="Attachment icon"
                        className="profile-image logo-img"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Attachments"
                        ) || {}
                      ).mvalue || "nf Attachments"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/dashboard/timeline");
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      {/* <img
                        src={Profile}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image logo-img "
                      /> */}
                      <MdOutlineViewTimeline
                        style={{
                          fontSize: "55px",
                          color:
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "NavBarBgColor"
                              ) || {}
                            ).mvalue || "#000",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Timeline"
                        ) || {}
                      ).mvalue || "nf Timeline"}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul className="card-body mt-2">
            <li>
              <hr class="w-100  mb-3 mb-xl-9 border-dark-subtle" />

              <div className="d-flex mt-2">
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard/detailedprofile")}
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={employment}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyEmployments"
                        ) || {}
                      ).mvalue || "nf My Employments"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard/detailedprofile/project")}
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1   me-3">
                      <img
                        src={Projects}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyProjects"
                        ) || {}
                      ).mvalue || "nf My Projects"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/education")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Education}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyEducation"
                        ) || {}
                      ).mvalue || "nf My Education"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/certification")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Certification}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyCertifications"
                        ) || {}
                      ).mvalue || "nf My Certifications"}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/training")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Training}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyTrainings"
                        ) || {}
                      ).mvalue || "nf My Trainings"}
                    </h6>
                  </div>
                </div>

                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/conference")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Conference}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyConferences"
                        ) || {}
                      ).mvalue || "nf My Conferences"}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/skilling")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Skilling}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MySkillings"
                        ) || {}
                      ).mvalue || "nf My Skillings"}{" "}
                    </h6>
                  </div>
                </div>
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/licenses")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Licenses}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyLicenses"
                        ) || {}
                      ).mvalue || "nf My Licenses"}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/achivements")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Awards}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyAwards"
                        ) || {}
                      ).mvalue || "nf My Awards"}
                    </h6>
                  </div>
                </div>

                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/membership")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={membership}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyMemberships"
                        ) || {}
                      ).mvalue || "nf My Memberships"}
                    </h6>
                  </div>
                </div>

                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/dashboard/detailedprofile/language")
                  }
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Languagess}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MyLanguages"
                        ) || {}
                      ).mvalue || "nf My Languages"}
                    </h6>
                  </div>
                </div>

                <div
                  className="p-2 hover rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard/detailedprofile/others")}
                >
                  <div className="d-flex justify-content-center">
                    <div class="profile-container1  me-3">
                      <img
                        src={Others}
                        width="350"
                        alt="Pink Blue Logo Design Template Png"
                        className="profile-image"
                      />
                    </div>
                  </div>
                  <div
                    style={{ height: "25px", width: "100px" }}
                    className="text-center"
                  >
                    <h6>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Others"
                        ) || {}
                      ).mvalue || "nf Others"}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Menu;
