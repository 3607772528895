import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersBasedOnSkillSearch } from '../../../api/SkillSeeker/fetchUsersBasedOnSkillSearch.js';



const initialState = {
    data: [{
        AccountID: "Jansi1002",
        Email: "priyajansi2009@gmail.com",
        EmailShowHide: false,
        FNShowHide: false,
        FirstName: "JANSI RANI",
        LNShowHide: false,
        LastName: "VINOTHRAJ",
        PPShowHide: false,
        PhNoShowHide: false,
        PhoneNumber: "9659665758 ",
        location: "Chennai",
        userId: "USERD-4212"
    }],
    noOfResult: 0,
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
}






const SkillBasedResultSlice = createSlice({
    name: "SkillBasedResult",
    initialState: {
        data: [],
        noOfResult: 0,
        status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
        error: null,
    },
    reducers: {
        setSkillSearchResult: (state, action) => {

            state.data = [action.payload.data];

        },
        //   logout reset to initial state
        emptySkillSearchResult: (state) => {
            return initialState;
        },
        setAcquiredSkills: (state, action) => {
            const { userId, acquiredSkillData } = action.payload;
            const index = state.data.findIndex(obj => obj.userId === userId);

            // If the object with the given userId is found
            if (index !== -1) {
                console.log("Object with id axquired", acquiredSkillData, " found.");

                if (acquiredSkillData?.length > 0) {
                    acquiredSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }



                // Create a copy of the object with the additional field added
                state.data[index] = { ...state.data[index], skillacq: acquiredSkillData };
                console.log(state.data);
            } else {
                console.log("Object with id axquired", acquiredSkillData, " not found.");
            }
        },
        setAppliedSkills: (state, action) => {
            const { userId, appliedSkillData } = action.payload;
            const index = state.data.findIndex(obj => obj.userId === userId);

            // If the object with the given userId is found
            if (index !== -1) {

                if (appliedSkillData?.length > 0) {
                    appliedSkillData?.map((datas) => {
                        return {
                            ...datas,
                            validation: datas?.ValidationData?.length > 0 ? "Yes" : "No"
                        }
                    })
                }

                console.log(appliedSkillData);
                // Create a copy of the object with the additional field added
                state.data[index] = { ...state.data[index], skillapp: appliedSkillData };
                console.log("Object with id applied", appliedSkillData, "  found.");
                console.log("indx ", state.data[index].skillapp);
            } else {
                console.log("Object with id applied", appliedSkillData, " not found.");
            }
        },
        setDetailedProfileData: (state, action) => {
            console.log("action of detailed page ", action.payload);
            const { userID } = action.payload;
            if (userID === null) {
                return;
            }
            const index = state.data.findIndex(obj => obj.userId === action.payload.userID);

            // If the object with the given userId is found
            if (index !== -1) {
                console.log("All details of user", action.payload, " found.");

                if (action.payload) {
                    state.data[index] = { ...state.data[index], detailedProfileData: action.payload };

                }

                // Create a copy of the object with the additional field added

            } else {
                console.log("Object with id axquired", userID, " not found.");
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersBasedOnSkillSearch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchUsersBasedOnSkillSearch.fulfilled, (state, action) => {
                let data = action.payload.data;
                data = JSON.parse(JSON.stringify(data));
                if (data.availalbeSkills && Array.isArray(data.availalbeSkills)) {
                    data = data.availalbeSkills.sort((a, b) => (a.Rank || 0) - (b.Rank || 0));

                }
                state.noOfResult = data[data.length-1].totalCount;
                data = data.slice(0,data.length-1);
                state.data = data;
                state.status = "success";
                

                state.error = null;
            })
            .addCase(fetchUsersBasedOnSkillSearch.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });
    },

});

export const { setSkillSearchResult, emptySkillSearchResult, setAcquiredSkills, setAppliedSkills, setDetailedProfileData } = SkillBasedResultSlice.actions;
export default SkillBasedResultSlice.reducer;



