import React, { useEffect, useState } from 'react'
import ShowHideIcon from '../../ShowHideIcon'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import DeleteApi from '../../../api/DeleteData/DeleteApi'
import { useDispatch } from 'react-redux'
import { ProjectDelete, projectUpdate } from '../../../reducer/detailedProfile/projectSlice'
import { showSuccessToast } from '../../ToastNotification/showSuccessToast'
import { showErrorToast } from '../../ToastNotification/showErrorToast'
import { DayDifferenceToDynamicView } from '../HelperFunction/DayDifferenceToDynamicView'
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference '
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import Validation from '../../ValidationForm';
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setValidationData } from '../../../reducer/validation/validationSlice'
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments'
import AttachmentForm from '../../AttachmentForm'
import Anonimization from '../../Anonimization'
import { setDetailedProfileEditData } from '../../../reducer/edit/detailedProfileEditSlice'
import EditDetailedProfile from '../Forms/Edit Forms/EditDetailedProfile'
import DeleteFormDetailedProfile from '../../DeleteFormDetailedProfile'
import { setDeleteDetailedProfileData } from '../../../reducer/delete/deleteDetailedProfileSlice'
import { BiSolidInfoCircle } from 'react-icons/bi'


const ProjectSummary = ({ data, editEnable }) => {

    const dispatch = useDispatch();
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    const handleAttachment = (selectedData) => {
        dispatch(setSkillOwnerAttachment(selectedData));
    };

    const handleDelete = (id) => {
        DeleteApi("Project  History", id).then((res) => {
            if (res.data.deletedStatus === "true") {
                dispatch(ProjectDelete(id));
                showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'DataDeletedSuccessfully') || {}).mvalue || "nf Data Deleted Successfully");
            } else {
                showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'YouAlreadyDeletedData') || {}).mvalue || "nf You already deleted this data");
            }
        }).catch((err) => {
            console.error(err);
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'ErrorDeletingData') || {}).mvalue || "nf Error in deleting data");
        });

    }

    /* DATA SENDING FOR VALIDATION */
    const handleValidate = (data) => {
        dispatch(setValidationData(data));
    };

    /* CHANGE VALIDATION RESULTS */
    const {
        getUserValidation: { userValidationData },
    } = useSelector((state) => state);

    useEffect(() => {
        /* CHECK FOR MATCHING ID AND CAPTURE DATA */
        data?.data.forEach(dataItem => {
            userValidationData.forEach(validationItem => {
                if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
                    dispatch(projectUpdate({ id: dataItem.id, validation: "Yes" }));
                }
            });
        });
    }, [data, userValidationData, dispatch]);

    const handleEdit = (data) => {
        dispatch(setDetailedProfileEditData(data));
    }

    return (

        <>
            {/* VALIDATION MODAL */}
            <Validation />
            {<AttachmentForm />}
            {/* DETAILED PROFILE EDIT MODAL */}
            {<EditDetailedProfile />}
            <DeleteFormDetailedProfile />
            {/* table start */}
            <div className="table-responsive ">
                <table className='table table-sm   table-fixed table-hover    '>
                    <thead >
                        <tr className='border-dark-subtle border-bottom '>
                            <th scope="col" className='bg-body- ' style={{ width: "32%" }}>
                                {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectName') || {}).mvalue || "nf Project Name"}
                                <span className='ms-1 mb-1' data-tooltip-id="my-tooltip" data-tooltip-content={(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDescription') || {}).mvalue || "nf ProjectDescription"}><BiSolidInfoCircle className='text-muted' style={{ fontSize: '20px' }} /></span>
                            </th>
                            <th scope="col" className='bg-body- ' style={{ width: "19%" }}>
                                {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "nf Organization"}
                            </th>
                            <th scope="col" className='bg-body- ' style={{ width: "13%" }}>
                                {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "nf Duration"}
                            </th>
                            <th scope="col" className='bg-body- ' style={{ width: "14%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf Location"}      </th>
                            <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf Validation"}    </th>
                            <th scope="col" className='bg-body- ' style={{ width: "10%" }}>              </th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {data.data.map((proj, index) => (
                            <>
                                {proj.mlanguage === selectedLanguage &&
                                    <tr key={index} className=''>
                                        <td >{proj.projectActivity ? (proj.projectActivity.length > 17 ? proj.projectActivity.substring(0, 17) + "..." : proj.projectActivity) : ''}</td>
                                        <td >{proj.organization ? (proj.organization.length > 17 ? proj.organization.substring(0, 17) + "..." : proj.organization) : 'NA'}</td>
                                        <td > {DayDifferenceToDynamicView(
                                            proj.duration
                                        )}</td>
                                        <td data-tooltip-id="my-tooltip" data-tooltip-content={proj.location} >{proj.location ? (proj.location.length > 12 ? proj.location.substring(0, 12) + "..." : proj.location) : ''}</td>
                                        <td className=' '>{proj.validation}</td>
                                        <td className='d-flex justify-content-between   ' style={{ minHeight: "26px" }}>

                                            {editEnable ? (
                                                <>
                                                    {/* <div ><ShowHideIcon value={true} /></div> */}
                                                    <div data-bs-toggle="modal" data-bs-target="#detailedProfileEditModal" id="addModalBtn"><button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                                        onClick={() => handleEdit(proj)}
                                                    ><MdEdit /></button></div>
                                                    <ShowHideIcon value={proj.recordHide === 'Yes' ? true : false} toggleable={true}
                                                        form={proj} fieldName={"recordHide"} api={"Skills Applied"} id={proj.id} />
                                                    <Anonimization value={proj.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                                                        form={proj} fieldName={"recordAnonymous"} api={"Skills Applied"} id={proj.id} />
                                                    {/* <div className='' onClick={() => dispatch(setDeleteDetailedProfileData(proj))}><MdDelete /></div> */}
                                                    <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                                        dispatch(setDeleteDetailedProfileData(proj))
                                                    }}>  <MdDelete /> </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="border-0 bg-white"
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Validate"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#validationForm"
                                                        onClick={() => handleValidate(proj)}
                                                    >
                                                        <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                                                    </button>
                                                    <button
                                                        className="border-0 bg-white"
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Attachment"
                                                    >
                                                        <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Attachment" onClick={() => handleAttachment(proj)} data-bs-toggle="modal" data-bs-target="#attachmentList">
                                                            <ImAttachment />
                                                        </button>
                                                    </button>
                                                </>

                                            )}
                                        </td>
                                    </tr>}
                            </>
                        )
                        )
                        }
                    </tbody>

                </table>
            </div>
            {/* table end */}


        </>


    )
}

export default ProjectSummary