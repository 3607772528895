import React, { useCallback } from "react";
import { GetAttachment } from "../../api/Attachment  API/DownloadAttachmentApi";
import { MdOpenInNew } from "react-icons/md";
import SecondaryBtnLoader from "../Buttons/SecondaryBtnLoader";

const AttachmentSelectCard = ({
  userAttachmentData,
  userDetails,
  content,
  selectedLanguage,
  selectedAttachments,
  setSelectedAttachments,
  loading,
  setLoading,
  FaTimes,
  setShowAttachmentCard,
  role_,
}) => {
  /* HANDLE CHECKBOX CHANGE */
  const handleAttachmentsCheckboxChange = useCallback(
    (attachment) => {
      setSelectedAttachments((prevSelected) =>
        prevSelected?.some((item) => item.fileName === attachment.fileName)
          ? prevSelected.filter((item) => item.fileName !== attachment.fileName)
          : [...prevSelected, attachment]
      );
    },
    [setSelectedAttachments]
  );
  return (
    <React.Fragment>
      <div
        className="modal d-flex justify-content-center"
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1050,
        }}
      >
        <div
          className="modal-dialog"
          style={{
            maxWidth: "600px",
            minWidth: "40vw",
            color: "#000",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-content"
            style={{
              boxShadow: "0 5px 15px rgba(87, 113, 38, 0.5)",
              marginTop: "5rem",
            }}
          >
            <div className="card">
              <div className="card-title d-flex justify-content-between align-items-center p-2">
                <div className="fs-4">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Attachments"
                    ) || {}
                  ).mvalue || "nf Attachments"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowAttachmentCard(false)}
                >
                  <FaTimes />
                </div>
              </div>
              <div className="card-body">
                <div>
                  {userAttachmentData &&
                  userAttachmentData?.filter(
                    (att) =>
                      att?.linkedApplicationName === "User Details" &&
                      att?.roleName === role_
                  )?.length > 0 ? (
                    userAttachmentData
                      ?.filter(
                        (att) =>
                          att?.linkedApplicationName === "User Details" &&
                          att?.roleName === role_
                      )
                      .map((attachment, index) => (
                        <React.Fragment key={index}>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-evenly align-items-center gap-2 order-1">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleAttachmentsCheckboxChange(attachment)
                                }
                              >
                                {attachment?.fileName}
                              </div>
                              <div>
                                <a
                                  rel="noreferrer"
                                  href={GetAttachment(
                                    userDetails?.id,
                                    attachment?.fileName,
                                    attachment?.fileId
                                  )}
                                  target="_blank"
                                >
                                  <MdOpenInNew
                                    className=""
                                    style={{
                                      color: "#577126",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                </a>
                              </div>
                            </div>
                            <div class="form-check form-check-inline order-0">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="attachments"
                                id={`attachment${index}`}
                                checked={selectedAttachments.some(
                                  (item) =>
                                    item.fileName === attachment.fileName
                                )}
                                onChange={() =>
                                  handleAttachmentsCheckboxChange(attachment)
                                }
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                  ) : (
                    <div className="text-center text-success mb-2">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NoAttachmentsFound"
                        ) || {}
                      ).mvalue || "nf No Attachments Found"}
                    </div>
                  )}
                </div>
              </div>

              {/* FUNC BUTTONS */}
              <div className="card-footer d-flex justify-content-end">
                <div className="d-flex">
                  <div className="d-flex flex-grow-1 gap-2">
                    <SecondaryBtnLoader
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Link"
                          ) || {}
                        ).mvalue || "nf Link"
                      }
                      onClick={() => {
                        setLoading(true);
                        setShowAttachmentCard(false);
                      }}
                      backgroundColor="#815F0B"
                      color="#F8F8E9"
                      // loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttachmentSelectCard;
