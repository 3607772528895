/* NOTE: THIS NAVBAR COMPONENT CREATED TO USE WHEN THE FUNC INCLUDED WITHOUT LOGIN {DEV: SHARMAL} */

import React from "react";
import { useSelector } from "react-redux";
import logo from "../../Images/logo.png";
import LanguageComponent from "../LanguageComponent";
import CountryFlagComponent from "../Country Flag/CountryFlagComponent";

const NavbarComponent = ({
  isNewLoginPage,
  Country,
  setCountry,
  isDisable,
}) => {
  /* STORE IMPORTS */
  const { language: selectedLanguage, content } = useSelector((state) => state);
  return (
    <nav
      style={{
        color:
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "navBarFontColor"
            ) || {}
          ).mvalue || "#F7FFDD",
        backgroundColor:
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "navBarBgColor"
            ) || {}
          ).mvalue || "#577126",
        direction: content[selectedLanguage].direction,
      }}
      className="navbar navbar-expand-lg d-print-none p-0 m-0 position- w-100"
    >
      <div className="d-flex container-fluid">
        <div className="flex-1">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src={logo}
              alt="Logo"
              width="38"
              height="38"
              className="d-inline-block bg-img"
            />
            <div
              className="px-1 font-weight-1 font-1"
              style={{
                color:
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "navBarFontColor"
                    ) || {}
                  ).mvalue || "#F7FFDD",
                direction: content[selectedLanguage].direction,
              }}
            >
              MySkillsTree
            </div>
          </a>
        </div>

        <div className="flex-1">
          <div className="d-flex align-items-center" style={{ gap: "2em" }}>
            {/* COUNTRY FLAG COMPONENT */}
            {isNewLoginPage && (
              <div
                className="d-flex align-items-center"
                style={{ pointerEvents: isDisable ? "none" : "" }}
              >
                <CountryFlagComponent
                  Country={Country}
                  setCountry={setCountry}
                />
              </div>
            )}

            {/* LANGUAGE COMPONENT */}
            <div className="d-flex align-items-center">
              <LanguageComponent />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
