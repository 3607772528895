import React from 'react'
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SkillingAgencyRightSidebar = () => {
    // store imports
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);

    const location = useLocation().pathname;
    console.log(location);

    return (
        <>
            {content.status === "succeeded" && <>

                <div class="container-fluid h-100 " style={{maxHeight:'calc(-60px + 96vh)'}}>

                    <div class="row h-50">
                        <div class="col-sm-12 px-0 ">
                            <table className='table table-sm table-hover' >
                                <thead>
                                    <tr>
                                        <div className="mt-2  rounded-top  " style={{ backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126", height: "36px" }}>
                                            <div className='text h6 text-center   ' style={{ padding: "6px 0", color: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD" }} >{(content[selectedLanguage]?.find(item => item.elementLabel === 'Trending') || {}).mvalue || "not found"}</div>
                                        </div>
                                    </tr>
                                </thead>

                                <tbody >
                                    <tr>
                                        <td>Brand Management</td>
                                    </tr>
                                    <tr>
                                        <td>Sales Development</td>
                                    </tr>
                                    <tr>
                                        <td>Solution Selling</td>
                                    </tr>
                                    <tr>
                                        <td>Sales Training</td>
                                    </tr>
                                    <tr>
                                        <td>Google Ads </td>
                                    </tr>
                                </tbody>


                            </table>
                        </div>
                    </div>


                    <div class="row h-50">
                        <div class="col-sm-12 px-0">
                            <table className='table  table-sm table-hover'>
                                <thead>
                                    <tr>
                                        <div className="   rounded-top " style={{ backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126", height: "36px" }}>
                                            <div className='text h6 text-center   ' style={{ padding: "6px 0", color: (content[selectedLanguage]?.find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD" }} >{(content[selectedLanguage]?.find(item => item.elementLabel === 'ActiveRecruiters') || {}).mvalue || "not found"}</div>
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Amazon</td>
                                    </tr>
                                    <tr>
                                        <td>Google</td>
                                    </tr>
                                    <tr>
                                        <td>Facebook</td>
                                    </tr>
                                    <tr>
                                        <td>Microsoft</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default SkillingAgencyRightSidebar
