import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const fetchRoles = createAsyncThunk("roles/fetchData", async () => {
  try {
    const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-master-data/Role Map`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
});


const RoleMappingSlice = createSlice({
  name: "roles",
  initialState: {
    status: "idle",
    error: null,
    data: [],
    userRoles: [],
  },
  reducers: {
    addNewRole: (state, action) => {
      state.userRoles = [...state?.userRoles, action.payload];
      console.log("new role added ", state.userRoles);

    },
    setUserRoles: (state, action) => {

      const filterr = action.payload.filter(obj => ['R1', 'R2', 'R3', 'R4', 'R5'].includes(obj.roleName));
      const filter = filterr.map(obj => {

        switch (obj.roleName) {
          case 'R1':
            return {
              ...obj,
              roleId: 'Skill Owner',

            }
            break;
          case 'R2':

            return {
              ...obj,
              roleId: 'Skill Seeker',

            }
            break;
          case 'R3':

            return {
              ...obj,
              roleId: 'Skilling Agency',

            }
            break; // Add your conditions for other roles similarly
          case 'R4':

            return {
              ...obj,
              roleId: 'Skill Validator',

            }
            break;
          case 'R5':

            return {
              ...obj,
              roleId: 'Government Agency',

            }
            break;
          case 'R6':

            return {
              ...obj,
              roleId: 'Support Services'

            }
            break;
          default:
            break;
        }
        return true;

      }).filter(obj => obj !== null);
      state.userRoles = filter;
      console.log("user roles ", state.userRoles);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});



export const { setLanguageContent, setUserRoles, addNewRole } = RoleMappingSlice.actions;
export default RoleMappingSlice.reducer;
