import React from 'react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { IoLanguage, IoLocation } from 'react-icons/io5'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'

export default function FeaturedServiceProvider({name,location,language,cost,currency,}){
  return (
    <article class="postcard light blue mb-5" >
    <div class="postcard__text p-4 t-dark">
      <div className="d-flex justify-content-between">
        <div>
          <h1 class="postcard__title blue mb-0">
            <a style={{cursor:"pointer"}}>{name}</a>
          </h1>
          <div class="postcard__subtitle small">
            <time
              datetime="2020-05-25 12:00:00"
              class="mr-2"
            ></time>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="content text-center">
            <div class="ratings">
              <span class="product-rating p-0">4.6</span>
              <span>/5</span>
              <div class="stars">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="rating-text">
                <span>46 ratings & 15 reviews</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="postcard__bar"></div> */}
      <div class="postcard__preview-txt mt-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eligendi, fugiat asperiores inventore beatae accusamus
        odit minima enim, commodi quia, doloribus eius! Ducimus
        nemo accusantium maiores velit corrupti tempora reiciendis
        molestiae repellat vero. Eveniet ipsam adipisci illo iusto
        quibusdam, sunt neque nulla unde ipsum dolores nobis enim
        quidem excepturi, illum quos!
      </div>
      <ul class="postcard__tagbox my-2">
        <li
          class="tag__item d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "#577126", color: "white" }}
        >
          <IoLocation
            style={{ fontSize: "17px", marginRight: "10px" }}
          />
          {location}
        </li>
        <li
          class="tag__item d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "#577126", color: "white" }}
        >
          <IoLanguage
            style={{ fontSize: "17px", marginRight: "10px" }}
          />{" "}
          {language}
        </li>
        <li
          class="tag__item d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "#577126", color: "white" }}
        >
          <FaMoneyBillAlt
            style={{ fontSize: "17px", marginRight: "10px" }}
          />
          <div class="mb-0 yellow">
            <span>{cost},</span>
          </div>
          <span>{currency}</span>
        </li>

      </ul>
    </div>
    <a class="postcard__img_link">
      <img
        class="postcard__img"
        src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1704366309/Cartoon_tiny_young_programmers_and_coders_working_with_computers_xr7gjf.jpg"
        alt="Image Title"
      />
    </a>
  </article>
  )
}
  

