import React, { useEffect, useState } from "react";

const ResumeFooter = ({
  footerColor,
  fontColor,
  backgroundImage,
  content,
  selectedLanguage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  /* CSS VARIABLE FOR DYNAMIC STYLING */
  // useEffect(() => {
  //   document.documentElement.style.setProperty("--selected-color", footerColor);
  //   document.documentElement.style.setProperty("--font-color", fontColor);
  //   document.documentElement.style.setProperty(
  //     "--selected-bg-img",
  //     backgroundImage
  //   );
  // }, [footerColor, fontColor, backgroundImage]);

  /* CALCULATING CURRENT PAGE AND TOTAL PAGE */
  useEffect(() => {
    const calculatePages = () => {
      const currentPage = Math.ceil(
        ((window.pageYOffset + window.innerHeight) /
          document.body.scrollHeight) *
          totalPages
      );
      setCurrentPage(currentPage);
    };

    const handlePrint = () => {
      setTotalPages(window.totalPages || 1);
      setCurrentPage(window.currentPage || 1);
    };

    window.addEventListener("scroll", calculatePages);
    window.addEventListener("beforeprint", handlePrint);

    return () => {
      window.removeEventListener("scroll", calculatePages);
      window.removeEventListener("beforeprint", handlePrint);
    };
  }, [totalPages]);

  /* SET TOTAL PAGES ON INITIAL LOAD */
  useEffect(() => {
    const totalPages = Math.ceil(
      document.body.scrollHeight / window.innerHeight
    );
    setTotalPages(totalPages);
  }, []);

  return (
    <React.Fragment>
      <footer
        id="resume_footer_id"
        className="resume-footer no-page-break rounded-1 mb-3"
        // className="resume-header_footer rounded-1 mb-2"
        style={{
          width: "96%",
          marginLeft: "14px",
          backgroundColor: "rgba(0, 0, 0, 0.125)",
          // backgroundColor: `${footerColor}`,
          color: `${fontColor}`,
          backgroundImage: `url(${backgroundImage})`,
          padding: "5px"
        }}
      >
        <div
          id="footer"
          color="#808080"
          // style={{ paddingLeft: "1rem" }}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <div style={{ fontStyle: "italic", fontSize: "12px" }}>
              Copyright &copy; MySkillsTree <sup>TM</sup>
            </div>
          </div>
          <div className="footer-page" style={{ fontStyle: "italic" }}>
            <div className="page-number">
              {/* {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Page"
                ) || {}
              ).mvalue || "nf Page"}{" "}
              {currentPage} of {totalPages} */}
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default ResumeFooter;
