import React, { useCallback, useEffect, useRef, useState } from "react";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { fetchCertificationHistory } from "../../../../api/fetchAllData/fetchCertificationHistory";
import CreateSelect from "../../SelectComponent/CreateSelect";
import { fetchTrainingHistory } from "../../../../api/fetchAllData/fetchTrainingHistory";
import { fetchDataSkillsAcquired } from "../../../../api/fetchAllData/fetchDataSkillsAcquired";

const EditDetailedProfileCertificationandTraining = () => {
  /* DISPATCH */
  const dispatch = useDispatch();

  /* STORE IMPORTS */
  const {
    detailedProfileEdit: detailedProfileCertandTrainingEditData,
    language: selectedLanguage,
    content,
    regionalData
  } = useSelector((state) => state);

  /* STATE INIT */
  const [desc, setDesc] = useState(
    detailedProfileCertandTrainingEditData?.briefDescription || ""
  );
  const [cert, setCert] = useState(
    detailedProfileCertandTrainingEditData?.certificationName || ""
  );
  const [organizationName, setOrganizationName] = useState(
    detailedProfileCertandTrainingEditData?.organization || ""
  );
  const [titleName, setTitleName] = useState(
    detailedProfileCertandTrainingEditData?.title || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* FORMAT THE DATES */
  const formattedStartDate =
    detailedProfileCertandTrainingEditData?.startDate &&
      detailedProfileCertandTrainingEditData?.startDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileCertandTrainingEditData?.startDate)
      : formatTimestampToDate(
        Number(detailedProfileCertandTrainingEditData?.startDate),
        regionalData?.selectedCountry?.dateFormat
      );
  const formattedEndDate =
    detailedProfileCertandTrainingEditData?.endDate &&
      detailedProfileCertandTrainingEditData?.endDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileCertandTrainingEditData?.endDate)
      : formatTimestampToDate(
        Number(detailedProfileCertandTrainingEditData?.endDate),
        regionalData?.selectedCountry?.dateFormat
      );

  /* HANDLE EDIT SUBMIT */
  const handleEditProjectDetail = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);

      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescription: desc,
        },
        formNames: [],
      };

      if (
        detailedProfileCertandTrainingEditData?.applicationName ===
        "Certification History"
      ) {
        payload.formNames.push("Skills Acquired");
        payload.formNames.push("Certification History");
        payload.editData.certificationName = cert;
        payload.editData.source = cert;
        payload.skillsAcquired = {
          certificationName: cert,
          source: cert
        };
        payload.certificationHistory = {
          certificationName: cert,
          source: cert
        }
      } else if (
        detailedProfileCertandTrainingEditData?.applicationName === "Training"
      ) {
        payload.formNames.push("Skills Acquired");
        payload.formNames.push("Training");
        payload.editData.title = titleName;
        payload.editData.source = titleName;
        payload.skillsAcquired = {
          source: titleName,
        };
        payload.training = {
          source: titleName,
        }
      }

      try {
        await EditSkillsApi(
          `${detailedProfileCertandTrainingEditData?.applicationName}`,
          detailedProfileCertandTrainingEditData?.id,
          payload
        );
        setIsSendingData(false);
        /* DISPATCH */
        if (
          detailedProfileCertandTrainingEditData?.applicationName ===
          "Certification History"
        ) {
          dispatch(fetchCertificationHistory());
          dispatch(fetchDataSkillsAcquired());
        } else if (
          detailedProfileCertandTrainingEditData?.applicationName === "Training"
        ) {
          dispatch(fetchTrainingHistory());
          dispatch(fetchDataSkillsAcquired());
        }

        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      titleName,
      dispatch,
      detailedProfileCertandTrainingEditData,
      handleModalClose,
      cert,
    ]
  );

  useEffect(() => {
    setDesc(detailedProfileCertandTrainingEditData?.briefDescription);
    if (
      detailedProfileCertandTrainingEditData?.certificationName &&
      detailedProfileCertandTrainingEditData?.organization
    ) {
      setCert(detailedProfileCertandTrainingEditData?.certificationName);
      setOrganizationName(detailedProfileCertandTrainingEditData?.organization);
    } else if (
      detailedProfileCertandTrainingEditData?.title &&
      detailedProfileCertandTrainingEditData?.organization
    ) {
      setTitleName(detailedProfileCertandTrainingEditData?.title);
      setOrganizationName(detailedProfileCertandTrainingEditData?.organization);
    } else if (detailedProfileCertandTrainingEditData?.organizationName) {
      setOrganizationName(detailedProfileCertandTrainingEditData?.organization);
    }
  }, [detailedProfileCertandTrainingEditData]);

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="detailedProfileEditCertandTrainingModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {detailedProfileCertandTrainingEditData?.applicationName ===
                  "Certification History"
                  ? (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Certifications"
                    ) || {}
                  ).mvalue || "nf Certification"
                  : (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "TrainingHistory"
                    ) || {}
                  ).mvalue || "nf TrainingHistory"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span> {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                {/* DETAILED PROFILE EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-1">
                      {["Training"].includes(
                        detailedProfileCertandTrainingEditData?.applicationName
                      ) ? (
                        <div>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "NameOfTheCertification"
                            ) || {}
                          ).mvalue || "nf Name OF The Certification"}
                          <input
                            type="text"
                            name="course"
                            className="form-control text-dark mt-2"
                            style={{ borderColor: "#ccc" }}
                            placeholder={
                              detailedProfileCertandTrainingEditData?.applicationName ===
                                "Certification History"
                                ? setCert
                                : detailedProfileCertandTrainingEditData?.course
                            }
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "NameOfTheCertification"
                            ) || {}
                          ).mvalue || "nf NameOFTheCertification"}
                          <input
                            type="text"
                            name="course"
                            className="form-control text-dark mt-2"
                            style={{ borderColor: "#ccc" }}
                            placeholder={
                              detailedProfileCertandTrainingEditData?.applicationName ===
                                "Certification History"
                                ? setCert
                                : detailedProfileCertandTrainingEditData?.course
                            }
                            value={
                              detailedProfileCertandTrainingEditData?.applicationName ===
                                "Certification History"
                                ? cert
                                : detailedProfileCertandTrainingEditData?.course
                            }
                            onChange={(e) => setCert(e.target.value)}
                          />
                        </div>
                      )}

                      {/* ORGANIZATIOn */}
                      {detailedProfileCertandTrainingEditData?.institute && (
                        <div className="mt-2" style={{ pointerEvents: "none" }}>
                          Institution:
                          <div className="mt-2">
                            <CreateSelect
                              newField="orgIsnew"
                              placeholder={
                                detailedProfileCertandTrainingEditData?.institute
                              }
                              setNewField="organization"
                              value={
                                detailedProfileCertandTrainingEditData?.institute
                              }
                            />
                          </div>
                        </div>
                      )}

                      {/* ORGANIZATION */}
                      {detailedProfileCertandTrainingEditData?.organization && (
                        <div
                          className="mt-2"
                          style={{
                            pointerEvents: "none",
                          }}
                        >
                          {/* Organization: */}
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "InstituteCollegeUniversity"
                            ) || {}
                          ).mvalue || "nf Institute/College/University"}
                          <div className="mt-2">
                            <input
                              type="text"
                              name="organizationName"
                              className="form-control text-secondary bg-body-tertiary"
                              style={{ borderColor: "#ccc" }}
                              placeholder={organizationName}
                              value={organizationName}
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className="d-lg-flex my-2 w-100">
                        <div className=" h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjecStart"
                              ) || {}
                            ).mvalue || "nf Project Start"}
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={timestampToYYYYMMDD(Number(detailedProfileCertandTrainingEditData?.startDate))}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjectEnd"
                              ) || {}
                            ).mvalue || "nf Project End"}
                          </label>
                          <input
                            type="date"
                            min={
                              detailedProfileCertandTrainingEditData?.endDate
                            }
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={detailedProfileCertandTrainingEditData?.endDate ? timestampToYYYYMMDD(Number(detailedProfileCertandTrainingEditData?.endDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}
                      {detailedProfileCertandTrainingEditData?.location && (
                        <div className="my-2" style={{ pointerEvents: "none" }}>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "not found"}
                          </label>

                          <div className="d-flex gap-2">
                            <div className="p-1 text-secondary rounded bg-light border">
                              {detailedProfileCertandTrainingEditData?.location}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "BriefDescriptionAboutOrganization"
                            ) || {}
                          ).mvalue || "nf Brief Description about Organization"}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescription"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditProjectDetail("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditProjectDetail("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditDetailedProfileCertificationandTraining;
