import React from 'react'

const PremiumService = () => {
    return (
        <>
            <div class="container text-center mt-5 secondary-brown-text">
                <h1>Premium Services Coming Soon</h1>
                <p>We're working on something awesome! Stay tuned.</p>
            </div>
        </>
    )
}

export default PremiumService