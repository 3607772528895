import React, { useEffect, useState } from 'react'
// import { myCourses } from '../../../pages/SkillingAgency/SkillingAgencyConstants'
import { useNavigate } from 'react-router-dom'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import ShortableItem from './ShortableItem';
import { useSelector } from 'react-redux';


const Top5Courses = ({userCourses,status}) => {
 

  const [top5Course,setTop5Course] = useState(userCourses)
    


  useEffect(()=>{

    setTop5Course(userCourses)

  },[userCourses,status])

  
    const navigate = useNavigate()



    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 8,
        },
      }),

      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    })
    )
    


    // Function to compare two objects for equality
function isEqual(obj1, obj2) {
  // You may need to implement a custom equality check based on your object structure
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
  const handleDragEnd=(event)=>{
         const{active,over}= event;


        if(active.id.id !== over.id.id){
          setTop5Course((prev)=>{
   
            const activeIndex = prev.findIndex(obj => isEqual(obj, active.id));
            const overIndex = prev.findIndex(obj => isEqual(obj, over.id));
            return arrayMove(prev,activeIndex,overIndex)
  
          })

         }
         
  }



  return (
    <DndContext 
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragEnd={handleDragEnd}
  >

    <SortableContext items={top5Course} strategy={verticalListSortingStrategy}>

    
    {
        top5Course?.map((course, i) => {
                    return (
                     
                       <ShortableItem key={course} id={course} i={i} course={course}  />

                     

                    )
                })
            }


    </SortableContext>














    </DndContext>
  )
}

export default Top5Courses
