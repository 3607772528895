import React, { useEffect, useState } from 'react'
import { fetchValidation } from '../../../reducer/validation/getUserValidationSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatTimestampToDate } from '../HelperFunction/FormatTimestampToDate';
import { BASE_URL } from '../../../config/Properties';

const ValidationDataComponent = ({ itemId, res = false }) => {

    const dispatch = useDispatch();

    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const SkillSelected = useSelector(state => state.SkillSelected.skillOccupation);
    const [validationReqData, setValidationReqData] = useState([]);
    const [count, setCount] = useState(0);
    const regionalData = useSelector(state => state.regionalData);

    const {
        getUserValidation: { userValidationData },
        SkillsApplied,
        SkillsAcquired,
    } = useSelector((state) => state);

    useEffect(() => {
        console.log("checker ", itemId);
        /* STORE THE FILTERED VALUE FOR VALIDATION REQUEST TABLE */
        const filterValidationReqData = userValidationData?.filter(

            (val) => val?.requestorItemId === itemId
        );
        const filterHiddenValidation = filterValidationReqData?.filter(

            (val) => val?.recordHide !== "Yes"
        );
        console.log("checker all ", userValidationData);
        console.log("checker foll ", filterValidationReqData);
        setValidationReqData(filterHiddenValidation);
        let c = 0;
        validationReqData.map((validatior) => (
            validatior.recordHide !== 'Yes'));
    }, [selectedLanguage, content, userValidationData, itemId]);



    const token = localStorage.getItem("token");




    return (

        <>

            {validationReqData && validationReqData?.length > 0 &&

                <React.Fragment>
                    {
                        res == false ?
                            <React.Fragment>
                                <p className='mt-2 ms-1'><b>{(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "MyValidations"
                                    ) || {}
                                ).mvalue || "nf MyValidation"}</b></p>
                                <table className="table table-hover ms-2 " style={{ marginTop: "-15px" }} >
                                    <thead
                                        style={{ position: "sticky", top: "0", zIndex: "2" }}
                                    >
                                        <tr>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "DateSent") ||
                                                {}).mvalue || "nf DateSent"}
                                            </th>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjeValidator") ||
                                                {}).mvalue || "nf ProjeValidator"}</th>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectValidatorRelationship") ||
                                                {}).mvalue || "nf Relationship"}</th>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectValidatorEmail") ||
                                                {}).mvalue || "nf ProjectValidatorEmail"}</th>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectValidatorPhone") ||
                                                {}).mvalue || "nf ProjectValidatorPhone"}</th>
                                            <th className="fw-bold">{(content[selectedLanguage].find((item) => item.elementLabel === "ValidationStatus") ||
                                                {}).mvalue || "nf ValidationStatus"}</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderBottomStyle: "hidden" }}>
                                        {
                                            validationReqData.map((validatior, index) => (
                                                validatior.recordHide !== 'Yes' &&

                                                <React.Fragment key={validatior.id}>

                                                    <tr
                                                        style={{
                                                            cursor: "pointer",
                                                            pointerEvents:
                                                                `${validatior?.validatorResponse ===
                                                                    "Request Expired" ? "none" : ""}`,
                                                            opacity: `${validatior?.validatorResponse ===
                                                                "Request Expired"
                                                                ? "0.5"
                                                                : ""
                                                                }`,


                                                        }}

                                                    >
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {formatTimestampToDate(
                                                                Number(validatior?.dateSent),
                                                                regionalData.selectedCountry.dateFormat
                                                            )}
                                                        </td>
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {validatior.validatorName}
                                                        </td>
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {validatior.relationship}
                                                        </td>
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {validatior.validatorEmail}
                                                        </td>
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {validatior.validatorPhone
                                                                ? validatior.validatorPhone
                                                                : "N/A"}
                                                        </td>
                                                        <td
                                                        // onClick={() =>
                                                        //     showMoreDetails(validatior.id)
                                                        // }
                                                        >
                                                            {validatior?.validatorResponse ===
                                                                "Request Sent" ||
                                                                validatior?.validatorResponse ===
                                                                "Request Expired"
                                                                ? validatior?.validatorResponse
                                                                : validatior?.validatorResponse ===
                                                                    "Resend"
                                                                    ? "Resent"
                                                                    : `${validatior?.validatorResponse}ed`}
                                                        </td>

                                                    </tr>

                                                    <tr style={{ borderTopStyle: "hidden" }}>
                                                        <td colSpan="7">

                                                            <div
                                                                className=" d-flex"

                                                            >

                                                                <div className="col-12">
                                                                    <p>
                                                                        <span><b>Validator's Remark</b></span>:{" "}
                                                                        {validatior?.validatorComments
                                                                            ? validatior?.validatorComments
                                                                            : "No Remarks from validator"}
                                                                    </p>

                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>


                                                </React.Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </React.Fragment>
                            :
                            <div className="ms-0">
                                <p className='mt-1  ' style={{ marginBottom: "-1px" }} ><b>{(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "MyValidations"
                                    ) || {}
                                ).mvalue || "nf MyValidation"}{" :"}</b></p>
                                <table className="table table-hover ms-1 mt-0"  >
                                    <thead
                                        style={{ position: "sticky", top: "0", zIndex: "2" }}
                                    >
                                        <tr>

                                            <th className="fw-bold" width="20%" >{(content[selectedLanguage].find((item) => item.elementLabel === "ProjeValidator") ||
                                                {}).mvalue || "nf ProjeValidator"}</th>
                                            <th className="fw-bold" width="14%">{(content[selectedLanguage].find((item) => item.elementLabel === "ProjectValidatorRelationship") ||
                                                {}).mvalue || "nf Relationship"}</th>
                                            <th className="fw-bold" width="40%">{(
                                                content[selectedLanguage].find(
                                                    (item) => item.elementLabel === "ValidatorsRemark"
                                                ) || {}
                                            ).mvalue || "nf Validators Remark"}</th>
                                            <th className="fw-bold" width="25%">{(content[selectedLanguage].find((item) => item.elementLabel === "ValidationStatus") ||
                                                {}).mvalue || "nf ValidationStatus"}</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderBottomStyle: "hidden" }} >
                                        {
                                            validationReqData.map((validatior, index) => (
                                                validatior.recordHide !== 'Yes' && validatior?.validatorResponse === "Accept" &&
                                                <React.Fragment key={validatior.id}>

                                                    <tr style={{ borderBottomStyle: "hidden" }}>

                                                        <td width="16%">
                                                            {validatior.validatorName}
                                                        </td>
                                                        <td width="14%">
                                                            {validatior.relationship}
                                                        </td>

                                                        <td width="40%">

                                                            {validatior?.validatorComments
                                                                ? validatior?.validatorComments
                                                                : "No Remarks from validator"}



                                                        </td>


                                                        <td width="25%">
                                                            {validatior?.validatorResponse ===
                                                                "Request Sent" ||
                                                                validatior?.validatorResponse ===
                                                                "Request Expired"
                                                                ? validatior?.validatorResponse
                                                                : validatior?.validatorResponse ===
                                                                    "Resend"
                                                                    ? "Resent"
                                                                    : `${validatior?.validatorResponse}ed`}
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td width="20%">

                                                        </td>
                                                        <td width="25%">

                                                        </td>

                                                        <div width="40%" style={{ borderTopStyle: "hidden" }} >

                                                            {validatior?.validatorAttachmentFileNames &&

                                                                validatior?.validatorAttachmentFileNames.length > 1 ?

                                                                <div >
                                                                    <p>{(content[selectedLanguage].find((item) => item.elementLabel === "ValidatorsAttachments") ||
                                                                        {}).mvalue || "nf Validators Attachments"}:</p>
                                                                    <div className='d-flex flex-column ms-2 '>
                                                                        {/* <a className='mt-0' rel="noreferrer" href={`${attachmentPdfBaseUrl}${validatior?.id}/${validatior?.validatorAttachmentFileNames}?authToken=${token}`} target="_blank" > */}
                                                                        {/* <a className='mt-0' rel="noreferrer" >
                                                                            <p>https://shorturl.at/yzJT1</p>
                                                                        </a> */}
                                                                        <p>
                                                                            <span>
                                                                                {validatior?.validatorAttachmentFileNames &&
                                                                                    JSON.parse(
                                                                                        validatior?.validatorAttachmentFileNames
                                                                                    ) ? (
                                                                                    JSON.parse(
                                                                                        validatior?.validatorAttachmentFileNames
                                                                                    ).map((attachment, index) => (
                                                                                        <div>
                                                                                            <a
                                                                                                key={index}
                                                                                                rel="noreferrer"
                                                                                                href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment?.filename}s/${attachment?.fileId}`}
                                                                                                target="_blank"
                                                                                            >

                                                                                                {`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment?.filename}/${attachment?.fileId}`}
                                                                                            </a>
                                                                                        </div >
                                                                                    ))
                                                                                ) : (
                                                                                    <p className="text-primary"></p>
                                                                                )}
                                                                            </span>
                                                                        </p>


                                                                    </div>
                                                                </div>

                                                                :
                                                                <>
                                                                </>

                                                            }



                                                        </div>
                                                        <td >

                                                        </td>




                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }
                </React.Fragment >

            }
        </>
    )

}

export default ValidationDataComponent