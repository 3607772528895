import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../../components/Navbar'
import DetailedPofileNavbar from '../../../components/DetailedPofileNavbar';
import RightSideBar from '../../../components/RightSideBar';
import DetailedResume from '../../../components/DetailedResume';
import Footer from '../../../components/Footer';
import { useSelector } from 'react-redux';
import SecondaryBtn from '../../../components/Buttons/SecondaryBtn';
import PremiumServicesOptions from '../../../components/PremiumServicesOptions';
import PrimaryBtn from '../../../components/Buttons/PrimaryBtn';
import PremiumService from '../../../components/SkillOwner/PremiumServices/PremiumService';
import CustomAnalyticsPS from '../../../components/SkillOwner/PremiumServices/CustomAnalyticsPS';
import OthersSummary from '../../../components/SkillOwner/DetailedProfile/OthersSummary';
import OthersDetail from '../../../components/SkillOwner/DetailedProfile/OthersDetail';
import { useDispatch } from 'react-redux';
import PostApi from '../../../api/PostData/PostApi';
import { FormatDateIntoPost } from '../../../components/SkillOwner/HelperFunction/FormatDateIntoPost';
import { convertDateToMilliseconds } from '../../../components/SkillOwner/HelperFunction/convertDateToMilliseconds';
import { addNewOther } from '../../../reducer/detailedProfile/otherSlice';
import { showSuccessToast } from '../../../components/ToastNotification/showSuccessToast';
import { showErrorToast } from '../../../components/ToastNotification/showErrorToast';
import { fetchOthersHistory } from '../../../api/fetchAllData/fetchOtherHistory';
import SecondaryBtnLoader from '../../../components/Buttons/SecondaryBtnLoader';

const Others = () => {

    const innistalState = {
        mtype: "Others",
        mothers: '',
        mlanguage: localStorage.getItem('HLang'),
        organization: "",
        userId: localStorage.getItem('userId'),
        title: ""
    }

    const dispatch = useDispatch()

    const buttonRef = useRef(null)
    const [otherDetails, setOtherDetails] = useState(innistalState)

    const [isAddingOther, setIsAddingOther] = useState(false)


    const [editEnable, setEditEnable] = useState(false);
    const [location, setLocation] = useState([]);
    const [online, setOnline] = useState(false);
    const [switchTab, setSwitchTab] = useState('');

    // Tabs
    const [summaryTab1, setsummaryTab1] = useState(true);
    const [DetailTab1, setDetailTab1] = useState(false);
    //store
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const otherHistory = useSelector(state => state.otherHistory);
    console.log("Other History : ", otherHistory);

    const toggleOnline = () => {
        setOnline(false);
    }


    const handleEdit = () => {
        setEditEnable(!editEnable);
    }

    const handleAccordion1 = (event) => {
        if (summaryTab1 == false && DetailTab1 == false) {
            setsummaryTab1(true);
            console.log("set sum 1");
        }
        else {
            setsummaryTab1(false);
            setDetailTab1(false);
        }
        event.stopPropagation();
        const target = document.getElementById("panelsStayOpen-collapseOne");
        if (target.classList.contains("show")) {
            target.classList.remove("show");
        } else {
            target.classList.add("show");
        }
    }


    const handleDetailsSummary = (event) => {
        event.stopPropagation();
        setsummaryTab1(false);
        setDetailTab1(true);
    }

    const handleSummaryClick = (event) => {
        event.stopPropagation();
        setDetailTab1(false);
        setsummaryTab1(true);
    }

    const handlePdf = () => {
        window.print();

    }

    const handleValidateProject = () => {
        setValidation(true);
    }

    const handleValidationClose = () => {
        setValidation(false);

    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        } else {
            setFileName('No file chosen');
        }
    };

    // modal validation show hide
    const [Validation, setValidation] = useState(false);
    const actualBtnRef = useRef(null);
    const [fileName, setFileName] = useState('No file chosen');

    const handleModalClose = () => {
        setValidation(false);
    }

    // to adjust the height of the content dynamically
    const navbarRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('100vh');

    useEffect(() => {
        if (navbarRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            setContentHeight(`calc(98vh - ${navbarHeight}px)`);
        }
    }, []);

    useEffect(() => {
        if (otherHistory?.status === "idle") {
            dispatch(fetchOthersHistory());

        }
    }, [])


    const handleOthersSubmit = (close) => {

        console.log(otherDetails);
        if (otherDetails.title === "" || otherDetails.mothers === "") {
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'PleaseFillMandatoryBoxes') || {}).mvalue || "nf Please Fill Mandatory Boxes");
            return;
        }

        if (isAddingOther) {
            return
        }

        setIsAddingOther(true)



        PostApi("Others",
            {
                ...otherDetails
            }
        ).then((res) => {

            dispatch(addNewOther({ ...res.data }))
            showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'NewDataAddedSuccessful') || {}).mvalue || "nf New Data Added Successful");

            setOtherDetails(innistalState);
            setLocation([])


            if (close && buttonRef.current) {
                buttonRef.current.click();
            }
            if (!close && buttonRef.current) {
                setOtherDetails(innistalState);
                setLocation([])
            }

        }).catch((err) => {
            console.log(err);
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong");
        }).finally(() => {
            setIsAddingOther(false);

        })

    }



    return (
        <>
            <div className='d-print-none'>

                {/* <!-- Modal --> */}
                <div className="modal fade font-5 m-0 p-0 " style={{ margin: "0" }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='d-flex justify-content-between align-items-center  w-100  '>
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || " nf Others"} </h1>
                                    <i className=' me-2' > <span className='text-danger '>*</span> {(
                                        content[selectedLanguage].find(
                                            (item) => item.elementLabel === "RequiredFields"
                                        ) || {}
                                    ).mvalue || "nf Required Fields"}</i>
                                </div>

                                <button type="button" className="btn-close" onClick={handleModalClose} data-bs-dismiss="modal" aria-label="Close" id='mclose' ref={buttonRef}></button>
                            </div>
                            <div className="modal-body ">
                                {/* form start */}
                                <div className="   " >


                                    {!Validation && <div className=' '>

                                        <div class="mb-2 ">
                                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf Name"}<span className='text-danger' > *</span></label>
                                            <input type="text" style={{ height: "32px" }} name='title' class="form-control bg-body-tertiary h-75 " id="" placeholder="" value={otherDetails.title} onChange={(e) => setOtherDetails({ ...otherDetails, [e.target.name]: e.target.value })} />
                                        </div>


                                        <div className="my-2 ">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label"> {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "nf ProjectBriefDescription"}</label>
                                            <textarea className="form-control bg-body-tertiary" name='mothers' id="exampleFormControlTextarea1" rows="2" value={otherDetails.mothers} onChange={(e) => setOtherDetails({ ...otherDetails, [e.target.name]: e.target.value })}></textarea>
                                        </div>

                                        {/* <div className="d-flex justify-content-between align-items-baseline   ">
                                            <div>
                                                <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'ValidateThisProject') || {}).mvalue || " nf ValidateThisProject"} onClick={handleValidateProject} backgroundColor="#F8F8E9" color="#815F0B" />
                              
                                                <div id="emailHelp" class="form-text">
                                                    <a href="/"> {(content[selectedLanguage].find(item => item.elementLabel === 'RequireValidationSupport') || {}).mvalue || "nf RequireValidationSupport"}</a>
                                                </div>

                                            </div>
                                            <div>
                                                <div>
                                                    <input type="file" id='fileChoose'  ref={actualBtnRef} hidden onChange={handleFileChange} />
                                                    <label htmlFor="fileChoose" > <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'AttachRelatedDocuments') || {}).mvalue || "nf AttachRelatedDocuments"} backgroundColor="#F8F8E9" color="#815F0B" /> </label>
                                                </div>
                                                <div id="file-chosen" class="form-text">{fileName}</div>
                                            </div>

                                        </div> */}


                                    </div>}


                                    {
                                        Validation === true &&
                                        <div className="ms-2  me-2  border  px-1 py-1  ">
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <h3 className="modal-title fs-5" id="exampleModalLabel">{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || " nf ProjectValidation"}</h3>
                                                <button type="button" className="btn-close" onClick={handleValidationClose} aria-label="Close"></button>
                                            </div>
                                            <div className="my-2  "  >
                                                <label htmlFor="exampleFormControlInput1" className="form-label ">{(content[selectedLanguage].find(item => item.elementLabel === 'WhoValidates') || {}).mvalue || " nf WhoValidates"}<span className='text-danger' >*</span></label>
                                                <input type="text" style={{ height: "32px" }} className="form-control bg-body-tertiary h-75 " id="exampleFormControlInput1" />
                                            </div>

                                            <div>
                                                <label htmlFor="" className='form-label mt-2  '>{(content[selectedLanguage].find(item => item.elementLabel === 'Relationship') || {}).mvalue || " nf Relationship"} <span className='text-danger' >*</span></label>
                                            </div>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <div class="form-check form-check-inline   ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship1" value="HOD" />
                                                            <label class="form-check-label" for="relationship1">Administrative Office</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship2" value="Teaching Staff" />
                                                            <label class="form-check-label" for="relationship2">Teaching Staff</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship3" value="Non Teaching staff" />
                                                            <label class="form-check-label" for="relationship3">Non Teaching staff </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship5" value="Friend" />
                                                            <label class="form-check-label" for="relationship5">Friend </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>




                                            <div class="form-check form-check-inline mb-2   ">
                                                <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship4" value="Other Person" />
                                                <label class="form-check-label" for="relationship4">{(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || "nf Others"} </label>
                                            </div>

                                            <div class="mb-2  ">
                                                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'EmailId') || {}).mvalue || "nf EmailID"} <span className='text-danger' >*</span></label>
                                                <input type="email" style={{ height: "32px" }} class="form-control bg-body-tertiary h-75 " id="" placeholder="" />
                                            </div>
                                            <div class="my-2 ">
                                                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'MobileNo') || {}).mvalue || "nf MobileNo"}</label>
                                                <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary h-75 " id="" placeholder="" />
                                            </div>
                                            <div className="my-2 ">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Remarks') || {}).mvalue || "nf Reamarks"}  </label>
                                                <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2"></textarea>
                                            </div>

                                            <div className='d-flex justify-content-end align-items-center mb-1  '>
                                                <button type="button" className="text-white border-0 px-2 py-1 rounded   pill-bg-color  font-5"  >{(content[selectedLanguage].find(item => item.elementLabel === 'Validate') || {}).mvalue || "nf Validate"}</button>
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-end  ">

                                {/* <div>
                                    <button type="button" className="btn  me-2 font-5" style={{ backgroundColor: "#EFF5DC", color: "#815F0B" }} onClick={()=>handleOthersSubmit(false) } data-bs-dismiss="modal">{(content[selectedLanguage].find(item => item.elementLabel === 'SaveAndClose') || {}).mvalue || "nf SaveAndClose"}</button>
                                    <button type="button" className="btn text-white font-5" style={{ backgroundColor: "#815F0B" }} >{(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillButton') || {}).mvalue || "nf AddSkillButton"}</button>
                                </div> */}

                                <div className='d-flex gap-2'>
                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "Save"} onClick={() => handleOthersSubmit(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isAddingOther} />
                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "Save & Close"} Active={true} onClick={() => handleOthersSubmit(true)} backgroundColor="#815F0B" color="#F8F8E9" loading={isAddingOther} />
                                    {/* <button type="button" className="btn  font-5 me-2" style={{ backgroundColor: "#EFF5DC", color:'#815F0B' }} onClick={()=>handleOthersSubmit(false) } disabled={isAddingOther} >Save</button>
                                    <button type="button" className="btn  me-2 font-5" style={{backgroundColor: "#815F0B" , color: "#EFF5DC" }}  onClick={()=>handleOthersSubmit(true) } disabled={isAddingOther}>Save & Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={navbarRef} id="yourNavbarId">
                    <Navbar handlePdf={handlePdf}></Navbar>
                </div>

                <hr className='p-0 m-0 ' />

                <div style={{ backgroundColor: "#", minHeight: "", height: "" }} className="container-fluid  h6 ">
                    <div className="row  gap-0 ">

                        <div className=" bg-white px-1 col-md font-5 fixed-sidebar   rounded " >

                            <div>


                                <DetailedPofileNavbar />
                            </div>
                            <div>
                                <PremiumServicesOptions setSwitchTab={setSwitchTab} />

                            </div>

                        </div>

                        <hr className='vr m-0 p-0' />

                        <div className="col-md-7  rounded bg-white  px-1 font-5   " style={{ overflowY: "auto", height: contentHeight }} >
                            {switchTab === "" &&
                                <>

                                    <div className="d-md-flex align-items-center justify-content-between my-1 px-1   " >
                                        <div className='d-flex align-items-center '>
                                        </div>
                                        <div className='py-1 d-flex gap-1'>

                                            <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillButton') || {}).mvalue || "nf AddSkillButton"} backgroundColor="#F7FFDD" color="#815F0B" />
                                            </div>
                                            <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'EditSkillButton') || {}).mvalue || "nf EditSkillButton"} backgroundColor="#F7FFDD" color="#815F0B" onClick={handleEdit} />



                                        </div>

                                    </div>

                                    {/* accordion one table */}
                                    <div className="accordion   " id="accordionPanelsStayOpenExample"  >
                                        <div className="accordion-item border-0  mb-2 rounded-top  ">
                                            <h2 className="accordion-header py-1 ">
                                                <button className="accordion-button flex justify-content-between py-2" onClick={handleAccordion1} style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126", color: (content[selectedLanguage].find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD" }} type="button" data-bs-toggle="collapse"   >
                                                    <div className='w-75 '> {(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || "nf Others"}</div>
                                                    <div className='d-flex gap-1'>
                                                        <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'SummaryView') || {}).mvalue || "nf SummaryView"} onClick={handleSummaryClick} backgroundColor="#F8F8E9" color="#815F0B" statusTab={summaryTab1} />
                                                        <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'DetailsView') || {}).mvalue || "nf DetailsView"} onClick={handleDetailsSummary} backgroundColor="#F8F8E9" color="#815F0B" statusTab={DetailTab1} />

                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse   collapse show">

                                                {summaryTab1 && <div className="accordion-body  ">

                                                    {/* table start */}
                                                    <div className="table-responsive ">
                                                        <OthersSummary data={otherHistory}
                                                            editEnable={editEnable} />
                                                    </div>
                                                    {/* table end */}
                                                </div>}
                                                {DetailTab1 && <div className="accordion-body  ">

                                                    {/* table start */}

                                                    <OthersDetail data={otherHistory} editEnable={editEnable} />
                                                    {/* table end */}
                                                </div>}
                                            </div>
                                        </div>



                                    </div>
                                </>
                            }
                            {switchTab === 'premium' &&
                                <>
                                    <button className="input-group-text  mt-2   ms-1 primary-green " style={{ backgroundColor: "#", color: "#815F0B", borderStyle: "solid", borderColor: "" }} onClick={() => setSwitchTab('')} >
                                        {(content[selectedLanguage].find(item => item.elementLabel === 'Back') || {}).mvalue || "nf Back"}
                                    </button>
                                    <PremiumService />
                                </>
                            }

                            {switchTab === 'customAnalytics' &&
                                <>
                                    <button className="input-group-text  mt-2    ms-1 primary-green " style={{ backgroundColor: "#", color: "#815F0B", borderStyle: "solid", borderColor: "" }} onClick={() => setSwitchTab('')} >
                                        {(content[selectedLanguage].find(item => item.elementLabel === 'Back') || {}).mvalue || "nf Back"}
                                    </button>
                                    <CustomAnalyticsPS />
                                </>
                            }
                            {/* end one table */}
                        </div>
                        {/* </div> */}

                        <hr className='vr m-0 p-0' />

                        <div className="col-md  rounded bg-white px-1 font-5 fixed-sidebar">
                            <RightSideBar />
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
            <div className='d-none d-print-block  '>
                <DetailedResume />
            </div>
        </>
    )
}

export default Others