import React from 'react'
import { useSelector } from 'react-redux';
import { DayDifferenceToDynamicView } from '../../HelperFunction/DayDifferenceToDynamicView';
import { formatTimestampToDate } from '../../HelperFunction/FormatTimestampToDate';

const AwardSummaryResView = ({ data }) => {
    const selectedLanguage = useSelector(state => state.language);

    const content = useSelector(state => state.content);
    return (
        <React.Fragment>
            {
                data?.length > 0 &&
                <React.Fragment>
                    <div className='font-3 font-weight-2'>{(content[selectedLanguage].find(item => item.elementLabel === 'MyAwards') || {}).mvalue || "nf Award"}</div>
                    <div className="table-responsive ms-3 mt-2">
                        <table className='table table-sm   table-fixed table-hover    '>
                            <thead >
                                <tr className='border-dark-subtle'>
                                    <th scope="col" className='bg-body- ' style={{ width: "21%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Award/Achievement') || {}).mvalue || "nf Award/Achievement"}   </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "25%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "nf ProjectOrganization"}    </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Date') || {}).mvalue || "nf Date"}        </th>
                                    <th scope="col" className='bg-body- ' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf ProjectLocation"}        </th>
                                    <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf ProjectValidation"}      </th>

                                </tr>
                            </thead>

                            <tbody className=''>
                                {data?.map((certs, index) => (

                                    <tr className=''>
                                        <td  >{certs.title}</td>
                                        <td >{certs.recordAnonymous === 'Yes' ? (
                                            content[selectedLanguage].find(
                                                (item) =>
                                                    item.elementLabel ===
                                                    "Confidential"
                                            ) || {}
                                        ).mvalue || "nf CONFIDENTIAL" : certs.organization}</td>
                                        <td >{formatTimestampToDate(certs.date)}</td>
                                        <td>{certs.location}</td>
                                        <td className=' '>{certs.validation === 'Yes' ? ' Yes' : ' No'}</td>

                                    </tr>))




                                }

                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default AwardSummaryResView