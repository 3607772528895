import React, { useEffect, useState } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';
import { ImAttachment, ImWarning, ImCheckmark } from 'react-icons/im'
import { SiHiveBlockchain } from 'react-icons/si';
import ShowHideIcon from '../../ShowHideIcon';
import { useDispatch } from 'react-redux';
import { skillDelete, updateValidation } from '../../../reducer/skillProfile/SkillsAppliedSlice';
import { useSelector } from 'react-redux';
import { dateDynamicView } from '../HelperFunction/DateDynamicView';
import DeleteApi from '../../../api/DeleteData/DeleteApi';
import { showSuccessToast } from '../../ToastNotification/showSuccessToast';
import { showErrorToast } from '../../ToastNotification/showErrorToast';
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference ';
import { DayDifferenceToDynamicView } from '../HelperFunction/DayDifferenceToDynamicView';
import Validation from '../../ValidationForm';
import validationIcon from '../../../Images/validate-svgrepo-com.svg';
import AttachmentForm from '../../AttachmentForm';
import { setValidationData } from '../../../reducer/validation/validationSlice';
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments';
import EditApi from '../../../api/editData/EditApi';
import SkillsAppiliedEdit from '../Forms/Edit Forms/SkillsAppiliedEdit';
import Anonimization from '../../Anonimization';
import DeleteForm from '../../DeleteForm';
import { setSkillsAppliedEditData } from '../../../reducer/edit/skillsAppliedEditSlice';
import { setDeleteDetailedProfileData } from '../../../reducer/delete/deleteDetailedProfileSlice';
import DeleteFormDetailedProfile from '../../DeleteFormDetailedProfile';
import { BiSolidInfoCircle } from 'react-icons/bi';

const SkillAppliedSummary = ({ data, editEnable }) => {


  const dispatch = useDispatch();
  const content = useSelector(state => state.content);
  const selectedLanguage = useSelector(state => state.language);
  const SkillSelected = useSelector(state => state.SkillSelected.skillOccupation);
  const [projectSelected, setProjectSelected] = useState(null)

  const initialState = {
    fromDate: "",
    toDate: "",
    duration: "",
    briefDescription: "",
    organization: "",
    location: "",
    mtype: "Own",
    showHide: "Yes",
    validation: "No",
    blockChain: "No",
    mlanguage: selectedLanguage,
    orgIsnew: false,
    projIsnew: false,
    title: "", // skill name
    userId: localStorage.getItem("userId"),
    projectActivity: "",
    id: "",
    ticketids: []
  }

  const [forms, setForms] = useState(initialState);

  const handleDelete = (id) => {
    DeleteApi("Skills Applied", id).then((res) => {
      if (res.data.deletedStatus === "true") {
        dispatch(skillDelete(id));
        showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'RecordDeletedSuccessfully') || {}).mvalue || "nf Record Deleted Successfully");
      } else {
        showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'YouAlreadyDeletedData') || {}).mvalue || "nf You already deleted this data");
      }
    }).catch((err) => {
      console.error(err);
      showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'ErrorDeletingData') || {}).mvalue || "nf Error in deleting data");
    });

  }

  const handleValidate = (selectedData) => {
    dispatch(setValidationData(selectedData));
  };

  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  /* CHANGE VALIDATION RESULTS */
  const {
    getUserValidation: { userValidationData },
  } = useSelector((state) => state);

  useEffect(() => {
    /* CHECK FOR MATCHING ID AND CAPTURE DATA */
    if (data?.data && userValidationData) {
      data.data.forEach(dataItem => {
        userValidationData.forEach(validationItem => {
          if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
            dispatch(updateValidation({ id: dataItem.id, validation: "Yes" }));
          }
        });
      });
    }
  }, [data, userValidationData, dispatch]);

  const handleEdit = (data) => {
    dispatch(setSkillsAppliedEditData(data));
  }
  return (
    <>
      {/* VALIDATION MODAL */}
      {<Validation />}
      {<AttachmentForm />}
      {<DeleteFormDetailedProfile />}
      {<SkillsAppiliedEdit />}









      <div className="table-responsive">
        {data.data &&
          <table className='table table-sm table-fixed table-hover'>
            <thead>
              <tr>
                <th scope="col" style={{ width: "28%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectTitle') || {}).mvalue || "nf Project Title"}  <span className='ms-0 mb-1' data-tooltip-id="my-tooltip" data-tooltip-content={(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDescription') || {}).mvalue || "nf ProjectDescription"}><BiSolidInfoCircle className='text-muted' style={{ fontSize: '20px' }} /></span></th>
                <th scope="col" style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf  Duration"}</th>
                <th scope="col" style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf Project Type"}</th>
                <th scope="col" style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf  Location"}</th>
                <th scope="col" style={{ width: "8%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf  Validation"}</th>
                <th scope="col" style={{ width: "13%" }}></th>
              </tr>
              {/* horizontal line */}
              <tr style={{ borderColor: "gray", borderWidth: "1px" }} />
            </thead>
            <tbody>
              {data.data.map((skill, index) => (
                <>
                  {skill.mlanguage === selectedLanguage && SkillSelected === skill.title &&
                    <tr key={index}  >
                      {console.log("CONSOLE FOR SKILL APPLIED SUMMAR", data?.data)}
                      <td>{skill.projectActivity ? (skill.projectActivity.length > 17 ? skill.projectActivity.substring(0, 17) + "..." : skill.projectActivity) : (skill.organization && skill.organization.length > 17 ? skill.organization.substring(0, 17) + "..." : skill.organization)}</td>
                      <td>{DayDifferenceToDynamicView(skill.duration)}</td>
                      <td>
                        {skill.mtype === "Own" ?
                          (content[selectedLanguage].find(item => item.elementLabel === 'ProjectOwn') || {}).mvalue || "nf Project(O)" :
                          (skill.mtype === "Employment" && skill.projectActivity ?
                            (content[selectedLanguage].find(item => item.elementLabel === 'ProjectEmployment') || {}).mvalue || "nf Project(E)" :
                            (content[selectedLanguage].find(item => item.elementLabel === 'ProfessionalExperience') || {}).mvalue || "nf Professional Experience"
                          )
                        }
                      </td>

                      {/* location only show 17characters alone after that ... */}
                      <td style={{ width: "15%" }} className='' data-tooltip-id="my-tooltip" data-tooltip-content={skill.location}>{skill.location ? skill.location.length > 12 ? skill.location.substring(0, 12) + "..." : skill.location : ""}</td>
                      <td>{skill.validation}</td>
                      <td className={`d-flex ${editEnable ? 'justify-content-between' : 'justify-content-evenly'}`} style={{ minHeight: "26px" }}>
                        {editEnable ? (
                          <>
                            {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Validate" data-bs-toggle="modal" data-bs-target="#validationForm"
                              onClick={() => handleValidate(skill)}
                            >
                              <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                            </button> */}
                            {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Attachment" onClick={() => handleAttachment(skill)} data-bs-toggle="modal" data-bs-target="#attachmentList">
                              <ImAttachment />
                            </button> */}
                            <div data-bs-toggle="modal" data-bs-target="#skillsAppliedEditModal" id="addModalBtn">
                              <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                onClick={() => handleEdit(skill)}
                              ><MdEdit /></button>
                            </div>

                            {/* <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillButton') || {}).mvalue || "not found"} backgroundColor="#F7FFDD" color="#815F0B" /> */}


                            <ShowHideIcon value={skill.recordHide === 'Yes' ? true : false} toggleable={true}
                              form={skill} fieldName={"recordHide"} api={"Skills Applied"} id={skill.id} />
                            <Anonimization value={skill.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                              form={skill} setForm={setForms} fieldName={"recordAnonymous"} api={"Skills Applied"} id={skill.id} />

                            {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Add on Blockchain"
                              style={{ visibility: skill.validation === "Yes" ? "visible" : "hidden" }}><SiHiveBlockchain /></button> */}
                            {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Trash"
                              onClick={() => handleDelete(skill.id)}
                            >
                              <MdDelete />
                            </button> */}
                            <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                              setProjectSelected(skill)
                              handleAttachment(skill)
                              handleValidate(skill)
                              dispatch(setDeleteDetailedProfileData(skill))

                            }}>  <MdDelete /> </button>
                          </>
                        ) : (
                          <>
                            <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Validate" data-bs-toggle="modal" data-bs-target="#validationForm"
                              onClick={() => handleValidate(skill)}
                            >
                              <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                            </button>
                            <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Attachment" onClick={() => handleAttachment(skill)} data-bs-toggle="modal" data-bs-target="#attachmentList">
                              <ImAttachment />
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  }
                </>
              ))}
            </tbody>
          </table>
        }
      </div>
    </>
  );
}

export default SkillAppliedSummary;
