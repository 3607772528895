import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate } from "react-router-dom";
import { RiDragMoveFill } from "react-icons/ri";

const ShortableItemSS = ({ id, service, i }) => {
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleClick = () => {
    navigate(`/supportservices/${service.id}`);
  };
  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={handleClick}
    >
      {/* <th className='p-1' scope="row">{props.i < 5 ? props.i + 1 : ''}</th> */}
      <th
        className="p-1"
        scope="row"
        style={i < 5 ? { color: "black" } : { color: "grey" }}
      >
        {" "}
        {i + 1}
      </th>
      <td className="p-1">{service.serviceName}</td>
      <td className="p-1">{service.status}</td>
      <td className="p-1">
        <RiDragMoveFill />
      </td>
    </tr>
  );
};

export default ShortableItemSS;
