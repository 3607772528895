import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";

export const  fetchUserCourses = createAsyncThunk(
  "courseSearch/userCourses",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userName");
      const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-all-user-data/UserCourses/${userId}?authToken=${token}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);
