import React, { useEffect, useState } from 'react'
import LanguageComponent from '../LanguageComponent'
import Playground from '../FlagSelection'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../config/Properties';
import { GetUserForSelectedLanguage } from '../SkillOwner/HelperFunction/GetUserForSelectedLanguage';
import { showErrorToast } from '../ToastNotification/showErrorToast';
import Select from 'react-select';
import SecondaryBtnLoader from '../Buttons/SecondaryBtnLoader';
import EditAccountApi from '../../api/Accounts/EditAccountApi';
import { showSuccessToast } from '../ToastNotification/showSuccessToast';

const ChangeRegionTimezones = () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");


    // store imports
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);
    const userDetailsAll = useSelector(state => state.userProfile.data);
    const userAccountDetails = useSelector((state) => state.UserAccountDetails);


    console.log('detailsare', userAccountDetails);


    const [selectedTimeZone, setSelectedTimeZone] = useState({ abbreviation: localStorage.getItem("TimeZone") });
    const [selectedObject, setSelectedObject] = useState({ "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" });
    const [flagSearch, setFlagSearch] = useState("");
    const [Country, setCountry] = useState({ country: localStorage.getItem('countryCode') });
    const [ShowDropDown, setShowDropDown] = useState(false)
    const [menu, setMenu] = useState(false)
    const [profileOptionShow, setProfileOptionShow] = useState(false)
    const [lang, setLang] = useState([]);
    const [timeZone, setTimeZone] = useState([{ "code": "en-US", "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" },
    { "code": "en-US", "country": "US", "abbr": "PST", "TZ": "America/Los_Angeles" },
    { "code": "en-US", "country": "US", "abbr": "EST", "TZ": "America/New_York" },
    { "code": "en-IN", "country": "India", "abbr": "IST", "TZ": "Asia/Kolkata" }]);
    const [userDetails, setuserDetails] = useState({})

    const handleDropDown = ({ handlePdf }) => {
        setShowDropDown(!ShowDropDown)
    }

    const [tempRegion, setTempRegion] = useState({});
    const [tempTimeZone, setTempTimeZone] = useState({});
    const [edit, setEdit] = useState(false);
    const [opened, setOpened] = useState(false);


    const regionalData = useSelector(state => state.regionalData);
    console.log("LIst of Countires , " , regionalData?.listOfCountries);
    const currency = useSelector(state => state.currency.value)
    const dispatch = useDispatch()

    // source https://api.first.org/data/v1/countries

    const [filter, setFilter] = useState([]);
    const [filterTimeZone, setFilterTimeZone] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const mappedArray = Array.isArray(regionalData?.listOfCountries) ? regionalData?.listOfCountries?.map(obj => ({
            ...obj,
            value: obj.country,
            label: obj.country
        })) : [];
        setFilter(mappedArray);
        const selectedObjects = mappedArray.find(item => item.countryCode === localStorage.getItem('countryCode'));
        console.log("selectedd country objects ", selectedObjects);
        if (selectedObjects !== undefined) {
            console.log("seld ", selectedObjects);
            setCountry(selectedObjects);
        }

    }, [regionalData?.listOfCountries]);

    console.log("ugbv ", regionalData?.listOfCountries);





    // useEffect(() => {

    //     async function fetchTimeZone() {
    //         try {
    //             const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/RegionalData/Time Zone Data?&searchFieldName=countryCode&searchValue=${Country.countryCode}%25`)
    //             setTimeZone(res?.data)
    //             setSelectedTimeZone(res?.data?.[0].abbreviation);

    //             setSelectedObject(res?.data?.[0]);

    //             console.log("change value is aa ", res?.data?.[0].abbreviation);
    //             console.log("change value is bb", res?.data?.[0]);
    //             console.log("nav bar res is", res);
    //         } catch (err) {
    //             console.log(err);
    //             showErrorToast('Cant fetch Timezones')
    //         }

    //     }

    //     // if(Country.countryCode !== localStorage.getItem('countryCode')){

    //     fetchTimeZone()
    //     // }


    // }, [localStorage.getItem('countryCode'),tempRegion])

    // useEffect(() => {



    //     setCountry()

    // }, [localStorage.getItem('countryCode')])


    useEffect(() => {

        async function fetchTimeZone() {
            const check = tempRegion?.countryCode ? tempRegion.countryCode : Country?.countryCode;
            if (check !== undefined && check !== null) {
                try {


                    const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/RegionalData/Time Zone Data?&searchFieldName=countryCode&searchValue=${check}%25`)
                    setTimeZone(res?.data);
                    // const selectedValue = e;
                    // const selectedObjects = timeZone.find(item => item.abbreviation === selectedValue.abbreviation);
                    // setSelectedTimeZone(selectedValue);
                    // setTempTimeZone(selectedObjects);

                    //setSelectedTimeZone(res?.data?.[0].abbreviation);
                    const mappedArray = res?.data.map(obj => ({
                        ...obj,
                        value: obj.abbreviation,
                        label: obj.countryCode + " " + obj.abbreviation + " " + obj?.timeZone + " " + obj.gmtOffset
                    }));
                    console.log("mappedArray ", tempRegion);
                    setFilterTimeZone(mappedArray);
                    console.log(localStorage.getItem("TimeZone"));
                    const selectedObjects = mappedArray.find(item => item.abbreviation === localStorage.getItem("TimeZone"));
                    console.log("selectedd country objects ", selectedObjects);
                    if (selectedObjects !== undefined) {
                        console.log("seld ", selectedObjects);
                        setTempTimeZone(selectedObjects);
                    } else setTempTimeZone(mappedArray[0]);


                    setSelectedObject(res?.data?.[0]);

                    //console.log("change value is aa ", res?.data?.[0].abbreviation);
                    console.log("change value is bb", res?.data?.[0]);
                    console.log("nav bar res is", res);
                } catch (err) {
                    console.log('Cant fetch TimezoneS ', err);
                    showErrorToast('Cant fetch TimezoneS')
                }
            }

        }
        fetchTimeZone();

    }, [edit, tempRegion])



    useEffect(() => {
        console.log("Countryyy  ", Country, " temp region ", tempRegion);

    }, [Country, tempRegion]);

    useEffect(() => {
        console.log(tempTimeZone);

    }, [tempTimeZone]);



    useEffect(() => {

        setuserDetails(GetUserForSelectedLanguage(userDetailsAll, selectedLanguage));

    }, [selectedLanguage, userDetailsAll])


    const handleTimeZoneChange = (e) => {
        const selectedValue = e;
        const selectedObjects = timeZone.find(item => item.abbreviation === selectedValue.abbreviation);
        //setSelectedTimeZone(selectedValue);
        setTempTimeZone(selectedObjects);

        console.log("change value is ", selectedObjects);
    }

    const handleFlagFilter = (e) => {
        setFlagSearch(e.target.value);
    }

    const handleSubmit = () => {
        setIsLoading(true);
        if (tempRegion !== null && tempTimeZone?.abbreviation !== null) {
            try {
                EditAccountApi({
                    "accountId": userId,
                    "homeCountry": tempRegion.countryCode,
                    "homeTimeZone": tempTimeZone.abbreviation
                })
                    .then((res) => {
                        setIsLoading(false);
                        localStorage.setItem("countryCode", res.data.account.homeCountry);
                        localStorage.setItem("TimeZone", tempTimeZone.countryCode + " " + tempTimeZone.abbreviation + " " + tempTimeZone?.timeZone + " " + tempTimeZone.gmtOffset);
                        localStorage.setItem("dateFormat", tempRegion?.dateFormat);
                        showSuccessToast("Change Successfull");
                        console.log(res.data);
                        onCancel();
                        window.location.reload();
                    });
            } catch (error) {
                setEdit(false);
                setIsLoading(false);
                showErrorToast("Change Failed");
            }
        } else {
            setIsLoading(false);
        }

    }


    const handleRegionChange = (e) => {
        setFilterTimeZone([]);
        setTempTimeZone();
        setTempRegion(e);

    }

    const onCancel = () => {
        setEdit(false);
        setTempRegion({});
        setTempTimeZone({});

    }




    return (
        <div class="container rounded ">
            <div class="row justify-content-center  mb-2 pb-2 rounded  ">
                <div class="col-12    bg-light  pt-2  rounded ">

                    <div>
                        <h6>{(content[selectedLanguage].find(item => item.elementLabel === 'Country') || {}).mvalue || "nf Country"}</h6>
                        <div className=" d-flex  flex-row   align-content-center  ">

                            <div className='me-4 '   >

                                {
                                    edit === true ?
                                        <div style={{ width: "425px", }}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={Country}
                                                //isDisabled={isDisabled}
                                                //isLoading={isLoading}
                                                isClearable={false}
                                                //isRtl={isRtl}
                                                isSearchable={true}
                                                onChange={handleRegionChange}
                                                name="color"

                                                options={filter}
                                            />


                                        </div> :
                                        <div className='d-flex ' >

                                            <div className='align-self-center border-2  ' style={{ width: "425px", backgroundColor: "white", border: "solid #DEE2E6", borderRadius: "5px", height: "30px" }}>
                                                <span className='ms-2' >{Country?.country}</span>
                                            </div>
                                            <div class="btn border-0 p-0 m-0  ms-3 pe-3 " data-bs-auto-close="outside">
                                                <img className='m-0 p-0 align-self-center me-1' style={{ width: "35px", height: "37px", borderRadius: "100%" }}
                                                    src={`https://flagsapi.com/${tempRegion?.countryCode !== undefined && tempRegion?.countryCode !== null ? tempRegion?.countryCode : Country?.countryCode}/flat/32.png`} alt="" />

                                            </div>
                                        </div>
                                }

                            </div>

                            {
                                edit === true &&
                                <div class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside">
                                    <img className='m-0 p-0 align-self-center me-1' style={{ width: "35px", height: "37px", borderRadius: "100%" }}
                                        src={`https://flagsapi.com/${tempRegion?.countryCode !== undefined && tempRegion?.countryCode !== null ? tempRegion?.countryCode : Country?.countryCode}/flat/32.png`} alt="" />

                                </div>
                            }

                        </div>
                    </div>
                    <div className=" d-flex  flex-row mt-4  ">

                        <div style={{ width: "425px", }}>
                            <h6>{(content[selectedLanguage].find(item => item.elementLabel === 'Timezone') || {}).mvalue || "nf TimeZone"} </h6>
                            {
                                edit === true ?
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        //defaultValue={filterTimeZone.find(option => option.value === tempTimeZone?.value)}
                                        //isDisabled={isDisabled}
                                        //isLoading={isLoading}
                                        isClearable={false}
                                        //isRtl={isRtl}
                                        isSearchable={true}
                                        onChange={handleTimeZoneChange}
                                        name="color"

                                        options={filterTimeZone}
                                    /> :
                                    <div className='align-self-center border-2  ' style={{ width: "425px", backgroundColor: "white", border: "solid #DEE2E6", borderRadius: "5px", height: "30px" }}>
                                        <span className='ms-2' > {localStorage.getItem("TimeZone")}</span>
                                    </div>

                            }
                            {/* <div className='d-flex justify-content-between  ' style={{ width: "50%" }} >

                                <div className='d-none d-lg-block  '>
                                    <select class="  border-0 font-dd  " style={{
                                        paddingLeft: "4px",
                                        color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#577126",
                                        width: "70px", height: "10px", fontWeight: "500", marginBottom: "15px", fontSize: "10px"
                                    }} aria-label="Default select example  "
                                        onChange={handleTimeZoneChange} value={selectedTimeZone} >
                                        {timeZone.map((item) =>
                                            <option value={item.abbreviation}
                                                key={item.abbreviation} className='bg-body-tertiary font-dd    text-black  ' >
                                                {selectedTimeZone === item.abbreviation ? selectedObject.countryCode + " " + selectedObject.abbreviation : item.countryCode + " " + item.abbreviation}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div> */}
                        </div>


                    </div>
                    <div className=" mt-4">
                        {
                            edit === false ? <SecondaryBtnLoader onClick={() => setEdit(true)} label={(content[selectedLanguage].find(item => item.elementLabel === 'Edit') || {}).mvalue || "nf Edit"} backgroundColor="#F8F8E9"
                                color="#815F0B" />
                                :


                                <div className='d-flex'>
                                    <SecondaryBtnLoader
                                        label={
                                            (
                                                content[selectedLanguage].find(
                                                    (item) => item.elementLabel === "Save"
                                                ) || {}
                                            ).mvalue || "nf Save"
                                        }
                                        backgroundColor="#F8F8E9"
                                        color="#815F0B"
                                        type="submit"
                                        loading={isLoading}
                                        onClick={handleSubmit}
                                    />
                                    <div className='ms-3' >
                                        <SecondaryBtnLoader onClick={onCancel} label={(content[selectedLanguage].find(item => item.elementLabel === 'Cancel') || {}).mvalue || "nf Cancel"} backgroundColor="#F8F8E9"
                                            color="#815F0B" />
                                    </div>
                                </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ChangeRegionTimezones