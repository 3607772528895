import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import EditApi from '../api/editData/EditApi';
import { showSuccessToast } from './ToastNotification/showSuccessToast';
import { showErrorToast } from './ToastNotification/showErrorToast';
import { BASE_URL } from './../config/Properties';
import axios from 'axios';
import { editSkillApplied } from '../reducer/skillProfile/SkillsAppliedSlice';
import { editSkillAcquired } from '../reducer/skillProfile/SkillsAcquiredSlice';
import { useDispatch } from 'react-redux';
import { editValidationRequest } from '../reducer/validation/getUserValidationSlice';
import Loader from './Loader';
import { updateEmployment } from '../reducer/detailedProfile/employmentSlice';
import { projectUpdateRecord } from '../reducer/detailedProfile/projectSlice';
import { educationUpdateRecord } from '../reducer/detailedProfile/educationSlice';
import { certificationUpdateRecord } from '../reducer/detailedProfile/certificationSlice';
import { trainingUpdateRecord } from '../reducer/detailedProfile/trainingSlice';
import { conferenceUpdateRecord } from '../reducer/detailedProfile/conferenceSlice';
import { skillingUpdateRecord } from '../reducer/detailedProfile/skillingsSlice';
import { licenseUpdateRecord } from '../reducer/detailedProfile/licensesSlice';
import { achievementUpdateRecord } from '../reducer/detailedProfile/achievementSlice';
import { membershipUpdateRecord } from '../reducer/detailedProfile/membershipSlice';
import { othersUpdateRecord } from '../reducer/detailedProfile/otherSlice';

const ShowHideIcon = ({ fieldName = "def", form, setForm, value, toggleable = false, api = "false", id = '0' }) => {
  const [isVisible, setIsVisible] = useState(value);
  const [Loader, setLoader] = useState(false);
  const dispatch = useDispatch();



  const toggleVisibility = (e) => {
    setLoader(true);
    e.stopPropagation();
    setIsVisible(prevIsVisible => !prevIsVisible); // Use state updater function
    if (toggleable && api !== "false") {
      try {
        const newValue = !isVisible ? "Yes" : "No"; // Use the updated state value
        EditApi(form?.applicationName, form?.id, { [fieldName]: newValue }

        ).then((res) => {
          setLoader(false);
          showSuccessToast("Status Changed");
          console.log(res.data);

          switch (res.data.applicationName) {
            case "Skills Applied":
              dispatch(editSkillApplied(res.data));
              console.log("skil Applied edit");
              break;
            case "Skills Acquired":
              dispatch(editSkillAcquired(res.data));
              console.log("skil Acquired edit");
              break;

            case "validation requests":
              dispatch(editValidationRequest(res.data));
              console.log("editValidationRequest");
              break;

            case "Employment History":
              dispatch(updateEmployment(res.data));
              console.log("updateEmployment edit");
              break;

            case "Project  History":
              dispatch(projectUpdateRecord(res.data));
              console.log("projectUpdateRecord edit");
              break;

            case "Education History":
              dispatch(educationUpdateRecord(res.data));
              console.log("educationUpdateRecord edit");
              break;

            case "Certification History":
              dispatch(certificationUpdateRecord(res.data));
              console.log("certificationUpdateRecord edit");
              break;

            case "Training":
              dispatch(trainingUpdateRecord(res.data));
              console.log("trainingUpdateRecord edit");
              break;

            case "Conferences":
              dispatch(conferenceUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Skilling":
              dispatch(skillingUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Licenses":
              dispatch(licenseUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Awards":
              dispatch(achievementUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Memberships":
              dispatch(membershipUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;
            case "Others":
              dispatch(othersUpdateRecord(res.data));
              console.log("conferenceUpdateRecord edit");
              break;

            default:
              break;
          }
        })
      } catch (error) {
        showErrorToast("status not changed");
        console.log(error);
      }

    }
  };
  // useEffect(() => {
  //   console.log("form ", form, " ", isVisible);
  //   if (toggleable && form && setForm && api === "false") {
  //     setForm({ ...form, [fieldName]: isVisible ? "true" : "false" })
  //   }




  // }, [isVisible])

  const visibilityChange = () => {
    if (toggleable && form && setForm && api === "false") {
      setForm({ ...form, [fieldName]: isVisible ? "Yes" : "No" })
    }
  }




  return (
    <React.Fragment>
      {
        // on click api call
        api === "false" ?
          //on click changes happens
          toggleable ?
            <div variant="outline-primary" onChange={visibilityChange} onClick={(e) => toggleVisibility(e)}>
              {!isVisible ? <FaEye /> : <FaEyeSlash />}
            </div> :
            //static only view
            <div variant="outline-primary" >
              {!isVisible ? <FaEye /> : <FaEyeSlash />}
            </div>
          :
          //no on click api call
          toggleable && Loader === false ?
            <div variant="outline-primary" className='' onClick={(e) => toggleVisibility(e)}>
              {!isVisible ? <FaEye /> : <FaEyeSlash />}
            </div> :
            //static only view
            <div variant="outline-primary"   >
              {!isVisible ? <FaEye class="opacity-50 " /> : <FaEyeSlash class="opacity-50  " />}
            </div>

      }
    </React.Fragment>
  );
};

export default ShowHideIcon;
