import React, { useEffect, useState } from 'react'
import ShowHideIcon from '../../ShowHideIcon'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DayDifferenceToDynamicView } from '../HelperFunction/DayDifferenceToDynamicView'
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference '
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import Validation from '../../ValidationForm';
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import TableLoaders from '../../CustomLoader/TableLoaders'
import { useDispatch } from 'react-redux'
import { setValidationData } from '../../../reducer/validation/validationSlice'
import { conferenceUpdate } from '../../../reducer/detailedProfile/conferenceSlice'
import AttachmentForm from '../../AttachmentForm'
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments'
import { setDetailedProfileEditData } from '../../../reducer/edit/detailedProfileEditSlice'
import EditDetailedProfileConfandSkilling from '../Forms/Edit Forms/EditDetailedProfileConfandSkilling'
import DeleteDetailedProfileSlice, { setDeleteDetailedProfileData } from '../../../reducer/delete/deleteDetailedProfileSlice'
import DeleteFormDetailedProfile from '../../DeleteFormDetailedProfile'
import Anonimization from '../../Anonimization'

const ConferenceSummary = ({ data, editEnable }) => {
    const dispatch = useDispatch();
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    /* HANDLE EDIT FOR CONF */
    const handleEdit = (data) => {
        dispatch(setDetailedProfileEditData(data));
    };

    const handleValidate = (validationData) => {
        dispatch(setValidationData(validationData));
    };

    const handleAttachment = (selectedData) => {
        dispatch(setSkillOwnerAttachment(selectedData));
    };

    /* CHANGE VALIDATION RESULTS */
    const {
        getUserValidation: { userValidationData },
    } = useSelector((state) => state);

    useEffect(() => {
        /* CHECK FOR MATCHING ID AND CAPTURE DATA */
        data?.data?.forEach(dataItem => {
            userValidationData?.forEach(validationItem => {
                if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
                    dispatch(conferenceUpdate({ id: dataItem.id, validation: "Yes" }));
                }
            });
        });
    }, [data, userValidationData, dispatch]);

    return (
        <>
            {/* VALIDATION MODAL */}
            <Validation />
            {<AttachmentForm />}
            {/* DETAILED PROFILE EDIT MODAL */}
            {<EditDetailedProfileConfandSkilling />}
            <DeleteFormDetailedProfile />
            {/* table start */}
            <div className="table-responsive ">
                <table className='table table-sm   table-fixed table-hover    '>
                    <thead >
                        <tr className='border-dark-subtle border-bottom '>
                            <th scope="col" className='bg-body- ' style={{ width: "32%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ConferenceName') || {}).mvalue || "nf Conference Name "} </th>
                            <th scope="col" className='bg-body- ' style={{ width: "19%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "nf Organization"}   </th>
                            <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectDuration') || {}).mvalue || "nf Duration"}     </th>
                            <th scope="col" className='bg-body- ' style={{ width: "14%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectLocation') || {}).mvalue || "nf Location"}     </th>
                            <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf Validation"}     </th>
                            <th scope="col" className='bg-body- ' style={{ width: "10%" }}>              </th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {data.status === "loading" ?
                            <TableLoaders Rows={2} Cols={5} btnCols={2} />
                            : data.status === "success" && data.data.length > 0 && data.data.map((confs, index) => (

                                <tr className='' id={index}>
                                    <td >{confs.title ? (confs.title.length > 17 ? confs.title.substring(0, 17) + "..." : confs.title) : ''}</td>
                                    <td >{confs.organization ? (confs.organization.length > 17 ? confs.organization.substring(0, 17) + "..." : confs.organization) : ''}</td>
                                    <td >{DayDifferenceToDynamicView(
                                        confs.duration
                                    )}</td>
                                    <td>{confs.location ? (confs.location.length > 12 ? confs.location.substring(0, 12) + "..." : confs.location) : ''}</td>
                                    <td className=' '>{confs.validation}</td>
                                    <td className='d-flex justify-content-between' style={{ minHeight: "26px" }}>
                                        <>
                                            <button
                                                className="border-0 bg-white"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Validate"
                                                data-bs-toggle="modal"
                                                data-bs-target="#validationForm"
                                                onClick={() => handleValidate(confs)}
                                            >
                                                <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                                            </button>
                                            <button className='border-0 bg-white'
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Attachment"
                                                onClick={() => handleAttachment(confs)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#attachmentList">
                                                <ImAttachment />
                                            </button>
                                        </>
                                        {editEnable && (
                                            <>

                                                <div data-bs-toggle="modal" data-bs-target="#detailedProfileEditConfandSkillingModal" id="addModalBtn"><button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                                    onClick={() => handleEdit(confs)}
                                                ><MdEdit /></button></div>
                                                <ShowHideIcon value={confs.recordHide === 'Yes' ? true : false} toggleable={true}
                                                    form={confs} fieldName={"recordHide"} api={"confss Applied"} id={confs.id} />
                                                <Anonimization value={confs.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                                                    form={confs}  fieldName={"recordAnonymous"} api={"confss Applied"} id={confs.id} />
                                                <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                                    dispatch(setDeleteDetailedProfileData(confs))
                                                }}>  <MdDelete /> </button>
                                            </>
                                        )}
                                    </td>
                                </tr>)


                            )





                        }
                    </tbody>




                </table>
            </div>
            {/* table end */}


        </>
    )
}

export default ConferenceSummary