import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const educationInstitutionApi = async (value, selectedLanguage) => {

  selectedLanguage  = selectedLanguage.substring(0, 2);
  let SEARCH_URI =0;
  console.log("selectedLanguage" , selectedLanguage);
  SEARCH_URI = BASE_URL +
  `/skill/api/v1/skills/searchByName/Educational Institutions?authToken=${localStorage.getItem("token")}&searchFieldName=mlanguage&searchString=${selectedLanguage}&name=institutionName&nameSearch=${value}`;
  return axios
    .get(`${SEARCH_URI}`)
    .then((response) => {
      console.log("response ins", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default educationInstitutionApi;
