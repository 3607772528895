import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { FaPhone, FaFileSignature, FaEye } from "react-icons/fa6";
import Select from "react-select";
import { showErrorToast } from "../../ToastNotification/showErrorToast";
import { showSuccessToast } from "../../ToastNotification/showSuccessToast";
import { timestampToYYYYMMDD } from "../../SkillOwner/HelperFunction/timestampToYYYYMMDD";
import CreatableSelect from "react-select/creatable";
import EditApi from "../../../api/editData/EditApi";
import { FormatDateIntoPost } from "../../SkillOwner/HelperFunction/FormatDateIntoPost";
import SecondaryBtn from "../../Buttons/SecondaryBtn";
import AnonIcon from "../../../Images/anonymousLogo.png";
import { IoIosCamera } from "react-icons/io";
import { set } from "lodash";
import { showWarningToast } from "../../ToastNotification/showWarningToast";
import axios from "axios";
import { BASE_URL } from "../../../config/Properties";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  editExistingUserProfile,
  listaddNewUserProfile,
  setUserInfo,
} from "../../../reducer/userDetails/UserProfileSlice";
import ReactDOM from "react-dom";
import Avatar from "react-avatar-edit";
import UserDetailsApi from "../../../api/auth/UserDetailsApi";
import { formatTimestampToDate } from "./../../SkillOwner/HelperFunction/FormatTimestampToDate";
import AttachmentPost from "../../../api/Attachment  API/AttachmentPost";
import ShowHideIcon from "../../ShowHideIcon";
import Anonimization from "../../Anonimization";
import MultiSelect from "../../SkillOwner/SelectComponent/MultiSelect";
import CreateSelectForLocation from "./../../SkillOwner/SelectComponent/CreateSelectForLocation";
import MultiSelectProfile from "../../SkillOwner/SelectComponent/MultiSelectProfile";
import LocationSuggestionApi from "../../../api/locationApi/LocationSuggestionApi";
import Loader from "../../Loader";
import SmallLoader from "./../../SkillAvailer/SmallLoader";
import UserProfileGallery from "../../UserProfileGallery";
import user from "../../../Images/avatar-placeholder.webp";
import { GetAttachment } from "../../../api/Attachment  API/DownloadAttachmentApi";
import SecondaryBtnLoader from "./../../Buttons/SecondaryBtnLoader";
import { setLanguage } from "../../../reducer/localization/languageSlice";
import DatePicker from "react-datepicker";
import { formatDateInputType } from "../../SkillOwner/HelperFunction/FormatDateInputType";

const UserProfile = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const userDetails = useSelector((state) => state.userProfile.data);
  const userInfo = useSelector(
    (state) => state.userProfile.accountDetails.userInfo
  );
  const selectedLanguage = useSelector((state) => state.language);
  const content = useSelector((state) => state.content);
  const regionalData = useSelector((state) => state.regionalData);

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pp, setPP] = useState(
    "https://bootdey.com/img/Content/avatar/avatar7.png"
  );
  const [preview, setPreview] = useState({
    preview: null,
    src: "https://bootdey.com/img/Content/avatar/avatar7.png",
  });

  const [date, setDate] = useState(false);

  const [location, setLocation] = useState([]);
  const [online, setOnline] = useState(false);
  const [locationToArray, setLocationToArray] = useState([]);
  //image edit and add

  const [iniProfilePicture, setIniProfilePicture] = useState(null); //file upload
  const [profilePicture, setProfilePicture] = useState(null); //file upload
  const [isFileUploaded, setIsFileUploaded] = useState(false); //file error

  const [profilePicName, setProfilePicName] = useState(null); //list of file names
  const [picReloader, setPicReloader] = useState(false);

  const [hideProfile, setHideProfile] = useState(false);

  const [profilePicObj, setProfilePicObj] = useState({});

  //API's Data
  const [countryData, setCountryData] = useState([]);

  const [isNewLangProfile, setIsNewLangProfile] = useState(false);

  const setuserInfo = useCallback(
    (newUserInfo) => {
      dispatch(setUserInfo(newUserInfo)); // Dispatch the action to update userInfo
    },
    [dispatch]
  );

  useEffect(() => { }, [selectedLanguage]);

  // const [userInfo, setuserInfo] = useState({});

  // const [userInfo, setuserInfo] = useState({
  //     firstName: "Prakash",
  //     lastName: "k",
  //     email: "prakashk@gmail.com",
  //     mobileNumber: "(239) 816-9029",
  //     authenticationtoken: "",
  //     candidateState: "",
  //     city: "",
  //     mobileNumber: "",
  //     city: "Chennai",
  //     recoveryEmail: "",
  //     accountId: "",
  //     dob: "26/12/2001",
  //     description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique voluptatem fuga impedit eos repellendus maiores saepe, perspiciatis nulla sint quidem voluptates. nesciunt saepe. Molestias numquam aut voluptatem in cum.",
  //     userCountry: "India",
  //     mgender: "",
  //     address1: "KIIT square 2nd Phase",
  //     homeLocationCity: "",
  //     location: "",
  //     mgender: "Male",
  //     userRole: "",
  //     postalCode: "760010",
  //     organization: "",
  //     userId: "",
  // });

  function stringConvertor(fileId, fileName, title) {
    return `fileName = ${fileName} || fileId = ${fileId} || title = ${title}`;
  }

  function splitStringToObject(str) {
    try {
      const parts = str.split("||").map((part) => part.trim());
      const obj = {};
      parts?.forEach((part) => {
        const [key, value] = part.split("=").map((item) => item.trim());
        obj[key] = value;
      });
      return obj;
    } catch (error) {
      console.error("Error occurred while parsing the string:", error.message);
      return {}; // Return an empty object in case of failure
    }
  }

  const initialState = {};

  const [isLangProfile, setIsLangProfile] = useState(true);

  useEffect(() => {
    // Convert object to an array
    const userDetailsArray = Object.values(userDetails);

    console.log("usd `1 ", userDetails?.[0]);

    // Check if userDetailsArray is not empty
    if (Array.isArray(userDetailsArray) && userDetailsArray.length > 0) {
      const userForSelectedLanguage = userDetailsArray.find(
        (userObject) => userObject && userObject.mlanguage === selectedLanguage
      );

      if (userForSelectedLanguage) {
        setIsNewLangProfile(false);
        //setProfilePicObj(splitStringToObject(userForSelectedLanguage?.profilePictureFileName));
        setuserInfo({ ...userForSelectedLanguage });

        setIsLangProfile(true);
        console.log("Language matched! userInfo updated.");
        // setEdit(false);
      } else {
        setIsNewLangProfile(true);
        // showWarningToast(`User profile is not in ${selectedLanguage}, Please update the profile in ${selectedLanguage}.`);
        document.getElementById("clickConfirmLang").click();

        console.log("No user profile found for the selected language.");
      }
    } else {
      console.log("userDetails is not a non-empty array.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, localStorage.getItem("userName"), userDetails]);

  const handleChanges = (e) => {
    if (e.target.id === "dob") {
      var someDate = new Date(e.target.value);
      someDate = someDate.getTime();
      console.log("some date ", someDate);

      setuserInfo({ ...userInfo, [e.target.id]: `${someDate}` });
      return;
    }

    setuserInfo({ ...userInfo, [e.target.id]: e.target.value });
  };

  // useEffect(() => {
  //   console.log(userInfo);
  //   if (userInfo?.profilePictureFileName?.length > 1) {
  //     setProfilePicObj(splitStringToObject(userInfo?.profilePictureFileName));
  //   }

  //   if (userInfo?.location?.length > 0) {
  //     let locToString = "";
  //     if (location.length > 0)
  //       locToString = location.map((item) => item.value).join(",");
  //     console.log("loc " + locToString);
  //     if (locToString !== userInfo?.location) {
  //       let tempLocArray = userInfo?.location.split(",");
  //       const data = tempLocArray.map((item) => {
  //         return { value: item, label: item };
  //       });
  //       console.log(data);
  //       setLocation(data);
  //     }
  //   }
  // }, [userInfo]);

  useEffect(() => {
    console.log(userInfo);
    console.log(FormatDateIntoPost(timestampToYYYYMMDD( Number(userInfo?.dob))));
    if (userInfo?.profilePictureFileName?.length > 1) {
      setProfilePicObj(splitStringToObject(userInfo?.profilePictureFileName));
    }

    if (userInfo?.location?.length > 0) {
      let locToString = "";
      if (location.length > 0)
        locToString = location.map((item) => item.value).join(",");
      console.log("loc " + locToString);
      if (locToString !== userInfo?.location) {
        let tempLocArray = userInfo?.location.split(",");
        const data = tempLocArray.map((item) => {
          return { value: item, label: item };
        });
        console.log(data);
        setLocation(data);
      }
    }
  }, [userInfo]);

  const handlePPChanges = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        showErrorToast("Wrong image type");
        return;
      }

      // if (file.size < 100000) {
      //     showErrorToast('image size is too small')
      //     return;
      // }

      if (file.size > 5000) {
        showErrorToast("image size is too large");
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setPP(imageUrl);
    }
  };

  const handleCancel = () => {
    // setuserInfo({
    //     firstName: "Prakash",
    //     lastName: "k",
    //     email: "prakashk@gmail.com",
    //     mobileNumber: "(239) 816-9029",
    //     authenticationtoken: "",
    //     candidateState: "",
    //     city: "",
    //     mobileNumber: "",
    //     city: "Chennai",
    //     recoveryEmail: "",
    //     accountId: "",
    //     dob: "26/12/2001",
    //     description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique voluptatem fuga impedit eos repellendus maiores saepe, perspiciatis nulla sint quidem voluptates. nesciunt saepe. Molestias numquam aut voluptatem in cum.",
    //     userCountry: "India",
    //     mgender: "",
    //     address1: "KIIT square 2nd Phase",
    //     homeLocationCity: "",
    //     location: "",
    //     mgender: "Male",
    //     userRole: "",
    //     postalCode: "760010",
    //     organization: "",
    //     userId: "",
    // })
    // setPP("https://bootdey.com/img/Content/avatar/avatar7.png")
    setEdit(false);
    setEditProfilePic(false);
    setuserInfo({ ...userDetails[0] });
    setSelectedCity(undefined);
    setSelectedCountry(undefined);
    setProfilePicture(null);
    setProfilePicName(null);
    setIniProfilePicture(null);
  };

  const handleAnonClickOnSection = () => { };

  const handleSubmit = (e) => {
    console.log(userInfo);

    if (
      !userInfo?.firstName ||
      userInfo?.firstName.trim().length === 0 ||
      !userInfo?.lastName ||
      userInfo?.lastName.trim().length === 0 ||
      // !userInfo?.mobileNumber || userInfo?.mobileNumber.trim().length === 0 ||
      !userInfo?.dob ||
      userInfo?.dob.trim().length === 0
      // !userInfo?.mgender || userInfo?.mgender.trim().length === 0 ||
      // !userInfo?.address1 || userInfo?.address1.trim().length === 0 ||
      // !userInfo?.postalCode || userInfo?.postalCode.trim().length === 0 ||
      // !userInfo?.about || userInfo?.about.trim().length === 0
    ) {
      showErrorToast("Please fill all required fields");
      return;
    }
    if (
      !userInfo?.userCountry ||
      userInfo?.userCountry.trim().length === 0 ||
      !userInfo?.city ||
      userInfo?.city.trim().length === 0 ||
      !userInfo?.userState ||
      userInfo?.userState.trim().length === 0
    ) {
      if (
        !selectedCity &&
        selectedCity?.label?.length > 0 &&
        !selectedCountry &&
        selectedCountry?.label?.length > 0
      ) {
        showErrorToast("Please fill country");
        return;
      }
    }

    e.preventDefault();
    setEditProfilePic(false);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    //    const isValidUserName = emailRegex.test(userDetails.email);
    //    if(!isValidUserName){
    //     showErrorToast('Please enter a valid email')
    //     return
    // }
    const isValidEmail = emailRegex.test(userDetails.email);
    if (!isValidEmail) {
      // showErrorToast('Please enter a valid email')
      // return
    }

    setLoading(true);

    //calling profile pic attachment api if added

    if (profilePicture != null) {
      setPicReloader(true);
      const formData = new FormData();
      formData.append("file", profilePicture);
      AttachmentPost("handleMultiFile", userInfo?.id, formData)
        .then((res) => {
          showSuccessToast("Profile Picture uploaded");
          console.log("file upload result ", res);
          setProfilePicture(null);
          setIsFileUploaded(true);
          setPicReloader(false);
          const profilepic = stringConvertor(
            res.data?.[0].fileId,
            res.data?.[0].fileName,
            "title"
          );
          console.log("profilepic ", profilepic);
          const data = {
            ...userInfo,
            profilePictureFileName: profilepic,
            userCountry:
              selectedCountry !== undefined
                ? selectedCountry.label
                : userInfo?.userCountry,
            city:
              selectedCity !== undefined ? selectedCity.label : userInfo?.city,
            mlanguage: selectedLanguage,
            dob: FormatDateIntoPost(timestampToYYYYMMDD( Number(userInfo?.dob))),
            location:
              location.length > 0
                ? location.map((item) => item.value).join(", ")
                : "",
          };
          var someDate = new Date(e.target.value);
          someDate = someDate.getTime();
          console.log("some date ", someDate);
          //Edit api calling :
          EditApi("User Details", userInfo?.id, data)
            .then((res) => {
              console.log("User Details result ", res);
              showSuccessToast("Profile Updated Successfully");
              dispatch(
                editExistingUserProfile({
                  id: userInfo?.id,
                  updatedData: {
                    ...res.data,
                    dob: `${new Date(res.data.dob).getTime()}`,
                  },
                })
              );
              //setuserInfo(userDetails[0]);
              setLoading(false);
              setEdit(false);
              setProfilePicName(null);
              UserDetailsApi().then((res) => setuserInfo({ ...res.data[0] }));
            })
            .catch((err) => {
              setuserInfo({ ...userDetails[0] });

              console.log(err);
              showErrorToast("Something went wrong");
              setLoading(false);
              setEdit(false);
            });
        })
        .catch((err) => {
          setIsFileUploaded(false);
          setPicReloader(false);
        });
    } else if (isLangProfile) {
      const data = {
        ...userInfo,

        userCountry:
          selectedCountry !== undefined
            ? selectedCountry.label
            : userInfo?.userCountry,
        city: selectedCity !== undefined ? selectedCity.label : userInfo?.city,
        mlanguage: selectedLanguage,
        dob: FormatDateIntoPost(timestampToYYYYMMDD( Number(userInfo?.dob))),
        location:
          location.length > 0
            ? location.map((item) => item.value).join(", ")
            : "",
      };
      var someDate = new Date(e.target.value);
      someDate = someDate.getTime();
      console.log("some date ", someDate);
      //Edit api calling :
      EditApi("User Details", userInfo?.id, data)
        .then((res) => {
          console.log(res);
          showSuccessToast("Profile Updated Successfully");
          dispatch(
            editExistingUserProfile({
              id: userInfo?.id,
              updatedData: {
                ...res.data,
                dob: `${new Date(res.data.dob).getTime()}`,
              },
            })
          );
          //setuserInfo(userDetails[0]);
          setLoading(false);
          setEdit(false);
          setProfilePicName(null);

          UserDetailsApi().then((res) => setuserInfo({ ...res.data[0] }));
        })
        .catch((err) => {
          setuserInfo({ ...userDetails[0] });

          console.log(err);
          showErrorToast("Something went wrong");
          setLoading(false);
          setEdit(false);
        });
    } else {
      const data = {
        ...userInfo,
        attachmentFileNames: isFileUploaded
          ? profilePicName
          : userInfo?.attachmentFileNames,
        userCountry:
          selectedCountry !== undefined
            ? selectedCountry.label
            : userInfo?.userCountry,
        city: selectedCity !== undefined ? selectedCity.label : userInfo?.city,
        mlanguage: selectedLanguage,
        dob: FormatDateIntoPost(timestampToYYYYMMDD( Number(userInfo?.dob))),
        location:
          location.length > 0
            ? location.map((item) => item.value).join(", ")
            : "",
      };
      axios
        .post(
          `${BASE_URL}/skill/api/v1/skills/create/User Details`,
          {
            ...data,
            UserID: localStorage.getItem("userId"),
            accountId: localStorage.getItem("userName"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("response:", response.data);
          dispatch(listaddNewUserProfile(response.data));
          showSuccessToast(
            "Profile Created Successfully in " + selectedLanguage + " language."
          );
          setLoading(false);
          setEdit(false);
          setIsLangProfile(true);
          UserDetailsApi();
        })
        .catch((error) => {
          console.log("error:", error);
          setLoading(false);
        });
    }
  };
  //edit profile picture handling

  const onBeforeFileLoad = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > 5e6) {
        showErrorToast("image size is too large");

        e.target.value = "";
        return;
      }

      if (!file.name.match(/\.(jpeg|jpg|png)$/)) {
        showErrorToast("Wrong file type");

        e.target.value = "";
        return;
      }
      //setProfilePicture(file)

      setIniProfilePicture(file);
      return;
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //Avatar cropping
  const onCrop = (preview) => {
    setPreview({ preview });
    console.log(preview);
    var file = dataURLtoFile(preview, iniProfilePicture.name);
    console.log(file);
    setProfilePicture(file);
    setProfilePicName(file.name);
  };
  //Avatar cropping
  const onClose = () => {
    setPreview({ preview: null });
    setProfilePicture(null);
    setIniProfilePicture(null);
  };

  const [editProfilePic, setEditProfilePic] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedCity, setSelectedCity] = useState(undefined);

  const [cityData, setCityData] = useState([]);
  const handleCountryKeyStoke = (e) => {
    const val = e;
    console.log(e);
    if (e.length > 1) {
      const data = regionalData?.listOfCountries;

      //eslint-disable-next-line
      setCountryData(
        data.map((item) => ({
          ...item,
          value: item.country,
          label: item.country,

          latitude: item.cityLatitude,
          longitude: item.cityLongitude,
        }))
      );
    }
  };

  const handleCityKeyStroke = (e) => {
    const val = e;
    console.log(e);
    if (e.length > 1) {
      LocationSuggestionApi(e, "city", selectedCountry.countryCode)
        .then((res) => {
          const data = res.data;

          //eslint-disable-next-line
          setCityData(
            data.map((item) => ({
              ...item,
              value: item.city,
              label: item.city,
              latitude: item.cityLatitude,
              longitude: item.cityLongitude,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    console.log(selectedCity);
    console.log(selectedCountry);
    console.log(regionalData?.listOfCountries);
  }, [selectedCity, selectedCountry, regionalData?.listOfCountries]);

  useEffect(() => {
    console.log(picReloader);
  }, [picReloader]);

  const handleAllMandatoryChange = (e) => {
    if (e.target.checked) {
      setHideProfile(true);
    } else {
      setHideProfile(false);
    }
  };
  useEffect(() => {
    console.log("file obj ", profilePicObj);
  }, [profilePicObj]);

  const handleCreateNewLangProfile = () => {
    document.getElementById("userProfileshow").click();
    setEdit(true);
    setIsLangProfile(false);
    setuserInfo(initialState);
  };

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  const handleCancelNewLang = useCallback(
    (isClose) => {
      if (isClose === "new_") handleModalClose();

      setIsLangProfile(true);
      setEdit(false);
      setuserInfo({ ...userDetails[0] });
      const prevLang = localStorage.getItem("prevLang");
      if (
        prevLang !== null &&
        prevLang !== undefined &&
        prevLang !== selectedLanguage
      ) {
        dispatch(setLanguage(prevLang));
      } else {
        dispatch(setLanguage("EN-US"));
      }
    },
    [dispatch, handleModalClose, selectedLanguage, setuserInfo, userDetails]
  );

  return (
    <div>
      <div
        class="modal fade modal-xl"
        id="setting"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <button
          data-bs-toggle="modal"
          data-bs-target="#setting"
          id="userProfileshow"
        ></button>

        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 me-3" id="exampleModalLabel">
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "MyProfile"
                  ) || {}
                ).mvalue || "nf My Profile"}
              </h1>
              {/* {edit ?
                                <div className='d-flex   ' style={{ justifyContent: "flex-start", fontSize: "12px", fontWeight: "600" }}>
                                    <div class="form-check form-switch d-flex align-items-center " style={{ borderColor: "#815F0B" }}>
                                        <input
                                            class="form-check-input" onChange={(e) => handleAllMandatoryChange(e)}
                                            style={{ borderColor: "#815F0B" }} checked={hideProfile}
                                            type="checkbox" role="switch"
                                            id={"flexSwitchCheckChecked"}
                                        />

                                    </div>
                                    <div className=''>
                                        {(content[selectedLanguage].find(item => item.elementLabel === 'HideProfile') || {}).mvalue || "nf Hide Profile"}
                                    </div>
                                </div>
                                : hideProfile &&
                                <div className='fst-italic ' >{(content[selectedLanguage].find(item => item.elementLabel === 'ProfileHidden') || {}).mvalue || "nf Profile Hidden"}</div>
                            } */}
              {!edit ? (
                <div className="d-flex justify-content-end w-100">
                  <SecondaryBtnLoader
                    onClick={() => setEdit(true)}
                    label={
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Edit"
                        ) || {}
                      ).mvalue || "nf Edit"
                    }
                    backgroundColor="#F8F8E9"
                    color="#815F0B"
                  />
                </div>
              ) : (
                <div class="d-flex justify-content-end w-100">
                  <SecondaryBtnLoader
                    onClick={(e) => handleSubmit(e)}
                    label={
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Save"
                        ) || {}
                      ).mvalue || "nf save"
                    }
                    backgroundColor="#F8F8E9"
                    loading={loading}
                    color="#815F0B"
                  />
                  {!isNewLangProfile && (
                    <SecondaryBtnLoader
                      onClick={() => handleCancel()}
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Cancel"
                          ) || {}
                        ).mvalue || "nf Cancel"
                      }
                      backgroundColor="#F8F8E9"
                      color="#815F0B"
                    />
                  )}
                  {/* IF NEW USER */}
                  {/* {isNewLangProfile && (
                    <SecondaryBtnLoader
                      onClick={() => handleCancelNewLang("new_")}
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Cancel"
                          ) || {}
                        ).mvalue || "nf Cancel"
                      }
                      backgroundColor="#F8F8E9"
                      color="#815F0B"
                    />
                  )} */}
                </div>
              )}
              {!isNewLangProfile && (
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                  onClick={() => setEdit(false)}
                ></button>
              )}
              {/* IF NEW USER */}
              {isNewLangProfile && (
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCancelNewLang("new_")}
                ></button>
              )}
            </div>
            <div class="modal-body">
              {loading ? (
                <div
                  class="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh" }}
                >
                  <div
                    class="spinner-border"
                    style={{ width: "2rem", height: "2rem" }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {/* EDITABLE FORM */}

                  {edit ? (
                    <div class="container">
                      <div class="main-body">
                        <div class="row gutters-sm">
                          <div class="col-md-4 mb-3">
                            <div class="card ">
                              {/* Basic details EDit */}
                              <div
                                class="accordion"
                                id="accordionPanelsStayOpenExample"
                              >
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div className="d-flex">
                                      <button
                                        class="accordion-button"
                                        type="button"
                                        style={{
                                          backgroundColor:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarBgColor"
                                              ) || {}
                                            ).mvalue || "#577126",
                                          color:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarFontColor"
                                              ) || {}
                                            ).mvalue || "#F7FFDD",
                                        }}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "BasicDetails"
                                          ) || {}
                                        ).mvalue || "nf Basic Details"}
                                      </button>
                                    </div>
                                  </h2>
                                  <div
                                    id="panelsStayOpen-collapseOne"
                                    class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionPanelsStayOpenExample"
                                  >
                                    <div class="accordion-body">
                                      <div class="card">
                                        <div class="card-body d-flex justify-content-center">
                                          <div class="d-flex flex-column align-items-center text-center">
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <div
                                                style={{
                                                  width: "150px",
                                                  height: "150px",
                                                }}
                                                class="rounded-circle"
                                              >
                                                {editProfilePic ? (
                                                  <div>
                                                    <div
                                                      style={{
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Avatar
                                                        width={150}
                                                        onCrop={onCrop}
                                                        onClose={onClose}
                                                        height={150}
                                                        onBeforeFileLoad={
                                                          onBeforeFileLoad
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {/* <div>
                                                                                                                    <Anonimization value={userInfo?.ppShowHide === 'Yes' ? true : false} />
                                                                                                                </div> */}
                                                    {userInfo?.profilePictureFileName &&
                                                      userInfo
                                                        ?.profilePictureFileName
                                                        .length > 2 ? (
                                                      <img
                                                        style={{
                                                          objectFit: "cover",
                                                        }}
                                                        //`${BASE_URL}/skill/api/v1/skills/imgHandle/filedownload/${userInfo?.id}/${userInfo?.attachmentFileNames}?authToken=${token}`
                                                        src={GetAttachment(
                                                          userInfo?.id,
                                                          profilePicObj?.fileName,
                                                          profilePicObj?.fileId
                                                        )}
                                                        alt="Admin"
                                                        class="rounded-circle"
                                                        width="150"
                                                        height="150"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={user}
                                                        alt="Admin"
                                                        class="rounded-circle"
                                                        width="150"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              {/* <img src={preview.preview} ></img> */}
                                              {!editProfilePic && (
                                                <div>
                                                  <label
                                                    htmlFor="profilePic"
                                                    style={{
                                                      position: "absolute",
                                                      bottom: 0,
                                                      right: "10px",
                                                      backgroundColor: "white",
                                                      cursor: "pointer",
                                                    }}
                                                    className="p-1 rounded-circle"
                                                  >
                                                    <IoIosCamera
                                                      style={{
                                                        fontSize: "25px",
                                                      }}
                                                      onClick={() =>
                                                        setEditProfilePic(true)
                                                      }
                                                    />
                                                    {/* <input type="file" id='profilePic' class="form-control" style={{ height: "32px", display: 'none' }} /> */}
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div class="col-sm-1 text-secondary">
                                            <Anonimization
                                              value={
                                                userInfo?.ppShowHide === "Yes"
                                                  ? true
                                                  : false
                                              }
                                              toggleable={true}
                                              form={userInfo}
                                              setForm={setuserInfo}
                                              fieldName={"ppShowHide"}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row mt-2">
                                        <div class="col-sm-4 p-0 ps-3">
                                          <h6 class="m-0 p-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "FirstName"
                                              ) || {}
                                            ).mvalue || "nf First Name"}<span className='text-danger '>*</span>
                                          </h6>
                                        </div>
                                        <div class="col-sm-7 text-secondary">
                                          <input
                                            type="text"
                                            id="firstName"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.firstName}
                                            onChange={(e) => handleChanges(e)}
                                          /> 
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.fn === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"fn"}
                                          />
                                        </div>
                                      </div>
                                      <hr className="m-2"></hr>
                                      <div class="row ">
                                        <div class="col-sm-4 p-0 ps-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "LastName"
                                              ) || {}
                                            ).mvalue || "nf LastName"} <span className='text-danger '>*</span>
                                          </h6>
                                        </div>
                                        <div class="col-sm-7 text-secondary">
                                          <input
                                            type="text"
                                            id="lastName"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.lastName}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.mlnShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"mlnShowHide"}
                                          />
                                        </div>
                                      </div>
                                      <hr className="m-2"></hr>
                                      <div class="row">
                                        <div class="col-sm-4">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "Gender"
                                              ) || {}
                                            ).mvalue || "nf Gender"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-7 text-secondary">
                                          <input
                                            type="text"
                                            id="mgender"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.mgender}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.genderShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"genderShowHide"}
                                          />
                                        </div>
                                      </div>
                                      <hr className="m-2"></hr>
                                      <div class="row">
                                        <div class="col-sm-4 p-0 ps-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "DateOfBirth"
                                              ) || {}
                                            ).mvalue || "nf DOB"} <span className='text-danger '>*</span>
                                          </h6>
                                        </div>
                                        <div class="col-sm-7 text-secondary">
                                          {/* <input
                                            type="date"
                                            id="dob"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={timestampToYYYYMMDD(
                                              Number(userInfo?.dob)
                                            )}
                                            max={new Date()
                                              .toJSON()
                                              .slice(0, 10)}
                                            onChange={(e) => handleChanges(e)}
                                          /> */}
                                          <DatePicker
                                            // showIcon
                                            toggleCalendarOnIconClick
                                            selected={userInfo?.dob ? timestampToYYYYMMDD( Number(userInfo?.dob) ) : ''}
                                            onChange={(e) => {
                                              if (e){

                                              var someDate = new Date(e);
                                              someDate = someDate.getTime();
                                              console.log("some date ", someDate);

                                              setuserInfo({ ...userInfo, dob: `${someDate}` });
                                              }
                                            }}
                                            dateFormat={formatDateInputType(regionalData.selectedCountry.dateFormat)}
                                            placeholderText={localStorage.getItem("dateFormat")}

                                            maxDate={timestampToYYYYMMDD(Date.now())}

                                            className="  buttom-line-input px-0 form-control  h-75 "
                                            id="exampleFormControlInput1"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            // showMonthDropdown
                                            // scrollableMonthDropdown
                                            yearDropdownItemNumber={100}


                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.dobShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"dobShowHide"}
                                          />
                                        </div>
                                      </div>
                                      <hr className="m-2"></hr>
                                      <div class="row">
                                        <div class="col-sm-11">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "About"
                                              ) || {}
                                            ).mvalue || "nf About"}
                                          </h6>
                                        </div>
                                        {/* <div class="col-sm-1 text-secondary">
                                                                                            <Anonimization value={userInfo?.aboutShowHide === 'Yes' ? true : false} toggleable={true} form={userInfo} setForm={setuserInfo} fieldName={"aboutShowHide"} />
                                                                                        </div> */}
                                        <div className="d-flex justify-content-end">
                                          <div
                                            class="col-sm-11  text-secondary"
                                            style={{ minHeight: "4rem" }}
                                          >
                                            <textarea
                                              id="about"
                                              class="form-control"
                                              style={{ height: "70px" }}
                                              value={userInfo?.about}
                                              onChange={(e) => handleChanges(e)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            {/* RIGHT BOX NAME, EMAIL, PHONE */}
                            <div class="card mb-3">
                              <div class="accordion" id="rightForm">
                                {/* Contact infos Edit*/}
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div className="d-flex">
                                      <button
                                        class="accordion-button"
                                        style={{
                                          backgroundColor:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarBgColor"
                                              ) || {}
                                            ).mvalue || "#000",
                                          color:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarFontColor"
                                              ) || {}
                                            ).mvalue || "#000",
                                        }}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#ContactDetails"
                                        aria-expanded="true"
                                        aria-controls="ContactDetails"
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "ContactDetails"
                                          ) || {}
                                        ).mvalue || "nf Contact Details"}
                                      </button>
                                    </div>
                                  </h2>
                                  <div
                                    id="ContactDetails"
                                    class="accordion-collapse collapse show"
                                  >
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "Email"
                                              ) || {}
                                            ).mvalue || "nf Email"} <span className='text-danger '>*</span>
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="email"
                                            id="email"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.email}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.memailShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"memailShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "Phone"
                                              ) || {}
                                            ).mvalue || "nf Phone"} <span className='text-danger '>*</span>
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            id="mobileNumber"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.mobileNumber}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.mnShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"mnShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Address"
                                              ) || {}
                                            ).mvalue || "nf Address"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            id="address1"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.address1}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.address1ShowHide ===
                                                "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"address1ShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "Country"
                                              ) || {}
                                            ).mvalue || "nf Country"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          {/* <input type="text" id='userCountry' class="form-control" style={{ height: "32px" }} value={userInfo?.userCountry} onChange={(e) => handleChanges(e)} />
                                           */}
                                          <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={
                                              userInfo?.userCountry && {
                                                label: userInfo?.userCountry,
                                                value: userInfo?.userCountry,
                                              }
                                            }
                                            //isLoading={true}
                                            isClearable={true}
                                            //isRtl={true}
                                            isSearchable={true}
                                            name="Country"
                                            options={countryData}
                                            onInputChange={(e) =>
                                              handleCountryKeyStoke(e)
                                            }
                                            onChange={(e) =>
                                              setSelectedCountry(e)
                                            }
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.countryShowHide &&
                                                userInfo?.countryShowHide ===
                                                "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"countryShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "State"
                                              ) || {}
                                            ).mvalue || "nf State"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            id="userState"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.userState}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.stateShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"stateShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel === "City"
                                              ) || {}
                                            ).mvalue || "nf City"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          {/* <input type="text" id='city' class="form-control" style={{ height: "32px" }} value={userInfo?.city} onChange={(e) => handleChanges(e)} /> */}
                                          <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={
                                              userInfo?.city && {
                                                label: userInfo?.city,
                                                value: userInfo?.city,
                                              }
                                            }
                                            // isLoading={true}
                                            isClearable={true}
                                            //isRtl={true}
                                            isSearchable={true}
                                            name="city"
                                            options={cityData}
                                            onInputChange={(e) =>
                                              handleCityKeyStroke(e)
                                            }
                                            onChange={(e) => setSelectedCity(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.cityShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"cityShowHide"}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "PostalCode"
                                              ) || {}
                                            ).mvalue || "nf Postal Code"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            id="postalCode"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                            value={userInfo?.postalCode}
                                            onChange={(e) => handleChanges(e)}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={
                                              userInfo?.pcShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                            toggleable={true}
                                            form={userInfo}
                                            setForm={setuserInfo}
                                            fieldName={"pcShowHide"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Socials view only */}
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div className="d-flex">
                                      <button
                                        style={{
                                          backgroundColor:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarBgColor"
                                              ) || {}
                                            ).mvalue || "#577126",
                                          color:
                                            (
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "NavBarFontColor"
                                              ) || {}
                                            ).mvalue || "#F7FFDD",
                                        }}
                                        class="accordion-button "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseThree"
                                      >
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "SocialMediaLinks"
                                          ) || {}
                                        ).mvalue || "nf Social Media Links"}
                                      </button>
                                    </div>
                                  </h2>
                                  <div
                                    id="panelsStayOpen-collapseThree"
                                    class="accordion-collapse collapse show"
                                  >
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mx-2"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-globe mr-2 icon-inline"
                                              >
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                ></circle>
                                                <line
                                                  x1="2"
                                                  y1="12"
                                                  x2="22"
                                                  y2="12"
                                                ></line>
                                                <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                              </svg>
                                              <span className="mx-1">
                                                Website
                                              </span>
                                            </h6>
                                          </li>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mx-2"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-github mr-2 icon-inline"
                                              >
                                                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                              </svg>
                                              <span className="mx-1">
                                                Github
                                              </span>
                                            </h6>
                                          </li>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mx-2"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-twitter mr-2 icon-inline text-info"
                                              >
                                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                              </svg>
                                              <span className="mx-1">
                                                Twitter
                                              </span>
                                            </h6>
                                          </li>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mx-2"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-instagram mr-2 icon-inline text-danger"
                                              >
                                                <rect
                                                  x="2"
                                                  y="2"
                                                  width="20"
                                                  height="20"
                                                  rx="5"
                                                  ry="5"
                                                ></rect>
                                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                                <line
                                                  x1="17.5"
                                                  y1="6.5"
                                                  x2="17.51"
                                                  y2="6.5"
                                                ></line>
                                              </svg>
                                              <span className="mx-1">
                                                Instagram
                                              </span>
                                            </h6>
                                          </li>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>

                                        <div class="col-sm-3">
                                          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 class="mb-0">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mx-2"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-facebook mr-2 icon-inline text-primary"
                                              >
                                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                              </svg>
                                              <span className="mx-1">
                                                Facebook
                                              </span>
                                            </h6>
                                          </li>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <input
                                            type="text"
                                            class="form-control"
                                            style={{ height: "32px" }}
                                          />
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* miscellaneous */}
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <button
                                      class="accordion-button "
                                      style={{
                                        backgroundColor:
                                          (
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "NavBarBgColor"
                                            ) || {}
                                          ).mvalue || "#0000",
                                        color:
                                          (
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "NavBarFontColor"
                                            ) || {}
                                          ).mvalue || "#ffff",
                                      }}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#panelsStayOpen-collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="panelsStayOpen-collapseTwo"
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "PrefferedWorkingLocation"
                                        ) || {}
                                      ).mvalue ||
                                        "nf Preffered Working Location"}
                                    </button>
                                  </h2>
                                  <div
                                    id="panelsStayOpen-collapseTwo"
                                    class="accordion-collapse collapse show"
                                  >
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-sm-3 mt-4">
                                          <h6 class="mb-0">
                                            {(
                                              content[selectedLanguage].find(
                                                (item) =>
                                                  item.elementLabel ===
                                                  "PreferredLocation"
                                              ) || {}
                                            ).mvalue || "nf Preferred Location"}
                                          </h6>
                                        </div>
                                        <div class="col-sm-8 text-secondary">
                                          <div className="">
                                            <div className="d-flex justify-content-end">
                                              <div className="d-flex align-items-center justify-align-content">
                                                <input
                                                  id="onlineCheckbox"
                                                  className="ms-2"
                                                  type="checkbox"
                                                  name="online"
                                                  checked={online}
                                                  onChange={(e) => {
                                                    const isChecked =
                                                      e.target.checked;
                                                    setOnline(isChecked);
                                                  }}
                                                />
                                                <label
                                                  htmlFor="onlineCheckbox"
                                                  className="ms-1"
                                                >
                                                  {" "}
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "Online"
                                                    ) || {}
                                                  ).mvalue || "nf Online"}
                                                </label>
                                              </div>
                                            </div>
                                            <MultiSelectProfile
                                              setLocationData={setLocation}
                                              viewLocation={location}
                                              onlineStatus={online}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-sm-1 text-secondary">
                                          <Anonimization
                                            value={true}
                                            toggleable={false}
                                          />
                                        </div>

                                        <hr className="m-2"></hr>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // PROFILE VIEW ONLY
                    <div class="container">
                      <div class="main-body">
                        <div class="row gutters-sm">
                          <div class="col-md-4 mb-2">
                            {/* basic details accordian */}
                            <div class="accordion" id="basicDetailsView">
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <div className="d-flex">
                                    <button
                                      class="accordion-button"
                                      type="button"
                                      style={{
                                        backgroundColor:
                                          (
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "NavBarBgColor"
                                            ) || {}
                                          ).mvalue || "#000",
                                        color:
                                          (
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "NavBarFontColor"
                                            ) || {}
                                          ).mvalue || "#ffff",
                                      }}
                                      data-bs-toggle="collapse"
                                      data-bs-target="#BasicDetailsView"
                                      aria-expanded="true"
                                      aria-controls="BasicDetailsView"
                                    >
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "BasicDetails"
                                        ) || {}
                                      ).mvalue || "nf BasicDetails"}
                                    </button>
                                  </div>
                                </h2>
                                <div
                                  id="BasicDetailsView"
                                  class="accordion-collapse collapse show"
                                  data-bs-parent="#basicDetailsView"
                                >
                                  <div class="accordion-body">
                                    <div class="card mb-2 d-flex">
                                      <div class="card-body  d-flex  justify-content-center">
                                        {/* user profile view */}
                                        <div class="d-flex flex-column align-items-center text-center">
                                          {!picReloader &&
                                            userInfo?.profilePictureFileName &&
                                            userInfo?.profilePictureFileName
                                              ?.length > 2 ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              key={`${picReloader}`}
                                              src={GetAttachment(
                                                userInfo?.id,
                                                profilePicObj?.fileName,
                                                profilePicObj?.fileId
                                              )}
                                              alt=""
                                              class="rounded-circle"
                                              width="150"
                                              height="150"
                                            />
                                          ) : (
                                            <img
                                              src={user}
                                              alt="Admin"
                                              class="rounded-circle"
                                              width="150"
                                            />
                                          )}
                                          {picReloader && <SmallLoader />}
                                        </div>
                                        <div className="flex-grow-0">
                                          <Anonimization
                                            value={
                                              userInfo?.ppShowHide === "Yes"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row ">
                                      <div class="col-sm-4">
                                        <h6 class="mb-0">
                                          {" "}
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "FirstName"
                                            ) || {}
                                          ).mvalue || "nf First Name"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-7 text-secondary">
                                        {userInfo?.firstName}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.fn === 'Yes' && <Anonimization value={userInfo?.fn} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.fn === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr className="m-2"></hr>
                                    <div class="row ">
                                      <div class="col-sm-4">
                                        <h6 class="mb-0">
                                          {" "}
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "LastName"
                                            ) || {}
                                          ).mvalue || "nf Last Name"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-7 text-secondary">
                                        {userInfo?.lastName}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.mlnShowHide === 'Yes' && <Anonimization value={userInfo?.mlnShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userDetails?.[0]?.mlnShowHide ===
                                              "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr className="m-2"></hr>
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Gender"
                                            ) || {}
                                          ).mvalue || "nf Gender"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-7 text-secondary">
                                        {userInfo?.mgender}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.genderShowHide === 'Yes' && <Anonimization value={userInfo?.genderShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.genderShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr className="m-2"></hr>
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "DateOfBirth"
                                            ) || {}
                                          ).mvalue || "nf DOB"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-7 text-secondary">
                                        {userInfo?.dob
                                          ? formatTimestampToDate(
                                            userInfo?.dob,
                                            regionalData.selectedCountry
                                              .dateFormat
                                          )
                                          : ""}
                                        {/* {userInfo?.dob} */}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.dobShowHide === 'Yes' && <Anonimization value={userInfo?.dobShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.dobShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr className="m-2"></hr>
                                    <div class="row">
                                      <div class="col-sm-11">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "About"
                                            ) || {}
                                          ).mvalue || "nf About"}
                                        </h6>
                                      </div>
                                      {/* <div class="col-sm-1 text-secondary">

                                                                                        <Anonimization value={userDetails?.[0]?.aboutShowHide === 'Yes' ? true : false} />
                                                                                    </div> */}
                                      <div className="d-flex justify-content-end">
                                        <div
                                          class="col-sm-11  text-secondary"
                                          style={{ minHeight: "4rem" }}
                                        >
                                          {userInfo?.about}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Attachments */}
                            {/* <div class="accordion" id="basicDetailsView">

                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header">
                                                                            <button class="accordion-button" type="button" style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || '#000', color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#fff" }}
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#BasicDetailsView"
                                                                                aria-expanded="true" aria-controls="BasicDetailsView">
                                                                                {(content[selectedLanguage].find(item => item.elementLabel === 'Gallery') || {}).mvalue || "nf Gallery"}
                                                                            </button>
                                                                        </h2>
                                                                        <div id="BasicDetailsView" class="accordion-collapse collapse show" data-bs-parent="#basicDetailsView">
                                                                            <div class="accordion-body">
                                                                                <div className='' style={{ width: "120px", height: "120" }} >
                                                                                <UserProfileGallery />
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div> */}
                          </div>
                          {/* Right forms */}
                          <div class="col-md-8">
                            <div class="accordion" id="rightForm">
                              {/* Contact infos */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button"
                                    style={{
                                      backgroundColor:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarBgColor"
                                          ) || {}
                                        ).mvalue || "#000",
                                      color:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarFontColor"
                                          ) || {}
                                        ).mvalue || "#fff",
                                    }}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-expanded="true"
                                    aria-controls="panelsStayOpen-collapseOne"
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "ContactDetails"
                                      ) || {}
                                    ).mvalue || "nf  Contact Details"}
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpen-collapseOne"
                                  class="accordion-collapse collapse show"
                                >
                                  <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Email"
                                            ) || {}
                                          ).mvalue || "nf Email"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.email}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.memailShowHide === 'Yes' && <Anonimization value={userInfo?.memailShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.memailShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Phone"
                                            ) || {}
                                          ).mvalue || "nf Phone"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.mobileNumber}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.mnShowHide === 'Yes' && <Anonimization value={userInfo?.mnShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.mnShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Address"
                                            ) || {}
                                          ).mvalue || "nf Address"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.address1}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.address1ShowHide === 'Yes' && <Anonimization value={userInfo?.address1ShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.address1ShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "Country"
                                            ) || {}
                                          ).mvalue || "nf Country"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.userCountry}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.countryShowHide === 'Yes' && <Anonimization value={userInfo?.countryShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.countryShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "State"
                                            ) || {}
                                          ).mvalue || "nf State"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.userState}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.stateShowHide === 'Yes' && <Anonimization value={userInfo?.stateShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.stateShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel === "City"
                                            ) || {}
                                          ).mvalue || "nf City"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.city}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.cityShowHide === 'Yes' && <Anonimization value={userInfo?.cityShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.cityShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "PostalCode"
                                            ) || {}
                                          ).mvalue || "nf PostalCode"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.postalCode}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* {userInfo?.pcShowHide === 'Yes' && <Anonimization value={userInfo?.pcShowHide} toggleable={false} />} */}
                                        <Anonimization
                                          value={
                                            userInfo?.pcShowHide === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Socials view only */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    style={{
                                      backgroundColor:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarBgColor"
                                          ) || {}
                                        ).mvalue || "#000",
                                      color:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarFontColor"
                                          ) || {}
                                        ).mvalue || "#fff",
                                    }}
                                    class="accordion-button "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-collapseThree"
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel ===
                                          "SocialMediaLinks"
                                      ) || {}
                                    ).mvalue || "nf Social Media Links"}
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpen-collapseThree"
                                  class="accordion-collapse collapse show"
                                >
                                  <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-sm-3">
                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                          <h6 class="mb-0">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="mx-2"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-globe mr-2 icon-inline"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <line
                                                x1="2"
                                                y1="12"
                                                x2="22"
                                                y2="12"
                                              ></line>
                                              <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                            </svg>
                                            <span className="mx-1">
                                              Website
                                            </span>
                                          </h6>
                                        </li>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        <span class="text-secondary"></span>
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        <Anonimization
                                          value={true}
                                          toggleable={false}
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                          <h6 class="mb-0">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="mx-2"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-github mr-2 icon-inline"
                                            >
                                              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                            </svg>
                                            <span className="mx-1">Github</span>
                                          </h6>
                                        </li>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        <span class="text-secondary"></span>
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        <Anonimization
                                          value={true}
                                          toggleable={false}
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                          <h6 class="mb-0">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="mx-2"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-twitter mr-2 icon-inline text-info"
                                            >
                                              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                            </svg>
                                            <span className="mx-1">
                                              Twitter
                                            </span>
                                          </h6>
                                        </li>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        <span class="text-secondary"></span>
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        <Anonimization
                                          value={true}
                                          toggleable={false}
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                          <h6 class="mb-0">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="mx-2"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-instagram mr-2 icon-inline text-danger"
                                            >
                                              <rect
                                                x="2"
                                                y="2"
                                                width="20"
                                                height="20"
                                                rx="5"
                                                ry="5"
                                              ></rect>
                                              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                              <line
                                                x1="17.5"
                                                y1="6.5"
                                                x2="17.51"
                                                y2="6.5"
                                              ></line>
                                            </svg>
                                            <span className="mx-1">
                                              Instagram
                                            </span>
                                          </h6>
                                        </li>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        <span class="text-secondary"></span>
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        <Anonimization
                                          value={true}
                                          toggleable={false}
                                        />
                                      </div>

                                      <hr className="m-2"></hr>

                                      <div class="col-sm-3">
                                        <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                          <h6 class="mb-0">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="mx-2"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-facebook mr-2 icon-inline text-primary"
                                            >
                                              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                            </svg>
                                            <span className="mx-1">
                                              Facebook
                                            </span>
                                          </h6>
                                        </li>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        <span class="text-secondary"></span>
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        <Anonimization
                                          value={true}
                                          toggleable={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* miscellaneous */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button "
                                    style={{
                                      backgroundColor:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarBgColor"
                                          ) || {}
                                        ).mvalue || "#000",
                                      color:
                                        (
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "NavBarFontColor"
                                          ) || {}
                                        ).mvalue || "#ffff",
                                    }}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-collapseTwo"
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel ===
                                          "PrefferedWorkingLocation"
                                      ) || {}
                                    ).mvalue || "nf Preffered Working Location"}
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpen-collapseTwo"
                                  class="accordion-collapse collapse show"
                                >
                                  <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-sm-3">
                                        <h6 class="mb-0">
                                          {(
                                            content[selectedLanguage].find(
                                              (item) =>
                                                item.elementLabel ===
                                                "PreferredLocation"
                                            ) || {}
                                          ).mvalue || "nf Preferred Location"}
                                        </h6>
                                      </div>
                                      <div class="col-sm-8 text-secondary">
                                        {userInfo?.location}
                                      </div>
                                      <div class="col-sm-1 text-secondary">
                                        {/* <Anonimization value={true} toggleable={false} /> */}
                                      </div>
                                      <hr className="m-2"></hr>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <button
        data-bs-toggle="modal"
        className="d-none"
        data-bs-target="#confirmLang"
        id="clickConfirmLang"
      ></button>

      {/* show confirm msg change language creating new profile  */}
      <div
        class="modal fade"
        id="confirmLang"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            {/* <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
            <div class="modal-body">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "YouAreNotHavingProfile"
                ) || {}
              ).mvalue ||
                "nf You are not having profile in this language. Do you want to create a new profile in this language?"}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={handleCancelNewLang}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleCreateNewLangProfile}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
