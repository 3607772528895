import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
/* SEARCH ICON IMPORT */
import { FaSearch, FaFilter } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../config/Properties";
import Pagination from "../Pagination/Pagination";
import { formatTimestampToDate } from "../SkillOwner/HelperFunction/FormatTimestampToDate";

const ValidationModal = () => {
  /* STORE IMPORTS */
  const {
    getUserValidation: { userValidationData },
    language: selectedLanguage,
    content,
    regionalData,
  } = useSelector((state) => state);
  /* STATES INIT */
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [isFilter, setIsFilter] = useState(false);

  /* TRIGGER USERVALIDATION ON MODAL OPEN */
  useEffect(() => {}, [userValidationData]);

  const indexOfLastItem = useMemo(
    () => currentPage * itemsPerPage,
    [currentPage, itemsPerPage]
  );
  const indexOfFirstItem = useMemo(
    () => indexOfLastItem - itemsPerPage,
    [indexOfLastItem, itemsPerPage]
  );

  /* STATE FOR DROPDOWN CLICK */
  const [isAcceptedClicked, setIsAcceptedClicked] = useState(false);
  const [isAbstainClicked, setIsAbstainClicked] = useState(false);
  const [isRejectedClicked, setIsRejectedClicked] = useState(false);
  /* FILTER DATA BASED ON SEARCH */
  const filteredData = useMemo(() => {
    if (isFilter && isAcceptedClicked) {
      setCurrentPage(1);
      return userValidationData.filter(
        (data) => data?.validatorResponse === "Accept"
      );
    } else if (isFilter && isAbstainClicked) {
      setCurrentPage(1);
      return userValidationData.filter(
        (data) => data?.validatorResponse === "Abstain"
      );
    } else if (isFilter && isRejectedClicked) {
      setCurrentPage(1);
      return userValidationData.filter(
        (data) => data?.validatorResponse === "Reject"
      );
    }
    if (!searchTerm) return userValidationData;
    return userValidationData.filter(
      (validatior) =>
        validatior.keyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        validatior.validatorName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
  }, [
    searchTerm,
    userValidationData,
    isFilter,
    isAcceptedClicked,
    isAbstainClicked,
    isRejectedClicked,
  ]);

  /* SEARCH INPUT HANDLING */
  const handleSearchInputChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); /* RESET THE CURRENT PAGE WHEN SEARCH TERM CHANGES */
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  const showMoreDetails = useCallback(
    (valId) => {
      setSelectedId(valId === selectedId ? null : valId);
    },
    [selectedId]
  );

  /* CALCULATE THE TOTAL PAGES BASED ON DATA LENGTH AND ITEMS PER PAGE */
  const totalPage = useMemo(
    () =>
      userValidationData ? Math.ceil(filteredData?.length / itemsPerPage) : 0,
    [userValidationData, itemsPerPage, filteredData?.length]
  );

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  /* SHOW NO. OF CONTENT ACC TO FILTERED DATA */
  useEffect(() => {
    if (filteredData?.length < 50) {
      setItemsPerPage(3);
    } else {
      setItemsPerPage(5);
    }
  }, [filteredData?.length]);

  /* INIT TOKEN */
  const token = localStorage.getItem("token");

  return (
    <div
      className="modal fade modal-xl"
      id="validation"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content" style={{ marginTop: "5rem" }}>
          <div className="modal-header bg-body-secondary text-dark">
            <h1 className="modal-title fs-5">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "MyValidations"
                ) || {}
              ).mvalue || "nf My Validations"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div>
                    <div className="card">
                      <div className="card-body">
                        {/* Search input */}
                        <div className="mb-3 d-flex justify-content-between">
                          <div className="input-group w-50">
                            <span className="input-group-text text-success">
                              <FaSearch />
                            </span>
                            <input
                              type="text"
                              className="form-control rounded border-success"
                              placeholder="Search..."
                              spellCheck="true"
                              value={searchTerm}
                              onChange={handleSearchInputChange}
                            />
                          </div>
                          <div className="d-flex align-items-center position-relative">
                            <span className="fw-bold text-success m-1">
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "Filter"
                                ) || {}
                              ).mvalue || "nf Filter"}
                            </span>
                            <button
                              className="btn btn-outline-success"
                              onClick={() => setIsFilter((prev) => !prev)}
                            >
                              <FaFilter />
                            </button>
                            {/* CARD OPEN FOR SORT */}
                            {isFilter && (
                              <div
                                class="card mb-2 position-absolute mt-2 custom-tooltip"
                                style={{
                                  top: "100%",
                                  width: "min(100%, 6rem)",
                                }}
                              >
                                {/* ACCEPT */}
                                <div className="d-flex justify-content-start">
                                  <span
                                    className="text-success fw-bold"
                                    style={{
                                      fontSize: "0.9rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setIsAcceptedClicked((prev) => !prev)
                                    }
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "Accepted"
                                      ) || {}
                                    ).mvalue || "nf Accepted"}
                                  </span>
                                </div>
                                {/* ABSTAIN */}
                                <div className="d-flex justify-content-start">
                                  <span
                                    className="text-primary fw-bold"
                                    style={{
                                      fontSize: "0.9rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setIsAbstainClicked((prev) => !prev)
                                    }
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "Abstained"
                                      ) || {}
                                    ).mvalue || "nf Abstained"}
                                  </span>
                                </div>
                                {/* REJECT */}
                                <div className="d-flex justify-content-start">
                                  <span
                                    className="fw-bold"
                                    style={{
                                      color: "#815F0B",
                                      fontSize: "0.9rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setIsRejectedClicked((prev) => !prev)
                                    }
                                  >
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "Rejected"
                                      ) || {}
                                    ).mvalue || "nf Rejected"}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <table
                          className="table table-bordered"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <thead>
                            <tr>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "ItemType"
                                  ) || {}
                                ).mvalue || "nf Item Type"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "DateSent"
                                  ) || {}
                                ).mvalue || "nf Date Sent"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ProjectValidator"
                                  ) || {}
                                ).mvalue || "nf Validator Name"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel ===
                                      "ProjectValidatorRelationship"
                                  ) || {}
                                ).mvalue || "nf Relationship"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel ===
                                      "ProjectValidatorEmail"
                                  ) || {}
                                ).mvalue || "nf Email Id"}
                              </th>
                              <th className="fw-bold">
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ValidationStatus"
                                  ) || {}
                                ).mvalue || "nf Validation Status"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userValidationData && filteredData?.length ? (
                              filteredData
                                .slice(indexOfFirstItem, indexOfLastItem)
                                .map((validatior, index) => (
                                  <React.Fragment key={validatior.id}>
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        showMoreDetails(validatior.id)
                                      }
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-place="top"
                                      data-tooltip-content={`Click to view ${
                                        validatior?.skillOccupation ||
                                        validatior?.keyName
                                      }'s details`}
                                    >
                                      <td>
                                        {validatior?.keyTable
                                          ? validatior?.keyTable
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {formatTimestampToDate(
                                          Number(validatior?.dateSent),
                                          regionalData?.selectedCountry
                                            ?.dateFormat
                                        )}
                                      </td>
                                      <td>
                                        {validatior?.validatorName
                                          ? validatior?.validatorName
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {validatior?.relationship
                                          ? validatior?.relationship
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {validatior?.validatorEmail
                                          ? validatior?.validatorEmail
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {validatior?.validatorResponse
                                          ? validatior?.validatorResponse
                                          : "N/A"}
                                      </td>
                                    </tr>
                                    {selectedId === validatior.id && (
                                      <tr>
                                        <td
                                          colSpan={
                                            Object.keys(validatior)?.length
                                          }
                                        >
                                          <div className="card">
                                            <div
                                              className="card-body d-flex bg-body-light"
                                              style={{ textIndent: "3rem" }}
                                            >
                                              <div
                                                className="col-6"
                                                style={{
                                                  borderRight:
                                                    "1px solid #577126",
                                                }}
                                              >
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "ProjectValidatorRemark"
                                                    ) || {}
                                                  ).mvalue || "nf Remarks"}
                                                  : {validatior.remarks}
                                                </p>
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "Attachments"
                                                    ) || {}
                                                  ).mvalue || "nf Attachments"}
                                                  :{" "}
                                                  {validatior?.requestorAttachmentFileNames &&
                                                  JSON.parse(
                                                    validatior?.requestorAttachmentFileNames
                                                  ) ? (
                                                    JSON.parse(
                                                      validatior?.requestorAttachmentFileNames
                                                    ).map(
                                                      (attachment, index) => (
                                                        <React.Fragment>
                                                          {index > 0 && (
                                                            <span>, </span>
                                                          )}
                                                          <a
                                                            key={index}
                                                            rel="noreferrer"
                                                            href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${
                                                              validatior?.id
                                                            }/${
                                                              attachment.filename
                                                            }/${
                                                              attachment.fileId
                                                            }?Authorization=${localStorage.getItem(
                                                              "token"
                                                            )}`}
                                                            target="_blank"
                                                          >
                                                            {
                                                              attachment.fileTitle
                                                            }
                                                          </a>
                                                        </React.Fragment>
                                                      )
                                                    )
                                                  ) : (
                                                    <p>
                                                      {(
                                                        content[
                                                          selectedLanguage
                                                        ].find(
                                                          (item) =>
                                                            item.elementLabel ===
                                                            "NoAttachmentsSent"
                                                        ) || {}
                                                      ).mvalue ||
                                                        "nf No Attachments Sent"}
                                                    </p>
                                                  )}
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "ValidatorsRemark"
                                                    ) || {}
                                                  ).mvalue ||
                                                    "nf Validator's Remark"}
                                                  :{" "}
                                                  {validatior?.validatorComments
                                                    ? validatior?.validatorComments
                                                    : (
                                                        content[
                                                          selectedLanguage
                                                        ].find(
                                                          (item) =>
                                                            item.elementLabel ===
                                                            "NoRemarksFromValidator"
                                                        ) || {}
                                                      ).mvalue ||
                                                      "nf No remarks from validator"}
                                                </p>
                                                <p>
                                                  {(
                                                    content[
                                                      selectedLanguage
                                                    ].find(
                                                      (item) =>
                                                        item.elementLabel ===
                                                        "ValidatorsAttachments"
                                                    ) || {}
                                                  ).mvalue ||
                                                    "nf Validator's Attachments"}
                                                  :{" "}
                                                  {validatior?.validatorAttachmentFileNames &&
                                                  JSON.parse(
                                                    validatior?.validatorAttachmentFileNames
                                                  ) ? (
                                                    JSON.parse(
                                                      validatior?.validatorAttachmentFileNames
                                                    ).map(
                                                      (attachment, index) => (
                                                        <React.Fragment>
                                                          {index > 0 && (
                                                            <span>, </span>
                                                          )}
                                                          <a
                                                            key={index}
                                                            rel="noreferrer"
                                                            href={`${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${validatior?.id}/${attachment.filename}/${attachment.fileId}?Authorization=${token}`}
                                                            target="_blank"
                                                          >
                                                            {
                                                              attachment.fileTitle
                                                            }
                                                          </a>
                                                        </React.Fragment>
                                                      )
                                                    )
                                                  ) : (
                                                    <p>
                                                      {(
                                                        content[
                                                          selectedLanguage
                                                        ].find(
                                                          (item) =>
                                                            item.elementLabel ===
                                                            "NoAttachmentsMsg"
                                                        ) || {}
                                                      ).mvalue ||
                                                        "nf No Attachments Received Yet"}
                                                    </p>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))
                            ) : (
                              <tr>
                                <td
                                  className="fw-bold text-center text-success"
                                  colSpan="6"
                                >
                                  {(
                                    content[selectedLanguage].find(
                                      (item) =>
                                        item.elementLabel === "NoSearchResult"
                                    ) || {}
                                  ).mvalue || "nf No Search Result"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* PAGINATION */}
                        {totalPage > 1 && filteredData?.length ? (
                          <React.Fragment>
                            <Pagination
                              currentPage={currentPage}
                              totalPage={totalPage}
                              onPageChange={handlePageChange}
                            />
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" style={{ zIndex: 100 }} />
    </div>
  );
};

export default ValidationModal;
