import { debounce } from "lodash";
import SkillSuggestionApi from "../../api/skillOwner/mySkill/SkillSuggestionApi";

const sendRequest = (value, selectedLanguage, setSkillSuggestions, setSuggestionLoader) => {

  // send value to the backend
  SkillSuggestionApi(value, selectedLanguage, "skillOccupation").then((res) => {
    console.log(res.data);
    setSuggestionLoader(false);

    // Create a map to track unique skillOccupations
    const uniqueSkillOccupations = new Map();

    // Filter unique skillOccupations
    const uniqueSuggestions = res.data.filter(item => {
      if (!uniqueSkillOccupations.has(item.skillOccupation)) {
        uniqueSkillOccupations.set(item.skillOccupation, true);
        return true;
      }
      return false;
    });


    // Check if uniqueSuggestions is empty
    if (uniqueSuggestions.length === 0) {
      setSkillSuggestions([
        { skillOccupation: "No suggestions found", id: 1, occupation: selectedLanguage },
      ]);
    } else {
      setSkillSuggestions(uniqueSuggestions);
    }
    
  });

};


// debounce(callback, wait in ms)
export const debouncedSendRequest = debounce(sendRequest, 500);
