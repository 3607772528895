import React, { useCallback, useEffect, useState } from "react";
import NavbarComponent from "../../../../components/navbar/NavbarComponent";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginForm from "./LoginForm";
import LoginApi from "../../../../api/auth/LoginApi";
import { encryptData } from "../../../../config/encrypt/encryptData";
import { BASE_URL } from "../../../../config/Properties";
import axios from "axios";
import UserDetailsApi from "../../../../api/auth/UserDetailsApi";
import { useDispatch } from "react-redux";
import { setUserRoles } from "../../../../reducer/roles/RoleMappingSlice";
import { setUserProfile } from "../../../../reducer/userDetails/UserProfileSlice";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../../../components/ToastNotification/showErrorToast";
import { showSuccessToast } from "../../../../components/ToastNotification/showSuccessToast";
import { setRegCountry } from "../../../../reducer/localization/CountryRegionalSlice";
import "../LoginPage.css";
import logo from "../../../../Images/logo.png";
import { getUserExistenceDetail } from "../../../../api/auth/getUserExistenceDetail";
import {
  removeScreenName,
  setScreenName,
} from "../../../../reducer/screen/screenNameSlice";
import { getAccountDetails } from "../../../../api/auth/getAccountDetails";

const LoginTemplate = () => {
  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    roles,
    UserAccountDetails,
    regionalData: { listOfCountries, selectedCountry },
  } = useSelector((state) => state);

  /* DISPATCH INIT */
  const dispatch = useDispatch();

  /* NAVIGATE INIT */
  const navigate = useNavigate();

  /* STATES INIT */
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("R1");
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState("");
  const [validated, setValidated] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [rolesMap, setRolesMap] = useState([]);
  const [isLoadinRoles, setIsLoadinRoles] = useState(false);
  const email = localStorage.getItem("auth_key");

  useEffect(() => {
    if (!email) {
      navigate("/user-auth");
    }
  }, [email, navigate]);

  useEffect(() => {
    const fetchScreenDetail = async () => {
      try {
        const res = await getAccountDetails(email);
        if (res?.data?.account?.defaultView === "DIALOG") {
          dispatch(setScreenName(res?.data?.account?.defaultView));
        } else if (
          res?.data?.account?.defaultView === "MAIN" ||
          res?.data?.account?.defaultView !== "DIALOG"
        ) {
          dispatch(removeScreenName());
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchScreenDetail();
  }, [dispatch, email]);

  useEffect(() => {
    setIsLoadinRoles(true);
    const fetchDetails = async () => {
      const res = await getUserExistenceDetail(email);
      const filteredRoles = roles?.data.filter((role) =>
        res?.data?.account?.roles
          .map((role) => role.roleName)
          .includes(role.roleName)
      );
      setRolesMap(filteredRoles);
      setIsLoadinRoles(false);
    };
    fetchDetails();
  }, [email, roles]);

  /* DOCUMENT TITLE INIT */
  useEffect(() => {
    document.title = `${
      (
        content[selectedLanguage].find(
          (item) => item.elementLabel === "Title"
        ) || {}
      ).mvalue || "MySkillsTree"
    } ${
      (
        content[selectedLanguage].find(
          (item) => item.elementLabel === "SignIn"
        ) || {}
      ).mvalue || "SignIn"
    }`;
  }, [content, selectedLanguage]);

  /* HANDLE ROLE CHANGE */
  const handleRoleChange = useCallback((e) => {
    setSelectedRole(e.target.value);
  }, []);

  /* GET ACCOUNT DETAIILS */
  const getAccountDetail = useCallback(async (accountId) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/skill/api/v1/skills/AcccountDetails/${accountId}`
      );
      return res;
    } catch (error) {
      console.error("ERROR GETTING ACCOUNT DETAILS: ", error);
    }
  }, []);

  /* HANDLE SIGNIN */
  const handleSignIn = useCallback(
    async (e) => {
      setIsLoading(true);
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }

      setValidation("was-validated");
      setValidated(true);

      if (selectedRole === "" || password === "") {
        setIsLoading(false);
        return;
      }

      /* PAYLOAD */
      const payload = {
        username: encryptData(localStorage.getItem("auth_key")),
        password: encryptData(password),
      };

      try {
        const res = await LoginApi(payload);

        const data = res?.data;
        if (res?.status === 200 && data?.status === "success") {
          localStorage.setItem("userId", data?.userId);
          localStorage.setItem("token", data?.authenticationtoken);

          if (
            data?.account?.homeTimeZone !== null &&
            data?.account.homeTimeZone !== undefined
          ) {
            const res = await axios.get(
              `${BASE_URL}/skill/api/v1/skills/RegionalData/Time Zone Data?&searchFieldName=countryCode&searchValue=${data?.account?.homeCountry}%25`
            );
            const mappedArray = res?.data.map((obj) => ({
              ...obj,
              value: obj.abbreviation,
              label:
                obj.countryCode +
                " " +
                obj.abbreviation +
                " " +
                obj?.timeZone +
                " " +
                obj.gmtOffset,
            }));

            let selectedObjects = data.account?.homeTimeZone;
            if (mappedArray.length > 0) {
              selectedObjects = mappedArray?.find(
                (item) => item.abbreviation === data.account?.homeTimeZone
              );
            }
            localStorage.setItem("TimeZone", selectedObjects?.label);
          } else {
            localStorage.setItem("TimeZone", data?.account?.homeTimeZone);
          }

          localStorage.setItem("userName", data?.account?.accountId);
          localStorage.setItem("countryCode", data?.account?.homeCountry);

          localStorage.setItem("HLang", data?.account?.homeLanguage);
          dispatch(setUserRoles(data?.account?.roles));

          /* IF CANDIDATE ID PRESENT IN DATA AND SET IT INTO USER PROFILE OR ELSE SHOW CREATE PROFILE POPUP */
          if (data.userId !== undefined) {
            /* FETCH CANDIDATE DETAILS AND SET IN USER PROFILE */
            UserDetailsApi().then((res) => {
              if (res.status === 200) {
                /* SET STATE FOR USER DETAILS */
                const userDetails = {
                  ...res.data,
                  token: data?.authenticationtoken,
                };
                dispatch(setUserProfile(userDetails));
              } else {
                /* IF SOMETHING WENT WRONG NAVIGATE TO USER VERIFICATION PAGE */
                localStorage.clear();
                navigate("/user-auth");
              }
            });
          }

          /* ROLES */
          const rolesArray = [];
          if (data && data?.account) {
            data?.account?.roles?.forEach((role) => {
              rolesArray.push(role.roleName);
            });
          }

          /* SET ISLOADING TO FALSE */
          setIsLoading(false);

          if (!rolesArray.includes(selectedRole)) {
            showErrorToast(
              "You are not authorized to selected role... Select a valid role and try again!"
            );
            return;
          }
          const response = await getAccountDetail(data?.account?.accountId);
          showSuccessToast("Login Successful!!");
          localStorage.removeItem("auth_key");
          if (selectedRole === "R1" && rolesArray.includes("R1")) {
            if (response?.data?.account?.defaultView === "DIALOG") {
              navigate("/newuser/basicinfo");
            } else if (
              response?.data?.account?.defaultView === "MAIN" ||
              response?.data?.account?.defaultView !== "DIALOG"
            ) {
              navigate("/dashboard/skillprofile");
            }
          } else if (selectedRole === "R2" && rolesArray.includes("R2")) {
            navigate("/dashboard/conventionalsearch");
          } else if (selectedRole === "R3" && rolesArray.includes("R3")) {
            navigate("/skillingagency/skillsearch");
          } else if (selectedRole === "R6" && rolesArray.includes("R6")) {
            navigate("/supportservices/skillsearch");
          }
        } else {
          setIsLoading(false);
          showErrorToast("Invalid username or password, please try again!");
        }
      } catch (error) {
        setIsLoading(false);
        if (error instanceof ReferenceError) {
          console.error("Reference error occured: ", error?.message);
        } else if (error instanceof TypeError) {
          console.error("Type error occured: ", error?.message);
        } else {
          console.error("Error occured during login: ", error);
        }
      }
    },
    [selectedRole, password, dispatch, navigate, getAccountDetail]
  );

  /* HANDLE PASSWORD SHOW */
  const handlePasswordShow = useCallback(() => {
    setIsPasswordShow((prev) => !prev);
  }, []);

  useEffect(() => {}, [UserAccountDetails]);

  useEffect(() => {
    if (listOfCountries) {
      for (let country of listOfCountries) {
        if (country.countryCode === localStorage.getItem("countryCode")) {
          localStorage.setItem("dateFormat", country.dateFormat);
          dispatch(setRegCountry(country));
          break;
        }
      }
    }
  }, [listOfCountries, dispatch]);

  useEffect(() => {}, [selectedCountry]);

  return (
    <React.Fragment>
      {/* NAVBAR */}
      <NavbarComponent isNewLoginPage={true} isDisable={true} />

      {/* CONTENT */}
      <div className="p-5" style={{ height: "calc(-60px + 96vh)" }}>
        <div className="container-fluid" style={{ height: "100%" }}>
          <div className="row no-gutter" style={{ height: "100%" }}>
            {/* LEFT CONTENT {IMAGE} */}
            <div className="col-md-6 d-none  d-lg-flex  justify-content-center align-items-center h-100 ">
              <img
                src="https://res.cloudinary.com/dr9v4bjwg/image/upload/v1706759529/6300958_kouoz3555_ntgoex.png"
                alt="login-avatar"
                style={{ width: "40vw" }}
              />
            </div>

            {/* RIGHT CONTENT */}
            <LoginForm
              content={content}
              selectedLanguage={selectedLanguage}
              validation={validation}
              validated={validated}
              isPasswordShow={isPasswordShow}
              password={password}
              setPassword={setPassword}
              handlePasswordShow={handlePasswordShow}
              handleRoleChange={handleRoleChange}
              selectedRole={selectedRole}
              handleSignIn={handleSignIn}
              isLoading={isLoading}
              roles={roles}
              FaEye={FaEye}
              FaEyeSlash={FaEyeSlash}
              logo={logo}
              rolesMap={rolesMap}
              isLoadinRoles={isLoadinRoles}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginTemplate;
