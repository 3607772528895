// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer/rootReducer";
import { fetchData } from "./reducer/localization/contentSlice";
import { useSelector } from "react-redux";
import { fetchRoles } from "./reducer/roles/RoleMappingSlice";
import { fetchLanguages } from "./reducer/localization/listOfLangSlice";
import { logoutTopSkill } from "./reducer/mySkills/TopSkillSlice";
import { logoutSkillsApplied } from "./reducer/skillProfile/SkillsAppliedSlice";
import { logoutSkillsAcquired } from "./reducer/skillProfile/SkillsAcquiredSlice";
import { logoutSkillSelected } from "./reducer/mySkills/SkillSelectedSlice";
import { logoutEmployment } from "./reducer/detailedProfile/employmentSlice";
import { logoutProject } from "./reducer/detailedProfile/projectSlice";
import { fetchCategory } from "./reducer/nf specific/categorySlice";
import { fetchCountryRegional } from "./api/content/fetchRegionalCountries";
import { fetchValidation } from "./reducer/validation/getUserValidationSlice";
import { removeResumeSkills } from "./reducer/resume/resumeSkillSlice";
import { removeScreenName } from "./reducer/screen/screenNameSlice";
import { fetchUserAttachment } from "./reducer/attachments/getUserAttachmentSlice";





const store = configureStore({
  reducer: rootReducer,

});

export const useAppSelector = useSelector;
// Dispatch the fetchData action when the store is configured
// write here what ever you want to load on app load
store.dispatch(fetchData());
store.dispatch(fetchLanguages());
store.dispatch(fetchRoles());

/* SPECIFIC CATEGORY DISPATCH */
store.dispatch(fetchCategory());
store.dispatch(fetchCountryRegional());

/* GET USER VALIDATION DISPATCH */
store.dispatch(fetchValidation());
/* GET USER ATTACHMENT */
store.dispatch(fetchUserAttachment());

// logout function to set all user data alone to initial state
export const logoutUser = () => {
  store.dispatch(logoutTopSkill());
  store.dispatch(logoutSkillsApplied());
  store.dispatch(logoutSkillsAcquired());
  store.dispatch(logoutSkillSelected());
  store.dispatch(logoutEmployment());
  store.dispatch(logoutProject());
  store.dispatch(logoutProject());
  store.dispatch(removeResumeSkills());
  store.dispatch(removeScreenName());
  console.log("logout fn2 ");

};





export default store;
