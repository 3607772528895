import React, { useCallback } from "react";
import SecondaryBtnLoader from "../Buttons/SecondaryBtnLoader";

const AttachmentDeleteCard = ({
  content,
  selectedLanguage,
  setisDeleteOn,
  FaTimes,
  additionalData,
  setIsDeleting,
  userDetails,
  handleRemoveAttachment,
  FileId,
  Id,
  setId,
  setFileId,
  isDeleting,
}) => {
  /* HANLDE SAVE */
  const handleSave = useCallback(async () => {
    setIsDeleting(true);
    await handleRemoveAttachment(FileId, Id);
    setisDeleteOn(false);
    setId("");
    setFileId("");
    /* RETYRN TO DEFAULT STATE */
    setIsDeleting(false);
  }, [
    setIsDeleting,
    setisDeleteOn,
    FileId,
    Id,
    handleRemoveAttachment,
    setFileId,
    setId,
  ]);
  return (
    <React.Fragment>
      <div
        className="modal d-flex justify-content-center"
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1050,
        }}
      >
        <div
          className="modal-dialog"
          style={{
            maxWidth: "600px",
            minWidth: "40vw",
            color: "#000",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-content"
            style={{
              boxShadow: "0 5px 15px rgba(87, 113, 38, 0.5)",
              marginTop: "5rem",
            }}
          >
            <div className="card">
              <div className="card-title d-flex justify-content-between align-items-center p-2">
                <div className="fs-5">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "AttachmentDelete"
                    ) || {}
                  ).mvalue || "nf Delete Attachments"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setisDeleteOn(false)}
                >
                  <FaTimes />
                </div>
              </div>
              <div className="card-body">
                <div className="mb-1 fw-bold">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "AreYouSure"
                    ) || {}
                  ).mvalue || "nf Are You Sure"}
                  ?
                </div>
                <div>
                  {additionalData?.filter(
                    (att) =>
                      att?.applicationName !== userDetails?.applicationName
                  ).length &&
                    additionalData
                      .filter(
                        (att) =>
                          att?.applicationName !== userDetails?.applicationName
                      )
                      .map((data) => (
                        <React.Fragment key={data?.id}>
                          <div style={{ fontSize: "0.9rem" }}>
                            This attachment used in{" "}
                            <b style={{ color: "rgb(87, 113, 38)" }}>
                              {data?.applicationName}
                            </b>{" "}
                            for{" "}
                            <b style={{ color: "rgb(87, 113, 38)" }}>
                              {data?.projectActivity ||
                                data?.organization ||
                                data?.keyName}
                            </b>
                          </div>
                        </React.Fragment>
                      ))}
                </div>
              </div>

              {/* FUNC BUTTONS */}
              <div className="card-footer d-flex justify-content-end">
                <div className="d-flex">
                  <div className="d-flex flex-grow-1 gap-2">
                    <SecondaryBtnLoader
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Cancel"
                          ) || {}
                        ).mvalue || "nf Cancel"
                      }
                      onClick={() => {
                        setisDeleteOn(false);
                      }}
                      backgroundColor="#815F0B"
                      color="#F8F8E9"
                    />

                    <SecondaryBtnLoader
                      label={
                        (
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Delete"
                          ) || {}
                        ).mvalue || "nf Delete"
                      }
                      onClick={() => {
                        handleSave();
                      }}
                      backgroundColor="#815F0B"
                      color="#F8F8E9"
                      loading={isDeleting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttachmentDeleteCard;
