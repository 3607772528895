/* THIS CODE IS FOR EDITING THE DETAILED PROFILE EMPLOYMENT AND PROJECT */
import React, { useCallback, useEffect, useRef, useState } from "react";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { useDispatch, useSelector } from "react-redux";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import CreateSelect from "../../SelectComponent/CreateSelect";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { FetchProjectHistory } from "../../../../api/fetchAllData/FetchProjectHistory";
import { fetchDataSkillsApplied } from "../../../../api/fetchAllData/fetchDataSkillsApplied";
import { debouncedApiRequest } from "../../../DebounceHelperFunction/debouncedApiRequest";
import organizationSearchSuggestions from "../../../../api/searchSuggestionAPIs/organizationSearchSuggestions";
import { ThreeDots } from "react-loader-spinner";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { toTitleCase } from "../../HelperFunction/toTitleCase";
import { exceptionPOSTapi } from "../../../../api/PostData/exceptionsPOSTapi";
import { FetchOrganizationHistory } from "../../../../api/fetchAllData/fetchOrganization";
import MultiSelect from "../../SelectComponent/MultiSelect";
import { fetchRelationshipdetails } from "../../../../api/relationshipApi/fetchRelationshipdetails";
import { showErrorToast } from "../../../ToastNotification/showErrorToast";
import SecondaryBtn from "../../../Buttons/SecondaryBtn";
import { RxCross2 } from "react-icons/rx";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = (innerProps) => {
  return <></>;
};

const EditDetailedProfile = () => {
  /* DISPATCH */
  const dispatch = useDispatch();

  //LOCATION STATE
  const [location, setLocation] = useState("");
  const [locationForRecord, setLocationForRecord] = useState([]);

  const [online, setOnline] = useState(false);

  /* STORE IMPORTS */
  const {
    detailedProfileEdit: detailedProfileEditData,
    language: selectedLanguage,
    content,
    regionalData,
  } = useSelector((state) => state);

  /* STATE INIT */

  const [desc, setDesc] = useState(
    detailedProfileEditData?.briefDescriptions || ""
  );
  const [projectName, setProjectName] = useState(
    detailedProfileEditData?.projectActivity || ""
  );
  const [organizationName, setOrganizationName] = useState(
    detailedProfileEditData?.organization || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);
  // const [insConvertedToSelect, setInsConvertedToSelect] = useState([]);
  const [isApiLoader, setIsApiLoader] = useState(false);
  const [orgApiData, setOrgApiData] = useState([]);
  const [orgSearchValue, setOrgSearchValue] = useState("");
  const [organizationSuggestionsData, setOrganizationSuggestionsData] =
    useState([]);
  const [isCustomOrg, setIsCustomOrg] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  /* FORMAT THE DATES */
  const formattedStartDate =
    detailedProfileEditData?.fromDate &&
    detailedProfileEditData?.fromDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileEditData?.fromDate)
      : formatTimestampToDate(
          Number(detailedProfileEditData?.fromDate),
          regionalData?.selectedCountry?.dateFormat
        );
  const formattedEndDate =
    detailedProfileEditData?.toDate &&
    detailedProfileEditData?.toDate.includes("/")
      ? timestampToYYYYMMDD(detailedProfileEditData?.toDate)
      : formatTimestampToDate(
          Number(detailedProfileEditData?.toDate),
          regionalData?.selectedCountry?.dateFormat
        );

  /* HANDLE CREATING NEW ORGANIZATION NAME IN MASTER TABLE */
  const handleInsertNewOrganization = useCallback(
    async (newOrgName, lang, moduleName, contentName, itemId) => {
      const payload = {
        organization: newOrgName,
        mlanguage: lang,
        mstatus: "W",
      };
      try {
        const res = await exceptionPOSTapi("Organizations", payload);
        const data = res?.data;

        handleOrganizationExceptions(
          data?.applicationName,
          data?.id,
          moduleName,
          contentName,
          itemId
        );
      } catch (error) {
        console.error("Error inserting new institution name: ", error);
      }
    },
    []
  );

  /* HANDLE ORG EXCEPTION */
  const handleOrganizationExceptions = async (
    applicationName,
    recordId,
    moduleName,
    contentName,
    itemId
  ) => {
    const body = {
      masterTable: applicationName,
      masterTableRecordID: recordId,
      module: moduleName,
      userId: localStorage.getItem("userId"),
      content: contentName,
      itemId: itemId,
      status: "New",
    };
    try {
      await exceptionPOSTapi("Exceptions", body);
    } catch (error) {
      console.error("Error while handling exceptions: ", error);
    }
  };

  console.log("location outside callback ", location);

  /* HANDLE EDIT SUBMIT */
  const handleEditProjectDetail = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);
      console.log("location inside callback ", location);
      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescriptions: desc,
          location:
            detailedProfileEditData?.applicationName === "Employment History" ||
            (detailedProfileEditData?.applicationName === "Project  History" &&
              detailedProfileEditData?.mtype === "Own")
              ? location
              : locationForRecord.join(","),
        },

        formNames: [],
      };

      /* CHECK FOR EMPLOYMENT AND PROJECT HISTORY */
      if (
        detailedProfileEditData?.organization &&
        detailedProfileEditData?.projectActivity
      ) {
        payload.skillsApplied = {
          organization: organizationName,
          projectActivity: projectName,
        };
        payload.formNames.push("Skills Applied");
        payload.formNames.push("Employment History");
        payload.editData.organization = organizationName;
        payload.employmentHistory = {
          organization: organizationName,
        };
      }
      if (detailedProfileEditData?.organization) {
        payload.skillsApplied = {
          organization: organizationName,
        };
        payload.formNames.push("Skills Applied");
        payload.formNames.push("Project  History");
        payload.editData.organization = organizationName;
        payload.projectHistory = {
          organization: organizationName,
        };
      }

      if (detailedProfileEditData?.projectActivity) {
        payload.skillsApplied = {
          projectActivity: projectName,
        };
        payload.formNames.push("Skills Applied");
        payload.editData.projectActivity = projectName;
      }
      /* REMOVE DUPLICATES */
      payload.formNames = Array.from(new Set(payload.formNames));

      try {
        const res = await EditSkillsApi(
          `${detailedProfileEditData?.applicationName}`,
          detailedProfileEditData?.id,
          payload
        );
        const data = res?.data;
        if (isCustomOrg) {
          handleInsertNewOrganization(
            data?.editData?.organization,
            data?.editData?.mlanguage,
            data?.editData?.applicationName,
            data?.editData?.organization,
            data?.editData?.id
          );
        }

        const relationship = await fetchRelationshipdetails(
          detailedProfileEditData
        );
        console.log("RELATIONSHIP CONSOLE FOR CHECK", relationship)
        try {
          await Promise.all(
            relationship.map(async (relation) => {
              const payload = {
                editData: {
                  location:
                    detailedProfileEditData?.applicationName ===
                      "Employment History" ||
                    (detailedProfileEditData?.applicationName ===
                      "Project  History" &&
                      detailedProfileEditData?.mtype === "Own")
                      ? location
                      : locationForRecord.join(","),
                },
                formNames: [],
              };

              const response = await EditSkillsApi(
                `${relation?.applicationName}`,
                relation?.id,
                payload
              );
            })
          );
        } catch (error) {
          console.log(error);
          showErrorToast(
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "SomethingWentWrong"
              ) || {}
            ).mvalue || "nf Something went wrong"
          );
        }

        // relationship.forEach(async(relation)=>{
        //   const res = await EditSkillsApi(
        //     `${relation?.applicationName}`,
        //     relation?.id,
        //     {
        //       editData: {

        //         location: location
        //       },
        //     }
        //   );
        // })

        console.log(relationship);
        dispatch(FetchProjectHistory());
        dispatch(fetchDataSkillsApplied());
        dispatch(FetchOrganizationHistory());
        setIsSendingData(false);
        setIsCustomOrg(false);
        // setLocation('')
        // setOnline(false)
        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      projectName,
      dispatch,
      organizationName,
      detailedProfileEditData,
      handleModalClose,
      isCustomOrg,
      handleInsertNewOrganization,
      location,
      locationForRecord,
    ]
  );

  useEffect(() => {
    if (detailedProfileEditData) {
      setDesc(detailedProfileEditData?.briefDescriptions);
      /* SET ORGANIZATION AND PROJECT ACTIVITY */
      if (
        detailedProfileEditData?.projectActivity &&
        detailedProfileEditData?.organization
      ) {
        setProjectName(detailedProfileEditData?.projectActivity);
        setOrganizationName(detailedProfileEditData?.organization);
      } else if (detailedProfileEditData?.projectActivity) {
        setProjectName(detailedProfileEditData?.projectActivity);
        setOrganizationName("");
      } else if (detailedProfileEditData?.organization) {
        setProjectName("");
        setOrganizationName(detailedProfileEditData?.organization);
      }
    }
    // setOnline(false)
    // console.log('online false');
    setLocation("");
  }, [detailedProfileEditData]);

  /* ORGANIZATION SUGGESTIONS */
  useEffect(() => {
    if (
      organizationSuggestionsData.length > 0 &&
      organizationSuggestionsData[0].skillOccupation !== "No suggestions found"
    ) {
      const data = organizationSuggestionsData?.map((item) => {
        const newItem = {
          ...item,
          value: item.organization,
          label: item.organization,
        };
        return newItem;
      });
      setOrgApiData(data);
    }
  }, [organizationSuggestionsData]);

  const orgSearch = (e) => {
    if (e.length > 0) {
      setOrgApiData([]);
      setIsApiLoader(true);
      debouncedApiRequest(
        organizationSearchSuggestions,
        e,
        selectedLanguage,
        setOrganizationSuggestionsData,
        setIsApiLoader
      );
    } else {
      setOrgApiData([]);
    }
  };

  /* HANDLE ORGANIZATION NAME CHANGE */
  const handleOrganizationChange = (selectedOption) => {
    if (selectedOption) {
      /* CHECKING FOR THE ENTERED ORGANIZATION VALUE PRESENT IN DB */
      const isExistingOrganization = orgApiData.some(
        (organization) => organization.label === selectedOption.label
      );

      if (!isExistingOrganization) {
        /* FOR USER ENTERED VALUE */
        setOrganizationName(toTitleCase(selectedOption.label));
        setIsCustomOrg(true);
      } else {
        /* FOR THE ORGANIZATION VALUES IN DB */
        setOrganizationName(selectedOption.label);
      }
    } else {
      setOrganizationName("");
      setOrgApiData([]);
    }
  };

  useEffect(() => {
    if (detailedProfileEditData?.location) {
      setLocationForRecord(detailedProfileEditData?.location.split(","));
    } else {
      setLocationForRecord([]);
    }
  }, [detailedProfileEditData]);

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="detailedProfileEditModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <h5>
                {detailedProfileEditData?.applicationName ===
                "Employment History"
                  ? (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "EmploymentHistory"
                      ) || {}
                    ).mvalue || "nf EmploymentHistory"
                  : (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "ProjectHistory"
                      ) || {}
                    ).mvalue || "nf ProjectHistory"}
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <i className="me-2 mt-1">
                  {" "}
                  <span className="text-danger ">*</span>{" "}
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "RequiredFields"
                    ) || {}
                  ).mvalue || "nf Required Fields"}
                </i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                {/* DETAILED PROFILE EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-1">
                      {["Project  History"].includes(
                        detailedProfileEditData?.applicationName
                      ) && (
                        <div>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "ProjectName"
                            ) || {}
                          ).mvalue || "nf ProjectName"}
                          <input
                            type="text"
                            name="projectActivity"
                            className="form-control text-dark mt-2"
                            style={{ borderColor: "#ccc" }}
                            placeholder={projectName}
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                          />
                        </div>
                      )}

                      {/* M TYPE */}
                      <div className="mt-2" style={{ pointerEvents: "none" }}>
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "ProjectType"
                          ) || {}
                        ).mvalue || "nf Type"}
                        :
                        <div className="mt-2">
                          <CreateSelect
                            newField="orgIsnew"
                            placeholder={detailedProfileEditData?.mtype}
                            setNewField="organization"
                            value={detailedProfileEditData?.mtype}
                          />
                        </div>
                      </div>

                      {/* ORGANIZATION */}
                      {detailedProfileEditData?.organization && (
                        <div className="mt-2">
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "OrganizationName"
                            ) || {}
                          ).mvalue || "nf Organization Name"}
                          <div
                            className="mt-2"
                            style={{
                              pointerEvents: `${
                                detailedProfileEditData?.organization &&
                                detailedProfileEditData?.projectActivity
                                  ? "none"
                                  : ""
                              }`,
                            }}
                          >
                            {/* <input
                              type="text"
                              name="projectActivity"
                              className={
                                detailedProfileEditData?.organization &&
                                detailedProfileEditData?.projectActivity
                                  ? "form-control text-secondary bg-body-tertiary"
                                  : "form-control text-dark"
                              }
                              style={{
                                borderColor: "#ccc",
                                pointerEvents: `${
                                  detailedProfileEditData?.organization &&
                                  detailedProfileEditData?.projectActivity
                                    ? "none"
                                    : ""
                                }`,
                              }}
                              placeholder={organizationName}
                              value={organizationName}
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                            /> */}
                            <CreatableSelect
                              components={{
                                DropdownIndicator,
                                IndicatorSeparator,
                              }}
                              placeholder={organizationName}
                              onInputChange={(e) => {
                                setOrgSearchValue(e);
                                orgSearch(e);
                              }}
                              onChange={handleOrganizationChange}
                              value={
                                organizationName &&
                                organizationName.organization
                                  ? {
                                      label: organizationName.organization,
                                      value: organizationName.organization,
                                    }
                                  : null
                              }
                              isClearable
                              options={orgApiData}
                              isValidNewOption={() => orgSearchValue && true}
                            />

                            {isApiLoader && (
                              <div
                                style={{
                                  transform: "translate(365px,-24px)",
                                  width: "50px",
                                }}
                              >
                                <ThreeDots width={"30"} height={"10"} />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="d-lg-flex my-2 w-100">
                        <div className=" h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            {detailedProfileEditData?.applicationName ===
                            "Employment History"
                              ? (
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel ===
                                      "EmploymentStartDate"
                                  ) || {}
                                ).mvalue || "nf EmploymentStartDate"
                              : (
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ProjectStartDate"
                                  ) || {}
                                ).mvalue || "nf ProjectStartDate"}
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={timestampToYYYYMMDD(Number(detailedProfileEditData?.fromDate))}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            {detailedProfileEditData?.applicationName ===
                            "Employment History"
                              ? (
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "EmploymentEndDate"
                                  ) || {}
                                ).mvalue || "nf EmploymentEndDate"
                              : (
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ProjectEndDate"
                                  ) || {}
                                ).mvalue || "nf ProjectEndDate"}
                            {}
                          </label>
                          <input
                            type="date"
                            min={detailedProfileEditData?.toDate}
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={detailedProfileEditData?.toDate ? timestampToYYYYMMDD(Number(detailedProfileEditData?.toDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}
                      <div className="my-2">
                        <div>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label me-2"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "not found"}
                          </label>

                          {!(
                            detailedProfileEditData?.applicationName ===
                              "Employment History" ||
                            (detailedProfileEditData?.applicationName ===
                              "Project  History" &&
                              detailedProfileEditData?.mtype === "Own")
                          ) && (
                            <>
                              <SecondaryBtn
                                label={"reset"}
                                onClick={() => {
                                  if (detailedProfileEditData?.location) {
                                    setLocationForRecord(
                                      detailedProfileEditData?.location.split(
                                        ","
                                      )
                                    );
                                  } else {
                                    setLocationForRecord([]);
                                  }
                                }}
                              />
                            </>
                          )}

                          <div></div>
                        </div>

                        {detailedProfileEditData?.applicationName ===
                          "Employment History" ||
                        (detailedProfileEditData?.applicationName ===
                          "Project  History" &&
                          detailedProfileEditData?.mtype === "Own") ? (
                          <>
                            <div className="d-flex gap-2">
                              {detailedProfileEditData?.location
                                ?.split(",")
                                .map((loc) => {
                                  return (
                                    <div className="p-1 rounded bg-light text-secondary border">
                                      {loc}
                                    </div>
                                  );
                                })}

                              {!detailedProfileEditData?.location && (
                                <p className="mb-0 text-muted">
                                  {" "}
                                  No Locations{" "}
                                </p>
                              )}
                            </div>

                            <div className="d-flex align-items-center justify-content-end">
                              <input
                                id="onlineCheckbox"
                                className="ml-2"
                                type="checkbox"
                                name="online"
                                checked={location.includes("Online")}
                                onChange={(e) => {
                                  setOnline(!online);
                                }}
                              />
                              <label htmlFor="onlineCheckbox" className="ms-1">
                                {" "}
                                {(
                                  content[selectedLanguage].find(
                                    (item) => item.elementLabel === "Online"
                                  ) || {}
                                ).mvalue || "nf Online"}
                              </label>
                            </div>
                            <MultiSelect
                              setLocationData={setLocation}
                              viewLocation={location}
                              onlineStatus={online}
                            />
                          </>
                        ) : (
                          <div className="d-flex gap-2">
                            {locationForRecord.map((loc) => {
                              return (
                                <div className="p-1  rounded bg-light border">
                                  {loc}
                                  <span
                                    className="text-muted px-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let newLocations =
                                        locationForRecord?.filter(
                                          (location) => {
                                            return location !== loc;
                                          }
                                        );
                                      setLocationForRecord(newLocations);
                                    }}
                                  >
                                    <RxCross2 />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      {/* EDITABLE DATA */}
                      <div className="my-2">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "BriefDescriptionAboutOrganization"
                            ) || {}
                          ).mvalue || "nf Brief Description about Organization"}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescriptions"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditProjectDetail("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditProjectDetail("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditDetailedProfile;
