import axios from "axios";
import { BASE_URL } from "../../../config/Properties";

const SkillSuggestionApi = async (query, selectedLanguage, searchField) => {

  selectedLanguage  = selectedLanguage.substring(0, 2);
  let SEARCH_URI =0;
  console.log("selectedLanguage" , selectedLanguage);
  // if(selectedLanguage.includes("EN"))
  //   SEARCH_URI = BASE_URL +`/skill/api/v1/skills/searchSkills/skill/${"EN-GB"}?searchFieldName=${searchField}&authToken=${localStorage.getItem("token")}`;
  // else SEARCH_URI = BASE_URL +`/skill/api/v1/skills/searchSkills/skill/${selectedLanguage}?searchFieldName=${searchField}&authToken=${localStorage.getItem("token")}`;
  SEARCH_URI = BASE_URL +`/skill/api/v1/skills/searchSkills/skill/${selectedLanguage}?searchFieldName=${searchField}&authToken=${localStorage.getItem("token")}`;
  return axios
    .get(`${SEARCH_URI}&searchValue=${query}`)
    .then((response) => {
      //console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default SkillSuggestionApi;
