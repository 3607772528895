import React, { useEffect, useState } from "react";
import PersonalInfo from "./PersonalInfo";
import profileImage from "../../img/profile-circle-svgrepo-com (5).svg";
import {
  GetAttachment,
  splitStringToObject,
} from "../../api/Attachment  API/DownloadAttachmentApi";

const ResumeHeader = ({
  logo,
  content,
  selectedLanguage,
  userDetails,
  headerColor,
  fontColor,
  fontSize,
  profileNameColor,
  profieOtherDetailColor,
  selectedColor,
  alignment,
  isProfileImgLeft,
  handleStylingOptions,
  isShowStylingOptions,
  handleFontColor,
  handleProfileNameColor,
  handleProfileOtherColor,
  handleAlignmentChange,
  profileNameAlignment,
  profileDetailAlignment,
  handleProfileNameAlignment,
  handleOtherDetailAligment,
  setIsShowStylingOptions,
  handleFontPNSizeInc,
  handleFontPNSizeDec,
  profileNameSize,
  profielDetailSize,
  handleFontOtherDSizeInc,
  handleFontOtherDSizeDec,
  backgroundImage,
}) => {
  /* STATES */
  const [profilePicObj, setProfilePicObj] = useState({});

  /* FORMAT THE STR */
  useEffect(() => {
    setProfilePicObj(splitStringToObject(userDetails?.profilePictureFileName));
  }, [userDetails]);

  /* CSS VARIABLE FOR DYNAMIC STYLING */
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--selected-color",
      selectedColor
    );
    document.documentElement.style.setProperty("--font-color", fontColor);
    document.documentElement.style.setProperty(
      "--selected-bg-img",
      `url(${backgroundImage})`
    );
  }, [selectedColor, fontColor, backgroundImage]);

  return (
    <React.Fragment>
      <headers
        className="d-flex justify-content-between align-items-center resume-header_footer"
        id="resume_header"
        style={{
          flex: "3",
          backgroundColor: `${headerColor}`,
          color: `${fontColor}`,
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "1rem",
        }}
      >
        {userDetails?.profilePictureFileName &&
          userDetails?.profilePictureFileName?.length > 1 &&
          userDetails?.ppShowHide !== "Yes" &&

          <div
            className="d-flex"
            style={{ flex: "1", order: isProfileImgLeft ? "1" : "2" }}
          >
            <div style={{ margin: "1vw" }}>
              <img
                src={
                  userDetails?.profilePictureFileName &&
                    userDetails?.profilePictureFileName?.length > 1 &&
                    userDetails?.ppShowHide !== "Yes"
                    ? GetAttachment(
                      userDetails?.id,
                      profilePicObj?.fileName,
                      profilePicObj?.fileId
                    )
                    : profileImage
                }
                onError={(e) => (e.target.src = profileImage)}
                alt={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Profile Picture"
                    ) || {}
                  ).mvalue || "nf Profile Picture"
                }
                width="100"
                height="100"
                className="d-inline-block bg-img"
              />
            </div>
          </div>
        }
        <div style={{ flex: "3", order: isProfileImgLeft ? "2" : "1" }}>
          <PersonalInfo
            content={content}
            selectedLanguage={selectedLanguage}
            userDetails={userDetails}
            alignment={alignment}
            fontColor={fontColor}
            fontSize={fontSize}
            handleFontColor={handleFontColor}
            profileNameColor={profileNameColor}
            profieOtherDetailColor={profieOtherDetailColor}
            handleProfileNameColor={handleProfileNameColor}
            handleProfileOtherColor={handleProfileOtherColor}
            handleAlignmentChange={handleAlignmentChange}
            profileNameAlignment={profileNameAlignment}
            profileDetailAlignment={profileDetailAlignment}
            handleProfileNameAlignment={handleProfileNameAlignment}
            handleOtherDetailAligment={handleOtherDetailAligment}
            handleStylingOptions={handleStylingOptions}
            isShowStylingOptions={isShowStylingOptions}
            setIsShowStylingOptions={setIsShowStylingOptions}
            handleFontPNSizeDec={handleFontPNSizeDec}
            handleFontPNSizeInc={handleFontPNSizeInc}
            profileNameSize={profileNameSize}
            profielDetailSize={profielDetailSize}
            handleFontOtherDSizeDec={handleFontOtherDSizeDec}
            handleFontOtherDSizeInc={handleFontOtherDSizeInc}
          />
        </div>
        <div className="logo-right-watermark d-flex justify-content-end" style={{ flex: "1", order: "3" }}>
          <img
            src={logo}
            alt={
              (
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "MySTSkillProfile"
                ) || {}
              ).mvalue || "nf MySTSkillProfile"
            }
            width="100"
            height="100"
            style={{ pointerEvents: "none" }}
          />
        </div>
      </headers>
    </React.Fragment>
  );
};

export default ResumeHeader;
