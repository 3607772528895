import React from "react";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import Anonimization from "../../Anonimization";
import AttachmentForm from "../../AttachmentForm";
import TableLoaders from "../../CustomLoader/TableLoaders";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import ShowHideIcon from "../../ShowHideIcon";
import Validation from "../../ValidationForm";
import EditDetailedProfile from "../Forms/Edit Forms/EditDetailedProfile";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";
const EmploymentDetail = ({ data, editEnable }) => {
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };
  const regionalData = useSelector((state) => state.regionalData);

  /* VALIDATION HANDLING */
  const handleValidate = (data) => {
    dispatch(setValidationData(data));
  };

  /* HANDLE EDIT */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };
  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      {<AttachmentForm />}
      {/* DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfile />}
      <DeleteFormDetailedProfile />
      <div className="table-responsive ">
        <table className="table table-sm      table-fixed     ">
          <tr className="border-dark-subtle border-bottom ">
            <th scope="col" className="bg-body- " style={{ width: "32%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectOrganization"
                ) || {}
              ).mvalue || "nf Organization"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "19%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectDuration"
                ) || {}
              ).mvalue || "nf Duration"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "13%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectType"
                ) || {}
              ).mvalue || "nf Type"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "14%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectLocation"
                ) || {}
              ).mvalue || "nf Location"}
            </th>
            <th scope="col" className="         " style={{ width: "12%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "ProjectValidation"
                ) || {}
              ).mvalue || "nf Validation"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "10%" }}></th>
          </tr>
        </table>
      </div>

      {data.status === "loading" ? (
        <TableLoaders Rows={2} Cols={5} btnCols={3} />
      ) : (
        data.status === "success" &&
        data.data.length > 0 &&
        data.data.map((empl, index) => (
          <>
            {empl.mlanguage === selectedLanguage && (
              <>
                <div className="border-bottom  ">
                  <div className="d-flex   " key={index}>
                    <div className=""></div>
                    <div className="  " style={{ width: "32%" }}>
                      {empl.organization}
                    </div>
                    <div className="    " style={{ width: "19%" }}>
                      {DayDifferenceToDynamicView(empl.duration)}
                    </div>
                    <div style={{ width: "13%" }}>{empl.mtype}</div>
                    <div
                      className="    "
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={empl.location}
                      style={{ width: "14%" }}
                    >
                      {empl.location
                        ? empl.location.length > 15
                          ? empl.location.substring(0, 12) + "..."
                          : empl.location
                        : ""}
                    </div>
                    {console.log("JJKKDKDK", empl)}
                    <div className="" style={{ width: "12%" }}>
                      {empl?.validation}
                    </div>
                    <div
                      className="   d-flex justify-content-between   "
                      style={{ width: "10%" }}
                    >
                      {editEnable ? (
                        <>
                          <div
                            id="addModalBtn"
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="modal"
                            data-bs-target="#detailedProfileEditModal"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Edit"
                            onClick={() => handleEdit(empl)}
                          >
                            <MdEdit />
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Delete"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteformdetailedprofile"
                            onClick={() => {
                              dispatch(setDeleteDetailedProfileData(empl));
                            }}
                          >
                            <MdDelete />
                          </div>

                          <div style={{ cursor: "pointer" }}>
                            <ShowHideIcon
                              value={empl.recordHide === "Yes" ? true : false}
                              toggleable={true}
                              form={empl}
                              fieldName={"recordHide"}
                              api={"Skills Applied"}
                              id={empl.id}
                            />
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <Anonimization
                              value={
                                empl.recordAnonymous === "Yes" ? true : false
                              }
                              toggleable={true}
                              form={empl}
                              fieldName={"recordAnonymous"}
                              api={"Skills Applied"}
                              id={empl.id}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="border-0 bg-white"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Validate"
                            data-bs-toggle="modal"
                            data-bs-target="#validationForm"
                            onClick={() => handleValidate(empl)}
                          >
                            <img
                              src={validationIcon}
                              alt=""
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </button>
                          <button
                            className="border-0 bg-white"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Attachment"
                            onClick={() => handleAttachment(empl)}
                            data-bs-toggle="modal"
                            data-bs-target="#attachmentList"
                          >
                            <ImAttachment />
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="ms-5  my-3  ">
                    <table className="d-flex gap-4  ">
                      <tr>
                        <td>
                          <b>
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjecStart"
                              ) || {}
                            ).mvalue || "nf From"}{" "}
                            &nbsp;
                          </b>{" "}
                        </td>
                        <td>
                          :{" "}
                          {formatTimestampToDate(
                            Number(empl.fromDate),
                            regionalData.selectedCountry.dateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjectEnd"
                              ) || {}
                            ).mvalue || "nf To"}{" "}
                            &nbsp;
                          </b>{" "}
                        </td>
                        <td>
                          :{" "}
                          {empl.toDate
                            ? formatTimestampToDate(
                                Number(empl.toDate),
                                regionalData.selectedCountry.dateFormat
                              )
                            : "On-going"}
                        </td>
                      </tr>
                    </table>
                    <div className="pb-1 pt-2  ">
                      <b>
                        {(
                          content[selectedLanguage].find(
                            (item) =>
                              item.elementLabel === "ProjectBriefDescription"
                          ) || {}
                        ).mvalue || "nf Brief Description"}
                        :
                      </b>{" "}
                    </div>
                    <div className="ms-4  ">{empl.briefDescriptions}</div>
                  </div>
                  {empl.validation === "Yes" && (
                    <ValidationDataComponent itemId={empl.id} />
                  )}
                </div>
              </>
            )}
          </>
        ))
      )}
    </>
  );
};

export default EmploymentDetail;
