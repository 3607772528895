import React from 'react'
import Loader from '../Loader'
import { useSelector } from 'react-redux';

const ContentLoader = ({ children }) => {

    const status = useSelector(state => state.content.status);
    console.log(status,"status");

    return (
        <>
            {status === 'succeeded' && <div>{children}</div>}
            {(status === 'loading' || status === 'ideal') && <Loader />}
        </>
    )
}

export default ContentLoader