import React from 'react'
import Footer from '../components/Footer'

const NotFound = () => {
    return (
        <div>

            <div class="d-flex align-items-center justify-content-center mt-5  ">
                <div class="text-center">
                    <h1 class="display-1 fw-bold">404</h1>
                    <p class="fs-3"> <span class="secondary-brown-text">Opps!</span> Page not found.</p>
                    <p class="lead">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <button type="button" className="btn text-white font-5" style={{ backgroundColor: "#815F0B" }} onClick={() => window.location.href = "/myskill-app/#/dashboard/skillprofile"}>Go Home</button>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NotFound