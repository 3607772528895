import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SkillProfileDashboard from "./pages/SkillOwner/SkillProfile/SkillProfileDashboard";
import DetailsProfileManagement from "./pages/SkillOwner/DetailedProfile/DetailsProfileManagement";

import EducationHistory from "./pages/SkillOwner/DetailedProfile/EducationHistory";
import TrainingHistory from "./pages/SkillOwner/DetailedProfile/TrainingHistory";
import CertificationHistory from "./pages/SkillOwner/DetailedProfile/CertificationHistory";
import ConferenceHistory from "./pages/SkillOwner/DetailedProfile/ConferenceHistory";
import SkillingHistory from "./pages/SkillOwner/DetailedProfile/SkillingHistory";
import ProjectHistory from "./pages/SkillOwner/DetailedProfile/ProjectHistory";

import AchivementsHistory from "./pages/SkillOwner/DetailedProfile/AchivementsHistory";
import MembershipHistory from "./pages/SkillOwner/DetailedProfile/MembershipHistory";
import LanguageHistory from "./pages/SkillOwner/DetailedProfile/LanguageHistory";
import Resume from "./components/Resume";
import SkillDashboard from "./pages/SkillAvailer/SkillDashboard";
import DetailedResume from "./components/DetailedResume";
import NotFound from "./pages/NotFound";
import JdBasedSearch from "./pages/SkillAvailer/JdBasedSearch";
import { Route, Routes, HashRouter } from "react-router-dom";
import Others from "./pages/SkillOwner/DetailedProfile/Others";
import ContentLoader from "./components/CustomLoader/ContentLoader";

// skilling agency
import SkillingAgencyDashboard from "./pages/SkillingAgency/SkillingAgencyDashboard";
import CourseSearch from "./pages/SkillingAgency/CourseSearch/CourseSearch";
import CoursePreview from "./pages/SkillingAgency/CourseSearch/CoursePreview";
import EditCourse from "./pages/SkillingAgency/CourseSearch/EditCourse";
import CourseCandidate from "./pages/SkillingAgency/CourseSearch/CourseCandidate";
import { AuthGuard } from "./AuthGuard/AuthGuard";
import TranslationService from "./pages/SkillingAgency/PremiumServices/TranslationService";
import HscLogin from "./pages/AuthPages/HscLogin";
import HscSignup from "./pages/AuthPages/HscSignup";
import ForgotPassword from "./pages/AuthPages/ForgotPassword";
import ForgotPasswordNoAccess from "./pages/AuthPages/ForgotPasswordNoAccess";
import ForgotPasswordWithAltEmail from "./pages/AuthPages/ForgotPasswordWithAltEmail";
import CreateCourse from "./pages/SkillingAgency/CourseSearch/CreateCourse";
import ValidationTemp from "./pages/template/ValidationTemp";
import LicenseHistory from "./pages/SkillOwner/DetailedProfile/LicenseHistory";

// support services
import SupportService from "./pages/SupportService/SupportService";
import CreateService from "./pages/SupportService/CreateService.jsx/CreateService";
import ServiceSearch from "./pages/SupportService/ServiceSearch/ServiceSearch";
import ServiceDetails from "./components/Supportservices/ServiceDetails";
import EditService from "./pages/SupportService/EditService";
import ServiceDashboard from "./pages/SupportService/ServiceDashboard";
import ServiceDashboardLayout from "./pages/SupportService/ServiceDashboardLayout";
import CompanyList from "./pages/SupportService/CompanyList";
import TopServices from "./pages/SupportService/TopServices";
import NewUser from "./pages/SkillOwner/NewUser/NewUser";
import ForgotPasswordTemplate from "./pages/template/Forgot Password/ForgotPasswordTemplate";
import NewUserLinkSkills from "./pages/SkillOwner/NewUser/NewUserLinkSkills";
import ResumeTemplate from "./pages/template/Reseme/ResumeTemplate";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setRegCountry } from "./reducer/localization/CountryRegionalSlice";
import { useDispatch } from "react-redux";
import EmptyPage from "./components/SkillAvailer/EmptyPage";
import Timeline from "./pages/SkillOwner/Timeline/Timeline";
import ResumeParser from "./pages/SkillOwner/NewUser/ResumeParser";
import NavbarForNewUser from "./pages/SkillOwner/NewUser/NavbarForNewUser";
import ResumeResults from "./pages/SkillOwner/NewUser/ResumeResults";
import UserVerificationEmail from "./pages/AuthPages/Login & Singup/UserVerificationEmail";
import LoginTemplate from "./pages/AuthPages/Login & Singup/Login Component/LoginTemplate";
import SignUpTemplate from "./pages/AuthPages/Login & Singup/SignUp Component/SignUpTemplate";
import NewUserDestinationScreen from "./pages/SkillOwner/NewUser/components/NewUserDestinationScreen";
import JDVIewDashboard from "./pages/SkillAvailer/JDVIewDashboard";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();
  const regionalData = useSelector(state => state.regionalData.listOfCountries);
  const selectedCountry = useSelector(state => state.regionalData.selectedCountry);

  useEffect(() => {
    console.log("regionalData22 ", regionalData);
    regionalData?.map((country) => {
      if (country?.countryCode === localStorage.getItem("countryCode")) {
        dispatch(setRegCountry(country));
        return;
      }
    })
  }, [localStorage.getItem("countryCode"), localStorage.getItem("TimeZone")])
  useEffect(() => {
    regionalData?.map((country) => {
      if (country?.countryCode === localStorage.getItem("countryCode")) {
        dispatch(setRegCountry(country));
        return;
      }
    })
  }, [])

  useEffect(() => {
    console.log("selectedCountry ", selectedCountry);

  }, [selectedCountry]);
  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Routes>
          {/* auth pages */}
          <Route
            path="/"
            element={
              <ContentLoader>
                <HscLogin />
              </ContentLoader>
            }
          />
          <Route
            path="/login"
            element={
              <ContentLoader>
                <HscLogin />
              </ContentLoader>
            }
          />
          {/* NEW SIGNIN & SINGUP EMAIL PAGE */}
          <Route
            path="/user-auth"
            element={
              <ContentLoader>
                <UserVerificationEmail />
              </ContentLoader>
            }
          />
          {/* NEW LOGIN */}
          <Route
            path="/login-page"
            element={
              <ContentLoader>
                <LoginTemplate />
              </ContentLoader>
            }
          />
          {/* NEW SIGNUP */}
          <Route
            path="/signup-page"
            element={
              <ContentLoader>
                <SignUpTemplate />
              </ContentLoader>
            }
          />
          <Route
            path="/signup"
            element={
              <ContentLoader>
                 <HscSignup /> 
              </ContentLoader>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <ContentLoader>
                <ForgotPassword />
              </ContentLoader>
            }
          />
          <Route
            path="/forgotpassword-noaccess"
            element={
              <ContentLoader>
                <ForgotPasswordNoAccess />
              </ContentLoader>
            }
          />
          <Route
            path="/forgotpassword-altemail"
            element={
              <ContentLoader>
                <ForgotPasswordWithAltEmail />
              </ContentLoader>
            }
          />
          <Route
            path="reset/:id"
            element={
              <ContentLoader>
                <ForgotPasswordTemplate />
              </ContentLoader>
            }
          />
          <Route
            path="reset/:id"
            element={
              <ContentLoader>
                <ForgotPasswordTemplate />
              </ContentLoader>
            }
          />
          {/* skilling agency */}
          <Route
            path="/skillingagency"
          // element={<AuthGuard allowedRoles={["R3"]} />}
          >
            <Route path="skillsearch" element={<SkillDashboard />} />
            <Route path="coursesearch" element={<SkillingAgencyDashboard />}>
              <Route index element={<CourseSearch />} />
              {/* <Route path="createcourse/new" element={<NewCourse/>}/> */}
              <Route path="createcourse/new" element={<CreateCourse />} />
              <Route path="coursepreview/:id" element={<CoursePreview />} />
              <Route path="courseedit/:id" element={<EditCourse />} />
              <Route path="coursecandidate/:id" element={<CourseCandidate />} />

              <Route
                path="premimum/translationservice"
                element={<TranslationService />}
              />
            </Route>
          </Route>
          {/* SUPPORT SERVICES */}
          <Route
            path="/supportservices"
            element={
              <AuthGuard allowedRoles={["R4"]}>
                <SupportService></SupportService>
              </AuthGuard>
            }
          >
            <Route path="skillsearch" element={<ServiceDashboardLayout />}>
              <Route index element={<ServiceDashboard />}></Route>
              <Route path=":id" element={<CompanyList></CompanyList>} />
            </Route>
            <Route index element={<ServiceDashboard />}></Route>

            <Route path="servicesearch">
              <Route index element={<ServiceSearch />}></Route>
              <Route path="topservices" element={<TopServices />}></Route>
            </Route>
            <Route path="createservice" element={<CreateService />}></Route>
            <Route
              path=":id"
              element={<ServiceDetails></ServiceDetails>}
            ></Route>
            <Route
              path="editservices/:id"
              element={<EditService></EditService>}
            />
          </Route>
          {/* skill owner */}


          <Route path="/newuser" element={ <ContentLoader> {" "}<NavbarForNewUser/></ContentLoader>}>

            <Route
              path="resumeparser"
              element={
                <ContentLoader>
                  {" "}
                  <ResumeParser />{" "}
                </ContentLoader>
              }
            />
            <Route
              path="resumeresults"
              element={
                <ContentLoader>
                  {" "}
                  <ResumeResults />{" "}
                </ContentLoader>
              }
            />
            <Route
              path="basicInfo"
              element={
                <ContentLoader>
                  {" "}
                  <NewUser />{" "}
                </ContentLoader>
              }
            />
            <Route
              path="linkskills"
              element={
                <ContentLoader>
                  {" "}
                  <NewUserLinkSkills />{" "}
                </ContentLoader>
              }
            />
            </Route>
            
            <Route
            path="/newuser/destination"
            element={
              <ContentLoader>
                {" "}
                <NewUserDestinationScreen />{" "}
              </ContentLoader>
            }
          />

          <Route
            path="/dashboard"
          // element={<AuthGuard allowedRoles={["R1"]} />}
          >
            <Route
              path="skillprofile"
              element={
                <ContentLoader>
                  {" "}
                  <SkillProfileDashboard />{" "}
                </ContentLoader>
              }
            />
            <Route
              path="timeline"
              element={
                <ContentLoader>
                  {" "}
                  <Timeline />{" "}
                </ContentLoader>
              }
            />
          </Route>
          <Route
            path="/dashboard/detailedprofile"
          // element={<AuthGuard allowedRoles={["R1"]} />}
          >
            <Route
              index
              element={
                <ContentLoader>
                  <DetailsProfileManagement />
                </ContentLoader>
              }
            />
            <Route
              path="emploment"
              element={
                <ContentLoader>
                  <DetailsProfileManagement />
                </ContentLoader>
              }
            />
            <Route
              path="education"
              element={
                <ContentLoader>
                  <EducationHistory />
                </ContentLoader>
              }
            />
            <Route
              path="certification"
              element={
                <ContentLoader>
                  <CertificationHistory />
                </ContentLoader>
              }
            />
            <Route
              path="training"
              element={
                <ContentLoader>
                  <TrainingHistory />
                </ContentLoader>
              }
            />
            <Route
              path="conference"
              element={
                <ContentLoader>
                  <ConferenceHistory />
                </ContentLoader>
              }
            />
            <Route
              path="skilling"
              element={
                <ContentLoader>
                  <SkillingHistory />
                </ContentLoader>
              }
            />
            <Route
              path="licenses"
              element={
                <ContentLoader>
                  <LicenseHistory />
                </ContentLoader>
              }
            />
            <Route
              path="project"
              element={
                <ContentLoader>
                  <ProjectHistory />
                </ContentLoader>
              }
            />
            <Route
              path="achivements"
              element={
                <ContentLoader>
                  <AchivementsHistory />
                </ContentLoader>
              }
            />
            <Route
              path="membership"
              element={
                <ContentLoader>
                  <MembershipHistory />
                </ContentLoader>
              }
            />
            <Route
              path="language"
              element={
                <ContentLoader>
                  <LanguageHistory />
                </ContentLoader>
              }
            />
            <Route
              path="others"
              element={
                <ContentLoader>
                  <Others />
                </ContentLoader>
              }
            />
          </Route>{" "}
          {/* VALIDATION TEMPLATE PAGE */}
          <Route
            path="/dashboard/validation/:id"
            element={
              <ContentLoader>
                <ValidationTemp />
              </ContentLoader>
            }
          />
          <Route
            path="/dashboard/viewDetail/:id/:view"
            element={
              <ContentLoader>
                <EmptyPage />
              </ContentLoader>
            }
          />

          {/* RESUME TEMPLATE */}
          <Route
            path="/dashboard/resume-template"
            element={
              <ContentLoader>
                <ResumeTemplate />
              </ContentLoader>
            }
          />
          {/* support print */}
          <Route path="/dashboard/resume" element={<Resume />} />
          <Route path="/dashboard/detailResume" element={<DetailedResume />} />
          {/* skill availer */}
          <Route
            path="/dashboard"
            element={
              <ContentLoader>
                <AuthGuard allowedRoles={["R2"]}>
                  <SkillDashboard />
                </AuthGuard>
              </ContentLoader>
            }
          />

          <Route

            path="/dashboard/conventionalsearch"
            element={
              <ContentLoader>
                {/* <AuthGuard allowedRoles={["R2"]}> */}
                <SkillDashboard />
                {/* </AuthGuard> */}
              </ContentLoader>
            }
          />
          <Route
            path="/dashboard/jdsearch"
            element={
              <ContentLoader>
                <JDVIewDashboard />
              </ContentLoader>
            }
          />
          {/* error page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
