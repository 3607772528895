import React from 'react'
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DetailedPofileNavbar = () => {
    //store
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);


    const location = useLocation().pathname;
    const active = { backgroundColor: "" };
    const Inactive = { color: "black" };

    return (
        <div className=''>


            <table className='table table-sm table-hover' >
                <thead>
                    <tr>
                        <div className="mt-2  rounded-top  " style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126", height: "36px" }}>
                            <div className='text h6 text-center   ' style={{ padding: "6px 0", color: (content[selectedLanguage].find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD" }} >
                                {/* Detailed Profile */}
                                {(content[selectedLanguage].find(item => item.elementLabel === 'DetailedProfile') || {}).mvalue || "nf Detailed Profile"}
                            </div>
                        </div>

                    </tr>
                </thead>


                <tbody>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile" style={location === "/dashboard/detailedprofile" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile" ? active : Inactive}>
                                    {/* Employment */}
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyEmployments') || {}).mvalue || "nt MyEmployments"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/project" style={location === "/dashboard/detailedprofile/project" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/project" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectHistory') || {}).mvalue || "nf Projects"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/education" style={location === "/dashboard/detailedprofile/education" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/education" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyEducation') || {}).mvalue || "nt MyEducation"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/certification" style={location === "/dashboard/detailedprofile/certification" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/certification" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyCertifications') || {}).mvalue || "nt My Certifications"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/training" style={location === "/dashboard/detailedprofile/training" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/training" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyTrainings') || {}).mvalue || "nt MyTrainings"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/conference" style={location === "/dashboard/detailedprofile/conference" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/conference" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyConferences') || {}).mvalue || "nt MyConferences"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/skilling" style={location === "/dashboard/detailedprofile/skilling" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/skilling" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MySkillings') || {}).mvalue || "nt MySkillings"}
                                </div>
                            </Link>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/licenses" style={location === "/dashboard/detailedprofile/licenses" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/licenses" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyLicenses') || {}).mvalue || "nt MyLicenses"}
                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/achivements" style={location === "/dashboard/detailedprofile/achivements" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100  " style={location === "/dashboard/detailedprofile/achivements" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyAwards') || {}).mvalue || "nf /MyAwards"}

                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/membership" style={location === "/dashboard/detailedprofile/membership" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/membership" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyMemberships') || {}).mvalue || "nt MyMemberships"}

                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/language" style={location === "/dashboard/detailedprofile/language" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100  " style={location === "/dashboard/detailedprofile/language" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'MyLanguages') || {}).mvalue || "nt MyLanguages"}

                                </div>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to="/dashboard/detailedprofile/others" style={location === "/dashboard/detailedprofile/others" ? { color: "#815F0B", textDecoration: "none" } : { color: "#815F0B", textDecoration: "none" }}>
                                <div className=" text-start font-5 w-100   " style={location === "/dashboard/detailedprofile/others" ? active : Inactive}>
                                    {(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || "nt Others"}

                                </div>
                            </Link>
                        </td>
                    </tr>

                </tbody>

            </table>
            <div className="d-grid gap-2 ">





            </div>
        </div>
    )
}

export default DetailedPofileNavbar