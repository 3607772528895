import axios from 'axios';
import { BASE_URL } from './../../config/Properties';
export const GetAttachment = (ticketId, fileName, fileID) => {

    const token = localStorage.getItem("token");


    //http://localhost:8080/skill/api/v1/skills/GetFile/
    //filedownloadById/SPRJT-1452049/tiger(1).jpg/FILES-1452111?Authorization=7c481530f8c655952a22aee49389dc95

    return `${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${ticketId}/${fileName}/${fileID}?Authorization=${token ? token: "dwds"}`;
}

export const GetAttachmentNoToken = (ticketId, fileName, fileID) => {
    return `${BASE_URL}/skill/api/v1/skills/GetFileNoTk/filedownloadById/${ticketId}/${fileName}/${fileID}`;
}


export const DownloadAttachment = (ticketId, fileName, fileID) => {

    const token = localStorage.getItem("token");


    //http://localhost:8080/skill/api/v1/skills/GetFile/
    //filedownloadById/SPRJT-1452049/tiger(1).jpg/FILES-1452111?Authorization=7c481530f8c655952a22aee49389dc95

    return `${BASE_URL}/skill/api/v1/skills/download/filedownloadById/${ticketId}/${fileName}/${fileID}?Authorization=${token}`;
}


//https://beanstalk.myskillstree.in/skill/api/v1/skills/removeFile/SKLNG-1452190/FILES-1452191?Authorization=b08fb4eaa66d9795d5c515607d7038a5
// export const DeleteAttachment = (ticketId, fileID) => {

//     const token = localStorage.getItem("token");




//     return `${BASE_URL}/skill/api/v1/skills/removeFile/${ticketId}/${fileID}?Authorization=${token}`;
// }

export const DeleteAttachment = async (ticketId, fileID) => {
    const token = localStorage.getItem("token");

    return axios
        .delete(`${BASE_URL}/skill/api/v1/skills/removeFile/${ticketId}/${fileID}?Authorization=${token}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};

export const splitStringToObject = (str) => {
    try {
        const parts = str.split('||').map(part => part.trim());
        const obj = {};
        parts?.forEach(part => {
            const [key, value] = part.split('=').map(item => item.trim());
            obj[key] = value;
        });
        return obj;
    } catch (error) {
        console.error("Error occurred while parsing the string:", error.message);
        return {}; //Return an empty object in case of failure
    }
}
