import React from 'react'
import SmallLoader from './SmallLoader'
import DetailedProfileView from './DetailedProfileView'
import { useSelector } from 'react-redux';

const DetailedProfileViewSeeker = ({detailProfileView,userDetail,detailProfileLoader}) => {

    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);

    return (
        <React.Fragment>
            {
                detailProfileView &&
                <div className="   border mb-2" style={{
                    borderRadius: "20px",
                    borderColor: "#815F0B",
                    borderStyle: "solid",
                   

                }}>
                    <div
                        className="d-flex bg-body- justify-content-center align-items-center  mb-2"
                        style={{
                            fontSize: `22px`,
                            color: "#212529",
                           
                            fontWeight: "",
                        }}
                    >
                        {(
                            content[selectedLanguage].find(
                                (item) => item.elementLabel === "DetailedProfile"
                            ) || {}
                        ).mvalue || "nf DetailedProfile"}
                    </div>

                    <div style={{ maxHeight: "400px", minHeight: "150px", overflowY: "scroll" }}>
                        {detailProfileLoader ? <SmallLoader bg={"120px"} height={"3rem"} width={"3rem"} color={"black"} /> :
                            <>
                                <DetailedProfileView userDetails={userDetail} />
                                {/* Skill learnt from */}
                            </>
                        }

                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default DetailedProfileViewSeeker