import React from 'react'
import ShowHideIcon from '../ShowHideIcon'
import { useSelector } from 'react-redux';
import { GetUserForSelectedLanguage } from '../SkillOwner/HelperFunction/GetUserForSelectedLanguage';

const PrivacyModal = () => {


    const selectedLanguage = useSelector(state => state.language);

    const userDetails = useSelector(state => GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage));

    return (
        <div class="modal fade modal-xl" id="privacy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Privacy settings</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div className=''>
                            <div>
                                <div class="container">
                                    <div class="row justify-content-center  mb-2 pb-2  ">
                                        <div class="col-12    bg-body-secondary  pt-2  ">

                                            <div className='d-flex justify-content-between mb-2 '>
                                                {/* left side */}
                                                <div>
                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Personal Information</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Name</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: {userDetails.firstName}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>location</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: {userDetails.city}, {userDetails.candidateState}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Phone number</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: {userDetails.mobileNumber}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Email address</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: {userDetails.email}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Gender</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Male </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Date of birth</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: 01/01/1990</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Address</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: {userDetails.city}, {userDetails.candidateState}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>profile Picture</b></p>
                                                                    </td>

                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Organization</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: DocuSign</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* center side */}
                                                <div>
                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Employment History</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Company name</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: DocuSign</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Company name</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Virtusa </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Company name</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: TCS </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Education History</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Institute Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: DocuSign</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Institute Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Virtusa </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Institute Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: TCS </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Awards and Achivements</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Award Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Best Employee 2023</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Certifications</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Certification Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Java SE8</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Certification Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: React & Redux</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Skilling</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Conference</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>


                                                    </div>

                                                </div>
                                                {/* right side */}
                                                <div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Project History</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Project Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Chat bot</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b>Project Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: Billing portal </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'><b>Project Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: College web app </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>language</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'>English</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'>Tamil</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p className='font-5'>Arabic</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Memberships</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>

                                                        <div>
                                                            <table className='table table-borderless my-0 py-0 ' >
                                                                <tr className='my-0 py-0 '>
                                                                    <td>
                                                                        <p className='font-5'><b> Membership Name </b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>: DocuSign</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='font-5'>
                                                                            <ShowHideIcon value={true} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='d-flex gap-3'>
                                                            <b>Training</b>
                                                            <ShowHideIcon value={true} />
                                                        </p>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default PrivacyModal