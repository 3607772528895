import React from "react";
import { formatExperience } from "../SkillOwner/HelperFunction/FormatExperience";

const ResumeBodyResportCard = ({
  selectedLanguage,
  content,
  fontSize,
  userDetails,
  top5Skills,
  remainingSkills,
  SkillSelected,
  SkillsApplied,
  SkillsAcquired,
}) => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center align-items-center resume-sub-headings"
        style={{
          fontSize: `${fontSize + 6}px`,
          color: "#808080",
          fontWeight: "bold",
        }}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MySTSkillProfile"
          ) || {}
        ).mvalue || "nf MyST Skill Profile"}
      </div>
      <div
        className="d-flex justify-content-center align-items-center mt-2 resume-sub-headings"
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "darkgreen",
        }}
      ></div>
      <div
        className="d-flex justify-content-center align-items-center mt-3 resume-sub-headings"
        style={{
          fontSize: `${fontSize + 2}px`,
          color: "#808080",
          fontWeight: "bold",
        }}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MySkillsSummary"
          ) || {}
        ).mvalue || "nf MySkills Summary"}
      </div>
      <div
        className="d-flex justify-content-center align-items-center mt-2 resume-sub-headings"
        style={{
          fontSize: `${fontSize + 1}px`,
          color: "#808080",
          fontWeight: "bold",
        }}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "Top5Skills"
          ) || {}
        ).mvalue || "nf Top 5 Skills"}
      </div>
      <div className="table-responsive mt-2 resume-sub-headings">
        <table className="table table-sm table-bordered w-100 resume-report-table">
          <thead>
            <tr>
              <th>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Rank"
                  ) || {}
                ).mvalue || "nf Rank"}
              </th>
              <th>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "SkillLabel"
                  ) || {}
                ).mvalue || "nf Skill"}
              </th>
              <th>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "AppliedExperience"
                  ) || {}
                ).mvalue || "nf Applied Experience"}
                <sup>1</sup>
              </th>
              <th>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "AcquiredExperience"
                  ) || {}
                ).mvalue || "nf Acquired Experience"}
                <sup>2</sup>
              </th>
            </tr>
          </thead>
          {/* TABLE BODY */}
          <tbody>
            {top5Skills.map((skills) => (
              <tr key={skills?.id}>
                <td style={{ textAlign: "center" }}>{skills?.userRank}</td>
                <td>{skills?.skill}</td>
                <td>
                  {skills?.skillAppliedExp
                    ? formatExperience(skills?.skillAppliedExp)
                    : ""}{" "}
                  {SkillsApplied?.data
                    .filter(
                      (value) =>
                        value?.title === skills?.skillOccupation &&
                        value?.mlanguage === skills?.mlanguage
                    )
                    .map((finalVal, index) => (
                      <React.Fragment key={index}>
                        {finalVal?.validation === "Yes" ? "✔" : ""}
                      </React.Fragment>
                    ))}
                </td>
                <td>
                  {skills?.skillAcquiredExp
                    ? formatExperience(skills?.skillAcquiredExp)
                    : ""}{" "}
                  {SkillsAcquired?.filter(
                    (value) =>
                      value?.title === skills?.skillOccupation &&
                      value?.mlanguage === skills?.mlanguage
                  ).map((finalVal, index) => (
                    <React.Fragment key={index}>
                      {finalVal?.validation === "Yes" ? "✔" : ""}
                    </React.Fragment>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* OTHER SKILLS */}
      {remainingSkills.length > 0 && (
        <React.Fragment>
          <div
            className="d-flex justify-content-center align-items-center mt-2 resume-sub-headings"
            style={{
              fontSize: `${fontSize + 2}px`,
              color: "#808080",
              fontWeight: "bold",
            }}
          >
            My Other Skills
          </div>
          <div className="d-flex mt-1" style={{ flexWrap: "wrap" }}>
            {remainingSkills.map((skills) => (
              <div
                key={skills?.id}
                className="m-2 p-2 rounded-1 print-skill"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.125)",
                  color: "#181818",
                }}
              >
                {skills?.skill
                  ? skills.skill
                  : skills?.skillOccupation &&
                    skills?.skillOccupation.split("| |")[0].trim()}
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ResumeBodyResportCard;
