import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const GetAllLangApi = async () => {
 
  return axios
    .get(
      `${BASE_URL}/skill/api/v1/content/getLanguages`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default GetAllLangApi;
