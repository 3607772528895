import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { showWarningToast } from '../ToastNotification/showWarningToast';
import SkillSuggestionApi from '../../api/skillOwner/mySkill/SkillSuggestionApi';
import Loader from '../Loader';
import { GoPlusCircle } from 'react-icons/go';
import SecondaryBtnLoader from '../Buttons/SecondaryBtnLoader';
import { useDispatch } from 'react-redux';
import { setMyReqSkills } from '../../reducer/SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice';

const OccupationModal = () => {

    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);



    //Occupation Title
    const [occuTitle, setOccuTitle] = useState("");
    // occu sugge results
    const [occuSuggestions, setOccuSuggestions] = useState([]);
    const [occOpen, setOccOpen] = useState(false);
    //Occupation skills list left bar
    const [skillListInOccupation, setskillListInOccupation] = useState([]);

    //occupation skills list right bar
    const [occupationRightBox, setOccupationRightBox] = useState([]);
    const [suggestionLoader, setSuggestionLoader] = useState(false);

    const [isFocused, setIsFocused] = useState(false);
    const [exportOptions, setExportOptions] = useState(false);

    const [SkillSuggestions, setSkillSuggestions] = useState(false);
    //API store
    const [occuApiData, setOccuApiData] = useState([]);
    //occu loader
    const [occuApiLoading, setOccuApiLoading] = useState("none");
    const [showOccBox, setShowOccBox] = useState(false);



    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    // auto suggestion for  occupations
    const handleChangeOccu = (e) => {
        const val = e.target.value;
        setOccuTitle(val);
        // if value greater than 2 then query the database and get the suggestions
        if (val.length > 2) {
            setOccuSuggestions([]);
            SkillSuggestionApi(val, selectedLanguage, "occupation").then((res) => {
                //console.log(res.data);
                // check res data length if empty pass No suggestions found
                if (res.data.length === 0) {
                    setOccuSuggestions([{ occupation: "No suggestions found" }]);
                } else {
                    setOccuSuggestions(res.data.filter((tag, index, array) => array.findIndex(t => t.occupation === tag.occupation) === index));
                }
            });
        } else {
            setOccuSuggestions([]);
        }
    }

    //Add occupation btn

    const plusBtnOccupation = async () => {
        setOccuApiLoading("loading");
        setOccOpen(true);

        if (occuTitle.length !== 0) {
            setShowOccBox(true);
            const data = await SkillSuggestionApi(occuTitle, selectedLanguage, "occupation").then((res) => {
                //console.log(res.data);
                setOccuApiLoading("success");
                // check res data length if empty pass No suggestions found
                return res.data;
            });
            const uniqueData = Array.from(new Set(data.map(item => item.skillOccupation)))
                .map(skillOccupation => {
                    return data.find(item => item.skillOccupation === skillOccupation);
                });
            uniqueData.sort((a, b) => {
                if (a.skillOccupation < b.skillOccupation) {
                    return -1;
                }
                if (a.skillOccupation > b.skillOccupation) {
                    return 1;
                }
                return 0;
            });
            setOccuApiData(uniqueData);





        } else {
            showWarningToast("No occupation is selected");
        }

    }


    //Populate Skills based On Occupation

    const occuSuggestionClickHandle = (suggestion) => {
        setOccuSuggestions([]);
        setOccuTitle(suggestion.occupation);
        console.log("sug", suggestion);

    }

    useEffect(() => {
        setskillListInOccupation(occuApiData);
    }, [occuApiData])

    //search occupation skill list filtering 
    const occSkillFilter = (event) => {
        const value = event.target.value;
        //console.log("search value ", value);

        if (value !== "" && value !== "  ") {

            const filtered = occuApiData.filter((prev) => prev.skillOccupation.toLowerCase().includes(value.toLowerCase()));
            setskillListInOccupation(filtered);
        }
        else {
            setskillListInOccupation(occuApiData);
        }

    }

    //Left box add btn handler
    const handleAddSkillOccu = (e) => {

        // Find the skill object with the provided id
        let obj = skillListInOccupation.find((skill) => skill.id === e.target.id);


        // Check if obj is not undefined before proceeding
        if (obj) {
            const name = obj.skillOccupation;
            obj = {
                ...obj, experience: selectedValue,
                required: false,
                validated: false,
                edit: false,
                mandatory: false,
                show: true,
                label: name,
            }
            // Update the left box by removing the skill with the specified id
            setskillListInOccupation((prevList) => prevList.filter((skill) => skill.id !== e.target.id));

            // Update the right box by adding the skill to it
            setOccupationRightBox((prevRightBox) => {
                // Ensure that the state is updated with the latest values
                const updatedList = [...prevRightBox, obj];
                return updatedList;
            });
        } else {
            console.error("Skill not found with id:", e.target.id);
            // Optionally, you can add error handling or notify the user about the issue.
        }
    };

    //right box
    const handleRemoveSkillOccu = (e) => {

        // remove from list of skills
        const obj = occupationRightBox?.find((skill) => skill.id === e.target.id);
        if (obj) {
            // Update the left box by removing the skill with the specified id
            setOccupationRightBox((prevList) => prevList.filter((skill) => skill.id !== e.target.id));

            // Update the right box by adding the skill to it
            setskillListInOccupation((prevRightBox) => [...prevRightBox, obj]);
        } else {
            console.error("Skill not found with id:", e.target.id);
            // Optionally, you can add error handling or notify the user about the issue.
        }
    }
    const dispatch = useDispatch();

    // save occupation

    const handleSaveOccu = () => {
        setOccOpen(false);
        dispatch(setMyReqSkills(occupationRightBox));
        setOccuSuggestions([]);
        setOccuTitle([]);
        setOccupationRightBox([]);
        setShowOccBox(false);
        setskillListInOccupation([]);
        setOccuApiData([]);
    }

    const selectedValue = '0'; // '0' is the default value


    const handleClickCancel = () => {
        setOccOpen(false);
        setOccuSuggestions([]);
        setOccuTitle([]);
        setOccupationRightBox([]);
        setShowOccBox(false);
        setskillListInOccupation([]);
        setOccuApiData([]);
    }

    return (
        <div>
            <div class="modal fade  " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog   modal-lg">
                    <div class="modal-content " >
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillsBasedOcc') || {}).mvalue || "nf Add skills based on occupation"}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            {/* search bar for occupation */}
                            <div className='d-flex  justify-content-center align-items-center  '>
                                <div className="input-group    " style={{ height: "50px", width: "50%" }}>

                                    <input type="text" placeholder={isFocused ? '' : 'Enter an occupation   '} onFocus={handleFocus} onBlur={handleBlur} className="form-control h-75  bg-body-tertiary font-5"
                                        onChange={(e) => handleChangeOccu(e)} value={occuTitle} />
                                    <button className="input-group-text h-75   me-1   "
                                        onClick={plusBtnOccupation}
                                        style={{
                                            backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#655",
                                            color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000",
                                            borderStyle: "solid", borderColor: ""
                                        }}
                                    >
                                        +
                                    </button>

                                </div>
                                {/* suggestion list */}
                                {occuSuggestions.length > 0 && (
                                    <div className='dropdown-menu table-responsive d-flex font-5 my-0 py-0 mx-2'
                                        style={!occOpen
                                            ? {
                                                maxHeight: "130px",
                                                maxWidth: "44%",
                                                width: "44%",
                                                position: "absolute",
                                                top: "60%",
                                                left: "25%"

                                            } : {
                                                maxHeight: "130px",
                                                maxWidth: "44%",
                                                width: "44%",
                                                position: "absolute",
                                                top: "9%",
                                                left: "25%"

                                            }}>
                                        <table className='table table-sm d-flex table-hover px-0 mx-1 py-0 ' style={{ width: "100%" }}>
                                            <tbody className='font-5 bg-danger ' style={{ width: "300%" }}>
                                                {occuSuggestions.map((suggestion, index) => (
                                                    <tr key={index} className='bg-black ' style={{ width: "300%" }} onClick={() => occuSuggestionClickHandle(suggestion)}>
                                                        <td className=' ' style={{ width: "400px" }}>
                                                            <div >{suggestion.occupation}</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </div>





                            {/*main body*/}
                            {showOccBox && <div className='d-flex justify-content-around   ' style={{ height: "68vh" }}>

                                {/* left box */}
                                <div style={{ border: "solid 3px #577126", borderRadius: "10px", width: "47%", position: "relative" }}>
                                    <div style={{ backgroundColor: "#577126", color: "white", height: "28px" }}
                                        className='d-flex justify-content-center w-auto  '> {(content[selectedLanguage].find(item => item.elementLabel === 'SelectTheSkills') || {}).mvalue || "nf Select the skills"}
                                    </div>

                                    <div className='px-2 py-1 ' style={{ border: "solid 1px", borderTop: "none", borderLeft: "none", borderRight: "none" }}>
                                        <input placeholder='Search for skills' onChange={(event) => occSkillFilter(event)} className='w-100 mb-1' />
                                    </div>
                                    {/* Listing of Skills */}
                                    <div className='overflow-x-hidden overflow-y-auto ' style={{ height: "84%" }}>
                                        {!occuApiLoading === "loading" && <div style={{ maxHeight: "120px" }}><Loader /></div>}
                                        {occuApiLoading === "success" && skillListInOccupation.length > 0 &&
                                            <div className='mx-0 mb-1 d-flex flex-column' >
                                                {skillListInOccupation.length > 0 && skillListInOccupation.map((skill, index) =>
                                                    <div id={skill.id} onClick={handleAddSkillOccu}>
                                                        <div
                                                            className='  mx-1 d-flex align-items-center justify-content-between'
                                                            style={{
                                                                borderStyle: "solid", borderWidth: "1px",
                                                                color: "black", padding: "3px 6px",
                                                                fontSize: "15px", borderTop: "none",
                                                                borderLeft: "none", borderRight: "none",
                                                            }}
                                                            id={skill.id}
                                                        >
                                                            <div className=' me-2 ' style={{ maxWidth: "300px", minWidth: "300px" }} id={skill.id} >{skill.skillOccupation}</div>
                                                            <GoPlusCircle style={{ color: "" }} id={skill.id} />
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        }
                                        {occuApiLoading === "success" && skillListInOccupation.length === 0 && <div className='d-flex justify-content-center '>
                                            {(content[selectedLanguage].find(item => item.elementLabel === 'NoSkillsAvailable') || {}).mvalue || "nf NoSkillsAvailable"}
                                        </div>}

                                    </div>
                                </div>



                                {/* right box */}

                                <div style={{ border: "solid 3px #577126", borderRadius: "10px", width: "47%", position: "relative" }}>
                                    <div style={{ backgroundColor: "#577126", color: "white", height: "28px" }}
                                        className='d-flex justify-content-center w-auto  '> {(content[selectedLanguage].find(item => item.elementLabel === 'SelectedSkills') || {}).mvalue || "nf SelectedSkills"}</div>

                                    <div className='overflow-x-hidden overflow-y-auto ' style={{ height: "91%" }}>
                                        {occupationRightBox.length > 0 && occupationRightBox.map((skill, index) => (
                                            skill !== undefined && (

                                                <div onClick={handleRemoveSkillOccu} id={skill.id} >

                                                    <div className=' mx-1 d-flex align-items-center justify-content-between' style={{
                                                        borderStyle: "solid", borderWidth: "1px",
                                                        color: "black", padding: "3px 6px",
                                                        fontSize: "15px", borderTop: "none",
                                                        borderLeft: "none", borderRight: "none",
                                                    }} id={skill.id}
                                                    >
                                                        <div className='me-2 ' id={skill.id} >{skill.skillOccupation}</div>
                                                        <div className='align-items-baseline btn-close' id={skill.id} ></div>
                                                    </div>
                                                </div>

                                            )
                                        ))}
                                    </div>
                                </div>

                            </div>}


                        </div>
                        <div class="modal-footer">
    
                            <SecondaryBtnLoader onClick={handleClickCancel} label={(content[selectedLanguage].find(item => item.elementLabel === 'Cancel') || {}).mvalue || "nf Cancel"} />
                            <SecondaryBtnLoader onClick={handleSaveOccu} Active={true} label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "nf Save"} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OccupationModal