import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { setMyReqLocations } from '../../reducer/SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import LocationCitySuggestionApi from '../../api/locationApi/LocationCitySuggestionApi';
import LocationSuggestionApi from './../../api/locationApi/LocationSuggestionApi';
import { setMyRefinedLocations } from '../../reducer/SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice';

const CreateSelectInRequirements = ({ setLocationDatas }) => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(state => state.language);
  const content = useSelector(state => state.content);
  const [inputValue, setInputValue] = useState("");
  const [filterLocation, setFilterLocation] = useState([]);
  const RefMyRequirements = useSelector(state => state.RefMyRequirements);
  useEffect(() => {
    if (inputValue && inputValue.length < 3) setFilterLocation([]);
    if (inputValue && inputValue.length >= 3) {
      console.log("inp ", inputValue);
      LocationCitySuggestionApi(inputValue, "city").then(res => {
        const data = res.data;

        //eslint-disable-next-line
        setFilterLocation(
          data.map(item => ({
            value: item.city,
            label: item.city,
            latitude: item.cityLatitude,
            longitude: item.cityLongitude
          })))

      }).catch(err => {
        console.log(err);
      })
    }

  }, [inputValue])

  useEffect(() => {
    console.log("filt ", filterLocation);

  }, [filterLocation])

  return (
    <div className='row' >
      {/* location */}
      <div className='col ps-0 mx-4' >
        <div style={{ width: '100%' }}>
          <Select
            isMulti
            name="location"
            placeholder={(content[selectedLanguage].find(item => item.elementLabel === 'AnyLocation') || {}).mvalue || "nf Any Location"}
            options={filterLocation}
            className="basic-multi-select"
            classNamePrefix="select"
            inputValue={inputValue}
            onChange={(newValue) => dispatch(setMyRefinedLocations(newValue))}
            onInputChange={(newValue) => setInputValue(newValue)}
            value={RefMyRequirements?.locationsInRefined}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateSelectInRequirements;