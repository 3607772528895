import React from 'react'
import { useSelector } from 'react-redux';

const DialogButton = ({ Active = false, onClick = false, }) => {
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);
    return (
        <>
            {
                Active ?
                    <button className='btn' style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", color: 'white' }} onClick={onClick}>{(content[selectedLanguage].find(item => item.elementLabel === 'Next') || {}).mvalue || "nf Next"}</button>
                    :
                    <button className='btn' style={{ border: `2px solid ${(content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000"}`, color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", opacity: onClick ? "1" : '.5' }} onClick={onClick}  >{(content[selectedLanguage].find(item => item.elementLabel === 'Back') || {}).mvalue || "nf Back"}</button>
            }

        </>
    )
}

export default DialogButton
