import React from "react";
import ShowHideIcon from "../../ShowHideIcon";
import { MdDelete, MdEdit } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { useSelector } from "react-redux";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { useDispatch } from "react-redux";
import AttachmentForm from "../../AttachmentForm";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import Anonimization from "../../Anonimization";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import EditLanguageAndOthers from "../Forms/Edit Forms/EditLanguageAndOthers";
const OthersSummary = ({ data, yoeCalc, editEnable }) => {
  const dispatch = useDispatch();

  /* HANDLE EDIT FOR OTHERS DETAIL */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };

  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  return (
    <>
      <AttachmentForm />
      <DeleteFormDetailedProfile />

      {/* OTHERS DETAILED PROFILE EDIT MODAL */}
      {<EditLanguageAndOthers />}

      {/* table start */}
      <div className="table-responsive ">
        <table className="table table-sm   table-fixed table-hover    ">
          <thead>
            <tr className="border-dark-subtle border-bottom ">
              <th scope="col" className="bg-body- " style={{ width: "52%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Others"
                  ) || {}
                ).mvalue || "nf Others"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "19%" }}>
                {" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                {" "}
              </th>
              <th scope="col" style={{ width: "12%" }}>
                {" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "10%" }}>
                {" "}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data?.data?.map((others, index) => (
              <tr className="">
                <td
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "1px",
                  }}
                >
                  {others.title
                    ? others.title.length > 17
                      ? others.title.substring(0, 17) + "..."
                      : others.title
                    : ""}
                </td>
                <td></td>
                <td></td>
                <td></td>

                <td
                  className="d-flex justify-content-between   "
                  style={{ minHeight: "26px" }}
                >
                  <div className=""></div>
                  <button
                    className="border-0 bg-white"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Attachment"
                    onClick={() => handleAttachment(others)}
                    data-bs-toggle="modal"
                    data-bs-target="#attachmentList"
                  >
                    <ImAttachment />
                  </button>

                  {editEnable && (
                    <>
                      <div className="">
                        <button
                          className="border-0 bg-white"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Edit"
                          data-bs-toggle="modal"
                          data-bs-target="#EditDetailedProfileLanguageAndOthers"
                          onClick={() => handleEdit(others)}
                        >
                          <MdEdit />
                        </button>
                      </div>
                      <ShowHideIcon
                        value={others.recordHide === "Yes" ? true : false}
                        toggleable={true}
                        form={others}
                        fieldName={"recordHide"}
                        api={"Skills Applied"}
                        id={others.id}
                      />
                      <Anonimization
                        value={others.recordAnonymous === "Yes" ? true : false}
                        toggleable={true}
                        form={others}
                        fieldName={"recordAnonymous"}
                        api={"Skills Applied"}
                        id={others.id}
                      />
                      <button
                        className="border-0 bg-transparent"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Delete"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteformdetailedprofile"
                        onClick={() => {
                          dispatch(setDeleteDetailedProfileData(others));
                        }}
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* table end */}
    </>
  );
};

export default OthersSummary;
