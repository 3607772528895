import { createSlice } from "@reduxjs/toolkit";
import { fetchOthersHistory } from "../../api/fetchAllData/fetchOtherHistory";


const initialState = {
  data: [ ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const otherSlice = createSlice({
  name: "otherHistory",
  initialState,
  reducers: {
    setOther: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    emptyOther: (state) => {
      state.data = [];
      state.status = "idle";
      state.error = null;
    },
    addNewOther: (state, action) => {
      state.data = [action.payload,...state.data ];
      state.status = "success";
      state.error = null;
    },
    OtherDelete: (state, action) => {
      state.data = state.data.filter((skill) => skill.id !== action.payload);
    },
    logoutOther: ()=> initialState,
    othersUpdateRecord: (state, action) => {
      state.data = state.data.map((skill) => {
        if (skill.id === action.payload.id) {

          return {
            ...skill,
            ...action.payload,
          };
        }

        return skill;
      });
      console.log(state.data);
      state.status = "success";
      state.error = null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOthersHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOthersHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchOthersHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const {othersUpdateRecord, setOther, emptyOther,addNewOther,OtherDelete,logoutOther} =otherSlice.actions;
export default otherSlice.reducer;
