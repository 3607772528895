import React from "react";
import { useSelector } from "react-redux";

const Pagination = ({ currentPage, totalPage, onPageChange }) => {
  const { language: selectedLanguage, content } = useSelector((state) => state);

  const getPages = () => {
    if (totalPage <= 5) {
      return Array.from({ length: totalPage }, (_, i) => i + 1);
    }

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPage, currentPage + 2);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pages = getPages();

  return (
    <div className="pagination-container">
      <button
        className="btn btn-outline-success mx-1"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
      >
        {"<<"}
      </button>
      <button
        className="btn btn-outline-success mx-1"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "Back"
          ) || {}
        ).mvalue || "nf Back"}
      </button>
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`btn btn-outline-success mx-1 ${
            currentPage === page ? "active" : ""
          }`}
        >
          {page}
        </button>
      ))}
      <button
        className="btn btn-outline-success mx-1"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPage}
      >
        {(
          content[selectedLanguage].find(
            (item) => item.elementLabel === "Next"
          ) || {}
        ).mvalue || "nf Next"}
      </button>
      <button
        className="btn btn-outline-success mx-1"
        onClick={() => onPageChange(totalPage)}
        disabled={currentPage === totalPage}
      >
        {">>"}
      </button>
    </div>
  );
};

export default Pagination;

