export function formatTimestampToDate(timestamp, dateFormat = 'mm/dd/yyyy') {

  const dateFormatLc = localStorage.getItem('dateFormat') || dateFormat;
  const date = Number(timestamp) ? new Date(Number(timestamp)) : new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-based
  const year = date.getFullYear().toString().padStart(4, '0'); // Ensure year has 4 digits

  // Replace format tokens with corresponding date parts
  const formattedDate = dateFormatLc
    .replace('dd', day)
    .replace('mm', month)
    .replace('yyyy', year); // Use 'yyyy' for 4-digit year representation

  return formattedDate;
}

