import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { changeCurrency } from "../reducer/SkillingAgency/features/course/currency/myCurrencySlice";
import { setRegCountry } from "../reducer/localization/CountryRegionalSlice";

const Playground = ({ setCountry, setShowDropDown, flagSearch, setFlagSearch }) => {
    //api regional data store
    const regionalData = useSelector(state => state.regionalData);
    
    const currency = useSelector(state => state.currency.value)
    const dispatch = useDispatch()
    
    // source https://api.first.org/data/v1/countries

    const [filter, setFilter] = useState();
    useEffect(() => {
      setFilter(regionalData?.listOfCountries);
    }, [regionalData?.listOfCountries]);

    

    useEffect(() => {
        if (flagSearch) {
            try {
                console.log(regionalData?.listOfCountries?.filter((c) => c.country === flagSearch));
                
                const data = regionalData?.listOfCountries && regionalData?.listOfCountries?.filter((c) =>c.country&& c.country.toLowerCase().includes(flagSearch.toLowerCase()));
               
                setFilter(data);

            } catch (error) {
                console.error("Error occurred:", error);
            }
        }

    }, [flagSearch]);


    const handleClick = (country) => {
        const countryName = country.country;
        const countryCode = country.countryCode;
        setCountry({ countryCode, countryName })
        //dispatch(changeCurrency(countryName));
        console.log(country, 'Console for country');
        dispatch(setRegCountry(country));
        setShowDropDown(false);
        setFlagSearch("");

        // localStorage.setItem("countryCode", countryName)
        // localStorage.setItem("countryName", countryName)
    };

    /* HANDLE */


    return (
        < >
            {filter &&
                filter.map((country,index ) => {
                    return (
                        <tr key={index} onClick={() => handleClick(country)} >
                            <td className=' '>
                                <div className="d-flex flex-row" style={{ alignItems: "center", width: "130px", fontSize: "13px" }}>                                
                                    <div className="" style={{ marginLeft: "5px" }}>
                                        {country.country} ({country?.dialCode})
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export default Playground