import { createSlice } from "@reduxjs/toolkit";

//const initialState = [{ skill: "", experience: "", required: true, validated: true, edit: false, mandatory: false, show: true }];


const RefMyRequirementsSkillSlice = createSlice({
    name: "RefMyRequirements",
    initialState: {
        skillsInRefined: [],
        locationsInRefined: [],
        view: "card",
    }
    ,
    reducers: {
        setMyRefinedSkills: (state, action) => {
            state.skillsInRefined = action.payload;
            console.log("refine skils ", action.payload);

        }, setMyRefinedLocations: (state, action) => {
            state.locationsInRefined = action.payload;

        },
        setView:(state,action) => {
            console.log("view changed to ",action.payload);
            state.view = action.payload;
        },
        emptyMyRefinedSkills: (state) => {
            return [{
                skillsInRefined: [],
                locationsInRefined: []

            }];
        }
    }
})

export const { setMyRefinedSkills, setMyRefinedLocations, emptyMyRefinedSkills,setView } = RefMyRequirementsSkillSlice.actions;
export default RefMyRequirementsSkillSlice.reducer;