import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const RightSideBar = () => {

    // store imports
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);

    const location = useLocation().pathname;

    return (
        <>
            {content.status === "succeeded" &&<>
                <table className='table table-sm table-hover    ' >
                    <thead>
                        <tr>
                            <div className="mt-2  rounded-top  " style={{ backgroundColor:(content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", height: "36px" }}>
                                <div className='text h6 text-center   ' style={{ padding: "6px 0", color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000" }} >{(content[selectedLanguage].find(item => item.elementLabel === 'Trending')||{}).mvalue||"nf Trending"}</div>
                            </div>
                        </tr>
                    </thead>

                    <div className='mt-1 '>

                    </div>

                    <tbody >
                        <tr>
                            <td>Brand Management</td>
                        </tr>
                        <tr>
                            <td>Sales Development</td>
                        </tr>
                        <tr>
                            <td>Solution Selling</td>
                        </tr>
                        <tr>
                            <td>Sales Training</td>
                        </tr>
                        <tr>
                            <td>Google Ads </td>
                        </tr>
                    </tbody>

                    <div className="  " style={{ height: location==="/dashboard/skillprofile"?"40px":"110px" }}>
                    </div>
                </table>


                <table className='table  table-sm table-hover    '>
                    <thead> 
                        <tr>
                            <div className="mt-5     rounded-top " style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel ==='NavBarBgColor')||{}).mvalue||"#000", height: "36px" }}>
                                <div className='text h6 text-center   ' style={{ padding: "6px 0", color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor')||{}).mvalue|| "#Ffff" }} >{(content[selectedLanguage].find(item => item.elementLabel === 'ActiveRecruiters')||{}).mvalue||"nf Active Recruiters"}</div>
                            </div>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Amazon</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                        </tr>
                        <tr>
                            <td>Microsoft</td>
                        </tr>

                    </tbody>
                </table>
            </>}
        </>
    )
}

export default RightSideBar