import React, { useState } from "react";
import { BiSolidInfoCircle } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { SiHiveBlockchain } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import Anonimization from "../../Anonimization";
import AttachmentForm from "../../AttachmentForm";
import ShowHideIcon from "../../ShowHideIcon";
import Validation from "../../ValidationForm";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import SkillsAppiliedEdit from "../Forms/Edit Forms/SkillsAppiliedEdit";
import { setSkillsAppliedEditData } from "../../../reducer/edit/skillsAppliedEditSlice";

const SkillAppliedDetailed = ({ data, editEnable }) => {

  const [projectSelected, setProjectSelected] = useState(null)

  const content = useSelector((state) => state.content);
  const regionalData = useSelector((state) => state.regionalData);
  const selectedLanguage = useSelector((state) => state.language);
  const SkillSelected = useSelector(
    (state) => state.SkillSelected.skillOccupation
  );

  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  const handleValidate = (selectedData) => {
    dispatch(setValidationData(selectedData));
  };

  /* HANDLE EDIT */
  const handleEdit = (data) => {
    dispatch(setSkillsAppliedEditData(data));
  }

  return (
    <>
      {/* VALIDATION MODAL */}
      {<Validation />}
      {<AttachmentForm />}
      {<DeleteFormDetailedProfile />}
      {<SkillsAppiliedEdit />}
      <div className="table-responsive  ">
        <div className="d-none d-lg-block">
          <table className="table table-sm  table-borderless  my-2     table-fixed     ">
            <tr className="   ">
              <th scope="col" className="bg-body- " style={{ width: "28%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectTitle"
                  ) || {}
                ).mvalue || "nf Project Title"}{" "}
                <span
                  className="ms-1 mb-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "ProjectDescription"
                      ) || {}
                    ).mvalue || "nf ProjectDescription"
                  }
                >
                  <BiSolidInfoCircle
                    className="text-muted"
                    style={{ fontSize: "20px" }}
                  />
                </span>
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Duration"
                  ) || {}
                ).mvalue || "nf Duration"}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "21%" }}>
                {" "}
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectType"
                  ) || {}
                ).mvalue || "nf projecttype"}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Location"
                  ) || {}
                ).mvalue || "nf Location"}
              </th>
              <th scope="col" className="" style={{ width: "10%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Validation"
                  ) || {}
                ).mvalue || "nf Validation"}
              </th>
              <th
                scope="col"
                className="bg-body- "
                style={{ width: "12%" }}
              ></th>
            </tr>

            <div className="mt-1 "></div>

            {/* horizontal line */}
            <tr style={{ borderColor: "gray", borderWidth: "1px" }} />
          </table>
        </div>

        {data.data.map((skill, index) => (
          <>
            {skill.mlanguage === selectedLanguage &&
              SkillSelected === skill.title && (
                <>
                  <div className="   " key={index}>
                    <div className=" ">
                      {/* for mobile screens */}
                      <div className="d-lg-none ">
                        <table className="table table-borderless m-0  ">
                          <tr className=" ">
                            <td>
                              <b>Project/Activity</b>{" "}
                            </td>
                            <td>:{skill.projectActivity}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Duration</b>{" "}
                            </td>
                            <td>
                              :
                              {DayDifferenceToDynamicView(
                                calculateDaysDifference(
                                  skill.fromDate,
                                  skill.toDate
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Type</b>{" "}
                            </td>
                            <td>:{skill.mtype}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Location</b>{" "}
                            </td>
                            <td>
                              :
                              {skill.location
                                ? skill.location.length > 17
                                  ? skill.location.substring(0, 17) + "..."
                                  : skill.location
                                : ""}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Validation</b>{" "}
                            </td>
                            <td>:{skill.validation} </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Action</b>{" "}
                            </td>
                            <td>
                              <div className="   d-flex justify-content-between   ">
                                <div className="d-flex flex-column justify-content-center ">
                                  <button
                                    className="border-0 bg-white"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Attachment"
                                    onClick={() => handleAttachment(skill)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#attachmentList"
                                  >
                                    <ImAttachment />
                                  </button>
                                </div>
                                {editEnable && (
                                  <>
                                    <div className="d-flex flex-column justify-content-center ">
                                      <button
                                        className="border-0  bg-white "
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Edit"
                                      >
                                        <MdEdit />
                                      </button>
                                    </div>

                                    <button
                                      className="border-0 bg-white"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Trash"
                                    >
                                      <MdDelete />
                                    </button>
                                    {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Show/Hide" ><ShowHideIcon value={skill.showHide} /></button> */}
                                    <ShowHideIcon />
                                    <Anonimization
                                      value={
                                        skill.recordHide === "yes"
                                          ? false
                                          : true
                                      }
                                      toggleable={true}
                                      form={skill}
                                      fieldName={"recordHide"}
                                      api={"Skills Acquired"}
                                      id={skill.id}
                                    />
                                    <button
                                      className="border-0 bg-white"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Add on Blockchain"
                                      style={{
                                        visibility:
                                          skill.validation === "Yes"
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      <SiHiveBlockchain />
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      {/*for lap   */}
                      <div className="d-lg-flex d-none   ">
                        <div className="   " style={{ width: "29%" }}>
                          {skill.projectActivity
                            ? skill.projectActivity.length > 17
                              ? skill.projectActivity.substring(0, 17) + "..."
                              : skill.projectActivity
                            : skill.organization &&
                              skill.organization.length > 17
                              ? skill.organization.substring(0, 17) + "..."
                              : skill.organization}
                        </div>
                        <div style={{ width: "13%" }}>
                          {DayDifferenceToDynamicView(skill.duration)}
                        </div>
                        <div className="" style={{ width: "23%" }}>
                          {skill.mtype === "Own"
                            ? (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "ProjectOwn"
                              ) || {}
                            ).mvalue || "nf Project(O)"
                            : skill.mtype === "Employment" &&
                              skill.projectActivity
                              ? (
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectEmployment"
                                ) || {}
                              ).mvalue || "nf Project(E)"
                              : (
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel ===
                                    "ProfessionalExperience"
                                ) || {}
                              ).mvalue || "nf Professional Experience"}
                        </div>
                        <div
                          className=""
                          style={{ width: "15%" }}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            skill.location ? skill.location : ""
                          }
                        >
                          {skill.location
                            ? skill.location.length > 12
                              ? skill.location.substring(0, 12) + "..."
                              : skill.location
                            : ""}
                        </div>
                        {/* <div className='    ' style={{ width: "14%" }} data-tooltip-id="my-tooltip" data-tooltip-content={skill.location}>{skill.location.length > 12 ? skill.location.substring(0, 12) + "..." : skill.location}</div> */}
                        <div className="  " style={{ width: "10%" }}>
                          {skill.validation}{" "}
                        </div>
                        <div
                          className={`d-flex ${editEnable ? "justify-content-between" : "justify-content-evenly"} align-items-baseline`}
                          style={{ width: "12%" }}
                        >
                          {editEnable ? (
                            <>
                              <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                onClick={() => handleEdit(skill)}
                              ><MdEdit /></button>

                              <ShowHideIcon
                                value={
                                  skill.recordHide === "Yes" ? true : false
                                }
                                toggleable={true}
                                form={skill}
                                fieldName={"recordHide"}
                                api={"Skills Acquired"}
                                id={skill.id}
                              />
                              <Anonimization
                                value={
                                  skill.recordAnonymous === "Yes" ? true : false
                                }
                                toggleable={true}
                                form={skill}
                                fieldName={"recordAnonymous"}
                                api={"Skills Acquired"}
                                id={skill.id}
                              />
                              {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Add on Blockchain" style={{ visibility: skill.validation === "Yes" ? "visible" : "hidden" }}><SiHiveBlockchain /></button> */}
                              <button
                                className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                  setProjectSelected(skill)
                                  handleAttachment(skill)
                                  handleValidate(skill)
                                  dispatch(setDeleteDetailedProfileData(skill))

                                }}
                              >
                                <MdDelete />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="border-0 bg-white"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Validate"
                                data-bs-toggle="modal"
                                data-bs-target="#validationForm"
                                onClick={() => handleValidate(skill)}
                              >
                                <img
                                  src={validationIcon}
                                  alt=""
                                  style={{ width: "1rem", height: "1rem" }}
                                />
                              </button>
                              <button
                                className="border-0 bg-white"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Attachment"
                                onClick={() => handleAttachment(skill)}
                                data-bs-toggle="modal"
                                data-bs-target="#attachmentList"
                              >
                                <ImAttachment />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 mt-3   ">
                      <div className="d-lg-flex align-items-center  ">
                        {/* ONLY SHOW ORGANIZATION WHEN MTYPE === EMPLOYMENT */}
                        {skill?.mtype === "Employment" && (
                          <div className="d-flex  " style={{ width: "64%" }}>
                            {" "}
                            <b>
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "Organization"
                                ) || {}
                              ).mvalue || "nf Organization"}
                              :
                            </b>
                            <div>{skill.organization}</div>
                          </div>
                        )}
                        <div
                          className="d-flex  ps-lg-0  mt-1 mt-lg-0 me-2 "
                          style={{ width: "14%" }}
                        >
                          <b>
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "From"
                              ) || {}
                            ).mvalue || "nf From"}
                            &nbsp;:&nbsp;
                          </b>
                          <div>
                            {formatTimestampToDate(
                              skill.fromDate,
                              regionalData.selectedCountry.dateFormat
                            )}
                          </div>
                        </div>
                        <div
                          className="d-flex  ps-lg-0    "
                          style={{ width: "22%" }}
                        >
                          <b>
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "To"
                              ) || {}
                            ).mvalue || "nf To"}
                            &nbsp;:&nbsp;
                          </b>
                          <div>
                            {skill.toDate
                              ? formatTimestampToDate(
                                skill.toDate,
                                regionalData.selectedCountry.dateFormat
                              )
                              : "On-going"}
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 pt-2   ">
                        <b>
                          {(
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "BriefDescription"
                            ) || {}
                          ).mvalue || "nf Brief Description"}
                          :
                        </b>{" "}
                      </div>
                      <div className="ms-4    "> {skill.briefDescriptions}</div>

                      {skill.validation === "Yes" && (
                        <ValidationDataComponent itemId={skill.id} />
                      )}
                    </div>
                  </div>
                  <hr
                    className="border-black px-0 py-0  "
                    style={{ borderColor: "black", borderWidth: "2px" }}
                  />
                </>
              )}
          </>
        ))}
      </div>
    </>
  );
};

export default SkillAppliedDetailed;
