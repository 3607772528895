import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../../components/Navbar'
import DetailedPofileNavbar from '../../../components/DetailedPofileNavbar';
import RightSideBar from '../../../components/RightSideBar';
import ShowHideIcon from '../../../components/ShowHideIcon';
import { MdDelete, MdEdit } from 'react-icons/md';
import { ImAttachment } from 'react-icons/im';
import DetailedResume from '../../../components/DetailedResume';
import Footer from '../../../components/Footer';
import { useSelector } from 'react-redux';
import SecondaryBtn from '../../../components/Buttons/SecondaryBtn';
import AchievementSummary from '../../../components/SkillOwner/DetailedProfile/AchievementSummary';
import AchievementDetail from '../../../components/SkillOwner/DetailedProfile/AchievementDetail';
import CreateSelectForLocation from '../../../components/SkillOwner/SelectComponent/CreateSelectForLocation';
import PremiumServicesOptions from '../../../components/PremiumServicesOptions';
import PrimaryBtn from '../../../components/Buttons/PrimaryBtn';
import PremiumService from '../../../components/SkillOwner/PremiumServices/PremiumService';
import CustomAnalyticsPS from '../../../components/SkillOwner/PremiumServices/CustomAnalyticsPS';
import { timestampToYYYYMMDD } from '../../../components/SkillOwner/HelperFunction/timestampToYYYYMMDD';
import MultiSelect from '../../../components/SkillOwner/SelectComponent/MultiSelect';
import PostApi from '../../../api/PostData/PostApi';
import { FormatDateIntoPost } from '../../../components/SkillOwner/HelperFunction/FormatDateIntoPost';
import { useDispatch } from 'react-redux';
import { addNewAwards } from '../../../reducer/detailedProfile/achievementSlice';
import { convertDateToMilliseconds } from '../../../components/SkillOwner/HelperFunction/convertDateToMilliseconds';
import { showErrorToast } from '../../../components/ToastNotification/showErrorToast';
import { showSuccessToast } from '../../../components/ToastNotification/showSuccessToast';
import { fetchAwardsHistory } from '../../../api/fetchAllData/fetchAwardsHistory';
import SecondaryBtnLoader from '../../../components/Buttons/SecondaryBtnLoader';
import CreateSelectInstitution from '../../../components/SkillOwner/SelectComponent/CreateSelectInstitution';
import organizationSearchSuggestions from '../../../api/searchSuggestionAPIs/organizationSearchSuggestions';
import { debouncedApiRequest } from '../../../components/DebounceHelperFunction/debouncedApiRequest';
import { ThreeDots } from 'react-loader-spinner';
import { exceptionPOSTapi } from '../../../api/PostData/exceptionsPOSTapi';
import DatePicker from 'react-datepicker';
import { formatDateInputType } from '../../../components/SkillOwner/HelperFunction/FormatDateInputType';
const AchivementsHistory = () => {
    const buttonRef = useRef(null)
    const initialState = {
        mtype: "Awards",
        date: "",
        briefDescriptions: "",
        location: "",
        showHide: "No",
        validation: "No",
        blockChain: "",
        mlanguage: localStorage.getItem('HLang'),
        organization: "",
        organizationShowHide: "No",
        userId: localStorage.getItem('userId'),
        title: "",
        AwardName: '',
    }

    /* STORE IMPORTS */
    const {
        regionalData,
    } = useSelector((state) => state);

    const [skillingDetails, setSkillingDetails] = useState(initialState)

    const [isAdddingSkill, setIsAddingSkill] = useState(false)
    //store
    const achievementHistory = useSelector(state => state.achievementHistory);

    const [switchTab, setSwitchTab] = useState('');


    const [editEnable, setEditEnable] = useState(false);
    const [location, setLocation] = useState([]);
    const [online, setOnline] = useState(false);
    //store
    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);

    // Tabs
    const [summaryTab1, setsummaryTab1] = useState(true);
    const [DetailTab1, setDetailTab1] = useState(false);

    const handleEdit = () => {
        setEditEnable(!editEnable);
    }

    /* FOR CUSTOM ORGANIZATION NAME */
    const [isCustomIOrgAwards, setIsCustomOrgAwards] = useState(false);

    const handleOrgAchivementParentToChild = (val) => {
        setIsCustomOrgAwards(val);
    }

    //Years of Expiriece / Duration Calculation 

    function dateDynamicView(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);


        const yearDiff = end.getYear() - start.getYear();
        const monthDiff = end.getMonth() - start.getMonth();


        const totalDays = Math.round((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
        console.log(totalDays);
        if (totalDays <= 13) {
            return totalDays >= 7 ? '1 Wk' : totalDays + ' Days';
        } else if (totalDays <= 31) {
            return totalDays >= 30 ? '1 Mon' : Math.floor(totalDays / 7) + ' Wks';
        } else if (monthDiff > 0) {
            return monthDiff + ' Mos';
        } else {
            return yearDiff === 1 ? "1 Yr" : Math.round((totalDays / 365) * 10) / 10 + " Yrs";
        }


    }



    const handleAccordion1 = (event) => {
        if (summaryTab1 == false && DetailTab1 == false) {
            setsummaryTab1(true);
            console.log("set sum 1");
        }
        else {
            setsummaryTab1(false);
            setDetailTab1(false);
        }
        event.stopPropagation();
        const target = document.getElementById("panelsStayOpen-collapseOne");
        if (target.classList.contains("show")) {
            target.classList.remove("show");
        } else {
            target.classList.add("show");
        }
    }


    const handleDetailsSummary = (event) => {
        event.stopPropagation();
        setsummaryTab1(false);
        setDetailTab1(true);
    }

    const handleSummaryClick = (event) => {
        event.stopPropagation();
        setDetailTab1(false);
        setsummaryTab1(true);
    }

    const toggleOnline = () => {
        setOnline(false);
    }

    const handlePdf = () => {
        window.print();
    }

    const handleValidateProject = () => {
        setValidation(true);
    }

    const handleValidationClose = () => {
        setValidation(false);

    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        } else {
            setFileName('No file chosen');
        }
    };

    // modal validation show hide
    const [Validation, setValidation] = useState(false);
    const actualBtnRef = useRef(null);
    const [fileName, setFileName] = useState('No file chosen');

    const handleModalClose = () => {
        setValidation(false);
    }

    // to adjust the height of the content dynamically
    const navbarRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('100vh');

    useEffect(() => {
        if (navbarRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            setContentHeight(`calc(98vh - ${navbarHeight}px)`);
        }
    }, []);
    useEffect(() => {

        dispatch(fetchAwardsHistory());
    }, [])



    const dispatch = useDispatch();

    const checkDuplicate = () => {

        var duplicate = false;

        achievementHistory?.data?.map((cert) => {

            if (cert.title === skillingDetails.AwardName && cert.organization === skillingDetails.organization) {



                if (cert.date === convertDateToMilliseconds(skillingDetails.date)) {
                    duplicate = true; // Overlap detected
                }


            }

        })

        return duplicate


    }

    const handleSkillingAdd = (close) => {
        console.log(skillingDetails);


        let duplicate = checkDuplicate()
        console.log(duplicate);
        if (duplicate) {
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'AwardAlreadyExistDate') || {}).mvalue || "nf Award already exist with this date")
            return
        }


        setIsAddingSkill(true)

        // console.log({ ...certificationDetails, location: locationForNewCertificate });
        // dispatch(addNewCertification({...certificationDetails,location:locationForNewCertificate}))
        // dispatch(emptyCertification())

        PostApi("Awards",
            {
                ...skillingDetails, location: location, date: FormatDateIntoPost(skillingDetails.date), mlanguage: selectedLanguage, title: skillingDetails.AwardName
            }
        ).then((res) => {

            dispatch(addNewAwards({ ...res.data, date: convertDateToMilliseconds(res.data.date), }))

            /* IF NEW ORGANIZATION NAME ENTERED */
            const data = res?.data;
            if (isCustomIOrgAwards) {
                handleInsertNewOrganization(data?.organization, data?.mlanguage, data?.applicationName, data?.organization, data?.id);
            }

            showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'NewAwardsAddedSuccessful') || {}).mvalue || "nf New Awards Added Successful");

            setSkillingDetails(initialState);
            setLocation("")



            if (close && buttonRef.current) {
                buttonRef.current.click();
            }
            if (!close && buttonRef.current) {
                setSkillingDetails(initialState);
                setLocation("");
            }
            setIsCustomOrgAwards(false);
        }).catch((err) => {
            console.log(err);
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong");
        }).finally(() => {
            setIsAddingSkill(false);
            console.log(achievementHistory);
        })
    }

    /* HANDLE CREATING NEW ORGANIZATION NAME IN MASTER TABLE */
    const handleInsertNewOrganization = async (newOrgName, lang, moduleName, contentName, itemId) => {
        const payload = {
            organization: newOrgName,
            mlanguage: lang,
            mstatus: "W"
        }
        try {
            const res = await exceptionPOSTapi("Organizations", payload);
            console.log("response for inserting new org record", res);
            const data = res?.data;

            handleOrganizationExceptions(data?.applicationName, data?.id, moduleName, contentName, itemId);
        } catch (error) {
            console.error("Error inserting new institution name: ", error);
        }
    }

    /* HANDLE ORG EXCEPTION */
    const handleOrganizationExceptions = async (applicationName, recordId, moduleName, contentName, itemId) => {
        const body = {
            masterTable: applicationName,
            masterTableRecordID: recordId,
            module: moduleName,
            userId: localStorage.getItem("userId"),
            content: contentName,
            itemId: itemId,
            status: "New"
        }
        try {
            await exceptionPOSTapi("Exceptions", body);
        } catch (error) {
            console.error("Error while handling exceptions: ", error);
        }
    }

    //Api institution data
    const [orgApiData, setOrgApiData] = useState([]);
    const [insConvertedToSelect, setInsConvertedToSelect] = useState([]);
    const [insSearch, setInsSearch] = useState("");
    const [eduApiLoader, setEduApiLoader] = useState(false);





    useEffect(() => {
        if (insSearch.length > 1 && insSearch !== " ") {
            setEduApiLoader(true);
            debouncedApiRequest(organizationSearchSuggestions, insSearch, selectedLanguage, setOrgApiData, setEduApiLoader);
        } else {
            setEduApiLoader(false);
            setOrgApiData([])
            setInsConvertedToSelect([])

        }

    }, [insSearch])

    useEffect(() => {
        if (orgApiData.length > 0) {
            const data = orgApiData.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.organization, // Add new field 1 with a default value
                    label: item.organization, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setInsConvertedToSelect(data);
        }

    }, [orgApiData])


















    return (
        <>
            <div className='d-print-none'>

                {/* <!-- Modal --> */}
                <div className="modal fade font-5 m-0 p-0 " style={{ margin: "0" }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='d-flex justify-content-between align-items-center  w-100  '>
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">{(content[selectedLanguage].find(item => item.elementLabel === 'AwardsAccoladesAchievements') || {}).mvalue || "nf Awards/Accolades/Achievements"} </h1>
                                    <i className=' me-2' > <span className='text-danger '>*</span> {(
                                        content[selectedLanguage].find(
                                            (item) => item.elementLabel === "RequiredFields"
                                        ) || {}
                                    ).mvalue || "nf Required Fields"}</i>
                                </div>

                                <button type="button" className="btn-close" onClick={handleModalClose} data-bs-dismiss="modal" aria-label="Close" id='mclose' ref={buttonRef}></button>
                            </div>
                            <div className="modal-body ">
                                {/* form start */}
                                <div className="   " >


                                    {!Validation && <div className=' '>

                                        <div class="mb-2 ">
                                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'AwardsAccoladesAchievements') || {}).mvalue || "nf Awards/Accolades/Achievements"}<span className='text-danger' > *</span></label>
                                            <input type="text" style={{ height: "32px" }} name='AwardName' class="form-control bg-body-tertiary h-75 " id="" placeholder="" value={skillingDetails.AwardName} onChange={(e) => setSkillingDetails({ ...skillingDetails, [e.target.name]: e.target.value })} />
                                        </div>
                                        {/* <div class="mb-2 ">
                                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || " nf org"}<span className='text-danger' > *</span></label>
                                            <input type="text" style={{ height: "32px" }} name='organization' class="form-control bg-body-tertiary h-75 " id="" placeholder="" value={skillingDetails.organization} onChange={(e) => setSkillingDetails({ ...skillingDetails, [e.target.name]: e.target.value })} />
                                        </div> */}

                                        <div class="mb-2 ">
                                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'OrganizationName') || {}).mvalue || "nf Organization Name"}<span className='text-danger' > *</span></label>
                                            {/* <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary h-75 font-5" id="" placeholder="Enter your Organization name" onChange={(e) => setEmployee({ ...employee, organization: e.target.value })} value={employee.organization} /> */}
                                            <CreateSelectInstitution
                                                setInsSearch={setInsSearch}
                                                insSearch={insSearch}
                                                insConvertedToSelect={insConvertedToSelect}
                                                formvalues={skillingDetails}
                                                setFormValues={setSkillingDetails}
                                                showDropdown={false}
                                                formType={'Achievement'}
                                                handleOrgAchivementParentToChild={handleOrgAchivementParentToChild}
                                            />
                                            {eduApiLoader && <div style={{ transform: "translate(365px,-24px)", width: "50px" }}>
                                                <ThreeDots width={"30"} height={"10"} />
                                            </div>}
                                        </div>

                                        <div className='d-flex my-2  w-100   '  >
                                            <div className=" h-75 w-100  " >
                                                <label htmlFor="exampleFormControlInput1" className="form-label ">{(content[selectedLanguage].find(item => item.elementLabel === 'Date') || {}).mvalue || "nf Date"}<span className='text-danger' >*</span></label>
                                                {/* <input type="date" style={{ height: "32px" }} value={skillingDetails.date} max={timestampToYYYYMMDD(Date.now())} name='date' className="form-control bg-body-tertiary h-75 " id="exampleFormControlInput1" onChange={(e) => setSkillingDetails({ ...skillingDetails, [e.target.name]: e.target.value })} /> */}
                                                <DatePicker
                                                    style={{ height: "32px" }}
                                                    maxDate={timestampToYYYYMMDD(Date.now())}
                                                    className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                                    id="exampleFormControlInput1"
                                                    onChange={(e) => setSkillingDetails({ ...skillingDetails, date: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                                    toggleCalendarOnIconClick
                                                    selected={skillingDetails?.date ? skillingDetails?.date : null}
                                                    dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100}
                                                    placeholderText={regionalData?.selectedCountry?.dateFormat}
                                                    onBlur={() => { }}
                                                />
                                            </div>

                                        </div>

                                        <div className="my-2">
                                            <div className='d-flex justify-content-between'>
                                                <label htmlFor="locationInput" className="form-label">
                                                    {(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}<span className='text-danger'> *</span>
                                                </label>
                                                <div className='d-flex align-items-center justify-align-content'>
                                                    <input
                                                        id="onlineCheckbox"
                                                        className='ms-2'
                                                        type="checkbox"
                                                        name="online"
                                                        checked={location.includes('Online')}
                                                        onChange={(e) => {
                                                            setOnline(!online);
                                                        }}
                                                    />
                                                    <label htmlFor="onlineCheckbox" className="ms-1"> {(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</label>
                                                </div>
                                            </div>
                                            {/* <CreateSelectForLocation locationData={location}
                                                setLocation={setLocation}
                                                onlineStatus={toggleOnline} /> */}
                                            <MultiSelect viewLocation={location} setLocationData={setLocation} onlineStatus={online} />
                                        </div>





                                        <div className="my-2 ">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label"> {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "nf ProjectBriefDescription"}</label>
                                            <textarea className="form-control bg-body-tertiary" name='briefDescriptions' id="exampleFormControlTextarea1" rows="2" value={skillingDetails.briefDescription} onChange={(e) => setSkillingDetails({ ...skillingDetails, [e.target.name]: e.target.value })}></textarea>
                                        </div>





                                        {/* <div className="d-flex justify-content-between align-items-baseline   ">
                                            <div>
                                                <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'ValidateThisProject') || {}).mvalue || " nf ValidateThisProject"} onClick={handleValidateProject} backgroundColor="#F8F8E9" color="#815F0B" />
         
                                                <div id="emailHelp" class="form-text">
                                                    <a href="/"> {(content[selectedLanguage].find(item => item.elementLabel === 'RequireValidationSupport') || {}).mvalue || "nf RequireValidationSupport"}</a>
                                                </div>
                                            </div> 
                                            <div>
                                                <div>
                                                    <input type="file" id='fileChoose'  ref={actualBtnRef} hidden onChange={handleFileChange} />
                                                    <label htmlFor="fileChoose" > <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'AttachRelatedDocuments') || {}).mvalue || "nf AttachRelatedDocuments"} backgroundColor="#F8F8E9" color="#815F0B" /> </label>
                                                </div>
                                                <div id="file-chosen" class="form-text">{fileName}</div>
                                            </div>

                                        </div> */}



                                    </div>}


                                    {
                                        Validation === true &&
                                        <div className="ms-2  me-2  border  px-1 py-1  ">
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <h3 className="modal-title fs-5" id="exampleModalLabel">{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidationDetail') || {}).mvalue || "nf ProjectValidationDetail"}</h3>
                                                <button type="button" className="btn-close" onClick={handleValidationClose} aria-label="Close"></button>
                                            </div>
                                            <div className="my-2  "  >
                                                <label htmlFor="exampleFormControlInput1" className="form-label ">{(content[selectedLanguage].find(item => item.elementLabel === 'WhoValidates') || {}).mvalue || "nf WhoValidates"}<span className='text-danger' >*</span></label>
                                                <input type="text" style={{ height: "32px" }} className="form-control bg-body-tertiary h-75 " id="exampleFormControlInput1" />
                                            </div>

                                            <div>
                                                <label htmlFor="" className='form-label mt-2  '>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidatorRelationship') || {}).mvalue || "nf ProjectValidatorRelationship"} <span className='text-danger' >*</span></label>
                                            </div>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <div class="form-check form-check-inline   ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship1" value="HOD" />
                                                            <label class="form-check-label" for="relationship1">Administrative Office</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship2" value="Teaching Staff" />
                                                            <label class="form-check-label" for="relationship2">Teaching Staff</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship3" value="Non Teaching staff" />
                                                            <label class="form-check-label" for="relationship3">Non Teaching staff </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-check-inline  ">
                                                            <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship5" value="Friend" />
                                                            <label class="form-check-label" for="relationship5">Friend </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>




                                            <div class="form-check form-check-inline mb-2   ">
                                                <input class="form-check-input bg-body-tertiary  " type="radio" name="relationship" id="relationship4" value="Other Person" />
                                                <label class="form-check-label" for="relationship4">{(content[selectedLanguage].find(item => item.elementLabel === 'Others') || {}).mvalue || "nf Others"} </label>
                                            </div>

                                            <div class="mb-2  ">
                                                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'EmailId') || {}).mvalue || "nf EmailId"} <span className='text-danger' >*</span></label>
                                                <input type="email" style={{ height: "32px" }} class="form-control bg-body-tertiary h-75 " id="" placeholder="" />
                                            </div>
                                            <div class="my-2 ">
                                                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'MobileNo') || {}).mvalue || "MobileNo"}</label>
                                                <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary h-75 " id="" placeholder="" />
                                            </div>
                                            <div className="my-2 ">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Remarks') || {}).mvalue || "Remarks"}  </label>
                                                <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2"></textarea>
                                            </div>

                                            <div className='d-flex justify-content-end align-items-center mb-1  '>
                                                <button type="button" className="text-white border-0 px-2 py-1 rounded   pill-bg-color  font-5"  >{(content[selectedLanguage].find(item => item.elementLabel === 'Validate') || {}).mvalue || "nf Validate"}</button>
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-end  ">

                                <div className='d-flex gap-2'>
                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "Save"} onClick={() => handleSkillingAdd(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isAdddingSkill} />
                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "Save & Close"} Active={true} onClick={() => handleSkillingAdd(true)} backgroundColor="#815F0B" color="#F8F8E9" loading={isAdddingSkill} />

                                    {/* <button type="button" className="btn  font-5" style={{ color: "#815F0B", backgroundColor: "#EFF5DC" }} onClick={() => handleSkillingAdd(false)}>save</button>
                                    <button type="button" className="btn  me-2 font-5" style={{ backgroundColor: "#815F0B", color: "#EFF5DC" }} data-bs-dismiss="modal" onClick={() => handleSkillingAdd(false)}>Save & Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={navbarRef} id="yourNavbarId">
                    <Navbar handlePdf={handlePdf}></Navbar>
                </div>

                <hr className='p-0 m-0 ' />

                <div style={{ backgroundColor: "#", minHeight: "", height: "" }} className="container-fluid  h6 ">
                    <div className="row  gap-0 ">

                        <div className=" bg-white px-1 col-md font-5 fixed-sidebar   rounded " >
                            <div>


                                <DetailedPofileNavbar />
                            </div>
                            <div>
                                <PremiumServicesOptions setSwitchTab={setSwitchTab} />

                            </div>
                        </div>

                        <hr className='vr m-0 p-0' />

                        <div className="col-md-7  rounded bg-white  px-1 font-5   " style={{ overflowY: "auto", height: contentHeight }} >
                            {switchTab === "" &&
                                <>
                                    <div className="d-md-flex align-items-center justify-content-between my-1 px-1   " >
                                        <div className='d-flex align-items-center '>
                                        </div>

                                        <div className='py-1 d-flex gap-1'>

                                            <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillButton') || {}).mvalue || "nf AddSkillButton"} backgroundColor="#F7FFDD" color="#815F0B" />
                                            </div>
                                            <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'EditSkillButton') || {}).mvalue || "nf EditSkillButton"} backgroundColor="#F7FFDD" color="#815F0B" onClick={handleEdit} />



                                        </div>

                                    </div>

                                    {/* accordion one table */}
                                    <div className="accordion   " id="accordionPanelsStayOpenExample"  >
                                        <div className="accordion-item border-0  mb-2 rounded-top  ">
                                            <h2 className="accordion-header py-1 ">
                                                <button className="accordion-button flex justify-content-between py-2  " onClick={handleAccordion1} style={{ backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'secBarBgColor') || {}).mvalue || "#577126", color: (content[selectedLanguage].find(item => item.elementLabel === 'secBarFontColor') || {}).mvalue || "#F7FFDD" }} type="button" data-bs-toggle="collapse"   >
                                                    <div className='w-75 '> {(content[selectedLanguage].find(item => item.elementLabel === 'AwardsAccoladesAchievements') || {}).mvalue || "nf Awards/Accolades/Achievements"}</div>
                                                    <div className='d-flex gap-1'>
                                                        <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'SummaryView') || {}).mvalue || "nf SummartView"} onClick={handleSummaryClick} backgroundColor="#F8F8E9" color="#815F0B" statusTab={summaryTab1} />
                                                        <PrimaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'DetailsView') || {}).mvalue || "nf DetailsView"} onClick={handleDetailsSummary} backgroundColor="#F8F8E9" color="#815F0B" statusTab={DetailTab1} />
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse   collapse show">



                                                {summaryTab1 && <div className="accordion-body  ">

                                                    {/* table start */}
                                                    <div className="table-responsive ">
                                                        <AchievementSummary data={achievementHistory}
                                                            yoeCalc={dateDynamicView} editEnable={editEnable} />

                                                    </div>
                                                    {/* table end */}
                                                </div>}
                                                {DetailTab1 && <div className="accordion-body  ">

                                                    <AchievementDetail data={achievementHistory} yoeCalc={dateDynamicView} editEnable={editEnable} />

                                                    {/* table end */}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {switchTab === 'premium' &&
                                <>
                                    <button className="input-group-text  mt-2   ms-1 primary-green " style={{ backgroundColor: "#", color: "#815F0B", borderStyle: "solid", borderColor: "" }} onClick={() => setSwitchTab('')} >
                                        {(content[selectedLanguage].find(item => item.elementLabel === 'Back') || {}).mvalue || "nf Back"}
                                    </button>
                                    <PremiumService
                                    />
                                </>
                            }

                            {switchTab === 'customAnalytics' &&
                                <>
                                    <button className="input-group-text  mt-2    ms-1 primary-green " style={{ backgroundColor: "#", color: "#815F0B", borderStyle: "solid", borderColor: "" }} onClick={() => setSwitchTab('')} >
                                        {(content[selectedLanguage].find(item => item.elementLabel === 'Back') || {}).mvalue || "nf Back"}
                                    </button>
                                    <CustomAnalyticsPS />
                                </>
                            }
                            {/* end one table */}
                        </div>

                        <hr className='vr m-0 p-0' />

                        <div className="col-md  rounded bg-white px-1 font-5 fixed-sidebar">
                            <RightSideBar />
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
            <div className='d-none d-print-block  '>
                <DetailedResume />
            </div>
        </>
    )
}

export default AchivementsHistory