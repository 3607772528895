import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { timestampToYYYYMMDD } from "../../HelperFunction/timestampToYYYYMMDD";
import CreateSelect from "../../SelectComponent/CreateSelect";
import SecondaryBtnLoader from "../../../Buttons/SecondaryBtnLoader";
import { formatTimestampToDate } from "../../HelperFunction/FormatTimestampToDate";
import { EditSkillsApi } from "../../../../api/editData/EditSkillsApi";
import { fetchDataSkillsApplied } from "../../../../api/fetchAllData/fetchDataSkillsApplied";
import { showSuccessToast } from "../../../ToastNotification/showSuccessToast";
import { RxCross2 } from "react-icons/rx";

const SkillsAppiliedEdit = () => {
  /* DISPATCH */
  const dispatch = useDispatch();
  const [locationForRecord, setLocationForRecord] = useState([]);

  /* STORE IMPORTS */
  const {
    skillsAppliedEdit: skillsAppliedEditData,
    language: selectedLanguage,
    content,
    regionalData
  } = useSelector((state) => state);

  useEffect(() => {
  }, [skillsAppliedEditData]);

  /* FORMAT THE DATES */
  const formattedStartDate =
    skillsAppliedEditData?.fromDate &&
    skillsAppliedEditData?.fromDate.includes("/")
      ? timestampToYYYYMMDD(skillsAppliedEditData?.fromDate)
      : formatTimestampToDate(
          Number(skillsAppliedEditData?.fromDate),
          regionalData?.selectedCountry?.dateFormat
        );
  const formattedEndDate =
    skillsAppliedEditData?.toDate && skillsAppliedEditData?.toDate.includes("/")
      ? timestampToYYYYMMDD(skillsAppliedEditData?.toDate)
      : formatTimestampToDate(
          Number(skillsAppliedEditData?.toDate),
          regionalData?.selectedCountry?.dateFormat
        );

  /* STATE INIT */
  const [desc, setDesc] = useState(
    skillsAppliedEditData?.briefDescriptions || ""
  );
  const [isSendingData, setIsSendingData] = useState(false);

  /* HANDLE MODAL CLOSE */
  const closeBtnRef = useRef(null);
  const handleModalClose = useCallback(() => {
    closeBtnRef?.current?.click();
  }, []);

  useEffect(() => {
    setDesc(skillsAppliedEditData?.briefDescriptions);
  }, [skillsAppliedEditData?.briefDescriptions]);

  /* HANDLE EDIT SUBMIT */
  const handleEditSkillsApplied = useCallback(
    async (closeResponse) => {
      setIsSendingData(true);

      /* PAYLOAD */
      const payload = {
        editData: {
          briefDescriptions: desc,
          location: locationForRecord.join(","),
        },
        formNames: [],
        projectHistory: {},
      };

      /* CHECK FOR EMPLOYMENT AND PROJECT HISTORY */
      if (skillsAppliedEditData?.organization) {
        payload.employmentHistory = {
          organization: skillsAppliedEditData.organization,
        };
        payload.formNames.push("Employment History");
      }

      if (skillsAppliedEditData?.projectActivity) {
        payload.projectHistory.projectActivity =
          skillsAppliedEditData.projectActivity;
        payload.formNames.push("Project  History");
      }

      try {
        await EditSkillsApi(
          "Skills Applied",
          skillsAppliedEditData?.id,
          payload
        );
        setIsSendingData(false);
        dispatch(fetchDataSkillsApplied());
        showSuccessToast(
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SkillsAppliedEditedSuccessfully"
            ) || {}
          ).mvalue || "nf Skills applied edited successfully"
        );
        if (closeResponse === "Yes") handleModalClose();
      } catch (error) {
        setIsSendingData(false);
        console.error("Error editing skills applied: ", error);
      }
    },
    [
      desc,
      skillsAppliedEditData,
      dispatch,
      handleModalClose,
      locationForRecord,
      content,
      selectedLanguage,
    ]
  );

  useEffect(() => {
    if (skillsAppliedEditData?.location) {
      setLocationForRecord(skillsAppliedEditData?.location.split(","));
    } else {
      setLocationForRecord([]);
    }
  }, [skillsAppliedEditData]);

  return (
    <React.Fragment>
      <div
        className="modal fade font-5 m-0 p-0 "
        style={{ margin: "0" }}
        id="skillsAppliedEditModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content px-1 py-1">
            <div className="d-flex justify-content-between align-items-center">
              <i className="me-2 mt-1">
                {" "}
                <span className="text-danger ">*</span> Required Fields
              </i>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeBtnRef}
              ></button>
            </div>

            {/* CONTENT */}
            <div className="modal-body pb-0 mb-0">
              <React.Fragment>
                <div className="pill-bg-color text-white p-1 px-2 rounded-pill">
                  {skillsAppliedEditData?.title}
                </div>

                {/* SKILLS APPLIED EDIT */}
                {
                  <React.Fragment>
                    <div className="mt-3">
                      <div>
                        <h6>Edit Applying Skills</h6>
                      </div>
                      <div style={{ pointerEvents: "none" }}>
                        <CreateSelect
                          newField="orgIsnew"
                          placeholder={skillsAppliedEditData?.projectActivity}
                          setNewField="organization"
                          value={skillsAppliedEditData?.projectActivity}
                        />
                      </div>

                      {/* M TYPE */}
                      <div className="mt-2" style={{ pointerEvents: "none" }}>
                        Type:
                        <div className="mt-2">
                          <CreateSelect
                            newField="orgIsnew"
                            placeholder={skillsAppliedEditData?.mtype}
                            setNewField="organization"
                            value={skillsAppliedEditData?.mtype}
                          />
                        </div>
                      </div>

                      {/* ORGANIZATION */}
                      {skillsAppliedEditData?.organization && (
                        <div className="mt-2" style={{ pointerEvents: "none" }}>
                          Organization:
                          <div className="mt-2">
                            <CreateSelect
                              newField="orgIsnew"
                              placeholder={skillsAppliedEditData?.organization}
                              setNewField="organization"
                              value={skillsAppliedEditData?.organization}
                            />
                          </div>
                        </div>
                      )}

                      <div className="d-lg-flex my-2 w-100">
                        <div className=" h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className="form-control bg-body-tertiary text-secondary h-75"
                            id="exampleFormControlInput1"
                            name="fromDate"
                            value={timestampToYYYYMMDD( Number(skillsAppliedEditData?.fromDate) )}
                            disabled={true}
                          />
                        </div>
                        <div className="ms-lg-2 h-75 w-100">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className={"form-label bg-body-tertiary"}
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            min={skillsAppliedEditData?.toDate}
                            max={timestampToYYYYMMDD(Date.now())}
                            style={{ height: "32px" }}
                            className={
                              "form-control bg-body-tertiary text-secondary h-75"
                            }
                            id="exampleFormControlInput1"
                            name="toDate"
                            value={skillsAppliedEditData?.toDate ? timestampToYYYYMMDD( Number(skillsAppliedEditData?.toDate)) : ''}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* LOCATION */}

                      <div className="my-2">
                        <div>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label me-2"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Locations"
                              ) || {}
                            ).mvalue || "not found"}
                          </label>

                          {/* {
                            !(detailedProfileEditData?.applicationName === 'Employment History' || (detailedProfileEditData?.applicationName === 'Project  History' && detailedProfileEditData?.mtype === 'Own')) &&
                            <>
                              <SecondaryBtn label={'reset'} onClick={() => {
                                if (detailedProfileEditData?.location) {

                                  setLocationForRecord(detailedProfileEditData?.location.split(','))
                                } else {
                                  setLocationForRecord([])
                                }
                              }} />
                            </>
                          } */}

                          <div>
                          {!skillsAppliedEditData?.location && (
                                <p className="mb-0 text-muted">
                                  {" "}
                                  No Locations{" "}
                                </p>
                              )}
                          </div>
                        </div>
                        {locationForRecord ? (
                          <div className="d-flex gap-2">
                            {locationForRecord.map((loc) => {
                              return (
                                <div className="p-1  rounded bg-light border">
                                  {loc}
                                  <span
                                    className="text-muted px-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let newLocations =
                                        locationForRecord?.filter(
                                          (location) => {
                                            return location !== loc;
                                          }
                                        );
                                      setLocationForRecord(newLocations);
                                    }}
                                  >
                                    <RxCross2 />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            <p className="mb-0 text-muted"> </p>
                          </>
                        )}
                      </div>

                      {/* EDITABLE DATA */}
                      <div className="my-2 ">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Brief Description about Organization
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="briefDescriptions"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>

                      {/* SUBMIT BUTTONS */}
                      <div className="d-flex gap-2 align-items-center justify-content-end mt-3">
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save"
                              ) || {}
                            ).mvalue || "Save"
                          }
                          backgroundColor="#F8F8E9"
                          color="#815F0B"
                          onClick={() => handleEditSkillsApplied("No")}
                          loading={isSendingData}
                        />
                        <SecondaryBtnLoader
                          label={
                            (
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "Save&Close"
                              ) || {}
                            ).mvalue || "Save & Close"
                          }
                          backgroundColor="#815F0B"
                          color="#F8F8E9"
                          onClick={() => handleEditSkillsApplied("Yes")}
                          loading={isSendingData}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SkillsAppiliedEdit;
