import React from 'react'
import { useSelector } from 'react-redux';

const Footer = () => {

    // store imports
    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);


    return (
        <div className='footer z-3  bg-white   ' style={{backgroundColor:content[selectedLanguage]?.secBarBgColor,color:content[selectedLanguage]?.secBarFontColor,direction: content[selectedLanguage]?.direction}} >
            {/* <hr className='p-0 m-0  ' /> */}
            <div className='font-6 d-flex justify-content-between px-3  px-lg-2   '>
                <div className='' > &copy; Copyright MyST, 2023</div>
                <div>Powered by MyST</div>
            </div>
        </div>
    )
}

export default Footer