import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const getHeaders = () => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getValidationData = async () => {
    try {
        const res = await axios.get(
            `${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/validation requests/${localStorage.getItem(
                "userId"
            )}?authToken=${localStorage.getItem("token")}`
        );
        return res;
    } catch (error) {
        console.error("Validation get api error: ", error);
        throw error;
    }
};

/* SEND VALIDATION */
export const postValidation = async (data) => {
    try {
        const res = await axios.post(
            `${BASE_URL}/skill/api/v1/skills/sendValidation`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
        return res;
    } catch (error) {
        console.error("Error creating the validation: ", error);
        throw error;
    }
};

/* VALIDATION VIA EMAIL */
export const validationReqApi = async (invitaitonId) => {
    try {
        const res = await axios.get(`${BASE_URL}/skill/api/v1/skills/get/validation/${invitaitonId}`);
        return res;
    } catch (error) {
        throw error;
    }
}

/* VALIDATION TEMPLATE UPDATE API */
export const validationTemplateUpdateApi = async (validationId, body) => {
    try {
        const res = await axios.put(`${BASE_URL}/skill/api/v1/skills/validation/validation requests/${validationId}`, body);
        return res;
    } catch (error) {
        throw error;
    }
}

/* EDIT VALIDATION ON REQUESTOR SIDE */
export const editValidationReq = async (validationId, body) => {
    try {
        const res = await axios.put(`${BASE_URL}/skill/api/v1/skills/editValidation/Validation Requests/${validationId}`, body, getHeaders());
        return res;
    } catch (error) {
        throw error;
    }
}

/* VALIDATION ATTACHMENT DOWNLOAD */
export const downloadValidationAttachment = async (ticketId, fileName, fileId) => {
    try {
        return `${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${ticketId}/${fileName}/${fileId}?Authorization=${localStorage.getItem("token")}`;
    } catch (error) {
        throw error;
    }
}