import React from "react";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import ShowHideIcon from "../../ShowHideIcon";
import { useSelector } from "react-redux";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import { useDispatch } from "react-redux";
import AttachmentForm from "../../AttachmentForm";
import Anonimization from "../../Anonimization";
const OthersDetail = ({ data, yoeCalc, editEnable }) => {
  const dispatch = useDispatch();
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  return (
    <>
      <AttachmentForm />
      {/* table start */}
      <div className="table-responsive ">
        <table className="table table-sm  table-fixed     ">
          <tr className="border-dark-subtle border-bottom ">
            <th scope="col" className="bg-body- " style={{ width: "34%" }}>
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "Others"
                ) || {}
              ).mvalue || "nf Others"}
            </th>
            <th scope="col" className="bg-body- " style={{ width: "17%" }}></th>
            <th scope="col" className="bg-body- " style={{ width: "13%" }}></th>
            <th scope="col" className="bg-body- " style={{ width: "14%" }}></th>
            <th scope="col" className="         " style={{ width: "12%" }}></th>
            <th scope="col" className="bg-body- " style={{ width: "10%" }}></th>
          </tr>
        </table>
      </div>

      {data?.data?.map((certs, index) => (
        <div className="border-bottom  pt-2">
          <div className="d-flex   ">
            <div className=""></div>
            <div className="  " style={{ width: "34%" }}>
              {certs.title}
            </div>
            <div className="    " style={{ width: "17%" }}></div>
            <div style={{ width: "13%" }}></div>
            <div className="    " style={{ width: "14%" }}></div>
            <div className="" style={{ width: "12%" }}></div>
            <div className="   d-flex  " style={{ width: "10%" }}>
              <button
                className="border-0 bg-white"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Attachment"
                onClick={() => handleAttachment(certs)}
                data-bs-toggle="modal"
                data-bs-target="#attachmentList"
              >
                <ImAttachment />
              </button>
              <div>
                <ShowHideIcon value={false} />
              </div>

              {editEnable && (
                <>
                  <div className="">
                    <MdEdit />
                  </div>
                  <div className="">
                    <MdDelete />
                  </div>
                  <div>
                    <ShowHideIcon value={false} />
                  </div>
                  <ShowHideIcon
                    value={certs.recordHide === "Yes" ? true : false}
                    toggleable={true}
                    form={certs}
                    fieldName={"recordHide"}
                    api={"Skills Applied"}
                    id={certs.id}
                  />
                  <Anonimization
                    value={certs.recordAnonymous === "Yes" ? true : false}
                    toggleable={true}
                    form={certs}
                    fieldName={"recordAnonymous"}
                    api={"Skills Applied"}
                    id={certs.id}
                  />
                </>
              )}
            </div>
          </div>

          <div className="ms-5  my-3  ">
            {/* <table className='d-flex gap-4  '>
                        <tr>
                            <td><b>From &nbsp;</b> </td>
                            <td>: {certs.startDate}</td>
                        </tr>
                        <tr>
                            <td><b>To &nbsp;</b> </td>
                            <td>: {certs.endDate}</td>
                        </tr>
                    </table> */}
            <div className="pb-1 pt-2  ">
              <b>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectBriefDescription"
                  ) || {}
                ).mvalue || "nf Brief Description"}
                :
              </b>{" "}
            </div>
            <div className="ms-4  ">{certs.mothers}</div>

            {/* {certs.validation &&
                       <>
                        <div className='pb-1 pt-2   '><b>Validation:</b> </div>
                    <div className=' d-flex justify-content-between ms-2 me-4   '>
                        <table className='ms-3  '>

                            <tr>
                                <td><b>Validator Name</b> </td>
                                <td>:{certs.validatorName}</td>
                            </tr>

                            <tr>
                                <td><b>Email Id</b> </td>
                                <td>:Emily@gmail.com</td>
                            </tr>

                        </table>
                        <table className='ms-3 '>
                            <tr>
                                <td><b>Relationship</b> </td>
                                <td>:Professor</td>
                            </tr>
                            <tr>
                                <td><b>Mobile Number</b> </td>
                                <td>:5710838457</td>
                            </tr>
                        </table>
                    </div>

                    <div className='ms-3 mt-2   '><b>Remarks:</b> </div>
                    <div className='ms-5  mt-1   '>He is a good team player and very supportive in nature , he is very good in communication and he is very good in technical skills</div>
                    </>  
                    }*/}
          </div>
        </div>
      ))}
    </>
  );
};

export default OthersDetail;
