import React from "react";
import { Link } from "react-router-dom";
import "../LoginPage.css";

const LoginForm = ({
  content,
  selectedLanguage,
  validation,
  validated,
  isPasswordShow,
  password,
  setPassword,
  handlePasswordShow,
  handleRoleChange,
  selectedRole,
  roles,
  handleSignIn,
  isLoading,
  FaEye,
  FaEyeSlash,
  logo,
  rolesMap,
  isLoadinRoles,
}) => {
  return (
    <React.Fragment>
      <div className="col-md-6 m-auto">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <img src={logo} height={"50px"} alt="logo"></img>

          <h3 class="display-5 text-center  ">
            {(
              content[selectedLanguage].find(
                (item) => item.elementLabel === "SignIn"
              ) || {}
            ).mvalue || "nf SignIn"}
          </h3>
        </div>
        <p
          className="text-muted mb-4 text-center"
          style={{ letterSpacing: ".1rem" }}
        >
          {" "}
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "SignInMessage"
            ) || {}
          ).mvalue || "nf SignInMessage"}
        </p>
        <div className="login d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                {/* FORM */}
                <form className={validation} validated={validated}>
                  <div className="col-lg-8 mx-auto">
                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                    >
                      {/* PASSWORD */}
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: "2px",
                          left: "2px",
                        }}
                      >
                        <span
                          className="input-group-text bg-white pl-2 border-0 h-100"
                          style={{ borderRadius: 0 }}
                        >
                          <i className="fa fa-lock text-muted"></i>
                        </span>
                      </div>
                      <input
                        id="password"
                        style={{ height: "32px", paddingLeft: "43px" }}
                        name="password"
                        type={isPasswordShow ? "text" : "password"}
                        placeholder={`${
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Password"
                            ) || {}
                          ).mvalue || "nf Password"
                        }`}
                        required
                        autofocus
                        className={"form-control font-5"}
                        vlaue={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {/* HANDLE EYE ICONS FUNC */}
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handlePasswordShow}
                      >
                        {isPasswordShow ? <FaEyeSlash /> : <FaEye />}
                      </div>
                      <div className="text-end invalid-feedback">
                        {(
                          content[selectedLanguage].find(
                            (item) =>
                              item.elementLabel === "EnterCurrentPassword"
                          ) || {}
                        ).mvalue || "nf Please enter password"}
                      </div>

                      {/* ROLE SELECT */}
                      <div className="mt-3">
                        <select
                          onChange={handleRoleChange}
                          value={selectedRole}
                          required
                          className="form-select font-5 mb-2"
                          style={{
                            pointerEvents: rolesMap?.length >= 3 && "none",
                          }}
                          aria-label="Default select example"
                        >
                          {rolesMap.map((role, index) => {
                            if (
                              role.active === "Yes" &&
                              role.mlanguage === selectedLanguage
                            ) {
                              return (
                                <option key={index} value={role.roleName}>
                                  {role?.label}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </select>
                        <div className="text-end invalid-feedback">
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "PleaseSelectARole"
                            ) || {}
                          ).mvalue || "nf PleaseSelectARole"}
                        </div>
                      </div>

                      {/* FORGOT PASSWORD REDIRECT */}
                      <div className="d-block d-md-flex justify-content-between">
                        <div className="links">
                          <Link to="/forgotpassword" className="ml-2">
                            {" "}
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "HavingTroubleSigningIn"
                              ) || {}
                            ).mvalue || "nf HavingTroubleSigningIn"}
                          </Link>
                        </div>
                      </div>

                      {/* SIGNIN BUTTON */}
                      <button
                        type="button"
                        id="signInBtn"
                        className="btn mt-2 text-white btn-block mb-2 float-end rounded-pill shadow-sm"
                        style={{ backgroundColor: "#815F0B" }}
                        onClick={handleSignIn}
                      >
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "SignIn"
                          ) || {}
                        ).mvalue || "nf SignIn"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
