import {  createSlice } from "@reduxjs/toolkit";
import {  fetchUserCourses } from "../../../api/SkillingAgency/fetchUserCourses";

const initialState  = {
  data: [
    1,2,2,3
  ],
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};


const userCoursesSlice = createSlice({
  name: "userCourses",
  initialState,
  reducers: {
    addUserCourse: (state, action) => {
      state = [...state,action.payload]
    },   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCourses.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserCourses.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchUserCourses.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { addUserCourse } =
userCoursesSlice.actions;
export default userCoursesSlice.reducer;









































