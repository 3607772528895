import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ResumeTemplate from '../../pages/template/Reseme/ResumeTemplate';

const EmptyPage = () => {
    const { id, view } = useParams();


    const [userData, setUserData] = useState();

    useEffect(() => {



    }, [id, view])

    return (
        <div className='   '>


        </div>
    )
}

export default EmptyPage