import { useEffect, useState } from "react";
import './ProgressBar.css'

export default function ProgressBar({ value = 0, onComplete = () => { } }) {

  const [percent, setPercent] = useState(value);

  const MIN = 0;
  const MAX = 100;

  useEffect(() => {
    setPercent(Math.min(Math.max(value, MIN), MAX));

    if (value >= MAX) {
      onComplete();
    }
  }, [value]);

  return (
    <>
    <div className="progress-bar-outer">
      <div className="progress">

      <span
        style={{
          color: percent > 49 ? "white" : "black"
        }}
        className="fw-bold"
      >
        {percent.toFixed()}%
      </span>
      <div
        style={{ width: `${percent}%` ,
        backgroundColor:'#5cb85c'
        }}
        // style={{
        //   transform: `scaleX(${percent / MAX})`,
        //   transformOrigin: "left",
        
        // }}
        // aria-valuemin={MIN}
        // aria-valuemax={MAX}
        // aria-valuenow={percent}
        // role="progressbar"
        className="progress-bar progress-bar-info progress-bar-striped active"
      />
       {/* <div class="progress-value"> {percent.toFixed()}%</div> */}
    </div>
    </div>


      {/* <div class="d-flex" >


            <div class="progress-bar-outer">
              <div class="progress-container">
                <div class="progress-bar-inner" style={{
                  // transform: `scaleX(${percent / MAX})`,
                  // transformOrigin: "left",   
                  minWidth:'30px',              
                  width: `${percent}%` ,
                  // width:'100%',
                  boxShadow: '-1px 10px 10px rgba(116, 195, 116,0.3)'
                  , backgroundColor: 'black'
                }}

                ></div>
                <div class="progress-value"> {percent.toFixed()}%</div>
              </div>
            </div>


      </div> */}


    </>


  );
}