import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import axios from 'axios';

export const  fetchRelationshipdetails = 
    async (requestData) => {
      try {
        const url = `${BASE_URL}/skill/api/v1/skills/delete/relationship`

        const body = {
            "parentTicketId": requestData?.id,
            "applicationName": requestData?.applicationName
        }
        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
            'Content-Type': 'application/json'

        };
        const response = await axios.post(url, body, { headers })

        console.log(response?.data);
        return response?.data?.filter((data)=> data.applicationName);
      } catch (error) {
        throw error;
      }
    }
