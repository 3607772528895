import React from "react";
import ResumeBodyResportCard from "./ResumeBodyResportCard";
import ResumeBodySkillDetail from "./ResumeBodySkillDetail";

const ResumeBody = ({
  content,
  selectedLanguage,
  skillAppliedCount,
  skillAcquiredCount,
  SkillsApplied,
  SkillSelected,
  SkillsAcquired,
  regionalData,
  userDetails,
  fontColor,
  fontSize,
  logo,
  top5Skills,
  remainingSkills,
  userValidationData,
  resumeSkills
}) => {
  return (
    <div className="resume-body-container mt-2" style={{ marginRight: "10px" }}>
      {/* <div className="logo-watermark">
        <img src={logo} alt="MYST LOGO" />
      </div> */}
      <React.Fragment>
        <div className="content">
          <div className="ms-3">
            <div>
              <ResumeBodyResportCard
                content={content}
                selectedLanguage={selectedLanguage}
                fontSize={fontSize}
                userDetails={userDetails}
                top5Skills={top5Skills}
                remainingSkills={remainingSkills}
                SkillSelected={SkillSelected}
                SkillsApplied={SkillsApplied}
                SkillsAcquired={SkillsAcquired}
              />
            </div>
          </div>

          <div className="page-break-before ms-3">
            <ResumeBodySkillDetail
              selectedLanguage={selectedLanguage}
              content={content}
              fontSize={fontSize}
              userDetails={userDetails}
              top5Skills={top5Skills}
              remainingSkills={remainingSkills}
              skillAppliedCount={skillAppliedCount}
              skillAcquiredCount={skillAcquiredCount}
              SkillsApplied={SkillsApplied}
              SkillSelected={SkillSelected}
              SkillsAcquired={SkillsAcquired}
              regionalData={regionalData}
              userValidationData={userValidationData}
              resumeSkills={resumeSkills}
            />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default ResumeBody;
