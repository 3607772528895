import React from 'react'
import { useSelector } from 'react-redux';
import { DayDifferenceToDynamicView } from '../../HelperFunction/DayDifferenceToDynamicView';

const LanguageSummaryResView = ({ data }) => {
    const selectedLanguage = useSelector(state => state.language);

    const content = useSelector(state => state.content);
    return (
        <React.Fragment>
            {
                data?.filter(item => item.recordHide !== "Yes")?.length > 0 &&
                <React.Fragment>
                    <div className='font-3 font-weight-2'> {(content[selectedLanguage].find(item => item.elementLabel === 'Languages') || {}).mvalue || "nf Languages"}</div>


                    <div className='ms-3 mt-2 '>
                        {/* table */}
                        <table className='table-sm table font-5   '>
                            <thead>
                                <tr>
                                    <th scope='col' style={{ width: "30%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Languages') || {}).mvalue || "nf Languages"} </th>
                                    <th scope='col' style={{ width: "16%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Read') || {}).mvalue || "nf Read"}   </th>
                                    <th scope='col' style={{ width: "16%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Write') || {}).mvalue || "nf Write"} </th>
                                    <th scope='col' style={{ width: "16%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Speak') || {}).mvalue || "nf Speak"} </th>

                                    <th scope='col' className='text-center ' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectValidation') || {}).mvalue || "nf ProjectValidation"}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item =>
                                    item.recordHide === 'Yes' ?
                                        <></>
                                        :
                                        <tr>
                                            <td>{item.mlanguage}</td>
                                            <td className='  '>
                                                <input type="checkbox" checked={item.mread === "true"} className="  " readonly />

                                            </td>
                                            <td className=' '>

                                                <input type="checkbox" checked={item.mwrite === "true"} className="  " readonly />

                                            </td>
                                            <td className=' '>

                                                <input type="checkbox" checked={item.speak === "true"} className="  " readonly />
                                            </td>
                                            <td className='text-center'>{item.validation === 'Yes' ? ' Yes' : ' No'}</td>


                                        </tr>

                                )



                                }
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default LanguageSummaryResView