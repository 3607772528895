import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AiFillPrinter, AiOutlineClose } from 'react-icons/ai';
import { FcMenu } from 'react-icons/fc';
import Playground from '../../FlagSelection';
import user from "../../../Images/tom.jpeg"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../../reducer/localization/languageSlice';
import { logout } from '../../../reducer/userDetails/UserProfileSlice';
import logo from "../../../Images/logo.png";
import GetAllLangApi from '../../../api/content/GetAllLangApi';
import SettingsModal from '../../Modals/SettingsModal';
import PasswordModal from '../../Modals/PasswordModal';
import SwitchRoleModal from '../../Modals/SwitchRoleModal';
import PrivacyModal from '../../Modals/PrivacyModal';
import UserProfile from '../UserComponents/UserProfile';
import { GetUserForSelectedLanguage } from '../../SkillOwner/HelperFunction/GetUserForSelectedLanguage';



const SkillingAgencyNavbar = () => {
  const location = useLocation().pathname;
  // check local storage if present put in state or else default values
  const [Country, setCountry] = useState({ countryCode: "US", countryName: "United States" });
  // const [Country, setCountry] = useState({ countryCode: localStorage.getItem("countryCode") || "US", countryName: localStorage.getItem("countryName") || "United States" });
  const [ShowDropDown, setShowDropDown] = useState(false)
  const [menu, setMenu] = useState(false)
  const [profileOptionShow, setProfileOptionShow] = useState(false)
  const [lang, setLang] = useState([])

  const [timeZone, setTimeZone] = useState([{ "code": "en-US", "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" },
  { "code": "en-US", "country": "US", "abbr": "PST", "TZ": "America/Los_Angeles" },
  { "code": "en-US", "country": "US", "abbr": "EST", "TZ": "America/New_York" },
  { "code": "en-IN", "country": "India", "abbr": "IST", "TZ": "Asia/Kolkata" }]);
  const [selectedTimeZone, setSelectedTimeZone] = useState({});
  const [selectedObject, setSelectedObject] = useState({ "country": "US", "abbr": "AKST", "TZ": "America/Anchorage" });
  const [flagSearch, setFlagSearch] = useState("");

  useEffect(() => {

    GetAllLangApi().then((res) => {
      console.log("time " + res.data)
      setLang(res.data)
    })

    // storing country code in local storage
    // localStorage.setItem("countryCode", Country.countryCode)
    // localStorage.setItem("countryName", Country.countryName)
  }, [Country])


  const handleDropDown = ({ handlePdf }) => {
    setShowDropDown(!ShowDropDown)
  }


  // store imports
  const selectedLanguage = useSelector(state => state.language);
  const content = useSelector(state => state.content);
  const userDetails = useSelector(state => GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage));

  const dispatch = useDispatch();

  const handleLanguageChange = (event) => {
    dispatch(setLanguage(event.target.value));
  }
  const handleTimeZoneChange = (e) => {
    const selectedValue = e.target.value;
    const selectedObjects = timeZone?.find(item => item.abbr === selectedValue);
    setSelectedTimeZone(selectedValue);
    setSelectedObject(selectedObjects);
  }

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("candId");

  }
  const date = new Date();
  console.log(
    date.toLocaleString(selectedObject.code, {
      timeZone: selectedObject.TZ,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    }),
  ); // 👉️ "07/24/2023, 04:55:00 AM PDT"

  //specify the date string and the format it's initially in
  // var mydate = moment('15/11/2000', 'DD/MM/YYYY');

  // //format that date into a different format
  // moment(mydate).format("MM/DD/YYYY");

  const handleFlagFilter = (e) => {
    setFlagSearch(e.target.value);
  }

  return (
    <>
      {content.status === "succeeded" && <>

        {/* modal tabs */}
        <UserProfile />
        <PasswordModal />
        <SwitchRoleModal />
        <PrivacyModal />

        <nav style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarBgColor') || {}).mvalue || "#577126", direction: (content[selectedLanguage]?.find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className="navbar navbar-expand-lg d-print-none p-0 m-0   "  >
          <div className="container-fluid  ">
            <a className="navbar-brand d-flex gap-2 align-items-center   " href="/">
              <div className='d-flex  flex-column align-items-center  justify-align-content-around'>

                <img src={logo} alt="Logo" width="35" height="35" className="d-inline-block " />
                <div className='h6 m-0  ' style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", fontSize: "12px", fontWeight: "700" }} >MySkillsTree</div>
              </div>
              <div className='' style={{position:'relative'}}>
              {/* <div className='px-1 font-weight-1  font-1 py-0   ' style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage]?.find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }}>{(content[selectedLanguage]?.find(item => item.elementLabel === 'ApplicationName') || {}).mvalue || "not found"}</div>
              <div className='px-1 py-0 ' style={{ position:'relative', top:'-8px', fontStyle:'italic' , color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage]?.find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" , fontSize: "12px" } }>Skilling Agency</div> */}

              <div className='px-1 font-weight-1 font-1 mb-0 ' style={{ height: "28px", color: (content[selectedLanguage].find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ApplicationName') || {}).mvalue || "not found"}</div>
                <div className='d-flex px-1 pt-0  font-weight-0  font-6 fst-italic   ' style={{ color: (content[selectedLanguage].find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage].find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }}>Skilling Agency</div>
              </div>
            </a>

            <div className='d-flex align-items-center   ' >
              <div className='mx-0 ' >
                <ul className="nav   d-flex align-items-baseline d-none d-lg-flex      ">
                  <li className="nav-item  font-weight-bold  pt-lg-4 ">
                    <Link style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage]?.find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className={((location === "/skillingagency/skillsearch") || (location.startsWith("/premium-services"))) ? "  fw-bold px-2   " : "nav-link  text-gray py-0 my-0 px-2   "} aria-current="page" to="/skillingagency/skillsearch">Skill Search</Link>
                    {/* {(content[selectedLanguage]?.find(item => item.elementLabel === 'SkillsProfile') || {}).mvalue || "not found"} */}
                  </li>
                  <li className="nav-item font-weight-bold pt-lg-4  ">
                    <Link style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", direction: (content[selectedLanguage]?.find(item => item.elementLabel === 'Direction') || {}).mvalue || "ltr" }} className={location.startsWith("/skillingagency/coursesearch") ? "  fw-bold  px-2   " : "nav-link  text-gray py-0 my-0 px-2   "} to="/skillingagency/coursesearch">Course Search</Link>
                    {/* {(content[selectedLanguage]?.find(item => item.elementLabel === 'SkillDetails') || {}).mvalue || "not found"} */}
                  </li>
                  <li>
                    <div className=' d-flex flex-column-reverse ' style={{ width: "20px", marginRight: "22px", marginLeft: "10px" }}>
                      <button  style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarBgColor') || {}).mvalue || "#577126" }} className=' border-0   me-5   px-0   '>
                      <AiFillPrinter className='   ' />
                    </button>
                    </div>
                  </li>
                </ul>
              </div>


              <div className='d-flex justify-content-around   '>
                {/* Language and TImeZOne selection and time/timezone/date view*/}
                <div className=' d-flex flex-column justify-content-around  ' style={{ width: "140px", marginRight: "-10px", marginLeft: "20px" }}>
                  <div className='d-flex justify-content-between  ' style={{ width: "100%" }} >
                  
                    <div className='d-none d-lg-block '>
                      <select class="  border-0 font-dd  " style={{
                        color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarBgColor') || {}).mvalue || "#577126",
                        width: "60px", height: "10px", fontWeight: "500", fontSize: "10px"

                      }} aria-label="Default select example  "
                        onChange={handleLanguageChange} value={selectedLanguage} >
                        {lang.map((item) =>
                          <option value={item.code} key={item.code} className='bg-body-tertiary font-dd    text-black  ' >
                            {selectedLanguage === item.code ? item.code : ` ${item.mlanguage}`}
                          </option>
                        )}
                      </select>
                    </div>

                    <div className='d-none d-lg-block  '>
                      <select class="  border-0 font-dd  " style={{
                        color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD", backgroundColor: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarBgColor') || {}).mvalue || "#577126",
                        width: "70px", height: "10px", fontWeight: "500", marginBottom: "15px", fontSize: "10px"
                      }} aria-label="Default select example  "
                        onChange={handleTimeZoneChange} value={selectedTimeZone} >
                        {timeZone.map((item) =>
                          <option value={item.abbr}
                            key={item.abbr} className='bg-body-tertiary font-dd    text-black  ' >
                            {selectedTimeZone === item.abbr ? selectedObject.country + " " + selectedObject.abbr : item.country + " " + item.abbr}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='d-flex   ' style={{ fontSize: "10px", alignContent: "center", fontWeight: "500" }}>
                    {date.toLocaleString(selectedObject.code, {
                      timeZone: selectedObject.TZ,
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',

                      timeZoneName: 'short',
                    })}
                  </div>
 
                </div>
                {profileOptionShow &&
                  <div className='z-3  border px-2 py-1  mt-1 bg-body-tertiary   ' style={{ position: "absolute", backgroundColor: "white", borderRadius: "5px" }}>
                    <table className=' table-sm table-hover  font-6  ' >
                      <tr className=' ' >
                        <td>
                          <button className='btn p-0  m-0 ' data-bs-toggle="modal" data-bs-target="#setting" onClick={() => setProfileOptionShow(false)}  >Profile</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button className='btn p-0 m-0' data-bs-toggle="modal" data-bs-target="#password" onClick={() => setProfileOptionShow(false)} >
                            Change Password</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button className='btn p-0 m-0' data-bs-toggle="modal" data-bs-target="#role" onClick={() => setProfileOptionShow(false)} >Change Role</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button className='btn p-0 m-0' data-bs-toggle="modal" data-bs-target="#privacy" onClick={() => setProfileOptionShow(false)} >Privacy Settings</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button className='btn p-0 m-0' onClick={handleLogout}  >Logout</button>
                        </td>
                      </tr>
                    </table>
                  </div>}





                {/* hamburger menu mobile */}
                <div className='d-flex d-lg-none ps-1 mb-2 pb-1  '  >
                  {!menu && <button className=' border-0 navigation-bg-color  ' onClick={() => setMenu(true)} style={{ fontSize: "26px" }} >
                    <FcMenu />
                  </button>}
                  {menu && <button className=' border-0 navigation-bg-color  ' onClick={() => setMenu(false)} style={{ fontSize: "26px" }} >
                    <AiOutlineClose />
                  </button>}
                </div>

                {/* flag */}
                <div class="   px-0 d-none d-lg-block mx-3   ">
                  <button type="button" onClick={handleDropDown} class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside">
                    <img className='m-0 p-0 ' style={{ width: "35px", height: "37px", borderRadius: "100%" }} src={`https://flagsapi.com/${Country.countryCode}/flat/32.png`} alt="" />
                    <div className='font-10px p-0 m-0 ' style={{ color: (content[selectedLanguage]?.find(item => item.elementLabel === 'navBarFontColor') || {}).mvalue || "#F7FFDD" }}>
                      {/* {Country.countryName} want to show 10letters if more than that print ... */}
                      {/* {Country.countryName.length > 10 ? Country.countryName.slice(0, 7) + "..." : Country.countryName} */}
                      {Country.countryCode}
                    </div>
                  </button>
                  {ShowDropDown &&
                    <div class={ShowDropDown ? "dropdown-menu show" : "dropdown-menu "} style={{ minWidth: "10px", marginRight: "100px", marginLeft: "-30px" }} >

                      <div className='d-flex justify-content-center '>
                        <input placeholder='Search Country...' value={flagSearch} onChange={handleFlagFilter} style={{ width: "130px", height: "20px", fontSize: "10px", padding: "5px" }}>

                        </input>
                      </div>
                      <div className=' table-responsive d-flex  font-5 ' style={{ height: "130px" }}>

                        <table className='table table-sm d-flex table-hover  '>

                          <tbody className='font-5' style={{ width: "5%" }} >
                            <Playground setCountry={setCountry} setShowDropDown={setShowDropDown} flagSearch={flagSearch} setFlagSearch={setFlagSearch} />
                          </tbody>

                        </table>
                      </div>
                    </div>}
                </div>

                <div className='d-flex  flex-column align-items-center  ' onClick={() => setProfileOptionShow(!profileOptionShow)} style={{ cursor: "pointer" }} >
                  <div>
                    <img src={user} alt={(content[selectedLanguage]?.find(item => item.elementLabel === 'Profile Picture') || {}).mvalue || "not found"} width="32" height="32" className="d-inline-block rounded-circle ms-1 mt-1  " />
                  </div>
                  <div className=' font-10px p-0 m-0 text-center '>{userDetails.firstName}</div>
                </div>
              </div>
            </div>

            {
              menu && <div class="offcanvas-body mt-1 d-lg-none d-block   ">
              <ul class="   gap-2 d-flex flex-column justify-content-center align-content-center mx-auto my-0    list-unstyled   " style={{}} >
                <li className="nav-item  font-weight-bold  pt-lg-4 ">
                  <Link className={location === "/dashboard/skillprofile" ? " text-brown fw-bold  " : "nav-link  text-gray py-0 my-0  "} aria-current="page" to="/dashboard/skillprofile">Skill Profile Management</Link>
                </li>
                <li className="nav-item font-weight-bold pt-lg-4  ">
                  <Link className={location.startsWith("/dashboard/detailedprofile") ? " text-brown fw-bold  me-3  " : "nav-link  text-gray py-0 my-0  "} to="/dashboard/detailedprofile">Detailed Profile Management</Link>
                </li>

                <li>

                </li>

              </ul>

              <div className=' d-flex align-items-center  mb-1    ' >

                {/* flag */}
                <div className="   px-0  ps-2  ">
                  <button type="button" onClick={handleDropDown} class="btn border-0 p-0 m-0  ms-2  " data-bs-auto-close="outside">
                    <img className='m-0 p-0 ' style={{ width: "30px" }} src={`https://flagsapi.com/${Country.countryCode}/flat/32.png`} alt="" />
                    <div className='font-10px p-0 m-0 ' >
                      {/* {Country.countryName} want to show 10letters if more than that print ... */}
                      {Country.countryName.length > 10 ? Country.countryName.slice(0, 10) + "..." : Country.countryName}
                    </div>
                  </button>
                  {ShowDropDown &&
                    <div class={ShowDropDown ? "dropdown-menu show" : "dropdown-menu "} style={{ Width: "33px", }} >
                      <div className=' table-responsive d-flex  font-5 ' style={{ height: "130px" }}>

                        <table className='table table-sm d-flex table-hover  '>

                          <tbody className='font-5' style={{ width: "5%", }} >
                            <Playground setCountry={setCountry} setShowDropDown={setShowDropDown} />
                          </tbody>

                        </table>
                      </div>
                    </div>}
                </div>
              </div>

            </div>
            }



          </div>

        </nav>
      </>}
    </>
  )
}

export default SkillingAgencyNavbar
