import React from 'react'
import EmploymentSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/EmploymentSummaryResView'
import ProjectSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/ProjectSummaryResView'
import EducationSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/EducationSummaryResView'
import CertificationSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/CertificationSummaryResView'
import SkillingSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/SkillingSummaryResView'
import TrainingSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/TrainingSummaryResView'
import AwardSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/AwardSummaryResView'
import ConferenceSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/ConferenceSummaryResView'
import MembershipSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/MembershipSummaryResView'
import LanguageSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/LanguageSummaryResView'
import LicensesSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/LicensesSummaryResView'
import OthersSummaryResView from '../SkillOwner/DetailedProfile/For Resume and Seeker/OthersSummaryResView'

const DetailedProfileView = ({ userDetails }) => {
    return (
        
        <div>
            <EmploymentSummaryResView data={userDetails?.detailedProfileData?.employments} />

            <ProjectSummaryResView data={userDetails?.detailedProfileData?.worksProjects} />

            <EducationSummaryResView data={userDetails?.detailedProfileData?.education} />

            <CertificationSummaryResView data={userDetails?.detailedProfileData?.certifications} />

            <SkillingSummaryResView data={userDetails?.detailedProfileData?.skillings} />

            {/* Training */}
            <TrainingSummaryResView data={userDetails?.detailedProfileData?.trainings} />

            {/* Award/achievement */}
            <AwardSummaryResView data={userDetails?.detailedProfileData?.award} />
            {/* Conference */}

            <ConferenceSummaryResView data={userDetails?.detailedProfileData?.conferences} />

            {/* licenses history */}
            <LicensesSummaryResView data={userDetails?.detailedProfileData?.licenses} />
            {/*Membership  */}
            <MembershipSummaryResView data={userDetails?.detailedProfileData?.memberships} />

            {/* languages */}
            <LanguageSummaryResView data={userDetails?.detailedProfileData?.languages} />

            <OthersSummaryResView data = {userDetails?.detailedProfileData?.others} />

        </div>
    )
}

export default DetailedProfileView