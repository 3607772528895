import React from 'react'

const SecondaryToggleBtn = ({ label, onClick, isActive }) => {

    const activeStyle = {
        backgroundColor: "#F7FFDD",
        borderStyle: "solid",
        borderColor: "#815F0B",
        color: "#815F0B",
        cursor: "pointer",
    };

    const inactiveStyle = {
        backgroundColor: "white",
        borderStyle: "solid",
        color: "#815F0B",
        borderColor: "#815F0B",
        cursor: "pointer",
    };

    const buttonStyle = isActive ? activeStyle : inactiveStyle;

    return (
        <span className="badge ms-1 border-1" style={buttonStyle} onClick={onClick}>
            {label}
        </span>
    )
}

export default SecondaryToggleBtn