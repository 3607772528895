import React, { useEffect, useRef, useState } from 'react'
import NavbarA from '../../components/SkillAvailer/NavbarA'
import Footer from '../../components/Footer'

import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom'
import SkillingAgencyNavbar from '../../components/SkillingAgency/DashboardComponents/SkillingAgencyNavbar'
import { useDispatch } from 'react-redux';

import { setMyRefinedSkills } from '../../reducer/SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice'

import ResultMIddlePanel from './ResultMIddlePanel'
import OccupationModal from '../../components/SkillAvailer/OccupationModal'
import LeftPanelSeeker from './LeftPanelSeeker'
import SidebarSeeker from './SidebarSeeker'
import SkillSeekerMenuItems from './SkillSeekerMenuItems';
import SkillSeekerSearch from './SkillSeekerSearch';


const SkillDashboard = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const skillSuggestionRef = useRef(null);

    //auto hides popups
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                skillSuggestionRef.current && !skillSuggestionRef.current.contains(event.target)
            ) {
                setSkillSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [skillSuggestionRef]);

    //store imports

    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);

    const [locationForNewEmployment, setLocationForNewEmployment] = useState("");
    //search results
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    //my requiremnet
    const MyRequirement = useSelector(state => state.MyRequirement);
    //refine my req
    const RefMyRequirements = useSelector(state => state.RefMyRequirements);
    //Regional country Data
    const regionalData = useSelector(state => state.regionalData);

    useEffect(() => {
        console.log("ssssssss ", locationForNewEmployment);

    }, [locationForNewEmployment])




    //Suggestions inside skill search
    const [SkillSuggestions, setSkillSuggestions] = useState(false);
    //my req add skill


    useEffect(() => {
        console.log("ref my req ", RefMyRequirements?.skillsInRefined);
    }, [RefMyRequirements?.skillsInRefined])

    const navbarRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('100vh');
    const [sidebarHeight, setSidebarHeight] = useState('100vh');

    useEffect(() => {

        if (navbarRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            setContentHeight(`calc(98vh - ${navbarHeight}px)`);
            setSidebarHeight(`calc(100vh - ${navbarHeight}px)`);
        }
    }, []);

    const handleRemoveListOfMyReqSkill = (e) => {
        console.log(e.target.id);
        // remove from list of skills

        //setRefineSkillFilter(RefineSkillFilter.map((item, i) => i === parseInt(e.target.id) ? { ...item, show: !item.show } : item));

        dispatch(setMyRefinedSkills(MyRequirement?.skillsInMyReq?.map((item, i) => i === parseInt(e.target.id) ? { ...item, show: !item.show } : item)));
    }

    return (
        <div className=''>

            <div className='d-print-none' style={{ direction: "ltr" }}>

                {/*occupation modal */}

                <OccupationModal />


                {/* NavBar */}

                <div ref={navbarRef} id="yourNavbarId" >
                    {
                        pathname.includes('skillingagency') ? <SkillingAgencyNavbar /> : <NavbarA />
                    }


                    <hr className='p-0 m-0 ' />

                    {/* secondary nav bar lap view */}

                </div>
                <div className='d-flex '>


                    {/* <SidebarSeeker sidebarHeight={sidebarHeight} menuItems={SkillSeekerMenuItems} /> */}

                    <SkillSeekerSearch SkillBasedResult={SkillBasedResult} contentHeight={contentHeight} />



                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default SkillDashboard;