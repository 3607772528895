import React, { useEffect, useState } from "react";
import ShowHideIcon from "../../ShowHideIcon";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import { ImAttachment, ImCheckmark } from "react-icons/im";
import Validation from "../../ValidationForm";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { useDispatch } from "react-redux";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import { skillingUpdate } from "../../../reducer/detailedProfile/skillingsSlice";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import AttachmentForm from "./../../AttachmentForm";
import EditDetailedProfileConfandSkilling from "../Forms/Edit Forms/EditDetailedProfileConfandSkilling";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import Anonimization from "../../Anonimization";

const SkillingsSummary = ({ data, editEnable }) => {
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);

  const dispatch = useDispatch();

  /* HANDLE EDIT FOR SKILLING HISTORY */
  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };

  const handleValidate = (validationData) => {
    dispatch(setValidationData(validationData));
  };
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  /* CHANGE VALIDATION RESULTS */
  const {
    getUserValidation: { userValidationData },
  } = useSelector((state) => state);

  const [isValidationResult, setIsValidationResult] = useState(false);

  useEffect(() => {
    /* CHECK FOR MATCHING ID AND CAPTURE DATA */
    data?.data.forEach((dataItem) => {
      userValidationData.forEach((validationItem) => {
        if (
          dataItem.id === validationItem.requestorItemId &&
          dataItem.validation === "No" &&
          validationItem.isValidated === "Yes"
        ) {
          dispatch(skillingUpdate({ id: dataItem.id, validation: "Yes" }));
        } else if (
          dataItem.id === validationItem.requestorItemId &&
          validationItem.isValidated === "Yes"
        ) {
          setIsValidationResult(true);
        } else {
          setIsValidationResult(false);
        }
      });
    });
  }, [data, userValidationData, dispatch]);

  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      <AttachmentForm />
      {/* DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfileConfandSkilling />}
      <DeleteFormDetailedProfile />
      {/* table start */}
      <div className="table-responsive ">
        <table className="table table-sm   table-fixed table-hover    ">
          <thead>
            <tr className="border-dark-subtle border-bottom ">
              <th scope="col" className="bg-body- " style={{ width: "32%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "Skilling"
                  ) || {}
                ).mvalue || "nf Skilling"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "19%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectOrganization"
                  ) || {}
                ).mvalue || "nf Organization"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectDuration"
                  ) || {}
                ).mvalue || "nf duration"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectLocation"
                  ) || {}
                ).mvalue || "nf Location"}{" "}
              </th>
              <th scope="col" style={{ width: "12%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectValidation"
                  ) || {}
                ).mvalue || "nf Validation"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "10%" }}>
                {" "}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data?.data?.map((certs, index) => (
              <tr className="">
                <td>
                  {certs.title
                    ? certs.title.length > 17
                      ? certs.title.substring(0, 17) + "..."
                      : certs.title
                    : ""}
                </td>
                <td>
                  {certs.organization
                    ? certs.organization.length > 12
                      ? certs.organization.substring(0, 12) + "..."
                      : certs.organization
                    : ""}
                </td>
                <td>{DayDifferenceToDynamicView(certs.duration)}</td>
                <td>
                  {certs.location
                    ? certs.location.length > 12
                      ? certs.location.substring(0, 12) + "..."
                      : certs.location
                    : ""}
                </td>
                <td className=" ">
                  {certs.validation || (isValidationResult ? "Yes" : "No")}
                </td>
                <td
                  className="d-flex justify-content-between   "
                  style={{ minHeight: "26px" }}
                >
                  <>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Validate"
                      data-bs-toggle="modal"
                      data-bs-target="#validationForm"
                      onClick={() => handleValidate(certs)}
                    >
                      <img
                        src={validationIcon}
                        alt=""
                        style={{ width: "1rem", height: "1rem" }}
                      />
                    </button>
                    <button
                      className="border-0 bg-white"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Attachment"
                      onClick={() => handleAttachment(certs)}
                      data-bs-toggle="modal"
                      data-bs-target="#attachmentList"
                    >
                      <ImAttachment />
                    </button>
                  </>
                  {editEnable && (
                    <>
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#detailedProfileEditConfandSkillingModal"
                        id="addModalBtn"
                      >
                        <button
                          className="border-0 bg-white"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Edit"
                          onClick={() => handleEdit(certs)}
                        >
                          <MdEdit />
                        </button>
                      </div>
                      <ShowHideIcon
                        value={certs.recordHide === "Yes" ? true : false}
                        toggleable={true}
                        form={certs}
                        fieldName={"recordHide"}
                        api={"Skills Applied"}
                        id={certs.id}
                      />
                      <Anonimization
                        value={certs.recordAnonymous === "Yes" ? true : false}
                        toggleable={true}
                        form={certs}
                        fieldName={"recordAnonymous"}
                        api={"Skills Applied"}
                        id={certs.id}
                      />
                      <button
                        className="border-0 bg-transparent"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Delete"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteformdetailedprofile"
                        onClick={() => {
                          dispatch(setDeleteDetailedProfileData(certs));
                        }}
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* table end */}
    </>
  );
};

export default SkillingsSummary;
