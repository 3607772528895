import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import UserAccountDetailsApi from "../api/auth/UserAccountDetailsApi";
import { useDispatch } from "react-redux";
import { setUserAccountDetails } from "../reducer/userDetails/UserAccountDetailsSlice";
import Loader from "../components/Loader";

export function AuthGuard({ allowedRoles }) {
  const token = localStorage.getItem("token");
  const userAccountDetails = useSelector((state) => state.UserAccountDetails);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userAccountDetails.status === "idle") {
      const body = {
        accountId: localStorage.getItem("userName"),
      };

      UserAccountDetailsApi(body)
        .then((res) => {
          console.log("response for user account details", res);
          dispatch(setUserAccountDetails(res.data));
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountDetails.status]);

  const roles = useMemo(() => {
    const rolesArray = [];
    if (userAccountDetails.data && userAccountDetails.data.account) {
      userAccountDetails.data.account.roles.forEach((role) => {
        rolesArray.push(role.roleName);
      });
    }
    return rolesArray;
  }, [userAccountDetails.data]);

  console.log("userAccountDetails", roles);

  if (isLoading) {
    // Return a loading indicator or wait until userAccountDetails are loaded
    return <Loader />;
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (roles.length === 0) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles.find((role) => roles.includes(role))) {
    // If the user has the allowed role, render the child components
    return <Outlet />;
  } else {
    // If the user does not have the allowed role, redirect to 404 page
    return <Navigate to="/404" />;
  }
}
