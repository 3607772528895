import React, { useEffect, useState } from 'react'
import user from "../Images/avatar-placeholder.webp"
import { useSelector } from 'react-redux';
import logo from "../Images/logo.png"
import { formatTimestampToDate } from './SkillOwner/HelperFunction/FormatTimestampToDate';
import { DayDifferenceToDynamicView } from './SkillOwner/HelperFunction/DayDifferenceToDynamicView';
import { calculateDaysDifference } from './SkillOwner/HelperFunction/CalculateDaysDifference ';
import { GetUserForSelectedLanguage } from './SkillOwner/HelperFunction/GetUserForSelectedLanguage';
import { BASE_URL } from '../config/Properties';
import ValidationDataComponent from './SkillOwner/SelectComponent/ValidationDataComponent';
import { GetAttachment } from '../api/Attachment  API/DownloadAttachmentApi';


const Resume = () => {

  // set date alone
  const [timestamp, setTimestamp] = useState();
  const selectedLanguage = useSelector(state => state.language);
  const regionalData = useSelector(state => state.regionalData);
  const [profilePicObj, setProfilePicObj] = useState({});
  // const [timestamp, setTimestamp] = useState(new Date().toLocaleDateString(`${localStorage.getItem("countryCode")}`));
  const userDetails = useSelector(state => GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage));

  function splitStringToObject(str) {
    try {
      const parts = str.split('||').map(part => part.trim());
      const obj = {};
      parts?.forEach(part => {
        const [key, value] = part.split('=').map(item => item.trim());
        obj[key] = value;
      });
      return obj;
    } catch (error) {
      console.error("Error occurred while parsing the string:", error.message);
      return {}; //Return an empty object in case of failure
    }
  }

  useEffect(() => {
    setProfilePicObj(splitStringToObject(userDetails?.profilePictureFileName));
  }, [userDetails?.profilePictureFileName])


  useEffect(() => {
    setTimestamp(formatTimestampToDate(new Date().getTime(), regionalData.selectedCountry.dateFormat));
  }, []);

  useEffect(() => {
    // Find all elements with the class name "ktt10Buttons chat bot"
    const ktt10Buttons = document.querySelectorAll('.ktt10-btn');

    // add these classname d-print-none to hide in print
    ktt10Buttons?.forEach((button) => {
      button.classList.add('no-print');
    });

  }, []);

  // store data from redux
  const SkillsApplied = useSelector(state => state.SkillsApplied);
  const SkillsAcquired = useSelector(state => state.SkillsAcquired.data);
  const content = useSelector(state => state.content);
  const SkillSelected = useSelector(state => state.SkillSelected);
  const token = localStorage.getItem("token");

  const [skillAppliedCount, setSkillAppliedCount] = useState(0);
  const [skillAcquiredCount, setSkillAcquiredCount] = useState(0);

  useEffect(() => {
    SkillsApplied?.data?.map((skill, ind) => skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title && setSkillAppliedCount(skillAppliedCount + 1));
    SkillsAcquired?.data?.map((skill, ind) => skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title && setSkillAcquiredCount(skillAcquiredCount + 1));

  }, [SkillSelected])




  useEffect(() => {
    // Function to update page number
    const updatePageNumber = () => {
      const pageCount = document.getElementsByClassName('page-number');
      for (let i = 0; i < pageCount.length; i++) {
        pageCount[i].innerText = i + 1;
      }
    };

    // Attach the updatePageNumber function to the 'beforeprint' event
    window.addEventListener('beforeprint', updatePageNumber);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('beforeprint', updatePageNumber);
    };
  }, []); // Run this effect only once on component mounst



  return (
    <div className="   font-5  " style={{ backgroundColor: "red" }}  >

      <header className='mb-3 bg-success '  >

        <div id="header" style={{ marginBottom: '20px', backgroundColor: "red" }} >
          <div className='d-flex justify-content-between  ' style={{ order: "-1" }} >
            <a className="navbar-brand d-flex align-items-center  px-0 mx-0 py-0 my-0 " href="https://www.myskillstree.com/">
              <img src={logo} alt="Logo" width="38" height="38" className="d-inline-block bg-img" />
              <div className='px-1 font-weight-1  font-1 font-weight-1 ms-1   ' >{(content[selectedLanguage].find(item => item.elementLabel === 'MySkillss') || {}).mvalue || "MySkillsTree"}</div>
            </a>

            <div className='d-flex flex-column justify-content-end ' style={{ order: "1" }} > <div className='font-6'>Date: {timestamp}</div></div>
          </div>
          <div className='border-bottom border-1 border-dark  mt-1 mb-4 '></div>
        </div>
        <div className='mb-4'>&nbsp;</div>
      </header>

      <table className='paging w-100' style={{ width: "100%" }} ><thead className='' style={{ width: "100%" }} ><tr style={{ width: "100%" }} ><td style={{ width: "100%" }}>&nbsp;</td></tr></thead><tbody style={{ width: "100%" }}><tr style={{ width: "100%" }}><td style={{ width: "100%" }}>

        <div className='mx-2 ' style={{ backgroundColor: "red" }}>
          {/* second header user details */}

          <div className=''>
            <div className='font-3   d-flex flex-column  '   >
              <div className='font-weight-2 '>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillProfile') || {}).mvalue || "nf Skill Profile"} </div>
              <div className='my-2 mx-3 font-weight-2 font-15px'> {SkillSelected.skill} </div>

            </div>
          </div>

          <div class="">
            <div class=" ">
              <div class="    ">
                <div class="">
                  <form>
                    <div class="row mt-2  align-items-center">
                      {userDetails?.profilePictureFileName && userDetails.profilePictureFileName?.length > 0 &&
                        <div class="col-2 text-center mb-5">
                          <div class="avatar avatar-xl">
                            <img height="120" width="120" src={userDetails?.profilePictureFileName && userDetails?.profilePictureFileName?.length > 1 ? GetAttachment(userDetails?.id, profilePicObj?.fileName, profilePicObj?.fileId) : user}
                              alt="..." class="avatar-img rounded-circle   " />
                          </div>
                        </div>
                      }
                      <div class="col">
                        <div class="row align-items-center">
                          <div class="col-7">
                            <div class="mb-1 mt-2 font-3 font-weight-2">
                            {(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf Name"} :   {(userDetails.fn !== 'Yes'
                                &&
                                userDetails.mlnShowHide !== 'Yes') ?
                                userDetails.firstName + " " + userDetails.lastName
                                : userDetails.fn !== 'Yes' && userDetails.mlnShowHide === 'No'
                                  ? userDetails.lastName
                                  : userDetails.fn === 'No' && userDetails.mlnShowHide !== 'Yes' ? userDetails.firstName : 'CONFIDENTIAL'}
                            </div>
                            <p class=" mb-3"><span class="   ">{
                              userDetails.cityShowHide !== 'Yes' && userDetails.city}
                              {userDetails.stateShowHide !== 'Yes' && ", " + userDetails.userState}</span></p>
                          </div>
                          <div class="col-5 ">
                            <p class="small mb-0 text-muted">{userDetails.organization}</p>
                            <p class="small mb-0 text-muted"> {
                              userDetails.cityShowHide !== 'Yes' && userDetails.city}
                              {userDetails.stateShowHide !== 'Yes' && ", " + userDetails.userState}</p>
                            <p class="small mb-0 text-muted">{(content[selectedLanguage].find(item => item.elementLabel === 'Email') || {}).mvalue || "nf Email"} : {userDetails.memailShowHide !== 'Yes' ? userDetails.email : "CONFIDENTIAL"}</p>
                            <p class="small mb-0 text-muted">{(content[selectedLanguage].find(item => item.elementLabel === 'Phone') || {}).mvalue || "nf Phone"} :
                              {userDetails.mnShowHide
                                !== 'Yes' ? userDetails.mobileNumber : "CONFIDENTIAL"}</p>
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-7">
                            <p class="text-muted  ">
                              {userDetails.about}
                            </p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>

          <div className='border-bottom border-1 border-secondary  mt-1  '></div>

          <div className=" mt-1  " id=""  >
            <div className="border-0  mb-2 rounded-top  ">

              <div >
                <div >
                  {/* summary data */}
                  <div className='font-3 font-weight-2'>{(content[selectedLanguage].find(item => item.elementLabel === 'SummaryView') || {}).mvalue || "nf SummaryView"}</div>
                  <div className='ms-3'>

                    {skillAppliedCount > 0 &&
                      <div>
                        <div className='my-2  font-weight-2 font-15px'>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsAppliedTitle') || {}).mvalue || "nf Skills Applied Title"}:</div>
                        <div className="  ">


                          {/* table start */}

                          <div className="table-responsive mx-3 ">
                            <table className='table table-sm   table-fixed table-hover    '>
                              <thead >
                                <tr className=''>
                                  <th scope="col" className='bg-body- ' style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectTitle') || {}).mvalue || "nf Project Title"}</th>
                                  <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}</th>
                                  <th scope="col" className='bg-body- ' style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf ProjectType"}</th>
                                  <th scope="col" className='bg-body- ' style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                  <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>
                                </tr>
                              </thead>

                              <tbody className=''>


                                {SkillsApplied.data.map((skill, index) => (
                                  skill.recordHide === 'Yes' ?
                                    <></>
                                    :
                                    <>

                                      {skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title &&
                                        <tr className='mt-1' key={index}>
                                          <td >{skill.projectActivity}</td>
                                          <td>{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</td>
                                          <td >{skill.mtype}</td>
                                          <td>{skill.location}</td>
                                          <td >{skill.validation==="Yes" ? "Yes" : "No"}</td>
                                        </tr>}
                                    </>
                                ))



                                }
                              </tbody>
                            </table>
                          </div>
                          {/* table end */}

                        </div>
                      </div>
                    }


                    <div>
                      <div className='my-2  font-weight-2 font-15px'>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsAcquiredTitle') || {}).mvalue || "nf Skill Acquired Title"}</div>
                      <div className="  ">

                        {/* table start */}

                        <div className="table-responsive mx-3  ">
                          <table className='table table-sm   table-fixed table-hover    '>
                            <thead >
                              <tr className=''>
                                <th scope="col" style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf Name"}</th>
                                <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}</th>
                                <th scope="col" style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'Type') || {}).mvalue || "nf Type"}</th>
                                <th scope="col" style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>
                              </tr>
                            </thead>

                            <tbody className=''>

                              {SkillsAcquired.map((skill, index) => (
                                skill.recordHide === 'Yes' ?
                                  <></>
                                  :
                                  <>
                                    {skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title &&
                                      <tr className='' key={index}>
                                        <td style={{ width: "34%" }} >{skill.source}</td>
                                        <td style={{ width: "13%" }} >{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))} </td>
                                        <td style={{ width: "17%" }}>{skill.mtype}</td>
                                        <td style={{ width: "15%" }}>{skill.location}</td>
                                        <td style={{ width: "12%" }}>{skill.validation==="Yes" ? "Yes" : "No"}</td>
                                      </tr>
                                    }
                                  </>
                              ))}

                            </tbody>
                          </table>
                        </div>

                        {/* table end */}

                      </div>
                    </div>

                  </div>

                  <div className='page-break-before' >

                    {/* Detailed detail view  Skill Applied or Used at */}
                    <div className='font-3 font-weight-2'>{(content[selectedLanguage].find(item => item.elementLabel === 'DetailsView') || {}).mvalue || "nf Skill Detailed View "}</div>

                    <div className='ms-3 '>
                      {
                        skillAppliedCount > 0 && <div className=" ">

                          <div className='my-2  font-weight-2 font-15px '>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsAppliedTitle') || {}).mvalue || "nf Skill Applied Title"}</div>
                          {/* table start */}
                          <div className="table-responsive mx-3 " >
                            <table className='table table-sm  table-borderless     table-fixed     '>
                              <tr className='border-bottom '>
                                <th scope="col" className='bg-body- ' style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectTitle') || {}).mvalue || "nf Projects"}</th>
                                <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration "}</th>
                                <th scope="col" className='bg-body- ' style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf type"}</th>
                                <th scope="col" className='bg-body- ' style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                <th scope="col" style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>

                              </tr>



                            </table>


                            {SkillsApplied.data.map((skill, index) => (
                              skill.recordHide === 'Yes' ?
                                <></>
                                :
                                <>
                                  {skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title &&
                                    <div className='border-bottom    ' key={index}>
                                      <div className='d-flex   '>
                                        <div className='  ' style={{ width: "38%" }}>{skill.projectActivity}</div>
                                        <div style={{ width: "13%" }}>{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</div>
                                        <div className='    ' style={{ width: "20%" }}>{skill.mtype}</div>
                                        <div className='    ' style={{ width: "17%" }}>{skill.location}</div>
                                        <div className=' ' style={{ width: "12%" }}>{skill.validation==="Yes" ? "Yes" : "No"}</div>

                                      </div>
                                      <div className='my-2 mx-3  '>
                                        <div className='d-flex align-items-center flex-row   '>
                                          <div className='d-flex   ' style={{ width: "50%" }} > <b>{(content[selectedLanguage].find(item => item.elementLabel === 'Organization') || {}).mvalue || "nf Organization"}:</b>
                                            <div>&nbsp;{skill.recordAnonymous === 'Yes' ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : skill.organization}</div>
                                          </div>
                                          <div className='d-flex  ps-lg-0  mt-1 mt-lg-0  ' style={{ width: "20%" }}>
                                            <b>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"}&nbsp;:&nbsp;</b>
                                            <div>{formatTimestampToDate((skill.fromDate), regionalData.selectedCountry.dateFormat)}</div>
                                          </div>
                                          <div className='d-flex  ps-lg-0    ' style={{ width: "22%" }}>
                                            <b>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"}&nbsp;:&nbsp;</b>
                                            <div>{skill.toDate ? formatTimestampToDate((skill.toDate), regionalData.selectedCountry.dateFormat) : "On-going"}</div>
                                          </div>
                                        </div>

                                        <div className='pb-1 pt-2   '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nd BriefDescription"}:</b> </div>
                                        <div className=' mx-3 w-90'> {skill.briefDescriptions}</div>

                                        <ValidationDataComponent itemId={skill.id} res={true} />



                                      </div>
                                    </div>
                                  }
                                </>
                            ))}
                          </div>
                          {/* table end */}
                        </div>
                      }


                      <div class="page-break-before" >
                        {/* Skill Acquired DETAIL  DETAILED */}
                        <div className='my-2 mt-3  font-weight-2 font-15px'>{(content[selectedLanguage].find(item => item.elementLabel === 'SkillsAcquiredTitle') || {}).mvalue || "nf Skill Acquried Title"}</div>

                        {/* table start */}
                        <div className="table-responsive ms-3">
                          <table className='table table-sm  table-borderless     table-fixed     '>
                            <tr className='border-bottom '>
                              <th scope="col" style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf Name"}</th>
                              <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration"}</th>
                              <th scope="col" style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf ProjectType"}</th>
                              <th scope="col" style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                              <th scope="col" className='' style={{ width: "12%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>

                            </tr>

                          </table>

                          {SkillsAcquired.map((skill, index) => (
                            skill.recordHide === 'Yes' ?
                              <></>
                              :
                              <>
                                {skill.mlanguage === selectedLanguage && SkillSelected.skillOccupation === skill.title &&
                                  <div className='border-bottom pt-2 ' key={index}>
                                    <div className='d-flex   '>
                                      <div className='  ' style={{ width: "38%" }}>{skill.source}</div>
                                      <div style={{ width: "13%" }}>{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</div>
                                      <div className='    ' style={{ width: "20%" }}>{skill.mtype}</div>
                                      <div className='    ' style={{ width: "17%" }}>{skill.location}</div>
                                      <div className='ps-2 ' style={{ width: "12%" }}>{skill.validation==="Yes" ? "Yes" : "No"}</div>

                                    </div>
                                    <div className='my-3 mx-3  '>

                                      <div className='d-flex align-items-center  '>

                                        <div className='d-flex ' style={{ width: "64%" }} ><b>{skill.mtype !== "Education" ?
                                          (content[selectedLanguage].find(item => item.elementLabel === 'ProjectOrganization') || {}).mvalue || "not found" :
                                          (content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "not found"}:</b>

                                          <div>&nbsp;{skill.recordAnonymous === 'Yes' ? 'CONFIDENTIAL' : skill.organization}</div>
                                        </div>
                                        <div className='d-flex  ps-lg-0  mt-1 mt-lg-0  ' style={{ width: "20%" }}>
                                          <b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjecStart') || {}).mvalue || "not found"}:</b>
                                          <div>{formatTimestampToDate(Number(skill.fromDate), regionalData.selectedCountry.dateFormat)}</div>
                                        </div>
                                        <div className='d-flex  ps-lg-0    ' style={{ width: "22%" }}>
                                          <b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectEnd') || {}).mvalue || "not found"}:</b>
                                          <div>{formatTimestampToDate(Number(skill.toDate), regionalData.selectedCountry.dateFormat)}</div>
                                        </div>
                                      </div>

                                      <div className='pb-1 pt-2  '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"}:</b> </div>
                                      <div className='mx-3 w-90 '>
                                        {skill.briefDescriptions}
                                      </div>
                                      {
                                        <ValidationDataComponent itemId={skill.id} res={true} />
                                      }

                                    </div>

                                  </div>
                                }
                              </>
                          ))}

                        </div>
                      </div>
                      {/* table end */}

                    </div>

                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>

      </td></tr></tbody><tfoot><tr><td>&nbsp;</td></tr></tfoot></table>

      <footer>
        <div className='border-bottom border-1 border-dark  '></div>
        {/* Footer */}
        <div id="footer" className='d-flex justify-content-between ' >

          <div>
            <p>&copy; Copyright MyST, 2023</p>
          </div>
          <div  >
            <p>Powered by MyST</p>
          </div>
        </div>
      </footer>


    </div>
  )
}

export default Resume

