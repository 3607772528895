import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const SignupApi = async (data) => {
  return axios
    .post(`${BASE_URL}/skill/api/v1/skills/sign-up`, data)
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default SignupApi;
