import React, { useEffect, useRef, useState } from 'react'
import NavbarA from '../../components/SkillAvailer/NavbarA'
import RightSideBarSA from '../../components/RightSideBarSA'
import Footer from '../../components/Footer'
import SecondaryBtn from '../../components/Buttons/SecondaryBtn'
import { useSelector } from 'react-redux';
import PremiumServicesOptionsAvailor from '../../components/PremiumServicesOptionsAvailor'
import { useLocation } from 'react-router-dom'
import SkillingAgencyNavbar from '../../components/SkillingAgency/DashboardComponents/SkillingAgencyNavbar'
import CreateSelectInRequirements from '../../components/SkillAvailer/CreateSelectInRequirements'
import { useDispatch } from 'react-redux';
import { showWarningToast } from '../../components/ToastNotification/showWarningToast';
import { fetchUsersBasedOnSkillSearch } from '../../api/SkillSeeker/fetchUsersBasedOnSkillSearch'
import { emptyMyReqSkills, setMyReqSkills } from '../../reducer/SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice'
import { setMyRefinedLocations, setMyRefinedSkills } from '../../reducer/SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice'
import Loader from '../../components/Loader'
import { ThreeDots } from 'react-loader-spinner'
import { fetchCountryRegional } from '../../api/content/fetchRegionalCountries'
import { debouncedSendRequest } from '../../components/DebounceHelperFunction/debouncedSendRequest';
import { MdDeleteSweep } from "react-icons/md";

const LeftPanelSeeker = ({ contentHeight }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const skillSuggestionRef = useRef(null);

    //auto hides popups
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                skillSuggestionRef.current && !skillSuggestionRef.current.contains(event.target)
            ) {
                setSkillSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [skillSuggestionRef]);

    //store imports




    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);

    const [locationForNewEmployment, setLocationForNewEmployment] = useState("");
    //search results
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);
    //my requiremnet
    const MyRequirement = useSelector(state => state.MyRequirement);
    //refine my req
    const RefMyRequirements = useSelector(state => state.RefMyRequirements);
    //Regional country Data
    const regionalData = useSelector(state => state.regionalData);


    const [searchMode, setSearchMode] = useState(1);
    const [selectedMode, setSelectedMode] = useState(0);

    useEffect(() => {
        console.log("ssssssss ", locationForNewEmployment);

    }, [locationForNewEmployment])



    //occupation skills list right bar
    const [occupationRightBox, setOccupationRightBox] = useState([]);
    const [suggestionLoader, setSuggestionLoader] = useState(false);

    const [isFocused, setIsFocused] = useState(false);
    const [exportOptions, setExportOptions] = useState(false);


    const [jobTitle, setJobTitle] = useState('');
    const [skillObj, setSkillObj] = useState({});


    //end refine to selectedRef
    const [SelectedRefSkillFilter, setSelectedRefSkillFilter] = useState([]);

    //my reqirements section open close 
    const [collapsedMRSection, setCollapsedMRSection] = useState(false);
    const [collapseRefineAccordian, setCollapseRefineAccordian] = useState(true);
    //Suggestions inside skill search
    const [SkillSuggestions, setSkillSuggestions] = useState(false);
    //my req add skill

    //To select data from autosuggestion
    const handleAddASkillSg = (jobTitle) => {
        //  dispatch(fetchCountryRegional());
        console.log("ee ", jobTitle);
        if (!MyRequirement?.skillsInMyReq?.some(skill => skill.skillOccupation === jobTitle.skillOccupation)) {
            if (jobTitle) {

                dispatch(setMyReqSkills([...MyRequirement?.skillsInMyReq,
                {
                    experienceReq: false,
                    minExp: 0,
                    maxExp: 0,
                    range: "year",
                    required: false,
                    validated: false,
                    TopSkill: false,
                    edit: false,

                    show: true,
                    label: jobTitle.skillOccupation,
                    value: jobTitle.skillOccupation,
                    ...jobTitle,
                }]))

                setJobTitle("");
                setSkillObj({});
                setSkillSuggestions([]);
            } else {
                showWarningToast("No skills are selected");
            }
        } else {
            showWarningToast("Skill Already added");
        }

    }

    // auto suggestion for skill
    const handleChangeSkill = (e) => {
        const val = e.target.value;
        setJobTitle(val);
        // if value greater than 2 then query the database and get the suggestions
        if (val.length > 2) {
            setSkillSuggestions([]);
            setSuggestionLoader(true);
            debouncedSendRequest(e.target.value, selectedLanguage, setSkillSuggestions, setSuggestionLoader);
        } else {
            setSkillSuggestions([]);
        }
    }

    //To remove all skills in my requirments
    const removeAllMyReq = (e) => {
        //console.log("val ", e.target.checked);
        dispatch(emptyMyReqSkills());
    }


    const handleRemoveListOfSkill = (e) => {
        console.log(e.target);
        // remove from list of skills
        const updatedList = MyRequirement?.skillsInMyReq?.filter((skill, index) => index !== parseInt(e.target.id))
        dispatch(setMyReqSkills(updatedList));
    }




    //api body upadter for my req
    const apiBodyUpdate = () => {
        return new Promise((resolve) => {
            // const mandatorySkills = [];
            // const optionalSkills = [];
            const skills = [];
            let isMandatoryFlag = false;
            //const location = [];
            if (searchMode === 2)
                MyRequirement?.skillsInMyReq?.forEach((skill) => {
                    isMandatoryFlag = true;
                    if (skill.show === true) {

                        skills.push(skill.label);
                        isMandatoryFlag = true;

                    }
                });
            else {
                MyRequirement?.skillsInMyReq?.forEach((skill) => {
                    if (skill.show === true) {

                        skills.push(skill.label);

                    }
                });
            }
            // MyRequirement?.locationsInMyReq?.forEach((loc) => {
            //     location.push(loc.value);
            // })
            const bod = {
                //mandatorySkills: mandatorySkills,
                //optionalSkills: optionalSkills,
                skills: skills,
                isMandatoryFlag: isMandatoryFlag
                // location: location,
            };
            console.log("bod ", bod);
            resolve(bod);
        });
    };
    const daysConvertor = (range, number) => {

        if (range === "year") {
            return number * 365;
        } else if (range === "month") {
            return number * 30;
        } else if (range === "week") {
            return number * 7;
        }
    }
    //api body upadter for refine my req
    const apiBodyUpdateForRef = () => {
        return new Promise((resolve) => {
            const mandatorySkills = [];
            const optionalSkills = [];
            const location = [];
            const skills = [];
            let isMandatoryFlag = false;
            if (selectedMode === 2)
                RefMyRequirements?.skillsInRefined?.forEach((skill) => {

                    if (skill.show === true) {
                        isMandatoryFlag = true;
                        skills.push({
                            skill: skill.skillOccupation,
                            topRank: skill.TopSkill ? "Yes" : "No",
                            validated: skill.validated ? "Yes" : "No",
                            exp_req: skill.experienceReq ? "Yes" : "No",
                            exp_min: daysConvertor(skill.range, skill.minExp),
                            exp_max: daysConvertor(skill.range, skill.maxExp)
                        });

                    }
                });
            else {
                RefMyRequirements?.skillsInRefined?.forEach((skill) => {

                    if (skill.show === true) {

                        skills.push({
                            skill: skill.skillOccupation,
                            topRank: skill.TopSkill ? "Yes" : "No",
                            validated: skill.validated ? "Yes" : "No",
                            exp_req: skill.experienceReq ? "Yes" : "No",
                            exp_min: daysConvertor(skill.range, skill.minExp),
                            exp_max: daysConvertor(skill.range, skill.maxExp)
                        });

                    }
                });

            }

            RefMyRequirements?.locationsInRefined?.forEach((loc) => {
                location.push(loc.value);
            })
            const bod = {
                // mandatorySkills: mandatorySkills,
                // optionalSkills: optionalSkills,
                skills: skills,
                isMandatoryFlag: isMandatoryFlag,
                location: location,
            };
            console.log("bod ", bod);
            resolve(bod);
        });
    };

    //function copies my req skill list to refine skill list
    const updateSkillFilter = () => {

        const updatedSkillFilterRef = MyRequirement?.skillsInMyReq?.map(skill => ({
            ...skill,
            value: skill.skillOccupation,
            label: skill.skillOccupation,
            // Add other properties as needed
        }));
        const updatedLocationFilterRef = MyRequirement?.MyRequirement?.map(location => ({
            value: location.value,
            label: location.value,
            show: true,
            // Add other properties as needed
        }));

        dispatch(setMyRefinedLocations(updatedLocationFilterRef));
        dispatch(setMyRefinedSkills(updatedSkillFilterRef));

    };

    //clicking on search button
    const handleSkillSearch = async () => {
        console.log(regionalData?.listOfCountries);
        let bod = { body: "", title: "getUserIdsBasedOnSkills", start: 0, size: 50, sortField: "createdTime", sortOrder: "desc" };
        if (MyRequirement?.skillsInMyReq?.length === 0) {
            showWarningToast("Please Enter a skill");
            return;

        }
        try {
            bod.body = await apiBodyUpdate();


            //api caller
            dispatch(fetchUsersBasedOnSkillSearch(bod)).then((res) => {
                const collapseTwo = document.getElementById('panelsStayOpen-collapseTwo');
                setCollapsedMRSection(true);
                setCollapseRefineAccordian(false);
                updateSkillFilter();
                setSelectedRefSkillFilter(
                    MyRequirement?.skillsInMyReq?.map((skill) => ({
                        ...skill,
                        value: skill?.skillOccupation,
                        label: skill?.skillOccupation,
                        // Add other properties as needed
                    }))
                );
                collapseTwo.classList.remove('collapse')
                collapseTwo.classList.add('show');
                setSelectedMode(searchMode);

            });

        } catch (error) {
            console.error('Error:', error);
        }
    };
    // go button handler 
    const handleGo = async () => {
        let bod = { body: "", title: "getUserIdsBasedOnSkillsWithConditions", start: 1, size: 50, sortField: "createdTime", sortOrder: "desc" };
        let expCheck = RefMyRequirements.skillsInRefined.map(item => {
            if (item.experienceReq && item.maxExp <= item.minExp) {
                showWarningToast("Expirience Mismatch");
                return 1;
            }
        })
        if (expCheck != 1) {
            try {
                setSelectedRefSkillFilter(
                    RefMyRequirements?.skillsInRefined?.map((skill) => ({
                        ...skill,
                        value: skill.skillOccupation,
                        label: skill.skillOccupation,
                        // Add other properties as needed
                    }))
                );

                bod.body = await apiBodyUpdateForRef();

                //api caller    
                dispatch(fetchUsersBasedOnSkillSearch(bod));
            } catch (error) {
                console.error('Error:', error);
            }
        }


    };

    useEffect(() => {
        console.log("ref my req ", RefMyRequirements?.skillsInRefined);
    }, [RefMyRequirements?.skillsInRefined])

    useEffect(() => {
        console.log("ref my req loc", RefMyRequirements?.locationsInRefined);
    }, [RefMyRequirements?.locationsInRefined])







    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const isCheckedValidation = false; // 'false' is the default value



    const handleRemoveListOfMyReqSkill = (e) => {
        console.log(e.target.id);
        // remove from list of skills

        //setRefineSkillFilter(RefineSkillFilter.map((item, i) => i === parseInt(e.target.id) ? { ...item, show: !item.show } : item));

        dispatch(setMyRefinedSkills(MyRequirement?.skillsInMyReq?.map((item, i) => i === parseInt(e.target.id) ? { ...item, show: !item.show } : item)));
    }


    const selectedValue = '0'; // '0' is the default value


    //occupation skill populate

    const occSkillPopulate = () => {
        if (MyRequirement?.skillsInMyReq?.length > 0) {
            setOccupationRightBox(MyRequirement?.skillsInMyReq);
        }
    }
    return (
        <div className=" px-1 col-lg font-5 fixed-sidebar    rounded " style={{ height: contentHeight, overflow: "auto", position: "relative", backgroundColor: "#E7E7E7" }} >




            <div class="accordion accordion-flush mb-5 mt-1" id="accordionPanelsStayOpenExample">
                <div class="accordion-item  " style={{ border: "solid 4px #DEDEDE" }}>

                    <div class="text-center">
                        <h2 class="accordion-header  text-center " >


                            <button class="accordion-button rounded-top-2 font-5  text-center d-flex justify-content-center " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne"
                                onClick={() => setCollapsedMRSection(false)}
                                style={{

                                    backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#fff",
                                    color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#000"
                                }} >
                                <label >{(content[selectedLanguage].find(item => item.elementLabel === 'MyRequirements') || {}).mvalue || "nf My Requirements"}</label>
                            </button>
                        </h2>
                    </div>



                    {/* Version 2 new one */}
                    <div id="panelsStayOpen-collapseOne" class={collapsedMRSection ? "accordion-collapse collapse" : "accordion-collapse show"} style={{ overflow: 'hidden' }}>



                        <div class="accordion-body px-0 me-2 ms-1" style={{ top: "0px", marginTop: "-10px" }}>

                            <>
                                <div className="input-group   mx-1  " style={{ height: "40px" }}>




                                    <input className="form-control h-75 w-75 bg-body-tertiary font-6 " type="text" style={{ width: "10px" }}
                                        placeholder={isFocused ? '' : (content[selectedLanguage].find(item => item.elementLabel === 'EnterASkillAndClick+') || {}).mvalue || "nf Enter a Skill and click + "}
                                        onFocus={handleFocus} onBlur={handleBlur}
                                        onChange={(e) => handleChangeSkill(e)} value={jobTitle} />


                                </div>
                                {/* suggestion Loader */}
                                <div className='d-flex   '>
                                    {suggestionLoader && <ThreeDots width={"30"} height={"10"} />}
                                </div>

                                {/* skill suggestion  dropdown*/}

                                <>
                                    {SkillSuggestions.length > 0 && (
                                        <div ref={skillSuggestionRef} className='dropdown-menu table-responsive d-flex font-5 my-0 py-0 mx-1' style={{
                                            maxHeight: "130px",
                                            position: "absolute",
                                            zIndex: 999,
                                        }}>
                                            <table className='table table-sm d-flex table-hover px-0 mx-1 py-0' style={{ width: "100%" }}>
                                                <tbody className='font-5'>
                                                    {SkillSuggestions.map((suggestion, index) => (
                                                        <tr key={index} onClick={() => {
                                                            // handleSuggestionClick(suggestion)
                                                            handleAddASkillSg(suggestion)

                                                        }}>
                                                            <td>
                                                                {/* <span>{suggestion.skill}</span> || <span>{suggestion.occupation}</span> */}
                                                                <span>{suggestion.skillOccupation}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </>
                                {/* occupation btn */}
                                <div className='d-flex mb-2 font-5 '>


                                    <div className='d-flex justify-content-end w-100 '>
                                        <div className='' style={{ textDecoration: "underline" }}
                                            onClick={occSkillPopulate} type="button" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal" >{(content[selectedLanguage].find(item => item.elementLabel === 'AddSkillsBasedOcc') || {}).mvalue || "nf Add skills based on occupation"}
                                        </div>
                                    </div>

                                </div>


                                {MyRequirement?.skillsInMyReq?.length > 0 &&


                                    <div className='my-2 font-5' style={{ overflow: 'hidden' }}>
                                        <div className='d-flex justify-content-end mb-2 ' >
                                            <div className='d-flex align-items-center mx-1  ' style={{
                                                justifyContent: "flex-start", color: "#815F0B",
                                                fontWeight: "600"
                                            }}  >
                                                {(content[selectedLanguage].find(item => item.elementLabel === 'ClearAll') || {}).mvalue || "nf Clear all"}
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={removeAllMyReq}>
                                                <MdDeleteSweep style={{ width: "20px", height: "20px" }} />
                                            </div>

                                            {/* <div class="form-check form-switch d-flex align-items-center " style={{ borderColor: "#815F0B" }}>
                                            <input class="form-check-input" style={{ borderColor: "#815F0B" }}  type="checkbox"
                                                role="switch" id={"flexSwitchCheckChecked"} />
                                        </div> */}

                                        </div>
                                        <div className='table-responsive font-5' >

                                            <>
                                                <div className='mx-0 mb-2 d-flex flex-wrap      ' >
                                                    {MyRequirement?.skillsInMyReq?.length > 0 &&
                                                        MyRequirement?.skillsInMyReq?.map((skill, index) =>

                                                            <div key={index} className='mt-1  ' style={skill.show ? { overflow: 'hidden' } : { overflow: 'hidden', opacity: "0.4" }}>
                                                                <div className="badge mx-1 border-1 d-flex align-items-center bg-white " style={{
                                                                    border: "solid 1px #815F0B",
                                                                    color: "#815F0B", padding: "3px 6px",
                                                                    borderRadius: "7px", height: "35px"
                                                                }} id={index}>

                                                                    <div className=' font-6' data-toggle="tooltip" data-placement="top" title={skill.skillOccupation}
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: "ellipsis", whiteSpace: "nowrap",
                                                                            maxWidth: "100%"
                                                                        }} >{skill.skillOccupation}
                                                                    </div>

                                                                    <div class=" d-flex mx-2 ">
                                                                        <input class="" style={{ borderColor: "#815F0B" }} type="radio" checked={skill.show} onClick={(e) => dispatch(setMyReqSkills(MyRequirement?.skillsInMyReq?.map((item, i) => i === index ? { ...item, show: !item.show } : item)))} />

                                                                    </div>

                                                                    <div className=' align-items-baseline btn-close' onClick={handleRemoveListOfSkill} id={index}  ></div>

                                                                </div>



                                                            </div>
                                                        )}


                                                </div>

                                            </>


                                        </div>
                                    </div>

                                }


                                <div className=' d-flex '  >

                                    <div className=' d-flex align-self-center gap-1' style={{ marginRight: "auto" }} >
                                        <input type="radio" id="1" name="1" value="1" checked={searchMode === 1 ? true : false} onClick={() => setSearchMode(1)}  ></input> <label for="QuickSearch">{(content[selectedLanguage].find(item => item.elementLabel === 'QuickSearch') || {}).mvalue || "nf Quick Search"}</label>
                                        <input type="radio" id="2" name="2" value="2" checked={searchMode === 2 ? true : false} onClick={() => setSearchMode(2)}  ></input> <label for="ExactSearch">{(content[selectedLanguage].find(item => item.elementLabel === 'ExactSearch') || {}).mvalue || "nf Exact Search"}</label>
                                    </div>
                                    <div className='flex-end ' style={{ marginLeft: "auto" }}>
                                        <SecondaryBtn label={(content[selectedLanguage].find(item => item.elementLabel === 'Search') || {}).mvalue || "nf Search"} onClick={handleSkillSearch} />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                {/* Refine my requirement */}
                <div class="accordion-item rounded mt-2 " style={{ border: "solid 4px #DEDEDE" }} >
                    <h2 class="accordion-header rounded-top-2 ">
                        <button class=" accordion-button collapsed rounded-top-2 font-5" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo"
                            onClick={() => setCollapseRefineAccordian(!collapseRefineAccordian)}
                            style={{

                                display: "flex", justifyContent: "center", textAlign: "center",
                                backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#fff"
                            }} >
                            <label> {(content[selectedLanguage].find(item => item.elementLabel === 'RefineMyReq') || {}).mvalue || "nf Refine My Requirement"}</label>
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class={collapseRefineAccordian ? "accordion-collapse collapse" : "accordion-collapse show"}>
                        <div class="accordion-body px-0 font-5" >

                            {MyRequirement?.skillsInMyReq?.length !== 0 &&
                                <div className='mx-1 '>
                                    <div className='mb-2 ' style={{ width: "100%", overflow: "hidden" }}>
                                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Skills') || {}).mvalue || "nf Skills"}</label>

                                        <div className='mx-0 mb-2 d-flex ms-4 me-2 fw-bold  p-2 d-flex flex-column justify-content-center align-items-lg-start' >
                                            {RefMyRequirements?.skillsInRefined?.map((skill, index) =>
                                                skill.show === true &&

                                                <div className='   mx-1 border-1 d-flex  mb-2 font-6 ' style={{
                                                    overflow: "hidden",
                                                    border: "solid 1px #815F0B", color: "#815F0B",

                                                    borderRadius: "7px", minWidth: "85%", display: "block",
                                                }} id={index} key={index} >
                                                    <div id={index} className=' mx-2 d-flex  flex-column ' style={{ width: "210px", pointerEvents: skill.show ? "auto" : "none", opacity: skill.show ? "" : "50%" }}>
                                                        <div className='  d-flex align-items-center ' style={{ width: "100%", height: "20px" }} >
                                                            <div className='' style={{ overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "300px" }} data-toggle="tooltip" data-placement="top" title={skill.skillOccupation}>
                                                                {skill.skillOccupation}
                                                            </div>

                                                        </div>
                                                        <div className='d-flex  align-items-center gap-2 '>
                                                            <div className='d-flex align-items-center  '>
                                                                <div class="form-check form-switch d-flex align-items-center  ">
                                                                    <input class="form-check-input  "
                                                                        style={{ borderColor: "#815F0B" }} type="checkbox" role="switch"
                                                                        id="flexSwitchCheckChecked" checked={skill.TopSkill}
                                                                        onChange={(e) =>
                                                                            dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, TopSkill: !item.TopSkill } : item)))}
                                                                    />
                                                                    {/* setRefineSkillFilter(RefineSkillFilter.map((item, i) => i === index ? { ...item, validated: !item.validated } : item))} /> */}
                                                                </div>
                                                                <label className=' '>
                                                                    {(content[selectedLanguage].find(item => item.elementLabel === 'TopSkill') || {}).mvalue || "nf Top 5 Skill"}
                                                                </label>
                                                            </div>
                                                            <div className='d-flex align-items-center   '>
                                                                <div class="form-check form-switch d-flex align-items-center  ">
                                                                    <input class="form-check-input  " style={{ borderColor: "#815F0B" }} type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={skill.validated}
                                                                        onChange={(e) =>
                                                                            dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, validated: !item.validated } : item)))} />
                                                                    {/* setRefineSkillFilter(RefineSkillFilter.map((item, i) => i === index ? { ...item, validated: !item.validated } : item))} /> */}
                                                                </div>
                                                                <label className=' '>{(content[selectedLanguage].find(item => item.elementLabel === 'Validated') || {}).mvalue || "nf Validated"} </label>
                                                            </div>
                                                        </div>




                                                        <div className='d-flex flex-column  '>


                                                            <div className='d-flex' style={{ pointerEvents: skill.experienceReq ? "auto" : "none", opacity: skill.experienceReq ? "" : "50%" }}>
                                                                <div className=' d-flex  align-items-center'>
                                                                    <div className=''>{(content[selectedLanguage].find(item => item.elementLabel === 'Expirience') || {}).mvalue || "nf Exp"}: </div>
                                                                    <div className='me-2 mx-2'>
                                                                        <div className='mb-1'>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"}</div>
                                                                        <select name="minExp" id={index} value={skill.minExp} onChange={(e) =>
                                                                            dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, minExp: e.target.value } : item)))}>
                                                                            {Array.from({ length: 13 }, (_, i) => (
                                                                                <option key={i} value={i}>{i}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className='me-3'>
                                                                        <div className='mb-1'>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"}</div>
                                                                        <select name="maxExp" id={index} value={skill.maxExp} onChange={(e) =>
                                                                            dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, maxExp: e.target.value } : item)))}>
                                                                            {Array.from({ length: 13 }, (_, i) => (
                                                                                <option key={i} value={i}>{i}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className='mb-1'>{(content[selectedLanguage].find(item => item.elementLabel === 'Range') || {}).mvalue || "nf Range"}</div>
                                                                        <select name="range" id={index} value={skill.range} onChange={(e) =>
                                                                            dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, range: e.target.value } : item)))}>
                                                                            <option value="years">Years</option>
                                                                            <option value="week">wks</option>
                                                                            <option value="month">Month</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='d-flex align-items-center  ' >
                                                                    <div className='d-flex align-items-center  ' style={{ marginRight: "28px", }}>
                                                                        <div class="form-check form-switch d-flex align-items-center   ">
                                                                            <input class="form-check-input  " style={{ borderColor: "#815F0B" }} type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={skill.experienceReq}
                                                                                onChange={(e) =>
                                                                                    dispatch(setMyRefinedSkills(RefMyRequirements?.skillsInRefined?.map((item, i) => i === index ? { ...item, experienceReq: !item.experienceReq } : item)))} />
                                                                            {/* setRefineSkillFilter(RefineSkillFilter.map((item, i) => i === index ? { ...item, fresher: !item.fresher } : item))} /> */}
                                                                        </div>
                                                                        <label className=' '>{(content[selectedLanguage].find(item => item.elementLabel === 'IncludeExpirience') || {}).mvalue || "nf Include Expirience "}</label>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div class="mb-5  mx-1 d-flex  flex-column " style={{ height: "40px" }}>
                                        <div className='d-flex '>
                                            <div class="mb-1 ">{(content[selectedLanguage].find(item => item.elementLabel === 'Locations') || {}).mvalue || "nf Locations"}</div>
                                            <div className='align-items-center d-flex' style={{ marginLeft: "auto" }}>
                                                <input type='checkbox' id="remoteCheckbox"

                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        console.log("Online--- ", isChecked);
                                                        if (isChecked) {
                                                            const remoteLabelIndex = RefMyRequirements?.locationsInRefined?.findIndex((location) => location.label === "Online");
                                                            console.log("Online ", remoteLabelIndex);
                                                            // If it exists, remove it from the array
                                                            if (remoteLabelIndex === -1) {

                                                                if (RefMyRequirements.locationsInRefined && RefMyRequirements.locationsInRefined?.length > 0)
                                                                    dispatch(setMyRefinedLocations([...RefMyRequirements.locationsInRefined, { value: "Online", label: "Online" }]))
                                                                else
                                                                    dispatch(setMyRefinedLocations([{ value: "Online", label: "Online" }]))

                                                            }

                                                        } else {
                                                            const onlineRemover = RefMyRequirements?.locationsInRefined?.filter((location) => location.label !== "Online");
                                                            console.log("Online remove ", onlineRemover);
                                                            dispatch(setMyRefinedLocations(onlineRemover));
                                                        }
                                                    }}
                                                />
                                                <label className='mx-2 font-weight-5 '>{(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</label>
                                            </div>
                                        </div>
                                        <div style={{}}>

                                            <CreateSelectInRequirements setLocationDatas={setLocationForNewEmployment} />
                                        </div>
                                    </div>


                                    <div style={{ maxHeight: "10px" }} onClick={() => {
                                        setSelectedRefSkillFilter(RefMyRequirements?.skillsInRefined?.filter((skill, ind) => skill.show === true))

                                    }} >
                                        <SecondaryBtn label="Go" onClick={handleGo} />
                                    </div>
                                </div>}

                        </div>

                    </div>
                </div >


            </div>


        </div>

    )
}

export default LeftPanelSeeker