import React, { useEffect, useState } from "react";
import ShowHideIcon from "../../ShowHideIcon";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { EmploymentDelete, employmentUpdate } from "../../../reducer/detailedProfile/employmentSlice";
import { showSuccessToast } from "../../ToastNotification/showSuccessToast";
import DeleteApi from "../../../api/DeleteData/DeleteApi";
import { showErrorToast } from "../../ToastNotification/showErrorToast";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import { ImAttachment, ImWarning, ImCheckmark } from "react-icons/im";
import Validation from "../../ValidationForm";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import TableLoaders from "../../CustomLoader/TableLoaders";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import { setSkillOwnerAttachment } from '../../../reducer/attachments/skillOwnerAttachments'
import AttachmentForm from '../../AttachmentForm'
import Anonimization from "../../Anonimization";
import { setDetailedProfileEditData } from "../../../reducer/edit/detailedProfileEditSlice";
import EditDetailedProfile from "../Forms/Edit Forms/EditDetailedProfile";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";

const EmploymentSummary = ({ data, editEnable, edit }) => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content);
  const selectedLanguage = useSelector((state) => state.language);
  const handleAttachment = (selectedData) => {
    dispatch(setSkillOwnerAttachment(selectedData));
  };

  const handleDelete = (id) => {
    DeleteApi("Employment History", id)
      .then((res) => {
        if (res.data.deletedStatus === "true") {
          dispatch(EmploymentDelete(id));
          showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'DataDeletedSuccessfully') || {}).mvalue || "nf Data Deleted Successfully");
        } else {
          showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'YouAlreadyDeletedData') || {}).mvalue || "nf You already deleted this data");
        }
      })
      .catch((err) => {
        console.error(err);
        showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'ErrorDeletingData') || {}).mvalue || "nf Error in deleting data");
      });
  };

  const handleEdit = (data) => {
    dispatch(setDetailedProfileEditData(data));
  };

  /* VALIDATION HANDLING */
  const handleValidate = (data) => {
    dispatch(setValidationData(data));
  };

  /* CHANGE VALIDATION RESULTS */
  const {
    getUserValidation: { userValidationData },
  } = useSelector((state) => state);

  useEffect(() => {
    /* CHECK FOR MATCHING ID AND CAPTURE DATA */
    if (data && data?.data) {
      data?.data?.forEach(dataItem => {
        userValidationData?.forEach(validationItem => {
          if (dataItem.id === validationItem.requestorItemId && dataItem.validation === "No" && validationItem.isValidated === "Yes") {
            dispatch(employmentUpdate({ id: dataItem.id, validation: "Yes" }));
          }
        });
      });
    }
  }, [data, userValidationData, dispatch]);



  return (
    <>
      {/* VALIDATION MODAL */}
      <Validation />
      {<AttachmentForm />}
      {/* DETAILED PROFILE EDIT MODAL */}
      {<EditDetailedProfile />}
      <DeleteFormDetailedProfile />
      {/* table start */}
      <div className="table-responsive ">

        <table className="table table-sm  table-fixed table-hover    ">
          <thead>
            <tr className="border-dark-subtle ">
              <th scope="col" className="bg-body- " style={{ width: "19%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectOrganization"
                  ) || {}
                ).mvalue || "nf Organization"}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectDuration"
                  ) || {}
                ).mvalue || "nf Duration"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "13%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectType"
                  ) || {}
                ).mvalue || "nf Type"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectLocation"
                  ) || {}
                ).mvalue || "nf Location"}{" "}
              </th>
              <th scope="col" style={{ width: "12%" }}>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ProjectValidation"
                  ) || {}
                ).mvalue || "nf Validation"}{" "}
              </th>
              <th scope="col" className="bg-body- " style={{ width: "10%" }}>
                {" "}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.status === "loading" ?
              <TableLoaders Rows={2} Cols={5} btnCols={3} />

              :

              data.status === "success" && data.data.length > 0 && data.data.map((certs) => (

                <>
                  {certs.mlanguage === selectedLanguage && (
                    <tr className="" key={certs.id}>
                      <td>{certs.organization ? (certs.organization.length > 17 ? certs.organization.substring(0, 17) + "..." : certs.organization) : ''}</td>
                      <td>
                        {DayDifferenceToDynamicView(
                          certs.duration
                        )}
                      </td>
                      <td>{certs.mtype}</td>
                      <td
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={certs.location}
                      >
                        {certs.location
                          ? certs.location.length > 12
                            ? certs.location.substring(0, 12) + "..."
                            : certs.location
                          : ""}
                      </td>
                      <td className=" ">{certs.validation}</td>
                      <td
                        className="d-flex justify-content-between   "
                        style={{ minHeight: "26px" }}
                      >

                        {editEnable ? (
                          <>

                            <div data-bs-toggle="modal" data-bs-target="#detailedProfileEditModal" id="addModalBtn"><button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                              onClick={() => handleEdit(certs)}
                            ><MdEdit /></button></div>

                            <ShowHideIcon value={certs.recordHide === 'Yes' ? true : false} toggleable={true}
                              form={certs} fieldName={"recordHide"} api={"Skills Applied"} id={certs.id} />
                            <Anonimization value={certs.recordAnonymous === 'Yes' ? true : false} toggleable={true}
                              form={certs} fieldName={"recordAnonymous"} api={"Skills Applied"} id={certs.id} />

                            <button className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                              dispatch(setDeleteDetailedProfileData(certs))
                            }}>  <MdDelete /> </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="border-0 bg-white"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Validate"
                              data-bs-toggle="modal"
                              data-bs-target="#validationForm"
                              onClick={() => handleValidate(certs)}
                            >
                              <img src={validationIcon} alt="" style={{ width: '1rem', height: '1rem' }} />
                            </button>
                            <button className='border-0 bg-white'
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Attachment"
                              onClick={() => handleAttachment(certs)}
                              data-bs-toggle="modal"
                              data-bs-target="#attachmentList">
                              <ImAttachment />
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              ))}

          </tbody>
        </table>
      </div>
      {/* table end */}
    </>
  );
};

export default EmploymentSummary;
