import axios from 'axios';
import React from 'react'
import { BASE_URL } from '../../config/Properties';

const EditAccountApi = async (data) => {
    const userName = localStorage.getItem("userName");
    try {
        const response = await axios
            .put(`${BASE_URL}/skill/api/v1/skills/editAccount/${userName}`,data);
        console.log("response", response);
        return response;
    } catch (error) {
        console.log("error", error);
        throw error;
    }
    //http://localhost:8080/skill/api/v1/skills/editAccount/PRIYAJANSIUSER
}

export default EditAccountApi