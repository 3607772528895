import React, { useCallback, useState } from "react";
import SecondaryBtnLoader from "../Buttons/SecondaryBtnLoader";
import { useSelector } from "react-redux";
import { ChangePasswordApi } from "../../api/auth/ChangePasswordApi";
import { encryptData } from "../../config/encrypt/encryptData";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [currentPwdErr, setCurrentPwdErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  /* LOCAL STORAGE INIT */
  const userName = localStorage.getItem("userName");

  /* STORE IMPORTS */
  const { language: selectedLanguage, content } = useSelector((state) => state);


  const handleSubmitChangePassword = useCallback(
    async (e) => {
      e.preventDefault();

      const _ = e.currentTarget;
      if (_.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
        return;
      }

      /* CHECK FOR ERROR IN INPUT FIELDS */
      const input$ = _.querySelectorAll("input");
      const hasError = Array.from(input$).some(
        (inputVal) => !inputVal.checkValidity()
      );
      if (hasError) return;

      /* SET LOADING STATE TO TRUE */
      setIsLoading(true);

      /* PAYLOAD */
      const payload = {
        currentPassword: encryptData(currentPassword),
        newPassword: encryptData(newPassword),
        confirmNewPassword: encryptData(confirmNewPassword),
      };

      /* RETURN IF NEW PASSWORD AND CURRENT PASSWORD IS MATCHED */
      if (newPassword === currentPassword) {
        setIsLoading(false);
        return;
      }

      try {
        const res = await ChangePasswordApi(userName, payload);
        const data = res?.data;
        /* SET LOADING STATE TO FALSE AFTER SUCCESSFULL CHANGE */
        if (data?.status === "success") {
          setIsLoading(false);
          localStorage.clear();
          navigate("/login");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        } else if (data?.status === "error") {
          setIsLoading(false);

          if (data?.message === "Current password is incorrect.") {
            setCurrentPwdErr(true);
            setErrMsg(
              (
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "CurrentPasswordIncorrect."
                ) || {}
              ).mvalue || "nf Current password is incorrect."
            );
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error changing password: ", error);
      }
    },
    [
      currentPassword,
      newPassword,
      confirmNewPassword,
      userName,
      navigate,
      content,
      selectedLanguage,
    ]
  );

  return (
    <div className="container rounded">
      <div className="row justify-content-center mb-2 pb-2 rounded ">
        <div className="col-12 bg-light pt-2 rounded ">
          <div className="">
            <form
              className={
                validated
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              onSubmit={handleSubmitChangePassword}
              noValidate
            >
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="form-group my-1 ">
                    <label for="currentPassword">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "CurrentPassword"
                        ) || {}
                      ).mvalue || "nf Current Password"}
                    </label>
                    <input
                      type="password"
                      style={{ height: "32px" }}
                      className={
                        "form-control" + (currentPwdErr ? " is-invalid" : "")
                      }
                      id="currentPassword"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setCurrentPwdErr(false);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {!currentPwdErr ? (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "EnterCurrentPassword"
                            ) || {}
                          ).mvalue || "nf Please Enter Current Password"}
                        </p>
                      ) : (
                        <p>{errMsg}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group my-1 ">
                    <label for="newPassword">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NewPassword"
                        ) || {}
                      ).mvalue || "nf New Password"}
                    </label>
                    <input
                      type="password"
                      style={{ height: "32px" }}
                      className={
                        "form-control" +
                        (currentPassword.length &&
                          newPassword.length &&
                          newPassword === currentPassword
                          ? " is-invalid"
                          : "") +
                        (newPassword.length < 8 && newPassword.length
                          ? " is-invalid"
                          : "")
                      }
                      id="newPassword"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      minLength={8}
                      required
                    />
                    <div className="invalid-feedback">
                      {currentPassword.length &&
                      newPassword.length &&
                      newPassword === currentPassword ? (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "NewPasswordNotMatchCurrentPassword"
                            ) || {}
                          ).mvalue ||
                            "nf New Password should not match current password"}
                        </p>
                      ) : newPassword.length && newPassword.length < 8 ? (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "PasswordMustBeAtleastCharactersLong"
                            ) || {}
                          ).mvalue ||
                            "nf Password must be at least 8 characters long"}
                        </p>
                      ) : (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel === "PleaseEnterNewPassword"
                            ) || {}
                          ).mvalue || "nf Please Enter New Password"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group my-1 ">
                    <label for="confirmNewPassword">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ConfirmPassword"
                        ) || {}
                      ).mvalue || "nf Confirm Password"}
                    </label>
                    <input
                      type="password"
                      style={{ height: "32px" }}
                      className={
                        "form-control" +
                        (confirmNewPassword.length &&
                          confirmNewPassword !== newPassword
                          ? " is-invalid"
                          : "")
                      }
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {confirmNewPassword !== newPassword ? (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "ConfirmPasswordNotMatchNewPassword"
                            ) || {}
                          ).mvalue ||
                            "nf Confirm password should match New Password"}
                        </p>
                      ) : (
                        <p>
                          {(
                            content[selectedLanguage].find(
                              (item) =>
                                item.elementLabel ===
                                "PleaseEnterConfirmPassword"
                            ) || {}
                          ).mvalue || "nf Please Enter Confirm Password"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="mb-2">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "PasswordRequirements"
                      ) || {}
                    ).mvalue || "nf Password requirements"}
                  </p>
                  <p className="small text-muted mb-2">
                    {(
                      content[selectedLanguage].find(
                        (item) =>
                          item.elementLabel ===
                          "ToCeateNewPasswordMeetRrequirements:"
                      ) || {}
                    ).mvalue ||
                      "nf To create a new password, you have to meet all of the following requirements:"}
                  </p>
                  <ul className="small text-muted p-2 mb-0">
                    <li>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "MinimumCharacter"
                        ) || {}
                      ).mvalue || "nf Minimum 8 characters"}
                    </li>
                    <li>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "AtleastSplCharacter"
                        ) || {}
                      ).mvalue || "nf At least one special character"}
                    </li>
                    <li>
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "AtleastNumber"
                        ) || {}
                      ).mvalue || "nf At least one number"}
                    </li>
                    <li>
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "CantBeSameAsCurrentPassword"
                        ) || {}
                      ).mvalue || "nf Can’t be the same as a current password"}
                    </li>
                  </ul>
                </div>
              </div>
              <SecondaryBtnLoader
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Save"
                    ) || {}
                  ).mvalue || "nf Save"
                }
                backgroundColor="#F8F8E9"
                color="#815F0B"
                type="submit"
                loading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
