import React, { useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import educationInstitutionApi from "../../../api/searchSuggestionAPIs/educationInstitutionApi";
import { debouncedApiRequest } from "../../../components/DebounceHelperFunction/debouncedApiRequest";
import { toTitleCase } from "../HelperFunction/toTitleCase";
import { useSelector } from "react-redux";

const CreateSelectInstitution = ({
  insConvertedToSelect,
  insSearch,
  setInsSearch,
  setFormValues,
  formvalues,
  formName = "organization",
  showDropdown = true,
  handleInsParentToChild,
  handleOrgParentToChild,
  handleInsCertParentToChild,
  handleInsTrainingParentToChild,
  handleInsTConfParentToChild,
  handleInsTSkillingParentToChild,
  handleOrgLicenseParentToChild,
  handleOrgAchivementParentToChild,
  handleOrgMemberParentToChild,
  formType,
  usedIn= false,
  errors={},
  setErrors=()=>{}
}) => {

  const content = useSelector(state => state.content);
  const selectedLanguage = useSelector(state => state.language);
  const handleInputChange = (newValue) => {
    setErrors({...errors,[formName]:false})
    setInsSearch(newValue);
    setErrors({...errors,[formName]:false})
  };

  const handleCreateOption = (inputValue) => {
    /* CONVERT INPUT VALUE TO TITLECASE */
    const newInstitutionName = toTitleCase(inputValue);
    setInsSearch(newInstitutionName);
    setFormValues({
      ...formvalues,
      [formName]: newInstitutionName,
    });

    if (formType === "Education") {
      /* INCASE OF NEW INSTITUTION */
      handleInsParentToChild(true);
    } else if (formType === "Employment") {
      /* INCASE OF NEW ORGANIZATION */
      handleOrgParentToChild(true);
    } else if (formType === "Certification") {
      /* INCASE OF NEW CERT */
      handleInsCertParentToChild(true);
    } else if (formType === "Training") {
      /* INCASE OF NEW Training */
      handleInsTrainingParentToChild(true);
    } else if (formType === "Conference") {
      /* INCASE OF NEW Training */
      handleInsTConfParentToChild(true);
    } else if (formType === "Skilling") {
      /* INCASE OF NEW Training */
      handleInsTSkillingParentToChild(true);
    } else if (formType === "License") {
      /* INCASE OF NEW Training */
      handleOrgLicenseParentToChild(true);
    } else if (formType === "Achievement") {
      handleOrgAchivementParentToChild(true)
    } else if (formType === "Membership") {
      handleOrgMemberParentToChild(true);
    }
  };

  useEffect(() => {
    if (insSearch && insSearch.length > 1) {
      debouncedApiRequest(educationInstitutionApi, insSearch);
    }
  }, [insSearch]);


    // Custom styles for CreatableSelect
    const customStyles = {
      control: (provided, state) => ({

        ...provided,
        width:'22rem',
        border: 'none',
        borderBottom: errors?.[formName] ? '2px solid #d9534f' : '1px solid #ced4da' , // Customize bottom border style
        borderRadius: '0',
        boxShadow: state.isFocused ? 'none' : 'none', // Remove the default focus box-shadow
        '&:hover': {
          borderBottom: errors?.[formName] ? '2px solid #d9534f' : '2px solid #ced4da' , // Adjust hover state if needed
        }
      }),
      // input: (provided) => ({
      //   ...provided,
      //   padding: 0,
      // }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
      }),
      indicatorSeparator: () => ({ display: 'none' }), 
      // You can add more custom styles as needed
    };

  return (
    <CreatableSelect
      placeholder={`${(content[selectedLanguage].find(item => item.elementLabel === 'TypeMoreThan2LettersForOptions') || {}).mvalue || "nf Type more than 2 letters for options"}...`}
      isClearable
      options={insConvertedToSelect}
      onChange={(selectedOption) => {
        setFormValues({
          ...formvalues,
          [formName]: selectedOption ? selectedOption.label : "",
        });
        
      }}
      onCreateOption={handleCreateOption}
      onInputChange={handleInputChange}
      value={
        formvalues[formName]
          ? { label: formvalues[formName], value: formvalues[formName] }
          : null
      }
      isValidNewOption={(inputValue, selectValue, selectOptions) =>
        inputValue && inputValue.length > 2
      }
      components={!showDropdown && { DropdownIndicator: () => null }}
      
      styles={ usedIn && customStyles }
    />
  );
};

export default CreateSelectInstitution;
