import React, { useState } from "react";
import { ImAttachment } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import ShowHideIcon from "../../ShowHideIcon";
import { SiHiveBlockchain } from "react-icons/si";
import { useSelector } from "react-redux";
import { formatTimestampToDate } from "../HelperFunction/FormatTimestampToDate";
import { DayDifferenceToDynamicView } from "../HelperFunction/DayDifferenceToDynamicView";
import { calculateDaysDifference } from "../HelperFunction/CalculateDaysDifference ";
import AttachmentForm from "../../AttachmentForm";
import { useDispatch } from "react-redux";
import { setSkillOwnerAttachment } from "../../../reducer/attachments/skillOwnerAttachments";
import Anonimization from "../../Anonimization";
import ValidationDataComponent from "../SelectComponent/ValidationDataComponent";
import { setValidationData } from "../../../reducer/validation/validationSlice";
import Validation from "../../ValidationForm";
import validationIcon from "../../../Images/validate-svgrepo-com.svg";
import { setSkillsAcquiredEditData } from "../../../reducer/edit/skillAcquiredEditSlice";
import SkillAcquiredEdit from "../Forms/Edit Forms/SkillAcquiredEdit";
import DeleteFormDetailedProfile from "../../DeleteFormDetailedProfile";
import { setDeleteDetailedProfileData } from "../../../reducer/delete/deleteDetailedProfileSlice";

const SkillAcquiredDetail = ({ data, editEnable }) => {
    const content = useSelector((state) => state.content);
    const selectedLanguage = useSelector((state) => state.language);
    const regionalData = useSelector((state) => state.regionalData);
    const SkillSelected = useSelector(
        (state) => state.SkillSelected.skillOccupation
    );

    const dispatch = useDispatch();

    const [projectSelected, setProjectSelected] = useState(null)

    /* VALIDATION HANDLING */
    const handleValidation = (data) => {
        dispatch(setValidationData(data));
    };

    const handleAttachment = (selectedData) => {
        dispatch(setSkillOwnerAttachment(selectedData));
    };

    /* HANDLE EDIT */
    const handleEdit = (data) => {
        dispatch(setSkillsAcquiredEditData(data));
    };

    return (
        <>
            {/* VALIDATION MODAL */}
            {<Validation />}
            {/* EDIT MODAL */}
            {<SkillAcquiredEdit />}
            {/* DELETE MODAL */}
            {<DeleteFormDetailedProfile />}
            <div className="table-responsive ">
                <div className="d-none d-lg-block">
                    <table className="table table-sm  table-borderless  my-2    table-fixed     ">
                        <tr className="    ">
                            <th scope="col" style={{ width: "30%" }}>
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Name"
                                    ) || {}
                                ).mvalue || "nf Name   "}
                            </th>
                            <th scope="col" className="bg-body- " style={{ width: "15%" }}>
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Duration"
                                    ) || {}
                                ).mvalue || "nf Duration"}
                            </th>
                            <th scope="col" className="bg-body- " style={{ width: "17%" }}>
                                {" "}
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "ProjectType"
                                    ) || {}
                                ).mvalue || "nf ProjectType"}
                            </th>
                            <th scope="col" className="bg-body- " style={{ width: "14%" }}>
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Location"
                                    ) || {}
                                ).mvalue || "nf Location"}
                            </th>
                            <th scope="col" className="" style={{ width: "10%" }}>
                                {(
                                    content[selectedLanguage].find(
                                        (item) => item.elementLabel === "Validation"
                                    ) || {}
                                ).mvalue || "nf Validation"}
                            </th>
                            <th
                                scope="col"
                                className="bg-body- "
                                style={{ width: "12%" }}
                            ></th>
                        </tr>

                        <div className="mt-1 "></div>

                        {/* horizontal line */}
                        <tr style={{ borderColor: "gray", borderWidth: "1px" }} />
                    </table>
                </div>

                {data.map((skill, index) => (
                    <>
                        {<AttachmentForm attachmentData={skill} />}
                        {skill.mlanguage === selectedLanguage &&
                            SkillSelected === skill.title && (
                                <>
                                    <div className="  " key={index}>
                                        <div className="d-lg-none ">
                                            <table className="table table-borderless m-0  ">
                                                <tr className=" ">
                                                    <td>
                                                        <b>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Source"
                                                                ) || {}
                                                            ).mvalue || "nf Source"}
                                                        </b>{" "}
                                                    </td>
                                                    <td>:{skill.source} </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Duration"
                                                                ) || {}
                                                            ).mvalue || "nf Duration"}
                                                        </b>{" "}
                                                    </td>
                                                    <td>
                                                        :{DayDifferenceToDynamicView(skill.duration)} mos
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Type"
                                                                ) || {}
                                                            ).mvalue || "nf Type"}
                                                        </b>{" "}
                                                    </td>
                                                    <td>:{skill.mtype}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Location"
                                                                ) || {}
                                                            ).mvalue || "nf Location"}
                                                        </b>{" "}
                                                    </td>
                                                    <td
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content={skill.location}
                                                    >
                                                        :
                                                        {skill.location
                                                            ? skill.location.length > 20
                                                                ? skill.location.substring(0, 20) + "..."
                                                                : skill.location
                                                            : ""}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            {(
                                                                content[selectedLanguage].find(
                                                                    (item) => item.elementLabel === "Validation"
                                                                ) || {}
                                                            ).mvalue || "nf Validation"}
                                                        </b>{" "}
                                                    </td>
                                                    <td>:{skill.validation}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b></b>{" "}
                                                    </td>
                                                    <td>
                                                        <div className="   d-flex justify-content-between   ">
                                                            <div className="d-flex flex-column justify-content-center ">
                                                                <button
                                                                    className="border-0 bg-white"
                                                                    data-tooltip-id="my-tooltip"
                                                                    data-tooltip-content="Attachment"
                                                                    onClick={() => handleAttachment(skill)}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#attachmentList"
                                                                >
                                                                    <ImAttachment />
                                                                </button>
                                                            </div>
                                                            {editEnable && (
                                                                <>
                                                                    <div className="d-flex flex-column justify-content-center ">
                                                                        <button
                                                                            className="border-0  bg-white "
                                                                            data-tooltip-id="my-tooltip"
                                                                            data-tooltip-content="Edit"
                                                                        >
                                                                            <MdEdit />
                                                                        </button>
                                                                    </div>

                                                                    <button
                                                                        className="border-0 bg-white"
                                                                        data-tooltip-id="my-tooltip"
                                                                        data-tooltip-content="Trash"
                                                                    >
                                                                        <MdDelete />
                                                                    </button>
                                                                    <button
                                                                        className="border-0 bg-white"
                                                                        data-tooltip-id="my-tooltip"
                                                                        data-tooltip-content="Show/Hide"
                                                                    >
                                                                        <ShowHideIcon value={skill.showHide} />
                                                                    </button>
                                                                    <button
                                                                        className="border-0 bg-white"
                                                                        data-tooltip-id="my-tooltip"
                                                                        data-tooltip-content="Add on Blockchain"
                                                                        style={{
                                                                            visibility:
                                                                                skill.validation === "Yes"
                                                                                    ? "visible"
                                                                                    : "hidden",
                                                                        }}
                                                                    >
                                                                        <SiHiveBlockchain />
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex d-none    ">
                                            <div className="  " style={{ width: "34%" }}>
                                                {skill.source}
                                            </div>
                                            <div style={{ width: "13%" }}>
                                                {DayDifferenceToDynamicView(skill.duration)}
                                            </div>
                                            <div className="    " style={{ width: "17%" }}>
                                                {skill.mtype}
                                            </div>
                                            <div
                                                className="    "
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content={skill.location}
                                                style={{ width: "14%" }}
                                            >
                                                {skill.location
                                                    ? skill.location.length > 20
                                                        ? skill.location.substring(0, 20) + "..."
                                                        : skill.location
                                                    : ""}
                                            </div>
                                            <div className=" " style={{ width: "10%" }}>
                                                {skill.validation}
                                            </div>
                                            <div
                                                className="d-flex justify-content-between align-items-baseline"
                                                style={{ width: "12%" }}
                                            >
                                                {editEnable ? (
                                                    <div
                                                        className="d-flex justify-content-between  "
                                                        style={{ width: "100%" }}
                                                    >
                                                        <div
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#skillsAcquiredEditModal"
                                                            id="addModalBtn"
                                                        >
                                                            <button
                                                                className="border-0 bg-white"
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content="Edit"
                                                                onClick={() => handleEdit(skill)}
                                                            >
                                                                <MdEdit />
                                                            </button>
                                                        </div>
                                                        <ShowHideIcon
                                                            value={skill.recordHide === "Yes" ? true : false}
                                                            toggleable={true}
                                                            form={skill}
                                                            fieldName={"recordHide"}
                                                            api={"Skills Acquired"}
                                                            id={skill.id}
                                                        />
                                                        <Anonimization
                                                            value={
                                                                skill.recordAnonymous === "Yes" ? true : false
                                                            }
                                                            toggleable={true}
                                                            form={skill}
                                                            fieldName={"recordAnonymous"}
                                                            api={"Skills Acquired"}
                                                            id={skill.id}
                                                        />

                                                        {/* <button className='border-0 bg-white' data-tooltip-id="my-tooltip" data-tooltip-content="Add on Blockchain" style={{ visibility: skill.validation === "Yes" ? "visible" : "hidden" }}><SiHiveBlockchain /></button> */}
                                                        <button
                                                            className='border-0 bg-transparent' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-bs-toggle="modal" data-bs-target="#deleteformdetailedprofile" onClick={() => {
                                                                setProjectSelected(skill)
                                                                handleAttachment(skill)
                                                                handleValidation(skill)
                                                                dispatch(setDeleteDetailedProfileData(skill))
                                                            }}
                                                        >
                                                            <MdDelete />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="border-0 bg-white"
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content="Validate"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#validationForm"
                                                            onClick={() => handleValidation(skill)}
                                                        >
                                                            <img
                                                                src={validationIcon}
                                                                alt=""
                                                                style={{ width: "1rem", height: "1rem" }}
                                                            />
                                                        </button>
                                                        <button
                                                            className="border-0 bg-white"
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content="Attachment"
                                                            onClick={() => handleAttachment(skill)}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#attachmentList"
                                                        >
                                                            <ImAttachment />
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="border-bottom pt-1 ms-5  "></div>

                                        <div className="mb-3 mt-2    ">
                                            <div className="d-lg-flex  align-items-center  ">
                                                <div className="d-flex ps-5  " style={{ width: "64%" }}>
                                                    {" "}
                                                    <b>
                                                        {skill.mtype !== "Education"
                                                            ? (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel === "Organization"
                                                                ) || {}
                                                            ).mvalue || "nf Organization"
                                                            : (
                                                                content[selectedLanguage].find(
                                                                    (item) =>
                                                                        item.elementLabel === "InstitutionName"
                                                                ) || {}
                                                            ).mvalue || "nf InstitutionName"}
                                                        :
                                                    </b>
                                                    <div>{" " + skill.organization}</div>
                                                </div>

                                                <div
                                                    className="d-flex font-6 ps-lg-0 ps-5 mt-1 mt-lg-0 "
                                                    style={{ width: "14%" }}
                                                >
                                                    <b>
                                                        {(
                                                            content[selectedLanguage].find(
                                                                (item) => item.elementLabel === "From"
                                                            ) || {}
                                                        ).mvalue || "nf From"}
                                                        :
                                                    </b>
                                                    <div>
                                                        {formatTimestampToDate(
                                                            skill.fromDate,
                                                            regionalData.selectedCountry.dateFormat
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex font-6  ps-lg-0  ps-5"
                                                    style={{ width: "22%" }}
                                                >
                                                    <b>
                                                        {(
                                                            content[selectedLanguage].find(
                                                                (item) => item.elementLabel === "To"
                                                            ) || {}
                                                        ).mvalue || "nf To"}
                                                        :
                                                    </b>
                                                    <div>
                                                        {skill.toDate
                                                            ? formatTimestampToDate(
                                                                skill.toDate,
                                                                regionalData.selectedCountry.dateFormat
                                                            )
                                                            : "On-going"}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pb-1 pt-2 ps-5  ">
                                                <b>
                                                    {(
                                                        content[selectedLanguage].find(
                                                            (item) => item.elementLabel === "BriefDescription"
                                                        ) || {}
                                                    ).mvalue || "nf BriefDescription"}
                                                    :
                                                </b>{" "}
                                            </div>
                                            <div className="ms-4 ps-5   ">
                                                {skill.briefDescriptions}
                                            </div>
                                            {skill.validation === "Yes" && (
                                                <ValidationDataComponent itemId={skill.id} />
                                            )}
                                        </div>
                                    </div>

                                    <hr
                                        className="border-black px-0 py-0  "
                                        style={{ borderColor: "black", borderWidth: "2px" }}
                                    />
                                </>
                            )}
                    </>
                ))}
            </div>
        </>
    );
};

export default SkillAcquiredDetail;
