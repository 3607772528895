import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MyStImage from "./img/A.png";
// import profileImage from "./img/profile2.svg";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import AttachmentPost from "../../api/Attachment  API/AttachmentPost";
import {
  DeleteAttachment,
  GetAttachmentNoToken,
  splitStringToObject,
} from "../../api/Attachment  API/DownloadAttachmentApi";
import {
  validationReqApi,
  validationTemplateUpdateApi,
} from "../../api/validations/validationApi";
import LanguageComponent from "../../components/LanguageComponent";
import { calculateDaysDifference } from "../../components/SkillOwner/HelperFunction/CalculateDaysDifference ";
import { DayDifferenceToDynamicView } from "../../components/SkillOwner/HelperFunction/DayDifferenceToDynamicView";
import { formatTimestampToDate } from "../../components/SkillOwner/HelperFunction/FormatTimestampToDate";
import { showSuccessToast } from "../../components/ToastNotification/showSuccessToast";
import { BASE_URL } from "../../config/Properties";
import profileImage from "../../img/profile-circle-svgrepo-com (5).svg";
import "./validation success template/ValidationAnimation.css";
import ValidationLetterTemplate from "./validation success template/ValidationLetterTemplate";
import ValidationSuccessTemp from "./validation success template/ValidationSuccessTemp";
import expiredAnimation from "./validation success template/cancel-animation.json";
import somethingWentWrong from "./validation success template/something-went-wrong-animation.json";

const ValidationTemp = () => {
  /* STATES */
  const [userValidationData, setUserValidationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [validatorComment, setValidatiorComment] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [days, setDays] = useState("");
  const [errorPage, setErrorPage] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachmentFileNames, setAttachmentFileNames] = useState([]);
  const [showAttachmentError, setShowAttachmentError] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [profilePicObj, setProfilePicObj] = useState({});

  /* INIT TOKEN */
  const token = localStorage.getItem("token");

  /* ROUTE PARAMS */
  const { id } = useParams();

  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    regionalData,
  } = useSelector((state) => state);

  /* LOAD DATA FOR SPECIFIC USER */
  const getValidationDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await validationReqApi(id);
      setUserValidationData(res?.data);
      setIsLoading(false);
      setErrorPage(false);
    } catch (error) {
      console.error("Error getting validation data", error);
      setIsLoading(false);
      setErrorPage(true);
    }
  }, [id]);

  useEffect(() => {
    getValidationDetails();
  }, [getValidationDetails]);

  /* FORMAT THE PROFILE PIC */
  useEffect(() => {
    setProfilePicObj(
      splitStringToObject(userValidationData?.profilePictureFileName)
    );
  }, [userValidationData]);

  useEffect(() => {
    if (
      userValidationData?.validatorResponse === "Request Sent" ||
      userValidationData?.validatorResponse === "Resend"
    ) {
      setIsValidated(false);
    } else {
      setIsValidated(true);
    }

    /* FOR DURATION */
    const calcDays = calculateDaysDifference(
      userValidationData?.fromDate,
      userValidationData?.toDate
    );
    setDays(calcDays);
  }, [
    userValidationData?.validatorResponse,
    userValidationData?.fromDate,
    userValidationData?.toDate,
  ]);

  const handleValidatorAttachment = async (e, validationId) => {
    const files = Array.from(e.target.files);

    if (e?.target?.files[0]?.size > 500000) {
      setShowFileSizeError(true);
      setTimeout(() => {
        setShowFileSizeError(false);
      }, 5000);
      return;
    } else {
      setShowFileSizeError(false);
    }

    if (files.length === 0) {
      console.error("No file selected!");
      setShowAttachmentError(false);
      return;
    }

    /* CHECK FOR TOTAL NUMBER OF FILES */
    if (attachmentFiles.length + files.length > 4) {
      setShowAttachmentError(true);
      setTimeout(() => {
        setShowAttachmentError(false);
      }, 5000);
      return;
    }

    const newFiles = files.slice(0, 4 - attachmentFiles.length);
    setAttachmentFiles((prev) => [...prev, ...newFiles]);

    const formData = new FormData();
    newFiles.forEach((file) => {
      formData.append("file", file);
    });

    try {
      setIsAttachmentLoading(true);

      const res = await AttachmentPost(
        "handleMultiFile",
        validationId,
        formData
      );
      const data = res?.data;

      const newUploadedFiles = data.map((file) => ({
        filename: file.fileName,
        fileId: file.fileId,
        fileTitle: file.fileTitle || file.fileName,
      }));

      setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
      setIsAttachmentLoading(false);
    } catch (error) {
      setIsAttachmentLoading(false);
      console.error("Error uploading files: ", error);
    }
  };

  /* HANDLE ATTACHMENT REMOVING */
  const handleRemoveAttachment = async (indexToRemove, id, fileId) => {
    try {
      await DeleteAttachment(id, fileId);
    } catch (error) {
      console.error("Error deleting attachment: ", error);
    }
    const updatedAttachmentFiles = [...attachmentFiles];
    updatedAttachmentFiles.splice(indexToRemove, 1);
    setAttachmentFiles(updatedAttachmentFiles);

    const updatedUploadedFiles = [...uploadedFiles];
    updatedUploadedFiles.splice(indexToRemove, 1);
    setUploadedFiles(updatedUploadedFiles);
  };

  useEffect(() => {}, [uploadedFiles]);

  /* HANDLE VALIDATOR PUT */
  const handleUpdate = async (response) => {
    try {
      /* UPDATE EXISTING DATA */
      const updatedData = {
        validatorComments: validatorComment,
        validatorResponse: response,
        validatorName: userValidationData?.validatorName,
        relationship: userValidationData?.relationship,
        description:
          userValidationData?.about || userValidationData?.description,
        validatorEmail: userValidationData?.validatorEmail,
        validatorPhone: userValidationData?.validatorPhone,
        isValidated: response === "Accept" ? "Yes" : "No",
        skillOccupation: userValidationData?.skillOccupation,
        requestorId: userValidationData?.requestorId,
        requestorItemId: userValidationData?.requestorItemId,
        remarks: userValidationData?.remarks,
        mlanguage: userValidationData?.mlanguage,
        userId: userValidationData?.userId,
        invitationLink: userValidationData?.invitationLink,
        keyTable: userValidationData?.keyTable,
        keyName: userValidationData?.keyName,
        requestorAttachmentUrls: userValidationData?.requestorAttachmentUrls,
        requestorAttachmentFileNames:
          userValidationData?.requestorAttachmentFileNames,
        validatorAttachmentUrls: "",
        validatorAttachmentFileNames: uploadedFiles?.length
          ? JSON.stringify(uploadedFiles)
          : "",
      };
      await validationTemplateUpdateApi(userValidationData?.id, updatedData);
      showSuccessToast("Validation Updated Successfully");
      /* REFRESH DATA */
      getValidationDetails();
      /* EMPTY THE COMMENT BOX */
      setValidatiorComment("");
      /* RESETTING THE ATTACHMENT */
      setAttachmentFiles([]);
      setAttachmentFileNames([]);
      setUploadedFiles([]);
      /* DISABLE THE BUTTONS ON SUCCESS */
      setIsValidated(true);
      setShowAttachmentError(false);
    } catch (error) {
      console.error("Error validating the request: ", error);
    }
  };

  useEffect(() => {}, [attachmentFileNames, attachmentFiles]);

  const toggleAdditionalDetails = () => {
    setShowAdditionalDetails(!showAdditionalDetails);
  };

  if (isLoading) {
    return <div>{""}</div>;
  }

  /* LOTTIE OPTIONS */
  const somethingWentWorngDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: somethingWentWrong,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const expiredDefaultOptions = {
    loop: false,
    autoplay: true,
    animationData: expiredAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg mynav"
        style={{
          color:
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "navBarFontColor"
              ) || {}
            ).mvalue || "#F7FFDD",
          backgroundColor:
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "navBarBgColor"
              ) || {}
            ).mvalue || "#577126",
          direction: content[selectedLanguage].direction,
        }}
      >
        <div className="container-fluid">
          <div className="col-12 col-md-5">
            <Link className="navbar-brand d-flex ms-2" to={""}>
              <div
                className="overflow-hidden"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img
                  className="w-100 h-100 object-fit-cover"
                  src={MyStImage}
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center ms-2">
                <h4
                  className="fw-bold mt-2 fs-3"
                  style={{
                    color:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "navBarFontColor"
                        ) || {}
                      ).mvalue || "#F7FFDD",
                    direction: content[selectedLanguage].direction,
                  }}
                >
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Title"
                    ) || {}
                  ).mvalue || "nf MySkillsTree"}
                </h4>
              </div>
            </Link>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <span className="navbar-text myhref">
              <span
                className="fw-bold fs-6"
                style={{
                  color:
                    (
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "navBarFontColor"
                      ) || {}
                    ).mvalue || "#F7FFDD",
                  direction: content[selectedLanguage].direction,
                }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "WantToJoinTheMyst"
                  ) || {}
                ).mvalue || "nf Want to join the Myst community"}
              </span>
              <Link
                to={"/signup"}
                className="signup fs-6 text-primary text-decoration-underline"
                style={{ marginLeft: "1em", fontFamily: "cursive" }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "SignUp"
                  ) || {}
                ).mvalue || "nf SignUp"}
              </Link>
            </span>
          </div>
          <div className="col-md-1 btn-group me-2">
            <LanguageComponent />
          </div>
        </div>
      </nav>

      {/* PAGE CONTENT IF VALIDATION IS NOT UPDATED */}
      {userValidationData?.isValidated === "No" &&
        userValidationData?.validatorResponse !== "Request Expired" &&
        ["Request Sent", "Resend"].includes(
          userValidationData?.validatorResponse
        ) && (
          <div className="container-fluid mt-5">
            <div className="container">
              <div className="row">
                <div
                  className="overflow-hidden pe-none"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <img
                    className="w-100 h-100 object-fit-cover"
                    src={
                      userValidationData?.profilePictureFileName
                        ? GetAttachmentNoToken(
                            userValidationData?.userId,
                            profilePicObj?.fileName,
                            profilePicObj?.fileId
                          )
                        : profileImage
                    }
                    onError={(e) => {
                      e.target.src = profileImage;
                    }}
                    alt=""
                  />
                </div>
                <div className="col-12 col-md-8">
                  <h2 className="fw-bold myprofile">
                    {userValidationData?.firstName +
                      " " +
                      userValidationData?.lastName}
                  </h2>
                  <blockquote>
                    <p>
                      {userValidationData?.about ||
                        userValidationData?.description}
                    </p>
                  </blockquote>
                </div>
                <div className="col">
                  <h4 className="fw-bold">
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "DetailsView"
                      ) || {}
                    ).mvalue || "nf Details"}
                  </h4>
                  <blockquote>
                    <p>
                      <div className="mylist d-flex align-items-center">
                        <i className="fa fa-envelope text-muted"></i>
                        <span style={{ marginLeft: "0.5em" }}>
                          {userValidationData?.email}
                        </span>
                      </div>
                      <div className="mylist d-flex align-items-center">
                        <i className="fas fa-phone text-muted"></i>
                        <span style={{ marginLeft: "0.5em" }}>
                          {userValidationData?.mobileNumber}
                        </span>
                      </div>
                    </p>
                  </blockquote>
                </div>
              </div>

              {/* Validation Details */}
              <div className="row mt-5">
                <h3 className="fw-bold">
                  {(
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "ValidationDetails"
                    ) || {}
                  ).mvalue || "nf Validation Details"}
                </h3>
                <div className="col-12">
                  <div className="ms-4">
                    <h5 className="fw-bold mt-4">
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "ValidationRequiredFor"
                        ) || {}
                      ).mvalue || "nf Validation required for"}{" "}
                      :
                    </h5>
                    <div className="table-responsive">
                      <table className="table border border-2 rounded mt-2">
                        <thead className="text-white fw-bold">
                          <tr>
                            <th
                              scope="col"
                              className="text-white fw-bold"
                              style={{ background: "#577126" }}
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "ItemName"
                                ) || {}
                              ).mvalue || "nf Item Name"}
                            </th>
                            <th
                              scope="col"
                              className="text-white fw-bold"
                              style={{ background: "#577126" }}
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) =>
                                    item.elementLabel === "ProjectDuration"
                                ) || {}
                              ).mvalue || "nf Duration"}
                            </th>
                            <th
                              scope="col"
                              className="text-white fw-bold"
                              style={{ background: "#577126" }}
                            >
                              {(
                                content[selectedLanguage].find(
                                  (item) => item.elementLabel === "ProjectType"
                                ) || {}
                              ).mvalue || "nf Type"}
                            </th>
                            {userValidationData?.location && (
                              <th
                                scope="col"
                                className="text-white fw-bold"
                                style={{ background: "#577126" }}
                              >
                                {(
                                  content[selectedLanguage].find(
                                    (item) =>
                                      item.elementLabel === "ProjectLocation"
                                  ) || {}
                                ).mvalue || "nf Location"}
                              </th>
                            )}
                            <th
                              scope="col"
                              className="text-white fw-bold"
                              style={{ background: "#577126" }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{userValidationData?.keyName}</th>
                            <td>
                              {userValidationData?.fromDate &&
                              userValidationData?.toDate
                                ? DayDifferenceToDynamicView(days)
                                : formatTimestampToDate(
                                    Number(
                                      userValidationData?.fromDate
                                        ? userValidationData?.fromDate
                                        : userValidationData?.dateSent
                                    ),
                                    regionalData?.selectedCountry?.dateFormat
                                  )}
                            </td>
                            <td>{userValidationData?.mtype}</td>
                            <td>{userValidationData?.location}</td>
                          </tr>

                          {/* EXTRA DETAILS */}
                          <tr>
                            <td
                              colSpan="4"
                              className="d-flex col-12"
                              style={{ fontSize: "0.9em" }}
                            >
                              {/* LEFT SIDE */}
                              <div className="col-8">
                                <div className="d-flex justify-content-between">
                                  <p className="fw-bold">
                                    {(
                                      content[selectedLanguage].find(
                                        (item) =>
                                          item.elementLabel === "SkillLabel"
                                      ) || {}
                                    ).mvalue || "nf Skill"}
                                    :{" "}
                                    <span className="text-secondary">
                                      {userValidationData?.skillOccupation}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p>
                                    <b>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel ===
                                            "ProjectBriefDescription"
                                        ) || {}
                                      ).mvalue || "nf Brief Description"}
                                      :
                                    </b>
                                  </p>
                                  &nbsp;
                                  <span className="fw-bold text-secondary">
                                    {userValidationData?.briefDescriptions ||
                                      "No descriptions available"}
                                  </span>
                                </div>
                              </div>
                              {/* RIGHT SIDE */}
                              <div className="col-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="fw-bold">
                                    {(
                                      content[selectedLanguage].find(
                                        (item) => item.elementLabel === "From"
                                      ) || {}
                                    ).mvalue || "nf From"}
                                    :{" "}
                                    <span className="text-secondary">
                                      {formatTimestampToDate(
                                        Number(
                                          userValidationData?.fromDate
                                            ? userValidationData?.fromDate
                                            : userValidationData?.dateSent
                                        ),
                                        regionalData?.selectedCountry
                                          ?.dateFormat
                                      )}
                                    </span>
                                  </p>
                                  {userValidationData?.toDate && (
                                    <p className="fw-bold">
                                      {(
                                        content[selectedLanguage].find(
                                          (item) => item.elementLabel === "To"
                                        ) || {}
                                      ).mvalue || "nf To"}
                                      :{" "}
                                      <span className="text-secondary">
                                        {formatTimestampToDate(
                                          Number(userValidationData?.toDate),
                                          regionalData?.selectedCountry
                                            ?.dateFormat
                                        )}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="d-flex align-items-center">
                                  <p>
                                    <b>
                                      {(
                                        content[selectedLanguage].find(
                                          (item) =>
                                            item.elementLabel === "Validated"
                                        ) || {}
                                      ).mvalue || "nf Validated"}
                                      :
                                    </b>
                                  </p>
                                  &nbsp;
                                  <span className="fw-bold text-secondary">
                                    {userValidationData?.validatorResponse ===
                                    "Accept" ? (
                                      <p>
                                        {(
                                          content[selectedLanguage].find(
                                            (item) =>
                                              item.elementLabel ===
                                              "AlreadyValidated"
                                          ) || {}
                                        ).mvalue || "nf Already Validated"}
                                      </p>
                                    ) : (
                                      <p>
                                        {(
                                          content[selectedLanguage].find(
                                            (item) => item.elementLabel === "No"
                                          ) || {}
                                        ).mvalue || "nf No"}
                                      </p>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* REQUESTOR MESSAGE */}
                <div
                  className="col-12 col-md-6"
                  style={{ borderRight: "1px solid #2E8B57" }}
                >
                  <div className="ms-4">
                    <h5 className="fw-bold mt-4">
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "MessageFromTheRequester"
                        ) || {}
                      ).mvalue || "nf Message from the requester"}{" "}
                      :
                    </h5>
                    <blockquote className="mt-2">
                      <p>{userValidationData?.remarks}</p>
                    </blockquote>
                  </div>
                </div>
                {/* VALIDATIOR MESSAGE */}
                <div className="col-12 col-md-6">
                  <div className="ms-4">
                    <h5 className="fw-bold mt-4">
                      {(
                        content[selectedLanguage].find(
                          (item) =>
                            item.elementLabel === "MessageToTheRequester"
                        ) || {}
                      ).mvalue || "nf Message to the requester"}{" "}
                      :
                    </h5>
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        value={validatorComment}
                        onChange={(e) => setValidatiorComment(e.target.value)}
                      ></textarea>
                      <label htmlFor="floatingTextarea">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "TypeRemarks"
                          ) || {}
                        ).mvalue || "nf Type your remarks here"}{" "}
                        ...
                      </label>
                    </div>
                  </div>
                </div>
                {/* REQUESTOR ATTACHMENTS */}
                <div
                  className="col-12 col-md-6"
                  style={{ borderRight: "1px solid #2E8B57" }}
                >
                  <div className="ms-4">
                    <h5 className="fw-bold mt-4">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Attachments"
                        ) || {}
                      ).mvalue || "nf Attachments"}{" "}
                      :
                    </h5>
                    <p>
                      <span>
                        {userValidationData?.requestorAttachmentFileNames &&
                        JSON.parse(
                          userValidationData?.requestorAttachmentFileNames
                        ) ? (
                          JSON.parse(
                            userValidationData?.requestorAttachmentFileNames
                          ).map((attachment, index) => (
                            <React.Fragment>
                              <a
                                key={index}
                                rel="noreferrer"
                                href={`${BASE_URL}/skill/api/v1/skills/GetFileNoTk/filedownloadById/${attachment?.linkedId}/${attachment.fileName}/${attachment.fileId}`}
                                target="_blank"
                              >
                                {index > 0 && " | "}{" "}
                                {attachment?.fileName?.trim()}
                              </a>
                            </React.Fragment>
                          ))
                        ) : (
                          <p className="text-primary">No Attachments Sent</p>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                {/* VALIDATOR ATTACHMENTS */}
                <div className="col-12 col-md-6">
                  <div className="ms-4">
                    <h5 className="fw-bold mt-4">
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "Attachments"
                        ) || {}
                      ).mvalue || "nf Attachments"}{" "}
                      :
                    </h5>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        onChange={(e) =>
                          handleValidatorAttachment(e, userValidationData?.id)
                        }
                        multiple
                        accept=".pdf,.jpeg,.jpg,.png,.xlsx,.docx"
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="inputGroupFileAddon04"
                      >
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "AddSkillButton"
                          ) || {}
                        ).mvalue || "nf Add"}
                      </button>
                    </div>
                    {uploadedFiles.length > 0 && (
                      <div className="d-flex align-items-center">
                        {uploadedFiles.map((file, index) => (
                          <div key={index}>
                            <p
                              className="bg-success text-white p-2 rounded me-1"
                              style={{
                                fontSize: "0.9em",
                                opacity: isAttachmentLoading ? "0.5" : "",
                              }}
                            >
                              <FaTimes
                                onClick={() =>
                                  handleRemoveAttachment(
                                    index,
                                    userValidationData?.id,
                                    file?.fileId
                                  )
                                }
                              />
                              {file?.filename}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* INCASE OF ERROR IN FILE NUMBERS */}
                    {showAttachmentError && (
                      <b className="text-danger">{"Upto 4 files allowed!"}</b>
                    )}
                    {showFileSizeError && (
                      <b className="text-danger">{"File size too large!"}</b>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-outline-secondary fw-bold"
                    type="button"
                    disabled={isValidated || isAttachmentLoading}
                    onClick={() => handleUpdate("Abstain")}
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Abstain"
                      ) || {}
                    ).mvalue || "Abstain"}
                  </button>
                  <button
                    className="btn btn-primary fw-bold mybtn1"
                    type="button"
                    disabled={isValidated || isAttachmentLoading}
                    style={{
                      backgroundColor: "#845511",
                      color: "white",
                      border: "none",
                    }}
                    onClick={() => handleUpdate("Reject")}
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Reject"
                      ) || {}
                    ).mvalue || "Reject"}
                  </button>
                  <button
                    className="btn btn-primary fw-bold mybtn1"
                    type="button"
                    disabled={isValidated || isAttachmentLoading}
                    style={{
                      backgroundColor: "#577126",
                      color: "white",
                      border: "none",
                    }}
                    onClick={() => handleUpdate("Accept")}
                  >
                    {(
                      content[selectedLanguage].find(
                        (item) => item.elementLabel === "Accept"
                      ) || {}
                    ).mvalue || "Accept"}
                  </button>
                </div>
              </div>
            </div>
            {/* Arrow for toggling additional details */}
            <div
              className="arrow-toggle"
              onClick={toggleAdditionalDetails}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                top: "50%",
                right: "0",
                cursor: "pointer",
                zIndex: 100,
                animation: !showAdditionalDetails
                  ? "moveArrow 0.5s infinite alternate"
                  : "none",
              }}
            >
              <FaChevronLeft size={30} />
            </div>

            {/* Additional details bar */}
            {showAdditionalDetails && (
              <div
                className="additional-details"
                style={{
                  position: "fixed",
                  top: "0",
                  left: showAdditionalDetails ? "0" : "-100%",
                  width: showAdditionalDetails ? "100%" : "0",
                  height: "100vh",
                  backgroundColor: "white",
                  zIndex: 1000,
                  overflowY: "auto",
                  transition: "left 0.5s ease-in-out, width 0.5s ease-in-out",
                }}
              >
                {/* Close icon */}
                <div
                  className="close-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    zIndex: 1001,
                  }}
                  onClick={toggleAdditionalDetails}
                >
                  <FaTimes size={30} />
                </div>
                {/* Additional details content */}
                <ValidationLetterTemplate
                  dateSent={userValidationData?.dateSent}
                  firstName={userValidationData?.firstName}
                  lastName={userValidationData?.lastName}
                  skillOccupation={userValidationData?.skillOccupation}
                  fromDate={userValidationData?.fromDate}
                  toDate={userValidationData?.toDate}
                  briefDescription={userValidationData?.remarks}
                  keyName={userValidationData?.keyName}
                  keyTable={userValidationData?.keyTable}
                  relationship={userValidationData?.relationship}
                  location={userValidationData?.location}
                />
              </div>
            )}
          </div>
        )}

      {/* IF VALIDATION ABSTAIN OR REJECT */}
      {["Reject", "Abstain"].includes(userValidationData?.validatorResponse) &&
        !errorPage &&
        !userValidationData?.expired && (
          <React.Fragment>
            <ValidationSuccessTemp
              content={content}
              selectedLanguage={selectedLanguage}
            />
          </React.Fragment>
        )}

      {/* IF VALIDATION IS UPDATED */}
      {userValidationData?.expired &&
        !errorPage &&
        userValidationData?.message === "Validation link already used" && (
          <React.Fragment>
            <ValidationSuccessTemp
              content={content}
              selectedLanguage={selectedLanguage}
            />
          </React.Fragment>
        )}

      {/* ERROR PAGE */}
      {errorPage && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <Lottie
              options={somethingWentWorngDefaultOptions}
              style={{ width: "100%", height: "90vh" }}
            />
          </div>
        </div>
      )}

      {/* LINK EXPIRED PAGE */}
      {userValidationData?.validatorResponse === "Request Expired" &&
        !errorPage &&
        userValidationData?.expired &&
        userValidationData?.message && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <div style={{ width: "200px", height: "200px" }}>
              <Lottie
                options={expiredDefaultOptions}
                style={{
                  animation: "heartbeat 1s infinite",
                  width: "100%",
                  height: "100%",
                }}
              />
              <div
                className="mt-2"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "rgb(140, 0, 0)",
                  textAlign: "center",
                }}
              >
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "LinkExpired"
                  ) || {}
                ).mvalue || "nf Link has expired"}
                !
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default ValidationTemp;
