import React from "react";

const ResumeFootnote = ({ content, selectedLanguage }) => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-start resume-footnote"
        style={{ color: "#808080", flexDirection: "column", padding: "1rem" }}
      >
        <div
          style={{
            fontStyle: "italic",
          }}
        >
          &#10003;{" "}
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "Validated"
            ) || {}
          ).mvalue || "nf Validated"}
        </div>
        <div>
          1.{" "}
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "ExperienceApplicationSkill"
            ) || {}
          ).mvalue || "Experience in Application of Skill"}
        </div>
        <div>
          2.{" "}
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "ExperienceAcquisitionSkill"
            ) || {}
          ).mvalue || "Experience in Acquisition of Skill"}
        </div>
        <div>
          3.{" "}
          {(
            content[selectedLanguage].find(
              (item) => item.elementLabel === "Limited10Skills"
            ) || {}
          ).mvalue || "Limited to next 10 skills"}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeFootnote;
