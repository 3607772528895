import React, { useEffect } from "react";
import SecondaryBtnLoader from "../Buttons/SecondaryBtnLoader";

const ChangeScreen = ({
  content,
  selectedLanguage,
  interfaceName,
  setInterfaceName,
  handleScreenNameSubmit,
  isSubmitLoading,
}) => {
  /* SCREEN NAMES */
  const screenNames = [
    {
      id: 1,
      name: "MAIN",
    },
    {
      id: 2,
      name: "DIALOG",
    },
  ];

  useEffect(() => {}, [interfaceName]);

  return (
    <React.Fragment>
      <div className="container rounded">
        <div className="row justify-content-center mb-2 pb-2 rounded">
          <div className="col-12 bg-light pt-2 rounded">
            <div
              className="d-flex align-items-center gap-2"
              style={{ marginBottom: "4rem" }}
            >
              <div>
                {(
                  content[selectedLanguage].find(
                    (item) => item.elementLabel === "ChangeScreen"
                  ) || {}
                ).mvalue || "nf Change Screen"}
                :
              </div>
              <div class="dropdown">
                <button
                  type="button"
                  class="btn dropdown-toggle"
                  style={{
                    color:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NavBarFontColor"
                        ) || {}
                      ).mvalue || "#000",
                    backgroundColor:
                      (
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "NavBarBgColor"
                        ) || {}
                      ).mvalue || "#000",
                  }}
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {interfaceName || "MAIN"}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {screenNames.map((screen) => (
                    <button
                      key={screen?.id}
                      className="dropdown-item interfaceName"
                      onClick={() => setInterfaceName(screen?.name)}
                    >
                      {screen?.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {/* SAVE BUTTON */}
            <div>
              <SecondaryBtnLoader
                loading={isSubmitLoading}
                onClick={handleScreenNameSubmit}
                label={
                  (
                    content[selectedLanguage].find(
                      (item) => item.elementLabel === "Save"
                    ) || {}
                  ).mvalue || "nf Save"
                }
                backgroundColor="#F8F8E9"
                color="#815F0B"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangeScreen;
