import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const EditAccountDetails = async (accountId, body) => {
  try {
    const res = await axios.put(
      `${BASE_URL}/skill/api/v1/skills/editAccount/${accountId}`,
      body
    );
    console.log("COSOD UDSUG UGISD", localStorage.getItem(
        "userName"
      ))
    return res;
  } catch (error) {
    throw error;
  }
};
