import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const ValidationByItemIdAPI = async (itemId) => {
//get-all-user-data-by-fieldName/Validation Requests/requestorItemId/SPRJT-1449894?
  const SEARCH_URI = BASE_URL + `/skill/api/v1/skills/get-all-user-data-by-fieldName/Validation Requests/requestorItemId/${itemId}?&authToken=${localStorage.getItem("token")}`;
  return axios
  .get(SEARCH_URI)
    .then((response) => {
      console.log("Validation Datas for ",itemId," = ", response);
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default ValidationByItemIdAPI;
