import React from "react";
import "../LoginPage.css";

const SignUpForm = ({
  content,
  selectedLanguage,
  validation,
  validated,
  isPasswordShow,
  isConfirmPasswordShow,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handlePasswordShow,
  handleConfirmPasswordShow,
  handleRoleChange,
  selectedRole,
  roles,
  handleSignUp,
  isLoading,
  FaEye,
  FaEyeSlash,
  ageConfirmationCheckBox,
  setAgeConfirmationCheckBox,
  tosCheckbox,
  setTosCheckBox,
  logo,
}) => {
  return (
    <React.Fragment>
      <div className="col-md-6 m-auto">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <img src={logo} height={"50px"} alt="logo"></img>
          <h3
            className="text-muted text-center"
            style={{ letterSpacing: ".2rem" }}
          >
            {(
              content[selectedLanguage].find(
                (item) => item.elementLabel === "SignUpHeading"
              ) || {}
            ).mvalue || "nf Sign-Up"}
          </h3>
        </div>
        <div className="login d-flex align-items-center py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                {/* FORM */}
                <form className={validation} validated={validated}>
                  <div className="col-lg-8 mx-auto">
                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                    >
                      {/* PASSWORD */}
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          left: "2px",
                        }}
                      >
                        <span
                          className="input-group-text bg-white pl-2 border-0 h-100"
                          style={{ borderRadius: 0 }}
                        >
                          <i className="fa fa-lock text-muted"></i>
                        </span>
                      </div>
                      <input
                        id="password"
                        style={{ height: "32px", paddingLeft: "43px" }}
                        name="password"
                        type={isPasswordShow ? "text" : "password"}
                        placeholder={`${
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "Password"
                            ) || {}
                          ).mvalue || "nf Password"
                        }`}
                        required
                        autoFocus
                        // className={"form-control font-5"}
                        className={
                          "form-control font-5" +
                          (password.length < 8 && password.length
                            ? " is-invalid"
                            : "")
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handlePasswordShow}
                      >
                        {isPasswordShow ? <FaEyeSlash /> : <FaEye />}
                      </div>
                      <div className="text-end invalid-feedback">
                        {password.length && password.length < 8 ? (
                          <p>
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "PasswordMustBeAtleastCharactersLong"
                              ) || {}
                            ).mvalue ||
                              "nf Password must be at least 8 characters long"}
                          </p>
                        ) : (
                          <p>
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "PleaseEnterNewPassword"
                              ) || {}
                            ).mvalue || "nf Please Enter New Password"}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* CONFIRM PASSWORD */}
                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          left: "2px",
                        }}
                      >
                        <span
                          className="input-group-text bg-white pl-2 border-0 h-100"
                          style={{ borderRadius: 0 }}
                        >
                          <i className="fa fa-lock text-muted"></i>
                        </span>
                      </div>
                      <input
                        id="Confirmpassword"
                        style={{ height: "32px", paddingLeft: "43px" }}
                        name="ConfirmPassword"
                        type={isConfirmPasswordShow ? "text" : "password"}
                        placeholder={`${
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "ConfirmPassword"
                            ) || {}
                          ).mvalue || "nf Confirm Password"
                        }`}
                        required
                        // className={"form-control font-5"}
                        className={
                          "form-control font-5" +
                          (confirmPassword.length &&
                          confirmPassword !== password
                            ? " is-invalid"
                            : "")
                        }
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "2px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handleConfirmPasswordShow}
                      >
                        {isConfirmPasswordShow ? <FaEyeSlash /> : <FaEye />}
                      </div>
                      <div className="text-end invalid-feedback">
                        {confirmPassword !== password ? (
                          <p>
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "ConfirmPasswordNotMatchNewPassword"
                              ) || {}
                            ).mvalue ||
                              "nf Confirm password should match New Password"}
                          </p>
                        ) : (
                          <p>
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel ===
                                  "PleaseEnterConfirmPassword"
                              ) || {}
                            ).mvalue || "nf Please Enter Confirm Password"}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* ROLE SELECT */}
                    <div className="mt-3 d-none">
                      <select
                        onChange={handleRoleChange}
                        value={selectedRole}
                        required
                        className="form-select font-5 mb-2"
                        aria-label="Default select example"
                      >
                        {roles?.data.map((role) => {
                          if (
                            role.active === "Yes" &&
                            role.mlanguage === selectedLanguage
                          ) {
                            return (
                              <option key={role.roleName} value={role.roleName}>
                                {role.label}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                      <div className="text-end invalid-feedback">
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "PleaseSelectARole"
                          ) || {}
                        ).mvalue || "nf PleaseSelectARole"}
                      </div>
                    </div>

                    {/* FORGOT PASSWORD REDIRECT */}
                    <div className="d-flex justify-content-between align-items-end mt-2 mx-2 mb-4">
                      <div>
                        <div className="d-flex">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={ageConfirmationCheckBox}
                            onChange={(e) =>
                              setAgeConfirmationCheckBox(e.target.checked)
                            }
                            id="ageCheckbox"
                            style={{ borderColor: "gray" }}
                          />
                          <label
                            class="form-check-label ms-2 "
                            for="ageCheckbox"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "AgeConsent"
                              ) || {}
                            ).mvalue || "nf AgeConsent"}
                          </label>
                        </div>

                        {/* <br /> */}

                        <div className="d-flex">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onChange={(e) => setTosCheckBox(e.target.checked)}
                            value={tosCheckbox}
                            id="servicepolicy"
                            style={{ borderColor: "gray" }}
                          />
                          <label
                            class="form-check-label ms-2 "
                            for="servicepolicy"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) => item.elementLabel === "PolicyConsent"
                              ) || {}
                            ).mvalue || "nf PolicyConsent"}
                          </label>
                        </div>

                        <div>
                          <a
                            href="https://www.myskillstree.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "TermsOfServiceLink"
                              ) || {}
                            ).mvalue || "nf TermsOfServiceLink"}
                          </a>
                          <a
                            className="ms-2"
                            href="https://www.myskillstree.com/privacypolicy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {(
                              content[selectedLanguage].find(
                                (item) =>
                                  item.elementLabel === "PrivacyPolicyLink"
                              ) || {}
                            ).mvalue || "nf TermsOfServiceLink"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* SIGNIN BUTTON */}
                      <button
                        type="button"
                        id="signInBtn"
                        className="btn mt-2 text-white btn-block mb-2 float-end rounded-pill shadow-sm"
                        style={{ backgroundColor: "#815F0B" }}
                        onClick={handleSignUp}
                      >
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "SignUp"
                          ) || {}
                        ).mvalue || "nf SignUp"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpForm;
