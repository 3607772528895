import axios from "axios";
import { BASE_URL } from "../../../config/Properties";

const ReorderSkillsApi = async (body) => {

  return axios
    .put(`${BASE_URL}/skill/api/v1/skills/reorderSkills?authToken=${localStorage.getItem("token")}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default ReorderSkillsApi;
