import { createSlice } from "@reduxjs/toolkit";

//const initialState = [{ skill: "", experience: "", required: true, validated: true, edit: false, mandatory: false, show: true }];


const MyRequirementSkillSlice = createSlice({
    name: "MyRequirement",
    initialState: {
            skillsInMyReq: [],
            locationsInMyReq: []
        }
    ,
    reducers: {
        setMyReqSkills: (state, action) => {
            state.skillsInMyReq = action.payload;
            

        }, 
        setMyReqLocations: (state, action) => {
            state.locationsInMyReq = action.payload;
            console.log(action.payload);
            
        },
        emptyMyReqSkills: (state) => {
            console.log("emptied my requirments");
            state.skillsInMyReq = [];
            state.locationsInMyReq = [];
        }
    }
})

export const { setMyReqSkills, emptyMyReqSkills,setMyReqLocations } = MyRequirementSkillSlice.actions;
export default MyRequirementSkillSlice.reducer;