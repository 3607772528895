import { BASE_URL } from "../../config/Properties";
import axios from "axios";

/* THIS API FOR EMAIL */
export const ForgotPasswordApi = async (body) => {
    try {
        const res = await axios.post(`${BASE_URL}/skill/api/v1/pass/reset`, body);
        return res;
    } catch (error) {
        throw error;
    }
};

/* THIS API FOR SUBMIT NEW PASSWORD */
export const ForgotPasswordUpdateApi = async (body) => {
    try {
        const res = await axios.post(`${BASE_URL}/skill/api/v1/pass/update`, body);
        return res;
    } catch (error) {
        throw error;
    }
};

/* THIS API FOR CHECKING THE ENDPOINT [TOKEN] STATE */
export const ForgotPasswordTokenCheckApi = async (body) => {
    try {
        const res = await axios.post(`${BASE_URL}/skill/api/v1/pass/check`, body);
        return res;
    } catch (error) {
        throw error;
    }
};