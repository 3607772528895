import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SecondaryToggleBtn from '../../components/Buttons/SecondaryToggleBtn';
import { LiaFileExportSolid } from 'react-icons/lia';
import Loader from '../../components/Loader';
import UserCardA from '../../components/SkillAvailer/UserCardA';
import { Pagination, TablePagination } from '@mui/material';
import GridViewComponent from './GridViewComponent';
import { setView } from '../../reducer/SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice';

const ResultMIddlePanel = ({ Result }) => {

    const dispatch = useDispatch();

    const selectedLanguage = useSelector(state => state.language);
    const content = useSelector(state => state.content);


    //refine my req
    const { skillsInRefined, view } = useSelector(state => state.RefMyRequirements);
    //Regional country Data
    const regionalData = useSelector(state => state.regionalData);



    return (
        <div style={{ backgroundColor: "#E7E7E7" }} >
            {Result.status !== 'idle' &&
                <div className=" d-md-flex flex-column" >

                    <div className="d-md-flex align-items-center justify-content-between   "  >
                        <div className='d-md-flex align-items-center  ' style={{ width: "10%" }}>

                            {/* result for */}
                            <div className=' d-flex justify-content-center  align-items-center' style={{ width: "100%", backgroundColor: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarBgColor') || {}).mvalue || "#000", color: (content[selectedLanguage].find(item => item.elementLabel === 'NavBarFontColor') || {}).mvalue || "#fff", height: "30px", borderRadius: "10px", }}>

                                <div className='font-6' style={{ display: "flex", }}>
                                    {Result.noOfResult + " results for"}

                                </div>

                            </div>


                        </div>
                        <div className=' ' style={{ width: "70%" }}>
                            <div className='mx-1   d-md-flex  flex-wrap' >
                                {skillsInRefined?.map((skill, index) =>
                                    skill.show &&
                                    <span className='mx-1 mt-1 badge ms-1 border-1 '
                                        style={{
                                            borderStyle: "solid", borderWidth: "1px", borderColor: "#815F0B",
                                            backgroundColor: "#E7E7E7", color: "#815F0B", padding: "3px 6px",
                                            borderRadius: "7px", alignContent: "center", alignItems: "center", fontSize: "11px",
                                        }}
                                        id={index}>{skill.label}

                                    </span>
                                )
                                }
                            </div>
                        </div>
                        <div className='d-flex align-items-center '>
                            <div className='d-flex'>
                                <SecondaryToggleBtn label={view === "card" ? (content[selectedLanguage].find(item => item.elementLabel === 'CardView') || {}).mvalue || "nf Card View" : (content[selectedLanguage].find(item => item.elementLabel === 'Card') || {}).mvalue || "nf Card"} onClick={() => dispatch(setView("card"))} isActive={view === "card"} />
                                <SecondaryToggleBtn label={view === "list" ? (content[selectedLanguage].find(item => item.elementLabel === 'ListView') || {}).mvalue || "nf List View" : (content[selectedLanguage].find(item => item.elementLabel === 'List') || {}).mvalue || "nf List"} onClick={() => dispatch(setView("list"))} isActive={view === "list"} />
                                <SecondaryToggleBtn label={view === "map" ? (content[selectedLanguage].find(item => item.elementLabel === 'MapView') || {}).mvalue || "nf Map View" : (content[selectedLanguage].find(item => item.elementLabel === 'Map') || {}).mvalue || "nf Map"} onClick={() => dispatch(setView("map"))} isActive={view === "map"} />
                            </div>

                        </div>
                    </div>


                </div>
            }
            <div className=" rounded  font-5 overflow-y-auto mt-1" style={{ height: "85vh", backgroundColor: "#E7E7E7" }} >
                {skillsInRefined?.length > 0 ?
                    <div>



                        {/* cards */}
                        <div>
                            {Result.status === "loading" && <Loader />}

                            {view === "card" && Result.status === "success" && Result.noOfResult > 0 &&
                                <React.Fragment>
                                    <div className='d-flex justify-content-center my-2' style={{ backgroundColor: "#E7E7E7" }}>
                                        {/* <Pagination count={11} /> */}
                                    </div>
                                    {Result.data.map((result) => {
                                        return (
                                            <div key={result.userId}>
                                                <UserCardA userDetail={result} SelectedRefSkillFilter={skillsInRefined} />
                                            </div>)
                                    })}
                                </React.Fragment>
                            }
                            {/* just ignore the error id not found */}
                            <div id='map' style={{ height: "0", width: "100%" }} ></div>
                            {/* {view === "map" && (
                                <div
                                    id="googleMap"
                                    style={{ width: '100%', height: '77vh' }}
                                ></div>
                            )} */}
                            {view === "list" && Result.status === "success" && Result.noOfResult > 0 &&
                                <>

                                    {/* <div className='d-flex justify-content-center'>
                                        <TablePagination
                                            component="div"
                                            class='d-flex justify-content-center'
                                            count={Result?.noOfResult}
                                            page={1}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={4}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div> */}

                                    <div style={{ maxHeight: "77vh" }}>
                                        <GridViewComponent data={Result?.data} />
                                    </div>
                                    {/* <div className=' d-lg-block font-5'>

                                                    <table className='table table-sm  table-borderless  mt-2  my-0 py-0  table-fixed     '>


                                                        <tr className=' font-5   '>
                                                            <th scope="col" style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf Name"}</th>
                                                            <th scope="col" className='bg-body- ' style={{ width: "20%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                                           
                                                            <th scope="col" className='' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Email') || {}).mvalue || "nf Email"}</th>
                                                            <th scope="col" className='' style={{ width: "15%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'PhoneNumber') || {}).mvalue || "nf PhoneNumber"}</th>
                                                            <th scope="col" style={{ width: "21%" }}></th>
                                                        </tr>

                                                        <div className=' ' ></div>
                                                      
                                                        <tr className=' p-0 m-0   border-black   ' style={{ borderColor: "gray", borderWidth: "1px" }} />

                                                    </table>
                                                </div> */}


                                    {/* {Result.data.map((result) => {
                                                    return (
                                                        <div key={result.userId}>
                                                            <ListView userDetail={result} />
                                                            
                                                        </div>)
                                                })} */}
                                </>
                            }

                        </div>
                    </div>
                    :
                    <>
                    </>
                }
            </div>
        </div>
    )
}

export default ResultMIddlePanel