import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import educationInstitutionApi from '../../../api/searchSuggestionAPIs/educationInstitutionApi';
import SmallLoader from '../../SkillAvailer/SmallLoader';
import { ThreeDots } from 'react-loader-spinner';
import { timestampToYYYYMMDD } from '../HelperFunction/timestampToYYYYMMDD';
import  { components, DropdownIndicatorProps } from 'react-select';


const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
       <></>
      </components.DropdownIndicator>
    );
  };

  const IndicatorSeparator = (
    innerProps) => {
    return <></>;
  };


const CreteSelectForEducation = ({ options, formValues, setFormValues,
    setNewEmployment, newEmployment, setEducationInput, educationInput,
    setSource, setExtractedLocation }) => {
    const [value, setValue] = useState(null);
    const [apiBuffer, setApiBuffer] = useState(false);
    const [apiOptions, setApiOptions] = useState([]);
    //Switch api and already added institution data
    const [apiSwitch, setApiSwitch] = useState(false);

    const [eduApiLoader, setEduApiLoader] = useState(false);

    const selectedLanguage = useSelector(state => state.language);

    const handleChange = (selectedOption) => {
        setValue(selectedOption);
        console.log(selectedOption);
        if (!apiSwitch) {
            if (!selectedOption?.__isNew__) {
                if (selectedOption) {
                    console.log("dsdasd ",options);

                    setNewEmployment({ ...selectedOption,});
                    setFormValues({
                        ...formValues,
                        source: selectedOption.institute,
                        institute: selectedOption.institute,
                        insIsnew: false,
                        fromDate: timestampToYYYYMMDD(Number(selectedOption.startDate)), toDate: timestampToYYYYMMDD(Number(selectedOption.endDate))
                      
                    });


                } else {
                    setNewEmployment({
                        institute: "",
                        InstituteShowHide: "Yes",
                        startDate: "",
                        endDate: "",
                       
                        location: "",
                    });
                    setExtractedLocation([]);
                    setFormValues({
                        ...formValues,
                        source: "",
                        institute: "",
                        insIsnew: false,
                        fromDate: '', toDate: ''
                    });
                }
            }

            else if (selectedOption?.__isNew__) {
                setFormValues({
                    ...formValues,
                    source: selectedOption.value,
                    insIsnew: selectedOption?.__isNew__
                   
                });
            
                setNewEmployment({ ...newEmployment, institute: selectedOption?.value });
                setSource(selectedOption.value);
                console.log("selectedoption : ", selectedOption);
            }

        } else if (apiSwitch) {
            setFormValues({
                ...formValues,
                source: selectedOption.value,
                insIsnew: true
            });
            setNewEmployment({ ...newEmployment, institute: selectedOption?.value });
            setSource(selectedOption.value);

            console.log("selectedoption api : ", selectedOption);
        }

    };
    const handleIpChange = (op) => {
        setEducationInput(op);

    }
    useEffect(() => {
        if (educationInput && educationInput.length > 0) {
            setApiSwitch(true);
            setEduApiLoader(true);
            debouncedApiRequest(educationInstitutionApi, educationInput, selectedLanguage, setApiBuffer, setEduApiLoader);


        } else {
            setApiSwitch(false);
            setEduApiLoader(false);
            setApiOptions([])
        }
    }, [educationInput])

    useEffect(() => {
        if (apiBuffer.length > 0) {
            const data = apiBuffer.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.institutionName, // Add new field 1 with a default value
                    label: item.institutionName, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setApiOptions(data);
        }

    }, [apiBuffer])



    return (
        <React.Fragment >
            <CreatableSelect
             components={{ DropdownIndicator,IndicatorSeparator }}
                options={apiOptions}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#f5f5f5',
                        primary: '#815F0B',
                        primary50: '#f5f5f5',
                        neutral0: "#f8f9fA"
                    },
                })}
                closeMenuOnSelect={true}
                openMenuOnClick={false}
                defaultMenuIsOpen={false}
                value={value}
                onChange={handleChange}
                onInputChange={(e) => handleIpChange(e)}
                isClearable={true}
                isValidNewOption={() => false}

                placeholder="Enter your institute name"
            />
            {eduApiLoader && <div style={{ transform: "translate(365px,-24px)",width: "50px" }}>
            <  ThreeDots width={"30"} height={"10"} />
            </div>}

        </React.Fragment>
    );
}

export default CreteSelectForEducation