import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const getUserExistenceDetail = async (email) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/skill/api/v1/skills/check/account/${email}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
