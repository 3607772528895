import React, { useState, useEffect } from 'react'
import CreteSelectForEducation from '../SelectComponent/CreteSelectForEducation'
import SecondaryBtn from '../../Buttons/SecondaryBtn';
import { useSelector } from 'react-redux';
import { showErrorToast } from '../../ToastNotification/showErrorToast';
import { showSuccessToast } from '../../ToastNotification/showSuccessToast';
import { FormatDateIntoPost } from '../HelperFunction/FormatDateIntoPost';
import PostApi from '../../../api/PostData/PostApi';
import MultiSelect from '../SelectComponent/MultiSelect';
import { timestampToYYYYMMDD } from './../HelperFunction/timestampToYYYYMMDD';
import SmallLoader from './../../SkillAvailer/SmallLoader';
import { useDispatch } from 'react-redux';
import { fetchEducationHistory } from '../../../api/fetchAllData/fetchEducationHistory';
import { RxCross2 } from 'react-icons/rx';
import institutionSearchApi from '../../../api/institutions/institutionSearchApi';
import degreeSearchSuggestions from './../../../api/searchSuggestionAPIs/degreeSearchSuggestions';
import CreatableSelectForDeg from '../../CreatableSelectForDeg';
import { debouncedApiRequest } from '../../DebounceHelperFunction/debouncedApiRequest';
import SecondaryBtnLoader from '../../Buttons/SecondaryBtnLoader';
import { calculateDaysDifference } from '../HelperFunction/CalculateDaysDifference ';
import { convertDateToMilliseconds } from '../HelperFunction/convertDateToMilliseconds';
import DatePicker from 'react-datepicker';
import { formatDateInputType } from '../HelperFunction/FormatDateInputType';
const EducationForm = ({ setFormValues2, formvalues2, setFileupload, setValidation, handleSubmit, isAcquiredEdit, isLoading, sendEducationToParent }) => {

    const content = useSelector(state => state.content);
    const selectedLanguage = useSelector(state => state.language);
    const SkillSelected = useSelector(state => state.SkillSelected);
    const educationHistory = useSelector(state => state.educationHistory);

    //redux
    /* STORE IMPORTS */
    const {
        regionalData,
    } = useSelector((state) => state);

    console.log("eduuu", educationHistory);
    console.log("form valesssssss", formvalues2);

    const [educationOptions, setEducationOptions] = useState([]);

    useEffect(() => {
        const filterEducation = educationHistory?.data
            .map((employment) => ({
                value: employment,
                label: employment.course,
            }));

        console.log("filter edu", filterEducation);

        setEducationOptions(filterEducation)

        console.log("changed");

    }, [educationHistory])





    const dispatch = useDispatch();

    const [onGoing, setOnGoing] = useState(false);
    const [online, setOnline] = useState(false);


    //Education box location
    const [location, setLocation] = useState([]);
    //acquiring skill box location final / editable/ this is added to api
    const [Acqlocation, setAcqLocation] = useState([]);
    //acquring skill box master location fetched from the added education
    const [extractedLocation, setExtractedLocation] = useState([]);
    //locations for existing courses
    const [exsitingLocation, setExsitingLocation] = useState([]);



    //text of education insititute
    const [educationInput, setEducationInput] = useState("");

    // new course check
    const [newCourse, setNewCourse] = useState();



    const [source, setSource] = useState("");
    const [eduOps, setEduOps] = useState([]);


    const [eduLoaderOn, setEduLoaderOn] = useState(false);
    const initalStateEdu = {
        institute: "",
        InstituteShowHide: "Yes",
        startDate: "",
        endDate: "",
        course: "",
        location: "",
    }
    const [newEducation, setNewEducation] = useState(initalStateEdu); //others
    const [courseIsNew, setCourseIsNew] = useState(false); //
    const [courseloader, setCourseLoader] = useState(true); //



    useEffect(() => {
        if (newEducation.location?.length > 0) {
            setExtractedLocation(newEducation.location.split(","));
        }
    }, [newEducation])

    useEffect(() => {
        console.log(extractedLocation);
        //let commaSeparatedString = extractedLocation.map(obj => obj.label).join(', ');
        setAcqLocation(extractedLocation);
    }, [extractedLocation])


    useEffect(() => {
        setNewEducation({ ...newEducation, location: location });
        console.log("Edu loc ", location);

    }, [location])

    useEffect(() => {
        console.log("acq ", Acqlocation);
        let commaSeparatedString = "";
        if (Acqlocation.length > 0) {
            commaSeparatedString = Acqlocation.map(obj => obj).join(', ');
        }
        setFormValues2({ ...formvalues2, location: commaSeparatedString });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Acqlocation])

    useEffect(() => {
        console.log("eddd ", educationHistory);
        const data = educationHistory.data.map(item => {
            // Create a new object to hold the existing fields and the new fields
            const newItem = {
                ...item, // Spread the existing fields
                value: item.institute, // Add new field 1 with a default value
                label: item.institute, // Add new field 2 with a default value
                // Add new field 3 with a default value
            };
            return newItem;
        });
        setEduOps(data);
    }, [educationHistory])

    useEffect(() => {
        console.log("isAcquiredEdit : ", isAcquiredEdit);
        if (isAcquiredEdit && formvalues2.location.length > 0 && location.length === 0) {
            setLocation(formvalues2.location)
        }
    }, [isAcquiredEdit])

    const toggleOnline = () => {
        setOnline(false);
    }
    const handleValidateProject = () => {
        setValidation(true);
    };

    const handleSubmitDate = (close) => {
        handleSubmit(close)


    }


    console.log(Acqlocation);


    const institutionAddClick = () => {
        setEduLoaderOn(true);
        PostApi("Education History", {
            ...newEducation, title: SkillSelected.userSkill, institute: source, course: formvalues2.course, location: newEducation.location,
            startDate: FormatDateIntoPost(newEducation.startDate), endDate: newEducation.endDate ? FormatDateIntoPost(newEducation.endDate) : "", duration: calculateDaysDifference(convertDateToMilliseconds(newEducation.startDate), newEducation.endDate ? convertDateToMilliseconds(newEducation.endDate) : Date.now()), ticketids: newEducation?.id ? [newEducation?.id] : []
        }).then((res) => {
            console.log(res);
            showSuccessToast((content[selectedLanguage].find(item => item.elementLabel === 'EducationAddedSuccessful') || {}).mvalue || "nf Education Added Successful");
            dispatch(fetchEducationHistory());
            setFormValues2({ ...formvalues2, course: res.data.course, source: res.data.institute, institute: res.data.institute, fromDate: newEducation.startDate, toDate: newEducation.endDate, insIsnew: false, ticketids: res?.data?.id });
            setSelectedDeg({ label: res.data.course, value: res.data })
            setOnGoing(false)
            setCourseIsNew(false)
            setNewEducation(initalStateEdu)
            setEduLoaderOn(false);

        }).catch((err) => {
            console.log(err);
            showErrorToast((content[selectedLanguage].find(item => item.elementLabel === 'SomethingWentWrong') || {}).mvalue || "nf Something went wrong");
            setEduLoaderOn(false);
        })
    }

    useEffect(() => {
        console.log(newEducation);
        console.log(formvalues2);
        //console.log(educationHistory.data);

    }, [newEducation])

    ///for degree suggestions
    const [degData, setDegData] = useState([]);
    const [degreeSuggestionsData, setDegreeSuggestionsData] = useState([]);
    const [degSuggestionLoader, setDegSuggestionLoader] = useState(false);
    const [degSearchValue, setDegSearchValue] = useState("");
    const [selectedDeg, setSelectedDeg] = useState("");

    const degSearch = (e) => {
        if (e.length > 0) {
            setDegData([]);
            setDegSuggestionLoader(true)
            debouncedApiRequest(degreeSearchSuggestions, e, selectedLanguage, setDegreeSuggestionsData, setDegSuggestionLoader);
        } else {
            setDegData([]);
        }
    }

    useEffect(() => {

        if (degreeSuggestionsData.length > 0 && degreeSuggestionsData[0].skillOccupation !== "No suggestions found") {
            const data = degreeSuggestionsData?.map(item => {
                // Create a new object to hold the existing fields and the new fields
                const newItem = {
                    ...item, // Spread the existing fields
                    value: item.combined, // Add new field 1 with a default value
                    label: item.combined, // Add new field 2 with a default value
                    // Add new field 3 with a default value
                };
                return newItem;
            });
            setDegData(data);
        }

    }, [degreeSuggestionsData]);

    useEffect(() => {
        if (!selectedDeg) {
            setOnGoing({ ...onGoing, certificationEndDate: false })
            return
        }

        if (!selectedDeg?.value?.endDate) {
            setOnGoing({ ...onGoing, certificationEndDate: true })
        } else {
            setOnGoing({ ...onGoing, certificationEndDate: false })
        }

        sendEducationToParent(selectedDeg);
    }, [selectedDeg])

    console.log("selected", selectedDeg);

    return (
        <div className='ms-3 '>
            <div class="my-2">
                <label for="exampleFormControlInput1" class="form-label">Course<span className='text-danger' >*</span></label>

                <CreatableSelectForDeg
                    options={educationOptions}
                    data={degData}
                    setDegSearchValue={setDegSearchValue}
                    degSearchValue={degSearchValue}
                    degSearch={degSearch}
                    setSelectedDeg={setSelectedDeg}
                    setFormValues={setFormValues2}
                    formValues={formvalues2}
                    setNewEducation={setNewEducation}
                    newEducation={newEducation}
                    setCourseIsNew={setCourseIsNew}
                    setExtractedLocation={setExtractedLocation}
                    degSuggestionLoader={degSuggestionLoader}
                />
                {/* <input type="text" style={{ height: "32px" }} class="form-control bg-body-tertiary" id="" placeholder="" name="course" value={newEducation.course} onChange={(e) => {
                    // setNewEducation({ ...newEducation, [e.target.name]: e.target.value });
                    // setFormValues2({ ...formvalues2, course: e.target.value })
                }} /> */}
            </div>


            {
                !courseIsNew &&
                <>

                    <div class="mb-2 ">
                        <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf Institution Name"}<span className='text-danger' > *</span></label>
                        <input type="text" style={{ height: "32px" }} name='institute' class="form-control bg-body-tertiary h-75 " id="" placeholder="" onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.institute} disabled />
                    </div>

                    <div className='d-lg-flex my-2 '>
                        <div className=" w-100  ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'StartDate') || {}).mvalue || "nf Start Date"}<span className='text-danger' >*</span></label>
                            {/* <input type="date"

                                min={timestampToYYYYMMDD(Number(selectedDeg?.value?.startDate))}
                                // max={(formvalues2.toDate) ? formvalues2.toDate : timestampToYYYYMMDD(Number(Date.now()))}
                                max={timestampToYYYYMMDD(Number(selectedDeg?.value?.endDate))}
                                style={{ height: "32px" }} className="form-control bg-body-tertiary" id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.fromDate} /> */}
                            <DatePicker
                                style={{ height: "32px" }}
                                minDate={timestampToYYYYMMDD(Number(selectedDeg?.value?.startDate))}
                                maxDate={timestampToYYYYMMDD(Number(selectedDeg?.value?.endDate))}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                id="exampleFormControlInput1"
                                onChange={(e) => setFormValues2({ ...formvalues2, fromDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues2?.fromDate ? formvalues2?.fromDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                onBlur={() => { }}
                            />
                        </div>
                        <div className=" ms-lg-2 w-100 ">
                            <label htmlFor="exampleFormControlInput1" className={onGoing.certificationEndDate ? "form-label text-secondary  " : "form-label"}>{(content[selectedLanguage].find(item => item.elementLabel === 'EndDate') || {}).mvalue || "nf End Date"}  </label>
                            {/* <input type="date"

                                min={formvalues2.fromDate}
                                // max={(formvalues2.toDate) ? formvalues2.toDate : timestampToYYYYMMDD(Number(Date.now()))}
                                max={timestampToYYYYMMDD(Number(selectedDeg?.value?.endDate))}
                                style={{ height: "32px" }} className={onGoing.certificationEndDate ? "form-control bg-body-tertiary text-secondary  " : "form-control bg-body-tertiary"} id="exampleFormControlInput1" name='toDate' onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.toDate}
                                {...onGoing.certificationEndDate && { disabled: true }} /> */}
                            <DatePicker
                                id="exampleFormControlInput1"
                                style={{ height: "32px" }}
                                className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                minDate={formvalues2?.fromDate}
                                maxDate={timestampToYYYYMMDD(Number(selectedDeg?.value?.endDate))}
                                onChange={(e) => setFormValues2({ ...formvalues2, toDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                toggleCalendarOnIconClick
                                selected={formvalues2?.toDate ? formvalues2?.toDate : null}
                                dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={regionalData?.selectedCountry?.dateFormat}
                                disabled={onGoing?.certificationEndDate}
                                onBlur={() => { }}
                            />

                            {
                                (!selectedDeg?.value?.endDate) &&
                                <div className={onGoing.certificationEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary   '} >
                                    <label htmlFor="exampleFormControlInput1" className="">{(content[selectedLanguage].find(item => item.elementLabel === 'OnGoing') || {}).mvalue || "nf OnGoing"} </label>
                                    <input className='ms-2 ' type="checkbox" name="certificationEndDate" checked={onGoing.certificationEndDate} onChange={(e) => {
                                        setOnGoing({ ...onGoing, [e.target.name]: e.target.checked })
                                        setFormValues2({ ...formvalues2, toDate: "" })
                                    }} />
                                </div>

                            }
                        </div>
                    </div>




                    <div>
                        <div class="my-2 ">


                            <div className='d-flex '>
                                <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</label>

                                <div className='mx-2'>
                                    <SecondaryBtn
                                        label={(content[selectedLanguage].find(item => item.elementLabel === 'Reset') || {}).mvalue || "nf reset"}
                                        onClick={() => {

                                            setAcqLocation(extractedLocation);
                                        }}
                                        backgroundColor="#F8F8E9"
                                        color="#815F0B"

                                    />
                                </div>

                            </div>

                            <div className="d-flex gap-2">
                                {Acqlocation?.map((loc) => {
                                    return (
                                        <div className="p-1  rounded bg-light border" >
                                            {loc}
                                            <span className="text-muted px-1" style={{ cursor: 'pointer' }} onClick={() => {
                                                let newLocations = Acqlocation.filter((location) => {
                                                    return location !== loc
                                                })
                                                setAcqLocation(newLocations)
                                            }}><RxCross2 /></span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>


                    <div className="my-2 ">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                        <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.briefDescriptions} >
                        </textarea>
                    </div>


                    <div className="modal-footer d-flex justify-content-end  ">

                        <div className='d-flex gap-2'>
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save') || {}).mvalue || "nf Save"} onClick={() => handleSubmit(false)} backgroundColor="#F8F8E9" color="#815F0B" loading={isLoading} />
                            <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'Save&Close') || {}).mvalue || "nf Save & Close"} Active={true} onClick={() => handleSubmit(true)} backgroundColor="#815F0B" color="#F8F8E9" loading={isLoading} />
                        </div>
                    </div>

                </>
            }



            {/* {
                courseIsNew &&
                <>

                    <div className="mb-2   ">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Institution name <span className='text-danger' >*</span></label>
                        <CreteSelectForEducation
                            setDegData={setDegData}
                            setFormValues={setFormValues2}
                            formValues={formvalues2}
                            setNewEmployment={setNewEducation}
                            newEmployment={newEducation}
                            options={eduOps}
                            setSource={setSource}
                            setExtractedLocation={setExtractedLocation}
                            setEducationInput={setEducationInput}
                            educationInput={educationInput}
                        />
                    </div>

                    <div class="my-2">


                        <div class="my-2 ">
                            <div className='d-flex '>
                                <label for="exampleFormControlInput1" class="form-label">Location</label>
                                <div className='align-content-center ' style={{ marginLeft: 'auto' }}><input type='checkbox' className='me-2' onClick={(e) => setOnline(e.target.checked)} />Online</div>
                            </div>

                            <MultiSelect setLocationData={setLocation} viewLocation={location} onlineStatus={online} isEdit={isAcquiredEdit} />
                        </div>



                        <div className='d-lg-flex my-2'>
                                        <div className=" w-100 h-75  ">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Education Start Date <span className='text-danger' >*</span></label>
                                            <input type="date"  style={{ height: "32px" }} disabled={formvalues2.source ? false : true} max={timestampToYYYYMMDD(Date.now())} className="form-control bg-body-tertiary h-75 " id="exampleFormControlInput1" name='fromDate' onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.fromDate} />
                                        </div>
                                        <div className=" ms-lg-2 w-100 h-75  ">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Education End Date </label>
                                            <input type="date" style={{ height: "32px" }} disabled={formvalues2.source ? false : true} max={timestampToYYYYMMDD(Date.now())} min={formvalues2.fromDate ? formvalues2.fromDate : ""} className={onGoing.instituteEndDate ? "form-control bg-body-tertiary h-75 text-secondary  " : "form-control bg-body-tertiary h-75 "}
                                                id="exampleFormControlInput1" name='toDate'  {...onGoing.instituteEndDate && { disabled: true }}
                                                onChange={(e) => setFormValues2({ ...formvalues2, [e.target.name]: e.target.value })} value={formvalues2.toDate} />

                                            <div className={onGoing.instituteEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary '} >
                                                <label htmlFor="exampleFormControlInput1" className="">Current Education</label>
                                                <input className='ms-2 ' type="checkbox" name="instituteEndDate"
                                                    onChange={(e) => {
                                                        setOnGoing({ ...onGoing, [e.target.name]: e.target.checked });
                                                        if (e.target.checked) {
                                                            setFormValues2({ ...formvalues2, toDate: timestampToYYYYMMDD(Date.now()) });
                                                        }
                                                    }} />
                                            </div>

                                        </div>
                                    </div>

                    </div>

                </>

            } */}

            {
                courseIsNew &&

                <>



                    <div className='' style={{ border: "solid 1px #D3D3D3", padding: "5px" }}>
                        <h5>{"Add Education"}</h5>
                        <div className="mb-2   ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf Institution Name"}<span className='text-danger' >*</span></label>
                            <CreteSelectForEducation
                                setDegData={setDegData}
                                setFormValues={setFormValues2}
                                formValues={formvalues2}
                                setNewEmployment={setNewEducation}
                                newEmployment={newEducation}
                                options={eduOps}
                                setSource={setSource}
                                setExtractedLocation={setExtractedLocation}
                                setEducationInput={setEducationInput}
                                educationInput={educationInput}
                            />
                        </div>

                        <div className='mx-3'>

                            <div>

                                <div class="my-2">


                                    <div class="my-2 ">
                                        <div className='d-flex '>
                                            <label for="exampleFormControlInput1" class="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</label>
                                            <div className='align-content-center ' style={{ marginLeft: 'auto' }}><input type='checkbox' className='me-2' value={online} onChange={(e) => setOnline(e.target.checked)} />{(content[selectedLanguage].find(item => item.elementLabel === 'Online') || {}).mvalue || "nf Online"}</div>
                                        </div>

                                        <MultiSelect setLocationData={setLocation} viewLocation={location} onlineStatus={online} isEdit={isAcquiredEdit} />
                                    </div>


                                </div>
                                <div className='d-lg-flex my-2'>
                                    <div className=" w-100 h-75  ">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'EducationStartDate') || {}).mvalue || "nf Education Start Date "}<span className='text-danger' >*</span></label>
                                        {/* <input type="date" style={{ height: "32px" }} max={timestampToYYYYMMDD(Date.now())} className="form-control bg-body-tertiary h-75 " id="exampleFormControlInput1" name='startDate' onChange={(e) => setNewEducation({ ...newEducation, [e.target.name]: e.target.value })} value={newEducation.startDate} /> */}
                                        <DatePicker
                                            style={{ height: "32px" }}
                                            maxDate={timestampToYYYYMMDD(Date.now())}
                                            className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                            id="exampleFormControlInput1"
                                            onChange={(e) => setNewEducation({ ...newEducation, startDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                            toggleCalendarOnIconClick
                                            selected={newEducation?.startDate ? newEducation?.startDate : null}
                                            dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            placeholderText={regionalData?.selectedCountry?.dateFormat}
                                            onBlur={() => { }}
                                        />
                                    </div>
                                    <div className=" ms-lg-2 w-100 h-75  ">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'EducationEndDate') || {}).mvalue || "nf EducationEndDate"}</label>
                                        {/* <input type="date" style={{ height: "32px" }} max={timestampToYYYYMMDD(Date.now())} min={newEducation.startDate ? newEducation.startDate : ""} className={onGoing.instituteEndDate ? "form-control bg-body-tertiary h-75 text-secondary  " : "form-control bg-body-tertiary h-75 "}
                                            id="exampleFormControlInput1" name='endDate'  {...onGoing.instituteEndDate && { disabled: true }}
                                            onChange={(e) => setNewEducation({ ...newEducation, [e.target.name]: e.target.value })} value={newEducation.endDate} /> */}

                                        <DatePicker
                                            style={{ height: "32px" }}
                                            minDate={newEducation.startDate ? newEducation.startDate : ""}
                                            maxDate={timestampToYYYYMMDD(Date.now())}
                                            className={`form-control  h-75 buttom-line-input px-0 w-100 `}
                                            id="exampleFormControlInput1"
                                            onChange={(e) => setNewEducation({ ...newEducation, endDate: e ? timestampToYYYYMMDD(new Date(e).getTime()) : null })}
                                            toggleCalendarOnIconClick
                                            selected={newEducation?.endDate ? newEducation?.endDate : null}
                                            dateFormat={formatDateInputType(regionalData?.selectedCountry?.dateFormat)}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            placeholderText={regionalData?.selectedCountry?.dateFormat}
                                            disabled={onGoing.instituteEndDate}
                                            onBlur={() => { }}
                                        />

                                        <div className={onGoing.instituteEndDate ? 'd-flex ms-1 align-items-center font-6 text-secondary   ' : 'd-flex ms-1 align-items-center font-6 text-secondary '} >
                                            <label htmlFor="exampleFormControlInput1" className="">Current Education</label>
                                            <input className='ms-2 ' type="checkbox" name="instituteEndDate"
                                                onChange={(e) => {
                                                    setOnGoing({ ...onGoing, [e.target.name]: e.target.checked });
                                                    if (e.target.checked) {
                                                        setNewEducation({ ...newEducation, endDate: "" });
                                                    }
                                                }} />
                                        </div>

                                    </div>
                                </div>

                                <div className="my-2 ">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">{(content[selectedLanguage].find(item => item.elementLabel === 'BriefDescription') || {}).mvalue || "nf Brief Description"} <span className='text-danger' >*</span></label>
                                    <textarea className="form-control bg-body-tertiary" id="exampleFormControlTextarea1" rows="2" name='briefDescriptions' onChange={(e) => setNewEducation({ ...newEducation, [e.target.name]: e.target.value })} value={newEducation.briefDescriptions} >
                                    </textarea>
                                </div>



                                <div className='d-flex justify-content-end'>
                                    <SecondaryBtnLoader label={(content[selectedLanguage].find(item => item.elementLabel === 'AddEducation') || {}).mvalue || "nf Add Education"} onClick={() => institutionAddClick()} backgroundColor="#815F0B" color="#F8F8E9" loading={eduLoaderOn} />

                                </div>
                            </div>
                        </div>
                    </div>

                </>


            }



        </div>
    )
}

export default EducationForm