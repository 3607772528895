import React, { useEffect, useState } from 'react'
import { showErrorToast } from '../../ToastNotification/showErrorToast'
import { BASE_URL } from '../../../config/Properties'
import Loader from '../../Loader'
import { FiMinus } from 'react-icons/fi'
const PrerequsiteSkills = ({ courseId }) => {
    const [prerequsiteSkills, setPrerequisiteSkills] = useState([])
    const [prerequsiteLoading, setPrerequsiteLoading] = useState(false)
    
    const fetchUserCoursePrerequsites = async () => {
        setPrerequsiteLoading(true)
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/courses/UserCourse%20Prerequisite?authToken=${token}&searchFieldName=userCourseId&searchValue=${courseId}`);
            const data = await response.json();
            setPrerequisiteSkills(data)
            console.log(data);
            setPrerequsiteLoading(false)
        } catch (error) {
            showErrorToast('something went wrong')
            setPrerequsiteLoading(false)
            throw error;
        }
    }
    useEffect(() => {
        fetchUserCoursePrerequsites()
    }, [courseId])


    if (prerequsiteLoading) {
        return (
            <div class="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <div class="spinner-border" style={{ width: "2rem", height: "2rem" }} role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th className='p-1' scope="col">#</th>
                        <th className='p-1' scope="col">prerequisite Skills</th>
                        <th className='p-1' scope="col">Mandatory</th>
                        <th className='p-1' scope="col">Exclude (not)</th>
                    </tr>
                </thead>
                <tbody className=" divide-y ml-5  ">
                    {
                        prerequsiteSkills?.map((skill, index) => {
                            if(skill.skillId){
                                
                            return (

                                <tr key={index}>
                                    <th className='p-1' scope="col">{index + 1}</th>
                                    <td className='p-1' scope="col">{skill.skillId}</td>
                                    <td className='p-1' scope="col">NA</td>
                                    <td className='p-1' scope="col">NA</td>
                                    {/* <td className='p-1' scope="col"><input type='checkbox' style={{ accentColor: '#577126' }} checked={skill.mandatory} /></td> */}
                                    {/* <td className='p-1' scope="col"><input type='checkbox' style={{ accentColor: '#577126' }} checked={false} /></td> */}
                                </tr>

                            )
                            }

                            {/* if (skill.exclude) {
                          return (
                            <tr className='' style={{ opacity: '.3' }} key={index}>
                              <th className='p-1' scope="col">{index + 1}</th>
                              <td className='p-1' scope="col">{skill.name}</td>
                              <td className='p-1' scope="col"><FiMinus /></td>
                              <td className='p-1' scope="col"><input type='checkbox' style={{ accentColor: '#577126' }} checked={true} /></td>
                            </tr>

                          )
                        } else {
                          return (
                            <tr key={index}>
                              <th className='p-1' scope="col">{index + 1}</th>
                              <td className='p-1' scope="col">{skill.name}</td>
                              <td className='p-1' scope="col"><input type='checkbox' style={{ accentColor: '#577126' }} checked={skill.mandatory} /></td>
                              <td className='p-1' scope="col"><input type='checkbox' style={{ accentColor: '#577126' }} checked={false} /></td>
                            </tr>
                          )
                        } */}
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default PrerequsiteSkills
