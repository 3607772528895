import axios from "axios";
import { BASE_URL } from "../../config/Properties";

const LocationCitySuggestionApi = async (query, searchField) => {

  const SEARCH_URI = BASE_URL + `/skill/api/v1/skills/searchLocationsCity/Map Database?searchFieldName=${searchField}&authToken=${localStorage.getItem("token")}`;
  return axios
    .get(`${SEARCH_URI}&searchValue=${query}`)
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default LocationCitySuggestionApi;
