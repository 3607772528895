import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaSearch } from "react-icons/fa";
import './Css/SidebarSeekerStyle.css';
import { BsFillBriefcaseFill } from "react-icons/bs";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

function SidebarSeeker({ sidebarHeight, menuItems }) {
    const [collapsed, setCollapsed] = useState(true); // Start with the sidebar collapsed
    const [currentPage, setCurrentPage] = useState();
    const pathNameString = useLocation().pathname;

    const navigate = useNavigate()

    const handleMouseEnter = () => {
        setCollapsed(false); // Expand the sidebar on hover
    };

    const handleMouseLeave = () => {
        setCollapsed(true); // Collapse the sidebar when mouse leaves
    };

    useEffect(() => {

        let ph = pathNameString.split('/');
        console.log("pathNameString ", ph[ph.length - 1]);
        setCurrentPage(ph[ph.length - 1]);


    }, [])
    const navigateTo = (e) => {


        navigate(`/dashboard/${e.path}`);
    }



    return (

        <Sidebar onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            height={sidebarHeight}
            width='200px'
            backgroundColor='#3A3A3A'
            className='sidebar'
            style={{ zIndex: "1", position: "absolute" }}
            collapsed={collapsed}
        >

            <Menu style={{ height: sidebarHeight }} >
                {/* <MenuItem className='menu-item' id='jdsearch' onClick={(e) => navigateTo(e.target.id)} icon={<BsFillBriefcaseFill className='icon' />}>My JD'S</MenuItem>
                <MenuItem className='menu-item' icon={<FaSearch className='icon' />}>Skill Search</MenuItem> */}
                {
                    menuItems?.map((data, index) => < MenuItem className={`menu-item  ${currentPage === data.path ? "active" : ""}`} id={index} onClick={() => navigateTo(data)} icon={data.icon}>{data.name}</MenuItem>)
                }

                {/* More menu items... */}
            </Menu>
        </Sidebar >


    );
}

export default SidebarSeeker;
