import React, { useCallback, useEffect, useState } from "react";

import ChangePassword from "./ChangePassword";
import ChangeRole from "./ChangeRole";
import { useSelector } from "react-redux";
import ChangeRegionTimezones from "./ChangeRegionTimezones";
import ChangeScreen from "./ChangeScreen";
import { getAccountDetails } from "../../api/auth/getAccountDetails";
import { EditAccountDetails } from "../../api/editData/EditAccountDetails";
import { showSuccessToast } from "../ToastNotification/showSuccessToast";
import AccountLanDetails from "./AccountLanDetails";

const AccountSettings = () => {
  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("userName");

  const userDetails = useSelector((state) => state.userProfile.data);
  const selectedLanguage = useSelector((state) => state.language);
  const content = useSelector((state) => state.content);
  const regionalData = useSelector((state) => state.regionalData);

  const [selectedValue, setSelectedValue] = useState("changePassword");

  /* SCREEN NAME STATE */
  const [interfaceName, setInterfaceName] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  /* GET ACCOUNT DETAILS */
  const getAccDetais = useCallback(async () => {
    try {
      const res = await getAccountDetails(accountId);
      if (res?.data?.account?.defaultView === "DIALOG") {
        setInterfaceName(res?.data?.account?.defaultView);
      } else if (
        res?.data?.account?.defaultView === "MAIN" ||
        res?.data?.account?.defaultView !== "DIALOG"
      ) {
        setInterfaceName("MAIN");
      }
    } catch (error) {
      console.error(
        "Error occured during fetch account details in change screen: ",
        error
      );
    }
  }, [accountId]);

  /* LOAD USER ACCOUNT DATA TO FETCH SCREEN NAME */
  useEffect(() => {
    getAccDetais();
  }, [getAccDetais]);

  /* HANDLE EDIT SCREEN NAME */
  const handleScreenNameSubmit = useCallback(async () => {
    setIsSubmitLoading(true);
    try {
      const payload = {
        defaultView: interfaceName,
      };
      const res = await EditAccountDetails(
        localStorage.getItem("userName"),
        payload
      );
      setIsSubmitLoading(false);
      showSuccessToast(res?.data?.message);
    } catch (error) {
      setIsSubmitLoading(false);
      console.err("Error occured during changing interface: ", error);
    }
  }, [interfaceName]);

  return (
    <div
      class="modal fade modal-xl"
      id="accountsettings"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              {(
                content[selectedLanguage].find(
                  (item) => item.elementLabel === "AccountSettings"
                ) || {}
              ).mvalue || "nf Account Settings"}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            {/* CHange Password Accordian */}
            <div class="accordion" id="changePassword">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <div className="d-flex">
                    <button
                      class="accordion-button"
                      type="button"
                      style={{
                        backgroundColor:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarBgColor"
                            ) || {}
                          ).mvalue || "#577126",
                        color:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarFontColor"
                            ) || {}
                          ).mvalue || "#F7FFDD",
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#changePasswordB"
                      aria-expanded="true"
                      aria-controls="changePasswordB"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ChangePassword"
                        ) || {}
                      ).mvalue || "nf Change Password"}
                    </button>
                  </div>
                </h2>
                <div
                  id="changePasswordB"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#changePassword"
                >
                  <div class="accordion-body">
                    <div class="container-fluid">
                      <div class="row flex-nowrap">
                        <div class="col ">
                          <ChangePassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Change Roles */}
            <div class="accordion" id="changeRole">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <div className="d-flex">
                    <button
                      class="accordion-button"
                      type="button"
                      style={{
                        backgroundColor:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarBgColor"
                            ) || {}
                          ).mvalue || "#577126",
                        color:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarFontColor"
                            ) || {}
                          ).mvalue || "#F7FFDD",
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#changeRoleBtn"
                      aria-expanded="true"
                      aria-controls="changeRoleBtn"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "AddRole"
                        ) || {}
                      ).mvalue || "nf Add Role"}
                    </button>
                  </div>
                </h2>
                <div
                  id="changeRoleBtn"
                  class="accordion-collapse collapse "
                  data-bs-parent="#changeRole"
                >
                  <div class="accordion-body">
                    <div class="container-fluid">
                      <div class="row flex-nowrap">
                        <div class="col ">
                          <ChangeRole key={1} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Change Region */}
            <div class="accordion" id="changeRegion">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <div className="d-flex">
                    <button
                      class="accordion-button"
                      type="button"
                      style={{
                        backgroundColor:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarBgColor"
                            ) || {}
                          ).mvalue || "#577126",
                        color:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarFontColor"
                            ) || {}
                          ).mvalue || "#F7FFDD",
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#changeRegionBtn"
                      aria-expanded="true"
                      aria-controls="changeRegionBtn"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "RegionAndTzSettings"
                        ) || {}
                      ).mvalue || "nf Region and Timezone Settings"}
                    </button>
                  </div>
                </h2>
                <div
                  id="changeRegionBtn"
                  class="accordion-collapse collapse "
                  data-bs-parent="#changeRegion"
                >
                  <div class="accordion-body">
                    <div class="container-fluid">
                      <div class="row flex-nowrap">
                        <div class="col ">
                          <ChangeRegionTimezones />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CHANGE SCREEN */}
            <div class="accordion" id="changeScreen">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <div className="d-flex">
                    <button
                      class="accordion-button"
                      type="button"
                      style={{
                        backgroundColor:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarBgColor"
                            ) || {}
                          ).mvalue || "#577126",
                        color:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarFontColor"
                            ) || {}
                          ).mvalue || "#F7FFDD",
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#changeScreenBtn"
                      aria-expanded="true"
                      aria-controls="changeScreenBtn"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ChangeScreen"
                        ) || {}
                      ).mvalue || "nf Change Screen"}
                    </button>
                  </div>
                </h2>
                <div
                  id="changeScreenBtn"
                  class="accordion-collapse collapse"
                  data-bs-parent="#changeScreen"
                >
                  <div class="accordion-body">
                    <div class="container-fluid">
                      <div class="row flex-nowrap">
                        <div class="col">
                          <ChangeScreen
                            content={content}
                            selectedLanguage={selectedLanguage}
                            interfaceName={interfaceName}
                            setInterfaceName={setInterfaceName}
                            isSubmitLoading={isSubmitLoading}
                            handleScreenNameSubmit={handleScreenNameSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ACCOUNT LANGUAGE DETAILS */}
            <div class="accordion" id="langDetails">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <div className="d-flex">
                    <button
                      class="accordion-button"
                      type="button"
                      style={{
                        backgroundColor:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarBgColor"
                            ) || {}
                          ).mvalue || "#577126",
                        color:
                          (
                            content[selectedLanguage].find(
                              (item) => item.elementLabel === "SecBarFontColor"
                            ) || {}
                          ).mvalue || "#F7FFDD",
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target="#langDetailsBtn"
                      aria-expanded="true"
                      aria-controls="langDetailsBtn"
                    >
                      {(
                        content[selectedLanguage].find(
                          (item) => item.elementLabel === "ProfileDetails"
                        ) || {}
                      ).mvalue || "nf Profile Details"}
                    </button>
                  </div>
                </h2>
                <div
                  id="langDetailsBtn"
                  class="accordion-collapse collapse"
                  data-bs-parent="#langDetails"
                >
                  <div class="accordion-body">
                    <div class="container-fluid">
                      <div class="row flex-nowrap">
                        <div class="col">
                          <AccountLanDetails
                            content={content}
                            selectedLanguage={selectedLanguage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
