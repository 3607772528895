import React, { useEffect, useState } from 'react'
import { userResumeData } from './components/userResumeData'
import ResumeEmp from './components/ResumeEmp'
import ResumeEdu from './components/ResumeEdu'
import ResumeSkills from './components/ResumeSkills'
import { useSelector } from 'react-redux'
import { MdDelete, MdDoneOutline, MdEdit, MdOutlineDone } from 'react-icons/md'
import ResumeProfile from './components/ResumeProfile'
import ResumeCert from './components/ResumeCert'
import ResumeLice from './components/ResumeLice'
import ResumeTrain from './components/ResumeTrain'
import ResumeAsso from './components/ResumeAsso'
import ResumeLang from './components/ResumeLang'
import ResumeAchi from './components/ResumeAchi'
import ResumeRef from './components/ResumeRef'
import ResumePersonalAttributes from './components/ResumePersonalAttributes'

const ResumeResults = () => {
  const content = useSelector(state => state.content);
  const selectedLanguage = useSelector(state => state.language);
  const [resumeData, setResumeData] = useState(JSON.parse(localStorage.getItem('resumeData'))?.Value?.ResumeData)
  const [profileInfo, setProfileInfo] = useState(null)
  const [resumePersonalAtt, setResumePersonalAtt] = useState(null)
  const [resumeSkills, setResumeSkills] = useState(null)
  const [resumeEmp, setResumeEmp] = useState(null)
  const [resumeEdu, setResumeEdu] = useState(null)
  const [resumeCert, setResumeCert] = useState(null)
  const [resumeLice, setResumeLice] = useState(null)
  const [resumeTrain, setResumeTrain] = useState(null)
  const [resumeAsso, setResumeAsso] = useState(null)
  const [resumeLang, setResumeLang] = useState(null)
  const [resumeAchi, setResumeAchi] = useState(null)
  const [resumeRef, setResumeRef] = useState(null)
  const [showAllDetails, setShowAllDetails] = useState(false)
  const [editProfile, setEditProfile] = useState(false)

  useEffect(() => {
    const profileInfo = {
      fullName: resumeData?.ContactInformation?.CandidateName.FormattedName,
      firstName: resumeData?.ContactInformation?.CandidateName?.GivenName,
      lastName: resumeData?.ContactInformation?.CandidateName?.FamilyName,
      address: resumeData?.ContactInformation?.Location?.StreetAddressLines,
      city: resumeData?.ContactInformation?.Location?.Municipality,
      state: resumeData?.ContactInformation?.Location?.Regions ? resumeData?.ContactInformation?.Location?.Regions[0] : '',
      country: resumeData?.ContactInformation?.Location?.CountryCode,
      postalCode: resumeData?.ContactInformation?.Location?.PostalCode,
      phone: resumeData?.ContactInformation?.Telephones ? resumeData?.ContactInformation?.Telephones[0]?.Raw : '',
      about: resumeData?.ProfessionalSummary,
      objective: resumeData?.Objective,
      edit: false
    }
    const resumePersonalAtt = resumeData?.PersonalAttributes && {
      availability: resumeData?.PersonalAttributes?.Availability,
      birthplace: resumeData?.PersonalAttributes?.Birthplace,
      currentLocation: resumeData?.PersonalAttributes?.CurrentLocation,
      drivingLicense: resumeData?.PersonalAttributes?.DrivingLicense,
      DOB: resumeData?.PersonalAttributes?.DateOfBirth?.Date,
      currentSalaryAmount: resumeData?.PersonalAttributes?.CurrentSalary?.Amount,
      currentSalaryCurrency: resumeData?.PersonalAttributes?.CurrentSalary?.Currency,
      requiredSalaryAmount: resumeData?.PersonalAttributes?.RequiredSalary?.Amount,
      requiredSalaryCurrency: resumeData?.PersonalAttributes?.RequiredSalary?.Currency,
      familyComposition: resumeData?.PersonalAttributes?.FamilyComposition,
      fathersName: resumeData?.PersonalAttributes?.FathersName,
      gender: resumeData?.PersonalAttributes?.Gender,
      maritalStatus: resumeData?.PersonalAttributes?.MaritalStatus,
      mothersMaidenName: resumeData?.PersonalAttributes?.MothersMaidenName,
      motherTongue: resumeData?.PersonalAttributes?.MotherTongue,
      nationality: resumeData?.PersonalAttributes?.Nationality,
      // nationalIdentities   : resumeData?.PersonalAttributes?.NationalIdentities[0] ? resumeData?.PersonalAttributes?.NationalIdentities[0]?.Phrase : '',
      passportNumber: resumeData?.PersonalAttributes?.PassportNumber,
      preferredLocation: resumeData?.PersonalAttributes?.PreferredLocation,
      visaStatus: resumeData?.PersonalAttributes?.VisaStatus,
      willingToRelocate: resumeData?.PersonalAttributes?.WillingToRelocate,

      edit: false
    }
    const resumeSkills =
      resumeData?.Skills?.Normalized?.map((e) => ({
        id: e.Id,
        skill: e.Name,
        experience: "0",
        required: false,
        validated: false,
        addToList: false,
        edit: false,
        rawSkills:e.RawSkills,
        type:e.Type
      }));
    const resumeEmp =
      resumeData?.EmploymentHistory?.Positions?.map((e) => ({
        id: e.Id,
        organization: e?.Employer?.Name?.Raw,
        position: e?.JobTitle?.Raw,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false,
      }));
    const resumeEdu =
      resumeData?.Education?.EducationDetails?.map((e) => ({
        id: e.Id,
        institution: e?.SchoolName?.Raw,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false,
      }));
    const resumeCert =
      resumeData?.Certifications?.map((e, i) => ({
        id: i,
        name: e.Name,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false

      }));
    const resumeLice =
      resumeData?.Licenses?.map((e, i) => ({
        id: i,
        name: e.Name,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false
      }));
    const resumeTrain =
      resumeData?.Training?.Trainings?.map((e, i) => ({
        id: i,
        name: e.Text,
        institution: e.Entity,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false
      }));
    const resumeAsso =
      resumeData?.Associations?.map((e, i) => ({
        id: i,
        name: e.Organization,
        role: e.Role,
        startDate: e?.StartDate?.Date,
        endDate: e?.EndDate?.Date,
        description: e?.Description,
        edit: false
      }));
    const resumeLang =
      resumeData?.LanguageCompetencies?.map((e, i) => ({
        id: i,
        language: e.Language,
        languageCode: e.LanguageCode,
        edit: false
      }));
    const resumeAchi =
      resumeData?.Achievements?.map((e, i) => ({
        id: i,
        name: e,
        date: '',
        edit: false
      }));
    const resumeRef =
      resumeData?.References?.map((e, i) => ({
        id: i,
        name: e.ReferenceName.FormattedName,
        phone: "",
        title: e.Title,
        company: e.Company,
        edit: false
      }));

    setProfileInfo(profileInfo)
    setResumeEmp(resumeEmp)
    setResumeEdu(resumeEdu)
    setResumeSkills(resumeSkills)
    setResumeCert(resumeCert)
    setResumeLice(resumeLice)
    setResumeTrain(resumeTrain)
    setResumeAsso(resumeAsso)
    setResumeLang(resumeLang)
    setResumeAchi(resumeAchi)
    setResumeRef(resumeRef)
    setResumePersonalAtt(resumePersonalAtt)
  }, [resumeData])

  console.log(resumeData);
  console.log(resumePersonalAtt);

  return (
    <div className='d-flex justify-content-center align-items-center flex-column px-md-5 py-5 px-1 mb-5'>

      <div className='bg-light w-100 text-center p-1 border mb-3'> <span className='fw-bold '>Name:</span> {resumeData?.ContactInformation?.CandidateName.FormattedName}</div>

      <div class="accordion w-100" id="accordionPanelsStayOpenExample">


        <div class="accordion-item  " >
          <h2 class="accordion-header " >
            <button class={`accordion-button collapsed `} style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseProfileInfo" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseProfileInfo">
              Profile Info
            </button>
          </h2>
          <div id="panelsStayOpen-collapseProfileInfo" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
            <div class="accordion-body" style={{ position: 'relative' }}>

              <ResumeProfile profileInfo={profileInfo} setProfileInfo={setProfileInfo} />

            </div>
          </div>
        </div>

        {
            resumePersonalAtt && 

        <div class="accordion-item  " >
          <h2 class="accordion-header " >
            <button class={`accordion-button collapsed `} style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseProfileInfoPersonal" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseProfileInfoPersonal">
              Personal Attributes
            </button>
          </h2>
          <div id="panelsStayOpen-collapseProfileInfoPersonal" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
            <div class="accordion-body" style={{ position: 'relative' }}>

              <ResumePersonalAttributes resumePersonalAtt={resumePersonalAtt} setResumePersonalAtt={setResumePersonalAtt} />

            </div>
          </div>
        </div>

        }



        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed  " style={{ backgroundColor: '#577126', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseThree">
              Employment History
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
            <div class="accordion-body py-md-5 py-2 px-md-5 px-2">
              {
                resumeEmp?.map((emp, i) => {
                  return (
                    <ResumeEmp emp={emp} setResumeEmp={setResumeEmp} index={i} />
                  )
                })
              }
            </div>
          </div>
        </div>




        <div class="accordion-item " >
          <h2 class="accordion-header " >
            <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseTwo">
              Education History
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
            <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
              {
                resumeEdu?.map((edu, i) => {
                  return (
                    <ResumeEdu edu={edu} resumeEdu={resumeEdu} setResumeEdu={setResumeEdu} index={i} />
                  )
                })
              }
            </div>
          </div>
        </div>

        {
          resumeCert &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseFive">
                Certification History
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFive" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeCert?.map((cert, i) => {
                    return (
                      <ResumeCert data={cert} setResumeCert={setResumeCert} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }


        {
          resumeLice &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseSix">
                Licenses
              </button>
            </h2>
            <div id="panelsStayOpen-collapseSix" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeLice?.map((lice, i) => {
                    return (
                      <ResumeLice data={lice} setResumeLice={setResumeLice} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {
          resumeTrain &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseSeven">
                Trainings
              </button>
            </h2>
            <div id="panelsStayOpen-collapseSeven" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeTrain?.map((lice, i) => {
                    return (
                      <ResumeTrain data={lice} setResumeTrain={setResumeTrain} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {
          resumeAsso &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseEight">
                Associations
              </button>
            </h2>
            <div id="panelsStayOpen-collapseEight" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeAsso?.map((asso, i) => {
                    return (
                      <ResumeAsso data={asso} setResumeAsso={setResumeAsso} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {
          resumeLang &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseNine">
                Languages
              </button>
            </h2>
            <div id="panelsStayOpen-collapseNine" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeLang?.map((lang, i) => {
                    return (
                      <ResumeLang data={lang} setResumeLang={setResumeLang} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {
          resumeAchi &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseTen">
                Achivements
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTen" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeAchi?.map((achi, i) => {
                    return (
                      <ResumeAchi data={achi} setResumeAchi={setResumeAchi} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }


        {
          resumeRef &&
          <div class="accordion-item " >
            <h2 class="accordion-header " >
              <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseEleven">
                References
              </button>
            </h2>
            <div id="panelsStayOpen-collapseEleven" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
              <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">
                {
                  resumeRef?.map((ref, i) => {
                    return (
                      <ResumeRef data={ref} setResumeRef={setResumeRef} index={i} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        }



        <div class="accordion-item " >
          <h2 class="accordion-header " >
            <button class="accordion-button collapsed " style={{ backgroundColor: '#577126', color: 'white', }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded={setShowAllDetails} aria-controls="panelsStayOpen-collapseFour">
              Skills
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" class={`accordion-collapse collapse ${showAllDetails ? 'show' : ''}`}>
            <div class="accordion-body  py-md-5 py-2 px-md-5 px-2">

              <div className="table-responsive w-100">
                <table className="table table-sm  table-fixed table-hover    ">
                  <thead>
                    <tr className="border-dark-subtle ">
                      <th scope="col" className="bg-body- " >
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Skills"
                          ) || {}
                        ).mvalue || "nf Skills"}
                      </th>
                      <th scope="col" className="bg-body- " >
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "OriginalTermsFromResume"
                          ) || {}
                        ).mvalue || "nf Original Terms From Resume"}
                      </th>
                      <th scope="col" className="bg-body- " >
                        {(
                          content[selectedLanguage].find(
                            (item) => item.elementLabel === "Type"
                          ) || {}
                        ).mvalue || "nf Type"}
                      </th>

                      <th>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      resumeSkills?.map((skill) => (
                        <tr className="" >
                          <td>{skill?.skill}</td>
                          <td>{ 
                            skill?.rawSkills?.join(", ")}</td>
                          <td>{skill?.type}</td>

                          <td className=''>
                            <MdDelete style={{ cursor: 'pointer' }} onClick={() => {
                              const updatedList = resumeSkills.filter(
                                (s) => s?.id !== skill?.id
                              );
                              setResumeSkills(updatedList);
                            }} />
                          </td>
                        </tr>

                      ))
                    }
                  </tbody>
                </table>
              </div>


            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ResumeResults
