import axios from "axios";
import { BASE_URL } from "../../config/Properties";

export const fetchSkillsData = async (value, selectedLanguage) => {
  selectedLanguage = selectedLanguage.substring(0, 2);

  try {
    const res = await axios.get(
      `${BASE_URL}/skill/api/v1/skills/searchSkills/skill/${selectedLanguage}?searchFieldName=occupation&authToken=${localStorage.getItem(
        "token"
      )}&searchValue=${value}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};
