import React, {  useState } from "react";
import { useSelector } from "react-redux";
import Playground from "../FlagSelection";

const CountryFlagComponent = ({ Country, setCountry }) => {
  /* STORE IMPORTS */
  const {
    language: selectedLanguage,
    content,
    regionalData: { selectedCountry },
  } = useSelector((state) => state);

  /* STATES INIT */
  // const [Country, setCountry] = useState({
  //   countryCode: "US",
  //   countryName: "United States",
  // });
  const [ShowDropDown, setShowDropDown] = useState(false);
  const [flagSearch, setFlagSearch] = useState("");

  /* HANDLE DROPDOWN */
  const handleDropDown = () => {
    setShowDropDown(!ShowDropDown);
  };

  /* HANDLE FLAG FILTER */
  const handleFlagFilter = (e) => {
    setFlagSearch(e.target.value);
  };

  return (
    <React.Fragment>
      <div class="px-0 d-none d-lg-block">
        <button
          type="button"
          onClick={handleDropDown}
          class="btn border-0 p-0 m-0 ms-2"
          data-bs-auto-close="outside"
        >
          <img
            className="m-0 p-0"
            style={{ width: "35px", height: "37px", borderRadius: "100%" }}
            src={`https://flagsapi.com/${selectedCountry?.countryCode}/flat/32.png`}
            alt=""
          />
        </button>
        {ShowDropDown && (
          <div
            class={ShowDropDown ? "dropdown-menu show" : "dropdown-menu"}
            style={{
              minWidth: "10px",
              marginRight: "100px",
              marginLeft: "-30px",
            }}
          >
            <div className="d-flex justify-content-center">
              <input
                placeholder="Search Country..."
                value={flagSearch}
                onChange={handleFlagFilter}
                style={{
                  width: "130px",
                  height: "20px",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></input>
            </div>
            <div
              className=" table-responsive d-flex font-5"
              style={{ height: "130px" }}
            >
              <table className="table table-sm d-flex table-hover">
                <tbody
                  className="font-5"
                  style={{ width: "5%", cursor: "pointer" }}
                >
                  <Playground
                    setCountry={setCountry}
                    setShowDropDown={setShowDropDown}
                    flagSearch={flagSearch}
                    setFlagSearch={setFlagSearch}
                  />
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CountryFlagComponent;
