import React from 'react'
import { MdDelete, MdDoneOutline, MdEdit } from 'react-icons/md'

const ResumeProfile = ({profileInfo,setProfileInfo}) => {
    return (
        <>
            <div className='d-flex align-items-center justify-content-end gap-2 px-2 ' style={{ position: 'absolute', right: '20px', top: '20px', zIndex: '9999' }}>
                {
                    profileInfo?.edit ?
                        <MdDoneOutline style={{ cursor: 'pointer' }} onClick={() => setProfileInfo({ ...profileInfo, edit: false })} />
                        :
                        <MdEdit style={{ cursor: 'pointer' }} onClick={() => setProfileInfo({ ...profileInfo, edit: true })} />
                }
                <MdDelete style={{ cursor: 'pointer' }} />
            </div>
            <div className='d-flex justify-content-center align-items-center flex-column gap-3 row' style={{ pointerEvents: profileInfo?.edit ? '' : 'none' }} >
                <div class=" d-md-flex d-block  gap-4 justify-content-center align-items-center col-md-6 col-12" >
                    <label htmlFor="first-name" className='w-25 text-start text-md-end'>First Name</label>
                    <input type="Text" class="form-control" id="first-name" placeholder="Enter your first name" value={profileInfo?.firstName}  onChange={(e)=>setProfileInfo({...profileInfo, firstName:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="last-name" className='w-25 text-start text-md-end'>Last Name</label>
                    <input type="Text" class="form-control" id="last-name" placeholder="Enter your last name" value={profileInfo?.lastName} onChange={(e)=>setProfileInfo({...profileInfo, lastName:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block  gap-4 justify-content-center align-items-center col-md-6 col-12" >
                    <label htmlFor="Address" className='w-25 text-start text-md-end'>Address</label>
                    <input type="Text" class="form-control" id="Address" placeholder="Enter your address" value={profileInfo?.address} onChange={(e)=>setProfileInfo({...profileInfo, address:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="city" className='w-25 text-start text-md-end'>City</label>
                    <input type="Text" class="form-control" id="city" placeholder="Enter your city" value={profileInfo?.city} onChange={(e)=>setProfileInfo({...profileInfo, city:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="Region" className='w-25 text-start text-md-end'>State</label>
                    <input type="Text" class="form-control" id="Region" placeholder="Enter your state" value={profileInfo?.state} onChange={(e)=>setProfileInfo({...profileInfo, state:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="country" className='w-25 text-start text-md-end'>Country</label>
                    <input type="Text" class="form-control" id="country" placeholder="Enter your country" value={profileInfo?.country} onChange={(e)=>setProfileInfo({...profileInfo, country:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="Postal Code" className='w-25 text-start text-md-end'>Postal Code</label>
                    <input type="Text" class="form-control" id="Postal Code" placeholder="Enter your postal code" value={profileInfo?.postalCode} onChange={(e)=>setProfileInfo({...profileInfo, postalCode:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="phone" className='w-25 text-start text-md-end'>Phone</label>
                    <input type="Text" class="form-control" id="phone" placeholder="Enter your phone" value={profileInfo?.phone} onChange={(e)=>setProfileInfo({...profileInfo, phone:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="about" className='w-25 text-start text-md-end'>About</label>
                    <textarea rows={4} cols={6} class="form-control " id="about" placeholder="tell us about yourself" value={profileInfo?.about} onChange={(e)=>setProfileInfo({...profileInfo, about:e.target.value})}/>
                </div>
                <div class=" d-md-flex d-block gap-4 justify-content-center align-items-center col-md-6 col-12">
                    <label htmlFor="about" className='w-25 text-start text-md-end'>Objective</label>
                    <textarea rows={4} cols={6} class="form-control " id="about" placeholder="objective..." value={profileInfo?.objective} onChange={(e)=>setProfileInfo({...profileInfo, objective:e.target.value})}/>
                </div>
            </div>

        </>
    )
}

export default ResumeProfile
