import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { DayDifferenceToDynamicView } from '../../SkillOwner/HelperFunction/DayDifferenceToDynamicView';
import { calculateDaysDifference } from '../../SkillOwner/HelperFunction/CalculateDaysDifference ';
import { formatTimestampToDate } from '../../SkillOwner/HelperFunction/FormatTimestampToDate';
import ValidationDataComponentSeeker from '../ValidationDataComponentSeeker';

const SeekerSkillAcquiredDetail = ({ skillName, userDetails }) => {
    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);
    const [acquiredData, setAcquiredData] = useState([]);
    const regionalData = useSelector(state => state.regionalData);
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);

    useEffect(() => {
        if (userDetails.skillacq !== "null" && userDetails.skillacq !== "undefined") {
            const temp = userDetails.skillacq?.filter((skill) => skill.title.toLowerCase() === skillName.toLowerCase())
            setAcquiredData(temp);

        }
    }, [SkillBasedResult])


    return (
        <>

            {acquiredData?.length > 0 ?
                <>
                    <div className="table-responsive  ms-4  me-5">
                        <div className='d-none d-lg-block'>
                            <table className='table table-sm  table-borderless  my-2    table-fixed     '>
                                <tr className='    '>
                                    <th scope="col" style={{ width: "34%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Name') || {}).mvalue || "nf   Name "}</th>
                                    <th scope="col" className='bg-body- ' style={{ width: "13%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Duration') || {}).mvalue || "nf Duration "}</th>
                                    <th scope="col" className='bg-body- ' style={{ width: "17%" }}>  {(content[selectedLanguage].find(item => item.elementLabel === 'ProjectType') || {}).mvalue || "nf ProjectType"}</th>
                                    <th scope="col" className='bg-body- ' style={{ width: "26%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Location') || {}).mvalue || "nf Location"}</th>
                                    <th scope="col" className='' style={{ width: "10%" }}>{(content[selectedLanguage].find(item => item.elementLabel === 'Validation') || {}).mvalue || "nf Validation"}</th>


                                </tr>

                                <div className='mt-1 ' ></div>

                                {/* horizontal line */}
                                <tr style={{ borderColor: "gray", borderWidth: "1px" }} />

                            </table>
                        </div>

                        {acquiredData.map((skill, index) =>
                            skill.recordHide === 'Yes' ?
                                <></>
                                :
                                <>
                                    <div className='  ' key={index}>
                                        <div className='d-lg-none '>
                                            <table className='table table-borderless m-0  ' >
                                                <tr className=' ' >
                                                    <td><b>Source</b> </td>
                                                    <td>:{skill.source} </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Duration</b> </td>
                                                    <td>:{DayDifferenceToDynamicView(calculateDaysDifference(skill.fromDate, skill.toDate))}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Type</b> </td>
                                                    <td>:{skill.mtype}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Location</b> </td>
                                                    <td data-tooltip-id="my-tooltip" data-tooltip-content={skill.location} >:{skill.location ? (skill.location?.length > 20 ? skill.location.substring(0, 20) + "..." : skill.location) : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Validation</b> </td>
                                                    <td>:{skill.validation}</td>
                                                </tr>


                                            </table>

                                        </div>
                                        <div className='d-lg-flex d-none    '>
                                            <div className='  ' style={{ width: "34%" }}>{skill.source}</div>
                                            <div style={{ width: "13%" }}>{DayDifferenceToDynamicView(skill.duration)}</div>
                                            <div className='    ' style={{ width: "17%" }}>{skill.mtype}</div>
                                            <div className='    ' data-tooltip-id="my-tooltip" data-tooltip-content={skill.location} style={{ width: "26%" }}>
                                                {skill.location?.length > 20 ? skill.location.substring(0, 20) + "..." : skill.location}
                                            </div>
                                            <div className=' ' style={{ width: "10%" }}>{skill.validation === "Yes" ? "Yes" : "No"}</div>

                                        </div>



                                        <div className='mb-3 mt-4 ms-4 '>
                                            <div className='d-lg-flex  align-items-center  '>
                                                <div className='d-flex  ' style={{ width: "64%" }} > <b>{skill.mtype !== "Education" ?
                                                    (content[selectedLanguage].find(item => item.elementLabel === 'Organization') || {}).mvalue || "nf Organization" :
                                                    (content[selectedLanguage].find(item => item.elementLabel === 'InstitutionName') || {}).mvalue || "nf InstitutionName"}:</b>
                                                    <div>{skill.recordAnonymous === 'Yes' ? (content[selectedLanguage].find(item => item.elementLabel === 'Confidential') || {}).mvalue || "nf CONFIDENTIAL" : " " + skill.organization}</div>
                                                </div>
                                                <div className='d-flex  ps-lg-0  mt-1 mt-lg-0 me-2' style={{ width: "17%" }}>
                                                    <b>{(content[selectedLanguage].find(item => item.elementLabel === 'From') || {}).mvalue || "nf From"}:</b>
                                                    <div>{formatTimestampToDate((skill.fromDate), regionalData.selectedCountry.dateFormat)}</div>
                                                </div>
                                                <div className='d-flex   ps-lg-0  ' style={{ width: "22%" }}>
                                                    <b>{(content[selectedLanguage].find(item => item.elementLabel === 'To') || {}).mvalue || "nf To"}:</b>
                                                    <div>{skill.toDate ? formatTimestampToDate((skill.toDate), regionalData.selectedCountry.dateFormat) : "On-going"}</div>
                                                </div>
                                            </div>

                                            <div className='pb-1 pt-2   '><b>{(content[selectedLanguage].find(item => item.elementLabel === 'ProjectBriefDescription') || {}).mvalue || "not found"}:</b> </div>
                                            <div className='ms-4    '>
                                                {skill.briefDescriptions}
                                            </div>




                                        </div>
                                        {skill.validation === "Yes" &&
                                            <ValidationDataComponentSeeker data={skill?.ValidationData} itemId={skill?.id} />
                                        }

                                    </div>

                                    <hr className='border-black px-0 py-0  ' style={{ borderColor: "black", borderWidth: "2px" }} />

                                </>
                        )}

                    </div></> : <div className='d-flex justify-content-center mt-2 mb-3'>
                    {(content[selectedLanguage].find(item => item.elementLabel === 'NoSkillAcquiredDataSeeker') || {}).mvalue || "nf No Skills Acquired Expirience "}
                </div>}
        </>
    )
}

export default SeekerSkillAcquiredDetail