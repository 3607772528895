import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import { useSelector } from 'react-redux';

import DetailPanel from './DetailPanel';

const GridViewComponent = ({ data }) => {
    const SkillBasedResult = useSelector(state => state.SkillBasedResult);

    const selectedLanguage = useSelector((state) => state.language);
    const content = useSelector((state) => state.content);


    //should be memoized or stable
    console.log("daataa ", data);

    function sliceUntilPipe(str) {
        if (typeof str !== 'string') {
            return '';
        }
        const index = str.indexOf('|');
        if (index !== -1) {
            return str.slice(0, index);
        }
        return str; // return the original string if '|' is not found
    }


    const columns = useMemo(
        () => [
            {
                accessorKey: 'fullName', //access nested data with dot notation
                header: 'Name',
                size: 100,
            },

            {
                accessorFn: (row) => {
                    if (Array.isArray(row.availableSkills)) {
                        return row.availableSkills?.map(skill => sliceUntilPipe(skill.skillOccupation))
                            .join(', ');
                    }
                    return '';
                },
                header: 'Skills',
                size: 150,
            },
            {
                accessorKey: 'PrefferedLocation', //normal accessorKey
                header: 'Preffered Location',
                size: 150,
            },
            {
                accessorKey: 'Email',
                header: 'Email',
                size: 100,
            },
            {
                accessorKey: 'PhoneNumber',
                header: 'Phone',
                size: 100,
            },
        ],
        [],
    );





    const table = useMaterialReactTable({

        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableExpandAll: false,
        enableBottomToolbar: false,
        enablePagination: false,
        enableRowVirtualization: false,
        muiDetailPanelProps: () => ({

            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
                borderLeft:"solid 4px #ffff",
                borderRight:"solid 4px #ffff"
            }),
            
        }),
        muiExpandButtonProps: ({ row, table }) => ({

            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),
        //conditionally render detail panel
        renderDetailPanel: ({ row }) => <DetailPanel row={row} />,

    });

    return <div>

        <MaterialReactTable table={table} />
    </div>
};

export default GridViewComponent;
